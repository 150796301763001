import { Form, Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import { useProduct } from '@stores/Products/useProduct';
import { useProductSubmit } from '@hooks/useProductSubmit';
import { ProductResponse } from '@interfaces/Api';
import {
    FormCheckbox,
    FormInputBox,
    FormRadioGroup,
} from '@components/Molecules';
import EditProductFormikButtons from './EditProductFormikButtons';
import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { useTranslation } from 'react-i18next';

type IncentiveFeeType = 'carriedInterest' | 'performance';

const incentiveFeesOptions: RadioOption<IncentiveFeeType>[] = [
    {
        label: 'Carried Interest',
        value: 'carriedInterest',
    },
    {
        label: 'Performance Fees',
        value: 'performance',
    },
];

export const ProductFees = () => {
    const { t } = useTranslation();
    const { productId } = useParams();
    const { product } = useProduct(productId);

    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({});
    const [incentiveFeeType, setIncentiveFeeType] =
        useState<IncentiveFeeType>();

    useEffect(() => {
        if (product) {
            if (
                product.fees?.incentiveFee?.carryFeePercentage ||
                product.fees?.incentiveFee?.hurdleFeePercentage ||
                product.fees?.incentiveFee?.managerCatchupPercentage
            ) {
                setIncentiveFeeType('carriedInterest');
            }
            if (product.fees?.incentiveFee?.performanceFeePercentage) {
                setIncentiveFeeType('performance');
            }
            setFormValue(product);
        }
    }, [product]);

    const minNumberErrorMessage = t('admin.product.negative_numbers_not_allowed');

    const validationSchema = Yup.object().shape({
        fees: Yup.object().shape({
            hasIncentiveFee: Yup.boolean(),
            incentiveFee: Yup.object().when('fees.hasIncentiveFee', {
                is: true,
                then: Yup.object().shape({
                    ['carryFeePercentage']: Yup.number().when(
                        'hasIncentiveFee',
                        {
                            is: () => incentiveFeeType == 'carriedInterest',
                            then: Yup.number()
                                .required('Required')
                                .min(0, minNumberErrorMessage),
                        }
                    ),

                    ['hurdleFeePercentage']: Yup.number().when(
                        'hasIncentiveFee',
                        {
                            is: () => incentiveFeeType == 'carriedInterest',
                            then: Yup.number()
                                .required('Required')
                                .min(0, minNumberErrorMessage),
                        }
                    ),
                    ['managerCatchupPercentage']: Yup.number().when(
                        'hasIncentiveFee',
                        {
                            is: () => incentiveFeeType == 'carriedInterest',
                            then: Yup.number()
                                .required('Required')
                                .min(0, minNumberErrorMessage),
                        }
                    ),
                    ['performanceFeePercentage']: Yup.number().when(
                        'hasIncentiveFee',
                        {
                            is: () => incentiveFeeType == 'performance',
                            then: Yup.number()
                                .required('Required')
                                .min(0, minNumberErrorMessage),
                        }
                    ),
                }),
            }),
        }),
    });

    const onSubmit = useProductSubmit(productId, `product-description`);

    const handleSubmit = (values: Partial<ProductResponse>) => {
        if (values?.fees?.hasIncentiveFee === true) {
            // delete previously saved fees
            if (incentiveFeeType == 'carriedInterest') {
                delete values.fees.incentiveFee?.['performanceFeePercentage'];
            }
            if (incentiveFeeType == 'performance') {
                delete values.fees.incentiveFee?.['carryFeePercentage'];
                delete values.fees.incentiveFee?.['hurdleFeePercentage'];
                delete values.fees.incentiveFee?.['managerCatchupPercentage'];
            }
        } else if (values.fees && !values.fees.hasIncentiveFee) {
            // if hasIncentiveFee is false, then we need to remove the closedEndedInformation
            delete values.fees['incentiveFee'];
        }

        onSubmit(values);
    };

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{t('admin.product.product_fees')}</h1>
            <div className="my-4">
                <Formik
                    initialValues={formValue}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ values, isSubmitting }) => (
                        <Form>
                            <FormCheckbox
                                name={'fees.hasIncentiveFee'}
                                label={t('admin.product.does_the_product_have_an_incentive_fee')}
                                actionLabel={t('client.registration.kyc_compliance.yes')}
                            />

                            {values.fees?.hasIncentiveFee && (
                                <>
                                    <h3 className="text-sm font-normal leading-8 mb-3">
                                        {t('admin.product.what_type_of_incentive_fees')}
                                    </h3>
                                    <div className="my-4">
                                        <FormRadioGroup
                                            name="asdfasdfasdf"
                                            value={incentiveFeeType}
                                            disabled={isSubmitting}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setIncentiveFeeType(
                                                    e.target.value as
                                                        | 'carriedInterest'
                                                        | 'performance'
                                                );
                                            }}
                                            optionsData={incentiveFeesOptions}
                                        />
                                    </div>
                                    {incentiveFeeType === 'carriedInterest' && (
                                        <>
                                            <FormInputBox
                                                name="fees.incentiveFee.carryFeePercentage"
                                                label={`${t('product.fees.incentive_fee.carried_interest')} (%)`}
                                                type="number"
                                            />
                                            <FormInputBox
                                                name="fees.incentiveFee.hurdleFeePercentage"
                                                label={`${t('product.fees.incentive_fee.hurdle')} (%)`}
                                                type="number"
                                            />
                                            <FormInputBox
                                                name="fees.incentiveFee.managerCatchupPercentage"
                                                label={`${t('admin.product.manager_catchup')} (%)`}
                                                type="number"
                                            />
                                        </>
                                    )}
                                    {incentiveFeeType === 'performance' && (
                                        <>
                                            <FormInputBox
                                                name="fees.incentiveFee.performanceFeePercentage"
                                                label={`${t('product.fees.performance_fee')} (%)`}
                                                type="number"
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                pathPrevious="liquidity-information"
                                pathNext="product-description"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ProductFees;
