import { BaseUrl } from '@helpers/Environment';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { msalInstance } from '@libs/msal/msalInit';

const GENERATE_SPEC_FILES_URL = `${BaseUrl}/jobs/generate-spec-files`;

export const generateSpecFiles = async (subscriptionIds: string[]) => {
    const account = msalInstance?.getActiveAccount();
    const scopes = localStorage.getItem('AUTH_SCOPES');
    const authority = localStorage.getItem('AUTH_AUTHORITY');
    let accessToken = getLocalStorageAuthTokens()?.access?.token;
    if (account) {
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (accessToken = response?.accessToken))
            .catch((error) => {
                return (accessToken = undefined);
            });
    }

    const response = await fetch(GENERATE_SPEC_FILES_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
            'X-Subscription-Ids': subscriptionIds.join(','),
        },
    });

    return response;
};
