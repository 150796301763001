import { useTranslation } from 'react-i18next';
import {
    EndInvestorProfileResponse,
    SubscriptionResponse,
} from '@interfaces/Api';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useProduct } from '@stores/Products/useProduct';
import { isIntermediaryPwmGermany } from '@helpers/SubscriptionsHelper';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';

interface ReviewInvestmentDetailsProps {
    subscription?: SubscriptionResponse;
    endInvestorProfile: EndInvestorProfileResponse;
}

function ReviewInvestmentDetails(props: ReviewInvestmentDetailsProps) {
    const { t } = useTranslation();
    const { subscription } = props;
    const { intermediaries } = useIntermediaries();
    const { product } = useProduct(props.subscription?.productId);

    const distributor = intermediaries.find(
        (i) => i._id === props.endInvestorProfile?.intermediaryId
    )?.name;

    const getEndInvestorProfileUrl = (endInvestorProfileId?: string) => {
        return '../../clients/' + endInvestorProfileId;
    };

    const getSubscriptionUrl = (subscriptionId?: string) => {
        return '../' + subscriptionId;
    };

    if (!subscription) {
        return null;
    }

    return (
        <div className="text-base leading-5">
            <div className="pt-1">
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.product_name'
                    )}{' '}
                </strong>
                {product?.name}
            </div>
            <div className="pt-1">
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.share_class_name_type'
                    )}
                </strong>{' '}
                {subscription.shareClass?.name} {subscription.shareClass?.type}
            </div>
            <div className="pt-1">
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.distribution_fee'
                    )}
                </strong>{' '}
                {subscription.shareClass?.distributionFee}%
            </div>

            {isIntermediaryPwmGermany(distributor) && (
                <div className="pt-1">
                    <strong>{t('subscriptions.add.advisory_fee_title')}</strong>{' '}
                    {subscription?.advisoryFeePercentage}%
                </div>
            )}
            <div className="pt-1">
                <strong>{t('subscriptions.add.placement_fee_title')}</strong>{' '}
                {subscription?.placementFee}%
            </div>
            <div className="pt-1">
                <strong>
                    {t('client.registration.bank_accounts.currency')}:
                </strong>{' '}
                {subscription.shareClass?.currency}
            </div>
            <div className="pt-1">
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.minimum_investment'
                    )}
                </strong>{' '}
                {getFormattedCurrency(
                    subscription.shareClass?.minimumInvestment,
                    subscription.shareClass?.currency
                )}
            </div>
            <div className="pt-1">
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.investment_commitment'
                    )}
                </strong>{' '}
                {getFormattedCurrency(
                    props.subscription?.subscriptionAmount,
                    subscription.shareClass?.currency
                )}
            </div>
            {props.subscription?.nomineeInvestors &&
                props.subscription?.nomineeInvestors.length > 0 && (
                    <div className="pt-1">
                        <strong className="pb-2">
                            {t(
                                'subscriptions.add.subscription.review_subscription.nominee_investors'
                            )}
                        </strong>

                        {props.subscription?.nomineeInvestors.map(
                            (nomineeInvestor, i) => (
                                <div
                                    key={i.toString() + 'parent'}
                                    className="flex gap-2 justify-between w-2/5 p-1"
                                >
                                    <a
                                        href={getEndInvestorProfileUrl(
                                            nomineeInvestor.endInvestorProfileId
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        {nomineeInvestor.investorCode}
                                    </a>

                                    <a
                                        href={getSubscriptionUrl(
                                            nomineeInvestor.subscriptionId
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {getFormattedCurrency(
                                            nomineeInvestor.nomineeSubscriptionAmount,
                                            subscription.shareClass?.currency
                                        )}
                                    </a>
                                </div>
                            )
                        )}
                    </div>
                )}
        </div>
    );
}

export default ReviewInvestmentDetails;
