import {
    ErrorMessage,
    Field,
    FieldInputProps,
    FieldMetaProps,
    useFormikContext,
} from 'formik';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Extend dayjs with necessary plugins
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

export interface DatePickerProps {
    name: string;
    defaultValue?: string;
    min?: string;
    max?: string;
    label?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
    name,
    min = '1900-01-01',
    max = '2050-01-01',
    label,
    onChange,
    onBlur,
    disabled,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();

    const formatAndConstrainDate = (dateStr: string) => {
        const parsedDate = dayjs(dateStr);
        if (!parsedDate.isValid()) return dateStr;

        const year = parsedDate.year();
        const boundedYear = Math.min(
            Math.max(year, parseInt(min.split('-')[0])),
            parseInt(max.split('-')[0])
        );
        return parsedDate.year(boundedYear).format('YYYY-MM-DD');
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const constrainedValue = formatAndConstrainDate(e.target.value);
        setFieldValue(name, constrainedValue); // Using Formik's setFieldValue to update the value
        onBlur && onBlur(e); // If an external onBlur handler is provided, call it
    };
    const formatDate = (dateStr: string) => {
        // Format Date Time to Date only
        if (!dateStr || (dateStr && !dateStr.includes('Z'))) {
            return dateStr;
        }
        const parsedDate = dayjs(dateStr);
        return parsedDate.format('YYYY-MM-DD');
    };

    return (
        <div className="relative w-full mb-3 cursor-default">
            <Field name={name}>
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<string>;
                    meta: FieldMetaProps<string>;
                }) => (
                    <input
                        className={`min-w-full w-full ${
                            label ? 'h-16 pt-5' : 'h-12'
                        } pl-3 pr-3 placeholder-gray-300 border ${
                            meta.touched && meta.error
                                ? 'border-red-500 bg-red-50'
                                : 'border-gray-300'
                        } rounded-lg focus:shadow-outline`}
                        type="date"
                        id={name}
                        name={name}
                        {...field}
                        onChange={(e) => {
                            field.onChange(e); // Default Formik onChange handling
                            onChange && onChange(e); // If an external onChange handler is provided, call it
                        }}
                        onBlur={handleBlur}
                        min={min}
                        max={max}
                        disabled={disabled}
                        value={formatDate(field.value)}
                    />
                )}
            </Field>
            {label && (
                <div className="absolute left-1 top-2 text-xs flex items-center px-2 pointer-events-none">
                    {label}
                </div>
            )}
            <div className="flex content-start text-red-500 text-sm">
                <ErrorMessage name={name} component="div" />
            </div>
        </div>
    );
};

export default DatePicker;
