import { FC, useCallback, useMemo, useState } from 'react';
import { ClientEnum, IndividualDetails } from 'common';
import { Button } from '@components/Atoms';
import { ModalService } from '@services/ModalService';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DatePicker } from '@components/Organisms';
import { FormInputBox, FormSelect } from '@components/Molecules';
import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { FormNationalities } from '@components/Organisms/FormNationalities';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export type PersonalDetail = Pick<
    IndividualDetails,
    | 'dateOfBirth'
    | 'occupation'
    | 'countryCodeOfBirth'
    | 'nationalities'
    | 'salutation'
>;

interface IndividualDetailProps {
    onSubmit: (personalDetail: PersonalDetail) => void;
}

export const IndividualDetail: FC<IndividualDetailProps> = ({ onSubmit }) => {
    const { isClient } = useConfigurationState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const [showAddNationalityForm, setShowAddNationalityForm] =
        useState<boolean>(false);
    const handleSubmit = useCallback(
        (value: PersonalDetail & { hgId?: string }) => {
            setIsLoading(true);
            onSubmit(value);
        },
        [onSubmit]
    );

    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                dateOfBirth: Yup.string().trim().required(
                        t(
                            'client.registration.personal_details.date_of_birth.required_validation_message'
                        )
                    ),
                countryCodeOfBirth: Yup.string().trim().required(
                        t(
                            'client.registration.personal_details.country_of_birth.required_validation_message'
                        )
                    ),
                townOfBirth: Yup.string().trim().required(
                        t(
                            'client.registration.personal_details.town_of_birth.required_validation_message'
                        )
                    ),
                nationalities: Yup.array()
                    .min(1)
                    .of(
                        Yup.object().shape({
                            countryCode: Yup.string().trim().required(
                                    t(
                                        'client.registration.personal_details.nationality.country_code.required_validation_message'
                                    )
                                ),
                            // idType
                            idType: Yup.string().trim().required(
                                    t(
                                        'client.registration.personal_details.nationality.id_type.required_validation_message'
                                    )
                                ),
                            nationalIdNumber: Yup.string().trim().required(
                                    t(
                                        'client.registration.personal_details.nationality.national_id_number.required_validation_message'
                                    )
                                ),

                            expiryDate: Yup.string().trim().required(
                                    'client.registration.personal_details.nationality.expiryDate.required_validation_message'
                                ),
                        })
                    ),
            }),
        []
    );

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{
                    countryCodeOfBirth: '',
                    townOfBirth: '',
                    nationalities: [
                        {
                            countryCode: '',
                            nationalIdNumber: '',
                        },
                    ],
                    occupation: '',
                    salutation: '',
                }}
                validateOnMount
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formProps) => (
                    <Form>
                        {isClient(ClientEnum.HG) && (
                            <FormInputBox
                                name="hgId"
                                label="Hg Id"
                                placeholder="Your HG ID, if you already have a relationship with Hg"
                            />
                        )}
                        <FormInputBox
                            name={
                                getClientRegistrationFormFields(t).salutation
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).salutation
                                    .label
                            }
                            placeholder={
                                getClientRegistrationFormFields(t).salutation
                                    .placeHolder
                            }
                        />
                        <DatePicker
                            name={
                                getClientRegistrationFormFields(t).dateOfBirth
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).dateOfBirth
                                    .label
                            }
                            max={dayjs().format('YYYY-MM-DD')}
                        />
                        <FormInputBox
                            name={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .name
                            }
                            label={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .label
                            }
                            placeholder={
                                getClientRegistrationFormFields(t).townOfBirth
                                    .label
                            }
                        />
                        <FormSelect
                            name={
                                getClientRegistrationFormFields(t)
                                    .countryCodeOfBirth.name
                            }
                            label={
                                getClientRegistrationFormFields(t)
                                    .countryCodeOfBirth.label
                            }
                            optionsData={isoCountriesOptions}
                        />
                        <FormInputBox
                            name="occupation"
                            label="Occupation"
                            placeholder="Occupation"
                        />

                        <h2 className="font-bold pt-2">Nationalities</h2>
                        <FormNationalities name={`nationalities`} />
                        <div className="flex justify-between mt-4 border-t pt-4 border-sky-100">
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    ModalService.getInstance().hideModal();
                                }}
                                label={t('ui.controls.cancel')}
                            />
                            <Button
                                label={t('ui.controls.next')}
                                type="submit"
                                isLoading={isLoading}
                                disabled={!formProps.isValid || isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
