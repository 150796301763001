import { FormInputBox, FormSelect } from '@components/Molecules';
import { DatePicker } from '@components/Organisms';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { isoCountriesOptions } from '@helpers/isoCountries';
import { useTranslation } from 'react-i18next';
import { Gender, MaritalStatus } from 'common';
import dayjs from 'dayjs';

export const PersonalDetailsSelection = () => {
    const { t } = useTranslation();

    const genderOptions = [
        {
            label: t('client.invite.male'),
            value: Gender.Male,
        },
        {
            label: t('client.invite.female'),
            value: Gender.Female,
        },
    ];

    const maritalStatusOptions = [
        {
            label: t('client.invite.marital_status.single'),
            value: MaritalStatus.Single,
        },
        {
            label: t('client.invite.marital_status.married'),
            value: MaritalStatus.Married,
        },
        {
            label: t('client.invite.marital_status.civil_partnered'),
            value: MaritalStatus.CivilPartnered
        },
        {
            label: t('client.invite.marital_status.divorced'),
            value: MaritalStatus.Divorced
        },
        {
            label: t('client.invite.marital_status.widowed'),
            value: MaritalStatus.Widowed
        }
    ];

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.personal_details.title')}
            </h2>
            <FormInputBox
                name="salutation"
                label={getClientRegistrationFormFields(t).salutation.label}
                placeholder={
                    getClientRegistrationFormFields(t).salutation.placeHolder
                }
            />
            <FormInputBox
                name="firstName"
                label={getClientRegistrationFormFields(t).firstName.label}
                placeholder={getClientRegistrationFormFields(t).firstName.label}
            />
            <FormInputBox
                name="lastName"
                label={getClientRegistrationFormFields(t).lastName.label}
                placeholder={getClientRegistrationFormFields(t).lastName.label}
            />
            <FormSelect
                name={getClientRegistrationFormFields(t).gender.name}
                label={getClientRegistrationFormFields(t).gender.label}
                optionsData={genderOptions}
            />
            <FormSelect
                name={getClientRegistrationFormFields(t).maritalStatus.name}
                label={getClientRegistrationFormFields(t).maritalStatus.label}
                optionsData={maritalStatusOptions}
            />
            <DatePicker
                name={getClientRegistrationFormFields(t).dateOfBirth.name}
                label={getClientRegistrationFormFields(t).dateOfBirth.label}
                max={dayjs().format('YYYY-MM-DD')}
            />
            <FormInputBox
                name={getClientRegistrationFormFields(t).occupation.name}
                label={getClientRegistrationFormFields(t).occupation.label}
                placeholder={getClientRegistrationFormFields(t).occupation.label}
            />
            <FormInputBox
                name={getClientRegistrationFormFields(t).townOfBirth.name}
                label={getClientRegistrationFormFields(t).townOfBirth.label}
                placeholder={
                    getClientRegistrationFormFields(t).townOfBirth.label
                }
            />
            <FormSelect
                name={
                    getClientRegistrationFormFields(t).countryCodeOfBirth.name
                }
                label={
                    getClientRegistrationFormFields(t).countryCodeOfBirth.label
                }
                optionsData={isoCountriesOptions}
            />
        </>
    );
};
