import {
    BookingCentreResponse,
    CreateBookingCentreRequestBody,
} from '@interfaces/Api';
import { Http } from '@libs/http';
import { BaseUrl } from '@helpers/Environment';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { BookingCentresStore } from './BookingCentres.store';

const URL = `${BaseUrl}/booking-centre`;

export const getBookingCentres = async (intermediaryId: string, rmId?: string) => {
    const GET_URL = `${URL}/${intermediaryId}${rmId ? `?rmId=${rmId}` : ''}`;
    return await Http.get<BookingCentreResponse[]>(GET_URL);
};

export const handleAddBookingCentre = async (
    set,
    bookingCentre: CreateBookingCentreRequestBody
) => {
    const POST_URL = `${URL}`;

    try {
        const response: BookingCentreResponse = await Http.post(
            POST_URL,
            bookingCentre
        );

        set(
            (state: BookingCentresStore) => ({
                bookingCentres: [...state.bookingCentres, response],
            }),
            false,
            'addBookingCentre'
        );

        notifySuccess('Booking centre added successfully', 3000);
    } catch (_) {
        notifyError(
            'An error has occured, a new booking centre has not been added'
        );
    }
};

export const handleDeleteBookingCentre = async (
    set,
    bookingCentreId: string
) => {
    const DELETE_URL = `${URL}/${bookingCentreId}`;

    try {
        const bookingCentreToDelete: BookingCentreResponse = await Http.delete(
            DELETE_URL
        );

        set(
            (state: BookingCentresStore) => ({
                bookingCentres: [
                    ...state.bookingCentres.filter(
                        (bookingCentre) =>
                            bookingCentre._id !== bookingCentreToDelete?._id
                    ),
                ],
            }),
            false,
            'deleteBookingCentre'
        );

        notifySuccess('Booking centre deleted successfully', 3000);
    } catch (_) {
        notifyError(
            'An error has occured, the booking centre has not been deleted'
        );
    }
};

export const handleUpdateBookingCentre = async (
    set,
    bookingCentre: CreateBookingCentreRequestBody
) => {
    const PUT_URL = `${URL}`;

    try {
        const response: BookingCentreResponse = await Http.put(
            PUT_URL,
            bookingCentre
        );

        set(
            (state: BookingCentresStore) => ({
                bookingCentres: [
                    ...state.bookingCentres.map((bc) =>
                        bc._id === response._id ? response : bc
                    ),
                ],
            }),
            false,
            'updateBookingCentre'
        );

        notifySuccess('Booking centre updated successfully', 3000);
    } catch (_) {
        notifyError(
            'An error has occured, the booking centre has not been updated'
        );
    }
};
