import { isIntermediaryPwmGermany } from '@helpers/SubscriptionsHelper';
import { CurrentUser } from '@interfaces/Api';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { AuthRoleEnum, ClientEnum } from 'common';

export const useRmDetermination = (
    client: ClientEnum,
    currentUser: CurrentUser
): {
    isOddoPWMFrance: Boolean;
    isOddoPWMGermany: Boolean;
    isOddoIFA: Boolean;
} => {
    const { intermediaries } = useIntermediaries();

    const intermediary = intermediaries?.find(
        (intermediary) => intermediary._id === currentUser.intermediaryId
    );

    if (!intermediary)
        return {
            isOddoPWMFrance: false,
            isOddoPWMGermany: false,
            isOddoIFA: false,
        };

    if (client !== ClientEnum.ODDO)
        return {
            isOddoPWMFrance: false,
            isOddoPWMGermany: false,
            isOddoIFA: false,
        };

    if (
        [
            AuthRoleEnum.relationshipManager,
            AuthRoleEnum.externalRelationshipManager,
            AuthRoleEnum.intermediaryMiddleOffice,
            AuthRoleEnum.intermediaryCompliance,
            AuthRoleEnum.superUser,
        ].includes(currentUser.role)
    ) {
        if (intermediary.name.toLowerCase().includes('ifa - cgp')) {
            return {
                isOddoPWMFrance: true,
                isOddoPWMGermany: false,
                isOddoIFA: true,
            };
        }
        if (intermediary.name.toLowerCase().includes('pwm france')) {
            return {
                isOddoPWMFrance: true,
                isOddoPWMGermany: false,
                isOddoIFA: false,
            };
        }

        if (isIntermediaryPwmGermany(intermediary.name)) {
            return {
                isOddoPWMFrance: false,
                isOddoPWMGermany: true,
                isOddoIFA: false,
            };
        }
    }

    // just to make sure that this case is only true for pmw germany admins
    const isPwmGermanyAdmin =
        currentUser.role === AuthRoleEnum.administrator &&
        isIntermediaryPwmGermany(intermediary.name);
    if (isPwmGermanyAdmin) {
        return {
            isOddoPWMFrance: false,
            isOddoPWMGermany: true,
            isOddoIFA: false,
        };
    }

    return {
        isOddoPWMFrance: false,
        isOddoPWMGermany: false,
        isOddoIFA: false,
    };
};
