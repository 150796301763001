import { Formik, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { eventStreamPost } from '@api/EventStream';
import { NoticeType, noticeTypeLabels } from '@interfaces/Api';
import { EndInvestorSubscriptionsProfile } from '@interfaces/Api/EndInvestorProfileResponse';
import { EventStreamRequest } from '@interfaces/Api/EventStreamRequest';
import {
    SubscriptionNotice,
    SubscriptionPaidNotice,
} from '@interfaces/Api/SubscriptionNotice';
import { EventTypeEnum, EventTypeLabelEnum } from '@interfaces/eventType.enum';
import { TaskStatusEnum } from '@interfaces/tasks.enum';

import { useAuthState } from '@contexts/AuthContext';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscriptionsActions } from '@stores/Subscriptions';

import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { Button } from '@components/Atoms';
import { FormInputBox, Modal, TextAreaBox } from '@components/Molecules';
import { DatePicker, FileUploadAsync } from '@components/Organisms';

import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';

import capitalCallLabels from '../Templates/capitalCallLabels';
import CapitalCallNoticeList from './CapitalCallNoticeList';
import SubscriptionList from './SubscriptionList';

export interface ReviewClientSubscriptionsListProps {
    endInvestorProfile: EndInvestorSubscriptionsProfile;
}

const ReviewClientSubscriptionsList: React.FC<
    ReviewClientSubscriptionsListProps
> = (props: ReviewClientSubscriptionsListProps) => {
    const params = useParams();
    const { endInvestorProfile } = props;
    const { subscriptions } = endInvestorProfile;
    const { eventType } = params;
    const isCapitalCall =
        eventType === EventTypeLabelEnum.CAPITAL_CALL_NOTICE_EVENT;
    const capitalCallType = isCapitalCall
        ? NoticeType.CapitalCall
        : NoticeType.CapitalCallPaid;

    const { hasPermissions, currentUser } = useAuthState();
    const { products } = useProducts();
    const { fetchSubscriptions } = useSubscriptionsActions();

    const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false);

    const [selectedSubscription, setSubscription] =
        useState<SubscriptionListItem>();
    const [selectedNotice, setNotice] = useState<SubscriptionNotice>();

    const capitalCallAmount = useMemo(() => {
        if (selectedNotice) {
            return selectedNotice.amount;
        }
        return undefined;
    }, [selectedNotice]);

    const hideModal = () => setShowNoticeModal(false);

    const closeModalOnSubmit = async () => setShowNoticeModal(false);

    const subscriptionListItems = useCallback(() => {
        return subscriptions.map((subscription) => {
            const product = products.find(
                (val) => val._id === subscription?.productId
            );

            const endInvestor = endInvestorProfile;

            return {
                notices: subscription?.notices,
                shareClass: subscription?.shareClass,
                subscriptionId: subscription?._id || '',
                subscriptionUrl: `../subscriptions/${subscription?._id}/`,
                subscriptionStatus: subscription?.status,
                endInvestorName: getEndInvestorName(endInvestor),
                endInvestor,
                productName: product?.name,
                shareClassName: subscription?.shareClass?.name,
                subscriptionAmountFormatted: getFormattedCurrency(
                    subscription?.subscriptionAmount,
                    subscription?.subscriptionCurrencyCode
                ),
                subscriptionAmount: subscription?.subscriptionAmount,
                subscriptionCurrencyCode:
                    subscription?.subscriptionCurrencyCode,
                acceptedSubscriptionAmount:
                    subscription?.acceptedSubscriptionAmount,
                status: subscription?.status,
                subscription,
                product,
            } as SubscriptionListItem;
        });
    }, [subscriptions, products, endInvestorProfile, hasPermissions]);

    const subscriptionListItemsData = subscriptionListItems();

    const initialValues: SubscriptionPaidNotice = {
        amount: 0,
        amountPaid: 0,
        noticeType: capitalCallType,
        uploadedAt: new Date(),
        uploadedBy: currentUser?.user._id || undefined,
        dueDate: undefined,
        documents: [],
        comment: '',
    };

    const getTotalCapitalCallAmt = (): number => {
        const amt =
            selectedSubscription?.notices &&
            selectedSubscription?.notices.reduce(
                (sum, obj) => sum + obj['amount'],
                0
            );
        return amt || 0;
    };

    const uncalledAmount =
        (selectedSubscription?.acceptedSubscriptionAmount || 0) -
        getTotalCapitalCallAmt();

    const selectedValidationSchema = Yup.object().shape({
        [capitalCallLabels[capitalCallType].noticeType.name]: Yup.string(),
        [capitalCallLabels[capitalCallType].comment.name]: Yup.string(),
        [capitalCallLabels.CapitalCall.amount.name]: Yup.number().when(
            capitalCallLabels.CapitalCall.noticeType.name,
            {
                is: (val: string) => val === NoticeType.CapitalCall,
                then: (schema) =>
                    schema
                        .min(0.0001, 'Number should be more than zero')
                        .max(
                            uncalledAmount,
                            `The amount should be less than the total uncalled commitment of ${getFormattedCurrency(
                                uncalledAmount,
                                selectedSubscription?.shareClass?.currency
                            )}`
                        )
                        .required(),
                otherwise: (schema) => schema,
            }
        ),
        [capitalCallLabels.CapitalCallPaid.amount.name]: Yup.number().when(
            capitalCallLabels.CapitalCall.noticeType.name,
            {
                is: (val: string) => val !== NoticeType.CapitalCall,
                then: (schema) =>
                    schema
                        .min(0.0001)
                        .required('Number should be more than zero'),
                otherwise: (schema) => schema,
            }
        ),
    });

    const onSubmit = async (
        values: SubscriptionPaidNotice,
        { setSubmitting, resetForm }: FormikHelpers<SubscriptionPaidNotice>
    ) => {
        setSubmitting(true);

        const updateNotice = {
            func: eventStreamPost,
            successMessage:
                'Capital Call Notice has been created successfully!',
            failMessage: 'Failed to submit data!',
        };
        const eventData: EventStreamRequest = {
            eventType: isCapitalCall
                ? EventTypeEnum.CAPITAL_CALL_NOTICE_EVENT
                : EventTypeEnum.CAPITAL_CALL_PAID_EVENT,
            title: noticeTypeLabels[capitalCallType],
            parentEventId: isCapitalCall ? undefined : selectedNotice?.eventId,
            payload: {
                notice: {
                    ...values,
                    amount: isCapitalCall ? values.amount : values.amountPaid,
                    currencyCode:
                        selectedSubscription?.subscriptionCurrencyCode,
                },
                subscriptionId: selectedSubscription?.subscriptionId,
                subscriptionDetails: selectedSubscription,
            },
            ...(isCapitalCall && {
                completionStatus: TaskStatusEnum.COMPLETED,
            }),
            ...(currentUser?.user.firstName && {
                issuer: `${currentUser?.user.firstName} ${currentUser?.user.surname}`,
            }),
        };

        updateNotice
            .func(eventData)
            .then(async () => {
                notifySuccess(updateNotice.successMessage);
            })
            .catch(() => {
                notifyError(updateNotice.failMessage);
            })
            .finally(() => {
                resetForm();
                setSubmitting(false);
                setTimeout(closeModalOnSubmit, 300);
                fetchSubscriptions();
            });
    };

    return (
        <>
            <div className="leading-5 mb-6">
                {isCapitalCall ? (
                    <SubscriptionList
                        subscriptionListItems={subscriptionListItemsData}
                        buttonAction={(subscription) => {
                            setSubscription(subscription);
                            setShowNoticeModal(true);
                        }}
                    />
                ) : (
                    <CapitalCallNoticeList
                        subscriptionListItems={subscriptionListItemsData}
                        buttonAction={(notice, subscription) => {
                            setNotice(notice);
                            setSubscription(subscription);
                            setShowNoticeModal(true);
                        }}
                    />
                )}
            </div>
            <Modal
                show={showNoticeModal}
                onBackdropClick={hideModal}
                width="w-2/5"
            >
                <Modal.Header onClose={hideModal}>
                    {isCapitalCall
                        ? `Raise a Capital Call`
                        : 'Raise a Capital Call Paid Notice'}
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full mx-auto">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            enableReinitialize={true}
                            validationSchema={selectedValidationSchema}
                        >
                            {({
                                values,
                                isValid,
                                isSubmitting,
                                dirty,
                                ...actions
                            }) => (
                                <div className="mb-3" id="notice-form">
                                    <div className="my-4">
                                        <FormInputBox
                                            name={
                                                capitalCallLabels[
                                                    capitalCallType
                                                ].amount.name
                                            }
                                            type="number"
                                            label={
                                                capitalCallLabels[
                                                    capitalCallType
                                                ].amount.label
                                            }
                                            placeholder={`Enter the ${capitalCallLabels[capitalCallType].amount.label}.`}
                                            disabled={isSubmitting}
                                        />
                                        {!isCapitalCall &&
                                            dirty &&
                                            capitalCallAmount &&
                                            (values?.amountPaid <
                                                capitalCallAmount ||
                                                values?.amountPaid >
                                                    capitalCallAmount) && (
                                                <div className="flex content-start text-red-500 text-sm">
                                                    You are raising a
                                                    notification that you have
                                                    paid{' '}
                                                    {values?.amountPaid <
                                                        capitalCallAmount &&
                                                        'less'}
                                                    {values?.amountPaid >
                                                        capitalCallAmount &&
                                                        'more'}{' '}
                                                    than the amount called.
                                                </div>
                                            )}
                                    </div>
                                    {isCapitalCall && (
                                        <div className="my-4">
                                            <DatePicker
                                                name={'dueDate'}
                                                label={'Due Date'}
                                                defaultValue={values.dueDate?.toString()}
                                            />
                                        </div>
                                    )}

                                    <div className="my-4">
                                        <TextAreaBox
                                            name={
                                                capitalCallLabels[
                                                    capitalCallType
                                                ].comment.name
                                            }
                                            label={
                                                capitalCallLabels[
                                                    capitalCallType
                                                ].comment.label
                                            }
                                            rows={4}
                                            placeholder={'Your Comment'}
                                        />
                                    </div>
                                    {isCapitalCall && (
                                        <div className="my-4">
                                            <FileUploadAsync
                                                name="documents"
                                                hasLabel={true}
                                                disabled={isSubmitting}
                                            ></FileUploadAsync>
                                        </div>
                                    )}

                                    <div className="my-4 p-2 flex justify-between">
                                        <Button
                                            onClick={hideModal}
                                            buttonType="secondary"
                                            label="Cancel"
                                        />
                                        <Button
                                            type="submit"
                                            label="Submit"
                                            disabled={
                                                isSubmitting ||
                                                !(isValid && dirty)
                                            }
                                            onClick={(
                                                e: React.MouseEvent<HTMLButtonElement>
                                            ) => {
                                                e.preventDefault();
                                                if (isValid) {
                                                    onSubmit(values, actions);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReviewClientSubscriptionsList;
