import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    GridColDef,
    GridRowsProp,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    gridFilteredSortedRowIdsSelector,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import Grid from '@components/Atoms/Grid/Grid';
import { GetEndInvestorCannedReports } from '@api/CannedReports';
import { notifyError } from '@helpers/toastrHelper';
import { formatDate } from '@helpers/Date.helper';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';

const CannedReports: React.FC<{
    endInvestorProfileId?: string
}> = ({ endInvestorProfileId }) => {
    const { t } = useTranslation();
    const [gridRows, setGridRows] = useState<GridRowsProp>();
    const apiRef = useGridApiRef();

    useEffect(() => {
        (async () => {
            if (endInvestorProfileId) {
                try {
                    const response = await GetEndInvestorCannedReports(endInvestorProfileId);
                    setGridRows(response);
                } catch (error: any) {
                    console.error(error);
                }
            }
        })();
    }, [endInvestorProfileId]);
    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                />
            </GridToolbarContainer>
        );
    };

    const gridColumns: GridColDef[] = [
        {
            field: 'productName',
            headerName: `${t('subscriptions.add.subscription.review_subscription.product_name')}`,
        },
        {
            field: 'documentType',
            headerName: `${t('portfolio.canned_reports.documentType')}`,
        },
        {
            field: 'altoFlexObject',
            headerName: `${t('product.type')}`,
        },
        {
            field: 'dateDocument',
            headerName: `${t('portfolio.canned_reports.dateDocument')}`,
            valueGetter: (params) =>
                params.row.dateDocument && new Date(params.row.dateDocument),
            valueFormatter: ({ value }) => formatDate(value),
        },
        {
            field: 'shareClass',
            headerName: `${t('product.share_classes')}`,
        },
        {
            field: 'fileName',
            headerName: `${t('portfolio.canned_reports.report')}`,
            renderCell: (params) => {
                return (
                    <DocumentLinkRender href={params.row.url} title={params.value}>
                        {params.value}
                    </DocumentLinkRender>
                );
            },
        }

    ];

    return (
        <div>
            <h1 className="text-3xl text-logo-blue">
                {t('client.pill_selector.documents')}
            </h1>
            <div className="mt-6">
                {(gridRows && (
                    <Grid
                        name="documentsGrid"
                        apiRef={apiRef}
                        rows={gridRows}
                        slots={{ toolbar: customToolbar }}
                        columns={gridColumns}
                        slotProps={{
                            columnsPanel: {
                                disableHideAllButton: true,
                                disableShowAllButton: true,
                            },
                            toolbar: {
                                csvOptions: {
                                    getRowsToExport:
                                        gridFilteredSortedRowIdsSelector,
                                },
                            },
                        }}
                    />
                )) || <p>{t('client.portfolio.product.reports.no_documents_found')}</p>}
            </div>
        </div>
    )
}

export default CannedReports