import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const ContactDetailsSummary = () => {
    const { t } = useTranslation();
    const { data: currentState } = useRegisterInvestorState();

    return (
        <div>
            <span className="font-bold mr-2">{t('client.registration.summary.contact_details')}:</span>
            <div>
                <p>{currentState.individualDetails?.emailAddress}</p>
                <p>+{currentState.individualDetails?.phoneDiallingCode}{currentState.individualDetails?.phoneNumber}</p>
            </div>
            
        </div>
    );
};
