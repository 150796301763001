import React from 'react';
import { Document } from '@interfaces/Api/Document';
import { Icons } from '@components/Atoms';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';

type LinkTextType = 'label' | 'fileName';

interface DownloadLinkProps {
    document: Document;
    linkTextType?: LinkTextType;
    linkType?: 'primary' | 'secondary';
    iconPosition?: 'left' | 'right';
}

const getLinkText = (document: Document, linkTextType: LinkTextType) => {
    switch (linkTextType) {
        case 'fileName':
            return document.fileName;
        default:
            return document.label;
    }
};

export const DownloadLink: React.FC<DownloadLinkProps> = ({
    document,
    linkTextType,
    linkType,
    iconPosition,
}) => {
    const icon = (
        <span className="inline-block top-1 relative ml-3">
            <Icons name="ArrowDownTrayIcon" />
        </span>
    );
    return (
        <DocumentLinkRender
            href={document.url}
            target="_blank"
            rel="noreferrer"
            className={`hover:underline font-bold ${
                linkType === 'secondary' ? 'text-brand-navy' : ''
            }`}
        >
            {iconPosition === 'left' && icon}
            {getLinkText(document, linkTextType!)}
            {iconPosition === 'right' && icon}
        </DocumentLinkRender>
    );
};

DownloadLink.defaultProps = {
    linkTextType: 'label',
    linkType: 'primary',
    iconPosition: 'left',
};
