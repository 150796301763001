import { SubscriptionContact } from 'common';

interface ReviewContactsProps {
    contacts?: SubscriptionContact[];
}

function ReviewContacts(props: ReviewContactsProps) {
    return (
        <div className="text-base grid grid-cols-3 w-full ">
            {props.contacts?.map((contact, index) => (
                <div className="mb-3" key={index}>
                    <div>
                        <strong>Contact's Name: </strong>
                        {`${contact.salutation} ${contact.firstName} ${contact.lastName}`}
                    </div>
                    <div>
                        <strong>Title: </strong>
                        {contact.title}
                    </div>
                    <div>
                        <strong>Company: </strong>
                        {contact.companyName}
                    </div>
                    <div>
                        <div>
                            <strong>Address Line 1: </strong>
                            {contact.address.houseName +
                                ' ' +
                                contact.address.residentialLine1}
                        </div>
                        {contact.address.residentialLine2 !== '' && (
                            <div className="text-base">
                                <strong>Address Line 2: </strong>
                                {contact.address.residentialLine2}
                            </div>
                        )}
                        <div>
                            <strong>City: </strong>
                            {contact.address.city}
                        </div>
                        <div>
                            <strong>Postcode: </strong>
                            {contact.address.postCode}
                        </div>
                        <div>
                            <strong>Country: </strong>
                            {contact.address.countryCode}
                        </div>
                        <div>
                            <strong>Email: </strong>
                            {contact.email}
                        </div>
                        <div>
                            <strong>Phone Number: </strong>
                            {`${contact.phoneDiallingCode} ${contact.phoneNumber}`}
                        </div>
                        <div>
                            <strong>Relationship to Investor: </strong>
                            {contact.relationshipToInvestor}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ReviewContacts;
