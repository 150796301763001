import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useMountEffect } from '@hooks/useMountEffect';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import { useEffect, useState } from 'react';
import IncompleteClientRegistration from './IncompleteClientRegistration';

const IncompleteClientRegistrationContainer = () => {
    const { preferences } = useConfigurationState();
    const { currentUser } = useAuthState();

    const [loggedInClientSystemUser, setLoggedInClientSystemUser] =
        useState<SystemUserResponse>();
    const [invitedBySystemUser, setInvitedBySystemUser] =
        useState<SystemUserResponse>();

    const loadSystemUser = async (id) => await SystemUsersGet(id);

    useMountEffect(() => {
        loadSystemUser(currentUser.user._id).then((systemUser: any) => {
            const isIncompleteClientRegistration =
                systemUser.role == AuthRoleEnum.endInvestor &&
                (!systemUser.endInvestorProfileIds ||
                    systemUser.endInvestorProfileIds?.length <= 0);

            if (isIncompleteClientRegistration)
                setLoggedInClientSystemUser(systemUser);
        });
    });

    useEffect(() => {
        if (!loggedInClientSystemUser) return;

        loadSystemUser(
            loggedInClientSystemUser?.invitationLog?.[0]?.sentBy
        ).then((systemUser: any) => {
            setInvitedBySystemUser(systemUser);
        });
    }, [loggedInClientSystemUser]);

    return (
        <IncompleteClientRegistration
            allowInvitedClientToRegister={
                !!preferences?.feature.investorRegistrationProcess
                    ?.allowInvitedClientToRegister
            }
            authorizeRMToComplete={
                !!currentUser?.user.authorizeRMToComplete?.authorized
            }
            loggedInClientSystemUser={loggedInClientSystemUser}
            invitedBySystemUser={invitedBySystemUser}
        />
    );
};

export default IncompleteClientRegistrationContainer;
