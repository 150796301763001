import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { isoCountries } from '@helpers/isoCountries';
import {
    ReviewClientSummaryDetailsProps,
    businessRelationShipsReviewTypeLabelMap,
} from '@interfaces/ReviewClientSummaryDetailsProps';

import { SystemUserResponse } from '@interfaces/Api';
import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReviewItem } from './ReviewItem';

const ReviewClientBusinessRelationshipDetails = ({
    data,
}: ReviewClientSummaryDetailsProps) => {
    const { t } = useTranslation();
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const profile = endInvestorProfiles.find(
        (profile) => profile._id === data?.nomineeEndInvestorProfileId
    );

    const [advisory, setAdvisory] = useState<SystemUserResponse>();

    const loadSystemUser = async (id) => await SystemUsersGet(id);

    useEffect(() => {
        if (!data) return;

        loadSystemUser(data.adviserSystemUserId).then((systemUser: any) => {
            setAdvisory(systemUser);
        });
    }, [data]);

    return (
        <div className="leading-5 mb-4">
            {data?.bookingCentreIds && data?.bookingCentreIds.length > 0 && (
                <ReviewItem
                    label={`${t(
                        'subscriptions.business_relationship_details.review.booking_centres'
                    )}:`}
                    value={data?.bookingCentreIds
                        .map((bc) => bc.bookingCentreName)
                        .join()}
                />
            )}

            <ReviewItem
                label={`${t(
                    'subscriptions.business_relationship_details.review.service_level_type'
                )} :`}
                value={
                    data?.serviceLevelType
                        ? businessRelationShipsReviewTypeLabelMap[
                              data?.serviceLevelType
                          ]
                        : ''
                }
            />

            {advisory && (
                <ReviewItem
                    label={`${t(
                        'subscriptions.business_relationship_details.review.relationship_manager'
                    )} :`}
                    value={`${advisory.firstName} ${' '} ${advisory.surname}`}
                />
            )}

            <ReviewItem
                label={`${t(
                    'subscriptions.business_relationship_details.review.subscription_process_type'
                )} :`}
                value={
                    data?.subscriptionProcessType
                        ? businessRelationShipsReviewTypeLabelMap[
                              data?.subscriptionProcessType
                          ]
                        : ''
                }
            />

            {profile && (
                <ReviewItem
                    label={`${t(
                        'subscriptions.business_relationship_details.review.associated_nominee'
                    )} :`}
                    value={profile?.businessEntityDetails?.companyName}
                />
            )}

            <ReviewItem
                label={`${t(
                    'subscriptions.business_relationship_details.review.onboarding_jurisdiction'
                )} :`}
                value={
                    data?.onboardingJurisdictionCountryCode
                        ? toCapitalizedWords(
                              isoCountries[
                                  data?.onboardingJurisdictionCountryCode
                              ]
                          )
                        : ''
                }
            />

            <ReviewItem
                label={`${t(
                    'subscriptions.business_relationship_details.review.client_type'
                )} :`}
                value={
                    data?.clientType ? toCapitalizedWords(data?.clientType) : ''
                }
            />
        </div>
    );
};

export default ReviewClientBusinessRelationshipDetails;
