import { BaseUrl } from '@helpers/Environment';
import { DocusignInfo, DocusignConsentRequestBody } from '@interfaces/Api';
import { Http } from '../libs/http';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { msalInstance } from '@libs/msal/msalInit';

const API_DOCUSIGN_URL = `${BaseUrl}/docusign`;

export const docusignInformationGet = async (): Promise<DocusignInfo> =>
    Http.get(`${API_DOCUSIGN_URL}/information`);

export const docusignConsentPost = async (
    body: DocusignConsentRequestBody
): Promise<DocusignInfo> => Http.post(`${API_DOCUSIGN_URL}/consent`, body);

export const docusignEnvelopeGet = async (
    envelopeId: string,
    documentIndex: number
) => {

    // Get Token either from local storage or msal
    const account = msalInstance?.getActiveAccount();
    const scopes = localStorage.getItem('AUTH_SCOPES');
    const authority = localStorage.getItem('AUTH_AUTHORITY');
    let token = getLocalStorageAuthTokens()?.access?.token;
    if (account) {
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (token = response?.accessToken))
            .catch((error) => {
                return (token = undefined);
            });
    }
    const url = `${API_DOCUSIGN_URL}/envelope?envelopeId=${envelopeId}`;
    const response = await fetch(
        url,

        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
            },
        }
    );

    return response;
};

export const removeSecondSigner = async (subscriptionId: string) => {
    return Http.delete(`${API_DOCUSIGN_URL}/second-signer`, undefined, {
        subscriptionId,
    });
};
