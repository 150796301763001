import { PdfViewer } from '@components/Organisms';
import { ModalService } from '@services/ModalService';
import { WarningTextType } from 'common';
import { downloadFile } from './File.helper';

export const openPopup = (text: string, warningTextType?: WarningTextType) => {
    let content;

    if (warningTextType === WarningTextType.Pdf) {
        content = <PdfViewer fileURL={text} enableControls compact />;
    } else {
        content = <div dangerouslySetInnerHTML={{ __html: text }}></div>;
    }

    ModalService.getInstance()
        .setShowHeader(false)
        .setShowFooter(true)
        .setWidth('w-auto')
        .setBodyStyle({ maxHeight: 'calc(100vh - 52px)' });

    if (warningTextType === WarningTextType.Pdf) {
        ModalService.getInstance()
            .setHideCancelBtn(false)
            .setCancelLabel('Close')
            .setConfirmationLabel('Download')
            .setConfirmCallback(() => {
                downloadFile(text);
                ModalService.getInstance().hideModal();
            });
    } else {
        ModalService.getInstance()
            .setHideCancelBtn(true)
            .setConfirmationLabel('Close')
            .setConfirmCallback(() => {
                ModalService.getInstance().hideModal();
            });
    }
    ModalService.getInstance().setComponent(content).showModal();
};
