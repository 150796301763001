import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SubscriptionOutletContext } from '@contexts/OutletContext';
import { Button, Icons } from '@components/Atoms';
import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';

interface StepperSubscriptionsFormikSubmitProps {
    isSubscriptionReadOnly: boolean;
    disabled?: boolean | undefined;
    saveButtonOnClick?: () => void; // Or use Function
    saveLabel?: string;
    loading?: boolean;
}

const SubscriptionsFormikSubmitBtns: React.FC<
    StepperSubscriptionsFormikSubmitProps
> = (props) => {
    const { t } = useTranslation();
    const { data } = useOutletContext<SubscriptionOutletContext>();

    const { showNextStep, goToNextStep, showPreviousStep, goToPreviousStep } =
        useSubscriptionPageContext();

    const isSubscription = data?.status;
    const readOnly = isSubscription ? props.isSubscriptionReadOnly : false;
    const saveLabel =
        props.saveLabel || t('subscriptions.add.save_and_continue_button');

    return (
        <div className="flex flex-row justify-between mt-10">
            <div>
                {showPreviousStep && (
                    <Button
                        buttonType="secondary"
                        type="button"
                        label={t('subscriptions.add.back_button')}
                        onClick={() => {
                            goToPreviousStep && goToPreviousStep();
                        }}
                    />
                )}
            </div>
            <div>
                {showNextStep &&
                    (readOnly ? (
                        <Button
                            type="button"
                            label={t('subscriptions.add.next_button')}
                            disabled={props.disabled}
                            onClick={() => {
                                goToNextStep && goToNextStep();
                            }}
                        />
                    ) : (
                        <Button
                            type="submit"
                            onClick={() => {
                                if (props.saveButtonOnClick) {
                                    props.saveButtonOnClick();
                                }
                            }}
                            label={saveLabel}
                            disabled={props.disabled}
                        />
                    ))}
            </div>
        </div>
    );
};

export default SubscriptionsFormikSubmitBtns;
