import { formatDateTimeString } from '@helpers/Date.helper';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getSystemUserFullName } from '@helpers/SystemUser.helper';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { useTranslation } from 'react-i18next';
import { ReviewItem } from './ReviewItem';

const ReviewClientContactDetails = (props: ReviewClientSummaryDetailsProps) => {
    const { t } = useTranslation();

    return (
        <div className="leading-5 mb-4">
            <ReviewItem
                label={`${t(
                    'subscriptions.contact_details.review.email_address'
                )}: `}
                value={props.data?.individualDetails?.emailAddress}
            />

            <div className="text-base">
                <strong>
                    {t('subscriptions.contact_details.review.phone_number')}:
                </strong>
                {props.data?.individualDetails?.phoneDiallingCode}
                {props.data?.individualDetails?.phoneNumber}
            </div>

            <div className="text-base">
                <strong>
                    {t(
                        'subscriptions.contact_details.review.additional_contact_information'
                    )}
                    :
                </strong>
                {props.data?.individualDetails?.additionalContactInformation}
            </div>

            {props.linkedSystemUser && (
                <>
                    {props.data?.individualDetails?.emailAddress !==
                        props.linkedSystemUser?.email && (
                        <ReviewItem
                            label={`${t(
                                'subscriptions.contact_details.review.login_email'
                            )}: `}
                            value={props.linkedSystemUser?.email}
                        />
                    )}

                    {getSystemUserFullName(props.linkedSystemUser) !==
                        getEndInvestorName(props.data) && (
                        <ReviewItem
                            label={`${t(
                                'subscriptions.contact_details.review.login_name'
                            )}: `}
                            value={getSystemUserFullName(
                                props.linkedSystemUser
                            )}
                        />
                    )}

                    {props.linkedSystemUser?.lastLoginDate && (
                        <ReviewItem
                            label={`${t(
                                'subscriptions.contact_details.review.last_login'
                            )}: `}
                            value={formatDateTimeString(
                                props.linkedSystemUser?.lastLoginDate
                            )}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ReviewClientContactDetails;
