import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';
import { AmlKycRequirements } from 'common';

const API_TASKS_URL = `${BaseUrl}/aml-kyc-requirements`;

export const getAllAmlKycRequirements = async (
    selectedLanguage: string
): Promise<AmlKycRequirements> =>
    Http.get(API_TASKS_URL + '?language=' + selectedLanguage);
