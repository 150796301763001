import React from 'react';

export const RecursiveErrorDisplay: React.FC<{ errors: any }> = ({
    errors,
}) => {
    const renderError = (error, path = '') => {
        if (Array.isArray(error)) {
            // Handling arrays of errors
            return error.map((item, index) =>
                renderError(item, `${path}-${index}`)
            );
        } else if (typeof error === 'object' && error !== null) {
            // Handling objects, including nested error objects
            return Object.keys(error).map((key) =>
                renderError(error[key], `${path}-${key}`)
            );
        } else {
            // Handling direct error messages
            return <p key={path}>{error}</p>;
        }
    };
    if (!errors) {
        return null;
    }
    return (
        <div className="text-sm text-red-500">
            {typeof errors === 'string' && <p>{errors}</p>}
            {typeof errors !== 'string' &&
                Object.keys(errors).map((key) => renderError(errors[key], key))}
        </div>
    );
};
