import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EndInvestorProfileResponse } from '@interfaces/Api';

import {
    AuthRoleEnum,
    ClientEnum,
    CompletenessCheck,
    FrenchInvestorRegistrationType,
} from 'common';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import {
    useSubscriptionsActions,
    useSubscriptionsState,
} from '@stores/Subscriptions';
import InformationRequest from './InformationRequest';
import IntermediaryMiddleOfficeReview from './IntermediaryMiddleOfficeReview';
import KYCAMLReview from './KYCAMLReview';
import RiskReview from './RiskReview';
import { showAmlKycReview } from './SubscriptionReview.logic';

const Subscription = (props: {
    endInvestorProfile: EndInvestorProfileResponse;
}) => {
    const { hasPermissions, hasRoles } = useAuthState();
    const { subscriptionId } = useParams();
    const { subscription } = useSubscriptionsState();
    const { subscriptionCompletenessCheck } = useSubscriptionsActions();
    const { isClient } = useConfigurationState();

    const isOddo = isClient(ClientEnum.ODDO);

    const completenessCheckStatus = useMemo(
        () => subscription?.completenessChecks?.status,
        [subscription]
    );
    const isEndInvestorNominativePure = useMemo(
        () =>
            props.endInvestorProfile?.additionalInfo
                ?.frenchInvestorRegistrationType ===
            FrenchInvestorRegistrationType.registered,
        [props.endInvestorProfile]
    );

    const onSubscriptionCompletenessCheck = useCallback(
        async ({ type, decision, comments }: Partial<CompletenessCheck>) => {
            try {
                if (!subscriptionId) return;
                await subscriptionCompletenessCheck({
                    body: {
                        type,
                        decision: String(decision),
                        comments,
                        createdAt: new Date(),
                    },
                    subscriptionId,
                });
                return notifySuccess('Review completed successfully');
            } catch (err) {
                return notifyError(
                    'A problem has occurred when reviewing this application'
                );
            }
        },
        [subscriptionCompletenessCheck, subscriptionId]
    );

    const subscriptionReviewComponents = useMemo(() => {
        const items: {
            component: any;
            showItem: boolean;
        }[] = [
            {
                component: (
                    <IntermediaryMiddleOfficeReview
                        isEndInvestorNominativePure={
                            isEndInvestorNominativePure
                        }
                        onSubscriptionCompletenessCheck={
                            onSubscriptionCompletenessCheck
                        }
                    />
                ),
                showItem: isOddo && !hasRoles([AuthRoleEnum.endInvestor]),
            },
            {
                component: (
                    <RiskReview
                        onSubscriptionCompletenessCheck={
                            onSubscriptionCompletenessCheck
                        }
                        isEndInvestorNominativePure={
                            isEndInvestorNominativePure
                        }
                    />
                ),
                showItem: isOddo,
            },
            {
                component: (
                    <KYCAMLReview
                        onSubscriptionCompletenessCheck={
                            onSubscriptionCompletenessCheck
                        }
                        showItem={
                            //TODO: Refactor back to show item
                            isOddo &&
                            completenessCheckStatus &&
                            showAmlKycReview({
                                subscription,
                                completenessCheckStatus,
                                hasPermissions,
                            })
                        }
                    />
                ),
                showItem: isOddo,
            },
            {
                component: <InformationRequest />,
                showItem: isOddo,
            },
        ];
        return items.filter((a) => a.showItem);
    }, [
        isEndInvestorNominativePure,
        onSubscriptionCompletenessCheck,
        isOddo,
        completenessCheckStatus,
        subscription,
        hasPermissions,
        hasRoles,
    ]);

    return (
        <>
            {subscriptionReviewComponents.map(
                (subscriptionReviewComponent, i) => (
                    <div key={i}>{subscriptionReviewComponent.component}</div>
                )
            )}
        </>
    );
};

export default Subscription;
