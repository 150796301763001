import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';
import {
    IntermediaryResponse,
    SigningDocumentTypeEnum,
    SubscriptionStatusEnum,
} from '@interfaces/Api';
import { SubscriptionFiltersEnum } from '@interfaces/Api/SubscriptionStatus.enum';
import { isDocumentSigned } from '@services/SubscriptionsHelper';

export const filterSubscriptions = ({
    subscription,
    selectedStatuses,
}: {
    subscription: SubscriptionListItem;
    selectedStatuses: string[];
}) => {
    const readyForReviewFilter = Boolean(
        subscription.subscriptionStatus ===
            SubscriptionStatusEnum.readyForReview
    );
    const readyToApproveFilter = Boolean(
        subscription.subscriptionStatus ===
            SubscriptionStatusEnum.readyForReview &&
            subscription.signingDocuments &&
            subscription.signingDocuments.length > 0 &&
            isDocumentSigned(subscription, SigningDocumentTypeEnum.Subscription)
    );
    const awaitingSignaturesFilter =
        subscription.signingDocuments &&
        subscription.signingDocuments.length > 0 &&
        !isDocumentSigned(subscription, SigningDocumentTypeEnum.Subscription);

    if (selectedStatuses?.length > 0) {
        const filters: { [key in SubscriptionFiltersEnum]: () => boolean } = {
            [SubscriptionFiltersEnum.readyForReview]: () =>
                readyForReviewFilter,
            [SubscriptionFiltersEnum.readyToApprove]: () =>
                readyToApproveFilter,
            [SubscriptionFiltersEnum.awaitingSignatures]: () =>
                awaitingSignaturesFilter || false,
        };

        return selectedStatuses.some((selectedStatus) =>
            filters[selectedStatus as SubscriptionFiltersEnum]()
        );
    }

    return true;
};


export const isIntermediaryPwmGermany = (
    name: string
) => {
    const result = (name || '')
        .toLowerCase()
        .includes('pwm germany');
    return result;
};
