import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { EndInvestorProfileResponse } from '@interfaces/Api';

import { DownloadLink } from '@components/Molecules';
import { useTranslation } from 'react-i18next';

interface ReviewKYCDetailsProps {
    isKycCompliant?: EndInvestorProfileResponse;
}

const ReviewClientKYCDetails = (
    props: ReviewClientSummaryDetailsProps & ReviewKYCDetailsProps
) => {
    const { t } = useTranslation();

    return (
        <div className="leading-5 mb-4">
            <p className="text-base">
                <span className="font-bold">
                    {t('subscriptions.kyc_details.review.aml_kyc_reliance')}:{' '}
                </span>
                <span className="capitalize">
                    {props.data?.isKycCompliant
                        ? t(
                              'subscriptions.kyc_details.review.aml_kyc_reliance.yes'
                          )
                        : t(
                              'subscriptions.kyc_details.review.aml_kyc_reliance.no'
                          )}
                </span>
            </p>
            {props.data?.kycDocuments?.map((doc, i) => (
                <div key={`${i}-doc`}>
                    <DownloadLink document={doc} />
                </div>
            ))}
        </div>
    );
};

export default ReviewClientKYCDetails;
