import { ProspectiveInvestorsGetAll } from '@api/ProspectiveInvestors';
import { Icons } from '@components/Atoms';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useMountEffect } from '@hooks/useMountEffect';
import { EndInvestorProfileResponse } from '@interfaces/Api';

import { useState } from 'react';
import { getDisclosuresForProspectiveInvestor } from './ReviewDisclosures.helper';

interface ReviewDisclosuresProps {
    endInvestorProfile?: EndInvestorProfileResponse;
}

const ReviewDisclosures = (props: ReviewDisclosuresProps) => {
    const { investorCountryRequirementTree } = useConfigurationState();

    const [downloadableDocuments, setDownloadableDocuments] = useState<
        { src: string; label: string }[]
    >([]);

    useMountEffect(() => {
        ProspectiveInvestorsGetAll()
            .then((response) => {
                const prospectiveInvestor = response.find(
                    (a) =>
                        a.endInvestorProfileId == props.endInvestorProfile?._id
                );

                const documents = getDisclosuresForProspectiveInvestor(
                    investorCountryRequirementTree,
                    prospectiveInvestor
                );

                setDownloadableDocuments(documents);
            })
            .catch((err) => {
                console.error('err', err);
            });
    });

    return (
        <div className="leading-5 mb-4">
            <p className="text-base">
                {downloadableDocuments.length > 0 && (
                    <>
                        <span>
                            Please review the following disclosures and
                            documents:
                        </span>
                        <ul>
                            {downloadableDocuments.map((document, i) => (
                                <li key={i}>
                                    <a
                                        href={document.src}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span className="inline-block top-1 relative mr-2">
                                            <Icons name="ArrowDownTrayIcon" />
                                        </span>
                                        {document.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {downloadableDocuments.length === 0 && (
                    <span>There are no disclosures or documents to view.</span>
                )}
            </p>
        </div>
    );
};

export default ReviewDisclosures;
