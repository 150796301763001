import { ITenantComponentProvider } from "./Pages/ITenantComponentProvider";
import { TenantComponentProviderFactory } from "./Pages/TenantComponentProviderFactory";
import { ClientEnum } from "common";

const Empty = () => <></>;

export const getComponent = (componentName: keyof ITenantComponentProvider) => {
    const client = localStorage.getItem('client') as ClientEnum || ClientEnum.S64;
    const tenantComponent =
        TenantComponentProviderFactory.getTenantComponentProvider(
            client
        );
    return tenantComponent[componentName]?.() || Empty;
}
