import React, { FC, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';
import { Button, InputBox } from '@components/Atoms';
import dayjs from 'dayjs';
import { FormFieldWrapper } from '@components/Molecules';
import { convertToDateIsoString, formatDate } from '@helpers/Date.helper';
import FormikLabels from './ModelProducts';
import { ProductResponse } from '@interfaces/Api';

export interface KeyDatesInterface {
    signedSubscriptionDeadline?: string;
    closingDate?: string;
    closingDescription?: string;
    _id?: string;
}

const DateInput: FC<{
    value?: string;
    defaultValue?: string;
    label?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: React.ChangeEvent<HTMLInputElement>): void;
    disabled?: boolean;
    hasError?: boolean;
}> = ({ value, defaultValue, label, disabled, onChange, onBlur, hasError }) => (
    <div className="relative w-full mb-3 cursor-default">
        <FormFieldWrapper label={label} name={''}>
            <InputBox
                type="date"
                min="1900-01-01"
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                label={label}
                name=""
                disabled={disabled}
                hasError={hasError}
            />
        </FormFieldWrapper>
    </div>
);

const FIELD_NAME = FormikLabels.formField.closedEndedInformation.keyDates.name;
interface ProductKeyDatesForm {
    product?: ProductResponse;
}

export const ProductKeyDatesForm: FC<ProductKeyDatesForm> = (props) => {
    const [field, meta] = useField<KeyDatesInterface[]>({
        name: FIELD_NAME,
    });
    const { t } = useTranslation();
    const {
        values,
        submitForm,
        setFieldValue,
        errors,
        setTouched,
    }: {
        values: any;
        submitForm: any;
        setFieldValue: any;
        errors: any;
        setTouched: any;
    } = useFormikContext();
    const [internalKeyDates, setInternalKeyDates] = useState<
        KeyDatesInterface[]
    >([]);

    useEffect(() => {
        const keyDates = field.value || [];

        if (keyDates && keyDates.length) {
            setInternalKeyDates(keyDates);
        }
    }, [field.value]);

    const dateChangeHandler = useCallback(
        (
            fieldName: keyof KeyDatesInterface,
            index: number,
            newValue: string
        ) => {
            const copyDates = [...internalKeyDates];
            copyDates[index][fieldName] = newValue
                ? convertToDateIsoString(newValue)
                : '';
            setInternalKeyDates(copyDates);
            setFieldValue(FIELD_NAME, copyDates);
        },
        [internalKeyDates, setFieldValue]
    );

    const closingDescriptionHandler = useCallback(
        (index: number, newValue: string) => {
            const copyDates = [...internalKeyDates];
            copyDates[index]['closingDescription'] = newValue;
            setInternalKeyDates(copyDates);
            setFieldValue(FIELD_NAME, copyDates);
        },
        [internalKeyDates, setFieldValue]
    );

    const handleNewDate = useCallback(() => {
        const newDates = [
            ...internalKeyDates,
            {
                signedSubscriptionDeadline: '',
                closingDate: '',
            },
        ];

        setInternalKeyDates(newDates);
        setFieldValue(FIELD_NAME, newDates);
    }, [internalKeyDates, setFieldValue]);

    const handleDelete = useCallback(
        (index) => {
            const newDates = [...internalKeyDates];
            newDates.splice(index, 1);
            setInternalKeyDates(newDates);
            setFieldValue(FIELD_NAME, newDates);
        },
        [internalKeyDates, setFieldValue]
    );

    return (
        <div>
            {internalKeyDates.map((internalKeyDate, index) => (
                <div
                    key={
                        internalKeyDate?._id ||
                        internalKeyDate.closingDate + index
                    }
                >
                    <div className="flex justify-between">
                        <label>Key Date {index + 1}</label>
                        <Button
                            label={`Remove Key Date ${index + 1}`}
                            type="button"
                            onClick={() => handleDelete(index)}
                        />
                    </div>
                    <div className="my-4">
                        <DateInput
                            defaultValue={formatDate(
                                internalKeyDate.signedSubscriptionDeadline
                            )}
                            label={t('product.signed_subscription_deadline')}
                            onBlur={(event) => {
                                dateChangeHandler(
                                    'signedSubscriptionDeadline',
                                    index,
                                    event.target.value
                                );
                            }}
                            hasError={
                                errors?.closedEndedInformation?.keyDates &&
                                Boolean(
                                    errors.closedEndedInformation?.keyDates[
                                        index
                                    ]
                                )
                            }
                        />
                    </div>
                    <div className="relative w-full mb-3 cursor-default">
                        <FormFieldWrapper
                            label={t('product.closing_description')}
                            name={''}
                        >
                            <InputBox
                                label={t('product.closing_description')}
                                name={`closingDescription${index}`}
                                onChange={(e) =>
                                    closingDescriptionHandler(
                                        index,
                                        e.target.value
                                    )
                                }
                                value={
                                    internalKeyDate?.closingDescription || ''
                                }
                            />
                        </FormFieldWrapper>
                    </div>
                    <div className="mt-4">
                        <DateInput
                            defaultValue={formatDate(
                                internalKeyDate.closingDate
                            )}
                            label="Closing Date"
                            onBlur={(event) => {
                                dateChangeHandler(
                                    'closingDate',
                                    index,
                                    event.target.value
                                );
                            }}
                            hasError={
                                errors?.closedEndedInformation?.keyDates &&
                                Boolean(
                                    errors.closedEndedInformation?.keyDates[
                                        index
                                    ]
                                )
                            }
                        />
                    </div>
                    {errors?.closedEndedInformation?.keyDates &&
                        errors.closedEndedInformation?.keyDates[index] && (
                            <div className="flex content-start text-red-500 text-sm mb-8">
                                {errors.closedEndedInformation?.keyDates[index]}
                            </div>
                        )}
                </div>
            ))}
            <div className="mt-4">
                <Button
                    label={t('admin.product.add_key_date')}
                    type="button"
                    onClick={handleNewDate}
                />
            </div>
        </div>
    );
};
