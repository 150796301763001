import { TaxCountry } from '@interfaces/Api';
import { FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Atoms';
import {
    FormInputBox,
    FormSelect,
    InlineFormFrame,
} from '@components/Molecules';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { TaxCountriesArrayFormProps } from './TaxDeclarations';
import { RecursiveErrorDisplay } from './RecursiveErrorDisplay';

export const TaxCountriesFieldArray = (props: TaxCountriesArrayFormProps) => {
    const { t } = useTranslation();
    const [showAddTaxCountry, setShowAddTaxCountry] = useState(false);
    const areInputsDisabled = props.disabled ?? false;
    const [visibleCountryList, setVisibleCountryList] =
        useState(isoCountriesOptions);

    useEffect(() => {
        // Remove countries that have already been selected
        const selectableCountries = isoCountriesOptions.filter(
            (a) => !props.taxCountries?.find((b) => b.countryCode == a.value)
        );
        if (props.taxCountries?.length && props.taxCountries.length > 0) {
            // Push the currently chosen tax country back on the list so that it shows when selected
            props.taxCountries.forEach((txc) => {
                const missingCountry = isoCountriesOptions.find(
                    (a) => a.value == txc.countryCode
                );
                missingCountry && selectableCountries.push(missingCountry);
            });
        }
        setVisibleCountryList(selectableCountries);
    }, [props.taxCountries, showAddTaxCountry]);

    return (
        <FieldArray
            name={props.fieldArrayName}
            render={(arrayHelpers) => {
                const taxCountry: TaxCountry = {
                    countryCode: '',
                    taxId: '',
                    reasonForNotHavingTaxId: '',
                };

                return (
                    <div>
                        {props.taxCountries &&
                            props.taxCountries.map((_taxCountry, i, _arr) => (
                                <InlineFormFrame
                                    key={i.toString()}
                                    secondaryAction={t('ui.controls.remove')}
                                    onSecondaryAction={() =>
                                        arrayHelpers.remove(i)
                                    }
                                >
                                    <FormSelect
                                        name={`${props.fieldArrayName}.[${i}].countryCode`}
                                        label={
                                            t(
                                                'client.registration.nationalities.country'
                                            ) + '*'
                                        }
                                        optionsData={visibleCountryList}
                                        disabled={areInputsDisabled}
                                    />
                                    <FormInputBox
                                        name={`${props.fieldArrayName}.[${i}].taxId`}
                                        label={t(
                                            'subscriptions.add.tax_declaration.tax_id_number'
                                        )}
                                        placeholder={t(
                                            'subscriptions.add.tax_declaration.tax_id_tin'
                                        )}
                                        disabled={areInputsDisabled}
                                    />
                                    <FormInputBox
                                        name={`${props.fieldArrayName}.[${i}].reasonForNotHavingTaxId`}
                                        label={t(
                                            'subscriptions.add.tax_declaration.reason_for_no_tax_id'
                                        )}
                                        disabled={areInputsDisabled}
                                    />
                                    {props.validationErrors && (
                                        <RecursiveErrorDisplay
                                            errors={props.validationErrors[i]}
                                        />
                                    )}
                                </InlineFormFrame>
                            ))}
                        {
                            <Button
                                label={t(
                                    'subscriptions.add.tax_declaration.country_of_residence.add_tax_country_button'
                                )}
                                type="button"
                                buttonType="secondary"
                                className="w-full my-2"
                                disabled={areInputsDisabled}
                                onClick={() => {
                                    arrayHelpers.push(taxCountry);
                                    setShowAddTaxCountry(true);
                                }}
                            />
                        }
                    </div>
                );
            }}
        />
    );
};
