import { useCallback, useMemo, FC } from 'react';
import * as Yup from 'yup';
import { FormRadioGroup } from '@components/Molecules';
import { EndInvestorType } from 'common';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { Button } from '@components/Atoms';
import { ModalService } from '@services/ModalService';

const endInvestorTypeLabelOptions = [
    {
        label: 'Individual Investor',
        value: EndInvestorType.individual
    },
    {
        label: 'Business Entity',
        value: EndInvestorType.businessEntity
    }
];

interface EndInvestorTypeSelectionProps {
    onSubmit: (endInvestorType: EndInvestorType) => void
}

export const EndInvestorTypeSelection: FC<EndInvestorTypeSelectionProps> = ({ onSubmit }) => {
    const handleSubmit = useCallback((value) => {
        onSubmit(value.endInvestorType);
    }, [onSubmit]);

    const validationSchema = useMemo(() => Yup.object().shape({
        endInvestorType: Yup.string().trim().required(
            t('client.registration.investor_type.required_validation_message')
        ),
    }), []);
    
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{}}
            validateOnMount
            validationSchema={validationSchema}
            enableReinitialize
        >
            {
                (formProps) => (
                    <Form>
                        <h2 className="mb-6 text-2xl">
                            Are you investing as yourself or via an entity?
                        </h2>
                        <FormRadioGroup
                            name="endInvestorType"
                            optionsData={endInvestorTypeLabelOptions}
                        />
                        <div className="flex justify-between mt-6 border-t pt-4 border-sky-100">
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    ModalService.getInstance().hideModal();
                                }}
                                label={t('ui.controls.cancel')}
                            />
                            <Button
                                label={t('ui.controls.next')}
                                type="submit"
                                disabled={!formProps.isValid}
                            />
                        </div>
                    </Form>
                )
            }
        </Formik>
    );
};
