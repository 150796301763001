import { Button, Icons, SelectBox } from '@components/Atoms';
import { AlertBox, FormFieldWrapper, ReviewFrame } from '@components/Molecules';

import { getTransferAgent } from '@api/TransferAgent';
import { useAuthState } from '@contexts/AuthContext';
import { dateTimeFormatter } from '@helpers/Date.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { getFullName } from '@helpers/common.helper';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { useMountEffect } from '@hooks/useMountEffect';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { SubscriptionReviewProps } from '@interfaces/SubscriptionReview';
import { useProduct } from '@stores/Products/useProduct';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import classNames from 'classnames';
import {
    AuthPermissionEnum,
    AuthRoleEnum,
    CompletenessCheck,
    CompletenessCheckType,
    ITransferAgent,
    RiskLevel,
    useTransferAgentDetermination,
} from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showRiskReview } from './SubscriptionReview.logic';
import { subscriptionRiskLevels } from './helpers/riskLevel.helper';

type UserDetails = {
    fullName: string;
    id: string;
};

const RiskReview = ({
    onSubscriptionCompletenessCheck,
}: SubscriptionReviewProps) => {
    const { subscription } = useSubscription();
    const { product } = useProduct(subscription.productId);
    const { hasPermissions } = useAuthState();
    const [riskComments, setRiskComments] = useState<string>('');
    const [subscriptionRiskLevel, setSubscriptionRiskLevel] = useState<
        keyof typeof RiskLevel
    >(RiskLevel.low);
    const [isAcceptButtonLoading, setIsAcceptButtonLoading] =
        useState<boolean>(false);
    const [editRiskReview, setEditRiskReview] = useState<boolean>(false);
    const [riskReviews, setRiskReviews] = useState<CompletenessCheck[]>([]);
    const [transferAgent, setTransferAgent] = useState<ITransferAgent>();
    const [hasComplianceReview, setHasComplianceReview] = useState<boolean>();
    const [users, setUsers] = useState<UserDetails[]>([]);
    const { isCaceisLux } = useTransferAgentDetermination(transferAgent);

    useMountEffect(() => {
        const riskReviews =
            subscription?.completenessChecks?.statusLogs?.filter(
                (check) => check.type === CompletenessCheckType.riskIndication
            ) || [];

        setRiskReviews(riskReviews);
    });

    useEffect(() => {
        if (!riskReviews.length) return;

        riskReviews.map((x) => {
            const systemUserGet = async () =>
                await SystemUsersGet(x.systemUserId);

            systemUserGet().then((systemUser) => {
                setUsers((prev) => [
                    ...prev,
                    {
                        fullName: getFullName(
                            systemUser.firstName,
                            systemUser.surname
                        ),
                        id: x.systemUserId,
                    },
                ]);
            });
        });

        setHasComplianceReview(
            Boolean(
                riskReviews?.find(
                    (check) =>
                        check.role === AuthRoleEnum.intermediaryCompliance ||
                        check.role === AuthRoleEnum.assetManagerCompliance
                )
            )
        );
    }, [riskReviews]);

    const { t } = useTranslation();

    useEffect(() => {
        if (product?.transferAgentId) {
            getTransferAgent(product?.transferAgentId)
                .then((transferAgent) => {
                    setTransferAgent(transferAgent);
                })
                .catch((error) => notifyError(errorToString(error)));
        }
    }, [product?.transferAgentId]);

    const hasSubmissionFileReview =
        subscription?.completenessChecks?.statusLogs?.find(
            (check) => check.type === CompletenessCheckType.internalReview
        );

    const currentRiskLevel = riskReviews?.sort((a, b) => {
        return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
    })?.[0]?.decision;

    useEffect(() => {
        if (
            hasPermissions(
                AuthPermissionEnum.updateCompletenessCheckFromInternalReviewToRiskIndication
            ) &&
            riskReviews.length < 1
        ) {
            setEditRiskReview(true);
        }
        if (
            (subscription.status === SubscriptionStatusEnum.rejected ||
                subscription.status === SubscriptionStatusEnum.approved) &&
            riskReviews.length < 1
        ) {
            setEditRiskReview(false);
        }
    }, [hasPermissions]);

    const showRiskReviewEditor =
        hasSubmissionFileReview &&
        (riskReviews.length < 1 ||
            (hasPermissions(
                AuthPermissionEnum.approveSubscriptionForCompliance
            ) &&
                subscription.status !== SubscriptionStatusEnum.readyForReview &&
                subscription.status !== SubscriptionStatusEnum.approved &&
                !hasComplianceReview) ||
            (subscription.status !== SubscriptionStatusEnum.rejected &&
                subscription.status !== SubscriptionStatusEnum.readyForReview &&
                hasPermissions(
                    AuthPermissionEnum.approveSubscriptionForCompliance
                ) &&
                editRiskReview) ||
            (subscription.status === SubscriptionStatusEnum.readyForReview &&
                hasPermissions(
                    AuthPermissionEnum.updateCompletenessCheckFromInternalReviewToRiskIndication
                ) &&
                !editRiskReview));

    const showEditButton =
        hasSubmissionFileReview &&
        (hasPermissions(AuthPermissionEnum.approveSubscriptionForCompliance) ||
            hasPermissions(
                AuthPermissionEnum.updateCompletenessCheckFromInternalReviewToRiskIndication
            ));

    return (
        <div
            className={classNames({
                hidden: !showRiskReview({
                    subscription,
                    isCaceisLux,
                    hasPermissions,
                }),
            })}
        >
            <h4 className="mt-6 mb-2 text-lg font-bold">
                {t('subscriptions.completeness_checks.risk_indication.title')}{' '}
                {currentRiskLevel &&
                    `- Decision (${toCapitalizedWords(currentRiskLevel)})`}
            </h4>
            {riskReviews?.map((riskIndication, i) => (
                <div
                    className="pb-4 mb-6 border-b"
                    key={riskIndication.systemUserId}
                >
                    <ReviewFrame
                        hideToggleButton
                        title=""
                        summary={[
                            t(
                                'subscriptions.completeness_checks.risk_indication.risk_level'
                            ),
                            ' ',
                            riskIndication?.decision,
                            t(
                                'subscriptions.completeness_checks.risk_indication.given_by'
                            ),
                            users.find(
                                (x) => x.id === riskIndication.systemUserId
                            )?.fullName,
                            t('ui.labels.at'),
                            dateTimeFormatter(riskIndication?.createdAt),
                            '\n',
                        ]
                            .filter((a) => a)
                            .join(' ')}
                        summaryClassName="whitespace-pre-line"
                        expanded
                    />
                    {riskIndication?.comments && (
                        <div className="text-sm">
                            <p>
                                {t(
                                    'subscriptions.completeness_checks.comments'
                                )}
                                :
                            </p>
                            <p>{riskIndication?.comments}</p>
                        </div>
                    )}
                </div>
            ))}

            {showRiskReviewEditor ? (
                <>
                    <p className="pb-2 mt-2">
                        {t(
                            'subscriptions.completeness_checks.risk_indication.intro'
                        )}
                    </p>

                    <div className="relative mb-4 w-72">
                        <FormFieldWrapper
                            label={'Risk level Indicator'}
                            rightIcon={
                                <Icons name="ChevronDown" size="small" />
                            }
                        >
                            <SelectBox
                                name="funds types"
                                optionsData={subscriptionRiskLevels}
                                onChange={({ target: { value } }) =>
                                    setSubscriptionRiskLevel(RiskLevel[value])
                                }
                                showDefault={false}
                            />
                        </FormFieldWrapper>
                    </div>
                    <p>
                        {t(
                            'subscriptions.completeness_checks.comments_paragraph'
                        )}
                    </p>
                    <textarea
                        aria-label="Risk Comments"
                        onChange={(comment) => {
                            setRiskComments(comment.target.value);
                        }}
                        className={`w-full h-auto pt-8 pl-3 pr-3 placeholder-gray-300 border rounded-lg focus:shadow-outline`}
                    />
                    <div className="flex flex-row justify-between pb-8 mt-2 mb-4 border-b">
                        <Button
                            type="button"
                            label={t('ui.controls.confirm')}
                            disabled={!subscriptionRiskLevel}
                            onClick={() => {
                                // reload the page if risk level same as last time we reload page...
                                const isRiskLevelSameAsLastTime =
                                    riskReviews?.[0]?.decision ===
                                    subscriptionRiskLevel;
                                if (isRiskLevelSameAsLastTime) {
                                    notifyError(
                                        'Risk level is same as last time'
                                    );
                                    return;
                                }

                                setIsAcceptButtonLoading(true);

                                if (
                                    hasPermissions(
                                        AuthPermissionEnum.updateCompletenessCheckFromInternalReviewToRiskIndication
                                    )
                                ) {
                                    setEditRiskReview(false);
                                }

                                onSubscriptionCompletenessCheck({
                                    type: CompletenessCheckType.riskIndication,
                                    decision: subscriptionRiskLevel,
                                    comments: riskComments,
                                })
                                    .then(() => setIsAcceptButtonLoading(false))
                                    .catch(() =>
                                        setIsAcceptButtonLoading(false)
                                    )
                                    .finally(() => {
                                        window.location.reload();
                                    });
                            }}
                            endIcon={
                                isAcceptButtonLoading && (
                                    <Icons name="Loading" />
                                )
                            }
                        />
                    </div>
                </>
            ) : showEditButton ? (
                <div className="pb-6 border-b">
                    <Button
                        onClick={() => {
                            setEditRiskReview(!editRiskReview);
                        }}
                    >
                        {t(
                            'subscriptions.completeness_checks.risk_indication.edit'
                        )}
                    </Button>
                </div>
            ) : (
                !riskReviews.length && (
                    <AlertBox
                        key="login-account-creation"
                        alertType={'info'}
                        title={'Risk Indication'}
                        message={
                            <p>
                                {t(
                                    'subscriptions.completeness_checks.risk_indication.info_incomplete'
                                )}
                            </p>
                        }
                    />
                )
            )}
        </div>
    );
};

export default RiskReview;
