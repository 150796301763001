import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ProductResponse } from '@interfaces/Api';

import { useProductSubmit } from '@hooks/useProductSubmit';

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';

import { FormCheckbox, FormInputBox, PhoneNumberBox } from '@components/Molecules';
import { useProducts } from '@stores/Products/useProducts';
import { FormSigningGroups } from './FormSigningGroups';
import { useTranslation } from 'react-i18next';

const ContactInformation: React.FC = () => {
    const { t } = useTranslation();
    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({
        contact: {
            firstName: '',
            lastName: '',
            email: '',
        },
    });

    const { productId } = useParams();
    const { products } = useProducts();
    const product = products.filter((e) => e._id === productId)[0];

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        contact: Yup.object().shape({
            firstName: Yup.string().trim().required(t('admin.user_management.first_name_is_required')),
            lastName: Yup.string().trim().required(t('admin.user_management.last_name_is_required')),
            email: Yup.string()
                .required(t('client.registration.contact_details.email_address.required_validation_message'))
                .email(t('admin.user_management.invalid_email')),
        }),
        subscriptionAgreementCountersignature: Yup.string()
            .required(t('admin.product.subscription_agreement_countersignature_is_required'))
            .email(t('admin.user_management.invalid_email')),
    });

    const onSubmit = useProductSubmit(productId, `product-eligibility`);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{t('product.contact_info')}</h1>
            {(productId === 'new' || productId) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={(values) => onSubmit({ ...product, ...values })}
                    validationSchema={selectedValidationSchema}
                    validateOnMount
                    enableReinitialize
                >
                    {({values}) =>  <Form className="mb-3">
                        <h3 className="text-sm font-normal leading-8 mb-3">
                            {t('admin.product.please_enter_contact_details_for_the_product')}
                        </h3>
                        <FormInputBox
                            name="contact.salutation"
                            label={t('client.registration.personal_details.salutation')}
                            placeholder="Mr/Mrs"
                        />
                        <FormInputBox
                            name="contact.firstName"
                            label={t('client.invite.first_name')}
                        />
                        <FormInputBox
                            name="contact.lastName"
                            label={t('client.invite.last_name')}
                        />
                        <FormInputBox name="contact.email" label={t('client.invite.email')} />

                        <PhoneNumberBox
                            phoneDiallingCodeName="contact.phoneNumberDiallingCode"
                            phoneDiallingCodeLabel={t('client.registration.contact_details.country_code')}
                            phoneNumberName="contact.phoneNumber"
                            phoneNumberLabel={t('client.registration.contact_details.phone_number')}
                        />

                        <FormInputBox
                            name="subscriptionAgreementCountersignature"
                            label={t('admin.product.subscription_agreement_countersignature')}
                            placeholder={t('product.email')}
                        />

                        <FormCheckbox
                            name='sendDocToEmail'
                            actionLabel="Send documents to email"
                        />

                        {!values['sendDocToEmail'] && <FormSigningGroups />}

                        <EditProductFormikButtons
                            productId={productId}
                            isReadOnly={false}
                            pathPrevious="product-description"
                            pathNext="product-eligibility"
                        />
                    </Form>}
                   
                </Formik>
            )}
        </div>
    );
};

export default ContactInformation;
