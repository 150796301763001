import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { useSubscriptionsAndTemplates } from '@hooks/useSubscriptionsAndTemplates';
import {
    AuthPermissionEnum,
    AuthRoleEnum,
    EndInvestorPortfolioItem,
    EndInvestorProfileResponse,
    SubscriptionResponse,
} from '@interfaces/Api';
import { useSubscriptionsStore } from '@stores/Subscriptions/Subscriptions.store';
import { ClientEnum } from 'common';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type PortfolioPillOptionType =
    | 'Portfolio'
    | 'ClientDetails'
    | 'InvestmentReportsDetails'
    | 'Subscriptions'
    | 'UnderlyingClients'
    | 'SubscriptionTemplates'
    | 'OptUpRequests'
    | 'Documents';

export const usePortfolioPills = (
    setSelectedPill: Dispatch<
        SetStateAction<PortfolioPillOptionType | undefined>
    >,
    portfolioData?: EndInvestorPortfolioItem[]
) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isClient } = useConfigurationState();
    const isOddo = isClient(ClientEnum.ODDO);
    const { preferences } = useConfigurationState();
    const { currentUser } = useAuthState();
    const { endInvestorProfile, endInvestorProfileId } =
        useEndInvestorProfile();

    const [searchParams, setSearchParams] = useSearchParams();
    const { subscriptions, isLoading } = useSubscriptionsStore();
    const { hasPermissions } = useAuthState();
    const { list: subscriptionsTemplates } =
        useSubscriptionsAndTemplates<SubscriptionResponse>(
            endInvestorProfileId!,
            'subscriptions-templates'
        );

    const pillOptions = useMemo(() => {
        const options: {
            label: string;
            value: PortfolioPillOptionType;
            isVisible?: boolean;
        }[] = [
                {
                    label: t('client.pill_selector.portfolio'),
                    value: 'Portfolio',
                    isVisible:
                        preferences?.feature?.clientView?.enablePortfolioView,
                },
                {
                    label: t('client.pill_selector.subscriptions'),
                    value: 'Subscriptions',
                    isVisible: true,
                },
                {
                    label: t('client.pill_selector.investment_reports'),
                    value: 'InvestmentReportsDetails',
                    isVisible:
                        !isOddo &&
                        currentUser?.user.role !== AuthRoleEnum.fundAdmin,
                },
                {
                    label: t('client.pill_selector.opt_up_requests'),
                    value: 'OptUpRequests',
                    isVisible: hasPermissions([
                        AuthPermissionEnum.getAllOptUpEndInvestorProfileRequest,
                    ]),
                },
                {
                    label: t('client.pill_selector.documents'),
                    value: 'Documents',
                    isVisible: hasPermissions([
                        AuthPermissionEnum.getCannedReport,
                    ]),
                },
                {
                    label: t('client.pill_selector.underlying_clients'),
                    value: 'UnderlyingClients',
                    isVisible:
                        endInvestorProfile?.endInvestorType ===
                        EndInvestorProfileResponse.EndInvestorType.nomineeEntity,
                },
                // {
                //     label: t('client.pill_selector.subscription_templates'),
                //     value: 'SubscriptionTemplates',
                //     isVisible: subscriptionsTemplates.length > 0,
                // },
            ];
        return options.filter((option) => option.isVisible);
    }, [t, preferences?.feature?.clientView?.enablePortfolioView, isOddo, currentUser?.user.role, hasPermissions, endInvestorProfile?.endInvestorType]);

    const handlePillSelection = useCallback(() => {
        const pillValueFromUrl = searchParams.get(
            'section'
        ) as PortfolioPillOptionType;

        const fromLogin = searchParams.get('from') === 'login';

        const isNotPortfolioData = portfolioData && portfolioData?.length < 1;

        let pill = pillOptions[0].value;

        if (pillValueFromUrl) pill = pillValueFromUrl;

        if (isNotPortfolioData && subscriptions.length > 0 && !isLoading) {
            pill = 'Subscriptions';
        }

        if (pillOptions.some((p) => p.value === pill)) {
            if (fromLogin && !isNotPortfolioData) {
                navigate('/products/');
            } else {
                setSelectedPill(pill);
                setSearchParams({ section: pill });
            }
        }
    }, [isLoading, navigate, pillOptions, portfolioData, searchParams, setSearchParams, setSelectedPill, subscriptions.length]);

    useEffect(() => {
        handlePillSelection();
    }, [handlePillSelection, portfolioData, subscriptions]);
    return { pillOptions };
};
