import { useTranslation } from 'react-i18next';
import { FormCheckbox } from '@components/Molecules';
import { TaxCountriesFieldArray } from './TaxCountriesFieldArray';

export const RenderIndividualTaxDeclarations = ({
    formikProps, areInputsDisabled,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <h3 className="pt-3 pb-2 text-2xl">
                {t('end_investor_type.individual')}
            </h3>

            <h3 className="my-2 mt-4 text-xl font-normal leading-8">
                {t(
                    'subscriptions.add.tax_declaration.country_of_residence.heading'
                )}
            </h3>
            <p className="py-1 text-base">
                {t(
                    'subscriptions.add.tax_declaration.country_of_residence.instruction'
                )}
            </p>
            <p
                className="py-1 pb-3 text-base"
                dangerouslySetInnerHTML={{
                    __html: t(
                        'subscriptions.add.tax_declaration.country_of_residence.instruction_note'
                    ),
                }}
            ></p>
            <TaxCountriesFieldArray
                fieldArrayName="individual.taxCountries"
                taxCountries={formikProps.values.individual?.taxCountries}
                validationErrors={formikProps.errors?.individual?.taxCountries}
                disabled={areInputsDisabled} />

            <div className="pt-8">
                <FormCheckbox
                    description={t(
                        'subscriptions.add.non_us_person_confirmation'
                    )}
                    name="individual.nonUsPersonConfirmation"
                    disabled={areInputsDisabled} />
            </div>
        </>
    );
};
