import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
    CreateBookingCentreRequestBody,
    BookingCentreResponse,
} from '@interfaces/Api';
import {
    getBookingCentres,
    handleAddBookingCentre,
    handleDeleteBookingCentre,
    handleUpdateBookingCentre,
} from './BookingCentres.services';

export interface BookingCentresStore {
    bookingCentres: BookingCentreResponse[];
    isLoading: boolean;
    actions: {
        fetchBookingCentres: (intermediaryId: string, rmId?: string) => Promise<void>;
        addBookingCentre: (
            bookingCentre: CreateBookingCentreRequestBody
        ) => Promise<BookingCentreResponse | void>;
        deleteBookingCentre: (bookingCentreId: string) => Promise<void>;
        updateBookingCentre: (
            bookingCentre: BookingCentreResponse
        ) => Promise<void>;
    };
}

export const useBookingCentresStore = create<BookingCentresStore>()(
    devtools(
        (set): BookingCentresStore => ({
            bookingCentres: [],
            isLoading: false,
            actions: {
                fetchBookingCentres: async (intermediaryId: string, rmId?: string) => {
                    set({ isLoading: true });
                    try {
                        const bookingCentres = await getBookingCentres(
                            intermediaryId,
                            rmId,
                        );
                        set({ bookingCentres }, false, 'fetchBookingCentres');
                    } finally {
                        set({ isLoading: false });
                    }
                },
                addBookingCentre: async (
                    bookingCentre: CreateBookingCentreRequestBody
                ) => {
                    await handleAddBookingCentre(set, bookingCentre);
                },
                deleteBookingCentre: async (bookingCentreId: string) => {
                    await handleDeleteBookingCentre(set, bookingCentreId);
                },
                updateBookingCentre: async (
                    bookingCentre: BookingCentreResponse
                ) => {
                    await handleUpdateBookingCentre(set, bookingCentre);
                },
            },
        })
    )
);
