import { Button } from '@components/Atoms';
import { FormInputBox, FormSelect } from '@components/Molecules';
import { Form, useFormikContext } from 'formik';

interface IBasicForm {
    onCancel?: () => void;
    fields: {
        name: string;
        label: string;
        placeholder: string;
        type?: 'input' | 'select';
        options?: { label: string; value: string }[];
    }[];
    handleSubmit: (values: any) => void;
    displayButtons?: boolean;
    disabled?: boolean;
}

export const BasicForm = ({
    onCancel,
    fields,
    handleSubmit,
    displayButtons = true,
    disabled = false,
}: IBasicForm) => {
    const { isValid, dirty, values } = useFormikContext();

    return (
        <Form className="text-logo-blue">
            {fields.map((field) =>
                field.type === 'input' ? (
                    <FormInputBox
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        disabled={disabled}
                    />
                ) : (
                    <FormSelect
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        placeholder={field.placeholder}
                        optionsData={field.options}
                        disabled={disabled}
                    />
                )
            )}

            {displayButtons && (
                <div className="flex justify-between">
                    <Button
                        buttonType="secondary"
                        label="Cancel"
                        onClick={onCancel}
                    />
                    <Button
                        disabled={!isValid || !dirty || disabled}
                        label="Submit"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(values);
                        }}
                    />
                </div>
            )}
        </Form>
    );
};
