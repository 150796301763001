import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from 'common';
import TagManager from 'react-gtm-module';

interface useGoogleTagManagerProps {
    eventName: string;
    eventParameters?: { [key: string]: any };
}

export const isGtmEnabled = (gtmId: string | undefined) => {
    if (!gtmId) {
        console.error('GTM ID not found');
        return false;
    }
    return true;
};

export const useGoogleTagManager = () => {
    const { configuration } = useConfigurationState();
    const { gtmId = '' } = configuration || {};
    const dataLayerName = configuration?.gtmDataLayerName || 'PageDataLayer';

    const gtmInitialise = () => {
        if (configuration?.client === ClientEnum.ODDO) return;
        if (!isGtmEnabled(gtmId)) return;
        const tagManagerArgs = {
            gtmId,
            auth: configuration?.gtmAuthId,
            preview: configuration?.gtmPreviewId,
            dataLayerName,
        };
        return TagManager.initialize(tagManagerArgs);
    };

    const gtmDataLayerPush = (dataLayer: { [key: string]: any }) => {
        if (configuration?.client === ClientEnum.ODDO) return;
        if (!isGtmEnabled(gtmId)) return;
        return TagManager.dataLayer({
            dataLayer,
            dataLayerName,
        });
    };

    const gtmSendEvent = ({
        eventName,
        eventParameters,
    }: useGoogleTagManagerProps) => {
        if (configuration.client === ClientEnum.ODDO) return;
        if (!isGtmEnabled(gtmId)) return;
        return TagManager.dataLayer({
            dataLayer: {
                event: eventName,
                ...eventParameters,
            },
            dataLayerName,
        });
    };

    return {
        gtmInitialise,
        gtmDataLayerPush,
        gtmSendEvent,
    };
};
