import { isoCountries } from '@helpers/isoCountries';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const ReviewClientBankDetails = (props: ReviewClientSummaryDetailsProps) => {
    const { t } = useTranslation();

    return (
        <div className="leading-5 mb-4">
            {props.data?.individualDetails?.bankAccounts?.map((bankAcc, i) => (
                <div className="pb-3" key={`${i}-bank`}>
                    <ReviewItem
                        label={
                            t('subscriptions.bank_details.review.name') + ':'
                        }
                        value={bankAcc?.bankName}
                    />
                    <ReviewItem
                        label={
                            t('subscriptions.bank_details.review.address') + ':'
                        }
                        value={bankAcc?.bankAddress}
                    />
                    <ReviewItem
                        label={
                            t(
                                'subscriptions.bank_details.review.country_currency'
                            ) + ':'
                        }
                        value={bankAcc?.countryCurrencyCode}
                    />

                    <ReviewItem
                        label={
                            t(
                                'subscriptions.bank_details.review.country_of_account'
                            ) + ':'
                        }
                        value={isoCountries[bankAcc?.countryCodeOfAccount]}
                    />

                    <ReviewItem
                        label={
                            t('subscriptions.bank_details.review.iban') + ':'
                        }
                        value={bankAcc?.IBAN}
                    />
                    <ReviewItem
                        label={
                            t(
                                'subscriptions.add.subscription.review_subscription.accountNumber'
                            ) + ':'
                        }
                        value={bankAcc?.accountNumber}
                    />
                    <ReviewItem
                        label={
                            t(
                                'subscriptions.add.subscription.review_subscription.accountHolderName'
                            ) + ':'
                        }
                        value={bankAcc?.accountHolderName}
                    />
                    <ReviewItem
                        label={
                            t(
                                'subscriptions.add.subscription.review_subscription.accountCode'
                            ) + ':'
                        }
                        value={bankAcc?.accountCode}
                    />
                </div>
            ))}
        </div>
    );
};

export default ReviewClientBankDetails;
