import { CustomDatePicker } from '@components/Atoms';
import { IDatePicker } from '@components/Atoms/Datepicker/Datepicker';
import {
    Field,
    FieldMetaProps,
    FieldInputProps,
    useFormikContext,
    ErrorMessage,
} from 'formik';
import { useTranslation } from 'react-i18next';

export const FormDatePicker: React.FC<IDatePicker> = ({
    disabled,
    label,
    name,
    ...rest
}) => {
    const { t } = useTranslation();
    const { setFieldValue } = useFormikContext();

    const handleOnChange = (value: string | Date) => setFieldValue(name, value);

    return (
        <div className="relative w-full mb-3">
            <Field id={name} name={name}>
                {({
                    field,
                    meta,
                }: {
                    field: FieldInputProps<any>;
                    meta: FieldMetaProps<any>;
                }) => {
                    return (
                        <>
                            <CustomDatePicker
                                label={label}
                                isCustomHeader
                                disabled={disabled}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={handleOnChange}
                                value={field.value}
                                isError={!!meta.touched && !!meta.error}
                                dateFormat="dd/MM/yyyy"
                                placeholderText={t('client.registration.personal_details.date_of_birth_placeholder')}
                                {...rest}
                            />
                            <div className="flex content-start text-red-500 text-sm">
                                <ErrorMessage
                                    name={field.name!}
                                    render={(msg: string) => <p>{msg}</p>}
                                />
                            </div>
                        </>
                    );
                }}
            </Field>
        </div>
    );
};
