import { FC } from 'react';
import { DefaultComponents } from '../Default/DefaultComponents';
import { ODDOBankSelection } from './Components/ODDOBankSelection';
import { ODDOServiceLevelSelection } from './Components/ODDOServiceLevelSelection';

export class ODDOComponents extends DefaultComponents {
    GetBankSelection = () => {
        return ODDOBankSelection;
    }

    GetServiceLevelSelection = () => {
        return ODDOServiceLevelSelection;
    }
}