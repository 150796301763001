import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { notifyError } from '@helpers/toastrHelper';

import { EndInvestorProfileGet } from '@api/EndInvestorProfiles';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { EndInvestorProfileRequestBody } from '@interfaces/Api';
import { ClientEnum, FrenchInvestorRegistrationType } from 'common';

const defaultInvestorDetails: Pick<
    EndInvestorProfileRequestBody,
    | 'intermediaryId'
    | 'individualDetails'
    | 'businessEntityDetails'
    | 'endInvestorType'
> = {
    intermediaryId: '',
    endInvestorType: undefined,
    individualDetails: {
        firstName: '',
        lastName: '',
        dateOfBirth: '' as any,
        townOfBirth: '',
        countryCodeOfBirth: '',
        nationalities: [],
        emailAddress: '',
        phoneDiallingCode: '',
        phoneNumber: '',
        addresses: [],
        bankAccounts: [],
    },
    businessEntityDetails: {
        companyName: '',
        registrationNumber: '',
        subscribersLegalForm: '',
        subscribersRegister: '',
        subscriberParentListingAuthority: '',
        isEntityWithOwnLegalPersonality: true,
        subscribersRegulator: '',
    },
};

const defaultRelationShips: Pick<
    EndInvestorProfileRequestBody,
    | 'adviserSystemUserId'
    | 'bookingCentreIds'
    | 'clientType'
    | 'isKycCompliant'
    | 'kycDocuments'
    | 'onboardingJurisdictionCountryCode'
    | 'serviceLevelType'
    | 'subscriptionProcessType'
    | 'nomineeEndInvestorProfileId'
> = {
    adviserSystemUserId: undefined,
    bookingCentreIds: undefined,
    clientType: '',
    isKycCompliant: undefined,
    kycDocuments: [],
    onboardingJurisdictionCountryCode: '',
    serviceLevelType: undefined,
    subscriptionProcessType: undefined,
    nomineeEndInvestorProfileId: undefined,
};

const defaultMiscData: Pick<
    EndInvestorProfileRequestBody,
    'loginAccountChoice' | 'systemUsers'
> = {
    loginAccountChoice: 'newAccount',
    systemUsers: [
        {
            _id: '',
            email: '',
            firstName: '',
            surname: '',
        },
    ],
};

export interface RegisterInvestorContextProps {
    data: EndInvestorProfileRequestBody;
    setData: Dispatch<SetStateAction<EndInvestorProfileRequestBody>>;
}

const registerInvestorDefaultState: RegisterInvestorContextProps = {
    data: {
        ...defaultInvestorDetails,
        ...defaultRelationShips,
        ...defaultMiscData,
    },
    setData: () => {},
};

export const RegisterInvestorContext =
    createContext<RegisterInvestorContextProps>(registerInvestorDefaultState);

export const useRegisterInvestorState = () =>
    useContext(RegisterInvestorContext);

export const RegisterInvestorProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const { currentUser } = useAuthState();
    const { isClient } = useConfigurationState();

    const isOddo = isClient(ClientEnum.ODDO);

    const [data, setData] = useState<EndInvestorProfileRequestBody>({
        ...defaultInvestorDetails,
        ...defaultRelationShips,
        ...defaultMiscData,
        intermediaryId: currentUser?.user.intermediaryId || '',
        ...(isOddo && {
            additionalInfo: {
                frenchInvestorRegistrationType:
                    FrenchInvestorRegistrationType.registered,
            },
        }),
    });

    const { endInvestorProfileId } = useParams();

    useEffect(() => {
        if (endInvestorProfileId) {
            EndInvestorProfileGet(endInvestorProfileId)
                .then((response) => {
                    setData({
                        ...response,
                        bookingCentreIds: response.bookingCentreIds.map(
                            (a) => a._id
                        ),
                    });
                })
                .catch(() => {
                    notifyError('Failed to get end investor profile');
                });
        }
    }, [endInvestorProfileId]);

    return (
        <RegisterInvestorContext.Provider
            value={{
                data,
                setData,
            }}
        >
            {children}
        </RegisterInvestorContext.Provider>
    );
};
