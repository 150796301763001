import { useState, useEffect } from 'react';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
interface ReviewFrameProps {
    title: string;
    summary?: string;
    expanded?: boolean;
    className?: string;
    summaryClassName?: string;
    hideToggleButton?: boolean;
    actionButtons?: {
        label: string;
        onClick: () => void;
        show?: boolean;
    }[];
}

const ReviewFrame: React.FC<ReviewFrameProps> = (props) => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const { actionButtons } = props;

    useEffect(() => {
        setShowDetails(!!props.expanded);
    }, [props.expanded]);

    const toggleStyle = classname({
        'cursor-pointer  hover:text-gray-500': !props.hideToggleButton,
    });

    return (
        <div className="mb-2 ">
            <div
                className={toggleStyle}
                onClick={() =>
                    !props.hideToggleButton && setShowDetails(!showDetails)
                }
            >
                <h3 className="text-xl font-normal pt-3">{props.title}</h3>
                <div
                    className={`${props.className} flex w-full text-sm mb-3 pb-1`}
                >
                    <p className="flex-1">{props.summary}</p>
                    <div className="flex">
                        {actionButtons?.map((actionButton, i) => {
                            if (actionButton.show) {
                                return (
                                    <button
                                        key={`button-${i}`}
                                        onClick={actionButton.onClick}
                                        className="text-gray-400 mr-4"
                                        type="button"
                                    >
                                        {actionButton.label}
                                    </button>
                                );
                            }
                            return null;
                        })}
                        {!props.hideToggleButton && (
                            <button
                                onClick={() => {
                                    setShowDetails(!showDetails);
                                }}
                                className="text-gray-400"
                                type="button"
                            >
                                {showDetails
                                    ? t('ui.controls.hide')
                                    : t('ui.controls.show')}
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {showDetails ? (
                <div className="w-full">{props.children}</div>
            ) : null}
        </div>
    );
};

export default ReviewFrame;
