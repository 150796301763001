import { Button } from '@components/Atoms';
import { Modal, FormSelect } from '@components/Molecules';
import { notifyError } from '@helpers/toastrHelper';
import { BookingCentreResponse, IntermediaryResponse } from '@interfaces/Api';
import { ProfilePayload } from '@interfaces/Api/Profile';
import { ModalService } from '@services/ModalService';
import { useBookingCentresActions } from '@stores/BookingCentres';
import { useIntermediariesActions } from '@stores/Intermediaries';

import { Form, Formik } from 'formik';

interface ILinkedProfileModal {
    profileId: string;
    profiles: ProfilePayload[];
    isProfileSelectionVisible: boolean;
    toggleProfileSelection: (isVisible: boolean) => void;
    bookingCentre?: BookingCentreResponse;
    intermediary?: IntermediaryResponse;
}

export const LinkedProfileModal = ({
    isProfileSelectionVisible,
    profiles,
    profileId,
    toggleProfileSelection,
    bookingCentre,
    intermediary
}: ILinkedProfileModal) => {
    const profileOptions = profiles.map((profile) => ({
        label: profile.name,
        value: profile._id,
    }));

    const { updateBookingCentre } = useBookingCentresActions();
    const { updateIntermediary } = useIntermediariesActions();

    const handleSubmit = async (values: { profileId: string }) => {
        const matchedProfile = profiles.find(profile => profile._id === values.profileId);
        
        ModalService.getInstance()
            .setShowHeader(false)
            .setComponent(`This distributor can only access the products defined in ${matchedProfile.name}. Are you sure you want to continue?`)
            .setConfirmCallback(async () => {
                try {
                    if (bookingCentre) {
                        await updateBookingCentre({
                            ...bookingCentre,
                            profileId: values.profileId,
                        });
                    } else if (intermediary) {
                        await updateIntermediary({
                            ...intermediary,
                            profileId: values.profileId,
                        });
                    }
                    
                    toggleProfileSelection(false);
                } catch (error) {
                    notifyError(error.toString());
                } finally {
                    ModalService.getInstance().hideModal();
                }
            })
            .setShowFooter(true)
            .showModal();
    };

    return (
        <Modal width="w-1/3" show={isProfileSelectionVisible}>
            <Modal.Header>
                <h3>Linked profile</h3>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmit}
                    enableReinitialize
                    initialValues={{
                        profileId,
                    }}
                >
                    <Form>
                        <FormSelect
                            name="profileId"
                            label="Profile selection"
                            optionsData={profileOptions}
                        />

                        <div className="flex justify-between items-center">
                            <Button
                                onClick={() => toggleProfileSelection(false)}
                                label="Cancel"
                                buttonType="secondary"
                            />
                            <Button type="submit" label="Submit" />
                        </div>
                    </Form>
                </Formik>
            </Modal.Body>
        </Modal>
    );
};
