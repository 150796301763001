import { dateShortFormatter } from '@helpers/Date.helper';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { useTranslation } from 'react-i18next';

import { isoCountries } from '@helpers/isoCountries';
import { endInvestorTypeLabelMap } from '@interfaces/Api/EndInvestorProfileResponse';
import { ReviewItem } from './ReviewItem';

const ReviewClientPersonalDetails = (
    props: ReviewClientSummaryDetailsProps
) => {
    const { t } = useTranslation();
    const getNationalityNames = () => {
        // nationalityCountryCode needs to be deprecated, in the meantime default to it else use nationalities
        if (props.data?.individualDetails?.nationalityCountryCode?.trim()) {
            return isoCountries[
                props.data?.individualDetails?.nationalityCountryCode?.trim() ||
                    ''
            ];
        }

        return props.data?.individualDetails?.nationalities
            ?.map((a) => isoCountries[a.countryCode])
            .join(' - ');
    };

    const personalDetails = [
        {
            label: `${t('subscriptions.client_details.review.investor_type')}:`,
            value: endInvestorTypeLabelMap[
                props.data
                    ?.endInvestorType as keyof typeof endInvestorTypeLabelMap
            ],
        },

        {
            label: `${t('subscriptions.client_details.review.salutation')}:`,
            value: props.data?.individualDetails?.salutation,
        },
        {
            label: `${t('subscriptions.client_details.review.first_name')}:`,
            value: props.data?.individualDetails?.firstName,
        },
        {
            label: `${t('subscriptions.client_details.review.last_name')}:`,
            value: props.data?.individualDetails?.lastName,
        },
        {
            label: `${t('subscriptions.client_details.review.gender')}:`,
            value: props.data?.individualDetails?.gender,
        },
        {
            label: `${t(
                'subscriptions.client_details.review.marital_status'
            )}:`,
            value: props.data?.individualDetails?.maritalStatus || '',
        },
        {
            label: `${t('subscriptions.client_details.review.occupation')}:`,
            value: props.data?.individualDetails?.occupation || '',
        },
        {
            label: `${t('subscriptions.client_details.review.date_of_birth')}:`,
            value: props.data?.individualDetails?.dateOfBirth
                ? dateShortFormatter(props.data?.individualDetails?.dateOfBirth)
                : '',
        },
        {
            label: `${t('subscriptions.client_details.review.town_of_birth')}:`,
            value: props.data?.individualDetails?.townOfBirth,
        },
        {
            label: `${t(
                'subscriptions.client_details.review.country_of_birth'
            )}:`,
            value: isoCountries[
                props.data?.individualDetails?.countryCodeOfBirth || ''
            ],
        },
        {
            label: `${t('subscriptions.client_details.review.nationalities')}:`,
            value: getNationalityNames(),
        },
    ];

    return (
        <div className="flex flex-col md:flex-row w-full leading-5 mb-4">
            <div className="w-full lg:w-4/5 flex flex-col justify-start text-xs">
                {personalDetails.map((item, index) =>
                    item.value ? (
                        <ReviewItem
                            key={index}
                            label={item.label}
                            value={item.value}
                        />
                    ) : null
                )}
            </div>
        </div>
    );
};

export default ReviewClientPersonalDetails;
