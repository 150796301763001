import { VPRiskWarning } from '@components/Organisms';
import { ClientEnum, EndInvestorType } from 'common';
import {
    AuthPermissionEnum,
    ConfigurationResponse,
    EndInvestorProfileResponse,
    LiquidityTypeEnum,
    Preferences,
    ProductResponse,
    SigningDocumentStatusEnum,
    SigningDocumentTypeEnum,
    SubscriptionResponse,
    SubscriptionStatusEnum,
    SubscriptionTemplateResponse,
} from '@interfaces/Api';

import { VerifyInvestorDetails } from '@components/Organisms/VerifyInvestorDetails/VerifyInvestorDetails';
import { SubscriptionListItem } from '@containers/Subscriptions/SubscriptionList';
import { isEndInvestorNomineeAccount } from '@helpers/EndInvestorHelper';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router';
import { ModalService } from './ModalService';
import { subscriptionsHelperValidation } from './SubscriptionsHelper.validation';

export interface SubscriptionStep {
    endPathUrl: string;
    stepName: string;
    isRequired?: boolean;
    isValid?: boolean;
    showStep: boolean;
    canClick?: boolean;
}

export const getFirstStepSubscriptionTemplate = (
    t: TFunction<'translation', undefined>,
    endInvestorProfile: EndInvestorProfileResponse
) => {
    const steps = getSubscriptionTemplateSteps(t, endInvestorProfile);
    const endPathUrl = steps?.[0]?.endPathUrl;
    return endPathUrl;
};

export const getSubscriptionSteps = (
    t: TFunction<'translation', undefined>,
    endInvestorProfile: EndInvestorProfileResponse,
    subscription: SubscriptionResponse,
    feature: Preferences['feature'],
    product: ProductResponse,
    isClient: (client: ClientEnum) => boolean,
    client?: ClientEnum
): SubscriptionStep[] => {
    if (!endInvestorProfile) {
        return [];
    }

    const isRepeatedOpenEndedSubscription =
        product?.liquidityType === LiquidityTypeEnum.openEnded &&
        subscription?.parentSubscriptionId;

    const steps: SubscriptionStep[] = [
        {
            endPathUrl: 'investment-details',
            stepName: t('subscriptions.add.sidebar.investment_details'),
            isRequired: true,
            isValid: !!subscription?.subscriptionAmount,
            showStep: true,
        },
        {
            endPathUrl: 'german-semi-professional-questionnaire',
            stepName: t(
                'subscriptions.add.sidebar.german_semi_pro_questionnaire'
            ),
            isRequired: true,
            isValid: !!subscription?.germanSemiProfessionalCategorisation,
            showStep:
                endInvestorProfile?.onboardingJurisdictionCountryCode ===
                    'DE' &&
                endInvestorProfile?.clientType === 'semiProfessional' &&
                !isClient(ClientEnum.HG) &&
                !isClient(ClientEnum.ODDO),
        },
        // Leave the below 'Opt-Up Questionnaire' step in place for now
        {
            endPathUrl: 'opt-up-questionnaire',
            stepName: t('subscriptions.add.sidebar.opt_up_questionnaire'),
            isRequired: false,
            isValid: false,
            showStep: false,
        },
        {
            endPathUrl: 'source-of-funds-and-wealth',
            stepName: t('subscriptions.add.sidebar.source_of_funds'),
            isRequired: true,
            isValid: subscriptionsHelperValidation(null, subscription)
                .sourceOfFunds,
            showStep: (() => {
                if (!isRepeatedOpenEndedSubscription) {
                    if (isClient(ClientEnum.HG) || isClient(ClientEnum.HG)) {
                        return (
                            endInvestorProfile.endInvestorType !==
                            EndInvestorType.nomineeEntity
                        );
                    }
                    return true;
                }
                return false;
            })(),
        },
        {
            endPathUrl: 'type-of-investment',
            stepName: t('subscriptions.add.sidebar.type_of_investment'),
            isRequired: true,
            isValid:
                !!subscription?.typeOfInvestment?.isMadeOnBehalfOfThirdParty,
            showStep:
                endInvestorProfile.endInvestorType ===
                    EndInvestorType.nomineeEntity &&
                (isClient(ClientEnum.HG) || isClient(ClientEnum.ODDO)),
        },
        {
            endPathUrl: 'economic-origin-of-monies',
            stepName: t('subscriptions.add.sidebar.economic_origin_of_monies'),
            isRequired: true,
            isValid:
                subscription?.economicOriginOfMonies
                    ?.thirdPartyOriginOfFundsTypes?.length > 0,
            showStep:
                endInvestorProfile.endInvestorType ===
                    EndInvestorType.nomineeEntity &&
                (isClient(ClientEnum.HG) || isClient(ClientEnum.ODDO)),
        },
        {
            endPathUrl: 'banking-preferences',
            stepName: t('subscriptions.add.sidebar.banking_preferences'),
            isRequired: true,
            isValid: !!subscription?.distributionOfFunds,
            showStep: !isRepeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'beneficial-owners',
            stepName: t('subscriptions.add.sidebar.beneficial_owners'),
            isRequired: true,
            isValid: !!(
                subscription?.beneficialOwnership &&
                subscription?.beneficialOwnership?.ownershipConfirmation
            ),
            showStep:
                !(
                    isClient(ClientEnum.ODDO) &&
                    endInvestorProfile?.endInvestorType ===
                        EndInvestorType.individual
                ) &&
                !!feature?.subscriptionProcess?.enableBeneficiaryOwnerSection &&
                !isRepeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'politically-exposed-people',
            stepName: t('subscriptions.add.sidebar.politically_exposed_people'),
            isRequired: true,
            isValid: !!subscription?.politicalExposure,
            showStep: !isRepeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'subscription-contacts',
            stepName: t('subscriptions.add.sidebar.subscription_contacts'),
            isRequired: false,
            isValid: !!(
                subscription?.contacts && subscription.contacts.length > 0
            ),
            showStep: !isRepeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'tax-declarations',
            stepName: t('subscriptions.add.sidebar.tax_declarations'),
            isRequired: true,
            isValid: !!(
                subscription?.taxInformation?.entity?.taxCountries?.length >
                    0 ||
                subscription?.taxInformation?.individual?.taxCountries?.length >
                    0
            ),
            showStep:
                !!feature?.subscriptionProcess?.taxSection &&
                !isRepeatedOpenEndedSubscription,
        },
        {
            endPathUrl: 'supporting-documents',
            stepName: t('subscriptions.add.sidebar.supporting_documents'),
            isRequired: false,
            isValid: !!(subscription?.supportingDocuments?.length > 0),
            showStep: true,
        },
        {
            endPathUrl: 'attestations',
            stepName: t('subscriptions.add.sidebar.attestations'),
            isRequired: true,
            isValid: subscriptionsHelperValidation(client ?? null, subscription)
                .attestations,
            showStep: true,
        },
        {
            endPathUrl: 'representations-and-warranties',
            stepName: t(
                'subscriptions.add.sidebar.representations_and_warranties'
            ),
            isRequired: false,
            isValid: Boolean(subscription?.representationsAndWarranties),
            showStep: isClient(ClientEnum.ODDO),
        },
        {
            endPathUrl: 'review',
            stepName: t('subscriptions.add.sidebar.review'),
            isRequired: true,
            isValid:
                subscription?.status === SubscriptionStatusEnum.approved ||
                subscription?.status ===
                    SubscriptionStatusEnum.readyForReview ||
                subscription?.status === SubscriptionStatusEnum.rejected,
            showStep: true,
        },
        {
            endPathUrl: 'documents',
            stepName: t('subscriptions.add.sidebar.documents'),
            isRequired: false,
            isValid: !!(subscription
                ? hasSignedSubscriptionDocument(subscription)
                : false),
            showStep:
                subscription?.status !== SubscriptionStatusEnum.inProgress,
        },
        {
            endPathUrl: 'settlement-instructions',
            stepName: t('subscriptions.add.sidebar.settlement_instructions'),
            isRequired: false,
            isValid: false,
            showStep:
                subscription?.status === SubscriptionStatusEnum.approved &&
                isClient(ClientEnum.HG),
        },
    ].filter((a) => a.showStep);

    return steps;
};

const hasSignedSubscriptionDocument = (subscription: SubscriptionResponse) => {
    const signedDocuments = subscription?.signingDocuments?.filter(
        (document) =>
            document.status === SigningDocumentStatusEnum.signed &&
            document.signedDocument
    );
    return signedDocuments ? signedDocuments?.length > 0 : false;
};

export const getSubscriptionTemplateSteps = (
    t: TFunction<'translation', undefined>,
    endInvestorProfile?: EndInvestorProfileResponse,
    subscriptionTemplate?: SubscriptionTemplateResponse,
    feature?: Preferences['feature']
): SubscriptionStep[] => {
    if (!endInvestorProfile) {
        return [];
    }

    const steps = [
        {
            endPathUrl: 'template-details',
            stepName: t('subscription.add.details.title'),
            showStep: true,
            isValid: !!subscriptionTemplate?.templateName,
        },
        {
            endPathUrl: 'distributor-details',
            stepName: t('subscriptions.add.sidebar.distributor_details'),
            showStep: !isEndInvestorNomineeAccount(endInvestorProfile),
            isValid:
                !!subscriptionTemplate?.distributorDetails?.distributorName,
        },
        {
            endPathUrl: 'source-of-funds-and-wealth',
            stepName: t('subscriptions.add.sidebar.source_of_funds'),
            showStep: true,
            isValid: !!(
                subscriptionTemplate?.sourceOfFundsAndWealth
                    ?.describeSourceOfFunds &&
                subscriptionTemplate?.sourceOfFundsAndWealth
                    ?.describeSourceOfWealth
            ),
        },
        {
            endPathUrl: 'banking-preferences',
            stepName: t('subscriptions.add.sidebar.banking_preferences'),
            showStep: true,
            isValid: !!subscriptionTemplate?.distributionOfFunds,
        },
        {
            endPathUrl: 'beneficial-owners',
            stepName: t('subscriptions.add.sidebar.beneficial_owners'),
            showStep:
                !!feature?.subscriptionProcess?.enableBeneficiaryOwnerSection,
            isValid: !!(
                subscriptionTemplate?.beneficialOwnership &&
                subscriptionTemplate?.beneficialOwnership?.ownershipConfirmation
            ),
        },
        {
            endPathUrl: 'politically-exposed-people',
            stepName: t('subscriptions.add.sidebar.politically_exposed_people'),
            showStep: true,
            isValid: !!subscriptionTemplate?.politicalExposure,
        },
        {
            endPathUrl: 'subscription-contacts',
            stepName: t('subscriptions.add.sidebar.subscription_contacts'),
            showStep: true,
            isValid: !!(
                subscriptionTemplate?.contacts &&
                subscriptionTemplate?.contacts.length > 0
            ),
        },
        {
            endPathUrl: 'tax-declarations',
            stepName: t('subscriptions.add.sidebar.tax_declarations'),
            showStep: true,
            isValid: !!subscriptionTemplate?.taxInformation,
        },
    ].filter((a) => a.showStep);

    return steps;
};

export const handleCreateSubscriptionEvent = async (
    t: TFunction<'translation', undefined>,
    navigate: NavigateFunction,
    {
        endInvestorProfile,
        productId,
        subscriptionTemplateId,
        acceptRiskBeforeCreatingSubscription,
        verifyInvestorEmailBeforeNewSubscription,
        isOddo,
    }: {
        endInvestorProfile: EndInvestorProfileResponse;
        productId?: string;
        subscriptionTemplateId?: string;
        acceptRiskBeforeCreatingSubscription?: boolean;
        verifyInvestorEmailBeforeNewSubscription?: boolean;
        isOddo?: boolean;
    }
) => {
    const navigateToNewSubscription = async () => {
        const queryParams = [
            `endInvestorProfileId=${endInvestorProfile._id}`,
            //TODO not sure if we want to put this here
            acceptRiskBeforeCreatingSubscription && `acceptRisk=true`,
            productId && `productId=${productId}`,
            subscriptionTemplateId &&
                `subscriptionTemplateId=${subscriptionTemplateId}`,
        ].filter((a) => a);

        const url = ['/subscriptions/create', queryParams.join('&')].join('?');

        navigate(url, {});
    };

    const shouldVerifyInvestorEmailBeforeNewSubscription =
        isOddo && verifyInvestorEmailBeforeNewSubscription;

    const handleAcceptRiskBeforeCreatingSubscriptionModal = () => {
        ModalService.getInstance('risk-warning')
            .setTitle(t('client.risk_warning.title'))
            .setComponent(
                <VPRiskWarning
                    onAbort={() => {
                        ModalService.getInstance('risk-warning').hideModal();
                    }}
                    onAccept={async () => {
                        if (shouldVerifyInvestorEmailBeforeNewSubscription)
                            handleVerifyInvestorEmailBeforeNewSubscriptionModal();
                        else await navigateToNewSubscription();
                        ModalService.getInstance('risk-warning').hideModal();
                    }}
                />
            )
            .showModal();
    };

    const handleVerifyInvestorEmailBeforeNewSubscriptionModal = () => {
        ModalService.getInstance('verify-investor-email')
            .setTitle(t('client.verify_investor_details.title'))
            .setWidth('w-auto')
            .setComponent(
                <VerifyInvestorDetails
                    endInvestorProfile={endInvestorProfile}
                    onEditInvestorDetails={() => {
                        navigate(
                            `/clients/edit-investor/${endInvestorProfile._id}`
                        );
                        ModalService.getInstance(
                            'verify-investor-email'
                        ).hideModal();
                    }}
                    onAccept={async () => {
                        await navigateToNewSubscription();
                        ModalService.getInstance(
                            'verify-investor-email'
                        ).hideModal();
                    }}
                />
            )
            .showModal();
    };

    if (
        acceptRiskBeforeCreatingSubscription ||
        shouldVerifyInvestorEmailBeforeNewSubscription
    ) {
        if (acceptRiskBeforeCreatingSubscription)
            handleAcceptRiskBeforeCreatingSubscriptionModal();
        else if (shouldVerifyInvestorEmailBeforeNewSubscription)
            handleVerifyInvestorEmailBeforeNewSubscriptionModal();
    } else {
        await navigateToNewSubscription();
    }
};

export const placementFeeAppliesToSubscription = (
    endInvestorProfile: EndInvestorProfileResponse,
    configuration: ConfigurationResponse
): boolean => {
    // Placement fee is applicable only if it is enabled and if the investor is not a nominee entity - https://s64capital.atlassian.net/browse/AF-773

    const applies =
        !!configuration?.preferences?.feature?.subscriptionProcess
            ?.placementFeeApplies &&
        endInvestorProfile.endInvestorType !==
            EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

    return applies;
};

export const canCancelSubscription = (
    subscription: SubscriptionResponse,
    product: Partial<ProductResponse>,
    hasPermissions: (permissions: AuthPermissionEnum[]) => boolean
): boolean => {
    if (product.liquidityType == LiquidityTypeEnum.openEnded) {
        return false;
    }

    if (
        subscription.status === SubscriptionStatusEnum.readyForReview &&
        hasPermissions([
            AuthPermissionEnum.updateSubscriptionStatusFromReadyForReviewToCancelled,
        ])
    ) {
        return true;
    }

    // check if subscription has been approved and is still in the product cooling off period
    if (
        product.liquidityType == LiquidityTypeEnum.closedEnded &&
        subscription.status === SubscriptionStatusEnum.approved &&
        hasPermissions([
            AuthPermissionEnum.updateSubscriptionStatusFromApprovedToCancelled,
        ])
    ) {
        const subscriptionApprovedDate = new Date(
            subscription.statusLogs[
                subscription.statusLogs.length - 1
            ].createdAt
        );

        const canCancelApproved =
            // subscription is within cooling off period
            dayjs(subscriptionApprovedDate)
                .add(
                    product.closedEndedInformation?.coolingOffDays || 0,
                    'days'
                )
                .isAfter(new Date());

        return canCancelApproved;
    }

    return false;
};

export const doesDocumentTypeExist = (
    subscription: SubscriptionResponse,
    signingDocumentType: SigningDocumentTypeEnum
) =>
    !!subscription.signingDocuments?.find(
        (document) => document.signingDocumentType === signingDocumentType
    );

export const isDocumentSigned = (
    subscription: SubscriptionResponse | SubscriptionListItem,
    signingDocumentType: SigningDocumentTypeEnum
) => {
    return (
        subscription.signingDocuments?.find(
            (document) => document.signingDocumentType === signingDocumentType
        )?.status === SigningDocumentStatusEnum.signed
    );
};

export const inducementFeeApplies = (
    subscription: SubscriptionResponse
): boolean => {
    return subscription.status == SubscriptionStatusEnum.approved;
};
