import { useState } from 'react';
import { FieldArray } from 'formik';

import { Button } from '@components/Atoms';
import {
    DataReview,
    InlineFormFrame,
    FormInputBox,
    FormSelect,
} from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { isoCountriesOptions } from '@helpers/isoCountries';
import { isoCurrenciesOptions } from '@helpers/isoCurrencies';
import { useTranslation } from 'react-i18next';

export const BankAccountsSelection = () => {
    const { t } = useTranslation();
    const [editBankAccountIndex, setEditBankAccountIndex] = useState<number>(-1);
    
    const emptyBankDetails = {
        bankName: '',
        bankAddress: '',
        countryCurrencyCode: '',
        countryCodeOfAccount: '',
        IBAN: '',
        accountNumber: '',
        accountCode:'',
        accountHolderName:''
    };

    return (
        <div className="text-logo-blue">
            <h3 className="my-2 mt-8 text-2xl font-normal leading-8">
                {t('client.registration.bank_accounts.title')} *
            </h3>
            <p className="py-1 text-base">
                {t('client.registration.bank_accounts.instruction')}
            </p>

            <FieldArray
                name="bankAccounts"
                render={(arrayHelpers) => (
                    <div>
                        {arrayHelpers.form.values?.bankAccounts?.map(
                            (bankAccount: any, i: number, arr: any) =>
                                editBankAccountIndex === i ? (
                                    <InlineFormFrame
                                        key={i.toString()}
                                        primaryAction={t(
                                            'client.registration.bank_accounts.save_bank_account_button'
                                        )}
                                        onPrimaryAction={() => {
                                            const isBankAccountInvalid =
                                                arrayHelpers?.form?.errors
                                                    ?.bankAccounts &&
                                                Array.isArray(
                                                    arrayHelpers.form.errors
                                                        .bankAccounts
                                                );
                                            const touchedValues = {
                                                bankAccounts: [
                                                    ...arrayHelpers.form.values.bankAccounts.map(
                                                        (address: any) =>
                                                            Object.keys(
                                                                address
                                                            ).reduce(
                                                                (
                                                                    prev,
                                                                    curr
                                                                ) => {
                                                                    return {
                                                                        ...prev,
                                                                        [curr]: true,
                                                                    };
                                                                },
                                                                {}
                                                            )
                                                    ),
                                                ],
                                            };
                                            arrayHelpers.form.setTouched(
                                                touchedValues,
                                                true
                                            );
                                            if (!isBankAccountInvalid) {
                                                setEditBankAccountIndex(-1);
                                            }
                                        }}
                                        secondaryAction={t(
                                            'ui.controls.cancel'
                                        )}
                                        onSecondaryAction={() => {
                                            // check if all fields are empty
                                            const isBankAccountEmpty =
                                                Object.values(
                                                    arrayHelpers.form.values
                                                        .bankAccounts[i]
                                                ).filter(
                                                    (value) => value !== ''
                                                ).length === 0;
                                            if (isBankAccountEmpty) {
                                                arrayHelpers.remove(i);
                                            }
                                            setEditBankAccountIndex(-1);
                                        }}
                                    >
                                        <FormInputBox
                                            name={`bankAccounts[${i}].bankName`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.bankName.label
                                            }
                                            placeholder={t(
                                                'client.registration.bank_accounts.account_nickname.placeholder'
                                            )}
                                        />

                                        <FormInputBox
                                            name={`bankAccounts[${i}].bankAddress`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.bankAddress.label
                                            }
                                            placeholder={t(
                                                'client.registration.bank_accounts.account_address.placeholder'
                                            )}
                                        />

                                        <div className="bg-white rounded-lg">
                                            <FormSelect
                                                name={`bankAccounts[${i}].countryCurrencyCode`}
                                                label={
                                                    getClientRegistrationFormFields(
                                                        t
                                                    ).bankAccounts
                                                        .countryCurrencyCode
                                                        .label
                                                }
                                                optionsData={
                                                    isoCurrenciesOptions
                                                }
                                            />
                                        </div>

                                        <div className="bg-white rounded-lg">
                                            <FormSelect
                                                name={`bankAccounts[${i}].countryCodeOfAccount`}
                                                label={
                                                    getClientRegistrationFormFields(
                                                        t
                                                    ).bankAccounts
                                                        .countryCodeOfAccount
                                                        .label
                                                }
                                                optionsData={
                                                    isoCountriesOptions
                                                }
                                            />
                                        </div>

                                        <FormInputBox
                                            name={`bankAccounts[${i}].IBAN`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.IBAN.label
                                            }
                                            placeholder={t(
                                                'client.registration.bank_accounts.iban.placeholder'
                                            )}
                                        />

                                        <FormInputBox
                                            name={`bankAccounts[${i}].confirmIBAN`}
                                            label={t(
                                                'client.registration.bank_accounts.confirm_iban'
                                            )}
                                            placeholder={t(
                                                'client.registration.bank_accounts.confirm_iban.placeholder'
                                            )}
                                        />
                                        <FormInputBox
                                            name={`bankAccounts[${i}].accountNumber`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.accountNumber
                                                    .label
                                            }
                                            placeholder={t(
                                                'client.registration.bank_accounts.account_number.placeholder'
                                            )}
                                        />

                                        <FormInputBox
                                            name={`bankAccounts[${i}].accountHolderName`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.accountHolderName
                                                    .label
                                            }
                                            placeholder="Account holder name"
                                        />
                                        <FormInputBox
                                            name={`bankAccounts[${i}].accountCode`}
                                            label={
                                                getClientRegistrationFormFields(
                                                    t
                                                ).bankAccounts.accountCode.label
                                            }
                                            placeholder="BIC / SWIFT / Sort Code"
                                        />
                                    </InlineFormFrame>
                                ) : (
                                    /* eslint-disable */
                                    <DataReview
                                        key={i.toString()}
                                        onDelete={() => arrayHelpers.remove(i)}
                                        onEdit={() => {
                                            setEditBankAccountIndex(i);
                                        }}
                                    >
                                        <p className="text-sm">
                                            {bankAccount.bankName}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.countryCurrencyCode}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.countryCodeOfAccount}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.IBAN}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.accountNumber}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.accountHolderName}
                                        </p>
                                        <p className="text-sm">
                                            {bankAccount.accountCode}
                                        </p>
                                    </DataReview>
                                )
                        )}
                        {editBankAccountIndex === -1 && (
                            <Button
                                label={t(
                                    'client.registration.bank_accounts.add_bank_account_button'
                                )}
                                type="button"
                                buttonType="secondary"
                                className="w-full my-2"
                                onClick={() => {
                                    arrayHelpers.push(emptyBankDetails);
                                    setEditBankAccountIndex(arrayHelpers.form.values?.bankAccounts.length);
                                }}
                            />
                        )}
                    </div>
                )}
            />
        </div>
    );
};
