import { useEffect, useMemo } from 'react';

import { AuthPermissionEnum, AuthRoleEnum, Preferences } from '@interfaces/Api';
import { useAuthState } from '@contexts/AuthContext';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from 'common';

export interface AdminMenuItem {
    label: string;
    link: string;
    requiredPermissions: AuthPermissionEnum[];
}

const getDefaultAdminMenuItems = (t: (key) => string): AdminMenuItem[] => [
    {
        label: t('navbar.admin.user_management'),
        link: '/admin/users',
        requiredPermissions: [
            AuthPermissionEnum.updateSystemUser,
            AuthPermissionEnum.createSystemUser,
        ],
    },
    {
        label: t('navbar.admin.profile_management'),
        link: '/admin/profiles',
        requiredPermissions: [
            AuthPermissionEnum.addProfileUsers,
            AuthPermissionEnum.updateProfileUsers,
        ],
    },
    {
        label: t('navbar.admin.product_management'),
        link: '/admin/products',
        requiredPermissions: [
            AuthPermissionEnum.updateProduct,
            AuthPermissionEnum.createProduct,
        ],
    },
    {
        label: t('navbar.admin.booking_centre_management'),
        link: '/admin/booking-centres',
        requiredPermissions: [AuthPermissionEnum.createBookingCentre],
    },
    {
        label: t('navbar.admin.application_settings'),
        link: '/admin/application-settings',
        requiredPermissions: [AuthPermissionEnum.updateConfiguration],
    },
    {
        label: t('navbar.admin.preference_settings'),
        link: '/admin/preference-settings',
        requiredPermissions: [AuthPermissionEnum.updatePreference],
    },
    {
        label: t('navbar.admin.prospective_investors'),
        link: '/admin/prospective-investors',
        requiredPermissions: [
            AuthPermissionEnum.updateProspectiveInvestorStatus,
            AuthPermissionEnum.getProspectiveInvestors,
        ],
    },
    {
        label: t('navbar.admin.event_simulator'),
        link: '/admin/event-simulator',
        requiredPermissions: [AuthPermissionEnum.viewEventSimulator],
    },
    // {
    //     label: 'Demo data reset',
    //     link: '/admin/reset-demo-data',
    //     requiredPermissions: [AuthPermissionEnum.resetDemoData],
    // },
];

export const useMenuNavItems = (t: (key) => string) => {
    const { hasPermissions, currentUser } = useAuthState();
    const { preferences, isClient } = useConfigurationState();

    const defaultToolMenuItems = [
        {
            link: '/inducement-fee-calculator',
            name: t('navbar.inducement'),
            showItem: (
                authRole?: AuthRoleEnum,
                feature?: Preferences['feature']
            ) =>
                (authRole &&
                    feature &&
                    [
                        AuthRoleEnum.superUser,
                        AuthRoleEnum.administrator,
                        AuthRoleEnum.systemViewer,
                        AuthRoleEnum.relationshipManager,
                        AuthRoleEnum.externalRelationshipManager,
                    ].includes(authRole) &&
                    feature.topMenu?.displayInducementFeeCalculator) ||
                false,
        },
        {
            link: '/fund-raising',
            name: t('navbar.fundraising'),
            showItem: (authRole?: AuthRoleEnum) =>
                !isClient(ClientEnum.ODDO) && authRole &&
                [
                    AuthRoleEnum.superUser,
                    AuthRoleEnum.administrator,
                    AuthRoleEnum.relationshipManager,
                    AuthRoleEnum.externalRelationshipManager,
                    AuthRoleEnum.systemViewer,
                    AuthRoleEnum.assetManagerAdministrator,
                ].includes(authRole),
        },
        {
            link: '/documents',
            name: t('navbar.documents'),
            showItem: (
                authRole?: AuthRoleEnum,
                feature?: Preferences['feature']
            ) =>
                authRole &&
                feature &&
                [
                    AuthRoleEnum.superUser,
                    AuthRoleEnum.administrator,
                    AuthRoleEnum.systemViewer,
                    AuthRoleEnum.relationshipManager,
                    AuthRoleEnum.externalRelationshipManager,
                ].includes(authRole) &&
                !!feature.topMenu?.displayTemplateDocuments,
        },
        {
            link: '/tools/subscriptions',
            name: t('navbar.subscriptions_management'),
            showItem: (authRole?: AuthRoleEnum) =>
                authRole &&
                [
                    AuthRoleEnum.superUser,
                    AuthRoleEnum.administrator,
                    AuthRoleEnum.relationshipManager,
                    AuthRoleEnum.externalRelationshipManager,
                    AuthRoleEnum.systemViewer,
                    AuthRoleEnum.assetManagerAdministrator,
                ].includes(authRole),
        },
    ];

    const adminMenuItems: AdminMenuItem[] = useMemo(() => {
        let menuItems = getDefaultAdminMenuItems(t).filter((menuItem) =>
            hasPermissions(menuItem.requiredPermissions)
        );

        if (!preferences?.feature?.profile?.enableProfile) {
            menuItems = menuItems.filter(
                ({ link }) => link !== '/admin/profiles'
            );
        }

        if (!preferences?.feature?.b2C?.enableB2CRegistration) {
            // don't like this, should be on the menu item objects, this will become confusing
            menuItems = menuItems.filter(
                ({ link }) => link !== '/admin/prospective-investors'
            );
        }

        if (
            [
                AuthRoleEnum.profileManager,
                AuthRoleEnum.profileAdministrator,
            ].includes(currentUser?.user.role as AuthRoleEnum)
        ) {
            menuItems = menuItems.filter(({ link }) => link !== '/admin/users');
        }

        return menuItems;
    }, [currentUser?.user.role, hasPermissions, preferences?.feature?.b2C?.enableB2CRegistration, preferences?.feature?.profile?.enableProfile, t]);

    return { adminMenuItems, defaultToolMenuItems };
};
