import {
    FormInputBox,
    FormRadioGroup,
    FormSelect,
} from '@components/Molecules';
import { useState } from 'react';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

import { useMountEffect } from '@hooks/useMountEffect';
import { loginAccountChoiceType } from '@interfaces/Api/LoginAccountChoice';
import { getFilteredLoginAccounts } from '@stores/SystemUsers/SystemUsers.services';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LoginAccountReview } from './LoginAccountReview';

interface EndInvestorTypeLabelOption {
    label: string;
    value: loginAccountChoiceType;
    abstract?: string;
    disabled?: boolean;
}

export const LoginAccountSelection = () => {
    const { t } = useTranslation();
    const { data, setData } = useRegisterInvestorState();
    const [loginAccount, setLoginAccount] = useState<loginAccountChoiceType>(
        data.loginAccountChoice || 'newAccount'
    );
    const [systemUserOptions, setSystemUserOptions] = useState([]);

    const { endInvestorProfileId } = useParams<{
        endInvestorProfileId: string;
    }>();

    const isEdit = Boolean(endInvestorProfileId);

    useMountEffect(() => {
        const loadEndInvestorSystemUsers = async () =>
            await getFilteredLoginAccounts(
                data.intermediaryId,
                data.endInvestorType
            );

        loadEndInvestorSystemUsers().then((endInvestorSystemUsers: any) => {
            const mappedSystemUsers = endInvestorSystemUsers.map(
                (systemUser) => ({
                    label: `${systemUser.firstName} ${systemUser.surname} - ${systemUser.email}`,
                    value: systemUser._id,
                })
            );
            setSystemUserOptions(mappedSystemUsers);
        });
    });

    const endInvestorTypeLabelOptions: EndInvestorTypeLabelOption[] = [
        {
            label: t('client.registration.login_account.create_new_option'),
            value: 'newAccount',
        },
        {
            label: t('client.registration.login_account.use_existing_option'),
            value: 'existingAccount',
            ...(systemUserOptions.length === 0 && {
                abstract: t(
                    'client.registration.login_account.use_existing_option.no_accounts_to_map'
                ),
                disabled: true,
            }),
        },
        {
            label: t('client.registration.login_account.login_not_required'),
            value: 'noAccount',
        },
    ];

    useMountEffect;

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {getClientRegistrationFormFields(t).loginAccount.label}
            </h2>
            {isEdit ? (
                <LoginAccountReview />
            ) : (
                <>
                    <div className="mb-4">
                        <FormRadioGroup
                            name="loginAccountChoice"
                            label={t(
                                'client.registration.login_account.instruction'
                            )}
                            optionsData={endInvestorTypeLabelOptions}
                            value={loginAccount}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const value = e.target
                                    .value as loginAccountChoiceType;
                                setLoginAccount(value);
                                setData({
                                    ...data,
                                    systemUsers: [],
                                    loginAccountChoice: value,
                                });
                            }}
                        />
                    </div>
                    {loginAccount === 'newAccount' && (
                        <div className="mb-4">
                            <FormInputBox
                                name={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.firstName.name
                                }
                                label={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.firstName.label
                                }
                                placeholder={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.firstName.label
                                }
                                defaultValue={
                                    data?.individualDetails?.firstName
                                }
                            />
                            <FormInputBox
                                name={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.surname.name
                                }
                                label={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.surname.label
                                }
                                placeholder={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.surname.label
                                }
                                defaultValue={data?.individualDetails?.lastName}
                            />
                            <FormInputBox
                                name={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.email.name
                                }
                                label={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.email.label
                                }
                                defaultValue={
                                    data?.individualDetails?.emailAddress
                                }
                                placeholder={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.email.label
                                }
                            />
                        </div>
                    )}
                    {loginAccount === 'existingAccount' && (
                        <div className="mb-4">
                            <FormSelect
                                name={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.id.name
                                }
                                label={
                                    getClientRegistrationFormFields(t)
                                        .loginAccount.id.label
                                }
                                value={data?.systemUsers?.[0]?._id}
                                optionsData={systemUserOptions}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};
