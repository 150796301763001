import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icons } from '@components/Atoms';

export interface BreadcrumbsItem {
    displayText: string;
    navigationUrl: string;
    onClick?(url: string): void;
}

interface BasicBreadcrumbsProps {
    items: BreadcrumbsItem[];
}

export const BasicBreadcrumbs: FC<BasicBreadcrumbsProps> = ({ items }) => {
    const location = useLocation();

    return (
        <ul data-testid="breadcrumbs" className="list-reset flex items-center">
            <li className="mr-2">
                <Link to={'/'}>
                    <Icons name="HomeIcon" size="small" />
                </Link>
            </li>
            {items?.map(
                ({ displayText, navigationUrl, onClick }, index: number) => (
                    <li
                        key={`${displayText}_${index}`}
                        className="capitalize pointer-events-visible"
                    >
                        {navigationUrl ? (
                            <Link
                                to={navigationUrl}
                                state={{ location }}
                                replace
                                className="text-sm no-underline hover:underline hover:text-gray-500"
                                onClick={(event) => {
                                    if (onClick) {
                                        event.preventDefault();
                                        onClick(navigationUrl);
                                    }
                                }}
                            >
                                {displayText}
                            </Link>
                        ) : (
                            <span className="text-sm">{displayText}</span>
                        )}
                        {index !== items.length - 1 && (
                            <span className="p-1">/</span>
                        )}
                    </li>
                )
            )}
        </ul>
    );
};
