import { AuthPermissionEnum, SubscriptionStatusEnum } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import { Button, Icons } from '@components/Atoms';
import { AlertBox, ReviewFrame } from '@components/Molecules';

import { dateTimeFormatter } from '@helpers/Date.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useMemo, useState } from 'react';
import {
    CompletenessCheckType,
    CompletenessDecisionType,
    CompletenessDecisionTypeLabel,
} from 'common';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useParams } from 'react-router-dom';
import { SubscriptionReviewProps } from '@interfaces/SubscriptionReview';
import { GetPageProperties } from './SubscriptionReview.logic';
import classNames from 'classnames';
import { useRmDetermination } from '@tenants/Pages/ODDO/Hooks/useRmDetermination.helper';
import { useConfigurationState } from '@contexts/ConfigurationContext';

const InformationRequest = () => {
    const { hasPermissions } = useAuthState();
    const { subscription } = useSubscription();
    return (
        <div
            className={classNames('border-b mb-6', {
                hidden:
                    subscription?.completenessChecks?.status !==
                    CompletenessCheckType.requestMoreInformation,
            })}
        >
            <div className="mt-3 mb-6">
                <AlertBox
                    alertType="info"
                    title={'More Information Requested'}
                    message={
                        <p>
                            The submission file has been reviewed and additional
                            information has been requested.
                        </p>
                    }
                />
            </div>
        </div>
    );
};

export default InformationRequest;
