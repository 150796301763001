import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { useTranslation } from 'react-i18next';
import { ReviewItem } from './ReviewItem';
import { dateShortFormatter } from '@helpers/Date.helper';

const ReviewClientBusinessDetails = (
    props: ReviewClientSummaryDetailsProps
) => {
    const { t } = useTranslation();

    return (
        <div className="leading-5 mb-4">
            <ReviewItem
                label={t('subscriptions.entity_details.review.company_name')}
                value={props.data?.businessEntityDetails?.companyName}
            />
            <ReviewItem
                label={t(
                    'subscriptions.entity_details.review.registration_number'
                )}
                value={props.data?.businessEntityDetails?.registrationNumber}
            />
            <ReviewItem
                label={t(
                    'subscriptions.entity_details.review.subscribers_legal_form_number'
                )}
                value={props.data?.businessEntityDetails?.subscribersLegalForm}
            />
            <ReviewItem
                label={t(
                    'subscriptions.entity_details.review.subscribers_register'
                )}
                value={props.data?.businessEntityDetails?.subscribersRegister}
            />
            <ReviewItem
                label={t(
                    'subscriptions.entity_details.review.subscribers_parent_listing_authority'
                )}
                value={
                    props.data?.businessEntityDetails
                        ?.subscriberParentListingAuthority
                }
            />
            <ReviewItem
                label={t(
                    'subscriptions.entity_details.review.subscribers_parent_regulators'
                )}
                value={props.data?.businessEntityDetails?.subscribersRegulator}
            />
            <ReviewItem
                label={t('client.registration.business_details.corporate_type')}
                value={props.data?.businessEntityDetails?.corporateType}
            />
            <ReviewItem
                label={t('client.registration.business_details.parent_company')}
                value={props.data?.businessEntityDetails?.parentCompany}
            />
            <ReviewItem
                label={
                    t(
                        'client.registration.business_details.date_of_incorporation'
                    ) + '*'
                }
                value={
                    props.data?.businessEntityDetails?.dateOfIncorporation
                        ? dateShortFormatter(
                              new Date(
                                  props.data?.businessEntityDetails?.dateOfIncorporation
                              )
                          )
                        : ''
                }
            />
            <ReviewItem
                label={
                    t(
                        'client.registration.business_details.country_of_incorporation'
                    ) + '*'
                }
                value={
                    props.data?.businessEntityDetails
                        ?.countryCodeOfIncorporation
                }
            />

            <ReviewItem
                label={
                    t(
                        'client.registration.business_details.principal_place_of_business'
                    ) + '*'
                }
                value={
                    props.data?.businessEntityDetails
                        ?.principalPlaceOfBusinessCountryCode
                }
            />
            <ReviewItem
                label={
                    t('client.registration.business_details.area_of_activity') +
                    '*'
                }
                value={
                    props.data?.businessEntityDetails
                        ?.areaOfBusinessActivityType
                }
            />

            <ReviewItem
                label={t(
                    'client.registration.business_details.is_listed_on_stock_exchange'
                )}
                value={
                    props.data?.businessEntityDetails?.isListedOnStockExchange
                        ? t('ui.labels.yes')
                        : t('ui.labels.no')
                }
            />
            <ReviewItem
                label={t(
                    'client.registration.business_details.is_own_legal_personality'
                )}
                value={
                    props.data?.businessEntityDetails
                        ?.isEntityWithOwnLegalPersonality
                        ? t('ui.labels.yes')
                        : t('ui.labels.no')
                }
            />
            <ReviewItem
                label={t(
                    'client.registration.business_details.is_regulated_entity'
                )}
                value={
                    props.data?.businessEntityDetails?.isRegulatedEntity
                        ? t('ui.labels.yes')
                        : t('ui.labels.no')
                }
            />
            <ReviewItem
                label={t('client.registration.business_details.listing_code')}
                value={
                    props.data?.businessEntityDetails
                        ?.listedOnStockExchangeListingCode
                }
            />
            <ReviewItem
                label={t('client.registration.business_details.license_number')}
                value={props.data?.businessEntityDetails?.licenseNumber}
            />
            <ReviewItem
                label={t(
                    'client.registration.business_details.regulator_website_address'
                )}
                value={
                    props.data?.businessEntityDetails?.regulatorWebsiteAddress
                }
            />
        </div>
    );
};

export default ReviewClientBusinessDetails;
