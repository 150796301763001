import React from 'react';
import { Button, Icons } from '@components/Atoms';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { handleGetManualDraftSubscriptionAgreement } from '@stores/Subscriptions/Subscriptions.services';
import { useTranslation } from 'react-i18next';
import { getDocumentUrl } from '@api/Documents';

interface DownloadManualDraftSubscriptionAgreementProps {
    subscriptionId: string;
    buttonLabel?: string;
    subscriptionStatus: SubscriptionStatusEnum;
}

const DownloadManualDraftSubscriptionAgreement: React.FC<
    DownloadManualDraftSubscriptionAgreementProps
> = ({ subscriptionId, subscriptionStatus, buttonLabel }) => {
    const { t } = useTranslation();
    const { withToken } = useStorageTokenState();

    const downloadManualDraftSubscriptionAgreement = async () => {
        try {
            const res = await handleGetManualDraftSubscriptionAgreement(
                subscriptionId
            );
            window.location.href = await getDocumentUrl(
                withToken(res.draftDocument.url || '')
            );
            notifySuccess('Subscription Document has been downloaded');
        } catch (error) {
            notifyError('Unable to download Subscription Document');
        }
    };

    if (subscriptionStatus === SubscriptionStatusEnum.inProgress) {
        return (
            <Button
                buttonType="secondary"
                label={
                    buttonLabel || t('subscription.download_document_button')
                }
                endIcon={<Icons name="ArrowDownTrayIcon" size="small" />}
                onClick={downloadManualDraftSubscriptionAgreement}
            />
        );
    }

    return null;
};

export default DownloadManualDraftSubscriptionAgreement;
