import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de, en, es, fr, it, ja } from './Translations';

const resources = {
    de,
    en,
    es,
    fr,
    it,
    ja,
};

if (typeof en != typeof de) {
    throw new Error('Translation files are not of the same type');
}

i18n.use(initReactI18next) // passes i18n down to react-i18next

    // .use()
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources,
        // Need to correctly detect the language.
        lng: 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        returnEmptyString: false,
    });

// The below allows us to have types for translations
declare module 'react-i18next' {
    interface CustomTypeOptions {
        resources: typeof en;
    }
}
