import { ClientEnum } from 'common';
import { ITenantComponentProvider } from './ITenantComponentProvider';
import { HGComponents } from './HG/HGComponents';
import { DefaultComponents } from './Default/DefaultComponents';
import { ODDOComponents } from './ODDO/ODDOComponents';

interface ITenantComponentProviders {
    [key: string]: { new (): ITenantComponentProvider };
}

const test: ITenantComponentProviders = {
    [ClientEnum.HG]: HGComponents,
    [ClientEnum.ODDO]: ODDOComponents,
    null: null,
};

export class TenantComponentProviderFactory {
    static getTenantComponentProvider(
        clientEnum: ClientEnum
    ): ITenantComponentProvider | null {
        const tenantComponentProvider = test[clientEnum];

        if (tenantComponentProvider) {
            return new tenantComponentProvider();
        } else {
            console.log(
                `Tenant component provider for ${clientEnum} does not have an implementation`
            );
            return new DefaultComponents();
        }
    }
}
