import { useTranslation } from 'react-i18next';
import { BankAccount } from '@interfaces/Api';

import { isoCountries } from '@helpers/isoCountries';
import { isoCurrencies } from '@helpers/isoCurrencies';

interface ReviewDistributionOfFundsProps {
    bankAccount?: BankAccount;
}

function ReviewDistributionOfFunds(props: ReviewDistributionOfFundsProps) {
    const { t } = useTranslation();
    return (
        <div className="text-base leading-5">
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.bank_name'
                    )}
                </strong>
                {props.bankAccount?.bankName}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.bank_address'
                    )}
                </strong>
                {props.bankAccount?.bankAddress}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.currency'
                    )}
                </strong>
                {props.bankAccount?.countryCurrencyCode
                    ? isoCurrencies[props.bankAccount?.countryCurrencyCode]
                    : ''}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.country'
                    )}
                </strong>
                {props.bankAccount?.countryCodeOfAccount
                    ? isoCountries[props.bankAccount?.countryCodeOfAccount]
                    : ''}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.iban'
                    )}
                </strong>
                {props.bankAccount?.IBAN}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.accountNumber'
                    )}
                    :
                </strong>
                {props.bankAccount?.accountNumber}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.accountHolderName'
                    )}
                    :
                </strong>
                {props.bankAccount?.accountHolderName}
            </div>
            <div>
                <strong>
                    {t(
                        'subscriptions.add.subscription.review_subscription.accountCode'
                    )}
                    :
                </strong>
                {props.bankAccount?.accountCode}
            </div>
        </div>
    );
}

export default ReviewDistributionOfFunds;
