import {
    ArrowRightCircleIcon,
    ArrowRightOnRectangleIcon,
    Bars2Icon,
    Bars3Icon,
    CheckIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XMarkIcon as XMarkIconSolid,
} from '@heroicons/react/24/solid';

import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    ArrowUpCircleIcon,
    ArrowsUpDownIcon,
    CalendarDaysIcon,
    ClipboardDocumentCheckIcon,
    ClipboardDocumentIcon,
    ClockIcon,
    DocumentIcon as DocumentIconMini,
    EllipsisVerticalIcon,
    ExclamationTriangleIcon as ExclamationTriangleIconOutline,
    EyeIcon,
    EyeSlashIcon,
    GlobeEuropeAfricaIcon,
    HomeIcon,
    ListBulletIcon,
    LockClosedIcon,
    LockOpenIcon,
    PencilIcon,
    TrashIcon,
    UserIcon,
    ViewColumnsIcon,
    XMarkIcon,
    InformationCircleIcon as InformationCircleOutlineIcon,
} from '@heroicons/react/24/outline';

import classNames from 'classnames';
import { IconSort } from './Custom/IconSort';
import { WindowsLogo } from './Custom/WindowsLogo';
import { IconSortAsc } from './Custom/IconSortAsc';
import { IconSortDesc } from './Custom/IconSortDesc';
import { IconTemplateSolid } from './Custom/IconTemplateSolid';
import { Loading } from './Custom/Loading/Loading';

export type SizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'xxl' | 'xxxl';

export type IconType =
    | 'ArrowPathIcon'
    | 'ArrowUpCircleIcon'
    | 'ArrowDownTrayIcon'
    | 'ArrowRightOnRectangleIcon'
    | 'ArrowsUpDownIcon'
    | 'ArrowUpCircleIcon'
    | 'ArrowRightCircleIcon'
    | 'Bars2Icon'
    | 'Bars3Icon'
    | 'CalendarDaysIcon'
    | 'CheckIcon'
    | 'ChevronDown'
    | 'ChevronUp'
    | 'ChevronRight'
    | 'ClipboardDocumentIcon'
    | 'ClipboardDocumentCheckIcon'
    | 'ClockIcon'
    | 'DocumentIcon'
    | 'EllipsisVerticalIcon'
    | 'EyeIcon'
    | 'EyeSlashIcon'
    | 'ExclamationTriangleIcon'
    | 'ExclamationTriangleIconOutline'
    | 'GlobeEuropeAfricaIcon'
    | 'HomeIcon'
    | 'IconTemplateSolid'
    | 'IconSort'
    | 'IconSortAsc'
    | 'IconSortDesc'
    | 'InformationCircleIcon'
    | 'QuestionMarkCircleIcon'
    | 'LockClosedIcon'
    | 'LockOpenIcon'
    | 'Loading'
    | 'MagnifyingGlassIcon'
    | 'PencilIcon'
    | 'PlusIcon'
    | 'TrashIcon'
    | 'UserIcon'
    | 'ViewColumnsIcon'
    | 'XMarkIcon'
    | 'WindowsLogo'
    | 'XMarkIconSolid'
    | 'InformationCircleOutlineIcon'
    | 'ListBulletIcon'
    | 'WindowsLogo';

interface IconsProps {
    name: IconType;
    size?: SizeType;
    stroke?: string;
    strokeWidth?: string;
    classList?: string;
}

export const Icons: React.FC<IconsProps> = ({
    name,
    size,
    stroke,
    strokeWidth,
    classList,
}) => {
    const getDimensions = (size?: SizeType) => {
        let dimension = 'h-6 w-6';

        if (size === 'xsmall') dimension = 'h-3 w-3';
        if (size === 'small') dimension = 'h-4 w-4';
        if (size === 'large') dimension = 'h-8 w-8';
        if (size === 'xxl') dimension = 'h-20 w-20';

        return dimension;
    };

    const dimensions = getDimensions(size);

    const props = {
        className: classNames(dimensions, { [`${classList}`]: classList }),
        stroke: stroke || 'currentColor',
        strokeWidth,
    };

    return (
        <>
            {name === 'ArrowPathIcon' && <ArrowPathIcon {...props} />}
            {name === 'ArrowDownTrayIcon' && <ArrowDownTrayIcon {...props} />}
            {name === 'ArrowUpCircleIcon' && <ArrowUpCircleIcon {...props} />}
            {name === 'ArrowsUpDownIcon' && <ArrowsUpDownIcon {...props} />}
            {name === 'ArrowRightCircleIcon' && (
                <ArrowRightCircleIcon {...props} />
            )}

            {name === 'ArrowRightOnRectangleIcon' && (
                <ArrowRightOnRectangleIcon {...props} />
            )}

            {name === 'Bars2Icon' && <Bars2Icon {...props} />}

            {name === 'Bars3Icon' && <Bars3Icon {...props} />}

            {name === 'CalendarDaysIcon' && <CalendarDaysIcon {...props} />}

            {name === 'CheckIcon' && <CheckIcon {...props} />}

            {name === 'ChevronDown' && <ChevronDownIcon {...props} />}

            {name === 'ChevronUp' && <ChevronUpIcon {...props} />}

            {name === 'ChevronRight' && <ChevronRightIcon {...props} />}

            {name === 'ClockIcon' && <ClockIcon {...props} />}

            {name === 'DocumentIcon' && <DocumentIconMini {...props} />}

            {name === 'EllipsisVerticalIcon' && (
                <EllipsisVerticalIcon {...props} />
            )}

            {name === 'EyeIcon' && <EyeIcon {...props} />}

            {name === 'EyeSlashIcon' && <EyeSlashIcon {...props} />}

            {name === 'GlobeEuropeAfricaIcon' && (
                <GlobeEuropeAfricaIcon {...props} />
            )}

            {name === 'HomeIcon' && <HomeIcon {...props} />}

            {name === 'ExclamationTriangleIcon' && (
                <ExclamationTriangleIcon {...props} />
            )}

            {name === 'ExclamationTriangleIconOutline' && (
                <ExclamationTriangleIconOutline {...props} />
            )}

            {name === 'InformationCircleIcon' && (
                <InformationCircleIcon {...props} />
            )}

            {name === 'LockClosedIcon' && <LockClosedIcon {...props} />}

            {name === 'LockOpenIcon' && <LockOpenIcon {...props} />}

            {name === 'InformationCircleOutlineIcon' && (
                <InformationCircleOutlineIcon {...props} />
            )}

            {name === 'PencilIcon' && <PencilIcon {...props} />}

            {name === 'PlusIcon' && <PlusIcon {...props} />}

            {name === 'UserIcon' && <UserIcon {...props} />}

            {name === 'TrashIcon' && <TrashIcon {...props} />}

            {name === 'ListBulletIcon' && <ListBulletIcon {...props} />}

            {name === 'ViewColumnsIcon' && <ViewColumnsIcon {...props} />}

            {name === 'XMarkIcon' && <XMarkIcon {...props} />}
            {name === 'XMarkIconSolid' && <XMarkIconSolid {...props} />}

            {name === 'ClipboardDocumentIcon' && (
                <ClipboardDocumentIcon {...props} />
            )}

            {name === 'ClipboardDocumentCheckIcon' && (
                <ClipboardDocumentCheckIcon {...props} />
            )}

            {/*CUSTOM ICONS*/}
            {name === 'Loading' && <Loading />}
            {name === 'IconSort' && (
                <IconSort className={getDimensions(size)} />
            )}
            {name === 'IconSortAsc' && (
                <IconSortAsc
                    strokeWidth={strokeWidth}
                    className={getDimensions(size)}
                />
            )}
            {name === 'IconSortDesc' && (
                <IconSortDesc
                    strokeWidth={strokeWidth}
                    className={getDimensions(size)}
                />
            )}
            {name === 'IconTemplateSolid' && <IconTemplateSolid />}
            {name === 'WindowsLogo' && (
                <WindowsLogo className={getDimensions(size)} />
            )}
            {name === 'QuestionMarkCircleIcon' && (
                <QuestionMarkCircleIcon className={getDimensions(size)} />
            )}
            {name === 'WindowsLogo' && (
                <WindowsLogo className={getDimensions(size)} />
            )}
        </>
    );
};

Icons.defaultProps = {
    size: 'medium',
};
