import { Button, Icons } from '@components/Atoms';
import {
    FormInputBox,
    FormMultiselect,
    FormMultiselectCreatable,
    Modal,
} from '@components/Molecules';
import { FileUploadAsync, SortableList } from '@components/Organisms';
import { useProductSubmit } from '@hooks/useProductSubmit';
import { ProductResponse } from '@interfaces/Api';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EditProductFormikButtons from './EditProductFormikButtons';
import { notNull } from '@helpers/typeGuards';
import { ModalService } from '@services/ModalService';
import { Document } from '@interfaces/Api';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useProduct } from '@stores/Products/useProduct';
import { ReportDocument } from '@containers/Subscriptions/InvestmentReports/SubscriptionInvestmentReportsDocs';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';
import { useTranslation } from 'react-i18next';

interface DocumentRowProps {
    document: Document;
    onEditDocument?: (document: Document) => void;
    onDeleteDocument?: (document: Document) => void;
}

export const DocumentRow: React.FC<DocumentRowProps> = (props) => {
    const { document: document, onEditDocument, onDeleteDocument } = props;
    return (
        <div className="w-full flex">
            <div className="w-5"></div>
            <div
                className={classNames('pr-0.5 flex-auto', {
                    'pl-5': document.folderName,
                })}
            >
                <DocumentLinkRender
                    href={document.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    {[document.label, document.fileName]
                        .filter((a) => a)
                        .join(' - ')}
                </DocumentLinkRender>
            </div>
            <div className="w-14 px-0.5">
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        onEditDocument!(document);
                    }}
                    hasIconOnly
                    startIcon={<Icons name="PencilIcon" strokeWidth="2" />}
                />

                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        onDeleteDocument!(document);
                    }}
                    hasIconOnly
                    startIcon={<Icons name="TrashIcon" strokeWidth="1.5" />}
                />
            </div>
        </div>
    );
};

interface FolderSectionProps {
    folderName: string;
    documents: Document[] | ReportDocument[];
    onEditDocument?: (document: Document) => void;
    onDeleteDocument?: (document: Document) => void;
    onSorted?: (documents: Document[]) => void;
}

const FolderSection: React.FC<FolderSectionProps> = (props) => {
    const {
        folderName,
        documents,
        onEditDocument,
        onDeleteDocument,
        onSorted,
    } = props;
    const [folderOpen, setFolderOpen] = useState<boolean>(false);

    return (
        <div className="pb-2">
            <div className="flex ">
                <div className="w-5">
                    {folderOpen ? (
                        <Button
                            type="button"
                            hasIconOnly={true}
                            startIcon={
                                <Icons
                                    size="xsmall"
                                    name={'ChevronDown'}
                                    classList="pointer-cursor"
                                />
                            }
                            onClick={() => {
                                setFolderOpen(false);
                            }}
                        />
                    ) : (
                        <Button
                            type="button"
                            hasIconOnly={true}
                            startIcon={
                                <Icons
                                    size="xsmall"
                                    name={'ChevronRight'}
                                    classList="pointer-cursor"
                                />
                            }
                            onClick={() => {
                                setFolderOpen(true);
                            }}
                        />
                    )}
                </div>

                <div className="pr-0.5">
                    <span className="">{folderName}</span>
                </div>
            </div>
            {folderOpen && (
                <SortableList
                    onSorted={(a) => {
                        // set new list order

                        onSorted!(a);
                    }}
                    handleClassName="relative inset"
                    listItems={documents}
                    listElements={documents?.map((file, index) => (
                        <DocumentRow
                            key={index}
                            document={file as Document}
                            onEditDocument={onEditDocument}
                            onDeleteDocument={onDeleteDocument}
                        />
                    ))}
                />
            )}
        </div>
    );
};

const Documents: React.FC = (props) => {
    const { t } = useTranslation();
    const title = t('admin.product.update_documents');

    const { productId } = useParams();
    const { product } = useProduct(productId);

    const [editDocument, setEditDocument] = useState<{
        show: boolean;
        document?: {
            label?: string;
            fileName: string;
            folderName?: { value: string; label: string };
            url: string;
        };
    }>({ show: false });
    const documentFolders = product?.documentFolders || [];
    const [folderList, setFolderList] = useState<string[]>(documentFolders);

    const initialVals = {
        documents: [],
    };

    const [formValue, setFormValue] =
        useState<Partial<ProductResponse>>(initialVals);

    useEffect(() => {
        if (product) {
            setFormValue(product);
            setFolderList(product?.documentFolders || []);
        }
    }, [product]);

    const handleFolderNameChange = useCallback(
        (newFolderName: {
            label: string;
            value: string;
            __isNew__?: boolean;
        }) => {
            const findInFolderList = folderList.find(
                (folder) => folder === newFolderName.label
            );

            if (!findInFolderList) {
                setFolderList([...folderList, newFolderName.value]);
            }
        },
        [folderList]
    );

    const removeDocument = useCallback((documentIndex) => {
        ModalService.getInstance()
            .setTitle(t('admin.product.delete_document'))
            .setWidth('300px')
            .setComponent(
                <p>
                    <span className="font-bold text-red-500 block">
                       {t('client.risk_warning.warning')}!
                    </span>
                       {t('admin.product.are_you_sure_you_want_to_delete_this_document')}
                </p>
            )
            .setShowFooter(true)
            .setConfirmationLabel('Delete')
            .setConfirmCallback(() => {
                // save documents without this one and update folder list
                setFormValue((formVal) => {
                    const documents = formVal.documents || [];
                    return {
                        ...formVal,
                        documents: [
                            ...documents.slice(0, documentIndex),
                            ...documents.slice(documentIndex + 1),
                        ],
                    };
                });
                ModalService.getInstance().hideModal();
            })
            .showModal();
    }, []);

    const onSubmit = useProductSubmit(product?._id, `logo`);

    const handleSubmit = (values: Partial<ProductResponse>) => {
        const submissionValues: Partial<ProductResponse> = {
            ...values,
            documents: [...(values.documents || [])],
            documentFolders: folderList,
        };
        onSubmit(submissionValues);
    };

    const folderDocuments = useCallback(
        (
            values: Partial<ProductResponse>
        ): {
            folders: { name: string; documents: Document[] }[];
            documents: Document[];
        } => {
            const folders: { name: string; documents: Document[] }[] = [];
            // organise files into appropriate folders
            const documents: Document[] = [];
            const formDocuments = values.documents || [];
            formDocuments.forEach((document) => {
                const folderName = document.folderName;
                if (folderName) {
                    const folder = folders.find((f) => f.name === folderName);
                    if (folder) {
                        folder.documents.push(document);
                    } else {
                        folders.push({
                            name: folderName,
                            documents: [document],
                        });
                    }
                } else {
                    documents.push(document);
                }
            });
            return { folders, documents };
        },
        []
    );

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{title}</h1>
            <Formik
                initialValues={formValue}
                enableReinitialize
                onSubmit={handleSubmit}
            >
                {({ values }) => {
                    const productDocuments = values.documents || [];

                    const editDocument = (document: Document) => {
                        setEditDocument({
                            show: true,
                            document: {
                                label: document.label,
                                fileName: document.fileName || '',
                                folderName: document.folderName
                                    ? {
                                          value: document.folderName,
                                          label: document.folderName,
                                      }
                                    : undefined,
                                url: document.url,
                            },
                        });
                    };

                    const deleteDocument = (document: Document) => {
                        // find index of document
                        const documentIndex = productDocuments.findIndex(
                            (d) => d.url === document.url
                        );
                        if (documentIndex !== -1) {
                            removeDocument(documentIndex);
                        }
                    };

                    return (
                        <Form>
                            <FileUploadAsync
                                name="documents"
                                fileLabel="productDocument"
                                hasLabel={true}
                                hideFileList={true}
                            />
                            <div>
                                {/* header */}
                                <div className="flex flex-row">
                                    <div className="w-5"></div>
                                    <div className="font-bold">{t('product.name')}</div>
                                </div>
                                {/* rows */}
                                <div>
                                    {/* documents with folders */}
                                    {folderDocuments(values).folders.map(
                                        (a, i) => (
                                            <FolderSection
                                                key={i}
                                                folderName={a.name}
                                                documents={a.documents}
                                                onEditDocument={editDocument}
                                                onDeleteDocument={
                                                    deleteDocument
                                                }
                                                onSorted={(a) => {
                                                    setFormValue((formVal) => {
                                                        // reorders the current folder documents
                                                        const slice1Folders =
                                                            i > 0
                                                                ? folderDocuments(
                                                                      values
                                                                  ).folders.slice(
                                                                      0,
                                                                      i
                                                                  )
                                                                : [];
                                                        const slice1Documents =
                                                            slice1Folders
                                                                .map(
                                                                    (a) =>
                                                                        a.documents
                                                                )
                                                                .flat();

                                                        const slice2Folders =
                                                            i <
                                                            folderDocuments(
                                                                values
                                                            ).folders.length -
                                                                1
                                                                ? folderDocuments(
                                                                      values
                                                                  ).folders.slice(
                                                                      i + 1
                                                                  )
                                                                : [];
                                                        const slice2Documents =
                                                            slice2Folders
                                                                .map(
                                                                    (a) =>
                                                                        a.documents
                                                                )
                                                                .flat();

                                                        const orderedDocuments =
                                                            [
                                                                slice1Documents,
                                                                a,
                                                                slice2Documents,
                                                                folderDocuments(
                                                                    values
                                                                ).documents,
                                                            ].flat();

                                                        return {
                                                            ...formVal,
                                                            documents:
                                                                orderedDocuments,
                                                        };
                                                    });
                                                }}
                                            />
                                        )
                                    )}
                                    {/* documents without folders */}
                                    <SortableList
                                        onSorted={(a) => {
                                            setFormValue((formVal) => {
                                                const documents =
                                                    folderDocuments(values)
                                                        .folders.map(
                                                            (a) => a.documents
                                                        )
                                                        .flat();
                                                return {
                                                    ...formVal,
                                                    documents: [
                                                        ...documents,
                                                        ...a,
                                                    ],
                                                };
                                            });
                                        }}
                                        handleClassName="relative inset"
                                        listItems={
                                            folderDocuments(values).documents
                                        }
                                        listElements={folderDocuments(
                                            values
                                        ).documents?.map((file, index) => (
                                            <DocumentRow
                                                key={index}
                                                document={file}
                                                onEditDocument={editDocument}
                                                onDeleteDocument={
                                                    deleteDocument
                                                }
                                            />
                                        ))}
                                    />
                                </div>
                            </div>

                            <EditProductFormikButtons
                                productId={product?._id}
                                isReadOnly={false}
                                hasPreview
                                pathPrevious="product-videos"
                                pathNext="logo"
                                previewLinkTo={`../../../products/${product?.slug}`}
                            />
                        </Form>
                    );
                }}
            </Formik>

            {/* Modal for editing a certain document */}
            <Modal
                show={editDocument.show}
                onBackdropClick={() => setEditDocument({ show: false })}
            >
                <Modal.Header
                    onClose={() => {
                        setEditDocument({ show: false });
                    }}
                >
                    {t('admin.product.edit_file')}
                </Modal.Header>

                {editDocument.document && (
                    <Formik
                        initialValues={editDocument.document}
                        enableReinitialize
                        onSubmit={(val) => {
                            const originalDocuments = formValue.documents || [];
                            const documentIndex = originalDocuments?.findIndex(
                                (doc) => doc.url === val.url
                            );

                            if (
                                documentIndex !== undefined &&
                                documentIndex !== -1
                            ) {
                                originalDocuments[documentIndex] = {
                                    ...originalDocuments[documentIndex],
                                    ...val,
                                    folderName: val.folderName?.value,
                                };
                                setFormValue((a) => ({
                                    ...a,
                                    documents: originalDocuments,
                                }));

                                const folderList = originalDocuments
                                    .map((doc) => doc.folderName)
                                    .filter(notNull)
                                    .filter(
                                        (folder, index, self) =>
                                            self.indexOf(folder) === index
                                    );
                                setFolderList([...folderList]);
                            }

                            // close this modal
                            setEditDocument({ show: false });
                        }}
                    >
                        <Form>
                            <Modal.Body>
                                <p className="mb-4">
                                    {t('admin.product.file_name')}: {editDocument.document.fileName}
                                </p>
                                <FormInputBox
                                    name={`label`}
                                    label={'File Label'}
                                />

                                <FormMultiselectCreatable
                                    name="folderName"
                                    label="Folder"
                                    placeholder="Select or create a folder"
                                    options={folderList.map((a) => ({
                                        value: a,
                                        label: a,
                                    }))}
                                    isSingle={true}
                                    isFieldValuesArray={false}
                                    onChange={(newFolderName) => {
                                        handleFolderNameChange(newFolderName);
                                    }}
                                    isClearable={true}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    type="button"
                                    label={t('ui.controls.cancel')}
                                    buttonType="secondary"
                                    onClick={() =>
                                        setEditDocument({ show: false })
                                    }
                                />
                                <Button
                                    type="submit"
                                    label={t('ui.controls.save')}
                                    buttonType="primary"
                                />
                            </Modal.Footer>
                        </Form>
                    </Formik>
                )}
            </Modal>
        </div>
    );
};

export default Documents;
