import { ClientEnum, EndInvestorType } from "common";
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { FormInputBox, FormSelect } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

export const JurisdictionSelection: React.FC = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { investorJurisdictions = [] } = useEndInvestorProfileState();
    const { isClient } = useConfigurationState();

    const onboardingJurisdictionLabelOptions = investorJurisdictions.map(
        (data) => ({
            label: data.jurisdictionCountry,
            value: data.countryCode,
        })
    ).sort((a, b) => a.label.localeCompare(b.label));

    const fieldName  = getClientRegistrationFormFields(t)
    .onboardingJurisdictionCountryCode.name;
    const [{value: onboardingJurisdictionCountryCode}] = useField(fieldName);

    return (
        <>
            <h2 className="py-3 text-2xl pt-7">{t('client.registration.client_relationship.onboarding_jurisdiction')}</h2>
            <div>
                {investorJurisdictions.length === 0 ? (
                    <div className="container mx-auto">
                        <p className="text-xl">
                            Onboarding Country not loading!
                        </p>
                        <p>Sorry! There are no countries currently listed!</p>
                    </div>
                ) : (
                    <>
                        <FormSelect
                            dataTestId={fieldName}
                            name={fieldName}
                            label={
                                isClient(ClientEnum.VP)
                                    ? 'Domicile'
                                    : getClientRegistrationFormFields(t)
                                        .onboardingJurisdictionCountryCode.label
                            }
                            optionsData={onboardingJurisdictionLabelOptions}
                        />
                        {
                            isClient(ClientEnum.ODDO) && onboardingJurisdictionCountryCode === 'FR' && data.endInvestorType !== EndInvestorType.businessEntity && <FormInputBox
                                name={
                                    getClientRegistrationFormFields(t).departOfBirth
                                        .name
                                }
                                label={
                                    getClientRegistrationFormFields(t).departOfBirth
                                        .label
                                }
                                placeholder={
                                    getClientRegistrationFormFields(t).departOfBirth
                                        .label
                                }
                            />
                        }
                    </>
                )}
            </div>
        </>
    );
};
