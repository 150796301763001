import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const BankAccountsSummary = (): JSX.Element => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();

    return (
        <div>
            <span className="mr-2 font-bold">
                {t('client.registration.summary.bank_accounts')}:
            </span>
            {data.individualDetails?.bankAccounts?.map((bankAccount) => (
                <div className="mr-2" key={bankAccount.IBAN}>
                    <p>{bankAccount.bankName}</p>
                    <p>{bankAccount.bankAddress}</p>
                    <p>{bankAccount.countryCurrencyCode}</p>
                    <p>{bankAccount.countryCodeOfAccount}</p>
                    <p>{bankAccount.IBAN}</p>
                    <p>{bankAccount.accountNumber}</p>
                    <p>{bankAccount.accountHolderName}</p>
                    <p>{bankAccount.accountCode}</p>
                </div>
            ))}
        </div>
    );
};
