import { useEffect, useState } from 'react';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { FormSelect } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { InvestorType } from '@interfaces/Api/InvestorJurisdictionsResponse';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export const ClientTypeSelection = () => {
    const { t } = useTranslation();
    const { investorJurisdictions = [] } = useEndInvestorProfileState();
    const { data } = useRegisterInvestorState();
    const { handleChange } = useFormikContext();

    const [clientTypeOptionsData, setClientTypeOptionsData] = useState<
        InvestorType[]
    >([]);
    const [selection, setSelection] = useState<string>('');

    useEffect(() => {
        const getClientTypeOptionsData = () => {
            const jurisdiction = investorJurisdictions?.find(
                (investorJurisdiction) =>
                    investorJurisdiction.countryCode ===
                    data?.onboardingJurisdictionCountryCode
            );

            if (jurisdiction) {
                return jurisdiction.investorTypes.map((investorType) => ({
                    label: investorType.label,
                    value: investorType.value,
                    description: investorType.description,
                }));
            }

            return [];
        };

        const clientTypeOptionsData = getClientTypeOptionsData();
        setClientTypeOptionsData(clientTypeOptionsData);
    }, [investorJurisdictions, data.onboardingJurisdictionCountryCode]);

    const description = clientTypeOptionsData.find(
        (clientTypeOption) =>
            clientTypeOption.value === (selection || data['clientType'])
    )?.description;

    return (
        <>
            <h2 className="pt-7 py-3 text-2xl">
                {t('client.registration.client_type.title')}
            </h2>

            <div>
                {clientTypeOptionsData.length > 0 && (
                    <FormSelect
                        dataTestId={
                            getClientRegistrationFormFields(t).clientType.name
                        }
                        name={
                            getClientRegistrationFormFields(t).clientType.name
                        }
                        label={
                            getClientRegistrationFormFields(t).clientType.label
                        }
                        optionsData={clientTypeOptionsData}
                        onChange={(e) => {
                            setSelection(e.target.value);
                            handleChange(e);
                        }}
                    />
                )}

                <div className="px-0 leading-2 w-full">
                    <pre className="whitespace-pre-line text-sm leading-6 font-sans">
                        {description}
                    </pre>
                </div>
            </div>
        </>
    );
};
