import React from 'react';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useProductSubmit } from '@hooks/useProductSubmit';
import { isoCountriesOptions } from '@helpers/isoCountries';

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';
import { FormMultiselect } from '@components/Molecules';
import { useProduct } from '@stores/Products/useProduct';
import { useTranslation } from 'react-i18next';

const EligibilityExclusion: React.FC = () => {
    const { t } = useTranslation();
    const { productId } = useParams();
    const { product } = useProduct(productId);

    const selectedValidationSchema = Yup.object().shape({
        excludedCountryCodes: Yup.array().of(Yup.string().trim().required()),
    });

    const onSubmit = useProductSubmit(productId, `shareclass-information`);

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">
                {t('admin.product.excluded_investor_countries')}
            </h1>
            {(productId === 'new' || productId) && (
                <Formik
                    initialValues={{
                        excludedCountryCodes:
                            product?.eligibility?.excludedCountryCodes,
                    }}
                    onSubmit={(values) => {
                        onSubmit({
                            ...product,
                            eligibility: {
                                ...product?.eligibility,
                                excludedCountryCodes:
                                    values.excludedCountryCodes,
                            },
                        });
                    }}
                    validationSchema={selectedValidationSchema}
                    validateOnMount
                    enableReinitialize
                >
                    <Form className="mb-3">
                        <h3 className="text-sm font-normal leading-8 mb-3">
                            {t('admin.product.add_to_exclusion_list')}
                        </h3>
                        <FormMultiselect
                            id="excludedCountryCodes"
                            label={t('admin.product.countries')}
                            isFieldValuesArray
                            name="excludedCountryCodes"
                            options={isoCountriesOptions}
                        />

                        <EditProductFormikButtons
                            productId={productId}
                            isReadOnly={false}
                            pathPrevious="product-eligibility"
                            pathNext="shareclass-information"
                        />
                    </Form>
                </Formik>
            )}
        </div>
    );
};

export default EligibilityExclusion;
