import React, { useCallback, useState } from 'react';
import { Document } from '@interfaces/Api';
import { Icons } from '@components/Atoms';
import { DownloadLink } from '@components/Molecules';
import classNames from 'classnames';

export type ReportDocument = {
    document: Document;
    createdAt?: Date | string;
    sectionName?: string;
};

export interface SubscriptionInvestmentReportsDocsInterface {
    title?: string;
    reportDocuments?: ReportDocument[];
    showSectionFolders?: boolean;
}

interface DocumentRowProps {
    reportDocument: ReportDocument;
    indent: boolean;
}

const DocumentRow: React.FC<DocumentRowProps> = ({
    reportDocument,
    indent = false,
}) => {
    return (
        <tr className="whitespace-nowrap border-b align-top flex items-baseline hover:bg-gray-100">
            <td className={`flex-1 p-4 ${indent ? 'pl-20' : ''}`}>
                <DownloadLink
                    document={reportDocument.document}
                    linkTextType={
                        reportDocument.document.label ? 'label' : 'fileName'
                    }
                    linkType="secondary"
                    iconPosition="right"
                />
            </td>
            <td className="p-4">
                <p className="text-base">
                    {reportDocument.createdAt
                        ? new Date(
                              String(reportDocument.createdAt)
                          ).toLocaleDateString()
                        : ''}
                </p>
            </td>
        </tr>
    );
};

interface FolderProps {
    folderName: string;
    reportDocuments: ReportDocument[];
    onSorted?: (documents: Document[]) => void;
    indent?: boolean;
    showSectionFolders: boolean;
}

const Folder: React.FC<FolderProps> = (props) => {
    const {
        folderName,
        reportDocuments,
        indent = false,
        showSectionFolders,
    } = props;
    const [folderOpen, setFolderOpen] = useState<boolean>(false);

    return (
        <div>
            <div
                className={`flex py-4 border-b ${
                    indent && showSectionFolders ? 'pl-10' : ''
                }`}
            >
                <div className={`w-5 `}>
                    <button
                        type="button"
                        onClick={() => {
                            setFolderOpen(!folderOpen);
                        }}
                        className={classNames({
                            'font-bold': folderOpen,
                        })}
                    >
                        <Icons
                            size="xsmall"
                            name={folderOpen ? 'ChevronDown' : 'ChevronRight'}
                        />
                    </button>
                </div>

                <div className="pr-0.5">
                    <span
                        className="font-bold"
                        onClick={() => {
                            setFolderOpen(!folderOpen);
                        }}
                    >
                        {folderName}
                    </span>
                </div>
            </div>
            {folderOpen &&
                reportDocuments?.map((reportDocument, index) => (
                    <div key={index}>
                        <DocumentRow
                            reportDocument={reportDocument}
                            indent={true}
                        />
                    </div>
                ))}
        </div>
    );
};

interface SectionFolderProps {
    folderName: string;
}

const SectionFolder: React.FC<SectionFolderProps> = (props) => {
    const { folderName, children } = props;
    const [folderOpen, setFolderOpen] = useState<boolean>(false);

    return (
        <div>
            <div className="flex py-4 border-b">
                <div className="w-5">
                    {folderOpen ? (
                        <button
                            type="button"
                            onClick={() => {
                                setFolderOpen(!folderOpen);
                            }}
                            className="font-bold"
                        >
                            <Icons size="xsmall" name={'ChevronDown'} />
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={() => {
                                setFolderOpen(!folderOpen);
                            }}
                        >
                            <Icons size="xsmall" name={'ChevronRight'} />
                        </button>
                    )}
                </div>

                <div className="pr-0.5">
                    <span
                        className="font-bold"
                        onClick={() => {
                            setFolderOpen(!folderOpen);
                        }}
                    >
                        {folderName}
                    </span>
                </div>
            </div>
            {folderOpen && children}
        </div>
    );
};

const SubscriptionInvestmentReportsDocs: React.FC<
    SubscriptionInvestmentReportsDocsInterface
> = ({ title = '', reportDocuments = [], showSectionFolders = true }) => {
    const sectionFolders = useCallback(
        (
            reportDocuments: ReportDocument[]
        ): {
            sectionFolders: { name: string; documents: ReportDocument[] }[];
        } => {
            const sectionFolders: {
                name: string;
                documents: ReportDocument[];
            }[] = [];
            // organise files into appropriate sections
            reportDocuments.forEach((reportDocument) => {
                const { sectionName } = reportDocument;
                if (sectionName) {
                    const folder = sectionFolders.find(
                        (f) => f.name === sectionName
                    );
                    if (folder) {
                        folder.documents.push(reportDocument);
                    } else {
                        sectionFolders.push({
                            name: sectionName,
                            documents: [reportDocument],
                        });
                    }
                }
            });
            return { sectionFolders: sectionFolders };
        },
        []
    );

    const folderDocuments = useCallback(
        (
            reportDocuments: ReportDocument[]
        ): {
            folders: { name: string; documents: ReportDocument[] }[];
            reportDocuments: ReportDocument[];
        } => {
            const folders: { name: string; documents: ReportDocument[] }[] = [];
            // organise files into appropriate folders
            const documents: Document[] = [];
            const formDocuments = reportDocuments || [];
            formDocuments.forEach((reportDocument) => {
                const { document } = reportDocument;
                const folderName = document.folderName;
                if (folderName) {
                    const folder = folders.find((f) => f.name === folderName);
                    if (folder) {
                        folder.documents.push(reportDocument);
                    } else {
                        folders.push({
                            name: folderName,
                            documents: [reportDocument],
                        });
                    }
                } else {
                    documents.push(document);
                }
            });
            return { folders, reportDocuments };
        },
        []
    );

    if (!reportDocuments)
        return (
            <div>
                <h1 className="text-base my-2">
                    Sorry! No document data currently available!
                </h1>
            </div>
        );

    const rows = (documents: ReportDocument[]) => {
        return (
            <>
                {/* rows */}
                <div>
                    {/* documents without folders */}
                    {folderDocuments(documents).reportDocuments?.map(
                        (reportDocument, index) => {
                            return 'folderName' in
                                reportDocument.document ? null : (
                                <DocumentRow
                                    key={index}
                                    reportDocument={reportDocument}
                                    indent={false}
                                />
                            );
                        }
                    )}
                    {/* documents with folders */}
                    {folderDocuments(documents).folders.map((a, i) => (
                        <Folder
                            key={i}
                            folderName={a.name}
                            reportDocuments={a.documents}
                            indent={true}
                            showSectionFolders={showSectionFolders}
                        />
                    ))}
                </div>
            </>
        );
    };

    return (
        <div id="documents" className="w-full mb-8 flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                {reportDocuments.length > 0 && (
                    <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        {title && (
                            <div className="py-4 border-b border-gray-200">
                                <h2 className="text-xl">{title}</h2>
                            </div>
                        )}
                        <div className="my-6 overflow-hidden">
                            <table className="w-full text-sm">
                                <tbody className="bg-white text-left">
                                    <div>
                                        {/* section folders */}
                                        {showSectionFolders
                                            ? sectionFolders(
                                                  reportDocuments
                                              ).sectionFolders.map((a, i) => (
                                                  <SectionFolder
                                                      key={i}
                                                      folderName={a.name}
                                                  >
                                                      {rows(a.documents)}
                                                  </SectionFolder>
                                              ))
                                            : rows(reportDocuments)}
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionInvestmentReportsDocs;
