import React from 'react';
import { Link } from 'react-router-dom';

import {
    AuthPermissionEnum,
    EndInvestorProfileResponse,
    SystemUserResponse,
    SystemUserRoleActions,
} from '@interfaces/Api';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { getRoleName } from '@helpers/SystemUser.helper';
import { formatDateTimeString } from '@helpers/Date.helper';

import { Button, ConditionalLink, Icons, Tooltip } from '@components/Atoms';
import { ResendEmailIcon } from '@components/Atoms/Icons';

import { UserTableHeaders } from './UserTableHeaders';
import { columns, getTableHeaderClass } from '../UserTable.helper';
import Highlighter from 'react-highlight-words';
import { resendEmailInvitation } from '@api/EndInvestorProfiles';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
import { useAuthState } from '@contexts/AuthContext';
import { PopUp } from '@components/Molecules';
import { NoResultsRow } from './NoResultsRow';
import { useTranslation } from 'react-i18next';

export interface ListSystemUsersComponentProps {
    items?: Array<SystemUserResponse & { owner?: string }>;
    currentUserRoleActions?: SystemUserRoleActions;
    requestSort: (key: keyof SystemUserResponse) => void;
    columnSortConfig: { key: string; direction: string };
    archiveUser?: (systemUser: SystemUserResponse) => void;
    searchText: string;
}

const UsersTable: React.FC<ListSystemUsersComponentProps> = (props) => {
    const { t } = useTranslation();
    const { preferences } = useConfigurationState();
    const { hasPermissions } = useAuthState();
    const isOwnerSetOn =
        preferences?.feature?.userManagement?.displayOwnerEntity;

    const handleResendEmailInvitation = async (id: string) => {
        try {
            await resendEmailInvitation({ id });
            notifySuccess(t('admin.user_management.invitation_email_has_been_sent'));
        } catch (_) {
            notifyError(t('admin.user_management.invitation_email_error'));
        }
    };

    const systemUserLineValues = (
        user: SystemUserResponse
    ): (string | JSX.Element)[] => {
        const {
            firstName,
            surname,
            email,
            role,
            lastLoginDate,
            currentLoginDate,
            _id,
            invitationLog,
            registered,
            archived,
            availableRoles,
            endInvestorProfileIds,
        } = user;

        const { sentAt } = invitationLog?.[invitationLog.length - 1] || {};
        const companyName = (
            endInvestorProfileIds as EndInvestorProfileResponse[]
        )
            .map(
                (endInvestorProfile: EndInvestorProfileResponse) =>
                    endInvestorProfile.businessEntityDetails?.companyName
            )
            .join(', ');

        const isPopUpvisible =
            props.currentUserRoleActions?.update.includes(role) ||
            props.currentUserRoleActions?.archive?.includes(role) ||
            hasPermissions(AuthPermissionEnum.resendInviteToUnregisteredUsers);

        const searchText = props.searchText.split(' ');

        const linkHref =
            endInvestorProfileIds.length === 1
                ? `/clients/${
                      (
                          endInvestorProfileIds as EndInvestorProfileResponse[]
                      )?.[0]?._id
                  }`
                : '/clients';

        const tableData = [
            <>
                <ConditionalLink
                    linkCondition={endInvestorProfileIds.length > 0}
                    to={linkHref}
                    title={t('admin.user_management.navigate_to_end_investor')}
                >
                    {companyName ? (
                        <Highlighter
                            searchWords={searchText}
                            autoEscape
                            textToHighlight={companyName}
                        />
                    ) : (
                        <>
                            <Highlighter
                                searchWords={searchText}
                                autoEscape
                                textToHighlight={firstName}
                            />{' '}
                            <Highlighter
                                searchWords={searchText}
                                autoEscape
                                textToHighlight={surname}
                            />
                        </>
                    )}

                    {archived ? `(${t('admin.user_management.archived')})` : ''}
                </ConditionalLink>
                <p className="text-xs font-bold">
                    <Highlighter
                        searchWords={props.searchText.split(' ')}
                        autoEscape
                        textToHighlight={email}
                    />
                </p>
            </>,
            <>
                <div className="flex items-center">
                    <div className="mr-2">
                        <Highlighter
                            searchWords={props.searchText.split(' ')}
                            autoEscape
                            textToHighlight={getRoleName(role)}
                        />
                        <p className="text-xs font-bold">
                            <Highlighter
                                searchWords={props.searchText.split(' ')}
                                autoEscape
                                textToHighlight={
                                    (availableRoles &&
                                        availableRoles.length > 0 &&
                                        `(${(availableRoles || [])
                                            .map(getRoleName)
                                            .join(', ')})`) ||
                                    ''
                                }
                            />
                        </p>
                    </div>
                </div>
            </>,
            formatDateTimeString(currentLoginDate),
            formatDateTimeString(sentAt),
            registered ? t('client.registration.kyc_compliance.yes') : t('client.registration.kyc_compliance.no'),

            isPopUpvisible ? (
                <PopUp
                    trigger={
                        <button>
                            <Icons
                                name="EllipsisVerticalIcon"
                                strokeWidth="1.5"
                            />
                        </button>
                    }
                    position="left center"
                >
                    <div className="min-w-4">
                        {archived ? (
                            <div className="h-12" />
                        ) : (
                            <div className="h-12 flex flex-row items-center justify-center">
                                {props.currentUserRoleActions?.update.includes(
                                    role
                                ) && (
                                    <div className="px-2">
                                        <Link
                                            to={`/admin/users/update-user/${_id}`}
                                        >
                                            <Icons
                                                name="PencilIcon"
                                                strokeWidth="1.5"
                                            />
                                        </Link>
                                    </div>
                                )}

                                {props.currentUserRoleActions?.archive?.includes(
                                    role
                                ) && (
                                    <div className="px-2">
                                        <a
                                            className="cursor-pointer"
                                            onClick={() =>
                                                props.archiveUser &&
                                                props.archiveUser(user)
                                            }
                                        >
                                            <Icons
                                                name="TrashIcon"
                                                stroke="black"
                                                strokeWidth="1.5"
                                            />
                                        </a>
                                    </div>
                                )}

                                {hasPermissions(
                                    AuthPermissionEnum.resendInviteToUnregisteredUsers
                                ) &&
                                    !registered && (
                                        <div className="px-2 h-5">
                                            <Tooltip tip="Resend email invitation">
                                                <Button
                                                    hasIconOnly
                                                    className="w-8"
                                                    onClick={() =>
                                                        handleResendEmailInvitation(
                                                            _id
                                                        )
                                                    }
                                                >
                                                    <ResendEmailIcon />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    )}
                            </div>
                        )}
                    </div>
                </PopUp>
            ) : (
                <></>
            ),
        ];

        return tableData;
    };

    return (
        <div className="h-full pb-9 xs:container xs:mx-auto max-w-full">
            <table className="table-auto w-full md-max:hidden rounded-md data-tables-admin">
                <UserTableHeaders
                    columnSortConfig={props.columnSortConfig}
                    requestSort={props.requestSort}
                />

                <tbody>
                    {props.items?.length === 0 && <NoResultsRow />}
                    {props.items?.map((user, i) => {
                        const values = systemUserLineValues(user);
                        return (
                            <tr
                                key={user._id + i.toString() + i.toString()}
                                className="text-xs xl:text-base"
                            >
                                {isOwnerSetOn &&
                                    (user.owner ? (
                                        <td className="border-b p-2 text-xs xl:text-base">
                                            <Highlighter
                                                searchWords={props.searchText.split(
                                                    ' '
                                                )}
                                                autoEscape={true}
                                                textToHighlight={user.owner}
                                            />
                                        </td>
                                    ) : (
                                        <td className="border-b" />
                                    ))}

                                {values.map((value, i) => (
                                    <td
                                        key={i.toString()}
                                        className="border-b p-2 text-xs xl:text-base"
                                    >
                                        {value}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <table className="w-full text-sm md:hidden">
                {props.items?.map((user, i) => {
                    const values = systemUserLineValues(user);
                    const [first] = values;
                    return (
                        <tbody
                            key={user._id + i.toString()}
                            className="bg-white text-left text-sm overflow-x-auto"
                        >
                            {columns.map((columnName, index) => (
                                <tr
                                    key={index.toString()}
                                    className="whitespace-nowrap"
                                >
                                    <td
                                        className={`${getTableHeaderClass(
                                            columnName.title,
                                            props.columnSortConfig
                                        )} font-bold px-3 md:pr-6 md:pl-0 py-1 w-1/3 md:w-1/2`}
                                    >
                                        {columnName.title}
                                    </td>
                                    <td className="px-3 md:pr-6 md:pl-0 py-1 text-clip overflow-hidden">
                                        {index === 0 ? (
                                            <span className="text-ellipsis overflow-hidden ...">
                                                {first}
                                                <br />
                                                <span className="text-sm font-bold">
                                                    {user.email}
                                                </span>
                                            </span>
                                        ) : (
                                            <span className="text-ellipsis overflow-hidden ...">
                                                {values[index + 1]}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="py-1" colSpan={2}>
                                    <div className="border-b mb-2" />
                                </td>
                            </tr>
                        </tbody>
                    );
                })}
            </table>
        </div>
    );
};

export default UsersTable;
