import { useEffect } from 'react';
import { legalEntityTypeLabelOptions } from '@interfaces/Api';
import { Trans, useTranslation } from 'react-i18next';
import { FormCheckbox, FormSelect } from '@components/Molecules';
import { ControllingPersonFieldArray } from './ControllingPersonFieldArray';
import { TaxCountriesFieldArray } from './TaxCountriesFieldArray';
import { getTranslatedOptions } from './TaxDeclarations';
import { RenderNonFinancialFields } from './RenderNonFinancialFields';
import { RenderFinancialFields } from './RenderFinancialFields';

export const RenderEntityTaxDeclarations = ({
    formikProps,
    areInputsDisabled,
    setCustomErrors,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const isFinancialInstitution =
            formikProps.values.entity?.fatca?.isFinancialInstitution;
        // if Entity is Financial Institution Clear Non Financial institution Fields, vice versa
        if (isFinancialInstitution) {
            formikProps.setFieldValue(
                'entity.fatca.nonFinancialInstitutionType',
                ''
            );
            formikProps.setFieldValue(
                'entity.fatca.otherNonFinancialInstitution',
                ''
            );
            formikProps.setFieldValue(
                'entity.crs.nonFinancialInstitutionType',
                ''
            );
            formikProps.setFieldValue(
                'entity.crs.isEntityNotATaxResident',
                false
            );
            formikProps.setFieldValue(
                'entity.crs.entityNotATaxResidentDescription',
                ''
            );
            formikProps.setFieldValue('entity.controllingPersons', []);
        } else {
            // If toggled to false, clear Financial Institution fields
            formikProps.setFieldValue(
                'entity.fatca.fatcaFinancialInstitutionType',
                ''
            );
            formikProps.setFieldValue('entity.fatca.GIIN', '');
            formikProps.setFieldValue('entity.fatca.noGIINReasonType', '');
            formikProps.setFieldValue('entity.fatca.sponsorsGIIN', '');
            formikProps.setFieldValue('entity.fatca.sponsorsName', '');
            formikProps.setFieldValue('entity.fatca.noGIINSponsorType', '');
            formikProps.setFieldValue(
                'entity.fatca.exemptBeneficialOwnerType',
                ''
            );
            formikProps.setFieldValue('entity.fatca.w8Document', []);
            formikProps.setFieldValue('entity.fatca.igaCountryCode', '');
            formikProps.setFieldValue('entity.fatca.nonReportingCategory', '');
            formikProps.setFieldValue(
                'entity.fatca.entityTypeWithInvestmentComponent',
                false
            );
            formikProps.setFieldValue(
                'entity.crs.financialInstitutionType',
                ''
            );
        }
        // Clear Controlling Person
        if (
            formikProps.values.entity?.fatca?.nonFinancialInstitutionType !==
            'passiveNonFinancialForeignEntity'
        ) {
            formikProps.setFieldValue('entity.controllingPersons', []);
        }
        const errorObj: any = {};
        if (isFinancialInstitution) {
            if (
                !formikProps.values?.entity?.fatca
                    ?.fatcaFinancialInstitutionType
            ) {
                errorObj.fatcaFinancialInstitutionType = t(
                    'entity.fatca.financialInstitutionType.isRequired'
                );
            }
            if (!formikProps.values?.entity?.crs?.financialInstitutionType) {
                errorObj.crsFinancialInstitutionType = `CRS ${t(
                    'entity.fatca.financialInstitutionType.isRequired'
                )}`;
            }
            if (
                !formikProps.values?.entity?.fatca?.GIIN &&
                !formikProps.values?.entity?.fatca?.noGIINReasonType
            ) {
                errorObj.crsFinancialInstitutionType = t(
                    'entity.fatca.financialInstitutionType.GIIN_noGIINReasonType.isRequired'
                );
            }
        } else {
            if (
                !formikProps?.values?.entity?.fatca?.nonFinancialInstitutionType
            ) {
                errorObj.nonFinancialInstitutionType = t(
                    'entity.fatca.nonFinancialInstitutionType.isRequired'
                );
            }
            if (
                !formikProps?.values?.entity?.crs
                    ?.nonFinancialInstitutionType ||
                (formikProps?.values?.entity?.fatca
                    ?.nonFinancialInstitutionType ===
                    'activeNonFinancialForeignEntity' &&
                    formikProps?.values?.entity?.crs
                        ?.nonFinancialInstitutionType === 'notApplicable')
            ) {
                errorObj.nonFinancialInstitutionType = `CRS ${t(
                    'entity.fatca.nonFinancialInstitutionType.isRequired'
                )}`;
            }
            if (
                formikProps?.values?.entity?.fatca
                    ?.nonFinancialInstitutionType === 'other' &&
                !formikProps?.values?.entity?.fatca
                    ?.otherNonFinancialInstitution
            ) {
                errorObj.nonFinancialInstitutionType = t(
                    'entity.fatca.otherNonFinancialInstitution.isRequired'
                );
            }
        }
        setCustomErrors(errorObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formikProps.values.entity?.fatca?.isFinancialInstitution,
        formikProps.values?.entity?.fatca?.fatcaFinancialInstitutionType,
        formikProps.values?.entity?.crs?.financialInstitutionType,
        formikProps.values?.entity?.fatca?.GIIN,
        formikProps.values?.entity?.fatca?.noGIINReasonType,
        formikProps?.values?.entity?.fatca?.nonFinancialInstitutionType,
        formikProps?.values?.entity?.fatca?.otherNonFinancialInstitution,
        formikProps?.values?.entity?.crs?.nonFinancialInstitutionType,
        setCustomErrors,
        t,
    ]);

    return (
        <>
            <h3 className="pt-3 pb-2 text-2xl">
                {t('subscriptions.add.tax_declaration.entity_investor.title')}
            </h3>

            <h3 className="my-2 mt-4 text-xl font-normal leading-8">
                {t(
                    'subscriptions.add.tax_declaration.country_of_residence.heading'
                )}
            </h3>
            <p className="py-1 text-base">
                {t(
                    'subscriptions.add.tax_declaration.entity_investor.add_tax_country.help_text_1'
                )}
            </p>
            <p className="py-1 pb-3 text-base">
                <Trans
                    i18nKey="subscriptions.add.tax_declaration.entity_investor.add_tax_country.help_text_2"
                    defaults=""
                    components={{
                        bold: <strong />,
                    }}
                />
            </p>
            <p className="py-1 pb-3 text-base">
                {t(
                    'subscriptions.add.tax_declaration.entity_investor.add_tax_country.help_text_3'
                )}
            </p>

            <TaxCountriesFieldArray
                fieldArrayName="entity.taxCountries"
                taxCountries={formikProps.values.entity?.taxCountries}
                validationErrors={formikProps.errors?.entity?.taxCountries}
            />

            {/* FATCA declaration */}
            <div className="pt-8">
                <h3 className="py-1 my-2 text-xl font-normal-leading-8">
                    {t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.us_person_title'
                    )}
                </h3>
                <FormSelect
                    name="entity.legalEntityType"
                    id="entity.legalEntityType"
                    label={
                        t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.us_person_selection'
                        ) + ' *'
                    }
                    optionsData={getTranslatedOptions(
                        t,
                        legalEntityTypeLabelOptions,
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.us_person.select_option'
                    )}
                    disabled={areInputsDisabled}
                />
                {/* FATCA */}
                <h3 className="my-2 mt-8 text-xl font-normal leading-8">
                    {t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fatca_classification_title'
                    )}
                </h3>
                <FormCheckbox
                    name="entity.fatca.isFinancialInstitution"
                    actionLabel={t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.institution_type_checkbox'
                    )}
                />

                {formikProps.values.entity?.fatca?.isFinancialInstitution && (
                    <RenderFinancialFields
                        formikProps={formikProps}
                        areInputsDisabled={areInputsDisabled}
                    />
                )}
                {!formikProps.values.entity?.fatca?.isFinancialInstitution && (
                    <RenderNonFinancialFields
                        formikProps={formikProps}
                        areInputsDisabled={areInputsDisabled}
                    />
                )}
            </div>

            <h3 className="my-2 mt-4 text-xl font-normal leading-8">
                {t(
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.title'
                )}
            </h3>
            <ControllingPersonFieldArray
                formikProps={formikProps}
                areInputsDisabled={areInputsDisabled}
            />
        </>
    );
};
