import ImageRender from '@components/Atoms/FileRender/ImageRender';
import { IntermediaryResponse } from '@interfaces/Api';
import { ProfileSelection } from '@pages/Admin/BookingCentres/ProfileSelection';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface ImgTextProps {
    name: string;
    description?: string | React.ReactNode;
    img: {
        url: string;
        label?: string;
    };
    intermediary?: IntermediaryResponse;
}

export const ImgText = (props: ImgTextProps) => {
    const {
        name,
        description,
        img: { url, label },
        intermediary,
    } = props;
    const { t } = useTranslation();

    const FALLBACK_URL =
        'https://via.placeholder.com/800X400.png?text=Logo+Not+Found';

    const handleImgOnError = ({ currentTarget }: any) => {
        currentTarget.onerror = null;
        currentTarget.src = FALLBACK_URL;
    };

    const isUrlValid = () => {
        let URL_HOLDER;

        try {
            URL_HOLDER = new URL(url);
        } catch (_) {
            return false;
        }
    };
    return (
        <div className="flex mb-14 border-b pb-6">
            <div
                className={classNames('flex-1 mr-4 max-w-400 max-h-200', {
                    'p-4 border border-logo-blue': isUrlValid(),
                })}
            >
                <ImageRender
                    className="w-full h-full"
                    src={url || FALLBACK_URL}
                    alt={label || ''}
                    onError={handleImgOnError}
                />
            </div>
            <div className="flex-2 pl-2">
                <p className="text-2xl text-logo-blue mb-2">{name}</p>
                {description && typeof description === 'string' ? (
                    <p>{description}</p>
                ) : (
                    description
                )}
                {intermediary?.contactDetails?.compliance && (
                    <p>
                        <span className="mr-2">
                            {t('add_distributor.compliance.email')}:
                        </span>

                        {intermediary?.contactDetails?.compliance.emailAddress}
                    </p>
                )}
                {intermediary?.contactDetails?.middleOffice && (
                    <p>
                        <span className="mr-2">
                            {t('add_distributor.middleOffice.email')}:
                        </span>

                        {
                            intermediary?.contactDetails?.middleOffice
                                .emailAddress
                        }
                    </p>
                )}
            </div>
            {intermediary && (
                <div>
                    <ProfileSelection
                        profileId={intermediary?.profileId}
                        intermediary={intermediary}
                    />
                </div>
            )}
        </div>
    );
};
