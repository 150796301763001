import { Icons } from '@components/Atoms';

// The below is a commented out type that could allow us to flatten the EndInvestorProfileRequestBody type so that we could match
// validation from the EndInvestorProfileRequestBody object to the validation on the client registration form.
// type Entry = { key: string, value: any, optional: boolean };
// type _Explode<T> =
//     T extends object ? { [K in keyof T]-?:
//         K extends string ? Explode<T[K]> extends infer E ? E extends Entry ?
//         {
//             key: `${K}${E['key'] extends "" ? "" : "."}${E['key']}`,
//             value: E['value'],
//             optional: E['key'] extends "" ? {} extends Pick<T, K> ? true : false : E['optional']
//         }
//         : never : never : never
//     }[keyof T] : { key: "", value: T, optional: false }
// type Explode<T> = _Explode<T extends readonly any[] ? { "[0]": T[number] } : T>;
// type Collapse<T extends Entry> = (
//         { [E in Extract<T, { optional: false }> as E['key']]: E['value'] }
//         & Partial<{ [E in Extract<T, { optional: true }> as E['key']]: E['value'] }>
//     ) extends infer O ? { [K in keyof O]: O[K] } : never
// type Flatten<T> = Collapse<Explode<T>>
// type FlattenedEndInvestorProfileRequestBody = Flatten<EndInvestorProfileRequestBody>;

export const ClientRegistrationErrors = ({ errors }: { errors: string[] }) => {
    const handleErrorString = (error: string) => {
        const dataKeys: any = {
            adviserSystemUserId: 'Adviser',
            intermediaryId: 'Intermediary',
            serviceLevelType: 'Service level',
            subscriptionProcessType: 'Subscription process',
            onboardingJurisdictionCountryCode: 'Jurisdiction',
            clientType: 'Client type',
            isKycCompliant: 'KYC',
            firstName: 'First name',
            lastName: 'Last name',
            dateOfBirth: 'Date of birth',
            nationalIdNumber: 'National ID number',
            emailAddress: 'Email address',
            phoneDiallingCode: 'Phone dialling code',
            phoneNumber: 'Phone number',
            endInvestorType: 'Investor type',
            addresses: 'Address',
            residentialLine2: 'Residential address line 2',
            bankAccounts: 'Bank account',
            countryCodeOfAccount: 'Country of bank account',
            bankName: 'Bank name',
            bankAddress: 'Bank address',
            countryCurrencyCode: 'Currency',
            IBAN: 'IBAN number',
            email: 'Email Address',
            gender: 'Gender',
            nationalities: 'Nationalities',
            maritalStatus: 'Marital Status',
            additionalContactInformation: 'Additional Contact Information',
            occupation: 'Occupation',
            _id: 'User Account',
            loginAccountChoice: 'Login account choice',
        };

        const reg = /\w..*\.\w*/;
        const match = new RegExp(reg).exec(error);

        const tempValidationMessage = match
            ? error.replace(match[0], '')
            : error.replace(error.split(' ')[0], '');

        const updatedMatch = match
            ? match[0].split('.')[match[0].split('.').length - 1]
            : error.split(' ')[0];
        const getErrorMessage = () => {
            if (
                error.includes(
                    'Business Entity details required for business or nominee entities'
                )
            )
                return 'Business Entity details required for business or nominee entities';

            if (error.includes('dateOfBirth'))
                return 'Date of birth is a required field';

            if (!updatedMatch) {
                return error;
            }
            return `${dataKeys[updatedMatch]} ${tempValidationMessage}`;
        };

        return getErrorMessage();
    };

    return (
        <div className="mt-4">
            <div className="flex">
                <span className="mr-1 mt-1">
                    <Icons
                        name="ExclamationTriangleIconOutline"
                        size="small"
                        classList="text-yellow-700"
                    />
                </span>
                <span className="font-bold text-blue-900">
                    The following fields are mandatory:
                </span>
            </div>
            <div className="block">
                {errors?.map((error: string) => (
                    <p key={error} className="text-blue-900">
                        {handleErrorString(error)}
                    </p>
                ))}
            </div>
        </div>
    );
};
