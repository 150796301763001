import { FormSelect } from '@components/Molecules';
import { useAuthState } from '@contexts/AuthContext';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { SelectOption } from '@interfaces/InterfaceFormsProps';
import {
    SystemUsersGet,
    getAdvisors,
} from '@stores/SystemUsers/SystemUsers.services';
import { useTranslation } from 'react-i18next';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const AdvisorSelection = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { systemUserId } = useParams();
    const { currentUser } = useAuthState();
    const [findWealthManager, setFindWealthManager] = useState<
        SelectOption<string> | undefined
    >(undefined);

    const [advisors, setAdvisors] = useState<SelectOption<string>[]>([]);

    useEffect(() => {
        if (data.intermediaryId) {
            getAdvisors(data.intermediaryId)
                .then((users: SystemUserResponse[]) => {
                    setAdvisors(
                        users.map((user) => ({
                            label: `${user.firstName} ${user.surname}`,
                            value: user._id,
                        }))
                    );
                })
                .catch((error) => notifyError(errorToString(error)));
        }
    }, [data.intermediaryId]);

    useEffect(() => {
        const endInvestorId =
            systemUserId ||
            (currentUser?.user.role === AuthRoleEnum.endInvestor &&
                currentUser?.user._id);

        if (endInvestorId) {
            SystemUsersGet(endInvestorId)
                .then(async (user) => {
                    if (!user.invitationLog?.[0].sentBy) {
                        return;
                    }

                    const invitedBySystemUser = await SystemUsersGet(
                        user.invitationLog?.[0].sentBy
                    );

                    if (
                        data.adviserSystemUserId &&
                        invitedBySystemUser &&
                        invitedBySystemUser.role ===
                            AuthRoleEnum.relationshipManager
                    ) {
                        setFindWealthManager(
                            advisors.find(
                                ({ value }) => value === invitedBySystemUser._id
                            )
                        );
                    }
                })
                .catch((error: string) => {
                    notifyError(errorToString(error));
                });
        }
    }, [
        currentUser?.user._id,
        currentUser?.user.role,
        systemUserId,
        data.adviserSystemUserId,
        advisors,
    ]);

    return (
        <div>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.advisor.title')}
            </h2>
            <FormSelect
                name={
                    getClientRegistrationFormFields(t).adviserSystemUserId.name
                }
                label={
                    getClientRegistrationFormFields(t).adviserSystemUserId.label
                }
                optionsData={advisors}
                disabled={Boolean(findWealthManager)}
            />
            <p className="text-xs -my-2">
                {t(
                    'client.registration.advisor.client_adviser.please_select_advisor_responsible'
                )}
            </p>
        </div>
    );
};
