import { BaseUrl } from '@helpers/Environment';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { notifyError } from '@helpers/toastrHelper';
import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { SystemUserRequestBody } from '@interfaces/Api/SystemUserRequest';
import { Http } from '@libs/http';
import { msalInstance } from '@libs/msal/msalInit';

const API_SYSTEM_USERS_URL = `${BaseUrl}/system-users`;

export const handleFetchSystemUsers = async (
    set,
    hasAssetManagerAndIntermediaryInfo = false
) => {
    try {
        set({ isLoading: true });

        const systemUsers: SystemUserResponse[] = await Http.get(
            API_SYSTEM_USERS_URL,
            undefined,
            {
                hasAssetManagerAndIntermediaryInfo,
            }
        );
        set({ systemUsers }, false, 'fetchSystemUsers');
    } catch (_) {
        notifyError('An error has occurred, system users have not been loaded');
    }

    set({ isLoading: false });
};

export const handleFetchSystemUser = async (set, systemUserId: string) => {
    try {
        set({ isLoading: true });

        const systemUser: SystemUserResponse = await Http.get(
            `${API_SYSTEM_USERS_URL}/${systemUserId}`
        );
        set({ systemUser }, false, 'fetchSystemUser');
    } catch (_) {
        notifyError('An error has occurred, system user has not been loaded');
    }

    set({ isLoading: false });
};

export const handleDeleteSystemUser = async (systemUserId: string) => {
    try {
        await Http.delete(`${API_SYSTEM_USERS_URL}/${systemUserId}`);
    } catch (_) {
        notifyError('An error has occurred, system user has not been deleted');
    }
};

export const SystemUsersGet = async (systemUserId: string) =>
    Http.get<SystemUserResponse>(`${API_SYSTEM_USERS_URL}/${systemUserId}`);

export const SystemUsersDelete = async (systemUserId: string) =>
    Http.delete(`${API_SYSTEM_USERS_URL}/${systemUserId}`);

export const SystemUsersPost = (systemUser: SystemUserRequestBody) =>
    Http.post(API_SYSTEM_USERS_URL, systemUser);

export const SystemUsersPut = (
    systemUser: SystemUserRequestBody,
    userId: string
) => Http.put(`${API_SYSTEM_USERS_URL}/${userId}`, systemUser);

export const agreeToNDAPUT = async (systemUserID: string) => {
    return Http.put(`${API_SYSTEM_USERS_URL}/${systemUserID}/nda`);
};

export const SystemUsersImportPost = (systemUser: SystemUserRequestBody[]) =>
    Http.post(`${API_SYSTEM_USERS_URL}/import`, systemUser);

export const SystemUsersSwitchRolePut = (role: AuthRoleEnum) =>
    Http.put(`${API_SYSTEM_USERS_URL}/switch-user-role`, { role });

export const inviteExternalWealthManager = (
    systemUser: Partial<SystemUserResponse>
) =>
    Http.post(
        `${API_SYSTEM_USERS_URL}/invite-external-wealth-manager`,
        systemUser
    );

export const getAdvisors = (intermediaryId: string) =>
    Http.get<SystemUserResponse[]>(
        `${API_SYSTEM_USERS_URL}/${intermediaryId}/advisors`
    );

export const setLanguagePreference = (
    systemUserId: string,
    languagePreference: string
) =>
    Http.put<SystemUserResponse>(
        `${API_SYSTEM_USERS_URL}/${systemUserId}/set-language-preference/${languagePreference}`
    );

export const getInvestmentAccounts = () =>
    Http.get<any>(`${API_SYSTEM_USERS_URL}/investment-accounts`);

export const getInvitedExternalWealthManagers = () =>
    Http.get<any>(`${API_SYSTEM_USERS_URL}/invited-external-wealth-managers`);

export const getInvitedClients = () =>
    Http.get<any>(`${API_SYSTEM_USERS_URL}/invited-clients`);

export const getFilteredLoginAccounts = (
    intermediaryId: string,
    endInvestorType: string
) =>
    Http.get(`${API_SYSTEM_USERS_URL}/filtered-login-accounts`, undefined, {
        intermediaryId,
        endInvestorType,
    });

export const getSystemUserByEndInvestorProfileId = (
    endInvestorProfileId: string
) =>
    Http.get<any>(
        `${API_SYSTEM_USERS_URL}/end-investor-profile/${endInvestorProfileId}`
    );

export const getSystemUsersByIds = async (systemUserIds: string[]) => {
    const account = msalInstance?.getActiveAccount();
    const scopes = localStorage.getItem('AUTH_SCOPES');
    const authority = localStorage.getItem('AUTH_AUTHORITY');
    let accessToken = getLocalStorageAuthTokens()?.access?.token;
    if (account) {
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (accessToken = response?.accessToken))
            .catch((error) => {
                return (accessToken = undefined);
            });
    }

    const response = await fetch(`${API_SYSTEM_USERS_URL}/ids`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
            'X-system-user-Ids': systemUserIds.join(','),
        },
    });

    const data = await response.json();
    return Array.isArray(data) ? data : [];
};
