import { TFunction } from 'react-i18next';

const germanHasAssetDescriptionKnowledgeRating = {
    hasAsset: {
        name: 'hasAsset',
        label: 'Besitzt Vermögen',
        requiredErrorMsg: 'Bitte wählen Sie eine Variante!',
    },
    description: {
        name: 'description',
        label: 'Beschreibung',
        requiredErrorMsg: 'Bitte fügen Sie eine Beschreibung hinzu!',
    },
    knowledgeRating: {
        name: 'knowledgeRating',
        label: 'Wissensbewertung',
        requiredErrorMsg: 'Bitte teilen Sie uns Ihre Wissensbewertung mit!',
    },
};

const germanHasExperienceKnowledgeRating = {
    hasExperience: {
        name: 'hasExperience',
        label: 'Besitzt Vermögen',
        requiredErrorMsg:
            'Bitte teilen Sie uns mit, ob Sie Erfahrung mit dieser Art von Investitionen haben!',
    },
    knowledgeRating: {
        name: 'knowledgeRating',
        label: 'Wissensbewertung',
        requiredErrorMsg: 'Bitte geben Sie Ihre Wissensbewertung ein!',
    },
};

const getFormikSubscribelabels = (t: TFunction<'translation', undefined>) => ({
    formId: 'subscriptionForm',
    formField: {
        sourceFunds: {
            name: 'source',
            label: t(
                'subscriptions.add.source_of_funds.source_of_funds.sub_heading'
            ),
            requiredErrorMsg: 'Choose a source of fund!',
        },
        addresses: {
            name: 'addresses',
            label: t('client.registration.summary.addresses'),
            requiredErrorMsg: 'Choose an Address!',
        },
        bankAccount: {
            name: 'bankAccount',
            label: t('subscriptions.add.banking_preferences.bank_account'),
            requiredErrorMsg: 'Choose a Bank Account!',
        },
        amount: {
            name: 'amount',
            label: 'Annual Salary Amount',
            requiredErrorMsg: 'Choose an Amount!',
        },
        firm: {
            name: 'firm',
            label: 'Firm Name',
            requiredErrorMsg: 'Choose a Firm Name!',
        },
        depository: {
            name: 'depository',
            label: 'Depository',
            requiredErrorMsg: 'Choose a Depository!',
        },
        notice: {
            amount: {
                name: 'amount',
                label: 'Capital Call Amount',
                requiredErrorMsg: 'Please type in an Amount!',
            },
            noticeType: {
                name: 'noticeType',
                label: 'Notice Type',
                requiredErrorMsg: 'Please Choose a Type!',
            },
            comment: {
                name: 'comment',
                label: 'Add a Comment',
                requiredErrorMsg: 'Comment required!',
            },
            uploadedBy: { name: 'uploadedBy', label: 'Uploaded By' },
            documents: {
                title: {
                    name: 'title',
                    label: 'Document Label',
                    requiredErrorMsg: 'Document Label is required!',
                },
                url: {
                    name: 'url',
                    label: 'Document URL',
                },
            },
        },
        beneficialOwners: {
            typeOfControl: {
                name: 'typeOfControl',
                label: t(
                    'subscriptions.add.beneficial_owners.add.type_of_control'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.type_of_control.required_validation_message'
                ),
            },
            title: {
                name: 'title',
                label: t('client.registration.personal_details.salutation'),
            },
            firstName: {
                name: 'firstName',
                label: t('client.invite.first_name'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.first_name.required_validation_message'
                ),
            },
            lastName: {
                name: 'lastName',
                label: t('client.invite.last_name'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.last_name.required_validation_message'
                ),
            },
            dateOfBirth: {
                name: 'dateOfBirth',
                label: t('client.registration.personal_details.date_of_birth'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.date_of_birth.required_validation_message'
                ),
            },
            occupation: {
                name: 'occupation',
                label: t('client.registration.personal_details.occupation'),
            },
            countryCodeOfBirth: {
                name: 'countryCodeOfBirth',
                label: t(
                    'client.registration.personal_details.country_of_birth'
                ),
            },
            placeOfBirth: {
                name: 'placeOfBirth',
                label: t('subscriptions.add.place_of_birth'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.place_of_birth.required_validation_message'
                ),
            },
            registeredAddress: {
                name: 'registeredAddress',
                label: t(
                    'subscriptions.add.beneficial_owners.add.registered_address'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.registered_address.required_validation_message'
                ),
            },
            nationalitiesInformation: {
                name: 'nationalitiesInformation',
                label: t('subscriptions.add.beneficial_owners.add.nationality'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.nationality.required_validation_message'
                ),
            },
            nationalIdNumber: {
                name: 'nationalIdNumber',
                label: t(
                    'subscriptions.add.beneficial_owners.add.national_id_number'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.national_id_number.required_validation_message'
                ),
            },
            percentageHolding: {
                name: 'percentageHolding',
                label: t(
                    'subscriptions.add.beneficial_owners.add.percentage_holding'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.percentage_holding.required_validation_message'
                ),
            },
            primarySourceOfIncome: {
                name: 'primarySourceOfIncome',
                label: t(
                    'subscriptions.beneficial_owners.section.ownership_details.checkbox.primary_source_income'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.source_of_funds.select.required_error_message'
                ),
            },
            email: {
                name: 'email',
                label:
                    t('client.registration.contact_details.email_address'),
            },
            phoneDiallingCode: {
                name: 'phoneDiallingCode',
                label:
                    t('client.registration.contact_details.country_code'),
            },
            phoneNumber: {
                name: 'phoneNumber',
                label:
                    t('client.registration.contact_details.phone_number'),
            },
        },
        politicalExposure: {
            positionOrName: {
                name: 'positionOrName',
                label: 'Position/ Name of Entity',
                requiredErrorMsg: 'Enter your position or entity name!',
            },
            firstName: {
                name: 'firstName',
                label: t('client.invite.first_name'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.first_name.required_validation_message'
                ),
            },
            lastName: {
                name: 'lastName',
                label: t('client.invite.last_name'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.last_name.required_validation_message'
                ),
            },
            dateOfBirth: {
                name: 'dateOfBirth',
                label: t('client.registration.personal_details.date_of_birth'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.date_of_birth.required_validation_message'
                ),
            },
            placeOfBirth: {
                name: 'placeOfBirth',
                label: t('subscriptions.add.place_of_birth'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.place_of_birth.required_validation_message'
                ),
            },
            registeredAddress: {
                name: 'registeredAddress',
                label: t(
                    'subscriptions.add.beneficial_owners.add.registered_address'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.registered_address.required_validation_message'
                ),
            },
            nationalities: {
                name: 'nationalities',
                label: t('subscriptions.add.beneficial_owners.add.nationality'),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.nationality.required_validation_message'
                ),
            },
            nationalIdNumber: {
                name: 'nationalIdNumber',
                label: t(
                    'subscriptions.add.beneficial_owners.add.national_id_number'
                ),
                requiredErrorMsg: t(
                    'subscriptions.add.beneficial_owners.add.national_id_number.required_validation_message'
                ),
            },
        },
        germanProfessional: {
            willFillForm: {
                name: 'willFillForm',
                label: 'Füllen oder überspringen',
                requiredErrorMsg:
                    'Bitte füllen Sie das Formular aus oder überspringen Sie diesen Schritt!',
            },
            individual: {
                gender: {
                    name: 'gender',
                    label: 'Anleger (Natürliche Person)',
                    requiredErrorMsg: 'Bitte geben Sie Ihr Geschlecht ein!',
                },
            },
            endInvestorType: {
                customerReferenceNumberOfEntity: {
                    name: 'customerReferenceNumberOfEntity',
                    label: 'Kundenreferenznummer des Unternehmens',
                    requiredErrorMsg:
                        'Bitte geben Sie Ihre Kundenreferenznummer des Unternehmens ein!',
                },
            },
            privateEquityInvestments: germanHasAssetDescriptionKnowledgeRating,
            directInvestments: germanHasAssetDescriptionKnowledgeRating,
            ownBusiness: germanHasAssetDescriptionKnowledgeRating,
            closedForeignInvestments: germanHasAssetDescriptionKnowledgeRating,
            domesticInvestments: germanHasAssetDescriptionKnowledgeRating,
            closedFundsOrAlternativeFixedInvestments:
                germanHasAssetDescriptionKnowledgeRating,
            complexInvestmentStructure: germanHasExperienceKnowledgeRating,
            illiquidParticipation: germanHasExperienceKnowledgeRating,
            investmentWithForeignCurrency: germanHasExperienceKnowledgeRating,
            personalLiability: germanHasExperienceKnowledgeRating,
            noRiskMixing: germanHasExperienceKnowledgeRating,
            investmentWithDebtFinancing: germanHasExperienceKnowledgeRating,
            capitalCalls: germanHasExperienceKnowledgeRating,
            proportionOfTotalFreeLiquidAssets: {
                name: 'proportionOfTotalFreeLiquidAssets',
                label: 'Wählen Sie einen Anteil aus',
                requiredErrorMsg:
                    'Der Anteil an den gesamten freien liquiden Mitteln ist erforderlich!',
            },
        },
        templateName: {
            name: 'templateName',
            label: t('subscription.add.details.name'),
            requiredErrorMsg: t(
                'subscription.add.details.name.required_validation_message'
            ),
        },
    },
});

export default getFormikSubscribelabels;
