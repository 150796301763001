import { BaseUrl } from '@helpers/Environment';

import {
    DocumentTemplate,
    DocumentTemplateUploadResponse,
} from '@interfaces/Api/DocumentTemplate';
import { Http } from '@libs/http';

const API_TEMPLATE_DOCUMENTS = `${BaseUrl}/template-documents`;

export const DocumentTemplatesGet = async (): Promise<DocumentTemplate[]> => {
    const result = await Http.get<DocumentTemplate[]>(
        API_TEMPLATE_DOCUMENTS + '/library',
        undefined
    );
    return result;
};

export const DocumentTemplateDelete = async (id: string): Promise<boolean> => {
    const result = await Http.delete<boolean>(
        `${API_TEMPLATE_DOCUMENTS}/library/${id}`
    );
    return result;
};

export const DocumentTemplateUpload = async (
    documentData: DocumentTemplate,
    file?: File
): Promise<DocumentTemplateUploadResponse> => {
    const formData = new FormData();
    file && formData.append('upfile', file);
    formData.append('documentData', JSON.stringify(documentData));
    return Http.post(`${API_TEMPLATE_DOCUMENTS}/library`, formData);
};
