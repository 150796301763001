import { useAuthState } from '@contexts/AuthContext';
import { AuthRoleEnum, EndInvestorProfileResponse } from '@interfaces/Api';
import { FormRadioGroup } from '@components/Molecules';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { getEndInvestorTypeLabelOptions } from '../InvestorDetails.helper';
import { useTranslation } from 'react-i18next';
import { ClientEnum } from 'common';

export const EndInvestorTypeSelection = () => {
    const { t } = useTranslation();
    const { configuration } = useConfigurationState();
    const { currentUser } = useAuthState();

    const isOddo = configuration.client === ClientEnum.ODDO;
    const isEnableEntityInvestors =
        configuration?.preferences?.feature?.investorRegistrationProcess
            ?.enableEntityInvestors;

    const endInvestorTypeLabelOptions = getEndInvestorTypeLabelOptions(
        t,
        isEnableEntityInvestors,
        currentUser?.user.role as AuthRoleEnum
    ).filter((x) => {
        if (!isOddo) return true;
        return (
            x.value !== EndInvestorProfileResponse.EndInvestorType.nomineeEntity
        );
    });

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.investor_type.title')}
            </h2>
            <FormRadioGroup
                name={getClientRegistrationFormFields(t).endInvestorType.name}
                optionsData={endInvestorTypeLabelOptions}
            />
        </>
    );
};
