import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from 'common';

const ImportantNotice = () => {
    const { isClient } = useConfigurationState();
    return (
        <div>
            <h2 className="mb-4 text-2xl">Important Notice</h2>

            {isClient(ClientEnum.HG) && (
                <p className="pb-4 text-sm">
                    Thank you for visiting the website (the “Site” or “Website”)
                    of S64 AltoFlex VI Private Markets SICAV – Hg Fusion Private
                    Capital Fund (“Sub-Fund”) and its affiliates (collectively,
                    “Hg”, “we” or “us”). By accessing this Website, you
                    acknowledge and agree to accept the following Terms of Use
                    pertaining to the use of the Site, which constitute a legal
                    agreement between you and Hg. This Website and the materials
                    herein are intended for certain types of investors only and
                    to persons in certain jurisdictions where the strategy is
                    authorised for distribution.
                </p>
            )}

            <p className="pb-4 pb-4 text-sm">
                This Site and the materials herein are directed only to certain
                types of investors and to persons in certain jurisdictions where
                the strategy is authorized for distribution. By selecting an
                investor type from the below list, you certify that you qualify
                as that investor type based on the definitions below:
            </p>

            <p className="pb-2 pb-4 text-sm">
                <b>Professional Investor</b> includes:
            </p>

            <ul className="list-disc ml-4 pb-4 pb-4 text-sm">
                <li>
                    for investors in the European Economic Area, any entity that
                    is a credit institution, investment firm, other regulated
                    financial institution, insurance company, collective
                    investment scheme, pension fund, certain large undertakings
                    or opt up persons which qualify as a Professional Investor
                    within the meaning of the European Union Alternative
                    Investment Fund Managers Directive (2011/61/EU) and the
                    European Union Markets in Financial Instruments Directive
                    (2014/65/EU);
                </li>
                <li>
                    for investors in the United Kingdom, any person or entity
                    that is a credit institution, investment firm, other
                    regulated financial institution, insurance company,
                    collective investment scheme, pension fund or any persons or
                    entities that qualify as a Professional Client under section
                    3.5 Conduct of Business Sourcebook of the FCA Handbook of
                    Rules and Guidance / Market in Financial Instruments
                    Regulation (as retained and amended in the United Kingdom);
                </li>
                <li>
                    for investors in Switzerland, any persons or entities (a)
                    who is a Professional Client as defined in article 4(3) of
                    the Swiss Federal Act on Financial Services (FinSA) or (b) a
                    Private Client within the meaning of article 4(2) FinSA who
                    is in a long-standing investment advisory- or investment
                    management relationship with a regulated financial
                    intermediary and who did not declare that they shall not be
                    treated as qualified investors in accordance with article 10
                    (3ter) of the Swiss Federal Act on Collective Investment
                    Schemes;
                </li>
                <li>
                    for investors in Singapore, any person or entity that is,
                    and will at all times continue to be an or an "institutional
                    investor" or "accredited investor” within the meaning of the
                    Securities and Futures Act 2001 of Singapore (“SFA”). The
                    Sub-Fund is not authorized or recognized by the Monetary
                    Authority of Singapore and the shares are not allowed to be
                    offered to the retail public;
                </li>
                <li>
                    for investors in the United Arab Emirates (not including the
                    DIFC or ADGM), any person who qualify as an institutional
                    investor;
                </li>
                <li>
                    for investors in Australia, any person or entity that is a
                    “Wholesale Client” in Australia within the meaning of
                    Section 761G of the Corporations Act 2001 (CTH).
                </li>
                {isClient(ClientEnum.HG) && (
                    <li>
                        for investors in Chile, any person that is a Qualified
                        Investor as defined under numbers 1 to 6 of Section II
                        of the General Rule N°216 of 2008
                    </li>
                )}
            </ul>

            <p className="pb-4 pb-4 text-sm">
                <b>Non-Professional Investor:</b> any person or entity who is
                not a Professional Investor
            </p>
        </div>
    );
};

export default ImportantNotice;
