import { t } from 'i18next';
import { Document } from './Document';
import { DocumentTemplate } from './DocumentTemplate';

import { ProductStatusEnum } from './ProductStatus.enum';
import { TFunction } from 'react-i18next';

export interface ProductChangeLogs {
    status: ProductStatusEnum;
    createdBy: string;
    createdAt: Date | string;
    _id?: string;
}

export interface ProductDescription {
    _id: string;
    title: string;
    descriptionText: string;
}

export interface KeyValue {
    keyName: string;
    value: string[];
}

export interface Video {
    title: string;
    url: string;
    embed_url: string;
}

export interface ShareClass {
    _id?: string;
    name: string;
    type: string;
    distributionFee: number;
    managementFee?: number;
    currency: string;
    minimumInvestment: number;
    minimumSubsequentSubscriptionAmount?: number;
    useProductBaseCurrencyForMinAmount?: boolean;
    openEndedInformation?: ShareClassOpenEndedInformation;
    additionalTerms?: KeyValue[];
    groupName?: string;
    isProxy?: boolean;
}

export interface ShareClassGrouped {
    groupName?: string;
    additionalTerms?: KeyValue[];
    currency: string[];
    distributionFee: number[];
    managementFee?: number[];
    minimumInvestment: number[];
    name: string[];
    type: string[];
    openEndedInformation?: ShareClassOpenEndedInformation;
    minimumSubsequentSubscriptionAmount?: number[];
    useProductBaseCurrencyForMinAmount?: boolean[];
}

export interface ShareClassOpenEndedInformation {
    investorLockUp: InvestorLockUpEnum;
    hardLockUpDetail?: {
        lengthOfTimeInMonths: number;
    };
    softLockUpDetails?: {
        lengthOfTimeInMonths: number;
        redemptionFeePercentage: number;
    }[];
}

export interface ProductContact {
    salutation?: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumberDiallingCode?: string;
    phoneNumber?: string;
}

export enum LiquidityTypeEnum {
    closedEnded = 'closedEnded',
    openEnded = 'openEnded',
}

export const getLiquidityTypeLabels = (
    t: TFunction<'translation', undefined>,
    liquidityType: LiquidityTypeEnum
): string => {
    return {
        [LiquidityTypeEnum.closedEnded]: t('liquidity_type.closed_ended'),
        [LiquidityTypeEnum.openEnded]: t('liquidity_type.open_ended'),
    }[liquidityType];
};

export interface ProductClosedEndedInformation {
    keyDates?: KeyDate[];
    coolingOffDays?: number;
}

export enum DateFrequencyEnum {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    quarterly = 'quarterly',
    annually = 'annually',
}

export const dateFrequencyLabels: { [key in DateFrequencyEnum]: string } = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    annually: 'Annually',
};

export enum CalendarTimeEnum {
    firstDay = 'firstDay',
    lastDay = 'lastDay',
}

export const calendarTimeLabels: { [key in CalendarTimeEnum]: string } = {
    firstDay: 'First Day',
    lastDay: 'Last Day',
};

export enum InvestorLockUpEnum {
    None = 'None',
    HardLockUp = 'Hard Lock-up',
    SoftLockUp = 'Soft Lock-up',
}

export interface ProductOpenEndedInformation {
    redemptionFrequency?: DateFrequencyEnum;
    redemptionFrequencyDeadline?: CalendarTimeEnum;
    redemptionNoticeDays?: number;
    redemptionQuantityAvailablePercentage?: number;
    businessDayCountryCodeList?: string[];
    subscriptionFrequency?: DateFrequencyEnum;
    subscriptionFrequencyDeadline?: CalendarTimeEnum;
    subscriptionNoticeDays?: number;
}

export interface ProductFees {
    hasIncentiveFee?: boolean;
    incentiveFee?: {
        // carry fees below
        carryFeePercentage?: number;
        hurdleFeePercentage?: number;
        managerCatchupPercentage?: number;

        // performance fees below
        performanceFeePercentage?: number;
    };
}

export enum AssetClassTypeEnum {
    privateEquity = 'privateEquity',
    privateCredit = 'privateCredit',
    credit = 'credit',
    infrastructure = 'infrastructure',
    realEstate = 'realEstate',
    realAssets = 'realAssets',
    multiManager = 'multiManager',
    multiAsset = 'multiAsset',
}

export const getAssetClassTypeLabels = (
    t: TFunction<'translation', undefined>,
    assetClassType: AssetClassTypeEnum
): string => {
    return {
        [AssetClassTypeEnum.privateEquity]: t(
            'asset_class_type.private_equity'
        ),
        [AssetClassTypeEnum.privateCredit]: t(
            'asset_class_type.private_credit'
        ),
        [AssetClassTypeEnum.credit]: t('asset_class_type.credit'),
        [AssetClassTypeEnum.infrastructure]: t(
            'asset_class_type.infrastructure'
        ),
        [AssetClassTypeEnum.realEstate]: t('asset_class_type.real_estate'),
        [AssetClassTypeEnum.realAssets]: t('asset_class_type.real_assets'),
        [AssetClassTypeEnum.multiManager]: t('asset_class_type.multi_manager'),
        [AssetClassTypeEnum.multiAsset]: t('asset_class_type.multi_asset'),
    }[assetClassType];
};

export const AssetClassTypeLabel: { [key in AssetClassTypeEnum]: string } = {
    privateEquity: 'Private Equity',
    privateCredit: 'Private Credit',
    credit: 'Credit',
    infrastructure: 'Infrastructure',
    realEstate: 'Real Estate',
    realAssets: 'Real Assets',
    multiManager: 'Multi Manager',
    multiAsset: 'Multi Asset',
};

export interface EligibleInvestorType {
    value: string;
    label: string;
}

export interface EligibleInvestorJurisdiction {
    jurisdictionCountry: string;
    countryCode: string;
    investorTypes: EligibleInvestorType[];
}

export interface Eligibility {
    eligibleInvestorJurisdictions?: EligibleInvestorJurisdiction[];
    excludedCountryCodes?: string[];
    hideEligibilityFromProductPage?: boolean;
}

export interface ProductCheck {
    label: string;
    value: boolean;
    key: string;
}

export interface KeyDate {
    signedSubscriptionDeadline?: Date;
    closingDate?: Date;
    closingDescription?: string;
}

export enum AvailabilityStatusEnum {
    upcoming = 'upcoming',
    openForSubscription = 'openForSubscription',
    closedToSubscription = 'closedToSubscription',
    expired = 'expired',
}

export enum FundInterestsNameEnum {
    Share = 'Share',
    Unit = 'Unit',
}

export interface VisibilitySettings {
    hideAssetClassBadge?: boolean;
    hideLiquidityBadge?: boolean;
    hideCurrencies?: boolean;
    hideDistributionFeeOnShareclasses?: boolean;
}

export const AvailabilityStatusLabel: {
    [key in AvailabilityStatusEnum]: string;
} = {
    upcoming: 'Upcoming',
    openForSubscription: 'Open for Subscription',
    closedToSubscription: 'Closed to Subscription',
    expired: 'Expired',
};

export interface ProductResponse {
    _id: string;
    assetManagerId?: string;
    transferAgentId?: string;
    visibleWithoutLogin?: boolean;
    hideDistributionFee?: boolean;
    slug: string;
    name: string;
    umbrellaFundName?: string;
    changeLogs?: ProductChangeLogs[];
    status: ProductStatusEnum;
    referenceProductName: string;
    shortDescription: string;
    referenceProduct: string;
    thumbnail: string;
    availabilityStatus: AvailabilityStatusEnum;
    description: ProductDescription[];
    keyInformation: KeyValue[];
    keyInformationMarkdown?: string;
    images: Document[];
    videos?: Video[];
    shareClasses: ShareClass[];
    shareClassGroupNames?: string[];
    documents?: Document[];
    documentFolders?: string[];
    descriptionMarkdown?: string;
    contact: ProductContact;
    subscriptionAgreementCountersignature: string;
    assetClassType: AssetClassTypeEnum;
    liquidityType: LiquidityTypeEnum;
    closedEndedInformation?: ProductClosedEndedInformation;
    openEndedInformation?: ProductOpenEndedInformation;
    fees?: ProductFees;
    targetSubscriptionAmount?: number;
    capacityAmount?: number;
    baseCurrency?: string;
    eligibility?: Eligibility;
    checks: ProductCheck[];
    created_date: Date | string;
    updated_date: Date | string;
    inceptionDate?: Date;
    visibilitySettings?: VisibilitySettings;
    fundInterestsName?: FundInterestsNameEnum;
    subscriptionSigningDocumentTemplates?: string[];
    logo?: Document[];
    priority?: number;
}
