import { getDocumentUrl } from '@api/Documents';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { useCallback } from 'react';

export default function DocumentLinkRender({
    href,
    target = '_blank',
    rel = 'noopener noreferrer',
    title = 'Download',
    className = 'text-blue-500 hover:underline',
    children,
}) {
    const { withToken } = useStorageTokenState();
    const downloadOnClick = useCallback(
        async (e) => {
            try {
                e.preventDefault();
                const _url = await getDocumentUrl(withToken(href));
                window.open(_url, '_blank');
            } catch (error) {
                notifyError('Unable to download  Document');
            }
        },
        [href, withToken]
    );

    return (
        <a
            href="#"
            title={title}
            target={target}
            rel={rel}
            onClick={downloadOnClick}
            className={className}
        >
            {children}
        </a>
    );
}
