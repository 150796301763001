import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EndInvestorProfilesDelete } from '@api/EndInvestorProfiles';

import { AuthPermissionEnum, ClientEnum } from 'common';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { useCreateTemplate } from '@hooks/useCreateTemplate';

import { ActionsMenuProps } from '@components/Molecules';

import { handleCreateSubscriptionEvent } from '@services/SubscriptionsHelper';
import { ModalService } from '@services/ModalService';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { useCanAddSubscription } from './useCanAddSubscription';

export const usePortfolioActionMenuItems = ({
    selectedPill,
}: {
    selectedPill: any;
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { create: onCreateTemplateButtonClick } = useCreateTemplate();
    const { preferences } = useConfigurationState();
    const { currentUser, hasPermissions } = useAuthState();
    const { endInvestorProfile, endInvestorProfileId } =
        useEndInvestorProfile();
    const { downloadInvestorProfileDocuments } = useEndInvestorProfileState();
    const { canAddSubscription } = useCanAddSubscription();
    const { isEnvironment, isClient } = useConfigurationState();
    const isOddo = isClient(ClientEnum.ODDO);

    const onSubscribeButtonClick = useCallback(async () => {
        if (!endInvestorProfile) return;

        const acceptRiskBeforeCreatingSubscription =
            preferences?.feature.subscriptionProcess
                ?.acceptRiskBeforeCreatingSubscription;

        const verifyInvestorEmailBeforeNewSubscription =
            preferences?.feature.subscriptionProcess
                ?.verifyInvestorEmailBeforeNewSubscription;

        await handleCreateSubscriptionEvent(t, navigate, {
            endInvestorProfile,
            acceptRiskBeforeCreatingSubscription: Boolean(
                acceptRiskBeforeCreatingSubscription
            ),
            verifyInvestorEmailBeforeNewSubscription: Boolean(
                verifyInvestorEmailBeforeNewSubscription
            ),
            isOddo,
        });
    }, [
        endInvestorProfile,
        navigate,
        preferences?.feature.subscriptionProcess,
    ]);

    const handleDeleteClientClick = useCallback(() => {
        // open modal that asks for confirmation
        ModalService.getInstance()
            .setTitle('Delete Client')
            .setWidth('300px')
            .setComponent(
                <div>
                    <div className="font-bold text-red-500	">Warning!</div>
                    All subscriptions and any documents will be permanently
                    deleted from the system.{' '}
                    <b>This operation is irreversible.</b> Are you sure you want
                    to continue?
                </div>
            )
            .setShowFooter(true)
            .setConfirmationLabel('Delete')
            .setConfirmCallback(async () => {
                EndInvestorProfilesDelete(endInvestorProfileId!)
                    .then(() => {
                        notifySuccess(
                            'End investor profile deleted successfully'
                        );

                        setTimeout(() => {
                            navigate('/clients');
                        }, 1000);
                        // reload subscriptions and show success message
                    })
                    .catch((error) => {
                        notifyError('Client could not be deleted');
                    })
                    .finally(() => {
                        setTimeout(() => {
                            ModalService.getInstance().hideModal();
                        }, 1000);
                    });
            })
            .showModal();
    }, [endInvestorProfileId, navigate]);

    const handleDownloadDocumentPack = useCallback(() => {
        return async () =>
            await downloadInvestorProfileDocuments(endInvestorProfileId!);
    }, [downloadInvestorProfileDocuments, endInvestorProfileId]);

    const actionMenuItems = useMemo(() => {
        const items: ActionsMenuProps['actionItems'] = [];

        if (canAddSubscription) {
            items.push({
                label: t('client.actions.add_subscription'),
                onClick: () => onSubscribeButtonClick(),
            });
        }

        // if (hasPermissions(AuthPermissionEnum.createSubscription)) {
        //     items.push({
        //         label: t('client.actions.create_template'),
        //         onClick: () => onCreateTemplateButtonClick(endInvestorProfile!),
        //     });
        // }

        if (
            preferences?.feature?.data?.canSuperUserDeleteEndInvestors &&
            hasPermissions([AuthPermissionEnum.deleteEndInvestorProfile])
        ) {
            items.push({
                label: t('client.actions.delete_client'),
                onClick: () => handleDeleteClientClick(),
            });
        }

        if (
            hasPermissions(AuthPermissionEnum.getEndInvestorProfileDocuments) &&
            selectedPill === 'ClientDetails'
        ) {
            items.push({
                label: 'Download Document Pack',
                onClick: () => handleDownloadDocumentPack(),
            });
        }

        // if (hasPermissions(AuthPermissionEnum.updateEndInvestorProfileLink)) {
        //     items.push({
        //         label: 'Link profile',
        //         onClick: () => handleLinkProfile(),
        //     });
        // }

        items.push({
            label: t('client.actions.client_details'),
            onClick: () => {
                navigate(`/clients/${endInvestorProfileId}/account-details`);
            },
        });

        return items;
    }, [
        currentUser?.user.role,
        handleDeleteClientClick,
        handleDownloadDocumentPack,
        hasPermissions,
        onSubscribeButtonClick,
        preferences?.feature.accessControl
            ?.relationshipManagersCannotAddNewSubscriptions,
        preferences?.feature?.data?.canSuperUserDeleteEndInvestors,
        selectedPill,
    ]);

    return { actionMenuItems };
};
