import { Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProductsState } from '@stores/Products';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { FormCheckbox } from '@components/Molecules';
import EditProductFormikButtons from './EditProductFormikButtons';
import { VisibilitySettings as VisibilitySettingsType } from '@interfaces/Api/ProductResponse';
import { useTranslation } from 'react-i18next';

export const VisibilitySetting: React.FC = () => {
    const { t } = useTranslation();
    const initialVals: VisibilitySettingsType = {
        hideAssetClassBadge: false,
        hideLiquidityBadge: false,
        hideCurrencies: false,
        hideDistributionFeeOnShareclasses: false,
    };

    const [formValue, setFormValue] = useState<any>(initialVals);

    const { productId } = useParams();

    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const onSubmit = useProductSubmit(
        productId,
        `subscription-signing-documents-templates`
    );

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{t('admin.product.visibility_setting')}</h1>
            {(productId === 'new' || (productId && formValue._id)) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={(values) => {
                        return onSubmit(values);
                    }}
                    enableReinitialize
                >
                    <Form>
                        <div className="my-3">
                        <FormCheckbox
                                name="visibilitySettings.hideAssetClassBadge"
                                actionLabel={t("admin.product.do_not_show_asset_class_badge_on_the_header_of_product_page")}
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideLiquidityBadge"
                                actionLabel={t("admin.product.do_not_show_liquidity_badge_badge_on_the_header_of_product_page")}
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideCurrencies"
                                actionLabel={t('admin.product.do_not_show_currencies_badge_on_the_header_of_product_page')}
                            />
                        </div>
                        <div className="my-3">
                            <FormCheckbox
                                name="visibilitySettings.hideDistributionFeeOnShareclasses"
                                actionLabel={t('admin.product.do_not_show_distribution_fee_on_the_product_page_and_on_subscriptions')}
                            />
                        </div>
                        <div className="my-3">
                            <EditProductFormikButtons
                                productId={productId}
                                isReadOnly={false}
                                hasPreview
                                pathPrevious="logo"
                                previewLinkTo={`../../../products/${product?.slug}`}
                            />
                        </div>
                    </Form>
                </Formik>
            )}
        </div>
    );
};
