import {
    LiquidityTypeEnum,
    ProductResponse,
    SubscriptionResponse,
    SubscriptionStatusEnum,
    SystemUserResponse,
} from '@interfaces/Api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@components/Atoms';
import {
    AlertBox,
    AlertBoxType,
    SubscriptionTimeline,
} from '@components/Molecules';

import { useAuthState } from '@contexts/AuthContext';
import { dateShortFormatter } from '@helpers/Date.helper';
import {
    getUpcomingSignedSubscriptionDeadline,
    nextDateString,
} from '@helpers/Product.helper';
import { SubscriptionStatusTimelineActivity } from '@interfaces/Api/SubscriptionTimeline';
import { canCancelSubscription } from '@services/SubscriptionsHelper';
import { handleGetSubscriptionStatusTimeline } from '@stores/Subscriptions/Subscriptions.services';
import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { getFullName } from '@helpers/common.helper';
import {
    AuthPermissionEnum,
    AuthRoleEnum,
    CompletenessCheckType,
    CompletenessDecisionType,
} from 'common';
import { roleMap } from '@helpers/SystemUser.helper';

interface SubscriptionStatusInfoProps {
    subscription: SubscriptionResponse;
    product?: ProductResponse;
    onCancelSubscription?: () => void;
}

export const SubscriptionStatusInfo: React.FC<SubscriptionStatusInfoProps> = (
    props
) => {
    const { t } = useTranslation();
    const { subscription, product, onCancelSubscription } = props;
    const { hasPermissions, hasRoles } = useAuthState();
    const [statusTimeline, setStatusTimeline] =
        useState<SubscriptionStatusTimelineActivity[]>();
    const [reviewerSystemUser, setReviewerSystemUser] =
        React.useState<SystemUserResponse>();
    const [reviewerRole, setReviewerRole] = React.useState<string>('');
    const [comments, setComments] = useState('');

    useEffect(() => {
        const informationRequest = subscription?.completenessChecks?.statusLogs
            ?.filter(
                (check) =>
                    check.decision ===
                    CompletenessDecisionType.requestMoreInformation
            )
            ?.sort((a, b) => {
                return (
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                );
            })?.[0];
        if (informationRequest?.comments) {
            setComments(informationRequest?.comments);
        }
        if (informationRequest?.role) {
            setReviewerRole(roleMap[informationRequest?.role]);
        }
        if (informationRequest?.systemUserId) {
            SystemUsersGet(informationRequest?.systemUserId)
                .then((systemUser: any) => {
                    setReviewerSystemUser(systemUser);
                })
                .catch((error) => {
                    //log error to stack trace
                });
        }
    }, [subscription?.completenessChecks?.statusLogs]);

    const showStatusLogs = useCallback(async () => {
        const timeline = await handleGetSubscriptionStatusTimeline(
            subscription._id
        );

        setStatusTimeline(timeline);
    }, [subscription._id]);
    const statusMessages: {
        title: string;
        alertType: AlertBoxType;
        message: () => JSX.Element | null;
    }[] = useMemo(() => {
        let messages = [
            {
                title: t('subscription.status_info.subscription_cancelled'),
                alertType: 'error' as AlertBoxType,
                showItem: true,
                message: () => {
                    if (
                        subscription.status === SubscriptionStatusEnum.cancelled
                    ) {
                        return <p>This subscription has been cancelled.</p>;
                    }
                    return null;
                },
            },
            {
                title: t('subscription.status_info.subscription_information'),
                alertType: 'info' as AlertBoxType,
                showItem: true,
                message: () => {
                    if (
                        [
                            SubscriptionStatusEnum.inProgress,
                            SubscriptionStatusEnum.readyForReview,
                        ].includes(subscription.status)
                    ) {
                        if (
                            product?.liquidityType ===
                                LiquidityTypeEnum.closedEnded &&
                            product.closedEndedInformation?.keyDates
                        ) {
                            const keyDate =
                                getUpcomingSignedSubscriptionDeadline(
                                    product.closedEndedInformation.keyDates
                                );

                            if (keyDate?.signedSubscriptionDeadline) {
                                const signedSubscriptionDeadline =
                                    dateShortFormatter(
                                        keyDate.signedSubscriptionDeadline
                                    );

                                return (
                                    <p>
                                        Subscription Deadline Date:{' '}
                                        {signedSubscriptionDeadline}
                                    </p>
                                );
                            }
                        }
                        if (
                            product?.liquidityType ===
                            LiquidityTypeEnum.openEnded
                        ) {
                            const information = product?.openEndedInformation;
                            const readableNextSubscriptionDate =
                                information?.subscriptionFrequency &&
                                information.subscriptionFrequencyDeadline &&
                                nextDateString(
                                    information.subscriptionFrequency,
                                    information.subscriptionFrequencyDeadline,
                                    information.subscriptionNoticeDays || 0
                                );
                            if (readableNextSubscriptionDate) {
                                return (
                                    <p>
                                        {t(
                                            'subscription.status_info.next_subscription_date'
                                        ) + ' '}
                                        {readableNextSubscriptionDate}
                                    </p>
                                );
                            }
                        }
                    }
                    return null;
                },
            },
            {
                title: t('subscription.status_info.subscription_submitted'),
                alertType: 'info' as AlertBoxType,
                showItem: true,
                message: () => {
                    if (
                        [
                            SubscriptionStatusEnum.readyForReview,
                            SubscriptionStatusEnum.approved,
                            SubscriptionStatusEnum.rejected,
                        ].includes(subscription.status)
                    ) {
                        return (
                            <>
                                <div className="flex justify-end ">
                                    {/* TODO: Below needs to be refactored further */}
                                    {product &&
                                        canCancelSubscription(
                                            subscription,
                                            product,
                                            hasPermissions
                                        ) && (
                                            <div className="flex items-center justify-end">
                                                {/* if subscription is approved, then show in cooling off period message */}
                                                {subscription.status ===
                                                    SubscriptionStatusEnum.approved &&
                                                    product.liquidityType ==
                                                        LiquidityTypeEnum.closedEnded && (
                                                        <p className="pr-3">
                                                            Cooling off period
                                                            ends on{' '}
                                                            {dateShortFormatter(
                                                                dayjs(
                                                                    subscription
                                                                        .statusLogs[
                                                                        subscription
                                                                            .statusLogs
                                                                            .length -
                                                                            1
                                                                    ].createdAt
                                                                )
                                                                    .add(
                                                                        product
                                                                            .closedEndedInformation
                                                                            ?.coolingOffDays ||
                                                                            0,
                                                                        'day'
                                                                    )
                                                                    .toDate()
                                                            )}
                                                        </p>
                                                    )}

                                                <Button
                                                    size="small"
                                                    className="mr-2"
                                                    label={t(
                                                        'subscriptions.subscription_submitted.cancel_subscription_button'
                                                    )}
                                                    onClick={() => {
                                                        onCancelSubscription &&
                                                            onCancelSubscription();
                                                    }}
                                                />
                                            </div>
                                        )}
                                    {!hasRoles([AuthRoleEnum.endInvestor]) && (
                                        <Button
                                            label={t(
                                                'subscriptions.view_time_line'
                                            )}
                                            onClick={() => {
                                                showStatusLogs();
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        );
                    }
                    return null;
                },
            },
            {
                title: t('subscription.status_info.information_requested'),
                alertType: 'warning' as AlertBoxType,
                showItem: !hasRoles([AuthRoleEnum.endInvestor]),
                message: () => {
                    if (
                        subscription?.completenessChecks?.status ===
                        CompletenessCheckType.requestMoreInformation
                    ) {
                        const reviewerName = reviewerSystemUser
                            ? getFullName(
                                  reviewerSystemUser?.firstName,
                                  reviewerSystemUser?.surname
                              )
                            : reviewerRole;

                        return (
                            <>
                                <p className="mt-4 whitespace-break-spaces">
                                    The following information has been requested
                                    by {reviewerName ?? 'the reviewer'}:{'\n\n'}
                                    {comments}
                                </p>
                                {hasPermissions(
                                    AuthPermissionEnum.updateSubscriptionRequestMoreInformationForRelationshipManager
                                ) && (
                                    <p className="mt-4">
                                        <Link
                                            className="underline text-decoration-line:"
                                            to={`/subscriptions/${subscription._id}/supporting-documents`}
                                        >
                                            Review the information request
                                        </Link>
                                    </p>
                                )}
                            </>
                        );
                    }
                    return null;
                },
            },
        ].filter((a) => a.message() && a.showItem);

        // only show error messages if there are error messages
        if (messages.find((a) => a.alertType === 'error')) {
            messages = messages.filter((a) => a.alertType === 'error');
        }
        return messages;
    }, [
        t,
        hasRoles,
        subscription,
        product,
        hasPermissions,
        onCancelSubscription,
        showStatusLogs,
        reviewerSystemUser,
        reviewerRole,
        comments,
    ]);

    return (
        <div>
            <div>
                <Drawer
                    isOpen={!!statusTimeline}
                    onClose={() => setStatusTimeline(undefined)}
                >
                    {statusTimeline && (
                        <SubscriptionTimeline
                            subscriptionId={subscription._id}
                            timeline={statusTimeline}
                            handleClose={() => setStatusTimeline(undefined)}
                        />
                    )}
                </Drawer>
            </div>
            {statusMessages &&
                statusMessages.map((potentialMessage) => (
                    <AlertBox
                        key={potentialMessage.title}
                        alertType={potentialMessage.alertType as AlertBoxType}
                        title={potentialMessage.title}
                        message={potentialMessage.message()}
                    />
                ))}
        </div>
    );
};
