import { ClientEnum } from "common";
import { useApplicationSetting } from "@hooks/useApplicationSetting";
import { useConfigurationState } from '@contexts/ConfigurationContext';

const SuccessfulSubmission: React.FC = () => {
    const { applicationSetting } = useApplicationSetting();
    const { configuration, configurationLoading } = useConfigurationState();

    const welcomeMessage = `Welcome to the ${configuration.client || ClientEnum.S64} investment portal.`;

    return (
        <div className="mt-3 flex flex-col items-center">
            { !configurationLoading && 
                <>
                    <h2 className="text-2xl logo-blue align-middle pb-4">
                        {welcomeMessage}
                    </h2>
                    <p>
                        We’ve sent a verification link to your email address. If you do not receive the link, please reach out to <a href={`mailto:${applicationSetting?.techSupportContactEmail}`}>{applicationSetting?.techSupportContactEmail}</a>.
                    </p>
                </>
            }
        </div>
    );
};

export default SuccessfulSubmission;
