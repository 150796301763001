import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { Button, Icons, InputBox } from '@components/Atoms';
import { notifySuccess } from '@helpers/toastrHelper';
import { useAuthState } from '@contexts/AuthContext';
import { useTranslation } from 'react-i18next';

interface TopBarProps {
    setCurrentPageNumber: (n: number) => void;
    setSearchText: (val: string) => void;
    getSystemUsers: (val: boolean) => void;
}

export const TopBar: React.FC<TopBarProps> = (props) => {
    const { t } = useTranslation();
    const { setCurrentPageNumber, setSearchText } = props;

    const { currentSystemUserRoleActions } = useAuthState();

    const refreshTableData = async () => {
        await props.getSystemUsers(false);
        notifySuccess('System User Data has been refreshed');
    };

    return (
        <>
            <div className="flex justify-between w-full mb-5">
                <h1 className="text-3xl text-logo-blue font-bold align-middle w-3/5 lg:w-4/5">
                    {t('navbar.admin.user_management')}
                </h1>
                {currentSystemUserRoleActions?.create &&
                    currentSystemUserRoleActions.create.length > 0 && (
                        <Link
                            to="/admin/users/create-user"
                            className="text-white"
                        >
                            <Button label={t('admin.user_management.add_user')}/>
                        </Link>
                    )}
            </div>

            <div className="flex flex-row flex-wrap justify-between mb-2">
                <div
                    id="search-user"
                    className="justify-start w-4/5 md:w-2/5 items-left"
                >
                    <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
                        <Form
                            onChange={(val: any) => {
                                setCurrentPageNumber(1);
                                setSearchText(val.target.value);
                            }}
                        >
                            <InputBox
                                name="search"
                                placeholder={t('admin.user_management.search_user')}
                                autoComplete="off"
                            />
                        </Form>
                    </Formik>
                </div>

                <Button
                    hasIconOnly
                    startIcon={<Icons name="ArrowPathIcon" />}
                    onClick={refreshTableData}
                    className="self-end bg-brand-navy-light rounded-md p-1 mr-2 cursor-pointer"
                />
            </div>
        </>
    );
};
