import { Button } from '@components/Atoms';
import { useAuthState } from '@contexts/AuthContext';
import { dateShortFormatter, formatDateTimeString } from '@helpers/Date.helper';
import {
    SigningDocumentStatusEnum,
    SubscriptionStatusEnum,
} from '@interfaces/Api';
import { SubscriptionStatusTimelineActivity } from '@interfaces/Api/SubscriptionTimeline';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import {
    ActivityTypeEnum,
    AuthPermissionEnum,
    NotificationStateEnum,
    NotificationStateLabelEnum,
    NotificationTypeEnum,
} from 'common';
import { startCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SubscriptionTimelineProps {
    subscriptionId: string;
    timeline: SubscriptionStatusTimelineActivity[];
    handleClose: Function;
}

const formatStatus = (status: string) => startCase(status);

const showToIcon = (item: SubscriptionStatusTimelineActivity) =>
    item.oldStatus && item.status;

const renderSubscriptionTimelineActivity = (
    item: SubscriptionStatusTimelineActivity
) => {
    if (item.activityType === ActivityTypeEnum.Subscription) {
        if (
            item.status === SubscriptionStatusEnum.inProgress &&
            !item.oldStatus
        ) {
            return (
                <span>
                    {`${startCase(item.userRole)} - ${item.userName} created a
                    new subscription`}
                </span>
            );
        } else if (
            item.status === SubscriptionStatusEnum.readyForReview &&
            item.oldStatus === SubscriptionStatusEnum.inProgress
        ) {
            return (
                <span>
                    {`${startCase(item.userRole)} - ${item.userName} submitted 
                    subscription`}
                </span>
            );
        } else {
            return (
                <span>
                    {`${startCase(item.userRole)} - ${item.userName} updated 
                    subscription`}
                </span>
            );
        }
    }
};

const renderSigningDocumentTimelineActivity = (
    item: SubscriptionStatusTimelineActivity
) => {
    if (item.status === SigningDocumentStatusEnum.signed) {
        return <span>{`Investor signed ${startCase(item.documentType)}`}</span>;
    }
    return <span>{`Document - ${startCase(item.documentType)}`}</span>;
};

const renderCompletenessCheckTimelineActivity = (item) => {
    return (
        <span>
            {`${startCase(item.role)} - ${item.userName}  - ${startCase(
                item.decision
            )} - ${startCase(item.type)} - ${item.comments}`}
        </span>
    );
};

const renderSFTPTimelineActivity = (
    item: SubscriptionStatusTimelineActivity
) => {
    return (
        <span>
            {[
                'Documents',
                NotificationStateLabelEnum[item.state]?.toLowerCase(),
                'via',
                item.sentTo,
            ]
                .filter((i) => i)
                .join(' ')}
        </span>
    );
};

const SubscriptionTimeline: React.FC<SubscriptionTimelineProps> = ({
    subscriptionId,
    timeline,
    handleClose,
}) => {
    const { t } = useTranslation();
    const { hasPermissions } = useAuthState();

    const { retryDocumentPackUpload } = useSubscriptionsActions();
    const [retryUpload, setRetryUpload] = useState(false);

    const documentPackUploadNotificationTypes = [
        NotificationTypeEnum.uploadDocumentsOnMiddleOfficeApproval,
        NotificationTypeEnum.uploadDocumentsOnCounterSignature,
        NotificationTypeEnum.documentPackUploadRetryToSftp,
    ];

    const handleRetryDocumentPackUpload = async () => {
        if (!subscriptionId) return;

        setRetryUpload(true);
        await retryDocumentPackUpload({ subscriptionId });

        handleClose();
        setRetryUpload(false);
    };

    const documentUploadNotifications = timeline.filter(
        (t) =>
            t.activityType === ActivityTypeEnum.Notification &&
            documentPackUploadNotificationTypes.includes(t.notificationType)
    );
    const lastDocumentUploadNotification =
        documentUploadNotifications[documentUploadNotifications.length - 1];

    const getTime = (date: Date) => new Date(date).getTime();

    const renderRetryButton = (item: SubscriptionStatusTimelineActivity) => {
        if (
            hasPermissions(
                AuthPermissionEnum.approveSubscriptionForMiddleOffice
            ) &&
            lastDocumentUploadNotification.state ===
                NotificationStateEnum.FailedToSend &&
            getTime(lastDocumentUploadNotification.updatedDate) ===
                getTime(item.updatedDate)
        ) {
            return (
                <Button
                    id="retry-document-pack-upload"
                    label={'Retry'}
                    type="button"
                    buttonType="preview"
                    size="small"
                    className="ml-2"
                    onClick={handleRetryDocumentPackUpload}
                    isLoading={retryUpload}
                />
            );
        }
    };

    const getActivityText = (item: SubscriptionStatusTimelineActivity) => {
        if (item.activityType === ActivityTypeEnum.Subscription) {
            return renderSubscriptionTimelineActivity(item);
        }

        if (item.activityType === ActivityTypeEnum.SigningDocument) {
            return renderSigningDocumentTimelineActivity(item);
        }

        if (item.activityType === ActivityTypeEnum.CompletenessCheck) {
            return renderCompletenessCheckTimelineActivity(item);
        }

        if (item.activityType === ActivityTypeEnum.Notification) {
            if (
                documentPackUploadNotificationTypes.includes(
                    item.notificationType
                )
            ) {
                return (
                    <>
                        {renderSFTPTimelineActivity(item)}
                        {renderRetryButton(item)}
                    </>
                );
            } else {
                if (
                    item.notificationType ===
                    NotificationTypeEnum.closingDateUpdated
                ) {
                    return (
                        <span>
                            {t(
                                'subscriptions.activity_timeline.closing_date_updated'
                            )}{' '}
                            {item?.comment &&
                                dateShortFormatter(new Date(item?.comment))}
                        </span>
                    );
                } else {
                    return <span>{`Notification sent to ${item.sentTo}`}</span>;
                }
            }
        }

        return '';
    };

    if (!timeline) return;
    return (
        <div>
            {/* large bold text */}
            <div className="text-2xl font-bold m-3 pl-2">
                {t('subscriptions.activity_timeline.heading')}
            </div>
            <Timeline
                position="right"
                sx={{
                    maxWidth: (window.innerWidth * 2) / 3,
                }}
            >
                {timeline.map((item, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent>
                            {getActivityText(item)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot
                                color={
                                    item.state ===
                                    NotificationStateEnum.FailedToSend
                                        ? 'error'
                                        : 'success'
                                }
                            />

                            {index !== timeline.length - 1 ? (
                                <TimelineConnector />
                            ) : null}
                        </TimelineSeparator>
                        <TimelineContent
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                            <span>
                                {`${formatStatus(item.oldStatus)}`}
                                {(showToIcon(item) && ' -> ') || ''}
                                {`${formatStatus(item.status)}`}
                            </span>
                            <Typography variant="body2" color="text.secondary">
                                {formatDateTimeString(item.updatedDate)}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </div>
    );
};

export default SubscriptionTimeline;
