import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { productSteps } from '@services/ProductsHelper';
import { WorkflowStepper } from '..';
import { useTranslation } from 'react-i18next';

const ProductSteps: React.FC = () => {
    const { t } = useTranslation()
    const location = useLocation();
    const endPathUrl = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    );

    const { productId } = useParams();

    const steps = useMemo(() => {
        return productSteps(t).map((step) => ({
            ...step,
            stepUrl: `${productId}/${step.endPathUrl}`,
            label: step.stepName,
        }));
    }, [productId, t]);

    return (
        <div>
            <WorkflowStepper
                steps={steps}
                activeStep={{ endPathUrl: endPathUrl }}
            />
        </div>
    );
};

export default ProductSteps;
