import { BaseUrl } from '@helpers/Environment';
import { Http } from '../libs/http';
import { ApplicationSetting } from 'common';

export const APPLICATION_SETTING_URL = `${BaseUrl}/application-setting`;

export const applicationSettingGet = () =>
    Http.get<ApplicationSetting>(APPLICATION_SETTING_URL);

export const applicationSettingPut = (applicationSetting: ApplicationSetting) =>
    Http.put<ApplicationSetting>(APPLICATION_SETTING_URL, applicationSetting);
