import { ITransferAgent } from 'common';
import { Http } from '../libs/http';
import { BaseUrl } from '@helpers/Environment';

const TRANSFER_AGENT_URL = `${BaseUrl}/transfer-agent`;

export const postTransferAgent = async (name): Promise<ITransferAgent> => {
    return await Http.post(`${TRANSFER_AGENT_URL}/`, { name });
};

export const getTransferAgents = async (): Promise<ITransferAgent[]> => {
    return await Http.get(TRANSFER_AGENT_URL);
};

export const getTransferAgent = async (
    transferAgentId: string
): Promise<ITransferAgent> => {
    return await Http.get(`${TRANSFER_AGENT_URL}/${transferAgentId}`);
};
