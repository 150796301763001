import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { AuthPermissionEnum, AuthRoleEnum } from 'common';
import { useMemo } from 'react';

export const useCanAddSubscription = () => {
    const { preferences } = useConfigurationState();
    const { currentUser, hasPermissions } = useAuthState();
    const { endInvestorProfile } = useEndInvestorProfile();

    const canUserAddSubscription = useMemo(() => {
        if (!hasPermissions(AuthPermissionEnum.createSubscription)) {
            return false;
        }

        if (
            preferences?.feature.accessControl
                ?.relationshipManagersCannotAddNewSubscriptions &&
            currentUser?.user.role === AuthRoleEnum.relationshipManager
        ) {
            return false;
        }

        if (
            currentUser?.user.role === AuthRoleEnum.endInvestor &&
            (!endInvestorProfile || endInvestorProfile?.serviceLevelType ===
                EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory)
        ) {
            return false;
        }

        return true;
    }, [currentUser?.user.role, endInvestorProfile, hasPermissions, preferences?.feature.accessControl?.relationshipManagersCannotAddNewSubscriptions]) ;

    return {
        canAddSubscription: canUserAddSubscription,
    };
};
