import React, { useState } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useIsAuthenticated } from '@azure/msal-react';

const RequiredAuth: React.FC = ({ children }) => {
    const { isLoggedIn } = useAuthState();

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { configuration } = useConfigurationState();
    const isAuthenticated = useIsAuthenticated();
    const [callbackURL] = useState<boolean>(location.pathname === '/callback');

    const defaultRedirectRoute = configuration?.preferences?.feature
        ?.authentication?.enableProductsPageWithoutLoggingIn
        ? '/products'
        : '/login';
    const peristedURL = sessionStorage.getItem('PERSISTED_URL');

    if (
        !peristedURL &&
        !isLoggedIn &&
        !isAuthenticated &&
        defaultRedirectRoute === '/login' &&
        location.pathname !== '/' &&
        !location.pathname.includes('/callback')
    ) {
        sessionStorage.setItem(
            'PERSISTED_URL',
            JSON.stringify(location.pathname)
        );
    }

    return (
        <>
            {!callbackURL && isLoggedIn && children}

            {location.pathname === '/' && (
                <Navigate
                    to={defaultRedirectRoute}
                    replace
                    state={{
                        path: `${location.pathname}${
                            searchParams ? `?${searchParams}` : ''
                        }`,
                    }}
                />
            )}
            {!callbackURL && !isLoggedIn && !isAuthenticated && (
                <Navigate
                    to={defaultRedirectRoute}
                    replace
                    state={{
                        path: `${location.pathname}${
                            searchParams ? `?${searchParams}` : ''
                        }`,
                    }}
                />
            )}
        </>
    );
};

export default RequiredAuth;
