import { ITenantComponentProvider } from '../ITenantComponentProvider';
import RegisterProspectiveInvestor from '../HG/RegisterProspectiveInvestor/RegisterProspectiveInvestor';
import ProspectiveInvestorProfilePage from '../HG//ProspectiveInvestorProfilePage/ProspectiveInvestorProfilePage';
import SuitabilityQuestionnairePage from '../HG//SuitabilityQuestionnaire/SuitabilityQuestionnairePage';
import ProspectiveInvestorListPage from '../HG//ProspectiveInvestorsListPage/ProspectiveInvestorsListPage';
import ProspectiveInvestorViewPage from '../HG//ProspectiveInvestorsListPage/ProspectiveInvestorViewPage';
import ProspectiveInvestorDefaultsPage from '../HG//ProspectiveInvestorDefaultsPage/ProspectiveInvestorDefaultsPage';
import { HelpAndContact } from './HelpAndContact/HelpAndContact';
import { BankSelection } from '@components/Organisms';
import { ServiceLevelSelection } from '@pages/Clients/Clients/Components/Registration/Components';

export class DefaultComponents implements ITenantComponentProvider {
    GetB2CClientRegistrationPage = () => {
        return RegisterProspectiveInvestor;
    };
    GetProspectiveInvestorProfilePage = () => {
        return ProspectiveInvestorProfilePage;
    };
    GetSuitabilityQuestionnairePage = () => {
        return SuitabilityQuestionnairePage;
    };
    GetProspectiveInvestorListPage = () => {
        return ProspectiveInvestorListPage;
    };
    GetProspectiveInvestorViewPage = () => {
        return ProspectiveInvestorViewPage;
    };
    GetProspectiveInvestorsDefaults = () => {
        return ProspectiveInvestorDefaultsPage;
    };
    GetHelpAndContact = () => {
        return HelpAndContact;        
    };

    GetBankSelection = () => {
        return BankSelection;
    }

    GetServiceLevelSelection = () => {
        return ServiceLevelSelection;
    }
}
