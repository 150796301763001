import { SelectOption } from '@interfaces/InterfaceFormsProps';
import { RiskLevel } from 'common';

export const subscriptionRiskLevels: SelectOption<RiskLevel>[] = [
    {
        label: '1 - Low',
        value: RiskLevel.low,
    },
    {
        label: '2 - Medium',
        value: RiskLevel.medium,
    },
    {
        label: '3 - High',
        value: RiskLevel.high,
    },
];
