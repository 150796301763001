import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';
import ReviewClientPersonalDetails from '@pages/Portfolio/Components/ReviewClientPersonalDetails';
import ReviewClientBusinessDetails from '@pages/Portfolio/Components/ReviewClientBusinessDetails';
import { EndInvestorProfileResponse } from '@interfaces/Api';

interface ReviewClientDetailsProps {
    endInvestorProfile: EndInvestorProfileResponse
}

function ReviewClientDetails({endInvestorProfile}: ReviewClientDetailsProps) {
    return (
        <div className="text-base leading-5">
            <div className="pt-1">
                <ReviewClientPersonalDetails data={endInvestorProfile} />
            </div>
            <div className="pt-1">
                {endInvestorProfile?.endInvestorType !== 'individual' && (
                    <ReviewClientBusinessDetails data={endInvestorProfile} />
                )}
            </div>
        </div>
    );
}

export default ReviewClientDetails;
