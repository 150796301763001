import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useAuthState } from '@contexts/AuthContext';
import { cryptoHelper } from '@helpers/crypto.helper';
import {
    DataGridPremium,
    GridColDef,
    GridEventListener,
    GridRowParams,
    GridRowsProp,
    GridSlotsComponentsProps,
    UncapitalizedGridPremiumSlotsComponent,
    useGridApiRef,
    gridPageSizeSelector,
    gridPageSelector,
    gridSortModelSelector,
    gridFilterModelSelector,
} from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import './Grid.css';
interface Props {
    name: string;
    apiRef?: ReturnType<typeof useGridApiRef>;
    rows: GridRowsProp;
    columns: GridColDef[];
    slots?: Partial<UncapitalizedGridPremiumSlotsComponent>;
    slotProps?: GridSlotsComponentsProps;
    initialState?: GridInitialStatePremium;
    onRowHandler?: (params: GridRowParams<any>) => void;
    selectedRowId?: string;
    totalRows?: number;
    serverSide?: boolean; // new prop to indicate if server-side handling is enabled
    onLoadData?: (queryString: string) => void; // handler to load data from the server
}

const Grid = ({
    name,
    apiRef,
    rows,
    columns,
    slots,
    slotProps,
    initialState,
    totalRows,
    onRowHandler,
    selectedRowId,
    serverSide,
    onLoadData,
}: Props) => {
    const { currentUser } = useAuthState();

    const gridStateStorageName = useMemo(
        () =>
            `${name}-${cryptoHelper.getHash(currentUser?.user?._id)}-gridState-new`,
        [currentUser, name]
    );

    const handleEvent: GridEventListener<'rowClick'> = (params) => {
        onRowHandler && onRowHandler(params);
    };

    const createQueryString = useCallback(() => {
        const pageSize = gridPageSizeSelector(apiRef.current.state) || 10;
        const page = gridPageSelector(apiRef.current.state);
        const sortModel = gridSortModelSelector(apiRef.current.state);
        const filterModel = gridFilterModelSelector(apiRef.current.state);

        const queryParts = [
            `pageSize=${encodeURIComponent(pageSize)}`,
            `page=${encodeURIComponent(page)}`,
        ];

        // Filter parameters
        filterModel.items.forEach((item, index) => {
            queryParts.push(
                `filters[${index}][field]=${encodeURIComponent(item.field)}`
            );
            queryParts.push(
                `filters[${index}][operator]=${encodeURIComponent(
                    item.operator
                )}`
            );
            queryParts.push(
                `filters[${index}][value]=${encodeURIComponent(item.value)}`
            );
        });

        // Sorting parameters
        sortModel.forEach((sort, index) => {
            queryParts.push(
                `sort[${index}][field]=${encodeURIComponent(sort.field)}`
            );
            queryParts.push(
                `sort[${index}][sort]=${encodeURIComponent(sort.sort)}`
            );
        });

        return queryParts.join('&');
    }, [apiRef]);

    const processServerSideHandlers = useCallback(() => {
        if (!serverSide) return;

        const updateData = () => {
            const queryString = createQueryString();
            onLoadData && onLoadData(queryString);
        };

        // Subscribe to necessary events only if server-side operations are enabled
        const unsubscribeFilters = apiRef?.current?.subscribeEvent(
            'filterModelChange',
            updateData
        );
        const unsubscribePagination = apiRef?.current?.subscribeEvent(
            'paginationModelChange',
            updateData
        );
        const unsubscribeSorting = apiRef?.current?.subscribeEvent(
            'sortModelChange',
            updateData
        );

        return () => {
            unsubscribeFilters?.();
            unsubscribePagination?.();
            unsubscribeSorting?.();
        };
    }, [apiRef, serverSide, createQueryString, onLoadData]);

    useEffect(() => {
        processServerSideHandlers();
    }, [processServerSideHandlers]);

    useEffect(() => {
        if (apiRef && apiRef.current) {
            const allRowsId = apiRef?.current?.getAllRowIds();
            const idFound = allRowsId.some((x) => x === selectedRowId);

            if (idFound && rows.length) {
                apiRef?.current?.selectRow(selectedRowId);
                const data = rows.find((x) => x.id === selectedRowId);
                if (data) onRowHandler({ row: data } as any);
            }
        }
    }, [apiRef, selectedRowId, rows, onRowHandler]);

    const saveState = useCallback(() => {
        if (apiRef?.current?.exportState && localStorage) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem(
                gridStateStorageName,
                JSON.stringify(currentState)
            );
        }
    }, [apiRef, gridStateStorageName]);

    useLayoutEffect(() => {
        const stateFromLocalStorage =
            localStorage?.getItem(gridStateStorageName);

        apiRef.current.restoreState(JSON.parse(stateFromLocalStorage) || {});

        window.addEventListener('beforeunload', saveState);

        return () => {
            window.removeEventListener('beforeunload', saveState);
            saveState();
        };
    }, [saveState, apiRef, gridStateStorageName]);
    return (
        <div className="h-screen">
            <DataGridPremium
                sx={{
                    overflowX: 'scroll',
                }}
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                slots={slots}
                slotProps={slotProps}
                initialState={{
                    ...initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                paginationMode={serverSide ? 'server' : 'client'}
                filterMode={serverSide ? 'server' : 'client'}
                autoPageSize={!serverSide} // Automatically adjust page size in client-side mode
                pagination
                onRowClick={handleEvent}
            />
        </div>
    );
};

export default Grid;
