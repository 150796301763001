import { Label } from '@components/Organisms/Dashboard/Components/Label';
import {
    Address,
    CrsFinancialInstitutionTypeEnum,
    FatcaEntityTaxInformation,
    crsFinancialInstitutionTypeLabel,
    exemptBeneficialOwnerTypesLabel,
    fatcaFinancialInstitutionTypeLabel,
    fatcaNonFinancialInstitutionTypeLabel,
    noGIINReasonTypeLabel,
    noGIINSponsorLabel,
} from 'common';

export enum ControllingPersonTypeEnum {
    legalPersonControlByOwnership = 'legalPersonControlByOwnership',
    legalPersonOtherMeans = 'legalPersonOtherMeans',
    legalPersonSeniorManagingOfficial = 'legalPersonSeniorManagingOfficial',
    trustSettlor = 'trustSettlor',
    trustTrustee = 'trustTrustee',
    trustProtector = 'trustProtector',
    trustBeneficiary = 'trustBeneficiary',
    trustOther = 'trustOther',
    legalArrangementSettlorEquivalent = 'legalArrangementSettlorEquivalent',
    legalArrangementTrusteeEquivalent = 'legalArrangementTrusteeEquivalent',
    legalArrangementProtectorEquivalent = 'legalArrangementProtectorEquivalent',
    legalArrangementBeneficiaryEquivalent = 'legalArrangementBeneficiaryEquivalent',
    legalArrangementOtherEquivalent = 'legalArrangementOtherEquivalent',
}

export const controllingPersonTypeLabel: {
    [key in ControllingPersonTypeEnum]: string;
} = {
    legalPersonControlByOwnership:
        'Controlling Person of a legal person - control by ownership',
    legalPersonOtherMeans: 'Controlling Person of a legal person - Other means',
    legalPersonSeniorManagingOfficial:
        'Controlling Person of a legal person - senior managing official',
    trustSettlor: 'Controlling Person of a trust - settlor',
    trustTrustee: 'Controlling Person of a trust - trustee',
    trustProtector: 'Controlling Person of a trust - protector',
    trustBeneficiary: 'Controlling Person of a trust - beneficiary',
    trustOther: 'Controlling Person of a trust - other',
    legalArrangementSettlorEquivalent:
        'Controlling Person of a legal arrangement (non-trust) - settlor-equivalent',
    legalArrangementTrusteeEquivalent:
        'Controlling Person of a legal arrangement (non-trust) - trustee-equivalent',
    legalArrangementProtectorEquivalent:
        'Controlling Person of a legal arrangement (non-trust) - protector-equivalent',
    legalArrangementBeneficiaryEquivalent:
        'Controlling Person of a legal arrangement (non-trust) - beneficiary-equivalent',
    legalArrangementOtherEquivalent:
        'Controlling Person of a legal arrangement (non-trust) - other-equivalent',
};

export const controllingPersonTypeOptions = Object.entries(
    controllingPersonTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export enum LegalEntityTypeEnum {
    isASpecifiedUSPerson = 'isASpecifiedUSPerson',
    isNotASpecifiedUSPerson = 'isNotASpecifiedUSPerson',
    isAUSPersonButNotSpecifiedUS = 'isAUSPersonButNotSpecifiedUS',
}

export const legalEntityTypeLabel: { [key in LegalEntityTypeEnum]: string } = {
    isASpecifiedUSPerson: 'The entity is a Specified U.S. Person',
    isNotASpecifiedUSPerson: 'The entity is not a specified U.S. Person',
    isAUSPersonButNotSpecifiedUS:
        'The Entity is a US person but not a Specified U.S',
};

export const legalEntityTypeLabelOptions = Object.entries(
    legalEntityTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const noGIINReasonTypeLabelOptions = Object.entries(
    noGIINReasonTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const fatcaFinancialInstitutionTypeLabelOptions = Object.entries(
    fatcaFinancialInstitutionTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const noGIINSponsorLabelOptions = Object.entries(
    noGIINSponsorLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const fatcaNonFinancialInstitutionTypeLabelOptions = Object.entries(
    fatcaNonFinancialInstitutionTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const exemptBeneficialOwnerTypesLabelOptions = Object.entries(
    exemptBeneficialOwnerTypesLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const crsFinancialInstitutionTypeLabelOptions = Object.entries(
    crsFinancialInstitutionTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export enum CrsNonFinancialInstitutionTypeEnum {
    activeNonFinancialEntityCorporation = 'activeNonFinancialEntityCorporation',
    activeNonFinancialEntityGovernmentEntityOrCentralBank = 'activeNonFinancialEntityGovernmentEntityOrCentralBank',
    activeNonFinancialEntityInternationOrganisation = 'activeNonFinancialEntityInternationOrganisation',
    activeNonFinancialEntityOther = 'activeNonFinancialEntityOther',
    passiveNonFinancialEntity = 'passiveNonFinancialEntity',
    notApplicable = 'notApplicable'
}

export const crsNonFinancialInstitutionTypeLabel: {
    [key in CrsNonFinancialInstitutionTypeEnum]: string;
} = {
    activeNonFinancialEntityCorporation:
        'Active Non-Financial Entity - a corporation the stock of which is regularly traded on an established securities market or a corporation which is a related entity of such a corporation',
    activeNonFinancialEntityGovernmentEntityOrCentralBank:
        'Active Non-Financial Entity - a Government Entity or Central Bank',
    activeNonFinancialEntityInternationOrganisation:
        'Active Non-Financial Entity - an International Organisation',
    activeNonFinancialEntityOther:
        'Active Non-Financial Entity - other than (I)-(III) (for example a start-up NFE or a non-profit NFE',
    passiveNonFinancialEntity:
        'Passive Non-Financial Entity (If this box is ticked, please complete a separate Individual Self-Certification Form for each of your Controlling Person(s) )',
    notApplicable: 'N/A'
};


export const crsActiveNonFinancialInstitutionTypeLabel = {
    activeNonFinancialEntityCorporation:
        'Active Non-Financial Entity - a corporation the stock of which is regularly traded on an established securities market or a corporation which is a related entity of such a corporation',
    activeNonFinancialEntityGovernmentEntityOrCentralBank:
        'Active Non-Financial Entity - a Government Entity or Central Bank',
    activeNonFinancialEntityInternationOrganisation:
        'Active Non-Financial Entity - an International Organisation',
    activeNonFinancialEntityOther:
        'Active Non-Financial Entity - other than (I)-(III) (for example a start-up NFE or a non-profit NFE',
};

export const crsActiveNonFinancialInstitutionTypeLabelOptions = Object.entries(
    crsActiveNonFinancialInstitutionTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export const crsNonFinancialInstitutionTypeLabelOptions = Object.entries(
    crsNonFinancialInstitutionTypeLabel
).map(([value, label]) => ({
    value,
    label,
}));

export interface TaxCountry {
    countryCode: string;
    taxId?: string;
    reasonForNotHavingTaxId: string;
}

export interface IndividualTaxInformation {
    taxCountries: TaxCountry[];
    nonUsPersonConfirmation: boolean;
}

export interface CrsEntityTaxInformation {
    isEntityNotATaxResident?: boolean;
    entityNotATaxResidentDescription?: string;

    financialInstitutionType?: CrsFinancialInstitutionTypeEnum;
    nonFinancialInstitutionType?: CrsNonFinancialInstitutionTypeEnum;
}

export interface ControllingPerson {
    fullName: string;
    townOfBirth: string;
    countryCodeOfBirth: string;
    dateOfBirth: Date;
    taxResidencies: TaxCountry[];
    controllingPersonType: ControllingPersonTypeEnum;
    address: Address,
}

export interface EntityTaxInformation {
    taxCountries: TaxCountry[];
    legalEntityType?: LegalEntityTypeEnum;
    fatca: FatcaEntityTaxInformation;
    crs: CrsEntityTaxInformation;
    controllingPersons: ControllingPerson[];
}

export interface TaxInformation {
    individual?: IndividualTaxInformation;
    entity?: EntityTaxInformation;
}
