import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import {
    FormCheckbox,
    FormInputBox,
    PhoneNumberBox,
} from '@components/Molecules';
import { Button } from '@components/Atoms';
import { ProspectiveInvestorsPost } from '@api/ProspectiveInvestors';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { Summary } from './Summary';
import CountryInformation from './CountryInformation';
import { useNavigate } from 'react-router-dom';
import CategoryOfInvestor from './CategoryOfInvestor';
import classNames from 'classnames';
import { ClientEnum, getInvestorCountryRequirementTree } from 'common';

interface RegistrationStep {
    stepLabel: string;
    component: React.FC<{
        onBack?: () => void;
        onSubmit?: (
            action: 'approve' | 'suitability-assessment' | 'review' | 'reject'
        ) => void;
    }>;
    values: any;
    validationSchema: (client: ClientEnum) => Yup.AnySchema;
    showButtons: boolean;
    nextButtonText?: string;
}

const staticRegistrationSteps: RegistrationStep[] = [
    {
        stepLabel: '1. Country Information',
        component: ({ onSubmit }) => {
            return <CountryInformation onSubmit={onSubmit} />;
        },
        values: {
            countryCodeOfResidence: '',
            investorType: '',
            investorTypeCheckbox: '',
        },
        validationSchema: (client: ClientEnum) =>
            Yup.object().shape({
                countryCodeOfResidence: Yup.string()
                    .required('Required')
                    .min(1, 'Required'),
                // investorType should only be required if the countryCodeOfResidence has not been selected as 'other'
                investorType: Yup.string().when('countryCodeOfResidence', {
                    is: (val: string) => {
                        // need to check if the investorCountryRequirementTree has a country where canPass is explicitly false
                        // if so, then we need to check if the countryCodeOfResidence is that country
                        const country = getInvestorCountryRequirementTree(
                            client
                        ).find((a) => a.countryCode === val);
                        return country?.canPass !== false;
                    },
                    then: Yup.string().trim().required('Required'),
                }),
                investorTypeCheckbox: Yup.boolean().when(
                    ['countryCodeOfResidence', 'investorType'],
                    {
                        is: (
                            countryCodeOfResidence: string,
                            investorType: string
                        ) => {
                            // check if there is a checkbox for the investorType.
                            // If so then the checkbox must be checked
                            const country = getInvestorCountryRequirementTree(
                                client
                            ).find(
                                (a) => a.countryCode === countryCodeOfResidence
                            );
                            const investorTypeObject =
                                country?.investorTypes?.find(
                                    (a) => a.value === investorType
                                );
                            if (country && investorTypeObject) {
                                return !!investorTypeObject?.checkbox;
                            }

                            return false;
                        },
                        then: Yup.boolean()
                            .required('Required')
                            .isTrue('Required'),
                    }
                ),
            }),
        showButtons: false,
    },
    {
        stepLabel: '2. Personal Details',
        component: () => {
            return (
                <>
                    <h2 className="mb-4 text-2xl">Personal Details</h2>
                    <FormInputBox
                        name="personalDetails.firstName"
                        label="First Name *"
                    />
                    <FormInputBox
                        name="personalDetails.surname"
                        label="Surname *"
                    />
                    <FormInputBox
                        name="personalDetails.email"
                        label="Email *"
                    />
                    <PhoneNumberBox
                        phoneDiallingCodeLabel="Country Code *"
                        phoneDiallingCodeName="personalDetails.phoneDiallingCode"
                        phoneNumberLabel="Phone Number *"
                        phoneNumberName="personalDetails.phoneNumber"
                    />
                    <FormCheckbox
                        name="personalDetails.investmentDisclaimer"
                        label="Privacy Policy *"
                        actionLabel={
                            <>
                                I have read and consented to the{' '}
                                <a
                                    href="https://hgcapital.com/legal-privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="underline"
                                >
                                    Privacy Policy
                                </a>
                            </>
                        }
                    />
                </>
            );
        },
        values: {
            'personalDetails.firstName': '',
            'personalDetails.surname': '',
            'personalDetails.email': '',
            'personalDetails.investmentDisclaimer': false,
        },
        validationSchema: () =>
            Yup.object().shape({
                personalDetails: Yup.object().shape({
                    firstName: Yup.string().trim().required('Required'),
                    surname: Yup.string().trim().required('Required'),
                    email: Yup.string()
                        .required('Required')
                        .email('Invalid email'),
                    phoneDiallingCode: Yup.string().trim().required('Required'),
                    phoneNumber: Yup.string().trim().required('Required'),
                    investmentDisclaimer: Yup.boolean()
                        .required('Required')
                        .isTrue('Required'),
                }),
            }),
        showButtons: true,
        nextButtonText: 'Next',
    },
    {
        stepLabel: '3. Category of Investor',
        component: ({ onBack, onSubmit }) => {
            return <CategoryOfInvestor onBack={onBack} onSubmit={onSubmit} />;
        },
        values: {},
        validationSchema: () => Yup.object().shape({}),
        showButtons: false,
    },
];

const RegistrationFlow: React.FC<{
    onSuccessfulSubmit: () => void;
    onRejectionSubmit: () => void;
}> = (props) => {
    const registrationSteps = staticRegistrationSteps;

    const [currentRegistrationStep, setCurrentRegistrationStep] =
        useState<RegistrationStep>(registrationSteps[0]);
    const [currentRegistrationStepIndex, setCurrentRegistrationStepIndex] =
        useState<number>(0);

    const [showSide, setShowSide] = useState<boolean>(false);

    const sideSteps = useMemo(() => {
        return registrationSteps.map((registrationStep, i) => {
            const isStepIndexLessThanCurrentStepIndex =
                i < currentRegistrationStepIndex;

            return {
                stepLabel: registrationStep.stepLabel,
                canEdit: isStepIndexLessThanCurrentStepIndex,
                isCompleted: isStepIndexLessThanCurrentStepIndex,
            };
        });
    }, [registrationSteps, currentRegistrationStepIndex]);

    const setCurrentStep = (stepName: string) => {
        const index = registrationSteps.findIndex(
            (registrationStep) => registrationStep.stepLabel === stepName
        );

        setCurrentRegistrationStep(registrationSteps[index]);
        setCurrentRegistrationStepIndex(index);

        if (index == 0) {
            setShowSide(false);
        } else {
            setShowSide(true);
        }
    };

    const handleBackStep = () => {
        setCurrentRegistrationStepIndex(currentRegistrationStepIndex - 1);
        setCurrentRegistrationStep(
            registrationSteps[currentRegistrationStepIndex - 1]
        );
        setShowSide(currentRegistrationStepIndex - 1 !== 0);
    };

    const handleSubmit = async (
        values: any,
        action?: 'approve' | 'suitability-assessment' | 'review' | 'reject'
    ) => {
        // save data from this step,
        // then move to next step
        // if last step, then submit
        if (action) {
            switch (action) {
                case 'suitability-assessment':
                case 'review':
                case 'approve': {
                    try {
                        await ProspectiveInvestorsPost(values);
                        notifySuccess('Registration successful');
                        props.onSuccessfulSubmit();
                    } catch (err) {
                        console.error('error');
                        console.error(err);
                        notifyError('Registration failed');
                    }
                    break;
                }
                case 'reject':
                    console.log('reject');
                    props.onRejectionSubmit();
                    break;
                default:
                    console.log('default');
                    break;
            }
        }

        if (
            !action &&
            currentRegistrationStepIndex < registrationSteps.length - 1
        ) {
            const newIndex = currentRegistrationStepIndex + 1;
            setCurrentRegistrationStepIndex(newIndex);
            setCurrentRegistrationStep(registrationSteps[newIndex]);
            setShowSide(newIndex !== 0);
        }
    };

    return (
        <>
            <div className="flex mt-3 justify-center">
                <div
                    className={classNames('flex-1', {
                        'max-w-xl': showSide,
                        'max-w-4xl': !showSide,
                    })}
                >
                    <Formik
                        initialValues={currentRegistrationStep.values}
                        enableReinitialize={false}
                        onSubmit={(value) => handleSubmit(value)}
                        validationSchema={
                            currentRegistrationStep.validationSchema
                        }
                    >
                        {({ values, isValid }) => (
                            <Form>
                                <currentRegistrationStep.component
                                    onBack={handleBackStep}
                                    onSubmit={(
                                        action:
                                            | 'reject'
                                            | 'suitability-assessment'
                                            | 'review'
                                            | 'approve'
                                    ) => {
                                        handleSubmit(values, action);
                                    }}
                                />
                                {currentRegistrationStep.showButtons && (
                                    <div className="flex flex-row-reverse justify-between pt-4 pb-6">
                                        <Button
                                            type="submit"
                                            label={
                                                currentRegistrationStep.nextButtonText
                                            }
                                            disabled={!isValid}
                                        />

                                        {/* Previous Step */}
                                        {currentRegistrationStepIndex > 0 && (
                                            <Button
                                                type="button"
                                                onClick={handleBackStep}
                                                label="Previous step"
                                                buttonType="secondary"
                                            />
                                        )}
                                        {currentRegistrationStepIndex === 0 && (
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    console.log('take me back');
                                                }}
                                                label="No, I am not eligible"
                                                buttonType="secondary"
                                            />
                                        )}
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>

                {showSide && (
                    <div className="flex-1 max-w-md ml-12 border p-6 shadow-md mb-4 self-start">
                        <Summary
                            currentStepName={currentRegistrationStep.stepLabel}
                            steps={sideSteps}
                            setCurrentStep={setCurrentStep}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default RegistrationFlow;
