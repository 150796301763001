import { FormInputBox, FormSelect } from '@components/Molecules';
import { RecursiveErrorDisplay } from '@containers/Subscriptions/RecursiveErrorDisplay';
import { isoCountriesOptions } from '@helpers/isoCountries';
import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';
import { t } from 'i18next';
import { FC } from 'react';

export const FormAddress: FC<{
    name: string;
    disabled: boolean;
    validationErrors?: any;
}> = ({ name, disabled = false, validationErrors }) => {
    return (
        <>
            <FormInputBox
                name={`${name}.houseName`}
                label={
                    getClientRegistrationFormFields(t).addresses.houseName.label
                }
                placeholder={t(
                    'client.registration.addresses.house_name_number.placeholder'
                )}
                disabled={disabled}
            />
            <FormInputBox
                name={`${name}.residentialLine1`}
                label={
                    getClientRegistrationFormFields(t).addresses
                        .residentialLine1.label
                }
                placeholder={t(
                    'client.registration.addresses.line1.placeholder'
                )}
                disabled={disabled}
            />
            <FormInputBox
                name={`${name}.residentialLine2`}
                label={
                    getClientRegistrationFormFields(t).addresses
                        .residentialLine2.label
                }
                placeholder={t(
                    'client.registration.addresses.line2.placeholder'
                )}
                disabled={disabled}
            />
            <FormInputBox
                name={`${name}.city`}
                label={getClientRegistrationFormFields(t).addresses.city.label}
                placeholder={t(
                    'client.registration.addresses.city.placeholder'
                )}
                disabled={disabled}
            />
            <FormInputBox
                name={`${name}.postCode`}
                label={
                    getClientRegistrationFormFields(t).addresses.postCode.label
                }
                placeholder={t(
                    'client.registration.addresses.postcode.placeholder'
                )}
                disabled={disabled}
            />
            <FormSelect
                name={`${name}.countryCode`}
                label={
                    getClientRegistrationFormFields(t).addresses.countryCode
                        .label
                }
                optionsData={isoCountriesOptions}
                placeholder={t(
                    'client.registration.addresses.country.placeholder'
                )}
                disabled={disabled}
            />
            {validationErrors && <RecursiveErrorDisplay errors={validationErrors} />}
        </>
    );
};
