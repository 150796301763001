import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AccountInfo,
    LogLevel,
} from '@azure/msal-browser';
import { ConfigurationResponse } from '@interfaces/Api';

type IEventMessage = EventMessage & {
    payload: {
        account?: AccountInfo;
        idToken?: any; //TOODO: fix this
    };
};

export let msalInstance: PublicClientApplication;

export const initMSAL = (configuration: ConfigurationResponse) => {
    // Set MSAL Configuration in local storage

    localStorage.setItem(
        'AUTH_SCOPES',
        JSON.stringify(configuration?.auth?.scopes)
    );

    /**
     * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
     */
    msalInstance = new PublicClientApplication({
        auth: {
            clientId: configuration.auth.clientId,
            authority: configuration.auth.authorities.signUpSignInSSO.name,
            knownAuthorities: [
                configuration.auth.authority,
                configuration.auth.authorities.signUpSignInSSO.name,
            ], // Mark your B2C tenant's domain as trusted.
            redirectUri: '/callback', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            // console.error(message);
                            return;
                        case LogLevel.Info:
                            // console.info(message);
                            return;
                        case LogLevel.Verbose:
                            // console.debug(message);
                            return;
                        case LogLevel.Warning:
                            // console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    });

    // Default to using the first account if no account is active on page load
    if (
        !msalInstance.getActiveAccount() &&
        msalInstance.getAllAccounts().length > 0
    ) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: IEventMessage) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload.account
        ) {
            msalInstance.setActiveAccount(event.payload.idToken);
        }
    });
};
