import {
    ControllingPerson,
    controllingPersonTypeOptions,
} from '@interfaces/Api';
import { FieldArray } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/Atoms';
import {
    FormDatePicker,
    FormInputBox,
    FormSelect,
    InlineFormFrame,
} from '@components/Molecules';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { FormAddress } from '@components/Organisms/FormAddress';
import { getTranslatedOptions } from './TaxDeclarations';
import { TaxCountriesFieldArray } from './TaxCountriesFieldArray';
import { RecursiveErrorDisplay } from './RecursiveErrorDisplay';

export const ControllingPersonFieldArray = ({
    formikProps,
    areInputsDisabled,
}) => {
    const [currentFormErrors, setCurrentFormErrors] = useState({});
    const { t } = useTranslation();
    return (
        <FieldArray
            name="entity.controllingPersons"
            render={(arrayHelpers) => {
                const controllingPerson: Partial<ControllingPerson> = {
                    fullName: '',
                    townOfBirth: '',
                    dateOfBirth: undefined,
                    countryCodeOfBirth: '',
                    controllingPersonType: undefined,
                    taxResidencies: [],
                    address: {
                        houseName: '',
                        residentialLine1: '',
                        residentialLine2: '',
                        city: '',
                        province: '',
                        postCode: '',
                        countryCode: '',
                    },
                };

                return (
                    <div>
                        {formikProps.values.entity?.controllingPersons?.map(
                            (_controllingPerson, i, _arr) => (
                                <InlineFormFrame
                                    key={i.toString()}
                                    onPrimaryAction={() => {
                                        // Trigger validation for this specific controlling person entry
                                        formikProps
                                            .validateForm()
                                            .then((errors) => {
                                                // Check for errors in the current controlling person's entry
                                                const currentErrors =
                                                    errors.entity
                                                        ?.controllingPersons?.[
                                                        i
                                                    ];
                                                if (
                                                    currentErrors &&
                                                    Object.keys(currentErrors)
                                                        .length > 0
                                                ) {
                                                    setCurrentFormErrors(
                                                        currentErrors
                                                    );
                                                }
                                            });
                                    }}
                                    secondaryAction={t('ui.controls.remove')}
                                    onSecondaryAction={() =>
                                        arrayHelpers.remove(i)
                                    }
                                >
                                    <FormInputBox
                                        name={`entity.controllingPersons[${i}].fullName`}
                                        label={
                                            t(
                                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.full_name'
                                            ) + ' *'
                                        }
                                        placeholder={t(
                                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.full_name'
                                        )}
                                        disabled={areInputsDisabled}
                                    />
                                    <FormInputBox
                                        name={`entity.controllingPersons[${i}].townOfBirth`}
                                        label={t(
                                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.birth_town'
                                        )+'*'}
                                        placeholder={t(
                                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.birth_town'
                                        )}
                                        disabled={areInputsDisabled}
                                    />

                                    <FormSelect
                                        name={`entity.controllingPersons.[${i}].countryCodeOfBirth`}
                                        label={
                                            t(
                                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.birth_country'
                                            ) + ' *'
                                        }
                                        optionsData={isoCountriesOptions}
                                        disabled={areInputsDisabled}
                                    />

                                    <FormDatePicker
                                        name={`entity.controllingPersons.[${i}].dateOfBirth`}
                                        label={
                                            t(
                                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.birth_date'
                                            ) + ' *'
                                        }
                                        maxDate={new Date()}
                                        disabled={areInputsDisabled}
                                    />
                                    <div>
                                        <h2>
                                            {t(
                                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.address.title'
                                            )}
                                        </h2>
                                        <FormAddress
                                            name={`entity.controllingPersons.[${i}].address`}
                                            disabled={areInputsDisabled}
                                        />
                                    </div>
                                    <TaxCountriesFieldArray
                                        fieldArrayName={`entity.controllingPersons.[${i}].taxResidencies`}
                                        validationErrors={
                                            formikProps.errors?.entity
                                                ?.controllingPersons?.[i]
                                                ?.taxResidencies
                                        }
                                        taxCountries={
                                            formikProps.values.entity
                                                ?.controllingPersons?.[i]
                                                ?.taxResidencies
                                        }
                                    />
                                    {formikProps.values.entity
                                        ?.controllingPersons?.[i]
                                        ?.taxResidencies.length === 0 && (
                                        <div className="text-sm text-red-500">
                                            <p>
                                                At least one tax residency must
                                                be specified
                                            </p>
                                        </div>
                                    )}
                                    <FormSelect
                                        name={`entity.controllingPersons.[${i}].controllingPersonType`}
                                        label={
                                            t(
                                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.type'
                                            ) + ' *'
                                        }
                                        optionsData={getTranslatedOptions(
                                            t,
                                            controllingPersonTypeOptions,
                                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.type.select_option'
                                        )}
                                        disabled={areInputsDisabled}
                                    />
                                </InlineFormFrame>
                            )
                        )}
                        {currentFormErrors && (
                            <RecursiveErrorDisplay errors={currentFormErrors} />
                        )}
                        {
                            <Button
                                label={t(
                                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.controlling_persons.add'
                                )}
                                type="button"
                                buttonType="secondary"
                                className="w-full my-2"
                                disabled={areInputsDisabled}
                                onClick={() => {
                                    arrayHelpers.push(controllingPerson);
                                }}
                            />
                        }
                    </div>
                );
            }}
        />
    );
};
