import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { AuthProvider } from '@contexts/AuthContext';
import { ConfigurationProvider } from '@contexts/ConfigurationContext';
import { StorageTokenProvider } from '@contexts/StorageTokenContext';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './Themes/index.css';
import './vp-bank.css';
import App from './App';
import { ModalWrapper } from '@components/Organisms';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigurationProvider>
                <Toaster position="bottom-center" reverseOrder={false} />
                <AuthProvider>
                    <StorageTokenProvider>
                        <App />
                        <ModalWrapper />
                    </StorageTokenProvider>
                </AuthProvider>
            </ConfigurationProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
