import { useEffect, useMemo, useState } from 'react';

import { docusignEnvelopeGet } from '@api/Docusign';
import {
    AuthPermissionEnum,
    SigningDocument,
    SigningDocumentStatusEnum,
    SigningUserStatusEnum,
} from '@interfaces/Api';
import {
    RedemptionApplicationSigningDocument,
    SigningMethodEnum,
} from '@interfaces/Api/SigningDocument';

import { Button, Icons } from '@components/Atoms';

import { useAuthState } from '@contexts/AuthContext';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { dateTimeFormatter } from '@helpers/Date.helper';
import { downloadFileOnTheClient } from '@helpers/File.helper';
import { getFullName } from '@helpers/common.helper';
import { notifyError } from '@helpers/toastrHelper';
import { notNull } from '@helpers/typeGuards';
import { getSystemUsersByIds } from '@stores/SystemUsers/SystemUsers.services';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';

interface DocumentSigningSummaryProps {
    showHeader?: boolean;
    signingDocuments?:
        | SigningDocument[]
        | RedemptionApplicationSigningDocument[];
    subscriptionId?: string;
}

// TODO: this component needs to be moved into a folder that makes sense
const DocumentSigningSummary: React.FC<DocumentSigningSummaryProps> = ({
    showHeader = true,
    signingDocuments,
    subscriptionId,
}) => {
    const { hasPermissions } = useAuthState();
    const { configuration } = useConfigurationState();
    const { t } = useTranslation();

    // Just figure out if both docs are in the same envelop or not
    // if both docs are in the same envelop then expectation is that we need to pass the index so docusign api can return correct doc from envelop
    // if multiple envelops then we dont really care about index!
    const envelopes = signingDocuments?.map(
        (signingDocument) => signingDocument.signingMethodId
    );
    const uniqueSigningMethodIds = new Set(envelopes);
    const hasDistinctEnvelopes = uniqueSigningMethodIds.size === 1;

    const [areDocumentsDownloading, setAreDocumentsDownloading] = useState<
        boolean[]
    >(new Array(signingDocuments?.length || 0).map(() => false));

    const setIsDocumentDownloading = (
        index: number,
        isDownloading: boolean
    ) => {
        const newAreDocumentsDownloading = [
            ...areDocumentsDownloading.slice(0, index),
            isDownloading,
            ...areDocumentsDownloading.slice(index + 1, 0),
        ];
        setAreDocumentsDownloading(newAreDocumentsDownloading);
    };

    const onSignedDocumentDownloadClick = async (
        index: number,
        documentIndex: number,
        signingDocument: SigningDocument | RedemptionApplicationSigningDocument
    ) => {
        if (areDocumentsDownloading[index]) return;

        switch (signingDocument.signingMethod) {
            case SigningMethodEnum.docusign:
                signingDocument.signingMethodId &&
                    handleDocumentClick(
                        index,
                        documentIndex,
                        signingDocument.signingMethodId
                    );
                break;
        }
    };

    const handleDocumentClick = async (
        index: number,
        documentIndex: number,
        envelopeId: string
    ) => {
        setIsDocumentDownloading(index, true);
        try {
            const response = await docusignEnvelopeGet(
                envelopeId,
                documentIndex ?? 0
            );
            downloadFileOnTheClient(response);
            setIsDocumentPackDownloading(false);
        } catch (err) {
            notifyError(
                'Could not download the document. Error:' + JSON.stringify(err)
            );
        }
        setIsDocumentDownloading(index, false);
    };

    const [documentInformationLines, setDocumentInformationLines] = useState(
        []
    );

    const loadSystemUsers = async (ids) => await getSystemUsersByIds(ids);

    useEffect(() => {
        const uniqueStatusLogUpdatedByIds = [
            ...new Set(
                signingDocuments
                    ?.map((signingDocument) =>
                        signingDocument.statusLogs?.map(
                            (statusLog) => statusLog
                        )
                    )
                    .flat(2)
                    .map((statusLog) => statusLog.updatedBy)
            ),
        ];

        if (!uniqueStatusLogUpdatedByIds.length) return;

        loadSystemUsers(uniqueStatusLogUpdatedByIds).then((users) => {
            const informationLines = signingDocuments?.map((signingDocument) =>
                signingDocument.statusLogs
                    ?.map((statusLog) => {
                        const updatedByUser = users.find(
                            (user) => user._id === statusLog.updatedBy
                        );

                        const messages = [];

                        if (
                            statusLog.status === SigningDocumentStatusEnum.draft
                        ) {
                            messages.push(
                                `${t(
                                    'subscription.review.document_signing.summary.messages.created_by'
                                )}: ${getFullName(
                                    updatedByUser?.firstName,
                                    updatedByUser?.surname
                                )}`
                            );
                            messages.push(
                                `${t(
                                    'subscription.review.document_signing.summary.messages.created_at'
                                )}: ${dateTimeFormatter(statusLog.createdAt)}`
                            );
                        } else if (
                            statusLog.status ===
                                SigningDocumentStatusEnum.sent ||
                            statusLog.status ===
                                SigningDocumentStatusEnum.signed
                        ) {
                            if (
                                statusLog.status ==
                                SigningDocumentStatusEnum.sent
                            ) {
                                messages.push(
                                    `${t(
                                        'subscription.review.document_signing.summary.messages.sent_by'
                                    )}: ${getFullName(
                                        updatedByUser?.firstName,
                                        updatedByUser?.surname
                                    )}`
                                );
                                messages.push(
                                    `${t(
                                        'subscription.review.document_signing.summary.messages.sent_at'
                                    )}: ${dateTimeFormatter(
                                        statusLog.createdAt
                                    )}`
                                );
                            } else if (
                                statusLog.status ==
                                SigningDocumentStatusEnum.signed
                            ) {
                                messages.push(
                                    `${t(
                                        'subscription.review.document_signing.summary.messages.completed_at'
                                    )}: ${dateTimeFormatter(
                                        statusLog.createdAt
                                    )}`
                                );
                            }

                            if (signingDocument.signingUsers?.length) {
                                signingDocument.signingUsers.map(
                                    (signingUser, index) => {
                                        // add lines for each update created by signer
                                        return signingUser.statusLogs
                                            ?.map((log) => {
                                                switch (log.status) {
                                                    case SigningUserStatusEnum.onHold:
                                                        if (
                                                            signingUser.status ==
                                                            SigningUserStatusEnum.onHold
                                                        )
                                                            messages.push(
                                                                hasDistinctEnvelopes &&
                                                                    index !== 0
                                                                    ? `${t(
                                                                          'subscription.review.document_signing.summary.messages.waiting_signing_group_confirmation'
                                                                      )} ${
                                                                          configuration.docuSignGroupName
                                                                      }`
                                                                    : `${t(
                                                                          'subscription.review.document_signing.summary.messages.waiting_confirmation'
                                                                      )} ${
                                                                          signingUser.name
                                                                      } ${
                                                                          (signingUser.email &&
                                                                              `(${signingUser.email})`) ||
                                                                          ''
                                                                      }`
                                                            );
                                                        break;
                                                    case SigningUserStatusEnum.sent:
                                                        messages.push(
                                                            hasDistinctEnvelopes &&
                                                                index !== 0
                                                                ? `${t(
                                                                      'subscription.review.document_signing.summary.messages.sent_to_signing_group'
                                                                  )} ${
                                                                      configuration.docuSignGroupName
                                                                  } ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                                : `${t(
                                                                      'subscription.review.document_signing.summary.messages.sent_to'
                                                                  )} ${
                                                                      signingUser.name
                                                                  } (${
                                                                      signingUser.email
                                                                  }) ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                        );
                                                        break;
                                                    case SigningUserStatusEnum.signed:
                                                        messages.push(
                                                            hasDistinctEnvelopes &&
                                                                index !== 0
                                                                ? `${t(
                                                                      'subscription.review.document_signing.summary.messages.signed_by_signing_group'
                                                                  )} ${
                                                                      configuration.docuSignGroupName
                                                                  } ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                                : `${t(
                                                                      'subscription.review.document_signing.summary.messages.signed_by'
                                                                  )} ${
                                                                      signingUser.name
                                                                  } (${
                                                                      signingUser.email
                                                                  }) ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                        );
                                                        break;
                                                    case SigningUserStatusEnum.declined:
                                                        messages.push(
                                                            hasDistinctEnvelopes &&
                                                                index !== 0
                                                                ? `${t(
                                                                      'subscription.review.document_signing.summary.messages.rejected_by_signing_group'
                                                                  )} ${
                                                                      configuration.docuSignGroupName
                                                                  } ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                                : `${t(
                                                                      'subscription.review.document_signing.summary.messages.rejected_by'
                                                                  )} ${
                                                                      signingUser.name
                                                                  } (${
                                                                      signingUser.email
                                                                  }) ${t(
                                                                      'subscription.review.document_signing.summary.messages.at'
                                                                  )} ${dateTimeFormatter(
                                                                      log.createdAt
                                                                  )}`
                                                        );
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            })
                                            .filter(notNull);
                                    }
                                );
                            }
                        }

                        return {
                            signingDocument,
                            status: statusLog.status,
                            messages,
                        };
                    })
                    .filter(notNull)
            );

            setDocumentInformationLines(informationLines.flat());
        });
    }, [signingDocuments, t]);

    const renderDocumentInformationLines = (
        signingDocument: SigningDocument | RedemptionApplicationSigningDocument,
        signingDocumentStatus: SigningDocumentStatusEnum
    ) => {
        if (!documentInformationLines) return;

        const informationLines = documentInformationLines
            .filter(
                (a) =>
                    isEqual(a.signingDocument, signingDocument) &&
                    a.status === signingDocumentStatus
            )
            .map((a) => a.messages)
            .flat(2);

        return (
            <>
                {informationLines?.map((a, i) => (
                    <p key={a + i} className="pl-4 text-xs">
                        {a}
                    </p>
                ))}
            </>
        );
    };

    const [isDocumentPackDownloading, setIsDocumentPackDownloading] =
        useState(false);

    if (!signingDocuments) {
        return <div></div>;
    }

    return (
        <div className="w-full">
            {hasPermissions(AuthPermissionEnum.getDocuSignEnvelope) &&
                signingDocuments &&
                signingDocuments.length > 0 && (
                    <>
                        {showHeader && (
                            <h3 className="py-3 text-xl font-normal">
                                {t(
                                    'subscription.review.document_signing.summary.title'
                                )}
                            </h3>
                        )}

                        {hasDistinctEnvelopes && (
                            <Button
                                label={t(
                                    'subscription.review.document_signing.summary.download_subscription_doc.button'
                                )}
                                onClick={() => {
                                    setIsDocumentPackDownloading(true);
                                    handleDocumentClick(
                                        0,
                                        0,
                                        signingDocuments.at(0).signingMethodId
                                    );
                                }}
                                endIcon={
                                    <Icons
                                        name={
                                            isDocumentPackDownloading
                                                ? 'Loading'
                                                : 'ArrowDownTrayIcon'
                                        }
                                    />
                                }
                                disabled={isDocumentPackDownloading}
                            />
                        )}

                        {signingDocuments?.map(
                            (
                                signingDocument:
                                    | SigningDocument
                                    | RedemptionApplicationSigningDocument,
                                index
                            ) => {
                                const documentIndex = hasDistinctEnvelopes
                                    ? signingDocuments.length - index - 1
                                    : 0;
                                return (
                                    <div
                                        className="mb-4"
                                        key={signingDocument.fileName + index}
                                    >
                                        <h3 className="text-lg">
                                            {signingDocument.fileName &&
                                                signingDocument.fileName.split(
                                                    '-'
                                                )[0]}
                                        </h3>

                                        {renderDocumentInformationLines(
                                            signingDocument,
                                            SigningDocumentStatusEnum.draft
                                        )}

                                        {signingDocument.status ==
                                            SigningDocumentStatusEnum.sent && (
                                            <>
                                                {!hasDistinctEnvelopes && (
                                                    <a
                                                        className="flex pt-2 cursor-pointer text-md"
                                                        onClick={() =>
                                                            !areDocumentsDownloading[
                                                                index
                                                            ] &&
                                                            signingDocument.signingMethodId &&
                                                            handleDocumentClick(
                                                                index,
                                                                documentIndex,
                                                                signingDocument.signingMethodId
                                                            )
                                                        }
                                                    >
                                                        <div className="pr-2">
                                                            <Icons
                                                                name={
                                                                    areDocumentsDownloading[
                                                                        index
                                                                    ]
                                                                        ? 'Loading'
                                                                        : 'ArrowDownTrayIcon'
                                                                }
                                                            />
                                                        </div>
                                                        {t(
                                                            'subscription.review.document_signing.summary.download_in_progress_document'
                                                        )}
                                                    </a>
                                                )}
                                                {renderDocumentInformationLines(
                                                    signingDocument,
                                                    SigningDocumentStatusEnum.sent
                                                )}
                                            </>
                                        )}

                                        {signingDocument.status ==
                                            SigningDocumentStatusEnum.signed && (
                                            <>
                                                {signingDocument.signingMethod ===
                                                    SigningMethodEnum.manual &&
                                                    signingDocument
                                                        .signedDocument
                                                        ?.url && (
                                                        <>
                                                            <DocumentLinkRender
                                                                className="flex pt-2 cursor-pointer text-md"
                                                                href={(
                                                                    signingDocument
                                                                        ?.signedDocument
                                                                        ?.url
                                                                )}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <div className="pr-2">
                                                                    <Icons
                                                                        name={
                                                                            areDocumentsDownloading[
                                                                                index
                                                                            ]
                                                                                ? 'Loading'
                                                                                : 'ArrowDownTrayIcon'
                                                                        }
                                                                    />
                                                                </div>
                                                                {t(
                                                                    'subscription.review.document_signing.summary.download_signed_document'
                                                                )}
                                                            </DocumentLinkRender>
                                                        </>
                                                    )}
                                                {signingDocument.signingMethod !==
                                                    SigningMethodEnum.manual && (
                                                    <>
                                                        <a
                                                            className="flex pt-2 cursor-pointer text-md"
                                                            onClick={() =>
                                                                onSignedDocumentDownloadClick(
                                                                    index,
                                                                    documentIndex,
                                                                    signingDocument
                                                                )
                                                            }
                                                        >
                                                            <div className="pr-2">
                                                                <Icons
                                                                    name={
                                                                        areDocumentsDownloading[
                                                                            index
                                                                        ]
                                                                            ? 'Loading'
                                                                            : 'ArrowDownTrayIcon'
                                                                    }
                                                                />
                                                            </div>
                                                            {t(
                                                                'subscription.review.document_signing.summary.download_signed_document'
                                                            )}
                                                        </a>
                                                    </>
                                                )}
                                                {renderDocumentInformationLines(
                                                    signingDocument,
                                                    SigningDocumentStatusEnum.signed
                                                )}
                                            </>
                                        )}

                                        <p className="my-8">
                                            {/* {JSON.stringify(signingDocument)} */}
                                        </p>
                                    </div>
                                );
                            }
                        )}
                        <div className="border-b"></div>
                    </>
                )}
        </div>
    );
};

export default DocumentSigningSummary;
