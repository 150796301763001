import { AuthRoleEnum, EndInvestorProfileResponse } from '@interfaces/Api';
import {
    endInvestorTypeLabelMap,
    getEndInvestorTypeLabels,
} from '@interfaces/Api/EndInvestorProfileResponse';
import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { TFunction } from 'react-i18next';

export const getEndInvestorTypesList = (
    t: TFunction<'translation', undefined>
): RadioOption<string>[] => {
    return Object.entries(endInvestorTypeLabelMap).map(
        ([enumValue, label]) => ({
            label: getEndInvestorTypeLabels(
                t,
                enumValue as EndInvestorProfileResponse.EndInvestorType
            ),
            value: enumValue,
        })
    );
};

export const getEndInvestorTypeLabelOptions = (
    t: TFunction<'translation', undefined>,
    isEnableEntityInvestors?: boolean,
    userRole?: AuthRoleEnum
) =>
    isEnableEntityInvestors
        ? getEndInvestorTypesList(t).filter((item) => {
              if (
                  userRole === AuthRoleEnum.relationshipManager &&
                  item.value ===
                      EndInvestorProfileResponse.EndInvestorType.nomineeEntity
              ) {
                  return false;
              }

              return true;
          })
        : [
              {
                  label: t('end_investor_type.individual'),
                  value: EndInvestorProfileResponse.EndInvestorType.individual,
              },
          ];
