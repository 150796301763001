import {
    FormCheckbox,
    FormRadioGroup,
    FormSelect,
} from '@components/Molecules';
import { useFormikContext } from 'formik';
import { Button } from '@components/Atoms';
import { useMemo, useState } from 'react';
import {
    InvestorCategory,
    InvestorCategoryEnumType,
    InvestorCategoryTypeLabels,
    WarningTextType,
    checkboxSubmissionType,
} from 'common';
import { InvestorType } from 'common';
import { openPopup } from '@helpers/popup.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useConfigurationState } from '@contexts/ConfigurationContext';

const getCheckboxOptionName = (investorCategory: InvestorCategory, index) => {
    return `${investorCategory.name}-checkboxes-option-${index}`;
};

const getRadioGroupName = (investorCategory: InvestorCategory) => {
    return `${investorCategory.name}-radio-group`;
};
interface CategoryOfInvestorProps {
    onBack?: () => void;
    onSubmit?: (
        action: 'approve' | 'suitability-assessment' | 'review' | 'reject'
    ) => void;
}

export const CheckboxConfirmation: React.FC<{
    checkboxSubmission: checkboxSubmissionType;
}> = ({ checkboxSubmission }) => {
    return (
        <FormCheckbox
            name="checkBoxConfirmation"
            actionLabel={
                <>
                    {checkboxSubmission.checkBox.checkBoxText}
                    &nbsp;
                    {checkboxSubmission.checkBox.warningText && (
                        <span>
                            (Please read the &nbsp;
                            <span
                                className="underline"
                                onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    openPopup(
                                        checkboxSubmission.checkBox.warningText,
                                        checkboxSubmission.checkBox
                                            .warningTextType
                                    );
                                }}
                            >
                                {checkboxSubmission.checkBox.warningLabel}
                            </span>
                            )
                        </span>
                    )}
                </>
            }
        />
    );
};

const DescriptionWithLink: React.FC<{
    text: string;
    warningLabel?: string;
    warningText?: string;
    warningTextType?: WarningTextType;
}> = ({ text, warningLabel, warningText, warningTextType }) => {
    return (
        <p className="pb-2 whitespace-pre-wrap">
            <span
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />{' '}
            {warningLabel && (
                <span
                    className="underline cursor-pointer"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        openPopup(warningText, warningTextType);
                    }}
                >
                    {warningLabel}
                </span>
            )}
        </p>
    );
};

const CategoryOfInvestor: React.FC<CategoryOfInvestorProps> = (props) => {
    const { values } = useFormikContext<{
        countryCodeOfResidence: string;
        investorType: string;
        investorCategory?: string;
        investorCategoryType?: string;
        subInvestorCategoryType?: string;
        checkBoxConfirmation?: boolean;
    }>();
    const { onBack, onSubmit } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { investorCountryRequirementTree } = useConfigurationState();

    const investorCategories = useMemo(
        () =>
            investorCountryRequirementTree
                .find((a) => a.countryCode == values.countryCodeOfResidence)
                // .find((a) => a.countryCode == 'CH')
                ?.investorCategories.filter((investorCategory) => {
                    if (investorCategory.availableForInvestorTypes) {
                        return investorCategory.availableForInvestorTypes.includes(
                            values.investorType as InvestorType
                        );
                    }

                    return true;
                }),
        [values.countryCodeOfResidence, values.investorType]
    );

    const investoryCategoryOptions = useMemo(() => {
        return investorCategories?.map((a) => ({
            label: toCapitalizedWords(InvestorCategoryTypeLabels[a.name]),
            value: a.name,
        }));
    }, [investorCategories]);

    const investorCategory = useMemo(() => {
        return investorCategories?.find(
            (a) => a.name == values.investorCategory
        );
    }, [investorCategories, values.investorCategory]);

    const investorCategoryTypesOptions = useMemo(() => {
        return investorCategory?.investorCategoryTypes?.map((a) => ({
            label: a.name,
            helpModalText: a.type == 'checkboxSubmission' && a.tooltip,
            value: a.value,
        }));
    }, [investorCategory?.investorCategoryTypes]);

    const investorCategoryType = useMemo(() => {
        return investorCategory?.investorCategoryTypes?.find(
            (a) => a.value == values.investorCategoryType
        );
    }, [investorCategory?.investorCategoryTypes, values.investorCategoryType]);

    const investorCategoryTypeDirectSubmission =
        investorCategoryType?.type == 'directSubmission' &&
        investorCategoryType;

    const investorCategoryTypeCheckboxSubmission =
        investorCategoryType?.type == 'checkboxSubmission' &&
        investorCategoryType;

    const investorCategoryTypeSubcategoriesSubmission =
        investorCategoryType?.type == 'subcategorySubmission' &&
        investorCategoryType;

    const investorCategoryTypeSubcategoriesCheckboxSubmissionOptions =
        useMemo(() => {
            return investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes?.map(
                (a) => ({
                    label: a.name,
                    value: a.value,
                })
            );
        }, [
            investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes,
        ]);

    const subsubSelection = useMemo(() => {
        return investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes?.find(
            (a) => a.value == values.subInvestorCategoryType
        );
    }, [
        investorCategoryTypeSubcategoriesSubmission?.subInvestorCategoryTypes,
        values.subInvestorCategoryType,
    ]);

    const handleSubmit = (
        action: 'approve' | 'suitability-assessment' | 'review' | 'reject'
    ) => {
        setIsSubmitting(true);
        onSubmit(action);
    };

    const shouldShowInvestorCategoryCheckboxes = useMemo(() => {
        if (investorCategory?.checkboxes) {
            if (
                investorCategory.checkboxes.availableForInvestorCategoryTypes
                    ?.length
            ) {
                if (
                    investorCategory.checkboxes.availableForInvestorCategoryTypes.includes(
                        values.investorCategoryType as InvestorCategoryEnumType
                    )
                ) {
                    return true;
                }

                return false;
            }

            return true;
        }

        return false;
    }, [investorCategory?.checkboxes, values.investorCategoryType]);

    const submitButton = useMemo(() => {
        let action: 'approve' | 'suitability-assessment' | 'review' | 'reject';
        let isDisabled = false;

        if (investorCategoryTypeDirectSubmission) {
            action = investorCategoryTypeDirectSubmission.action;
        } else if (investorCategoryTypeCheckboxSubmission) {
            action = investorCategoryTypeCheckboxSubmission.action;
            isDisabled = !values.checkBoxConfirmation;
        } else if (
            investorCategoryTypeSubcategoriesSubmission &&
            subsubSelection
        ) {
            action = subsubSelection.action;
            if (subsubSelection.type == 'directSubmission') {
                isDisabled = false;
            }
            if (subsubSelection.type == 'checkboxSubmission') {
                isDisabled = values.checkBoxConfirmation != true;
            }
        }

        if (shouldShowInvestorCategoryCheckboxes) {
            if (investorCategory.checkboxes.type === 'Radio') {
                if (!values[getRadioGroupName(investorCategory)]) {
                    isDisabled = true;
                }
            } else {
                investorCategory.checkboxes.options.forEach((option, index) => {
                    if (
                        option.tickRequired &&
                        !values[getCheckboxOptionName(investorCategory, index)]
                    ) {
                        isDisabled = true;
                    }
                });
            }
        }

        return {
            showSubmitButton: !!action,
            action,
            isDisabled,
        };
    }, [
        investorCategory,
        investorCategoryTypeCheckboxSubmission,
        investorCategoryTypeDirectSubmission,
        investorCategoryTypeSubcategoriesSubmission,
        shouldShowInvestorCategoryCheckboxes,
        subsubSelection,
        values,
    ]);

    return (
        <>
            <h2 className="mb-4 text-2xl">Category of Investor</h2>
            <FormSelect
                name="investorCategory"
                label="Investor Category *"
                optionsData={investoryCategoryOptions}
            />

            {investorCategory?.description && (
                <p className="pb-2 whitespace-pre-wrap">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: investorCategory?.description,
                        }}
                    />
                </p>
            )}

            {investorCategoryTypesOptions && (
                <FormRadioGroup
                    name="investorCategoryType"
                    label={
                        investorCategory?.description
                            ? ''
                            : 'Investor Category type *'
                    }
                    optionsData={investorCategoryTypesOptions}
                />
            )}

            {shouldShowInvestorCategoryCheckboxes && (
                <div>
                    <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                            __html: investorCategory.checkboxes.description,
                        }}
                    />
                    <div className="mt-2">
                        {investorCategory.checkboxes.type === 'Radio' ? (
                            <FormRadioGroup
                                name={getRadioGroupName(investorCategory)}
                                optionsData={investorCategory.checkboxes.options.map(
                                    (option) => ({
                                        label: option.checkBoxText,
                                        value: option.checkBoxText,
                                    })
                                )}
                            />
                        ) : (
                            investorCategory.checkboxes.options.map(
                                (option, index) => (
                                    <FormCheckbox
                                        name={getCheckboxOptionName(
                                            investorCategory,
                                            index
                                        )}
                                        actionLabel={option.checkBoxText}
                                        key={getCheckboxOptionName(
                                            investorCategory,
                                            index
                                        )}
                                    />
                                )
                            )
                        )}
                    </div>
                    {investorCategory.checkboxes?.footDescription && (
                        <div
                            className="mt-3"
                            dangerouslySetInnerHTML={{
                                __html: investorCategory.checkboxes
                                    .footDescription,
                            }}
                        />
                    )}
                </div>
            )}

            {investorCategoryTypeCheckboxSubmission && (
                <div className="py-4">
                    {investorCategoryTypeCheckboxSubmission.text && (
                        <DescriptionWithLink
                            text={investorCategoryTypeCheckboxSubmission.text}
                            warningLabel={
                                investorCategoryTypeCheckboxSubmission.warningLabel
                            }
                            warningText={
                                investorCategoryTypeCheckboxSubmission.warningText
                            }
                            warningTextType={
                                investorCategoryTypeCheckboxSubmission.warningTextType
                            }
                        />
                    )}
                    <CheckboxConfirmation
                        checkboxSubmission={
                            investorCategoryTypeCheckboxSubmission
                        }
                    />

                    {investorCategoryTypeCheckboxSubmission.footText && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: investorCategoryTypeCheckboxSubmission.footText,
                            }}
                        />
                    )}
                </div>
            )}

            {investorCategoryTypeSubcategoriesSubmission && (
                <div className="py-4">
                    <p className="pb-3 whitespace-pre-wrap">
                        {investorCategoryTypeSubcategoriesSubmission.text}
                    </p>
                    <FormRadioGroup
                        name="subInvestorCategoryType"
                        optionsData={
                            investorCategoryTypeSubcategoriesCheckboxSubmissionOptions
                        }
                    />
                    {subsubSelection && (
                        <div className="pt-4">
                            <p className="pb-6 whitespace-pre-wrap">
                                {subsubSelection.text}
                            </p>
                            {subsubSelection.type == 'checkboxSubmission' && (
                                <CheckboxConfirmation
                                    checkboxSubmission={subsubSelection}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}

            {investorCategory?.footWarningText && (
                <div
                    className="mb-2 mt-2 underline"
                    dangerouslySetInnerHTML={{
                        __html: investorCategory.footWarningText,
                    }}
                />
            )}

            <div className="flex justify-between py-4">
                <Button
                    type="button"
                    label="Back"
                    buttonType="secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        onBack();
                    }}
                />
                {submitButton.showSubmitButton && (
                    <Button
                        type="submit"
                        label="Submit"
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit(submitButton.action);
                        }}
                        isLoading={isSubmitting}
                        disabled={submitButton.isDisabled}
                    />
                )}
            </div>
        </>
    );
};

export default CategoryOfInvestor;
