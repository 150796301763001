import { Button } from '@components/Atoms';
import { ReviewFrame } from '@components/Molecules';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { dateTimeFormatter } from '@helpers/Date.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { getFullName } from '@helpers/common.helper';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { SubscriptionReviewProps } from '@interfaces/SubscriptionReview';
import { ModalService } from '@services/ModalService';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { getSystemUsersByIds } from '@stores/SystemUsers/SystemUsers.services';
import { useRmDetermination } from '@tenants/Pages/ODDO/Hooks/useRmDetermination.helper';
import { RejectionWarning } from '@tenants/Pages/ODDO/Subscriptions/RejectionWarning';
import classNames from 'classnames';
import {
    AuthPermissionEnum,
    CompletenessCheckType,
    CompletenessDecisionType,
    CompletenessDecisionTypeLabel,
} from 'common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const KYCAMLReview = ({
    onSubscriptionCompletenessCheck,
    showItem,
}: SubscriptionReviewProps) => {
    const { subscription } = useSubscription();
    const { hasPermissions, currentUser } = useAuthState();
    const { configuration } = useConfigurationState();
    const { t } = useTranslation();
    const [kycAmlComments, setKycAmlComments] = useState<string>('');
    const [isAcceptButtonLoading, setIsAcceptButtonLoading] =
        useState<boolean>(false);

    const { isOddoIFA } = useRmDetermination(
        configuration.client,
        currentUser.user
    );

    const [amlKycReviews, setAmlKycReviews] = useState([]);

    const loadSystemUsers = async (ids) => await getSystemUsersByIds(ids);

    useMemo(() => {
        const reviews =
            subscription?.completenessChecks?.statusLogs?.filter(
                (check) => check.type === CompletenessCheckType.reviewCompleted
            ) || [];

        const uniqueSystemUserIds = [
            ...new Set(reviews.map((review) => review.systemUserId)),
        ];

        if (!uniqueSystemUserIds.length) return;

        loadSystemUsers(uniqueSystemUserIds).then((users) => {
            const reviewWithSummary = reviews.map((review) => {
                const user = users.find(
                    (user) => user._id === review.systemUserId
                );

                return {
                    ...review,
                    summary: [
                        toCapitalizedWords(
                            String(
                                CompletenessDecisionTypeLabel[review.decision]
                            )
                        ),
                        `- ${t('ui.labels.by')}`,
                        getFullName(user?.firstName, user?.surname),
                        t('ui.labels.at'),
                        dateTimeFormatter(review.createdAt),
                        '\n',
                    ]
                        .filter((a) => a)
                        .join(' '),
                };
            });

            setAmlKycReviews(reviewWithSummary);
        });
    }, [subscription]);

    if (
        subscription?.completenessChecks?.status ===
            CompletenessCheckType.requestMoreInformation &&
        amlKycReviews.length < 1
    ) {
        return null;
    }

    return (
        <div
            className={classNames({
                hidden: !showItem,
            })}
        >
            <h4 className="mt-6 mb-2 text-lg font-bold">
                {t('subscriptions.completeness_checks.aml_kyc.title')}
            </h4>

            {amlKycReviews.map((amlKycReview, index) => (
                <div
                    className="pb-4 mb-2 border-b"
                    key={`${amlKycReview.systemUserId}-${index}`}
                >
                    <ReviewFrame
                        hideToggleButton
                        title=""
                        summary={amlKycReview.summary}
                        summaryClassName="whitespace-pre-line"
                        expanded
                    />
                    {amlKycReview?.comments && (
                        <div className="text-sm">
                            <p>
                                {t(
                                    'subscriptions.completeness_checks.comments'
                                )}
                                :
                            </p>
                            <p>{amlKycReview?.comments}</p>
                        </div>
                    )}
                </div>
            ))}

            {!amlKycReviews.length ||
            (subscription?.completenessChecks.status ===
                CompletenessCheckType.complianceAmlKyc &&
                subscription.status !== SubscriptionStatusEnum.approved &&
                subscription.status !== SubscriptionStatusEnum.rejected &&
                hasPermissions(
                    AuthPermissionEnum.approveSubscriptionForCompliance
                )) ? (
                <div className="pb-8 border-b">
                    <p className="pt-4 pb-2">
                        {t('subscriptions.completeness_checks.aml_kyc.intro')}.
                    </p>
                    <p>
                        {' '}
                        {t(
                            'subscriptions.completeness_checks.comments_paragraph'
                        )}
                    </p>
                    <textarea
                        onChange={(comment) => {
                            setKycAmlComments(comment.target.value);
                        }}
                        className={`w-full h-auto pt-8 pl-3 pr-3 placeholder-gray-300 border rounded-lg focus:shadow-outline`}
                    />
                    <div className="flex flex-row justify-between mt-4">
                        <Button
                            type="button"
                            label={t('ui.controls.reject')}
                            buttonType="secondary"
                            className="mr-2"
                            onClick={() => {
                                setIsAcceptButtonLoading(true);
                                ModalService.getInstance()
                                    .setTitle(
                                        t(
                                            'subscriptions.completeness_checks.internal_review.reject_modal_title'
                                        )
                                    )
                                    .setComponent(
                                        <RejectionWarning
                                            onAbort={() => {
                                                ModalService.getInstance().hideModal();
                                            }}
                                            onAccept={async () => {
                                                await onSubscriptionCompletenessCheck(
                                                    {
                                                        type: CompletenessCheckType.rejection,
                                                        decision:
                                                            CompletenessDecisionType.rejected,
                                                        comments:
                                                            kycAmlComments,
                                                    }
                                                )
                                                    .then(() =>
                                                        setIsAcceptButtonLoading(
                                                            false
                                                        )
                                                    )
                                                    .catch(() =>
                                                        setIsAcceptButtonLoading(
                                                            false
                                                        )
                                                    );
                                                ModalService.getInstance().hideModal();
                                            }}
                                        />
                                    )
                                    .showModal();
                            }}
                        />
                        <div>
                            {hasPermissions([
                                AuthPermissionEnum.updateSubscriptionStatusFromReadyForReviewToInProgress,
                            ]) && (
                                <Button
                                    type="button"
                                    label={t(
                                        'subscriptions.completeness_checks.request_more_info'
                                    )}
                                    buttonType="primary"
                                    className={classNames('mr-2', {
                                        hidden: isOddoIFA,
                                    })}
                                    onClick={() => {
                                        setIsAcceptButtonLoading(true);
                                        onSubscriptionCompletenessCheck({
                                            type: subscription
                                                ?.completenessChecks?.status,
                                            decision:
                                                CompletenessDecisionType.requestMoreInformation,
                                            comments: kycAmlComments,
                                        })
                                            .then(() => {
                                                setIsAcceptButtonLoading(false);
                                                window.location.reload();
                                            })
                                            .catch(() =>
                                                setIsAcceptButtonLoading(false)
                                            );
                                    }}
                                />
                            )}

                            <Button
                                type="button"
                                label={t('ui.controls.accept')}
                                buttonType="primary"
                                onClick={() => {
                                    setIsAcceptButtonLoading(true);
                                    onSubscriptionCompletenessCheck({
                                        type: CompletenessCheckType.reviewCompleted, //TODO: Change this check to AMLKYC
                                        decision:
                                            CompletenessDecisionType.approved,
                                        comments: kycAmlComments,
                                    })
                                        .then(() => {
                                            setIsAcceptButtonLoading(false);
                                            window.location.reload();
                                        })
                                        .catch(() =>
                                            setIsAcceptButtonLoading(false)
                                        );
                                }}
                                // endIcon={
                                //     isAcceptButtonLoading && (
                                //         <Icons name="Loading" />
                                //     )
                                // }
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default KYCAMLReview;
