import { Button } from '@components/Atoms';
import { AlertBox } from '@components/Molecules';
import { handleLoginAccountAssignment } from '@helpers/EndInvestorHelper';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { useHasEndInvestorLoginAccount } from '@hooks/useHasEndInvestorLoginAccount';
import { useTranslation } from 'react-i18next';

interface CreateLoginAccountToEndInvestorProps {
    endInvestorProfileId: string;
    getEndInvestorProfilesWithoutLoginAccount?: () => void;
    wrapInAlertBox?: boolean;
}

export const CreateLoginAccountToEndInvestor: React.FC<
    CreateLoginAccountToEndInvestorProps
> = ({
    endInvestorProfileId,
    getEndInvestorProfilesWithoutLoginAccount,
    wrapInAlertBox = true,
}) => {
    const { t } = useTranslation();
    const { hasEndInvestorLoginAccount } = useHasEndInvestorLoginAccount({
        endInvestorProfileId,
    });

    if (!hasEndInvestorLoginAccount) return null;

    const handleOnClick = async () => {
        try {
            await handleLoginAccountAssignment({
                endInvestorProfileId,
            });
        } catch (e) {
            notifyError(errorToString(e));
        } finally {
            setTimeout(async () => {
                if (getEndInvestorProfilesWithoutLoginAccount) {
                    await getEndInvestorProfilesWithoutLoginAccount();
                }
            }, 500);
        }
    };

    return wrapInAlertBox ? (
        <AlertBox
            key="login-account-creation"
            alertType={'info'}
            title={'Login Account Creation'}
            message={
                <div className="flex flex-row-reverse">
                    <Button
                        label={t('client.actions.create_login_account')}
                        onClick={handleOnClick}
                    />
                </div>
            }
        />
    ) : (
        <Button
            label={t('client.actions.create_login_account')}
            onClick={handleOnClick}
        />
    );
};
