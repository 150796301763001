import React, { useState } from 'react';
import './ToggleSwitch.css';

interface ToggleProps {
    title?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ToggleSwitch = (props: ToggleProps) => {
    const { checked, disabled, onChange, title } = props;
    const [isChecked, setIsChecked] = useState(checked || false);

    return (
        <div>
            <label
                onClick={() => {
                    setIsChecked(!(checked || isChecked));
                }}
                htmlFor="toggle-btn-checked"
                className="flex items-center cursor-pointer relative"
            >
                <input
                    type="checkbox"
                    id="toggle-btn-checked"
                    name="toggle-btn-checked"
                    className="sr-only"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                />
                <div className="toggle-bg" />
                <span className="ml-3 text-gray-400 text-sm font-medium">
                    {title}
                </span>
            </label>
        </div>
    );
};

export default ToggleSwitch;
