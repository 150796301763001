import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import {
    authenticationCurrentUserGet,
    authenticationUpdateLastLoginDate,
} from '@api/Authentication';
import { getSystemUserRoleActions } from '@api/SystemUserRoleActions';
import {
    EventType,
    InteractionStatus,
    RedirectRequest,
} from '@azure/msal-browser';

import { Button, Loading } from '@components/Atoms';
import { compareIssuingPolicy } from '@libs/msal/claimUtils';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { Nda } from '@pages/Password/Nda';
import { ModalService } from '@services/ModalService';
import { agreeToNDAPUT } from '@stores/SystemUsers/SystemUsers.services';
import { AuthRoleEnum, ClientEnum } from 'common';
import { t } from 'i18next';

const MSALCallback = () => {
    const { configuration, refreshConfiguration, isClient } =
        useConfigurationState();

    const loginRequest = {
        scopes: configuration?.auth?.scopes,
        ...(localStorage.getItem('AUTH_AUTHORITY') && {
            authority: localStorage.getItem('AUTH_AUTHORITY'),
        }),
    };

    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const {
        isLoggedIn,
        setCurrentUser,
        setCurrentSystemUserRolesActions,
        getCurrentUserData,
    } = useAuthState();
    const navigate = useNavigate();
    const location = useLocation();
    const [loadingConfig, setLoadingConfig] = useState<boolean>(true);

    const { auth } = configuration;

    const peristedURL = JSON.parse(sessionStorage.getItem('PERSISTED_URL'));

    const defaultnavigateRoute = peristedURL
        ? peristedURL
        : configuration?.preferences?.feature?.authentication
              ?.enableProductsPageWithoutLoggingIn
        ? '/products'
        : '/clients';

    const mfaSignUpSignInFlowRequest = {
        authority: auth.authorities.signUpSignInMFA.name,
    } as RedirectRequest;

    const signUpFlowRequestURL = {
        authority: auth.authorities.signUp.name,
    } as RedirectRequest;

    const acquireTokenAndConfig = async () => {
        try {
            setLoadingConfig(true);
            refreshConfiguration()
                .then(async () => {
                    const currentUser = await authenticationCurrentUserGet();
                    if (!currentUser) {
                        await instance.logout({
                            account: activeAccount,
                        });
                        return navigate('/user-not-found');
                    }

                    const initializeUser = async () => {
                        setCurrentUser(currentUser);

                        // Update last login date
                        await authenticationUpdateLastLoginDate();

                        const userRoleActions =
                            await getSystemUserRoleActions();
                        const navigateUrl =
                            currentUser.user.role === AuthRoleEnum.endInvestor
                                ? peristedURL
                                    ? peristedURL
                                    : '/portfolio'
                                : defaultnavigateRoute;
                        setCurrentSystemUserRolesActions(userRoleActions);
                        localStorage.setItem('AUTH_FLOW', 'LOGIN_SUCCESS');
                        sessionStorage.removeItem('PERSISTED_URL');
                        return navigate(navigateUrl, {
                            replace: true,
                        });
                    };

                    if (
                        !currentUser.user.agreeToNDA &&
                        !isClient(ClientEnum.HG)
                    ) {
                        ModalService.getInstance()
                            .setTitle(t('non_disclosure_agreement.title'))
                            .setShowHeader(true)
                            .setShowFooter(true)
                            .setHideCancelBtn(true)
                            .setComponent(<Nda />)
                            .setConfirmCallback(() => {
                                agreeToNDAPUT(currentUser.user._id)
                                    .then(async () => {
                                        await getCurrentUserData();
                                        ModalService.getInstance().hideModal();
                                        initializeUser();
                                    })
                                    .catch((e) => {
                                        notifyError(errorToString(e));
                                    });
                            })
                            .setBodyClassName('modal-full-height')
                            .setConfirmationLabel(
                                t('non_disclosure_agreement.accept_button')
                            )
                            .showModal();
                    } else {
                        await initializeUser();
                    }
                })
                .catch(async (err) => {
                    console.log(
                        'MSAL:ERROR[acquireTokenAndConfig.refreshConfiguration]: ',
                        err
                    );
                    await instance.logout({
                        account: activeAccount,
                    });
                    return navigate('/user-not-found');
                });
        } catch (e) {
            console.log('MSAL:ERROR[acquireTokenAndConfig]: ', e);
        }
    };

    const signUpFlowRequest = async () => {
        localStorage.setItem('AUTH_FLOW', 'SIGN_UP');
        localStorage.setItem(
            'AUTH_AUTHORITY',
            configuration.auth.authorities.signUpSignInMFA.name
        );
        await instance
            .loginRedirect(signUpFlowRequestURL)
            .catch((error) =>
                console.log('MSAL:ERROR[signUpFlowRequest]: ', error)
            );
        return;
    };

    useEffect(() => {
        // If the user is already logged in, redirect to the default route
        if (isLoggedIn) {
            localStorage.setItem('AUTH_FLOW', 'LOGIN_SUCCESS');
            sessionStorage.removeItem('PERSISTED_URL');
            navigate(defaultnavigateRoute, {
                replace: true,
            });
            return;
        }

        (async function () {
            // If MSAL is in starting up, do not do anything
            if (
                inProgress === InteractionStatus.Startup ||
                inProgress === InteractionStatus.HandleRedirect
            ) {
                return;
            }
            const authFlow = localStorage.getItem('AUTH_FLOW');

            if (isAuthenticated && authFlow !== 'LOGIN_ATTEMPT') {
                // Acquire an access token if the user is already authenticated when landing on the callback page
                instance
                    .acquireTokenSilent({
                        account: activeAccount,
                        ...loginRequest,
                    })
                    .then(async () => {
                        return await acquireTokenAndConfig();
                    })
                    .catch(async (e) => {
                        // Catch interaction_required errors and call interactive method to resolve
                        if (inProgress === InteractionStatus.None) {
                            await instance
                                .logout({
                                    account: activeAccount,
                                })
                                .then(() => {
                                    return navigate('/');
                                })
                                .catch((e) => {
                                    console.log(
                                        'MSAL:ERROR[acquireTokenSilent.logout.catch]:',
                                        e
                                    );
                                });
                        }
                    });
            }

            switch (location.pathname) {
                case '/callback/sign-up':
                    if (inProgress === InteractionStatus.None) {
                        await signUpFlowRequest();
                    }
                    break;
                case '/callback/sso':
                    await acquireTokenAndConfig();
                    break;
                case '/callback':
                    if (
                        !isAuthenticated &&
                        inProgress === InteractionStatus.None
                    ) {
                        await instance
                            .logout({
                                account: activeAccount,
                            })
                            .then(() => {
                                return navigate('/');
                            })
                            .catch((e) => {
                                console.log(
                                    'MSAL:ERROR[/callback.logout.catch]:',
                                    e
                                );
                                return navigate('/');
                            });
                    }
                    break;
                default:
                    break;
            }
        })();
    }, [inProgress]);

    // We shouldn't have to use this useEffect, so we need to refactor the code to remove it
    useEffect(() => {
        try {
            const callbackId = instance.addEventCallback(async (event) => {
                setLoadingConfig(true);
                const authFlow = localStorage.getItem('AUTH_FLOW');

                if (
                    (event.eventType === EventType.LOGIN_SUCCESS ||
                        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                    event.payload.account &&
                    event?.payload?.idTokenClaims
                ) {
                    const isMFAPolicy = compareIssuingPolicy(
                        event.payload.idTokenClaims,
                        auth.authorities.signUpSignInMFA.policyName
                    );
                    const isSSOPolicy = compareIssuingPolicy(
                        event.payload.idTokenClaims,
                        auth.authorities.signUpSignInSSO.policyName
                    );
                    /**
                     * Below we are checking if the user is returning from the reset password flow.
                     * If so, we will ask the user to reauthenticate with their new password.
                     * If you do not want this behavior and prefer your users to stay signed in instead,
                     * you can replace the code below with the same pattern used for handling the return from
                     * profile edit flow
                     */
                    if (
                        compareIssuingPolicy(
                            event.payload.idTokenClaims,
                            auth.authorities.passwordReset.name
                        )
                    ) {
                        await instance.loginRedirect(
                            mfaSignUpSignInFlowRequest
                        );
                    }
                    if (
                        (isMFAPolicy || isSSOPolicy) &&
                        authFlow !== 'LOGIN_ATTEMPT' &&
                        authFlow !== 'LOGIN_SUCCESS'
                    ) {
                        localStorage.setItem('AUTH_FLOW', 'LOGIN_ATTEMPT');
                        await acquireTokenAndConfig();
                    }
                } else if (event.eventType === EventType.LOGIN_FAILURE) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                    if (
                        event?.error &&
                        event?.error?.errorMessage.includes('AADB2C90118')
                    ) {
                        const resetPasswordRequest = {
                            authority: auth.authorities.passwordReset.name,
                            scopes: [],
                        };
                        await instance.loginRedirect(resetPasswordRequest);
                    }
                    if (
                        event?.error &&
                        event?.error?.errorMessage.includes('AADB2C90091')
                    ) {
                        // User has  cancelled the request
                        await instance.loginRedirect(
                            mfaSignUpSignInFlowRequest
                        );
                    }
                }
            });
            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId);
                }
            };
        } catch (e) {
            console.log('MSAL:ERROR[useEffect.addEventCallback]: ', e);
        }
    }, [instance]);

    return (
        <div className="flex items-center justify-center h-full min-h-screen bg-fixed bg-white bg-bottom bg-cover">
            <div className="container">
                <div className="row">
                    <div className="-mt-64 text-center col-sm-8 offset-sm-2 text-gray-50">
                        <div className="relative ">
                            {loadingConfig && <Loading size="large" />}
                            {!loadingConfig &&
                                !isAuthenticated &&
                                !isLoggedIn &&
                                !activeAccount && (
                                    <>
                                        <p className="mt-2 mb-6 text-blue-900">
                                            You have not been granted access to
                                            this portal. Please contact your
                                            administrator to request access.
                                        </p>
                                        <Link to="/">
                                            <Button label="Return Home" />
                                        </Link>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MSALCallback;
