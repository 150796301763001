import React, { useState, useEffect } from 'react';
import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';
import BookingCentreListGrid from '@pages/Admin/BookingCentres/BookingCentreListGrid';
import { Pagination } from '@components/Organisms';
import { useSortableData } from '@hooks/useSortableData/useSortableData';
import { useAuthState } from '@contexts/AuthContext';
import { IntermediariesSelect } from '@pages/Admin/BookingCentres/IntermediariesSelect';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { AuthRoleEnum } from '@interfaces/Api';
import { useTranslation } from 'react-i18next';

const ListBookingCentres: React.FC = () => {
    const { t } = useTranslation();
    const { currentUser, hasRoles } = useAuthState();
    const { intermediaries } = useIntermediaries();
    const preSelectedIntermediary = hasRoles([AuthRoleEnum.administrator])
        ? currentUser?.user?.intermediaryId
        : intermediaries.length === 1
        ? intermediaries[0]?._id
        : undefined;
    const [selectedIntermediaryId, selectIntermediaryId] = useState<
        string | undefined
    >(preSelectedIntermediary);
    const [BookingCentresPerPage, setBookingCentresPerPage] = useState<
        number | string
    >(50);

    const { bookingCentres, deleteBookingCentre } = useBookingCentres(
        selectedIntermediaryId
    );

    useEffect(() => {
        if (
            preSelectedIntermediary &&
            preSelectedIntermediary !== selectedIntermediaryId
        ) {
            selectIntermediaryId(preSelectedIntermediary);
        }
    }, [preSelectedIntermediary, selectedIntermediaryId]);

    const {
        tableData: currentBookingCentres,
        tableDataLength,
        setCurrentPageNumber: setCurrentPage,
        currentPageNumber: currentPage,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData: bookingCentres,
        searchText: '',
        itemsPerPage: BookingCentresPerPage,
    });

    return (
        <div className="xs:container xs:mx-auto max-w-full flex flex-col items-stretch h-full pb-9">
            <div className="flex justify-between w-full mb-5">
                <h1 className="text-3xl text-logo-blue font-bold align-middle w-3/5 lg:w-4/5">
                    {t('navbar.admin.booking_centre_management')}
                </h1>
            </div>
            {hasRoles([
                AuthRoleEnum.superUser,
                AuthRoleEnum.assetManagerAdministrator,
            ]) &&
                intermediaries.length > 1 && (
                    <>
                        <div className="flex justify-between w-full mb-5">
                            <p>
                                {t(
                                    'client.registration.intermediary.required_validation_message'
                                )}
                                :
                            </p>
                        </div>
                        <div className="flex justify-between w-full mb-5">
                            <IntermediariesSelect
                                intermediaries={intermediaries}
                                selectIntermediaryId={selectIntermediaryId}
                            />
                        </div>
                    </>
                )}

            {selectedIntermediaryId ? (
                <>
                    <BookingCentreListGrid
                        items={currentBookingCentres}
                        selectedIntermediaryId={selectedIntermediaryId}
                        deleteBookingCentre={deleteBookingCentre}
                    />
                    <Pagination
                        rowsPerPage={BookingCentresPerPage}
                        itemsLength={tableDataLength}
                        paginate={paginate}
                        currentPage={currentPage}
                        paginateBack={paginateBack}
                        paginateFront={paginateFront}
                        className="p-2"
                        onPageSizeChange={setBookingCentresPerPage}
                    />
                </>
            ) : null}
        </div>
    );
};

export default ListBookingCentres;
