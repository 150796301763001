import { FC, useState } from 'react';
import { Button, Checkbox } from '@components/Atoms';
import { useTranslation } from 'react-i18next';

interface CoolingOffWarningProps {
    onAbort(): void;
    onAccept(): void;
}

export const CoolingOffWarning: FC<CoolingOffWarningProps> = ({
    onAbort,
    onAccept,
}) => {
    const { t } = useTranslation();
    const [acceptRisk, setAcceptRisk] = useState<boolean>(false);

    return (
        <>
            <div>
                <Checkbox
                    label={t('client.cooling_off_warning.acceptance')}
                    name="acceptRisk"
                    checked={acceptRisk}
                    onChange={(e) => {
                        setAcceptRisk(e.target.checked);
                    }}
                />
            </div>

            <div className="mt-8 flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={onAbort}
                    label={t('client.cooling_off_warning.abort')}
                />

                <Button
                    label={t('client.cooling_off_warning.execute')}
                    disabled={!acceptRisk}
                    onClick={onAccept}
                />
            </div>
        </>
    );
};
