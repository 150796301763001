import { UploadDocument } from '@components/Organisms/FileUploadAsync/FileUploadAsync';

export interface DocumentTemplateUploadResponse {
    url: string;
    fileName: string;
}

export enum DocumentTemplateTypeEnum {
    AmlKyc = 'AML KYC',
    Subscription = 'Subscription',
    GermanSemiProfessionalQuestiqonnaire = 'German Semi Professional Questionnaire',
    NomineeIndemnitySubscriptionAgreement = 'Nominee Indemnity Subscription Agreement',
    SubscriptionAdditionalSharesRequest = 'Subscription Additional Shares Request',
    NomineeIndemnitySubscriptionAdditionalSharesRequest = 'Nominee Indemnity Subscription Additional Shares Request',
    RedemptionAgreement = 'Redemption Agreement',
    NomineeIndemnityRedemptionAgreement = 'Nominee Indemnity Redemption Agreement',
    AccountOpeningForm = 'Account Opening Form'   
}

export interface DocumentTemplateOption {
    value: DocumentTemplateTypeEnum;
    label: DocumentTemplateTypeEnum;
    __isNew__?: boolean;
}

export type FormDocumentTemplateUpload = DocumentTemplateRow & {
    documents?: UploadDocument[];
    file?: File;
};

export interface DocumentTemplate {
    _id?: string;
    fileName: string;
    documentType: DocumentTemplateTypeEnum | undefined;
    url: string;
    createdBy?: string;
    createdDate?: Date;
    updatedBy?: string;
    updatedDate?: Date;
}

export interface DocumentTemplateRow {
    _id?: string;
    fileName: string;
    documentType: DocumentTemplateTypeEnum | undefined;
    url: string;
    createdBy?: string;
    createdDate?: string;
    updatedBy?: string;
    updatedDate?: string;
}
