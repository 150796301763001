import React, { useEffect, useState } from 'react';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import SelectEndInvestor from './SelectEndInvestor';
import {
    EndInvestorProfileResponse,
    ProductResponse,
    SubscriptionResponse,
    SubscriptionStatusEnum,
} from '@interfaces/Api';
import { productsGetById } from '@api/Products';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { ImgText } from '@components/Molecules';
import { Button, Loading, Tooltip } from '@components/Atoms';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import {
    inducementFeeApplies,
    placementFeeAppliesToSubscription,
} from '@services/SubscriptionsHelper';
import { SubscriptionStatusBadge } from '@components/Organisms';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { useTranslation } from 'react-i18next';

interface SubscriptionWithProduct {
    subscription: SubscriptionResponse;
    product?: ProductResponse;
}

const IndecementFeeCalculator: React.FC = () => {
    const { t } = useTranslation();
    const { endInvestorProfiles, loadingEndInvestorProfiles } =
        useEndInvestorProfileState();
    const [loadingSubscriptionProducts, setLoadingSubscriptionProducts] =
        useState<Boolean>(false);
    const [selectedEndInvestor, setSelectedEndInvestor] =
        useState<EndInvestorProfileResponse>();
    const [endInvestorSubscriptions, setEndInvestorSubscriptions] =
        useState<SubscriptionWithProduct[]>();
    const [showInducementTooltip, setShowInducementTooltip] =
        useState<boolean>(false);

    const toggleShowInducementTooltip = () =>
        setShowInducementTooltip(!showInducementTooltip);

    const { configuration } = useConfigurationState();

    const { subscriptions, isLoading } = useSubscriptions(
        selectedEndInvestor?._id
    );

    useEffect(() => {
        const getEndInvestorSubscriptions = async () => {
            setEndInvestorSubscriptions([]);

            const filteredSubscriptions = subscriptions?.filter((s) =>
                [
                    SubscriptionStatusEnum.approved,
                    SubscriptionStatusEnum.readyForReview,
                ].includes(s.status)
            );

            if (filteredSubscriptions) {
                setLoadingSubscriptionProducts(true);
                const subscriptionsWithProduct: SubscriptionWithProduct[] =
                    await Promise.all(
                        filteredSubscriptions.map(
                            async (
                                subscription
                            ): Promise<SubscriptionWithProduct> => {
                                return productsGetById(subscription.productId!)
                                    .then((product) => ({
                                        subscription,
                                        product,
                                    }))
                                    .catch(() => ({
                                        subscription,
                                        product: undefined,
                                    }));
                            }
                        )
                    );
                setLoadingSubscriptionProducts(false);

                setEndInvestorSubscriptions(subscriptionsWithProduct);
            }
        };

        if (!selectedEndInvestor) {
            setEndInvestorSubscriptions(undefined);
            return;
        }

        getEndInvestorSubscriptions();
    }, [selectedEndInvestor]);

    const getSubscriptionsHeading = () =>
        `Subscriptions${
            selectedEndInvestor &&
            ` - ${getEndInvestorName(selectedEndInvestor)}`
        }`;

    const getSubscriptionTitle = (
        subscription: SubscriptionResponse,
        product: ProductResponse
    ) => {
        if (!product?.name) {
            return 'Unknown Subscription';
        }

        return `${(product?.name && product?.name + ' - ') || ''}${
            subscription.shareClass?.name || ''
        }`;
    };

    const calculatePlacementFee = (
        subscription: SubscriptionResponse
    ): number => {
        return (
            ((subscription.subscriptionAmount ?? 0) *
                (subscription.placementFee ?? 0)) /
            100
        );
    };

    const getInducementFeeText = (
        subscription: SubscriptionResponse
    ): string => {
        const { subscriptionAmount, shareClass }: SubscriptionResponse =
            subscription;
        const formatInducementFee = (inducementFeeAmount: number): string => {
            const calculatedFeeAmount = subscription.subscriptionCurrencyCode
                ? getFormattedCurrency(
                      inducementFeeAmount,
                      subscription.subscriptionCurrencyCode
                  )
                : 0;

            return `Quarterly Distribution Fee: ${calculatedFeeAmount}`;
        };

        if (
            shareClass?.distributionFee === 0 ||
            shareClass?.distributionFee === undefined ||
            shareClass?.distributionFee === null ||
            !subscriptionAmount
        ) {
            return formatInducementFee(0);
        }

        return formatInducementFee(
            (subscriptionAmount * shareClass.distributionFee) / 100 / 4
        );
    };

    return (
        <>
            <h1 className="text-3xl text-logo-blue font-bold align-middle mb-5">
                {t('navbar.inducement')}
            </h1>
            {loadingEndInvestorProfiles ? (
                <Loading size="large" />
            ) : (
                <SelectEndInvestor
                    endInvestorProfiles={endInvestorProfiles}
                    onChange={(
                        selectedEndInvestor?: EndInvestorProfileResponse
                    ) => setSelectedEndInvestor(selectedEndInvestor)}
                />
            )}
            <div className="flex md:flex-row h-full justify-center items-center bg-white">
                <div className="w-full xs:container xs:mx-auto">
                    {!loadingEndInvestorProfiles &&
                        (isLoading || loadingSubscriptionProducts) && (
                            <Loading size="large" className="mt-10" />
                        )}

                    {!isLoading &&
                        endInvestorSubscriptions &&
                        endInvestorSubscriptions?.length > 0 && (
                            <>
                                <h2 className="text-2xl pt-5 pb-10">
                                    {getSubscriptionsHeading()}
                                </h2>
                                <div>
                                    {endInvestorSubscriptions.map(
                                        (endInvestorSubscription) => {
                                            const { subscription, product } =
                                                endInvestorSubscription;
                                            const image = product?.images[0];

                                            return (
                                                <ImgText
                                                    key={subscription._id}
                                                    img={{
                                                        url: image?.url || '',
                                                        label: image?.label,
                                                    }}
                                                    name={getSubscriptionTitle(
                                                        subscription,
                                                        product!
                                                    )}
                                                    description={
                                                        <>
                                                            <div>
                                                                <SubscriptionStatusBadge
                                                                    subscriptionStatus={
                                                                        subscription.status!
                                                                    }
                                                                />
                                                            </div>
                                                            <p className="p-2 pt-4 font-semibold">
                                                                Investment
                                                                Amount:{' '}
                                                                {getFormattedCurrency(
                                                                    subscription.subscriptionAmount,
                                                                    subscription.subscriptionCurrencyCode
                                                                ) || 'N/A'}
                                                            </p>

                                                            {!product?.hideDistributionFee &&
                                                                inducementFeeApplies(
                                                                    subscription
                                                                ) && (
                                                                    <div className="flex">
                                                                        <p className="w-1/3 p-2 mt-2">
                                                                            Distribution
                                                                            Fee:{' '}
                                                                            {subscription
                                                                                .shareClass
                                                                                ?.distributionFee +
                                                                                '%' ||
                                                                                'N/A'}
                                                                        </p>

                                                                        <p className="w-1/3 p-2 font-medium">
                                                                            {getInducementFeeText(
                                                                                subscription
                                                                            )}
                                                                            {subscription.subscriptionAmount ? (
                                                                                <Button
                                                                                    hasIconOnly
                                                                                    startIcon={
                                                                                        <Tooltip tip="Investment Amount * Distribution Fee / 4" />
                                                                                    }
                                                                                    type="button"
                                                                                    className="relative top-1 left-2"
                                                                                    onClick={
                                                                                        toggleShowInducementTooltip
                                                                                    }
                                                                                ></Button>
                                                                            ) : null}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            {selectedEndInvestor &&
                                                                configuration &&
                                                                placementFeeAppliesToSubscription(
                                                                    selectedEndInvestor,
                                                                    configuration
                                                                ) && (
                                                                    <div className="flex flex-row">
                                                                        <p className="w-1/3 p-2 mt-2">
                                                                            Placement
                                                                            Fee:{' '}
                                                                            {subscription.placementFee
                                                                                ? subscription.placementFee +
                                                                                  '%'
                                                                                : 'N/A'}
                                                                        </p>

                                                                        <p className="w-1/3 p-2  font-medium">
                                                                            Calculated
                                                                            Placement
                                                                            Fee:{' '}
                                                                            {getFormattedCurrency(
                                                                                calculatePlacementFee(
                                                                                    subscription
                                                                                ),
                                                                                subscription.subscriptionCurrencyCode
                                                                            ) ||
                                                                                'N/A'}
                                                                            {subscription.subscriptionAmount ? (
                                                                                <Button
                                                                                    hasIconOnly
                                                                                    startIcon={
                                                                                        <Tooltip tip="Investment Amount * Placement Fee" />
                                                                                    }
                                                                                    type="button"
                                                                                    className="relative top-1 left-2"
                                                                                    onClick={
                                                                                        toggleShowInducementTooltip
                                                                                    }
                                                                                ></Button>
                                                                            ) : null}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                        </>
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </>
                        )}
                </div>
            </div>
        </>
    );
};

export default IndecementFeeCalculator;
