import { FieldArray, Formik, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useProductSubmit } from '@hooks/useProductSubmit';

import formModel from './ModelProducts';

import EditProductFormikButtons from '@pages/Admin/ProductsManagement/Components/EditProductFormikButtons';
import { Button } from '@components/Atoms';
import {
    DataReview,
    InlineFormFrame,
    FormInputBox,
    TextAreaBox,
} from '@components/Molecules';
import { SortableList } from '@components/Organisms';
import { useProductsState } from '@stores/Products';
import { useTranslation } from 'react-i18next';

interface AddVideoProps {
    title: string;
    url: string;
    embed_url: string;
}

const Videos: React.FC = () => {
    const { t } = useTranslation();
    const { formField } = formModel;

    const initialVals: AddVideoProps = {
        title: '',
        url: '',
        embed_url: '',
    };

    const [showAddVideo, setShowAddVideo] = useState<boolean>(true);
    const [formValue, setFormValue] = useState<any>();

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    const selectedValidationSchema = Yup.object().shape({
        videos: Yup.array().of(
            Yup.object().shape({
                [formField.videos.title.name]: Yup.string().trim().required(
                    `${formField.videos.title.requiredErrorMsg}`
                ),
                /*[formField.videos.url.name]: Yup.string().trim().required(`${formField.videos.url.requiredErrorMsg}`),*/
                [formField.videos.embed_url.name]: Yup.string().trim().required(
                    `${formField.videos.embed_url.requiredErrorMsg}`
                ),
            })
        ),
    });

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const toggleAddVideo = () => setShowAddVideo((bool) => !bool);

    const emptyVideoDetails = {
        title: '',
        url: '',
        embed_url: '',
    };

    const onSubmit = useProductSubmit(productId, `product-documents`);
    if (!formValue) {
        return null;
    }
    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{t('admin.product.update_videos')}</h1>
            {(productId === 'new' || product) && (
                <Formik
                    initialValues={formValue}
                    onSubmit={onSubmit}
                    validationSchema={selectedValidationSchema}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <div className="mb-3">
                                <h3 className="text-sm font-normal leading-8 mb-3">
                                    {t('admin.product.please_add_a_video')}
                                </h3>
                                <div className="text-logo-blue">
                                    <FieldArray
                                        name="videos"
                                        render={(arrayHelpers) => (
                                            <div>
                                                {values.videos &&
                                                    values.videos.length >
                                                        0 && (
                                                        <SortableList
                                                            onSorted={(items) =>
                                                                setFieldValue(
                                                                    'videos',
                                                                    items,
                                                                    false
                                                                )
                                                            }
                                                            handleClassName="relative -inset-x-9 inset-y-14"
                                                            listItems={
                                                                values.videos
                                                            }
                                                            listElements={values.videos.map(
                                                                (
                                                                    video: AddVideoProps,
                                                                    i: number
                                                                ) =>
                                                                    !showAddVideo &&
                                                                    i ===
                                                                        values
                                                                            .videos
                                                                            .length -
                                                                            1 ? (
                                                                        <InlineFormFrame
                                                                            key={i.toString()}
                                                                            primaryAction="Save Video"
                                                                            onPrimaryAction={() => {
                                                                                const isShareClassInvalid =
                                                                                    arrayHelpers
                                                                                        ?.form
                                                                                        ?.errors
                                                                                        ?.videos &&
                                                                                    Array.isArray(
                                                                                        arrayHelpers
                                                                                            .form
                                                                                            .errors
                                                                                            .videos
                                                                                    );
                                                                                const touchedValues =
                                                                                    {
                                                                                        video: [
                                                                                            ...arrayHelpers.form.values.videos.map(
                                                                                                (
                                                                                                    video: any
                                                                                                ) =>
                                                                                                    Object.keys(
                                                                                                        video
                                                                                                    ).reduce(
                                                                                                        (
                                                                                                            prev,
                                                                                                            curr
                                                                                                        ) => {
                                                                                                            return {
                                                                                                                ...prev,
                                                                                                                [curr]: true,
                                                                                                            };
                                                                                                        },
                                                                                                        {}
                                                                                                    )
                                                                                            ),
                                                                                        ],
                                                                                    };
                                                                                arrayHelpers.form.setTouched(
                                                                                    touchedValues,
                                                                                    true
                                                                                );
                                                                                if (
                                                                                    !isShareClassInvalid
                                                                                ) {
                                                                                    toggleAddVideo();
                                                                                }
                                                                            }}
                                                                            secondaryAction="Cancel"
                                                                            onSecondaryAction={() => {
                                                                                arrayHelpers.remove(
                                                                                    i
                                                                                );
                                                                                toggleAddVideo();
                                                                            }}
                                                                        >
                                                                            <FormInputBox
                                                                                name={`videos[${i}].title`}
                                                                                label={
                                                                                    formField
                                                                                        .videos
                                                                                        .title
                                                                                        .label
                                                                                }
                                                                            />

                                                                            <TextAreaBox
                                                                                name={`videos[${i}].embed_url`}
                                                                                label={
                                                                                    formField
                                                                                        .videos
                                                                                        .embed_url
                                                                                        .label
                                                                                }
                                                                                placeholder={
                                                                                    'Your Embed iFrame'
                                                                                }
                                                                            />
                                                                        </InlineFormFrame>
                                                                    ) : (
                                                                        <DataReview
                                                                            onDelete={() =>
                                                                                arrayHelpers.remove(
                                                                                    i
                                                                                )
                                                                            }
                                                                            key={i.toString()}
                                                                        >
                                                                            <div className="flex">
                                                                                <p className="font-bold text-sm w-24 mr-2">
                                                                                    {
                                                                                        formField
                                                                                            .videos
                                                                                            .title
                                                                                            .label
                                                                                    }
                                                                                </p>
                                                                                <p className="text-sm">
                                                                                    {
                                                                                        video.title
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex">
                                                                                <p className="font-bold text-sm w-24 mr-2">
                                                                                    {
                                                                                        formField
                                                                                            .videos
                                                                                            .embed_url
                                                                                            .label
                                                                                    }
                                                                                </p>
                                                                                {video.embed_url ? (
                                                                                    <p className="text-sm">
                                                                                        {
                                                                                            video.embed_url
                                                                                        }
                                                                                    </p>
                                                                                ) : (
                                                                                    <>

                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </DataReview>
                                                                    )
                                                            )}
                                                        />
                                                    )}

                                                {showAddVideo && (
                                                    <Button
                                                        label={`+ ${t('admin.product.add_video')}`}
                                                        type="button"
                                                        buttonType="secondary"
                                                        className="w-full my-2"
                                                        onClick={() => {
                                                            arrayHelpers.push(
                                                                emptyVideoDetails
                                                            );
                                                            toggleAddVideo();
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                                <EditProductFormikButtons
                                    productId={productId}
                                    isReadOnly={false}
                                    pathPrevious="product-images"
                                    pathNext="product-documents"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Videos;
