export const WindowsLogo = ({ className }: { className: string }) => (
    <svg
        className={className}
        viewBox="00 0 300 200"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M104.868 104.868H0V0h104.868v104.868z" fill="#f1511b" />
        <path d="M220.654 104.868H115.788V0h104.866v104.868z" fill="#80cc28" />
        <path d="M104.865 220.695H0V115.828h104.865v104.867z" fill="#00adef" />
        <path
            d="M220.654 220.695H115.788V115.828h104.866v104.867z"
            fill="#fbbc09"
        />
    </svg>
);
