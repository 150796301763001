import { ProspectiveInvestorResponse } from '@interfaces/Api/ProspectiveInvestorResponse';
import {
    InvestorCountryRequirement,
    WarningTextType,
    checkboxSubmissionType,
} from 'common';

const getCheckBoxSubmissionDocuments = (
    subcategorySubmission: checkboxSubmissionType
) => {
    const docs: { src: string; label: string }[] = [];
    const { warningText, warningTextType } = subcategorySubmission;
    if (warningTextType === WarningTextType.Pdf) {
        docs.push({
            src: warningText,
            label: getFileNameFromUrl(warningText),
        });
    }

    const { warningText: warningText2, warningTextType: warningTextType2 } =
        subcategorySubmission.checkBox;
    if (warningTextType2 === WarningTextType.Pdf) {
        docs.push({
            src: warningText2,
            label: getFileNameFromUrl(warningText2),
        });
    }
    return docs;
};

const getFileNameFromUrl = (url: string) => {
    const splitUrl = url.split('/');
    const fileName = splitUrl[splitUrl.length - 1];
    return fileName;
};

export const getDisclosuresForProspectiveInvestor = (
    investorCountryRequirementTree: InvestorCountryRequirement[],
    prospectiveInvestor: ProspectiveInvestorResponse
) => {
    const documents: { src: string; label: string }[] = [];

    const investorCountryRequirement = investorCountryRequirementTree.find(
        (b) => b.countryCode == prospectiveInvestor?.countryCodeOfResidence
    );

    const investorType = investorCountryRequirement?.investorTypes.find(
        (investorType) =>
            investorType.name === prospectiveInvestor?.investorType
    );

    if (investorType) {
        const { warningText, warningTextType } = investorType;

        if (warningTextType === WarningTextType.Pdf) {
            documents.push({
                src: warningText,
                label: getFileNameFromUrl(warningText),
            });
        }
    }

    if (investorCountryRequirement) {
        const category = investorCountryRequirement.investorCategories.find(
            (c) => c.name === prospectiveInvestor.investorCategory
        );

        if (category) {
            const subcategorySubmission = category.investorCategoryTypes.find(
                (d) => d.value === prospectiveInvestor.investorCategoryType
            );

            if (subcategorySubmission) {
                // check if sub category has documents

                if (subcategorySubmission.type === 'checkboxSubmission') {
                    documents.push(
                        ...getCheckBoxSubmissionDocuments(subcategorySubmission)
                    );
                } else if (
                    subcategorySubmission.type === 'subcategorySubmission'
                ) {
                    const subInvestorCategoryType =
                        subcategorySubmission.subInvestorCategoryTypes.find(
                            (e) =>
                                e.value ===
                                prospectiveInvestor.subInvestorCategoryType
                        );
                    if (!subInvestorCategoryType) {
                        return;
                    }
                    if (subInvestorCategoryType.type === 'checkboxSubmission') {
                        documents.push(
                            ...getCheckBoxSubmissionDocuments(
                                subInvestorCategoryType
                            )
                        );
                    }
                }
            }
        }
    }

    return documents;
};
