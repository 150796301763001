import { isoCountries } from '@helpers/isoCountries';
import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { ReviewItem } from './ReviewItem';
import { useTranslation } from 'react-i18next';

const ReviewClientAddressesDetails = (
    props: ReviewClientSummaryDetailsProps
) => {
    const { t } = useTranslation();

    return (
        <div className="leading-5 mb-4">
            {props.data?.individualDetails?.addresses?.map((addr, i) => (
                <div className="pb-3" key={`${i}-address`}>
                    <ReviewItem
                        label={`${t(
                            'subscriptions.address_details.review.house_name'
                        )}:`}
                        value={addr?.houseName}
                    />

                    <ReviewItem
                        label={`${t(
                            'subscriptions.address_details.review.address_line_1'
                        )}:`}
                        value={addr?.residentialLine1}
                    />

                    {addr?.residentialLine2 !== '' && (
                        <ReviewItem
                            label={`${t(
                                'subscriptions.address_details.review.address_line_2'
                            )}:`}
                            value={addr?.residentialLine2}
                        />
                    )}

                    <ReviewItem
                        value={addr?.city}
                        label={`${t(
                            'subscriptions.address_details.review.city'
                        )}:`}
                    />

                    <ReviewItem
                        value={addr?.postCode}
                        label={`${t(
                            'subscriptions.address_details.review.postcode'
                        )}:`}
                    />

                    <ReviewItem
                        label={`${t(
                            'subscriptions.address_details.review.country'
                        )}:`}
                        value={isoCountries[addr?.countryCode]}
                    />
                </div>
            ))}
        </div>
    );
};

export default ReviewClientAddressesDetails;
