import { FC, useCallback, useMemo, useState } from 'react';
import { BusinessEntityDetails, ClientEnum } from 'common';
import { t } from 'i18next';
import { Formik, Form } from 'formik';
import { getStepsConfig } from '@pages/Clients/Clients/Components/Registration/getStepsConfig';
import { Button } from '@components/Atoms';
import { ModalService } from '@services/ModalService';
import { FormInputBox } from '@components/Molecules';
import { useConfigurationState } from '@contexts/ConfigurationContext';

interface BusinessDetailProps {
    onSubmit: (businessEntityDetails: BusinessEntityDetails) => void;
}

export const BusinessDetail: FC<BusinessDetailProps> = ({ onSubmit }) => {
    const { isClient } = useConfigurationState();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const config = useMemo(() => {
        const { steps } = getStepsConfig();
        return steps.find((step) => step.name === 'businessEntityDetails');
    }, []);

    const handleSubmit = useCallback(
        (values: BusinessEntityDetails & { hgId?: string }) => {
            setIsLoading(true);
            onSubmit(values);
        },
        [onSubmit]
    );

    return (
        <div>
            <Formik
                onSubmit={handleSubmit}
                initialValues={{}}
                validateOnMount
                validationSchema={config.validationSchema}
                enableReinitialize
            >
                {(formProps) => (
                    <Form>
                        {isClient(ClientEnum.HG) && (
                            <FormInputBox
                                name="hgId"
                                label="Your Hg ID"
                                placeholder="Please add your Hg ID if you have invested with us previously"
                            />
                        )}
                        {config.component && <config.component />}
                        <div className="flex justify-between border-t pt-4 border-sky-100">
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    ModalService.getInstance().hideModal();
                                }}
                                label={t('ui.controls.cancel')}
                            />
                            <Button
                                label={t('ui.controls.next')}
                                type="submit"
                                isLoading={isLoading}
                                disabled={!formProps.isValid || isLoading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
