import { Button } from '@components/Atoms';
import { DatePicker } from '@components/Organisms';
import { getFormValuePath } from '@helpers/formik.helper';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { idTypeOptions } from '@interfaces/Api/Nationality';
import { Nationality } from 'common';
import { FieldArray } from 'formik';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { FormInputBox } from '../../Molecules/FormInputBox';
import { FormSelect } from '../../Molecules/FormSelect';
import { InlineFormFrame } from '../../Molecules/InlineFormFrame';

interface FormNationalitiesProps {
    name: string;
    disabled?: boolean;
}

export const FormNationalities: FC<FormNationalitiesProps> = ({
    name = 'nationalities',
    disabled = false,
}) => {
    const emptyNationality: Nationality = {
        countryCode: '',
        nationalIdNumber: '',
    };

    const [editNationalityIndex, setEditNationalityIndex] =
        useState<number>(-1);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => (
                <div>
                    {getFormValuePath(arrayHelpers.form.values, name)?.map(
                        (nationality: any, i: number, arr: any) => (
                            <div key={i.toString()}>
                                <InlineFormFrame
                                    secondaryAction={t('ui.controls.remove')}
                                    onSecondaryAction={() => {
                                        arrayHelpers.remove(i);
                                        setEditNationalityIndex(-1);
                                    }}
                                >
                                    <FormSelect
                                        name={`${name}[${i}].countryCode`}
                                        label={`${t(
                                            'clients.registration.nationalities.form.country'
                                        )} *`}
                                        optionsData={isoCountriesOptions}
                                        disabled={disabled}
                                    />
                                    <FormSelect
                                        name={`${name}[${i}].idType`}
                                        label={`${t(
                                            'clients.registration.nationalities.form.id_type'
                                        )} *`}
                                        optionsData={idTypeOptions}
                                        disabled={disabled}
                                    />
                                    <FormInputBox
                                        name={`${name}[${i}].nationalIdNumber`}
                                        label={`${t(
                                            'clients.registration.nationalities.form.id_number'
                                        )} *`}
                                        placeholder={`${t(
                                            'clients.registration.nationalities.form.id_number'
                                        )} *`}
                                        disabled={disabled}
                                    />
                                    <DatePicker
                                        name={`${name}[${i}].expiryDate`}
                                        label={`${t(
                                            'clients.registration.nationalities.form.expiry_date'
                                        )} *`}
                                        disabled={disabled}
                                    />
                                </InlineFormFrame>
                            </div>
                        )
                    )}

                    <Button
                        label={`+ ${t(
                            'client.registration.nationalities.add_nationality'
                        )}`}
                        type="button"
                        buttonType="secondary"
                        className="w-full my-2"
                        disabled={disabled}
                        onClick={() => {
                            const nationalities = getFormValuePath(
                                arrayHelpers.form.values,
                                name
                            );
                            setEditNationalityIndex(nationalities?.length || 0);

                            arrayHelpers.push(emptyNationality);
                        }}
                    />
                </div>
            )}
        />
    );
};
