import { useState, useEffect } from 'react';
import {
    Field,
    FieldHookConfig,
    FieldMetaProps,
    FieldInputProps,
    useFormikContext,
} from 'formik';

import { SelectOption } from '@interfaces/InterfaceFormsProps';

import { Icons, SelectBox } from '@components/Atoms';
import { FormFieldWrapper } from '../FormFieldWrapper';

export interface FormSelectProps {
    name: string;
    label: string;
    value?: any;
    optionsData?: SelectOption<any>[];
    getOptionsData?(): Promise<SelectOption<any>[]>;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement> | any) => void;
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    placeholder?: string;
    dataTestId?: string;
}

const FormSelect = (props: FormSelectProps & FieldHookConfig<string>) => {
    const [options, setOptions] = useState<SelectOption<any>[]>([]);
    const { optionsData, getOptionsData } = props;
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        if (optionsData) {
            setOptions(optionsData);
        } else if (getOptionsData) {
            getOptionsData().then(setOptions);
        }
    }, [optionsData, getOptionsData]);

    const handleOnChange = ({ onChange, e }: any) => {
        if (e.target.value === '') setFieldValue(props.name, undefined);
        onChange && onChange(e);
    };

    return (
        <div className="relative w-full mb-3 cursor-default">
            <FormFieldWrapper
                label={props.label}
                rightIcon={<Icons name="ChevronDown" size="small" />}
                name={props.name}
            >
                <Field id={props.name} name={props.name}>
                    {({
                        field,
                        meta: { touched, error },
                    }: {
                        field: FieldInputProps<any>;
                        meta: FieldMetaProps<any>;
                    }) => {
                        return (
                            <SelectBox
                                error={touched && !!error}
                                placeholder={props.placeholder}
                                required ={props.required}
                                onChange={(e) =>
                                    handleOnChange({
                                        e,
                                        onChange:
                                            props.onChange || field.onChange,
                                    })
                                }
                                onBlur={props.onBlur || field.onBlur}
                                value={props.value || field.value}
                                name={props.name || field.name}
                                disabled={props.disabled}
                                multiple={props.multiple}
                                dataTestId={props.dataTestId}
                                optionsData={options}
                            />
                        );
                    }}
                </Field>
            </FormFieldWrapper>
        </div>
    );
};

export default FormSelect;
