import { useMemo, useState } from 'react';

import {
    AuthPermissionEnum,
    AuthRoleEnum,
    SubscriptionStatusEnum,
    SystemUserResponse,
} from '@interfaces/Api';

import { EndInvestorProfileResponse } from '@interfaces/Api/EndInvestorProfileResponse';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getProductDetails } from '@helpers/Product.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';

import { Loading } from '@components/Atoms';
import {
    SubscriptionGridItem,
    SubscriptionsGrid,
} from '@pages/Subscriptions/SubscriptionsGrid';
import { ModalService } from '@services/ModalService';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import { getRiskLevelFromSubscription } from '@helpers/common.helper';

export interface ReviewClientSubscriptionsListProps {
    endInvestorProfile: EndInvestorProfileResponse;
}

const ReviewClientSubscriptionsList: React.FC<
    ReviewClientSubscriptionsListProps
> = (props: ReviewClientSubscriptionsListProps) => {
    const { endInvestorProfile } = props;

    const { preferences } = useConfigurationState();
    const { hasPermissions, hasRoles } = useAuthState();
    const { products } = useProducts();
    const { subscriptions, isLoading } = useSubscriptions(
        endInvestorProfile._id
    );

    const { intermediaries } = useIntermediaries();
    const [subscriptionGridItems, setSubscriptionGridItems] = useState<
        SubscriptionGridItem[]
    >([]);
    const [systemUser, setSystemUser] = useState<SystemUserResponse>();
    const [loadingGridItems, setLoadingGridItems] = useState<boolean>(false);

    const { deleteSubscription, fetchSubscriptions } =
        useSubscriptionsActions();

    const filteredSubscriptions = hasRoles([AuthRoleEnum.fundAdmin])
        ? subscriptions.filter((s) => s._id !== endInvestorProfile._id)
        : subscriptions;

    const handleDeleteSubscription = async (subscriptionId: string) => {
        const subscription = filteredSubscriptions.find(
            (val) => val._id === subscriptionId
        );
        if (subscription) {
            const product = products.find(
                (val) => val._id === subscription?.productId
            );

            let midSentencePhrase;
            if (product) {
                midSentencePhrase = getProductDetails({
                    productName: product?.name,
                    shareClassName: subscription?.shareClass?.name,
                    subscriptionAmount: getFormattedCurrency(
                        subscription?.subscriptionAmount,
                        subscription?.subscriptionCurrencyCode
                    ),
                });
            } else {
                midSentencePhrase = 'that has no associated product';
            }

            ModalService.getInstance()
                .setTitle('Delete Subscription')
                .setComponent(
                    <div>
                        All data related to the subscription{' '}
                        <b>{midSentencePhrase}</b> and any documents will be
                        permanently deleted from the system.{' '}
                        <b>This operation is irreversible.</b> Are you sure you
                        want to continue?
                    </div>
                )
                .setShowFooter(true)
                .setConfirmationLabel('Delete')
                .setConfirmCallback(() => {
                    deleteSubscription(subscriptionId).then(() => {
                        ModalService.getInstance().hideModal();
                        fetchSubscriptions(endInvestorProfile._id);
                    });
                })
                .showModal();
        }
    };

    const canDeleteSubscription =
        preferences?.feature?.data?.canSuperUserDeleteSubscriptions &&
        hasPermissions([AuthPermissionEnum.deleteSubscription]);

    const loadSystemUser = async (id) => await SystemUsersGet(id);

    useMemo(() => {
        if (endInvestorProfile.adviserSystemUserId) {
            loadSystemUser(endInvestorProfile.adviserSystemUserId).then(
                (systemUser) => setSystemUser(systemUser)
            );
        }
    }, [endInvestorProfile]);

    useMemo(() => {
        setLoadingGridItems(true);

        const data = filteredSubscriptions.map((subscription) => {
            const product = products.find(
                (val) => val._id === subscription.productId
            );

            const endInvestorName = getEndInvestorName(endInvestorProfile);

            const distributor = intermediaries.find(
                (i) => i._id === endInvestorProfile.intermediaryId
            )?.name;

            const relationshipManager =
                systemUser?.firstName + ' ' + systemUser?.surname;
            const creationDate = subscription.statusLogs.find(
                (f) => f.status === SubscriptionStatusEnum.inProgress
            )?.createdAt;
            return {
                subscriptionId: subscription._id,
                subscriptionUrl: `/subscriptions/${subscription._id}/`,
                status: subscription.status,
                endInvestorName,
                product,
                shareClass: subscription.shareClass,
                subscriptionAmount: subscription.subscriptionAmount,
                subscriptionCurrencyCode: subscription.subscriptionCurrencyCode,
                statusLogs: subscription.statusLogs,
                signingDocuments: subscription.signingDocuments,
                closingDate: subscription.closingDate,
                workflowStatus: subscription.completenessChecks?.status,
                distributor,
                relationshipManager,
                creationDate: creationDate,
                latestTimeActivity: subscription.latestTimeActivity,
                riskLevel: getRiskLevelFromSubscription(subscription),
            } as SubscriptionGridItem;
        });

        setSubscriptionGridItems(data);
        setLoadingGridItems(false);
    }, [
        products,
        endInvestorProfile,
        filteredSubscriptions,
        intermediaries,
        systemUser,
    ]);

    return (
        <>
            {((isLoading || loadingGridItems) && <Loading size="large" />) || (
                <div className="mb-6">
                    <div>
                        <SubscriptionsGrid
                            gridName="reviewClientSubscriptionsList"
                            endInvestorProfileId={endInvestorProfile._id}
                            subscriptions={subscriptionGridItems}
                            onDeleteSubscription={
                                canDeleteSubscription
                                    ? handleDeleteSubscription
                                    : undefined
                            }
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        endInvestorName: false,
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default ReviewClientSubscriptionsList;
