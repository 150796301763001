import { useEffect, useMemo, useState } from 'react';
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '@contexts/AuthContext';
import { Tabs } from '@components/Organisms';
import { BasicBreadcrumbs, Loading } from '@components/Atoms';
import { dateShortestFormatter } from '@helpers/Date.helper';
import EndInvestorNameHeadingComponent from '@pages/Portfolio/Components/endInvestorHeadingComponent';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import ProductHeaderWithDates from './ProductHeaderWithDates';
import { AuthRoleEnum, LiquidityTypeEnum } from '@interfaces/Api';
import { LifecycleTabContent } from '@components/Organisms/Dashboard/Components/LifeCycleTabContent';
import { ReportsTabContent } from '@components/Organisms/Dashboard/Components/ReportsTabContent';
import { ClosedEndedPerformanceTabContent } from '@components/Organisms/Dashboard/Components/ClosedEndedPerformanceTabContent';
import { DocumentTabContent } from '@components/Organisms/Dashboard/Components/DocumentTabContent';
import { usePortfolioState } from '@contexts/PortfolioContext';
import { OpenEndedPerformanceTabContent } from '@components/Organisms/Dashboard/Components/OpenEndedPerformanceTabContent';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import RedemptionApplicationsTabContent from '@components/Organisms/Dashboard/Components/RedemptionsTabContent';
import OverviewTabsContent from '@components/Organisms/Dashboard/Components/OverviewTabsContent';
import { AlertBox } from '@components/Molecules';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { ClientEnum } from 'common';

type PillOptionType =
    | 'Overview'
    | 'Performance'
    | 'Reports'
    | 'Lifecycle'
    | 'Documents'
    | 'Redemption Applications';

const ProductOverview = () => {
    const [searchParams] = useSearchParams();
    const { subscriptionId, endInvestorProfileId: endInvestorProfileIdParam } =
        useParams();
    const {
        getLifecycleData,
        subscriptionLifecycleData,
        getPortfolioBySubscriptionId,
        endInvestorPortfolioItem,
        portfolioIsLoading,
    } = usePortfolioState();
    const { currentUser } = useAuthState();
    const { isClient } = useConfigurationState();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { endInvestorProfile } = useEndInvestorProfile();

    const endInvestorProfileId =
        endInvestorProfileIdParam ||
        currentUser?.user?.endInvestorProfileIds?.[0];

    const [selectedPill, setSelectedPill] = useState<number>(0);

    useEffect(() => {
        if (subscriptionId) {
            getLifecycleData(subscriptionId);
        }
    }, [subscriptionId, getLifecycleData]);

    useEffect(() => {
        if (endInvestorProfileId && subscriptionId) {
            getPortfolioBySubscriptionId(endInvestorProfileId, subscriptionId);
        }
    }, [subscriptionId, endInvestorProfileId, getPortfolioBySubscriptionId]);

    const productDates = useMemo(() => {
        if (!endInvestorPortfolioItem) return [];

        if (
            endInvestorPortfolioItem.fundData.liquidityType ===
            LiquidityTypeEnum.closedEnded
        ) {
            return [
                {
                    label: t('client.portfolio.product.lifecycle.launched'),
                    value: endInvestorPortfolioItem.fundData.inceptionDate
                        ? dateShortestFormatter(
                              endInvestorPortfolioItem.fundData.inceptionDate
                          )
                        : '-',
                },
                {
                    label: t('client.portfolio.card.committed'),
                    value: endInvestorPortfolioItem.investmentData
                        .commitmentDate
                        ? dateShortestFormatter(
                              endInvestorPortfolioItem.investmentData
                                  .commitmentDate
                          )
                        : '-',
                },
                {
                    label: t('client.portfolio.card.ends'),
                    value: endInvestorPortfolioItem.fundData.endDate
                        ? dateShortestFormatter(
                              endInvestorPortfolioItem.fundData.endDate
                          )
                        : '-',
                },
            ];
        } else {
            return [
                {
                    label: t('client.portfolio.card.launched'),
                    value: endInvestorPortfolioItem.fundData.inceptionDate
                        ? dateShortestFormatter(
                              endInvestorPortfolioItem.fundData.inceptionDate
                          )
                        : '',
                },
            ];
        }
    }, [endInvestorPortfolioItem, t]);

    const tabs: { tabName: string; component: JSX.Element; show: boolean }[] =
        useMemo(() => {
            if (!endInvestorPortfolioItem || !subscriptionLifecycleData)
                return [];
            return [
                {
                    tabName: t('client.portfolio.product.pill_selector.overview'),
                    component: (
                        <OverviewTabsContent
                            endInvestorPortfolioItem={endInvestorPortfolioItem}
                        />
                    ),
                    show:
                        endInvestorPortfolioItem.fundData.liquidityType ===
                        LiquidityTypeEnum.closedEnded,
                },
                {
                    tabName: t('client.portfolio.product.pill_selector.performance'),
                    component:
                        endInvestorPortfolioItem.fundData.liquidityType ===
                        LiquidityTypeEnum.closedEnded ? (
                            <ClosedEndedPerformanceTabContent
                                {...endInvestorPortfolioItem}
                            />
                        ) : (
                            <OpenEndedPerformanceTabContent
                                {...endInvestorPortfolioItem}
                            />
                        ),
                    show: true,
                },
                {
                    tabName: t('client.portfolio.product.pill_selector.reports'),
                    component: (
                        <ReportsTabContent {...endInvestorPortfolioItem} />
                    ),
                    show: true,
                },
                {
                    tabName: t('client.portfolio.product.pill_selector.lifecycle'),
                    component: (
                        <LifecycleTabContent {...subscriptionLifecycleData} />
                    ),
                    show: true,
                },
                {
                    tabName: t('client.portfolio.product.pill_selector.documents'),
                    component: (
                        <DocumentTabContent {...endInvestorPortfolioItem} />
                    ),
                    show: true,
                },
                {
                    tabName: t('client.portfolio.product.pill_selector.redemption_applications'),
                    component: (
                        <RedemptionApplicationsTabContent
                            lifeCycleData={subscriptionLifecycleData}
                        />
                    ),
                    show:
                        endInvestorPortfolioItem.fundData.liquidityType ===
                        LiquidityTypeEnum.openEnded,
                },
            ].filter((a) => a.show);
        }, [endInvestorPortfolioItem, subscriptionLifecycleData, t]);

    useEffect(() => {
        const pill = searchParams.get('section') as PillOptionType;
        if (tabs.length > 0 && pill) {
            setSelectedPill(tabs.findIndex((tab) => tab.tabName === pill));
        }
    }, [searchParams, selectedPill, tabs]);

    const actionItems = [
        {
            label: 'Create Redemption',
            onClick: () => {
                navigate('create-redemption');
            },
            show:
                !isClient(ClientEnum.VP) &&
                endInvestorPortfolioItem?.fundData.liquidityType ==
                    LiquidityTypeEnum.openEnded,
        },
    ].filter((a) => a.show);

    return portfolioIsLoading ? (
        <div className="p-10 ml-2">
            <Loading size="large"></Loading>
        </div>
    ) : (
        <div className="mt-5 mb-8">
            <div className="mb-5 ">
                <Link
                    to={`/clients/${endInvestorProfile?._id}`}
                    title="Go back"
                >
                    <EndInvestorNameHeadingComponent
                        endInvestorProfile={endInvestorProfile!}
                    />
                </Link>
            </div>
            {endInvestorPortfolioItem ? (
                <>
                    <div className="mt-5 mb-5 p-2 pb-5">
                        <ProductHeaderWithDates
                            subHeaderList={productDates}
                            productName={
                                endInvestorPortfolioItem.fundData.productName
                            }
                            productSlug={
                                endInvestorPortfolioItem.fundData.productSlug
                            }
                            liquidityType={
                                endInvestorPortfolioItem.fundData.liquidityType
                            }
                            actionItems={actionItems}
                        />
                    </div>

                    <Tabs
                        onClick={(tab) => {
                            navigate(`?section=${encodeURIComponent(tab)}`, {
                                replace: true,
                            });
                        }}
                        tabs={tabs.map((a) => a.tabName)}
                        tabsPanel={tabs.map((a) => a.component)}
                        activeTab={selectedPill}
                        includeOutline={true}
                    />
                </>
            ) : (
                <AlertBox
                    alertType="info"
                    message={t('client.portfolio.no_portfolio_data')}
                />
            )}
        </div>
    );
};

const ProductOverviewBreadCrumbs = () => {
    const { hasRoles } = useAuthState();
    const params = useParams();
    const { endInvestorProfileId } = useParams();
    const { t } = useTranslation();

    const { subscriptions } = useSubscriptions();
    const subScriptionProductId = subscriptions.find(
        (sub) => sub._id === params.subscriptionId
    )?.productId;
    const { products } = useProducts();
    const { endInvestorProfiles } = useEndInvestorProfileState();
    const endInvestorProfile = useMemo(
        () => endInvestorProfiles.find((a) => a._id === endInvestorProfileId),
        [endInvestorProfileId, endInvestorProfiles]
    );
    const productName = products?.find(
        (product) => product._id === subScriptionProductId
    )?.name;
    const endInestorProfileName = getEndInvestorName(endInvestorProfile);
    const isEndInvestor = hasRoles([AuthRoleEnum.endInvestor]);
    const items = isEndInvestor
        ? [
              {
                  displayText: t('navbar.portfolio'),
                  navigationUrl: `/clients/${endInvestorProfileId}`,
              },
              {
                  displayText: productName,
                  navigationUrl: '',
              },
          ]
        : [
              {
                  displayText: t('navbar.clients'),
                  navigationUrl: '/clients',
              },
              {
                  displayText: endInestorProfileName,
                  navigationUrl: ``,
              },
          ];
    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ProductOverview,
    undefined,
    <ProductOverviewBreadCrumbs />
);
