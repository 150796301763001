import {
    RequestOptUpGet,
    RequestOptUpStatusPost,
} from '@api/EndInvestorProfiles';
import { Badge, Button, Loading } from '@components/Atoms';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';
import Grid from '@components/Atoms/Grid/Grid';
import { ActionsMenu, TextAreaBox } from '@components/Molecules';
import { useAuthState } from '@contexts/AuthContext';
import { formatDate } from '@helpers/Date.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { OptUpRequest, RequestOptUpStatus } from '@interfaces/OptUpRequests';
import {
    GridColDef,
    GridColumnHeaderParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ModalService } from '@services/ModalService';
import { AuthPermissionEnum } from 'common';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

type OptUpRequestsProps = {
    endInvestorProfileId?: string | undefined;
    selectedOptUpRequestId?: string | undefined;
};
type OptUpStatusUpdaterProps = {
    oldCategory: string;
    newCategory: string;
    productName: string;
    optUpRequestId: string;
    endInvestorId: string;
    comment: string;
    documents: any[];
    onUpdate: (
        optUpRequestId: string,
        endInvestorId: string,
        statusComment: string,
        status: RequestOptUpStatus
    ) => void;
    onAbort: () => void;
};

const OptUpStatusUpdater = ({
    oldCategory,
    newCategory,
    comment,
    productName,
    optUpRequestId,
    endInvestorId,
    onUpdate,
    onAbort,
    documents,
}: OptUpStatusUpdaterProps) => {
    const initialValues = {
        statusComment: '',
    };
    const validationSchema = Yup.object().shape({
        statusComment: Yup.string()
            .trim()
            .required('Please provide a valid status comment'),
    });
    const [selectedStatus, setSelectedStatus] = useState<RequestOptUpStatus>();
    return (
        <>
            <Formik
                onSubmit={(a) => {
                    onUpdate(
                        optUpRequestId,
                        endInvestorId,
                        a.statusComment,
                        selectedStatus
                    );
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(form) => (
                    <Form>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                Product Name
                            </label>
                            <p className="text-gray-800">{productName}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                Comment
                            </label>
                            <p className="text-gray-800">{comment}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                Old Category
                            </label>
                            <p className="text-gray-800">{oldCategory}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                New Category
                            </label>
                            <p className="text-gray-800">{newCategory}</p>
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2 text-sm font-bold text-gray-700">
                                Documents
                            </label>
                            <p className="text-gray-800">
                                <ol className="max-w-md space-y-1 list-decimal list-inside">
                                    {documents.map((doc, docIndex) => (
                                        <li key={docIndex}>
                                            <DocumentLinkRender href={doc.url}>
                                                {doc.fileName}
                                            </DocumentLinkRender>
                                        </li>
                                    ))}
                                </ol>
                            </p>
                        </div>
                        <div className="mb-4">
                            <TextAreaBox
                                name="statusComment"
                                placeholder="Status Comments"
                                value=""
                            />
                        </div>

                        <div className="flex justify-between mt-8">
                            <Button
                                buttonType="secondary"
                                label={'Cancel'}
                                onClick={() => {
                                    onAbort();
                                }}
                            />
                            <ActionsMenu
                                actionItems={[
                                    {
                                        label: 'Approve',
                                        onClick: () => {
                                            setSelectedStatus(
                                                RequestOptUpStatus.Approve
                                            );
                                            form.submitForm();
                                        },
                                    },
                                    {
                                        label: 'Reject',
                                        onClick: () => {
                                            setSelectedStatus(
                                                RequestOptUpStatus.Reject
                                            );
                                            form.submitForm();
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const OptUpRequests = ({
    endInvestorProfileId,
    selectedOptUpRequestId,
}: OptUpRequestsProps) => {
    const [optUpRequests, setOptUpRequests] = useState<OptUpRequest[]>([]);
    const [fetchCompleted, setFetchCompleted] = useState(false);
    const { hasPermissions } = useAuthState();
    const apiRef = useGridApiRef();
    const getOptUpRequests = (endInvestorProfileId: string | undefined) => {
        RequestOptUpGet(endInvestorProfileId).then((data) => {
            setFetchCompleted(true);
            setOptUpRequests(data);
        });
    };

    useEffect(() => {
        getOptUpRequests(endInvestorProfileId);
    }, [endInvestorProfileId]);

    const handleOptUpRequest = (
        optUpRequestId: string,
        profileIdForEndInvestor: string,
        statusComment: string,
        status: RequestOptUpStatus
    ) => {
        if (!optUpRequestId) return;

        RequestOptUpStatusPost(
            optUpRequestId,
            profileIdForEndInvestor,
            statusComment,
            status
        )
            .catch((error) => {
                notifyError(errorToString(error));
            })
            .finally(() => {
                getOptUpRequests(endInvestorProfileId);
            });
    };

    if (optUpRequests.length === 0 && fetchCompleted) {
        return (
            <div
                className="px-4 py-3 mt-4 mb-4 text-blue-700 bg-blue-100 border-t border-b border-blue-500"
                role="alert"
            >
                <p className="font-bold">No pending Opt Up Request Found</p>
            </div>
        );
    }

    const columns: GridColDef[] = [
        {
            field: 'requestedAt',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => (
                <span>
                    {params.row.requestedAt &&
                        formatDate(
                            new Date(params.row.requestedAt).toISOString()
                        )}
                </span>
            ),
        },
        {
            field: 'investor',
            headerName: 'Investor',
            flex: 1,
        },
        {
            field: 'product',
            headerName: 'Product',
            flex: 1,
        },

        {
            field: 'oldCategory',
            headerName: 'Old',
            flex: 1,
        },
        {
            field: 'newCategory',
            headerName: 'New',
            flex: 1,
        },
        {
            field: 'comment',
            headerName: 'Comment',
            flex: 2,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => {
                const status = String(params.row.status).toLowerCase();
                if (status === 'approve')
                    return (
                        <Badge
                            badgeType="success"
                            label="Approved"
                            sizeType={'medium'}
                        />
                    );
                if (status === 'pending')
                    return (
                        <Badge
                            badgeType="preview"
                            label="Pending"
                            sizeType={'medium'}
                        />
                    );
                return (
                    <Badge
                        badgeType="error"
                        label="Rejected"
                        sizeType={'medium'}
                    />
                );
            },
        },
        {
            field: 'documents',
            headerName: 'Documents',
            flex: 1,
            renderCell: (params) => {
                return (
                    <ol className="max-w-md space-y-1 list-decimal list-inside">
                        {params.row.documents.map((doc, docIndex) => (
                            <li key={docIndex}>
                                <DocumentLinkRender href={doc.url}>
                                    {doc.fileName}
                                </DocumentLinkRender>
                            </li>
                        ))}
                    </ol>
                );
            },
        },
        {
            field: 'statusComment',
            headerName: `Notes`,
            flex: 1,
        },
    ];

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        );
    };

    const rows = optUpRequests.map((optUpRequest) => {
        return {
            investor: `${optUpRequest?.endInvestorProfileId?.individualDetails?.firstName} ${optUpRequest?.endInvestorProfileId?.individualDetails?.lastName}`,
            id: optUpRequest._id,
            newCategory: toCapitalizedWords(optUpRequest.newCategory),
            product: optUpRequest.productId?.name, // why is a product coming from a product ID???????? wtf
            comment: optUpRequest.comment,
            documents: optUpRequest.uploadedDocuments,
            statusComment: optUpRequest.statusComment,
            endInvestorId: optUpRequest.endInvestorProfileId?._id,
            status: toCapitalizedWords(optUpRequest.status),
            oldCategory: toCapitalizedWords(
                optUpRequest.endInvestorProfileId?.clientType
            ),
            requestedAt: optUpRequest.requestedAt,
        };
    });

    const CustomHeader = (params: GridColumnHeaderParams<any, any, any>) => {
        return (
            <div
                style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {params.colDef.headerName}
            </div>
        );
    };

    return (
        <>
            {(!fetchCompleted && <Loading size="large" />) || (
                <div className="mt-4 overflow-x-auto shadow-md sm:rounded-lg">
                    <Grid
                        name="optUpRequests"
                        selectedRowId={selectedOptUpRequestId}
                        apiRef={apiRef}
                        columns={columns.map((column) => ({
                            ...column,
                            headerClassName: 'custom-header',
                            renderHeader: (params) => (
                                <CustomHeader {...params} />
                            ),
                        }))}
                        rows={rows}
                        slots={{ toolbar: customToolbar }}
                        onRowHandler={(params) => {
                            //Check for permissions before showing the modal popup to amend status
                            const userHasPermissionsToAmend = hasPermissions([
                                AuthPermissionEnum.approveOptUpEndInvestorProfileRequest,
                                AuthPermissionEnum.rejectOptUpEndInvestorProfileRequest,
                            ]);

                            if (!userHasPermissionsToAmend) return;

                            //Short circuit if request status is updated already.
                            const requestStatus = String(
                                params.row.status
                            ).toLowerCase();

                            if (
                                requestStatus === RequestOptUpStatus.Approve ||
                                requestStatus === RequestOptUpStatus.Reject
                            )
                                return;

                            ModalService.getInstance()
                                .setBodyStyle({
                                    maxHeight: '700px',
                                })
                                .setTitle('Update OptUp Request Status')
                                .setComponent(
                                    <OptUpStatusUpdater
                                        comment={params.row.comment}
                                        productName={params.row.product}
                                        newCategory={params.row.newCategory}
                                        oldCategory={params.row.oldCategory}
                                        endInvestorId={params.row.endInvestorId}
                                        optUpRequestId={params.row.id}
                                        documents={params.row.documents}
                                        onAbort={() => {
                                            ModalService.getInstance().hideModal();
                                        }}
                                        onUpdate={(
                                            optUpRequestId: string,
                                            endInvestorId: string,
                                            statusComment: string,
                                            status: RequestOptUpStatus
                                        ) => {
                                            handleOptUpRequest(
                                                optUpRequestId,
                                                endInvestorId,
                                                statusComment,
                                                status
                                            );
                                            ModalService.getInstance().hideModal();
                                        }}
                                    />
                                )
                                .showModal();
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default OptUpRequests;
