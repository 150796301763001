import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
    SubscriptionAttestations,
    SubscriptionGermanSemiProfessionalCategorisation,
    SubscriptionRequestBody,
    SubscriptionResponse,
    SubscriptionStatusRequestBody,
    Document,
    RepresentationsAndWarranties,
} from '@interfaces/Api';
import {
    handleDeleteSubscription,
    handleGetSubscription,
    handleUpdateSubscription,
    handleCompletenessCheck,
    handleUpdateSubscriptionBeneficialOwnership,
    handleUpdateSubscriptionPoliticalExposure,
    handleUpdateSubscriptionContacts,
    handleUpdateSubscriptionTaxInformation,
    handleUpdateSubscriptionSourceOfWealth,
    handleUpdateSubscriptionDistributionOfFunds,
    handleUpdateSubscriptionDistributorDetails,
    handleUpdateSubscriptionAttestations,
    handleUpdateSubscriptionGermanSemiProfessional,
    handleCreateSubscriptionStatus,
    handleUpdateManualSignedSubscriptionAgreement,
    handleUpdateSubscriptionSupportingDocuments,
    handleUpdateRepresentationsAndWarranties,
    handleComplianceRejection,
    handleUpdateSubscriptionTypeOfInvestment,
    handleUpdateSubscriptionEconomicOriginOfMonies,
    SubscriptionStatusPut,
    SubscriptionsGetAll,
    retryDocumentPackUpload,
} from './Subscriptions.services';
import { SubscriptionCompletenessCheckRequestBody } from '@interfaces/Api/SubscriptionCompletenessCheckRequestBody';
import { ComplianceRejectionRequestBody } from '@interfaces/Api/ComplianceRejectionRequestBody';

type SubscriptionUpdateRequestPaylod = {
    subscriptionId: string;
    body: Partial<SubscriptionRequestBody>;
};

interface SubscriptionsStore {
    isLoading: boolean;
    subscriptions: SubscriptionResponse[];
    subscription: SubscriptionResponse;
    actions: {
        deleteSubscription: (subscriptionIdToDelete: string) => Promise<void>;
        fetchSubscriptions: (endInvestorProfileId?: string) => Promise<void>;
        getSubscription: (subscriptionId: string) => Promise<void>;
        resetSubscriptions: () => void;
        resetSubscription: () => void;
        updateSubscription: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body:
                | SubscriptionStatusRequestBody
                | Partial<SubscriptionRequestBody>;
        }) => Promise<void>;
        updateSubscriptionBeneficialOwnership: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: Partial<SubscriptionRequestBody>;
        }) => Promise<void>;
        updateSubscriptionPoliticalExposure: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionStatus: ({
            body,
            subscriptionId,
        }: {
            subscriptionId: string;
            body: SubscriptionStatusRequestBody;
        }) => Promise<void>;
        complianceRejection: ({
            body,
            subscriptionId,
        }: {
            subscriptionId: string;
            body: ComplianceRejectionRequestBody;
        }) => Promise<void>;
        subscriptionCompletenessCheck: ({
            body,
            subscriptionId,
        }: {
            subscriptionId: string;
            body: SubscriptionCompletenessCheckRequestBody;
        }) => Promise<void>;
        retryDocumentPackUpload: ({
            subscriptionId,
        }: {
            subscriptionId: string;
        }) => Promise<void>;
        updateSubscriptionSupportingDocuments: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionContacts: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionTaxInformation: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionSourceOfWealth: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateTypeOfInvestment: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateEconomicOriginOfMonies: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionDistributionOfFunds: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionDistributorDetails: ({
            subscriptionId,
            body,
        }: SubscriptionUpdateRequestPaylod) => Promise<void>;
        updateSubscriptionAttestations: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionAttestations;
        }) => Promise<void>;
        updateRepresentationsAndWarranties: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: RepresentationsAndWarranties;
        }) => Promise<void>;
        updateSubscriptionGermanSemiProfessional: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionGermanSemiProfessionalCategorisation;
        }) => Promise<void>;
        createSubscriptionStatus: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: SubscriptionStatusRequestBody;
        }) => Promise<void>;
        updateManualSignedSubscriptionAgreement: ({
            subscriptionId,
            body,
        }: {
            subscriptionId: string;
            body: Document;
        }) => Promise<void>;
    };
}

export const useSubscriptionsStore = create<SubscriptionsStore>()(
    devtools(
        (set, get): SubscriptionsStore => ({
            subscriptions: [],
            subscription: {} as SubscriptionResponse,
            isLoading: false,
            actions: {
                deleteSubscription: async (subscriptionIdToDelete) =>
                    await handleDeleteSubscription(set, subscriptionIdToDelete),
                fetchSubscriptions: async (endInvestorProfileId?: string) => {
                    try {
                        set({ isLoading: true });

                        const subscriptions = await SubscriptionsGetAll(
                            endInvestorProfileId
                        );

                        set({
                            subscriptions,
                        });
                    } catch (err) {
                        console.error('[fetchSubscriptions]', err);
                    } finally {
                        set({ isLoading: false });
                    }
                },
                getSubscription: async (subscriptionId) =>
                    await handleGetSubscription(set, subscriptionId),
                resetSubscriptions: () => set({ subscriptions: [] }),
                resetSubscription: () =>
                    set({ subscription: {} as SubscriptionResponse }),
                updateSubscription: async ({
                    subscriptionId,
                    body,
                }: {
                    subscriptionId: string;
                    body:
                        | SubscriptionStatusRequestBody
                        | Partial<SubscriptionRequestBody>;
                }) =>
                    await handleUpdateSubscription({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionBeneficialOwnership: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionBeneficialOwnership({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionPoliticalExposure: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionPoliticalExposure({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionStatus: async ({ subscriptionId, body }) => {
                    try {
                        const putObj = await SubscriptionStatusPut(
                            subscriptionId,
                            body
                        );
                        set((store) => ({
                            subscription: {
                                ...store.subscription,
                                ...putObj,
                                status: putObj.status,
                                statusComment: putObj.statusComment,
                                acceptedSubscriptionAmount:
                                    putObj.acceptedSubscriptionAmount,
                            },
                        }));
                    } catch (err) {
                        console.error('[updateSubscriptionStatus]', err);
                    }
                },
                complianceRejection: async ({ subscriptionId, body }) =>
                    await handleComplianceRejection({
                        set,
                        subscriptionId,
                        body,
                    }),
                subscriptionCompletenessCheck: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleCompletenessCheck({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                retryDocumentPackUpload: async ({ subscriptionId }) =>
                    await retryDocumentPackUpload({
                        subscriptionId,
                    }),
                updateSubscriptionContacts: async ({ subscriptionId, body }) =>
                    await handleUpdateSubscriptionContacts({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionSupportingDocuments: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionSupportingDocuments({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionTaxInformation: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionTaxInformation({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionSourceOfWealth: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionSourceOfWealth({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateTypeOfInvestment: async ({ subscriptionId, body }) =>
                    await handleUpdateSubscriptionTypeOfInvestment({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateEconomicOriginOfMonies: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionEconomicOriginOfMonies({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionDistributionOfFunds: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionDistributionOfFunds({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionDistributorDetails: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionDistributorDetails({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionAttestations: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionAttestations({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateRepresentationsAndWarranties: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateRepresentationsAndWarranties({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                updateSubscriptionGermanSemiProfessional: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateSubscriptionGermanSemiProfessional({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
                createSubscriptionStatus: async ({ subscriptionId, body }) =>
                    await handleCreateSubscriptionStatus({
                        set,
                        subscriptionId,
                        body,
                    }),
                updateManualSignedSubscriptionAgreement: async ({
                    subscriptionId,
                    body,
                }) =>
                    await handleUpdateManualSignedSubscriptionAgreement({
                        get,
                        set,
                        subscriptionId,
                        body,
                    }),
            },
        })
    )
);
