import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import {
    SubscriptionNotice,
    NoticeType,
    noticeTypeLabels,
} from '@interfaces/Api';

import { SubscriptionNoticePut } from '@stores/Subscriptions/Subscriptions.services';

import { Button } from '@components/Atoms';
import { Modal, FormInputBox, TextAreaBox } from '@components/Molecules';
import { FileUploadAsync } from '@components/Organisms';
import getFormikSubscribelabels from '@containers/Subscriptions/ModelSubscriptions';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useSubscriptionsActions } from '@stores/Subscriptions';

const SubscriptionNoticeManagement: React.FC = () => {
    const { t } = useTranslation();
    const labelDistributionNotice = 'Distribution Amount';

    const { subscription } = useSubscription();
    const { getSubscription } = useSubscriptionsActions();
    const { subscriptionId } = useParams();
    const { formField } = getFormikSubscribelabels(t);

    const [isCapitalCall, setIsCapitalCall] = useState(true);

    const initialValues: SubscriptionNotice = {
        amount: 0,
        noticeType: isCapitalCall
            ? NoticeType.CapitalCall
            : NoticeType.Distribution || undefined,
        documents: [],
        comment: '',
    };

    const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>(initialValues);

    const getTotalCapitalCallAmt = (): number => {
        const amt =
            subscription.notices &&
            subscription.notices.reduce((sum, obj) => sum + obj['amount'], 0);
        return amt || 0;
    };

    const remain =
        (subscription.acceptedSubscriptionAmount || 0) -
        getTotalCapitalCallAmt();

    const decideCapitalCall = isCapitalCall
        ? {
              [formField.notice.amount.name]: Yup.number()
                  .min(0.0001, 'Number should be more than zero')
                  .max(
                      remain,
                      `The amount should be less than the total uncalled commitment of ${getFormattedCurrency(
                          remain,
                          subscription?.shareClass?.currency
                      )}`
                  )
                  .required(),
          }
        : {
              [formField.notice.amount.name]: Yup.number()
                  .min(0.0001, 'Number should be more than zero')
                  .max(
                      Number.MAX_SAFE_INTEGER,
                      'Limit of distribution notice exceeded!'
                  )
                  .required(),
          };

    const selectedValidationSchema = Yup.object().shape({
        [formField.notice.noticeType.name]: Yup.string(),
        [formField.notice.comment.name]: Yup.string().trim().required(
            `${formField.notice.comment.requiredErrorMsg}`
        ),
        documents: Yup.array()
            .min(1, 'Please upload at least one document')
            .required(`${formField.notice.documents.title.requiredErrorMsg}`),
        ...decideCapitalCall,
    });

    useEffect(() => {
        if (subscriptionId && subscription) {
            setFormValues(subscription);
        }
    }, [subscription, subscriptionId]);

    const hideModal = () => setShowNoticeModal(false);

    const closeModalOnSubmit = async () => setShowNoticeModal(false);

    const onSubmit = async (
        values: SubscriptionNotice,
        { setSubmitting, resetForm }: FormikHelpers<SubscriptionNotice>
    ) => {
        const notices = subscription.notices || [];

        setSubmitting(true);

        const updateNotice = {
            func: SubscriptionNoticePut,
            successMessage:
                'Subscription Notice has been updated and saved successfully!',
            failMessage: 'Failed to submit data!',
        };

        updateNotice
            .func([...notices, values], subscription._id as string)
            .then(async (result: any) => {
                await getSubscription(subscription._id);
                notifySuccess(updateNotice.successMessage);
            })
            .catch((e) => {
                notifyError(updateNotice.failMessage);
            })
            .finally(() => {
                if (!subscription._id) {
                    resetForm();
                }
                setSubmitting(false);
                setTimeout(closeModalOnSubmit, 300);
                // navigate(0);
            });
    };

    return (
        <div className="w-full py-4 fixed bottom-16 md:bottom-0 left-0 right-0 overflow-visible">
            <div className="text-sm md:text-xl mx-auto float-right">
                <Button
                    className="mx-1 md:mx-2 md:border-1 md:border-white bg-logo-blue-light"
                    onClick={() => {
                        setIsCapitalCall(true);
                        setShowNoticeModal(true);
                    }}
                    label="Raise Capital Call Notice"
                />
                <Button
                    className="mx-1 md:mx-2 md:border-1 md:border-white bg-logo-blue-light"
                    onClick={() => {
                        setIsCapitalCall(false);
                        setShowNoticeModal(true);
                    }}
                    label="Raise Distribution Notice"
                />
            </div>

            <Modal
                show={showNoticeModal}
                onBackdropClick={hideModal}
                width="w-2/5"
            >
                <Modal.Header onClose={hideModal}>
                    <h2 className="text-2xl">
                        Raise a{' '}
                        {isCapitalCall
                            ? noticeTypeLabels[NoticeType.CapitalCall]
                            : noticeTypeLabels[NoticeType.Distribution]}
                    </h2>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full mx-auto">
                        {subscriptionId && formValues._id && (
                            <Formik
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                enableReinitialize={true}
                                validationSchema={selectedValidationSchema}
                            >
                                {({
                                    values,
                                    isValid,
                                    isSubmitting,
                                    dirty,
                                    ...actions
                                }) => (
                                    <div className="mb-3" id="notice-form">
                                        <div className="my-4">
                                            <FormInputBox
                                                name={
                                                    formField.notice.amount.name
                                                }
                                                type="number"
                                                label={
                                                    isCapitalCall
                                                        ? formField.notice
                                                              .amount.label
                                                        : labelDistributionNotice
                                                }
                                                placeholder={`Enter the ${
                                                    isCapitalCall
                                                        ? formField.notice
                                                              .amount.label
                                                        : labelDistributionNotice
                                                }.`}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="my-4">
                                            <TextAreaBox
                                                name={
                                                    formField.notice.comment
                                                        .name
                                                }
                                                label={
                                                    formField.notice.comment
                                                        .label
                                                }
                                                rows={4}
                                                placeholder={'Your Comment'}
                                            />
                                        </div>
                                        <FileUploadAsync
                                            name="documents"
                                            hasLabel={true}
                                            disabled={isSubmitting}
                                        ></FileUploadAsync>

                                        <div className="my-4 p-2 flex justify-between">
                                            <Button
                                                onClick={hideModal}
                                                buttonType="secondary"
                                                label="Cancel"
                                            ></Button>
                                            <Button
                                                type="submit"
                                                label="Submit"
                                                disabled={
                                                    isSubmitting ||
                                                    !(isValid && dirty)
                                                }
                                                onClick={(
                                                    e: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    e.preventDefault();
                                                    if (isValid) {
                                                        onSubmit(
                                                            values,
                                                            actions
                                                        );
                                                    }
                                                }}
                                            ></Button>
                                        </div>
                                    </div>
                                )}
                            </Formik>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SubscriptionNoticeManagement;
