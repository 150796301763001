import { sourceOfFundsGet } from '@api/SourceOfFunds';
import { SubscriptionResponse } from '@interfaces/Api';
import { ClientEnum } from 'common';

export const subscriptionsHelperValidation = (
    client: ClientEnum | null,
    subscription: SubscriptionResponse
) => {
    switch (client) {
        case ClientEnum.ODDO:
            return {
                attestations: !!(
                    (subscription?.attestations?.readAllDocuments &&
                        subscription?.attestations?.agreedElectronicDelivery) ||
                    (subscription?.attestations?.hasReviewedProspectus &&
                        subscription.attestations.requestNotGuaranteed)
                ),
            };
        default:
            return {
                attestations: !!(
                    (subscription?.attestations?.understandsEnglish &&
                        subscription?.attestations?.readAllDocuments &&
                        subscription?.attestations?.agreedElectronicDelivery) ||
                    (subscription?.attestations?.hasReviewedProspectus &&
                        subscription.attestations.requestNotGuaranteed)
                ),
                sourceOfFunds: !!(
                    subscription?.sourceOfFundsAndWealth
                        ?.sourceOfFundsOriginType &&
                    subscription?.sourceOfFundsAndWealth
                        ?.sourceOfWealthOriginType &&
                    subscription?.sourceOfFundsAndWealth
                        ?.sourceOfFundsOriginCountry
                ),
            };
    }
};
