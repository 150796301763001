import { getImage } from '@api/Documents';
import { useStorageTokenState } from '@contexts/StorageTokenContext';
import { ReactEventHandler, useEffect, useState } from 'react';
interface ImageRenderProps {
    src: string;
    alt?: string;
    width?: any;
    height?: any;
    className?: string;
    onError?: ReactEventHandler | undefined;
}
export default function ImageRender({
    src,
    alt,
    width = 150,
    height = 100,
    className = '',
    onError,
}: ImageRenderProps) {
    const [imageSrc, setImageSrc] = useState('');
    const { withToken } = useStorageTokenState();

    useEffect(() => {
        (async () => {
            try {
                if (src.startsWith('http')) {
                    const url = withToken(src);
                    const blob = await getImage(url);
                    const objUrl = URL.createObjectURL(blob);
                    setImageSrc(objUrl);
                } else {
                    setImageSrc(src);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [src, withToken]);

    useEffect(() => {
        // cleanup
        return () => URL.revokeObjectURL(imageSrc);
    }, [imageSrc]);

    return (
        <img
            src={imageSrc}
            alt={alt}
            width={width}
            height={height}
            className={className}
            onError={onError}
        />
    );
}
