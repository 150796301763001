import { DataReview } from '@components/Molecules';
import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const LoginAccountReview = () => {
    const { data } = useRegisterInvestorState();
    const { t } = useTranslation();

    return (
        <>
            <p className="text-base py-3">
                {t('client.registration.login_account_review.title')}:
            </p>
            {data?.systemUsers &&
                data?.systemUsers.length > 0 &&
                data?.systemUsers.map((systemUser, i) => (
                    <DataReview key={systemUser?._id + i}>
                        <p className="text-sm">
                            <span className="font-bold mr-2">
                                {t(
                                    'client.registration.login_account_review.first_name'
                                )}
                                :
                            </span>
                            {systemUser?.firstName}
                        </p>
                        <p className="text-sm">
                            <span className="font-bold mr-2">
                                {' '}
                                {t(
                                    'client.registration.login_account_review.last_name'
                                )}
                                :
                            </span>
                            {systemUser.surname}
                        </p>
                        <p className="text-sm">
                            <span className="font-bold mr-2">
                                {t(
                                    'client.registration.login_account_review.email_address'
                                )}
                                :
                            </span>
                            {systemUser.email}
                        </p>
                    </DataReview>
                ))}
        </>
    );
};
