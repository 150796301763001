import { Button } from '@components/Atoms';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import { startsWith } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface VerifyInvestorDetailsProps {
    endInvestorProfile: EndInvestorProfileResponse;
    onEditInvestorDetails(): void;
    onAccept(): void;
}

export const VerifyInvestorDetails: FC<VerifyInvestorDetailsProps> = ({
    endInvestorProfile,
    onEditInvestorDetails,
    onAccept,
}) => {
    const { t } = useTranslation();

    const shouldAddPlus =
        startsWith(
            '+',
            endInvestorProfile.individualDetails.phoneDiallingCode
        ) || '+';
    const phoneNumber = `${shouldAddPlus}${endInvestorProfile.individualDetails.phoneDiallingCode} ${endInvestorProfile.individualDetails.phoneNumber}`;

    return (
        <div>
            <div>
                {t('client.verify_investor_details.name_text', {
                    endInvestorProfileName:
                        getEndInvestorName(endInvestorProfile),
                })}
            </div>
            <div className="mt-4">
                {t('client.verify_investor_details.email_text', {
                    endInvestorProfileEmail:
                        endInvestorProfile.individualDetails.emailAddress,
                })}
            </div>
            <div className="mt-4">
                {t('client.verify_investor_details.phone_text', {
                    endInvestorProfilePhone: phoneNumber,
                })}
            </div>
            <div className="mt-4">
                {t('client.verify_investor_details.continue_text')}
            </div>
            <div className="mt-4">
                {t('client.verify_investor_details.communication_text', {})}
            </div>

            <div className="mt-8 flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={onEditInvestorDetails}
                    label={t(
                        'client.verify_investor_details.button.editInvestor'
                    )}
                />

                <Button
                    label={t('client.verify_investor_details.button.confirm')}
                    onClick={onAccept}
                />
            </div>
        </div>
    );
};
