import { IndividualDetails } from './IndividualDetails';
import { BusinessEntityDetails } from './BusinessEntityDetails';
import { BookingCentreResponse } from './BookingCentreResponse';
import { KycDocument } from '@interfaces/InterfaceFormsClientProps';
import { SubscriptionResponse } from './SubscriptionResponse';
import { SystemUserResponse } from './SystemUserResponse';
import { AdditionalInfo } from 'common';
import { TFunction } from 'react-i18next';
import { ExternalReference } from './ExternalReference';

/**
 *
 * @export
 * @interface EndInvestorProfileResponse
 */

export type EndInvestorProfileResponse = {
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    _id: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    slug: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    adviserSystemUserId: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    intermediaryId: string;
    /**
     *
     * @type {BookingCentreResponse}
     * @memberof EndInvestorProfileResponse
     */
    bookingCentreIds?: BookingCentreResponse[];
    /**
     * Service level
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    serviceLevelType: EndInvestorProfileResponse.ServiceLevelTypeEnum;
    /**
     * Subscription process
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    subscriptionProcessType?: EndInvestorProfileResponse.SubscriptionProcessTypeEnum;
    /**
     * Alpha-2 two letter country code
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    onboardingJurisdictionCountryCode?: string;
    /**
     *
     * @type {string}
     * @memberof EndInvestorProfileResponse
     */
    clientType?: string;
    /**
     *
     * @type {boolean}
     * @memberof EndInvestorProfileResponse
     */
    isKycCompliant?: boolean;
    /**
     *
     * @type {Array<Document>}
     * @memberof EndInvestorProfileResponse
     */
    kycDocuments?: Array<KycDocument>;
    /**
     *
     * @type {EndInvestorType}
     * @memberof EndInvestorProfileRequestBody
     */
    endInvestorType?: EndInvestorProfileResponse.EndInvestorType;
    /**
     *
     * @type {IndividualDetails}
     * @memberof EndInvestorProfileResponse
     */
    nomineeEndInvestorProfileId?: string;
    /**
     *
     * @type {IndividualDetails}
     * @memberof EndInvestorProfileResponse
     */
    individualDetails?: IndividualDetails;
    /**
     *
     * @type {BusinessEntityDetails}
     * @memberof EndInvestorProfileResponse
     */
    businessEntityDetails?: BusinessEntityDetails;
    /**
     *
     * @type {Partial<SystemUserResponse>}
     * @memberof EndInvestorProfileRequestBody
     */
    systemUsers?: Partial<SystemUserResponse>[];
    /**
     *
     * @type {Partial<SystemUserResponse>}
     * @memberof EndInvestorProfileRequestBody
     */
    profileId?: string;

    additionalInfo?: AdditionalInfo;

    externalReferences?: ExternalReference[];
};

/**
 * @export
 * @namespace EndInvestorProfileResponse
 */

export namespace EndInvestorProfileResponse {
    /**
     * @export
     * @enum {string}
     */
    export enum ServiceLevelTypeEnum {
        ExecutionOnly = 'executionOnly',
        Advisory = 'advisory',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SubscriptionProcessTypeEnum {
        Direct = 'direct',
        OnBehalf = 'onBehalf',
        Nominee = 'nominee',
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EndInvestorType {
        individual = 'individual',
        businessEntity = 'businessEntity',
        nomineeEntity = 'nomineeEntity',
    }
}

export const getEndInvestorTypeLabels = (
    t: TFunction<'translation', undefined>,
    endInvestorType: string
): string => {
    return {
        [EndInvestorProfileResponse.EndInvestorType.individual]: t(
            'end_investor_type.individual'
        ),
        [EndInvestorProfileResponse.EndInvestorType.businessEntity]: t(
            'end_investor_type.business_entity'
        ),
        [EndInvestorProfileResponse.EndInvestorType.nomineeEntity]: t(
            'end_investor_type.nominee_entity'
        ),
    }[endInvestorType];
};

export const getSubscriptionProcessTypeLabels = (
    t: TFunction<'translation', undefined>,
    subscriptionProcessType: string
): string => {
    return {
        [EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct]: t(
            'subscription_process_type.direct'
        ),
        [EndInvestorProfileResponse.SubscriptionProcessTypeEnum.OnBehalf]: t(
            'subscription_process_type.on_behalf'
        ),
        [EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Nominee]: t(
            'subscription_process_type.nominee'
        ),
    }[subscriptionProcessType];
};

export const getServiceLevelTypeLabels = (
    t: TFunction<'translation', undefined>,
    serviceLevelType: string
): string => {
    return {
        [EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly]: t(
            'service_level_type.execution_only'
        ),
        [EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory]: t(
            'service_level_type.advisory'
        ),
    }[serviceLevelType];
};

export const endInvestorTypeLabelMap: {
    [key in EndInvestorProfileResponse.EndInvestorType]: string;
} = {
    individual: 'Individual Investor',
    businessEntity: 'Business Entity',
    nomineeEntity: 'Nominee Entity',
};

export interface EndInvestorSubscriptionsProfile
    extends EndInvestorProfileResponse {
    subscriptions: SubscriptionResponse[];
}
