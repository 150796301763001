/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CSSProperties, ReactNode } from 'react';
import { NotificationService } from './NotificationService';
import { t } from 'i18next';

export const UPDATE_MODAL = 'UPDATE_MODAL';

export interface ModalInterface {
    title: string;
    cancelLabel: string;
    confirmLabel: string;
    show: boolean;
    showFooter: boolean;
    cancelCallback(): void;
    confirmationCallback(): void;
    component: ReactNode;
    width: string;
    description: string;
    clickOutsideToClose: boolean;
    showHeader: boolean;
    centered: boolean;
    footerJustifyContent?: string;
    bodyClassName?: string;
    bodyStyle?: CSSProperties;
    hideCancelBtn?: boolean;
}

const instanceMap: Record<string, ModalService> = {};

export class ModalService implements ModalInterface {
    private static instance: ModalService;

    public title = '';

    public description = '';

    public cancelLabel = t('ui.controls.cancel');

    public confirmLabel = t('ui.controls.submit');

    public width = '';

    public show = false;

    public showFooter = false;

    public clickOutsideToClose = false;

    public showHeader = true;

    public centered = false;

    public hideCancelBtn = false;

    public cancelCallback = () => {};

    public confirmationCallback = () => {};

    public component: any;

    public prefix: string;

    public footerJustifyContent = 'space-between';

    public bodyClassName = '';

    public bodyStyle?: CSSProperties;

    constructor(prefix: string) {
        this.prefix = prefix;
    }

    public static getInstance(prefix = 'main'): ModalService {
        if (!instanceMap[prefix]) {
            instanceMap[prefix] = new ModalService(prefix);
        }

        return instanceMap[prefix];
    }

    setTitle(title: string): ModalService {
        this.title = title;
        return this;
    }

    setDescription(description: string): ModalService {
        this.description = description;
        return this;
    }

    setClickOutsideToClose(close: boolean): ModalService {
        this.clickOutsideToClose = close;
        return this;
    }

    setShowFooter(showFooter: boolean): ModalService {
        this.showFooter = showFooter;
        return this;
    }

    setFooterJustifyContent(justifyContent: string): ModalService {
        this.footerJustifyContent = justifyContent;
        return this;
    }

    setShowHeader(showHeader: boolean): ModalService {
        this.showHeader = showHeader;
        return this;
    }

    setCentered(centered: boolean): ModalService {
        this.centered = centered;
        return this;
    }

    setComponent(component: ReactNode): ModalService {
        this.component = component;
        return this;
    }

    setCancelCallback(callback: any): ModalService {
        this.cancelCallback = callback;
        return this;
    }

    setConfirmCallback(callback: any): ModalService {
        this.confirmationCallback = callback;
        return this;
    }

    setCancelLabel(cancelLabel: string): ModalService {
        this.cancelLabel = cancelLabel;
        return this;
    }

    setConfirmationLabel(confirmLabel: string): ModalService {
        this.confirmLabel = confirmLabel;
        return this;
    }

    setWidth(width: string): ModalService {
        this.width = width;
        return this;
    }

    setBodyClassName(className: string): ModalService {
        this.bodyClassName = className;
        return this;
    }

    setBodyStyle(style: CSSProperties): ModalService {
        this.bodyStyle = style;
        return this;
    }

    setHideCancelBtn(hide: boolean): ModalService {
        this.hideCancelBtn = hide;
        return this;
    }

    showModal(): ModalService {
        this.show = true;
        this.emitUpdates();
        return this;
    }

    hideModal(): ModalService {
        this.initModal();
        this.emitUpdates();
        return this;
    }

    initModal(): ModalService {
        this.title = '';
        this.cancelLabel = 'Cancel';
        this.confirmLabel = 'Submit';
        this.width = '';
        this.show = false;
        this.showFooter = false;
        this.cancelCallback = () => {};
        this.confirmationCallback = () => {};
        this.component = '';
        // this.prefix = '';
        this.clickOutsideToClose = false;
        this.centered = false;
        this.showHeader = true;
        this.footerJustifyContent = 'space-between';
        this.bodyClassName = '';
        this.bodyStyle = undefined;
        this.hideCancelBtn = false;

        return this;
    }

    emitUpdates(): ModalService {
        const {
            title,
            cancelLabel,
            confirmLabel,
            show,
            showFooter,
            cancelCallback,
            confirmationCallback,
            component,
            width,
            description,
            centered,
            clickOutsideToClose,
            showHeader,
            footerJustifyContent,
            bodyClassName,
            bodyStyle,
            hideCancelBtn,
        } = this;

        NotificationService.pub(`${this.prefix}${UPDATE_MODAL}`, {
            title,
            cancelLabel,
            confirmLabel,
            show,
            showFooter,
            cancelCallback,
            confirmationCallback,
            component,
            width,
            description,
            centered,
            clickOutsideToClose,
            showHeader,
            footerJustifyContent,
            bodyClassName,
            bodyStyle,
            hideCancelBtn,
        });

        return this;
    }
}
