import {
    NoGIINReasonTypeEnum,
    crsFinancialInstitutionTypeLabelOptions,
    fatcaFinancialInstitutionTypeLabelOptions,
    noGIINReasonTypeLabelOptions,
} from '@interfaces/Api';
import { useTranslation } from 'react-i18next';
import { FormCheckbox, FormInputBox, FormSelect } from '@components/Molecules';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { FileUploadAsync } from '@components/Organisms';
import {
    exemptBeneficialOwnerTypesLabelOptions,
    noGIINSponsorLabelOptions,
} from '@interfaces/Api/TaxInformation';
import { ExemptBeneficialOwnerTypes } from 'common';
import { getTranslatedOptions } from './TaxDeclarations';

export const RenderFinancialFields = ({ formikProps, areInputsDisabled }) => {
    const { t } = useTranslation();
    return (
        <>
            <h3 className="my-2 mt-8 text-xl font-normal leading-8">
                Financial Institutions
            </h3>
            <FormCheckbox
                name="entity.fatca.entityTypeWithInvestmentComponent"
                actionLabel={t(
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fatca_classification_checkbox'
                )}
            />
            <FormSelect
                id="entity.fatca.fatcaFinancialInstitutionType"
                name="entity.fatca.fatcaFinancialInstitutionType"
                label={
                    t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_giin_institution_type'
                    ) + '*'
                }
                optionsData={getTranslatedOptions(
                    t,
                    fatcaFinancialInstitutionTypeLabelOptions,
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_giin_institution_type.select_option'
                )}
                disabled={areInputsDisabled}
            />
            <FormInputBox
                id="entity.fatca.GIIN"
                name="entity.fatca.GIIN"
                label={t(
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_giin'
                )}
                disabled={areInputsDisabled}
            />

            <p className="py-1 text-base">
                {t(
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_no_giin_title'
                )}
            </p>
            <FormSelect
                id="entity.fatca.noGIINReasonType"
                name="entity.fatca.noGIINReasonType"
                label={
                    t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_no_giin'
                    ) + (!formikProps.values.entity?.fatca?.GIIN ? '*' : '')
                }
                optionsData={getTranslatedOptions(
                    t,
                    noGIINReasonTypeLabelOptions,
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.fi_no_giin.select_option'
                )}
                disabled={areInputsDisabled}
            />
            {formikProps.values.entity?.fatca?.noGIINReasonType ===
                NoGIINReasonTypeEnum.notYetObtainedButHasSponsor && (
                <>
                    <FormInputBox
                        id="entity.fatca.sponsorsGIIN"
                        name="entity.fatca.sponsorsGIIN"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.sponsors_giin'
                        )}
                        disabled={areInputsDisabled}
                    />
                    <FormInputBox
                        id="entity.fatca.sponsorsName"
                        name="entity.fatca.sponsorsName"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.sponsors_name'
                        )}
                        disabled={areInputsDisabled}
                    />
                    <FormSelect
                        id="entity.fatca.noGIINSponsorType"
                        name="entity.fatca.noGIINSponsorType"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.sponsors_entity'
                        )}
                        optionsData={getTranslatedOptions(
                            t,
                            noGIINSponsorLabelOptions,
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.sponsors_entity.select_option'
                        )}
                        disabled={areInputsDisabled}
                    />
                </>
            )}
            {formikProps.values.entity?.fatca?.noGIINReasonType ===
                NoGIINReasonTypeEnum.exemptBeneficialOwner && (
                <>
                    <FormSelect
                        id="entity.fatca.exemptBeneficialOwnerType"
                        name="entity.fatca.exemptBeneficialOwnerType"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.exempt_beneficial_owner'
                        )}
                        optionsData={getTranslatedOptions(
                            t,
                            exemptBeneficialOwnerTypesLabelOptions,
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.exempt_beneficial_owner.select_option'
                        )}
                        disabled={areInputsDisabled}
                    />
                    {formikProps.values.entity?.fatca
                        .exemptBeneficialOwnerType ===
                        ExemptBeneficialOwnerTypes.other && (
                        <>
                            <h2>
                                {t(
                                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.exempt_beneficial_owner_other'
                                )}
                                <a href="https://www.irs.gov">www.irs.gov</a>
                            </h2>
                            <FileUploadAsync name="entity.fatca.w8Document" />
                        </>
                    )}
                </>
            )}
            {formikProps.values.entity?.fatca?.noGIINReasonType ===
                NoGIINReasonTypeEnum.noeReportingIGAForeignForeignFinancialInstitution && (
                <>
                    <FormSelect
                        id="entity.fatca.igaCountryCode"
                        name="entity.fatca.igaCountryCode"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.iga_country_select'
                        )}
                        optionsData={isoCountriesOptions}
                        disabled={areInputsDisabled}
                    />
                    <FormInputBox
                        name="entity.fatca.nonReportingCategory"
                        label={t(
                            'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.non_reporting_category_input'
                        )}
                        disabled={areInputsDisabled}
                    />
                </>
            )}
            <FormSelect
                name="entity.crs.financialInstitutionType"
                label={
                    t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.fi_entity_select'
                    ) + '*'
                }
                optionsData={getTranslatedOptions(
                    t,
                    crsFinancialInstitutionTypeLabelOptions,
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.fi_entity.select_option'
                )}
                disabled={areInputsDisabled}
            />
        </>
    );
};
