import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Link,
    useNavigate,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import { AuthPermissionEnum, ProductResponse } from '@interfaces/Api';

import { DeleteProduct } from '@api/Products';

import { useProductsActions } from '@stores/Products';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { Button, Icons, InputBox, Tooltip } from '@components/Atoms';
import { Modal, PillSelector, PillOption } from '@components/Molecules';

import ProductCardAdmin from './ProductCardAdmin';
import {
    getUmbrellaFundNameAndProductName,
    handleProductsFiltering,
} from '@helpers/Product.helper';
import Highlighter from 'react-highlight-words';
import { AvailabilityStatusLabel } from '@interfaces/Api/ProductResponse';
import { exportJsonData } from '@helpers/File.helper';
import { useAuthState } from '@contexts/AuthContext';
import { ModalService } from '@services/ModalService';
import { UploadProduct } from './UploadProduct';
import { SortableList } from '@components/Organisms';
import { ProductPriorities } from '@interfaces/Api/ProductPriorities';
import { useTranslation } from 'react-i18next';

export interface ProductListEditProps {
    items: Array<ProductResponse>;
    currentView: string;
    onToggleCurrentView: any;
    searchItem?: any;
}

type ProductPillType = 'published' | 'draft' | 'all';

const ProductListGrid: React.FC<ProductListEditProps> = (
    props: ProductListEditProps
) => {
    const { t } = useTranslation();
    const isDetailedView = props.currentView === 'grid';

    const containerRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const { fetchProducts: getProductData, prioritiseProducts } =
        useProductsActions();
    const { hasPermissions } = useAuthState();
    const navigate = useNavigate();
    const location = useLocation();

    const [productSearch, setProductSearch] = useState<string>(
        props.searchItem
    );
    const [productsFound, setProductsFound] = useState<ProductResponse[]>([]);
    const [selectedItem, setSelectedItem] = useState<string>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [productStatus, setProductStatus] = useState(
        searchParams.get('productStatus') || 'all'
    );
    const [isPrioritising, setIsPrioritising] = useState<boolean>(false);
    const [productsFoundCopy, setProductsFoundCopy] = useState<
        ProductResponse[]
    >([]);

    const prodNotifyMeta = {
        successMessage: t('admin.product.product_has_been_deleted'),
        failMessage: t('admin.product.product_delete_fail'),
    };

    const pillOptions: PillOption<ProductPillType>[] = [
        {
            label: t('admin.product.published'),
            value: 'published',
        },
        {
            label: t('admin.product.draft'),
            value: 'draft',
        },
        {
            label: t('admin.product.all'),
            value: 'all',
        },
    ];

    const handleProductPillSelectorChange = (productStatus: string) => {
        navigate({
            pathname: '/admin/products/',
            search: `productStatus=${productStatus}`,
        });
        setProductStatus(productStatus);
    };

    useEffect(() => {
        const pathname = location.pathname;
        window.localStorage.setItem('pageUrl', JSON.stringify(pathname));

        const allListedProducts = props.items?.filter((val) =>
            handleProductsFiltering(val, productSearch)
        );

        setProductsFound(allListedProducts || []);
    }, [props.items, productSearch, location]);

    const refresh = async () => {
        stopPrioritising();
        await getProductData();
        notifySuccess(t('admin.product.product_list_has_been_refreshed'));
    };

    const prioritise = async () => {
        setProductsFoundCopy(productsFound);
        setIsPrioritising(true);
    };

    const deleteProductItem = async (productId: string) => {
        try {
            await DeleteProduct(productId);
        } catch (err) {
            notifyError(prodNotifyMeta.failMessage);
        }
    };

    const hideModal = () => setShowDeleteModal(false);

    const closeModalOnDelete = async () => {
        setShowDeleteModal(false);
        if (selectedItem !== 'undefined') {
            notifySuccess(prodNotifyMeta.successMessage);
            setTimeout(() => refresh(), 2000);
        }
    };

    const findFirstElementInViewPort = (elements: any): any => {
        Array.prototype.find.call(
            elements,
            (element) => element.getBoundingClientRect().y >= 85
        );
    };

    const scrollTo = useMemo(() => {
        const nodeElements =
            containerRef.current?.querySelectorAll('[data-item]');

        if (nodeElements) {
            return findFirstElementInViewPort(nodeElements);
        }

        return undefined;
    }, []);

    useLayoutEffect(() => {
        if (scrollTo) {
            scrollTo.scrollIntoView();
            window.scrollBy(0, -85);
        }
    }, [scrollTo, props.currentView]);

    const handleImport = useCallback(() => {
        ModalService.getInstance()
            .setTitle(t('admin.product.import_a_product'))
            .setComponent(<UploadProduct />)
            .setShowFooter(false)
            .showModal();
    }, []);

    const filterByProductStatus = (item: ProductResponse) =>
        item?.status?.toLowerCase() === productStatus ||
        productStatus.toLowerCase() === 'all';

    const handleOnSorted = async (sortedItems: ProductResponse[]) => {
        setProductsFoundCopy(sortedItems);
    };

    const handleOnSavePriorities = async () => {
        const productPriorities: ProductPriorities[] = productsFoundCopy.map(
            (item) => ({
                productId: item._id,
                priority: productsFoundCopy.indexOf(item) + 1,
            })
        );

        await prioritiseProducts(productPriorities);
        stopPrioritising();
    };

    const handleCancelPrioritisation = () => {
        stopPrioritising();
    };

    const stopPrioritising = () => {
        setIsPrioritising(false);
        setProductsFoundCopy([]);
    };

    return (
        <div className="h-full pb-9">
            <div className="xs:container xs:mx-auto max-w-full">
                <div className="flex flex-col items-stretch">
                    <div className="flex justify-between w-full mb-5">
                        <h1 className="text-3xl text-logo-blue align-middle font-bold">
                            {t('navbar.admin.product_management')}
                        </h1>
                        <div className="flex justify-end">
                            <Button
                                label={t('admin.product.import_a_product')}
                                onClick={handleImport}
                            />
                            &nbsp;
                            <Button
                                label={t('admin.product.add_a_product')}
                                onClick={() => {
                                    navigate(
                                        '/admin/products/new/product-information'
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="space-x-auto">
                        <div className="flex justify-left w-full items-center mb-4">
                            <PillSelector<ProductPillType>
                                onChange={handleProductPillSelectorChange}
                                options={pillOptions}
                                selectedValue={productStatus}
                            />
                        </div>

                        <div className="flex flex-row flex-wrap justify-between">
                            <div
                                id="search-product"
                                className="flex justify-start w-3/5 md:w-4/5 lg:w-2/5 items-left"
                            >
                                <Formik
                                    initialValues={{ search: '' }}
                                    onSubmit={() => {}}
                                >
                                    <Form
                                        onChange={(val: any) => {
                                            setProductSearch(val.target.value);
                                        }}
                                    >
                                        <InputBox
                                            name="search"
                                            placeholder={t('admin.product.search_for_a_product')}
                                        />
                                    </Form>
                                </Formik>
                            </div>

                            <div className="flex justify-end w-1/5 items-center">
                                {isDetailedView && !isPrioritising ? (
                                    <Button
                                        onClick={prioritise}
                                        hasIconOnly
                                        className="bg-brand-navy-light rounded-md p-2 mr-2 cursor-pointer"
                                        startIcon={
                                            <Icons name="ArrowsUpDownIcon" />
                                        }
                                    />
                                ) : (
                                    isDetailedView && (
                                        <>
                                            <Button
                                                onClick={() =>
                                                    handleOnSavePriorities()
                                                }
                                                hasIconOnly
                                                className="bg-brand-navy-light rounded-md p-2 mr-2 cursor-pointer"
                                                startIcon={
                                                    <Icons
                                                        name="CheckIcon"
                                                        strokeWidth="0"
                                                    />
                                                }
                                            />
                                            <Button
                                                onClick={() =>
                                                    handleCancelPrioritisation()
                                                }
                                                hasIconOnly
                                                className="bg-brand-navy-light rounded-md p-2 mr-2 cursor-pointer"
                                                startIcon={
                                                    <Icons name="XMarkIcon" />
                                                }
                                            />
                                        </>
                                    )
                                )}

                                <Button
                                    onClick={refresh}
                                    hasIconOnly
                                    className="bg-brand-navy-light rounded-md p-2 mr-2 cursor-pointer"
                                    startIcon={<Icons name="ArrowPathIcon" />}
                                />

                                <Button
                                    onClick={props.onToggleCurrentView}
                                    hasIconOnly
                                    className="bg-brand-navy-light rounded-md p-2 cursor-pointer"
                                    startIcon={
                                        <Icons
                                            name={
                                                isDetailedView
                                                    ? 'ViewColumnsIcon'
                                                    : 'Bars3Icon'
                                            }
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="xs:container xs:mx-auto max-w-full w-full mt-5">
                        {isDetailedView && (
                            <div className="flex flex-row flex-wrap bg-brand-navy-light h-10 p-2 align-middle rounded-md">
                                <div className="w-7/12">
                                    <h4 className="text-sm md:text-md font-bold">
                                        {t('product.name')}
                                    </h4>
                                </div>
                                <div className="hidden sm:block w-2/12">
                                    <h4 className="text-sm md:text-md font-bold">
                                        {t('prospective_investor.grid.column_header.status')}
                                    </h4>
                                </div>
                                <div className="w-1/12 md:w-2/12">
                                    <h4 className="text-sm md:text-md font-bold">
                                        {t('admin.product.active_inactive')}
                                    </h4>
                                </div>
                                <div className="w-1/12">
                                    <h4 className="text-sm md:text-md font-bold">
                                        &nbsp;
                                    </h4>
                                </div>
                            </div>
                        )}
                        <div
                            className={classNames('list p-2', {
                                'list-grid': isDetailedView,
                            })}
                            ref={containerRef}
                        >
                            {isDetailedView ? (
                                props.items && props.items.length === 0 ? (
                                    <div className="container mx-auto">
                                        <p className="text-2xl">
                                            <Icons name="ExclamationTriangleIconOutline" />
                                            {t('admin.product.products_missing')}
                                        </p>
                                        <p>
                                            {t('admin.product.no_products')}
                                        </p>
                                    </div>
                                ) : (
                                    <SortableList
                                        onSorted={handleOnSorted}
                                        handlePosition="left"
                                        handleClassName={
                                            isPrioritising
                                                ? `pt-5 pr-4 pb-9 border-b border-gray-200`
                                                : 'hidden'
                                        }
                                        listItems={
                                            !isPrioritising
                                                ? productsFound.filter(
                                                      filterByProductStatus
                                                  )
                                                : productsFoundCopy.filter(
                                                      filterByProductStatus
                                                  )
                                        }
                                        listElements={(!isPrioritising
                                            ? productsFound.sort((a, b) => {
                                                  // sort by priority
                                                  if (
                                                      a.priority === b.priority
                                                  ) {
                                                      return 0;
                                                  }

                                                  return a.priority < b.priority
                                                      ? -1
                                                      : 1;
                                              })
                                            : productsFoundCopy
                                        )
                                            .filter(filterByProductStatus)
                                            .map(
                                                (
                                                    filteredProduct: ProductResponse
                                                ) => (
                                                    <div
                                                        key={
                                                            filteredProduct._id
                                                        }
                                                        className="bg-white border-b my-2 flex flex-1 flex-wrap justify-between"
                                                    >
                                                        <div className="w-7/12 my-2">
                                                            <h4 className="text-md cursor-pointer">
                                                                <Link
                                                                    to={`/products/${filteredProduct.slug}`}
                                                                >
                                                                    <Highlighter
                                                                        searchWords={
                                                                            (
                                                                                productSearch ??
                                                                                ''
                                                                            ).split(
                                                                                ' '
                                                                            ) ?? [
                                                                                '',
                                                                            ]
                                                                        }
                                                                        autoEscape={
                                                                            true
                                                                        }
                                                                        textToHighlight={getUmbrellaFundNameAndProductName(
                                                                            filteredProduct
                                                                        )}
                                                                    />
                                                                </Link>{' '}
                                                                {filteredProduct?.shareClasses
                                                                    .map(
                                                                        (
                                                                            detail
                                                                        ) =>
                                                                            detail.currency
                                                                    )
                                                                    .filter(
                                                                        (
                                                                            val,
                                                                            index,
                                                                            self
                                                                        ) =>
                                                                            self.indexOf(
                                                                                val
                                                                            ) ===
                                                                            index
                                                                    )
                                                                    .map(
                                                                        (
                                                                            val,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                    className="bg-gold-300 text-xs rounded-lg inline-block leading-8 px-2 mx-1"
                                                                                >
                                                                                    {
                                                                                        val
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                            </h4>
                                                            <h6 className="block md:hidden capitalize text-green-600">
                                                                <Highlighter
                                                                    searchWords={
                                                                        (
                                                                            productSearch ??
                                                                            ''
                                                                        ).split(
                                                                            ' '
                                                                        ) ?? [
                                                                            '',
                                                                        ]
                                                                    }
                                                                    autoEscape={
                                                                        true
                                                                    }
                                                                    textToHighlight={
                                                                        filteredProduct.status
                                                                    }
                                                                />
                                                            </h6>
                                                        </div>
                                                        <div className="hidden sm:block w-2/12 my-2 text-sm text-green-600">
                                                            <span className="capitalize">
                                                                <Highlighter
                                                                    searchWords={
                                                                        (
                                                                            productSearch ??
                                                                            ''
                                                                        ).split(
                                                                            ' '
                                                                        ) ?? [
                                                                            '',
                                                                        ]
                                                                    }
                                                                    autoEscape={
                                                                        true
                                                                    }
                                                                    textToHighlight={
                                                                        filteredProduct.status
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="w-1/12 md:w-2/12 my-2 text-sm text-green-600 text-left">
                                                            <p>
                                                                <Highlighter
                                                                    searchWords={
                                                                        (
                                                                            productSearch ??
                                                                            ''
                                                                        ).split(
                                                                            ' '
                                                                        ) ?? [
                                                                            '',
                                                                        ]
                                                                    }
                                                                    autoEscape={
                                                                        true
                                                                    }
                                                                    textToHighlight={
                                                                        AvailabilityStatusLabel[
                                                                            filteredProduct
                                                                                ?.availabilityStatus
                                                                        ] || ''
                                                                    }
                                                                />
                                                            </p>
                                                        </div>
                                                        <div className="w-2/12 md:w-1/12 my-2 text-sm justify-items-end flex flex-col">
                                                            <div className="flex float-right">
                                                                <div className="w-1/2">
                                                                    <Link
                                                                        to={`/admin/products/${filteredProduct._id}`}
                                                                    >
                                                                        <Icons
                                                                            name="PencilIcon"
                                                                            strokeWidth="2"
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <div className="w-1/2">
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedItem(
                                                                                () =>
                                                                                    String(
                                                                                        filteredProduct?._id
                                                                                    )
                                                                            );
                                                                            setShowDeleteModal(
                                                                                true
                                                                            );
                                                                        }}
                                                                        className={`${
                                                                            filteredProduct.status ===
                                                                            'published'
                                                                                ? 'cursor-default'
                                                                                : 'cursor-pointer'
                                                                        }`}
                                                                        disabled={
                                                                            filteredProduct.status ===
                                                                            'published'
                                                                        }
                                                                    >
                                                                        <Icons
                                                                            name="TrashIcon"
                                                                            strokeWidth="1.5"
                                                                            stroke={
                                                                                filteredProduct.status ===
                                                                                'published'
                                                                                    ? 'gray'
                                                                                    : 'black'
                                                                            }
                                                                        />
                                                                    </button>
                                                                </div>
                                                                {hasPermissions(
                                                                    AuthPermissionEnum.exportProduct
                                                                ) && (
                                                                    <div className="w-1/2">
                                                                        <button
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                exportJsonData(
                                                                                    filteredProduct.slug,
                                                                                    filteredProduct,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <Tooltip tip={t('admin.product.export_product')}>
                                                                                <Icons
                                                                                    name="ArrowDownTrayIcon"
                                                                                    strokeWidth="2"
                                                                                />
                                                                            </Tooltip>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    />
                                )
                            ) : (
                                <div className="grid md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 gap-2">
                                    {props.items && props.items.length === 0 ? (
                                        <div className="container mx-auto">
                                            <p className="text-2xl">
                                                {t('admin.product.products_missing')}
                                            </p>
                                            <p>
                                                {t('admin.product.no_products')}
                                            </p>
                                        </div>
                                    ) : (
                                        productsFound
                                            .filter(filterByProductStatus)
                                            .map(
                                                (
                                                    filteredProduct: ProductResponse,
                                                    i: number
                                                ) => (
                                                    <div key={i.toString()}>
                                                        <ProductCardAdmin
                                                            key={i}
                                                            onEditProduct={() => {
                                                                setSelectedItem(
                                                                    filteredProduct?._id
                                                                );
                                                                setShowDeleteModal(
                                                                    true
                                                                );
                                                            }}
                                                            product={
                                                                filteredProduct
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeleteModal} onBackdropClick={hideModal}>
                <Modal.Header onClose={hideModal}>
                    <h2 className="text-2xl">{t('admin.product.delete_products')}</h2>
                </Modal.Header>
                <Modal.Body>
                    {t('admin.product.confirm_to_delete_product')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={hideModal}
                        buttonType="secondary"
                        label={t('ui.controls.cancel')}
                    ></Button>
                    <Button
                        onClick={() => {
                            deleteProductItem(selectedItem!);
                            closeModalOnDelete();
                        }}
                        label={t('system_user.delete')}
                    ></Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ProductListGrid;
