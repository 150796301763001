import {
    useLanguageLocaleState,
    LanguageLocaleType,
} from '@contexts/LanguageSwitcher';
import { Icons } from '@components/Atoms';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { EnvironmentEnum } from '@interfaces/Api/ConfigurationResponse';
import { ClientEnum } from 'common';

const defaultLanguages = [
    LanguageLocaleType.en,
    LanguageLocaleType.de,
    LanguageLocaleType.it,
    LanguageLocaleType.es,
    LanguageLocaleType.fr,
    LanguageLocaleType.ja,
];

export const LanguageSelector = () => {
    const { selectedLanguage, saveSelectedLanguage } = useLanguageLocaleState();
    const { configuration } = useConfigurationState();

    const languages: { [key in ClientEnum]?: string[] } = {
        [ClientEnum.ODDO]: [
            LanguageLocaleType.en,
            LanguageLocaleType.fr,
            LanguageLocaleType.de,
        ],
    };

    const languagesToDisplay =
        languages[configuration?.client] || defaultLanguages;

    if (
        [
            EnvironmentEnum.DEV,
            EnvironmentEnum.LOCAL,
            EnvironmentEnum.UAT,
        ].includes(configuration?.environment)
    ) {
        languagesToDisplay.push(LanguageLocaleType.cimode);
    }

    return (
        <div className="border-b border-gray-200 w-16 pb-1 mr-4 cursor-pointer flex relative">
            <div className="absolute top-2">
                <Icons name="GlobeEuropeAfricaIcon" size="small" />
            </div>
            <select
                value={selectedLanguage}
                className="bg-transparent outline-none border-none cursor-pointer pl-5 z-30 text-xs w-16 pr-2"
                name="language-switcher"
                onChange={(e) =>
                    saveSelectedLanguage(e.target.value as LanguageLocaleType)
                }
            >
                {languagesToDisplay.map((option) => (
                    <option value={option} key={option}>
                        {option === 'ja' ? '日本語' : option.toUpperCase()}
                    </option>
                ))}
            </select>
        </div>
    );
};
