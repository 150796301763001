import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
    ProspectiveInvestorDefaultsGet,
    ProspectiveInvestorDefaultsPut,
} from '@api/ProspectiveInvestorDefaults';
import {
    ProspectiveInvestorDefaultsRequest,
    ProspectiveInvestorDefaultsResponse,
} from '@interfaces/Api/ProspectiveInvestorDefaults';

import { BasicBreadcrumbs, Button, Icons } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { notifySuccess } from '@helpers/toastrHelper';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { getBookingCentres } from '@stores/BookingCentres/BookingCentres.services';
import BookingCentreConfiguration from './BookingCentreConfiguration';

const ProspectiveInvestorDefaultsPage = () => {
    const [prospectiveInvestorDefaults, setProspectiveInvestorDefaults] =
        useState<ProspectiveInvestorDefaultsResponse>();

    const { intermediaries } = useIntermediaries();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        intermediaryId: Yup.string().trim().required('Required'),
        fafIntermediaryId: Yup.string().trim().required('Required'),
    });

    const onCancel = () => {
        navigate('/admin/prospective-investors');
    };

    const onSubmit = (initialValues: ProspectiveInvestorDefaultsRequest) => {
        // filter out any empty booking centres
        initialValues.bookingCentres = initialValues.bookingCentres.filter(
            (a) => a.countryCode && a.bookingCentreId
        );

        ProspectiveInvestorDefaultsPut(initialValues).then((a) => {
            setProspectiveInvestorDefaults(a);
            notifySuccess('Prospective investor defaults saved');
        });
    };

    const reloadBookingCentres = async (intermediaryId: string) => {
        const bookingCentres = getBookingCentres(intermediaryId);
        // Do we set state here? or in the response function?
        return bookingCentres;
    };

    useEffect(() => {
        ProspectiveInvestorDefaultsGet().then((a) => {
            if (a) {
                // TODO: make sure that this is updated when the endpoint and models are updated
                setProspectiveInvestorDefaults(a);
                if (a.intermediaryId) {
                    reloadBookingCentres(a.intermediaryId);
                }
            }
        });
    }, []);

    const initialValues = {
        intermediaryId: prospectiveInvestorDefaults?.intermediaryId || '',
        bookingCentreId: prospectiveInvestorDefaults?.bookingCentreId || '',
        adviserSystemUserId:
            prospectiveInvestorDefaults?.adviserSystemUserId || '',
        bookingCentres: prospectiveInvestorDefaults?.bookingCentres || [
            {
                countryCode: '',
                bookingCentreId: '',
                adviserSystemUserId: '',
            },
        ],
        fafIntermediaryId: prospectiveInvestorDefaults?.fafIntermediaryId || '',
        fafBookingCentreId:
            prospectiveInvestorDefaults?.fafBookingCentreId || '',
        fafAdviserSystemUserId:
            prospectiveInvestorDefaults?.fafAdviserSystemUserId || '',
        fafBookingCentres: prospectiveInvestorDefaults?.fafBookingCentres || [
            {
                countryCode: '',
                bookingCentreId: '',
                adviserSystemUserId: '',
            },
        ],
    } as ProspectiveInvestorDefaultsResponse;

    return (
        <div>
            <div className="flex flex-wrap justify-between">
                <h1 className="text-3xl text-logo-blue align-middle font-bold">
                    Prospective Investor Defaults
                </h1>
            </div>

            <div className="w-full lg:w-1/2">
                <p className="py-3">
                    Here you can set the default intermediary and the default
                    booking centre a prospective investor is allocated to when
                    they register on the platform. You can also set the default
                    booking centre for a prospective investor from a certain
                    country further below.
                </p>

                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <BookingCentreConfiguration
                                name="Direct to Consumer"
                                intermediaries={intermediaries}
                                formFields={{
                                    intermediaryId: {
                                        fieldName: 'intermediaryId',
                                        fieldLabel: 'Intermediary',
                                    },
                                    bookingCentreId: {
                                        fieldName: 'bookingCentreId',
                                        fieldLabel: 'Booking Centre',
                                    },
                                    adviserSystemUserId: {
                                        fieldName: 'adviserSystemUserId',
                                        fieldLabel: 'Adviser',
                                    },
                                    bookingCentres: {
                                        fieldName: 'bookingCentres',
                                        fieldLabel: 'Booking Centres',
                                    },
                                }}
                            />

                            <BookingCentreConfiguration
                                name="Friends and family"
                                intermediaries={intermediaries}
                                formFields={{
                                    intermediaryId: {
                                        fieldName: 'fafIntermediaryId',
                                        fieldLabel: 'Intermediary',
                                    },
                                    bookingCentreId: {
                                        fieldName: 'fafBookingCentreId',
                                        fieldLabel: 'Booking Centre',
                                    },
                                    adviserSystemUserId: {
                                        fieldName: 'fafAdviserSystemUserId',
                                        fieldLabel: 'Adviser',
                                    },
                                    bookingCentres: {
                                        fieldName: 'fafBookingCentres',
                                        fieldLabel: 'Booking Centres',
                                    },
                                }}
                            />

                            <div className="flex justify-between mt-4 mb-8">
                                <Button
                                    type="button"
                                    buttonType="secondary"
                                    label="Cancel"
                                    onClick={onCancel}
                                />
                                <Button
                                    type="submit"
                                    buttonType="primary"
                                    label="Save"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export const ProspectiveInvestorDefaultsPageBreadcrumbs: React.FC = () => {
    const items = [
        {
            displayText: 'Prospective Investors',
            navigationUrl: '/admin/prospective-investors',
        },
        {
            displayText: 'Defaults',
            navigationUrl: '/admin/prospective-investors-defaults',
        },
    ];

    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ProspectiveInvestorDefaultsPage,
    undefined,
    <ProspectiveInvestorDefaultsPageBreadcrumbs />
);
