import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { AuthPermissionEnum } from '@interfaces/Api';
import ProductOverview from '@pages/AnalyticsTools/ProductOverview';
import { Navigate, Route, Routes } from 'react-router-dom';

import Portfolio from './Portfolio';
import EditRedemption from '@pages/AnalyticsTools/Redemption/EditRedemption';

export const PortfolioRoutes = () => {
    const { configuration } = useConfigurationState();
    const { hasPermissions } = useAuthState();

    return (
        <Routes>
            <Route path="" element={<Navigate replace to="portfolio/" />} />
            <Route path="portfolio/" element={<Portfolio />} />
            {configuration?.preferences?.feature.clientView
                ?.enablePortfolioView &&
                hasPermissions(AuthPermissionEnum.viewDashboard) && (
                    <Route
                        path="portfolio/subscription/:subscriptionId"
                        element={<ProductOverview />}
                    />
                )}
            {hasPermissions(AuthPermissionEnum.createRedemptionApplication) && (
                <Route
                    path="portfolio/subscription/:subscriptionId/create-redemption"
                    element={<EditRedemption />}
                />
            )}
        </Routes>
    );
};
