import { AuthPermissionEnum, SubscriptionStatusEnum } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import { Button } from '@components/Atoms';
import { AlertBox, ReviewFrame } from '@components/Molecules';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { dateTimeFormatter } from '@helpers/Date.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { getFullName } from '@helpers/common.helper';
import { SubscriptionReviewProps } from '@interfaces/SubscriptionReview';
import { ModalService } from '@services/ModalService';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { getSystemUsersByIds } from '@stores/SystemUsers/SystemUsers.services';
import { useRmDetermination } from '@tenants/Pages/ODDO/Hooks/useRmDetermination.helper';
import { RejectionWarning } from '@tenants/Pages/ODDO/Subscriptions/RejectionWarning/RejectionWarning';
import classNames from 'classnames';
import {
    AuthRoleEnum,
    CompletenessCheckType,
    CompletenessDecisionType,
    CompletenessDecisionTypeLabel,
} from 'common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPageProperties } from './SubscriptionReview.logic';

const IntermediaryMiddleOfficeReview = ({
    onSubscriptionCompletenessCheck,
    isEndInvestorNominativePure,
}: SubscriptionReviewProps) => {
    const { hasPermissions, currentUser } = useAuthState();
    const { configuration } = useConfigurationState();
    const { subscription } = useSubscription();
    const [comments, setComments] = useState<string>('');
    const { t } = useTranslation();

    const [isAcceptButtonLoading, setIsAcceptButtonLoading] =
        useState<boolean>(false);

    const [submissionFileReview, setSubmissionFileReview] = useState([]);

    const loadSystemUsers = async (ids) => await getSystemUsersByIds(ids);

    useMemo(() => {
        const reviews =
            subscription?.completenessChecks?.statusLogs?.filter(
                (check) =>
                    check.type === CompletenessCheckType.internalReview ||
                    check.type === CompletenessCheckType.documentsReviewed ||
                    check.decision ===
                        CompletenessDecisionType.requestMoreInformation
            ) || [];

        const uniqueSystemUserIds = [
            ...new Set(reviews?.map((review) => review.systemUserId)),
        ];

        if (!uniqueSystemUserIds.length) return;

        loadSystemUsers(uniqueSystemUserIds).then((users) => {
            const reviewWithSummary = reviews?.map((review) => {
                const user = users.find(
                    (user) => user._id === review.systemUserId
                );

                return {
                    ...review,
                    summary: [
                        toCapitalizedWords(
                            String(
                                CompletenessDecisionTypeLabel[review?.decision]
                            )
                        ),
                        '-',
                        getFullName(user?.firstName, user?.surname),
                        t('ui.labels.at'),
                        dateTimeFormatter(review?.createdAt),
                        '\n',
                    ]
                        .filter((a) => a)
                        .join(' '),
                };
            });

            setSubmissionFileReview(reviewWithSummary);
        });
    }, [subscription]);

    const pageProperties = GetPageProperties();
    const { isOddoIFA } = useRmDetermination(
        configuration.client,
        currentUser.user
    );

    return (
        <div className={classNames('mb-6')}>
            {pageProperties.role && (
                <h3 className="py-3 text-xl font-normal">
                    {pageProperties.role}{' '}
                    {t(
                        'subscriptions.completeness_checks.internal_review.title'
                    )}
                </h3>
            )}

            <div className="mt-3 mb-6">
                <h3 className="mb-2 text-lg font-bold">
                    {t(
                        'subscriptions.completeness_checks.internal_review.sub_heading'
                    )}
                </h3>

                {submissionFileReview.map((fileReview, i) => (
                    <div
                        className="pb-6 mb-6 border-b"
                        key={fileReview.systemUserId + i}
                    >
                        <ReviewFrame
                            hideToggleButton
                            title=""
                            summary={fileReview.summary}
                            summaryClassName="whitespace-pre-line"
                            expanded
                        />
                        {fileReview?.comments && (
                            <div className="text-sm">
                                <p>
                                    {t(
                                        'subscriptions.completeness_checks.comments'
                                    )}
                                    :
                                </p>
                                <p>{fileReview?.comments}</p>
                            </div>
                        )}
                    </div>
                ))}

                {!submissionFileReview.length &&
                    !hasPermissions(
                        AuthPermissionEnum.approveSubscriptionForMiddleOffice
                    ) && (
                        <AlertBox
                            key="login-account-creation"
                            alertType={'info'}
                            title={'Submission File Review'}
                            message={
                                <p>
                                    {t(
                                        'subscriptions.completeness_checks.internal_review.info_incomplete'
                                    )}
                                </p>
                            }
                        />
                    )}

                {subscription.status ===
                    SubscriptionStatusEnum.readyForReview &&
                    subscription?.completenessChecks?.status !==
                        CompletenessCheckType.requestMoreInformation &&
                    hasPermissions(
                        AuthPermissionEnum.approveSubscriptionForMiddleOffice
                    ) &&
                    subscription?.completenessChecks?.showRiskIndication !==
                        true && (
                        <>
                            <p className="pb-2">
                                {t(
                                    'subscriptions.completeness_checks.internal_review.intro'
                                )}
                            </p>
                            <p>
                                {t(
                                    'subscriptions.completeness_checks.comments_paragraph'
                                )}
                            </p>
                            <textarea
                                onChange={(comment) => {
                                    setComments(comment.target.value);
                                }}
                                className={`w-full h-auto pt-8 pl-3 pr-3 placeholder-gray-300 border rounded-lg focus:shadow-outline`}
                            />

                            <div className="flex flex-row justify-between mt-4">
                                {hasPermissions(
                                    AuthPermissionEnum.updateSubscriptionStatusFromReadyForReviewToRejected
                                ) && (
                                    <Button
                                        type="button"
                                        label={t('ui.controls.reject')}
                                        buttonType="secondary"
                                        className={classNames({
                                            hidden: isOddoIFA,
                                        })}
                                        onClick={() => {
                                            setIsAcceptButtonLoading(true);

                                            ModalService.getInstance()
                                                .setTitle(
                                                    t(
                                                        'subscriptions.completeness_checks.internal_review.reject_modal_title'
                                                    )
                                                )
                                                .setComponent(
                                                    <RejectionWarning
                                                        onAbort={() => {
                                                            ModalService.getInstance().hideModal();
                                                        }}
                                                        onAccept={async () => {
                                                            await onSubscriptionCompletenessCheck(
                                                                {
                                                                    type: CompletenessCheckType.internalReview,
                                                                    decision:
                                                                        CompletenessDecisionType.rejected,
                                                                    comments,
                                                                }
                                                            )
                                                                .then(() =>
                                                                    setIsAcceptButtonLoading(
                                                                        false
                                                                    )
                                                                )
                                                                .catch(() =>
                                                                    setIsAcceptButtonLoading(
                                                                        false
                                                                    )
                                                                );
                                                            ModalService.getInstance().hideModal();
                                                        }}
                                                    />
                                                )
                                                .showModal();
                                        }}
                                    />
                                )}
                                <div>
                                    {hasPermissions(
                                        AuthPermissionEnum.updateSubscriptionStatusFromReadyForReviewToInProgress
                                    ) && (
                                        <Button
                                            type="button"
                                            label={t(
                                                'subscriptions.completeness_checks.request_more_info'
                                            )}
                                            buttonType="primary"
                                            className="mr-2"
                                            onClick={() => {
                                                setIsAcceptButtonLoading(true);
                                                onSubscriptionCompletenessCheck(
                                                    {
                                                        type: CompletenessCheckType.internalReview,
                                                        decision:
                                                            CompletenessDecisionType.requestMoreInformation,
                                                        comments,
                                                    }
                                                )
                                                    .then(() => {
                                                        setIsAcceptButtonLoading(
                                                            false
                                                        );
                                                        window.location.reload();
                                                    })
                                                    .catch(() =>
                                                        setIsAcceptButtonLoading(
                                                            false
                                                        )
                                                    );
                                            }}
                                        />
                                    )}

                                    <Button
                                        type="button"
                                        label={t('ui.controls.accept')}
                                        disabled={
                                            currentUser.user.role ===
                                                AuthRoleEnum.assetManagerAdministrator &&
                                            !isEndInvestorNominativePure
                                        }
                                        onClick={() => {
                                            setIsAcceptButtonLoading(true);
                                            onSubscriptionCompletenessCheck({
                                                type: CompletenessCheckType.internalReview,
                                                decision:
                                                    CompletenessDecisionType.approved,
                                                comments,
                                            })
                                                .then(() => {
                                                    setIsAcceptButtonLoading(
                                                        false
                                                    );
                                                    window.location.reload();
                                                })
                                                .catch(() =>
                                                    setIsAcceptButtonLoading(
                                                        false
                                                    )
                                                );
                                        }}
                                        // endIcon={
                                        //     isAcceptButtonLoading && (
                                        //         <Icons name="Loading" />
                                        //     )
                                        // }
                                    />
                                </div>
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};

export default IntermediaryMiddleOfficeReview;
