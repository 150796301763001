import { useNavigate, useParams } from 'react-router-dom';

import { BasicBreadcrumbs, Loading } from '@components/Atoms';

import { WorkflowStepper } from '@components/Organisms';
import { useProduct } from '@stores/Products/useProduct';
import { useState } from 'react';
import ReviewStep from './Steps/ReviewStep';
import { Step } from '@components/Organisms/WorkflowStepper/WorkflowStepper';
import AttestationsStep, { AttestationsForm } from './Steps/AttestationsStep';
import CreateStep, { RedemptionForm } from './Steps/CreateStep';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';

const EditRedemption = () => {
    const { subscriptionId } = useParams();
    const navigate = useNavigate();
    const { subscription } = useSubscription(subscriptionId);
    const { product } = useProduct(subscription.productId);
    const [activeStepId, setActiveStep] = useState<number>(0);
    const [redemptionForm, setRedemptionForm] = useState<RedemptionForm>();

    const [attestationsForm, setAttestationsForm] =
        useState<AttestationsForm>();

    if (!subscription || !product) {
        return <Loading size="large" />;
    }

    const steps: (Step & { component: React.ReactNode })[] = [
        {
            id: 0,
            label: 'Create',
            showStep: true,
            isValid: !!redemptionForm,
            component: (
                <CreateStep
                    subscription={subscription}
                    product={product}
                    redemptionForm={redemptionForm}
                    setRedemptionForm={setRedemptionForm}
                    nextStep={() => setActiveStep(1)}
                />
            ),
        },
        {
            id: 1,
            label: 'Attestations',
            showStep: true,
            isValid: !!attestationsForm,
            component: (
                <AttestationsStep
                    attestationsForm={attestationsForm}
                    setAttestationsForm={(attestations) =>
                        setAttestationsForm(attestations)
                    }
                    previousStep={() => setActiveStep(0)}
                    nextStep={() => setActiveStep(2)}
                />
            ),
        },
        {
            id: 2,
            label: 'Review',
            canClick: !!redemptionForm && !!attestationsForm,
            showStep: true,
            component: redemptionForm && attestationsForm && (
                <ReviewStep
                    subscriptionId={subscription._id}
                    currencyCode={subscription.subscriptionCurrencyCode!}
                    product={product}
                    redemptionForm={redemptionForm}
                    attestationsForm={attestationsForm}
                    previousStep={() => setActiveStep(1)}
                    nextStep={(redemptionApplication) => {
                        navigate(
                            '/redemption-applications/' +
                                redemptionApplication._id
                        );
                    }}
                />
            ),
        },
    ];

    return (
        <div className="flex flex-row pt-4">
            <aside className="block md:w-3/12 px-0">
                <WorkflowStepper
                    steps={steps}
                    activeStep={{
                        id: activeStepId,
                    }}
                    onStepClick={(step) => setActiveStep(step)}
                />
            </aside>
            <main role="main" className="w-full md:w-9/12 px-0 md:px-10 pb-5">
                {steps[activeStepId].component}
            </main>
        </div>
    );
};

const EditRedemptionreadCrumbs = () => {
    const params = useParams();
    const { subscriptions } = useSubscriptions();
    const subScriptionProductId = subscriptions.find(
        (sub) => sub._id === params.subscriptionId
    )?.productId;
    const { products } = useProducts();

    const productName = products?.find(
        (product) => product._id === subScriptionProductId
    )?.name;
    const subscription = subscriptions.find(
        (sub) => sub._id === params.subscriptionId
    );

    const clientUrl = `/clients/${subscription?.endInvestorProfileId}`;

    const items = [
        {
            displayText: 'Portfolio',
            navigationUrl: clientUrl,
        },
        {
            displayText: productName,
            navigationUrl: `${clientUrl}/subscription/${subscription?.productId}`,
        },
        {
            displayText: 'Create Redemption',
            navigationUrl: '',
        },
    ];
    return <BasicBreadcrumbs items={items} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    EditRedemption,
    undefined,
    <EditRedemptionreadCrumbs />
);
