import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { applicationSettingGet } from '@api/ApplicationSetting';
import { ApplicationSetting } from 'common';
import { useApplicationSetting } from '@hooks/useApplicationSetting';

const DistributorContact = () => {
    const { applicationSetting } = useApplicationSetting();

    return (
        <div>
            <h1 className="text-2xl text-logo-blue font-bold">
                Investing with Hg as a Distributor
            </h1>
            <h2 className="py-4">
                Please reach out to a member of the team if you are interested in accessing Hg Fusion for your clients.
            </h2>
            {applicationSetting?.distributorContactEmail && (
                <p>
                    <Link to={`mailto:${applicationSetting.distributorContactEmail}`} className="underline">
                        {applicationSetting.distributorContactEmail}
                    </Link>
                </p>
            )}
        </div>
    );
};

export default DistributorContact;
