import { useFormikContext } from 'formik';

import {
    EndInvestorProfileResponse,
    getSubscriptionProcessTypeLabels,
} from '@interfaces/Api';
import { RadioOption } from '@interfaces/InterfaceFormsProps';

import { FormRadioGroup } from '@components/Molecules';

import getClientRegistrationFormFields from '../ClientRegistrationFormFields';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useTranslation } from 'react-i18next';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from 'common';
import { useEffect, useLayoutEffect, useMemo } from 'react';

export const SubscriptionProcessSelection = () => {
    const { t } = useTranslation();
    const { isClient } = useConfigurationState();

    const subscriptionProcessTypeRadioOptions: RadioOption<string>[] =
        useMemo(() => {
            const options = [
                {
                    label: getSubscriptionProcessTypeLabels(
                        t,
                        EndInvestorProfileResponse.SubscriptionProcessTypeEnum
                            .Direct
                    ),
                    value: EndInvestorProfileResponse
                        .SubscriptionProcessTypeEnum.Direct,
                    abstract: t(
                        'client.registration.subscription_process.signed_by_investor'
                    ),
                },
            ];

            if (!isClient(ClientEnum.ODDO)) {
                options.push({
                    label: getSubscriptionProcessTypeLabels(
                        t,
                        EndInvestorProfileResponse.SubscriptionProcessTypeEnum
                            .Nominee
                    ),
                    value: EndInvestorProfileResponse
                        .SubscriptionProcessTypeEnum.Nominee,
                    abstract: t(
                        'client.registration.subscription_process.investor_beneficial_owner'
                    ),
                });
            }

            return options;
        }, [isClient, t]);

    const subscriptionProcessOption = document.querySelector(
        '[name="subscriptionProcessType"]'
    ) as HTMLInputElement;

    useLayoutEffect(() => {
        subscriptionProcessOption && (subscriptionProcessOption.click());
    }, [subscriptionProcessOption]);

    const { handleChange } = useFormikContext<any>();

    const { data, setData } = useRegisterInvestorState();

    return (
        <>
            <h2 className="py-3 text-2xl pt-7">
                {t('client.registration.summary.subscription_process')}
            </h2>

            <FormRadioGroup
                name={
                    getClientRegistrationFormFields(t).subscriptionProcessType
                        .name
                }
                disabled={
                    data.serviceLevelType ===
                    EndInvestorProfileResponse.ServiceLevelTypeEnum
                        .ExecutionOnly
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);

                    if (
                        e.target.value ===
                            EndInvestorProfileResponse
                                .SubscriptionProcessTypeEnum.Direct &&
                        data.nomineeEndInvestorProfileId
                    ) {
                        setData({
                            ...data,
                            nomineeEndInvestorProfileId: undefined,
                        });
                    }
                }}
                optionsData={subscriptionProcessTypeRadioOptions}
            />
        </>
    );
};
