import { dateShortFormatter } from '@helpers/Date.helper';
import { BeneficialOwnerDetail, BeneficialOwnership } from '@interfaces/Api';
import { useTranslation } from 'react-i18next';

interface ReviewBeneficialOwnersProps {
    owners?: BeneficialOwnership;
}

function ReviewBeneficialOwners(props: ReviewBeneficialOwnersProps) {
    const { t } = useTranslation();
    return (
        <div className="text-base grid grid-cols-3 w-full">
            {props.owners?.beneficialOwners?.map(
                (owner: BeneficialOwnerDetail, index: number) => (
                    <div className="leading-5" key={index}>
                        <div>
                            <strong>
                                {t('subscription.add.details.name')}:{' '}
                            </strong>
                            {`${owner.firstName} ${owner.lastName}`}
                        </div>
                        <div>
                            <strong>
                                {t(
                                    'subscriptions.beneficial_owners.section.address.title'
                                )}
                                :{' '}
                            </strong>
                            {owner.address}
                        </div>
                        <div>
                            <strong>
                                {t(
                                    'subscriptions.beneficial_owners.section.holdings.title'
                                )}
                                :{' '}
                            </strong>
                            {owner.percentageHolding}%
                        </div>
                        <div>
                            <strong>
                                {t(
                                    'subscriptions.beneficial_owners.section.control.title'
                                )}
                                :{' '}
                            </strong>
                            {owner.typeOfControl}
                        </div>
                        <div>
                            <strong>D.O.B: </strong>
                            {dateShortFormatter(owner.dateOfBirth)}
                        </div>
                        <div>
                            <strong>
                                {t('subscriptions.add.place_of_birth')}:{' '}
                            </strong>
                            {owner.placeOfBirth}
                        </div>
                        <div>
                            <strong>
                                {t(
                                    'subscriptions.beneficial_owners.section.nationalities.title'
                                )}
                                :{' '}
                            </strong>
                            {owner?.nationalitiesInformation?.map(
                                (nationality, i) =>
                                    nationality?.countryCode +
                                    (i <
                                    owner?.nationalitiesInformation.length - 1
                                        ? ', '
                                        : '')
                            )}
                        </div>
                        <div>
                            <strong>
                                {t(
                                    'subscriptions.beneficial_owners.section.ni_number.title'
                                )}
                                :{' '}
                            </strong>
                            {owner?.nationalitiesInformation?.map(
                                (nationality, i) =>
                                    nationality?.nationalIdNumber +
                                    (i <
                                    owner?.nationalitiesInformation.length - 1
                                        ? ', '
                                        : '')
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default ReviewBeneficialOwners;
