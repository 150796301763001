import {
    AuthRoleEnum,
    EndInvestorProfileRequestBody,
    EndInvestorProfileResponse,
} from '@interfaces/Api';
import { StepProps } from './Components/Interfaces';
import { FormikValues } from 'formik';
import { TFunction } from 'react-i18next';
import getClientRegistrationFormFields from './ClientRegistrationFormFields';
import { isAdvisoryEnabled } from '@helpers/EndInvestorHelper';
import { getStepsConfig } from './getStepsConfig';

interface HandleServiceLevelandSubscriptionSelectionProps {
    currentStep?: StepProps;
    values: EndInvestorProfileRequestBody;
}

export const handleServiceLevelandSubscriptionSelection = ({
    currentStep,
    values,
}: HandleServiceLevelandSubscriptionSelectionProps) => {
    let data = {
        serviceLevelType: values.serviceLevelType,
        subscriptionProcessType: values.subscriptionProcessType,
    };

    if (
        values.serviceLevelType ===
        EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly
    ) {
        data = {
            ...data,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (
        values.endInvestorType ===
        EndInvestorProfileResponse.EndInvestorType.nomineeEntity
    ) {
        data = {
            serviceLevelType:
                EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (
        values.serviceLevelType ===
            EndInvestorProfileResponse.ServiceLevelTypeEnum.ExecutionOnly &&
        currentStep?.name === 'serviceLevelType'
    ) {
        data = {
            ...data,
            subscriptionProcessType:
                EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct,
        };
    }

    if (currentStep?.name === 'subscriptionProcessType') {
        data = {
            ...data,
            subscriptionProcessType: values.subscriptionProcessType,
        };
    }

    return data;
};

export const disabledNextStepButton = ({
    currentStep,
    values,
    isEdit,
}: {
    currentStep: StepProps;
    values: FormikValues;
    isEdit: boolean;
}) => {
    let isDisabled = false;

    if (
        currentStep?.name === 'addresses' &&
        values.addresses &&
        values.addresses.length === 0
    ) {
        isDisabled = true;
    }

    if (
        currentStep?.name === 'bankAccounts' &&
        values.bankAccounts &&
        values.bankAccounts.length === 0
    ) {
        isDisabled = true;
    }

    if (currentStep?.name === 'systemUsers' && isEdit) {
        isDisabled = true;
    }

    return isDisabled;
};

export const getSteps = ({
    t,
    data,
    formValues,
    hasRoles,
}: {
    t: TFunction<'translation', undefined>;
    data?: EndInvestorProfileRequestBody;
    formValues?: EndInvestorProfileRequestBody;
    hasRoles: (roles: AuthRoleEnum[]) => boolean;
}) => {
    const currentValues = {
        ...data,
        ...formValues,
    };

    const dynamicSteps = {
        [getClientRegistrationFormFields(t).intermediaryId.name]: hasRoles([
            AuthRoleEnum.superUser,
            AuthRoleEnum.assetManagerAdministrator,
        ]),
        [getClientRegistrationFormFields(t).adviserSystemUserId.name]:
            (currentValues?.endInvestorType &&
                isAdvisoryEnabled(currentValues?.endInvestorType)) ||
            currentValues.adviserSystemUserId ||
            false,
        nomineeEndInvestorProfileId:
            currentValues?.subscriptionProcessType ===
            EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Nominee,
        clientType: !!currentValues?.onboardingJurisdictionCountryCode || false,
        businessEntityDetails: currentValues?.endInvestorType
            ? currentValues?.endInvestorType !==
              EndInvestorProfileResponse.EndInvestorType.individual
            : false,
        // nationalities:
        //     currentValues?.endInvestorType ===
        //     EndInvestorProfileResponse.EndInvestorType.individual,
    };

    const { steps } = getStepsConfig();

    return steps.map((step: StepProps) => ({
        ...step,
        isEditable:
            dynamicSteps[step.name] !== undefined
                ? dynamicSteps[step.name]
                : true,
    }));
};
