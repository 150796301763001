import { Icons } from '@components/Atoms';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';
import { Document } from '@interfaces/Api';
import { LifecycleNotice } from '@interfaces/Api/SubscriptionNotice';

export interface QuarterlyReportEventProps {
    notice: LifecycleNotice;
}

export const QuarterlyReportEvent: React.FC<QuarterlyReportEventProps> = ({
    notice,
}) => {
    return (
        <>
            <p>{notice.comment}</p>
            <p className="text-sm md:text-base leading-snug text-opacity-100">
                {notice.documents?.map((document: Document, i) => (
                    <div key={`${document.fileName}${i}`}>
                        <DocumentLinkRender
                            href={document.url}
                            target="_blank"
                            rel="noreferrer"
                            className="hover:underline font-bold"
                        >
                            {document?.label}{' '}
                            <span className="inline-block top-1 relative ml-3">
                                <Icons name="ArrowDownTrayIcon" />
                            </span>
                        </DocumentLinkRender>
                    </div>
                ))}
            </p>
        </>
    );
};
