enum IdType {
    Passport = 'passport',
    ID = 'ID',
}
const idTypeLabels: { [key in IdType]: string } = {
    [IdType.Passport]: 'Passport',
    [IdType.ID]: 'ID',
};

export const idTypeOptions = Object.entries(idTypeLabels).map(
    ([key, label]) => ({
        value: key as IdType,
        label: label,
    })
);
export interface Nationality {
    countryCode: string;
    nationalIdNumber: string;
    idType?: IdType;
    expiryDate?: Date;
}
