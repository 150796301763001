import {
    FormInputBox,
    FormMultiselect,
    FormSelect,
} from '@components/Molecules';
import { useProduct } from '@stores/Products/useProduct';
import { useProductSubmit } from '@hooks/useProductSubmit';
import { LiquidityTypeEnum, ProductResponse } from '@interfaces/Api';
import { SelectOption } from '@interfaces/InterfaceFormsProps';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import EditProductFormikButtons from './EditProductFormikButtons';
import { KeyDatesInterface, ProductKeyDatesForm } from './ProductKeyDatesForm';
import FormikLabels from './ModelProducts';
import {
    CalendarTimeEnum,
    calendarTimeLabels,
    DateFrequencyEnum,
    dateFrequencyLabels,
} from '@interfaces/Api/ProductResponse';
import { isoCountriesOptions } from '@helpers/isoCountries';
import { t } from 'i18next';
import { ModalService } from '@services/ModalService';
import { ModalWrapper } from '@components/Organisms';
import { getUpcomingClosingDate } from '@helpers/Product.helper';
import { ClosingDateWarning } from '@components/Organisms/ClosingDateWarning';
import { ValidationError } from 'yup';

const liquidityTypeOptions: SelectOption<string>[] = [
    {
        label: t('liquidity_type.closed_ended'),
        value: LiquidityTypeEnum.closedEnded,
    },
    {
        label: t('liquidity_type.open_ended'),
        value: LiquidityTypeEnum.openEnded,
    },
];

const dateFreqencyOptions: SelectOption<DateFrequencyEnum>[] = Object.entries(
    dateFrequencyLabels
)
    .map(([enumType, description]) => ({
        value: enumType as DateFrequencyEnum,
        label: description,
    }))
    .filter((a) =>
        // only show monthly, quarterly and annually
        [
            DateFrequencyEnum.monthly,
            DateFrequencyEnum.quarterly,
            DateFrequencyEnum.annually,
        ].includes(a.value)
    );

const calendarTimeOptions: SelectOption<CalendarTimeEnum>[] = Object.entries(
    calendarTimeLabels
).map(([enumType, description]) => ({
    value: enumType as CalendarTimeEnum,
    label: description,
}));

const LiquidityInformation = () => {
    const { formField } = FormikLabels;

    const { productId } = useParams();
    const { product } = useProduct(productId);

    const [formValue, setFormValue] = useState<Partial<ProductResponse>>({});

    useEffect(() => {
        if (productId && product) {
            if (product.closedEndedInformation?.coolingOffDays === null) {
                delete product.closedEndedInformation.coolingOffDays;
            }

            if (product.closedEndedInformation?.keyDates === null) {
                delete product.closedEndedInformation.keyDates;
            }

            setFormValue(product);
        }
    }, [product, productId]);

    const minNumberErrorMessage = t(
        'admin.product.negative_numbers_not_allowed'
    );

    const validationSchema = Yup.object().shape({
        [formField.liquidityType.name]: Yup.string()
            .trim()
            .required(formField.liquidityType.requiredErrorMsg),
        closedEndedInformation: Yup.object().when(
            ['liquidityType'],
            (liquidityType: LiquidityTypeEnum) => {
                if (liquidityType === LiquidityTypeEnum.closedEnded) {
                    return Yup.object().shape({
                        // default for coolingOffDays is 0
                        coolingOffDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        keyDates: Yup.array()
                            .of(
                                Yup.object().shape({
                                    signedSubscriptionDeadline: Yup.string(),
                                    closingDate: Yup.string(),
                                })
                            )
                            .when(
                                ['coolingOffDays'],
                                (coolingOffDays: number) => {
                                    // sometimes isNaN is true even though it is a number, strange yup thing
                                    if (isNaN(coolingOffDays)) {
                                        coolingOffDays = 0;
                                    }
                                    const validationMessages = [
                                        `1. ${t(
                                            'admin.product.closing_date_must_be_later_then_subscription_dead_line'
                                        )}.`,

                                        `2. ${t(
                                            'admin.product.new_closing_date_must_be_later_then_previous_closing_data'
                                        )}`,

                                        `3. ${t(
                                            'admin.product.new_subscription_end_date_should_be_later_than_the_previous_subscription_end_date'
                                        )}`,

                                        `4. ${t(
                                            'admin.product.closing_date_must_be_greater_then_deadline_date_plus_cooling_off_days'
                                        )}`,
                                    ].join('\n');

                                    return Yup.array()
                                        .of(
                                            Yup.object().shape({
                                                signedSubscriptionDeadline:
                                                    Yup.string(),
                                                closingDate: Yup.string(),
                                                closingDescription:
                                                    Yup.string(),
                                            })
                                        )
                                        .test(
                                            'higherIndexLaterDate',
                                            validationMessages,
                                            (
                                                val:
                                                    | KeyDatesInterface[]
                                                    | undefined,
                                                context
                                            ) => {
                                                if (!val || val?.length === 0) {
                                                    return true;
                                                }

                                                if (
                                                    coolingOffDays === undefined
                                                ) {
                                                    return false;
                                                }

                                                let laterKeyDates: KeyDatesInterface =
                                                    val?.[0];

                                                for (
                                                    let i = 1;
                                                    i <= val.length;
                                                    i++
                                                ) {
                                                    const keyDate = val[i - 1];
                                                    if (
                                                        !keyDate.closingDate ||
                                                        !keyDate.signedSubscriptionDeadline
                                                    ) {
                                                        return new ValidationError(
                                                            validationMessages,
                                                            'context.value',
                                                            `closedEndedInformation.keyDates[${
                                                                i - 1
                                                            }]`
                                                        );
                                                    }
                                                    const signedSubscriptionDeadline =
                                                        +dayjs(
                                                            laterKeyDates.signedSubscriptionDeadline
                                                        );
                                                    const earliestClosingDate =
                                                        +dayjs(
                                                            laterKeyDates.signedSubscriptionDeadline
                                                        ).add(
                                                            +coolingOffDays,
                                                            'day'
                                                        );
                                                    const closingDate = +dayjs(
                                                        laterKeyDates.closingDate
                                                    );

                                                    if (
                                                        closingDate <
                                                        earliestClosingDate
                                                    ) {
                                                        return new ValidationError(
                                                            validationMessages,
                                                            'context.value',
                                                            `closedEndedInformation.keyDates[${
                                                                i - 1
                                                            }]`
                                                        );
                                                    }

                                                    if (i >= val.length) {
                                                        return true;
                                                    }

                                                    const currentSignedSubscriptionDeadline =
                                                        +dayjs(
                                                            val[i]
                                                                .signedSubscriptionDeadline
                                                        );
                                                    const currentEarliestClosingDate =
                                                        +dayjs(
                                                            val[i]
                                                                .signedSubscriptionDeadline
                                                        ).add(
                                                            +coolingOffDays,
                                                            'day'
                                                        );
                                                    const currentClosingDate =
                                                        +dayjs(
                                                            val[i].closingDate
                                                        );

                                                    if (
                                                        currentClosingDate <
                                                        currentEarliestClosingDate
                                                    ) {
                                                        return new ValidationError(
                                                            validationMessages,
                                                            'context.value',
                                                            `closedEndedInformation.keyDates[${i}]`
                                                        );
                                                    }

                                                    if (
                                                        currentClosingDate <=
                                                        closingDate
                                                    ) {
                                                        return new ValidationError(
                                                            validationMessages,
                                                            'context.value',
                                                            `closedEndedInformation.keyDates[${i}]`
                                                        );
                                                    }

                                                    if (
                                                        currentSignedSubscriptionDeadline <=
                                                        signedSubscriptionDeadline
                                                    ) {
                                                        return new ValidationError(
                                                            validationMessages,
                                                            'context.value',
                                                            `closedEndedInformation.keyDates[${i}]`
                                                        );
                                                    }

                                                    laterKeyDates = val[i];
                                                }

                                                return true;
                                            }
                                        );
                                }
                            ),
                    });
                }
                return Yup.object();
            }
        ),
        openEndedInformation: Yup.object().when(
            ['liquidityType'],
            (liquidityType: LiquidityTypeEnum) => {
                if (liquidityType === LiquidityTypeEnum.openEnded) {
                    return Yup.object().shape({
                        redemptionFrequency: Yup.string()
                            .trim()
                            .required(t('redemption_frequency_is_required')),
                        redemptionNoticeDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        redemptionQuantityAvailablePercentage: Yup.number()
                            .min(0, minNumberErrorMessage)
                            .max(100),
                        subscriptionNoticeDays: Yup.number().min(
                            0,
                            minNumberErrorMessage
                        ),
                        hardLockUpDetail: Yup.object().shape({
                            lengthOfTimeInMonths: Yup.number().min(
                                0,
                                minNumberErrorMessage
                            ),
                        }),
                    });
                }
                return Yup.object();
            }
        ),
    });

    const onSubmit = useProductSubmit(productId, `product-fees`);

    const handleSubmit = (values: Partial<ProductResponse>, actions: any) => {
        const currentNextKeyDate = getUpcomingClosingDate(
            product.closedEndedInformation.keyDates
        )?.closingDate;
        const submittedNextKeyDate = getUpcomingClosingDate(
            values.closedEndedInformation.keyDates
        )?.closingDate;

        if (
            currentNextKeyDate &&
            submittedNextKeyDate &&
            !dayjs(currentNextKeyDate).isSame(submittedNextKeyDate, 'day')
        ) {
            ModalService.getInstance('closingDateModal')
                .setTitle(t('admin.product.closing_date_change_confirmation'))
                .setComponent(
                    <ClosingDateWarning closingDate={submittedNextKeyDate} />
                )
                .setShowFooter(true)
                .setConfirmationLabel('Overwrite')
                .setConfirmCallback(async () => {
                    onSubmit(values);
                    ModalService.getInstance('closingDateModal').hideModal();
                })
                .setCancelCallback(() => {
                    actions.resetForm();
                    ModalService.getInstance('closingDateModal').hideModal();
                })
                .showModal();
        } else {
            if (values.liquidityType === 'openEnded') {
                values['closedEndedInformation'] = {};
            } else if (values.liquidityType === 'closedEnded') {
                values['openEndedInformation'] = {};
            }
            onSubmit(values);
        }
    };

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">
                {t('admin.product.liquidity_information')}
            </h1>
            <div className="my-4">
                {productId && (
                    <Formik
                        initialValues={formValue}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({ values, isValid, setFieldValue, ...actions }) => (
                            <Form>
                                <FormSelect
                                    name={formField.liquidityType.name}
                                    label={formField.liquidityType.label}
                                    optionsData={liquidityTypeOptions}
                                />
                                {values.liquidityType ==
                                    LiquidityTypeEnum.openEnded && (
                                    <>
                                        {/* select box for redemptionFrequency */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionFrequency.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionFrequency.label
                                            }
                                            optionsData={dateFreqencyOptions}
                                        />
                                        {/* date box for openEndedInformation.redemptionFrequencyDeadline */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionFrequencyDeadline
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionFrequencyDeadline
                                                    .label
                                            }
                                            optionsData={calendarTimeOptions}
                                        />

                                        {/* input box for openEndedInformation.redemptionNoticeDays */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionNoticeDays.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionNoticeDays.label
                                            }
                                            type="number"
                                        />
                                        {/* percentage box for openEndedInformation.redemptionQuantityAvailablePercentage */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .redemptionQuantityAvailablePercentage
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .redemptionQuantityAvailablePercentage
                                                    .label
                                            }
                                            type="number"
                                        />
                                        {/* multi select box for businessDayCountryCodeList*/}
                                        <FormMultiselect
                                            name={
                                                formField.openEndedInformation
                                                    .businessDayCountryCodeList
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .businessDayCountryCodeList
                                                    .label
                                            }
                                            options={isoCountriesOptions}
                                            isFieldValuesArray
                                            id="businessDayCountryCodeList"
                                        />

                                        {/* select box for subscriptionFrequency */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionFrequency.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionFrequency.label
                                            }
                                            optionsData={dateFreqencyOptions}
                                        />
                                        {/* select for subscriptionFrequencyDeadline */}
                                        <FormSelect
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionFrequencyDeadline
                                                    .name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionFrequencyDeadline
                                                    .label
                                            }
                                            optionsData={calendarTimeOptions}
                                        />

                                        {/* number box for subscriptionNoticeDays */}
                                        <FormInputBox
                                            name={
                                                formField.openEndedInformation
                                                    .subscriptionNoticeDays.name
                                            }
                                            label={
                                                formField.openEndedInformation
                                                    .subscriptionNoticeDays
                                                    .label
                                            }
                                            type="number"
                                        />
                                    </>
                                )}
                                {values.liquidityType ==
                                    LiquidityTypeEnum.closedEnded && (
                                    <>
                                        <FormInputBox
                                            name={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.name
                                            }
                                            label={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.label
                                            }
                                            placeholder={
                                                formField.closedEndedInformation
                                                    .coolingOffDays.label
                                            }
                                            type="number"
                                        />
                                        <ProductKeyDatesForm
                                            product={product}
                                        />
                                    </>
                                )}

                                <EditProductFormikButtons
                                    productId={productId}
                                    isReadOnly={false}
                                    pathPrevious="product-information"
                                    pathNext="product-fees"
                                />
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
            <ModalWrapper msgPrefix="closingDateModal" />
        </div>
    );
};

export default LiquidityInformation;
