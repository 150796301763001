import { Icons, Button } from '@components/Atoms';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';
import { PopUp } from '@components/Molecules';
import {
    DocumentTemplateRow,
    DocumentTemplateTypeEnum,
    FormDocumentTemplateUpload,
} from '@interfaces/Api/DocumentTemplate';
import { Dispatch, SetStateAction } from 'react';
import Highlighter from 'react-highlight-words';

interface DocumentTemplatesTableRowProps {
    document: DocumentTemplateRow;
    searchText: string;
    setSelectedDocument: Dispatch<
        SetStateAction<FormDocumentTemplateUpload | undefined>
    >;
    hideEdit?: boolean;
    setShowDocumentModal: (showDocumentModal: boolean) => void;
    setShowDeleteDocumentModal: (showDeleteDocumentModal: boolean) => void;
    onDeleteCallBack?: (documentTemplate: DocumentTemplateRow) => void,
    selectable?: boolean;
    hideOperations?: boolean;
    onSelectCallback?: (document: DocumentTemplateRow, checked: boolean) => void;
}

const DocumentTemplatesTableRow = ({
    document,
    searchText,
    setSelectedDocument,
    setShowDocumentModal,
    setShowDeleteDocumentModal,
    hideEdit = false,
    onDeleteCallBack,
    selectable = false,
    onSelectCallback,
    hideOperations = false,
}: DocumentTemplatesTableRowProps) => {
    const {
        _id,
        url,
        documentType,
        fileName,
        createdDate,
        createdBy,
        updatedDate,
        updatedBy,
    } = document;

    const documentTypeIndex = Object.keys(DocumentTemplateTypeEnum).findIndex(
        (key) => key === documentType
    );

    const tableData = [
        <>
            <p>
            {selectable && 
                    <><input type="checkbox" onChange={(event) => onSelectCallback(document, event.target.checked)} /> &nbsp;</>
                }
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={
                        Object.values(DocumentTemplateTypeEnum)[
                            documentTypeIndex
                        ]
                    }
                />
            </p>
        </>,
        <>
            <DocumentLinkRender
                href={url}
                title="Download template"
                className="hover:underline"
            >
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={fileName}
                />
            </DocumentLinkRender>
        </>,
        <>
            <Highlighter
                searchWords={searchText.split(' ')}
                autoEscape
                textToHighlight={''}
            />
            <p className="text-xs">
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={createdBy || ''}
                />
            </p>
        </>,
        <>
            <p className="text-xs">
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={createdDate || ''}
                />
            </p>
        </>,
        <>
            <p className="text-xs">
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={updatedBy || ''}
                />
            </p>
        </>,
        <>
            <p className="text-xs">
                <Highlighter
                    searchWords={searchText.split(' ')}
                    autoEscape={true}
                    textToHighlight={updatedDate || ''}
                />
            </p>
        </>,
        hideOperations ? (
            ''
        ) : (
            <PopUp
                key=""
                trigger={
                    <button>
                        <Icons name="EllipsisVerticalIcon" strokeWidth="1.5" />
                    </button>
                }
                position="left center"
            >
                <div className="min-w-4">
                    <div
                        className="h-12 flex flex-row items-center justify-center"
                        key={_id}
                    >
                         {!hideEdit && <div className="px-2">
                        <Button
                            onClick={() => {
                                setSelectedDocument(document);
                                setShowDocumentModal(true);
                            }}
                            hasIconOnly
                        >
                            <Icons name="PencilIcon" strokeWidth="1.5" />
                        </Button>
                        </div>}
                        <div className="px-2">
                            <Button
                                onClick={() => {
                                    setSelectedDocument(document);

                                    if (onDeleteCallBack) {
                                        onDeleteCallBack(document);
                                    } else {
                                        setShowDeleteDocumentModal(true);
                                    }
                                }}
                                hasIconOnly
                            >
                                <Icons
                                    name="TrashIcon"
                                    stroke="black"
                                    strokeWidth="1.5"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </PopUp>
        ),
    ];
    return tableData;
};

export default DocumentTemplatesTableRow;
