import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { ModalForm } from '@components/Organisms';
import { IModalForm } from '@components/Organisms/ModalForm/ModalForm';
import { BankAccountFields } from './BankAccountFields';
import { BankAccount } from 'common';


interface AddBankAccountProps {
    addBankAccount: (bankAccount: BankAccount) => void;
    disabled?: boolean;
    show?: boolean;
    withButton?: boolean;
    onModalChange?: (visible: boolean) => void;
}

export const AddBankAccount: React.FC<AddBankAccountProps> = ({
    addBankAccount,
    disabled,
    show = false,
    withButton = true,
    onModalChange
}) => {
    const { setFieldValue, values: formValues } = useFormikContext();
    const [showModal, toggleModal] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (show !== undefined) {
            toggleModal(show);
        }
    }, [show]);

    const validationSchemWithNameSpace: Yup.SchemaOf<{
        [key: string]: string;
    }> = Yup.object().shape(
        BankAccountFields.reduce((acc, field) => {
            return {
                ...acc,
                [field.name]: Yup.string().trim().required('Required'),
            };
        }, {})
    ) as Yup.SchemaOf<{
        [key: string]: string;
    }>;

    const handleSubmit = async (values) => {
        addBankAccount(values);

        toggleModal(false);

        if (formValues['bankAccountName'] === '') {
            setFieldValue('bankAccountName', values.bankName);
        }
    };

    const modalFormProps: IModalForm = {
        fields: BankAccountFields,
        handleSubmit,
        initialValues: {
            bankName: '',
            accountHolderName: '',
            accountCode: '',
            IBAN: '',
            countryCodeOfAccount: '',
            countryCurrencyCode: '',
            accountNumber: '',
        },
        label: t('client.registration.bank_accounts.add_bank_account_button'),
        showModal,
        toggleModal: onModalChange ? onModalChange : toggleModal,
        validationSchema: validationSchemWithNameSpace,
        disabled,
        withButton,
    };

    return <ModalForm {...modalFormProps} />;
};
