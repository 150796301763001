import {
    EndInvestorProfileResponse,
    EndInvestorProfileRequestBody,
    SystemUserResponse,
} from '@interfaces/Api';
import { EndInvestorProfilePortfolio } from '@interfaces/EndInvestorProfilePortfolio';

import { Http } from '../libs/http';
import { BaseUrl } from '@helpers/Environment';
import { OptUpRequest, RequestOptUpStatus } from '@interfaces/OptUpRequests';
import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { msalInstance } from '@libs/msal/msalInit';

const API_ENDINVESTOR_URL = `${BaseUrl}/end-investor-profiles`;

export const EndInvestorProfilesGetAll = async (advisorId?: string) =>
    Http.get<EndInvestorProfileResponse[]>(API_ENDINVESTOR_URL, undefined, {
        advisorId,
    });

export const EndInvestorProfilesGetAllWithoutLoginAccount = async () =>
    Http.get<EndInvestorProfileResponse[]>(
        `${API_ENDINVESTOR_URL}/without-login-account`
    );

export const EndInvestorProfileAssignLoginAccount = async ({
    endInvestorProfileId,
}: {
    endInvestorProfileId: string;
}) =>
    Http.post<EndInvestorProfileResponse[]>(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/create-login-account`
    );

export const EndInvestorProfileGet = async (endInvestorProfileId: string) =>
    Http.get<EndInvestorProfileResponse>(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}`
    );

export const EndInvestorProfileLink = async ({
    endInvestorProfileId,
    body,
}: {
    endInvestorProfileId: string;
    body: {
        profileId: string;
    };
}) =>
    await Http.put(`${API_ENDINVESTOR_URL}/${endInvestorProfileId}/link`, body);

export const EndInvestorProfilePortfolioGet = async (
    endInvestorProfileId: string
) =>
    Http.get<EndInvestorProfilePortfolio>(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/portfolio`
    );

export const EndInvestorProfileDocumentsGet = async (
    endInvestorProfileId: string,
    subscriptionId?: string
) => {
    const account = msalInstance?.getActiveAccount();
    const scopes = localStorage.getItem('AUTH_SCOPES');
    const authority = localStorage.getItem('AUTH_AUTHORITY');
    let accessToken = getLocalStorageAuthTokens()?.access?.token;
    if (account) {
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (accessToken = response?.accessToken))
            .catch((error) => {
                return (accessToken = undefined);
            });
    }

    const url = new URL(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/documents`
    );
    if (subscriptionId) {
        url.searchParams.set('subscriptionId', subscriptionId);
    }

    const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
        },
    });

    return response;
};

export const EndInvestorProfilesPost = async (
    body: EndInvestorProfileRequestBody,
    systemUserId?: string
) => {
    return await Http.post(`${API_ENDINVESTOR_URL}`, body, undefined, {
        systemUserId: systemUserId,
    });
};

export const EndInvestorProfilesPut = async (
    body: EndInvestorProfileRequestBody,
    endInvestorProfileId?: string | undefined
): Promise<EndInvestorProfileResponse> => {
    return await Http.put(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}`,
        body
    );
};

export const EndInvestorProfilesDelete = async (
    endInvestorProfileId: string
) => {
    return Http.delete(`${API_ENDINVESTOR_URL}/${endInvestorProfileId}`);
};

export const EndInvestorRequestRMToComplete = async () => {
    return Http.put(`${API_ENDINVESTOR_URL}/authorize-rm`);
};

export const inviteNewEndInvestor = (investor: Partial<SystemUserResponse>) => {
    return Http.post(`${API_ENDINVESTOR_URL}/invite`, investor);
};

export const resendEmailInvitation = async (data: {
    id: string;
}): Promise<any> =>
    Http.post(`${API_ENDINVESTOR_URL}/resend-email-invitation`, data);

export const validateEndInvestorSubscriptionReadiness = async (
    endInvestorProfile: EndInvestorProfileResponse
) => {
    return Http.post(
        `${API_ENDINVESTOR_URL}/validate-subscription-readiness`,
        endInvestorProfile
    );
};

export const EndInvestorProfileOptUpPost = async (
    endInvestorProfileId: string,
    body: {
        productId: string;
        investorType: string;
        investorJurisdiction: string;
    }
) => {
    return await Http.post(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/opt-up`,
        body
    );
};

export const EndInvestorProfileRequestOptUpPut = async (
    endInvestorProfileId: string,
    body: {
        productId: string;
        newCategory: string;
        comment: string;
        uploadedDocuments: string[];
        investorJurisdiction: string;
    }
) => {
    return await Http.post(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/opt-up`,
        body
    );
};

export const RequestOptUpGet = async (
    endInvestorProfileId: string | undefined
) => {
    return await Http.get<OptUpRequest[]>(
        `${API_ENDINVESTOR_URL}/opt-up/${endInvestorProfileId}`
    );
};

export const RequestOptUpStatusPost = async (
    optUpRequestId: string,
    endInvestorProfileId: string,
    statusComment: string,
    status: RequestOptUpStatus
) => {
    const statusUrl =
        status === RequestOptUpStatus.Approve ? 'approve' : 'reject';
    return await Http.post<OptUpRequest>(
        `${API_ENDINVESTOR_URL}/opt-up/${statusUrl}?optUpRequestId=${optUpRequestId}&endInvestorProfileId=${endInvestorProfileId}&statusComment=${statusComment}`
    );
};

export const EndInvestorProfileExternalReferencePut = async (
    endInvestorProfileId: string,
    referenceSystem: string,
    body: {
        referenceValue: string;
    }
) => {
    return await Http.put(
        `${API_ENDINVESTOR_URL}/${endInvestorProfileId}/ext/${referenceSystem}`,
        body
    );
};
