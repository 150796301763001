import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';
import { BookingCentreResponse } from '@interfaces/Api';
import { notNull } from '@helpers/typeGuards';

export const BookingCentresSummary = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const { bookingCentres } = useBookingCentres(data.intermediaryId);

    // check if bookingCentres is an array of Ids, or an array of objects
    // looks like either id, or the object is saved in the data form
    const bookingCentreNames =
        data.bookingCentreIds
            ?.map((bc: string) => {
                return bookingCentres.find((b) => b._id === bc)
                    ?.bookingCentreName;
            })
            .filter(notNull) || [];

    return (
        <p>
            <span className="font-bold mr-2">
                {t('client.registration.summary.booking_centres')}:
            </span>
            {bookingCentreNames.map((bookingCentre, id) => {
                return (
                    <span className="mr-2" key={id}>
                        {bookingCentre}
                    </span>
                );
            })}
        </p>
    );
};
