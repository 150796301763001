import { ProspectiveInvestorsPutStatus } from '@api/ProspectiveInvestors';
import { SuitabilityQuestionnaireResponseBody } from '@api/SuitabilityQuestionnaire';
import { Button } from '@components/Atoms';
import { FormInputBox, ReviewFrame, TextAreaBox } from '@components/Molecules';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import {
    ProspectiveInvestorResponse,
    ProspectiveInvestorStatusEnum,
} from '@interfaces/Api/ProspectiveInvestorResponse';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

interface AwaitingManualReviewSectionProps {
    prospectiveInvestor: ProspectiveInvestorResponse;
    suitabilityQuestionnaire?: SuitabilityQuestionnaireResponseBody;
    status?: ProspectiveInvestorStatusEnum;
    onUpdateProspectiveInvestorStatus: (
        status: ProspectiveInvestorStatusEnum,
        reason: string
    ) => void;
}

const validationSchema = Yup.object().shape({
    assessmentReason: Yup.string().trim().required('Required'),
});

const AwaitingManualReviewSection: React.FC<
    AwaitingManualReviewSectionProps
> = ({
    prospectiveInvestor,
    suitabilityQuestionnaire,
    status,
    onUpdateProspectiveInvestorStatus,
}) => {
    const handleApprove = async (assessmentReason: string) => {
        onUpdateProspectiveInvestorStatus &&
            onUpdateProspectiveInvestorStatus(
                ProspectiveInvestorStatusEnum.approved,
                assessmentReason
            );
    };

    const handleReject = async (assessmentReason: string) => {
        onUpdateProspectiveInvestorStatus &&
            onUpdateProspectiveInvestorStatus(
                ProspectiveInvestorStatusEnum.rejected,
                assessmentReason
            );
    };

    return (
        <ReviewFrame
            title="Manual Review"
            expanded={true}
            hideToggleButton={true}
        >
            <p className="pb-2">
                {' '}
                The user has been flagged for manual review. Please review the
                above information and approve or reject the user.{' '}
            </p>

            <Formik
                enableReinitialize
                initialValues={{ assessmentReason: '' }}
                onSubmit={(_, formikHelper) => {
                    formikHelper.setSubmitting(false);
                }}
                validationSchema={validationSchema}
            >
                {(formikProps) => {
                    return (
                        <Form className="w-1/2">
                            <TextAreaBox
                                name="assessmentReason"
                                label="Reason for assessment"
                                placeholder={''}
                                rows={5}
                            />
                            <div className="flex justify-between pb-2 ">
                                {status === ProspectiveInvestorStatusEnum.rejected ? <div></div> :  <Button
                                    buttonType="primary"
                                    type="button"
                                    label="Reject"
                                    onClick={() => {
                                        handleReject(
                                            formikProps.values.assessmentReason
                                        );
                                    }}
                                    disabled={
                                        formikProps.isSubmitting ||
                                        !formikProps.isValid ||
                                        !formikProps.values.assessmentReason
                                    }
                                />}
                                <Button
                                    buttonType="primary"
                                    type="button"
                                    label="Approve"
                                    onClick={() => {
                                        handleApprove(
                                            formikProps.values.assessmentReason
                                        );
                                    }}
                                    disabled={
                                        formikProps.isSubmitting ||
                                        !formikProps.isValid ||
                                        !formikProps.values.assessmentReason
                                    }
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </ReviewFrame>
    );
};

export default AwaitingManualReviewSection;
