import toast from 'react-hot-toast';

export const notifySuccess = (msg: string, duration?: number) => {
    toast.dismiss();

    if (!duration) duration = msg.length * 50 + 1000;

    toast.success(msg, {
        style: {
            border: '1px solid rgba(1, 16, 49, 1)',
            padding: '16px',
            color: 'rgba(1, 16, 49, 1)',
        },
        iconTheme: {
            primary: 'rgba(1, 16, 49, 1)',
            secondary: '#FFFAEE',
        },
        duration,
    });
};

export const notifyError = (msg: string, duration?: number) => {
    if (!duration) duration = msg.length * 50 + 1000;
    toast.error(msg, {
        style: {
            border: '1px solid rgba(1, 16, 49, 1)',
            padding: '16px',
            color: 'red',
        },
        iconTheme: {
            primary: 'rgba(1, 16, 49, 1)',
            secondary: '#FFFAEE',
        },
        duration,
    });
};

export const notifyInfo = (msg: string, duration?: number) => {
    toast.dismiss();

    if (!duration) duration = msg.length * 50 + 1000;

    toast(msg, {
        style: {
            border: '1px solid rgba(29 78 216)',
            padding: '16px',
            color: 'rgba(29 78 216)',
        },
        icon: 'ℹ️',
        iconTheme: {
            primary: 'rgba(29 78 216)',
            secondary: '#FFFAEE',
        },
        duration,
    });
};

export const notifyWarning = (msg: string, duration?: number) => {
    toast.dismiss();

    if (!duration) duration = msg.length * 50 + 1000;

    toast(msg, {
        style: {
            border: '1px solid rgba(234 179 8 )',
            padding: '16px',
            color: 'rgba(0 58 138)',
        },
        icon: '❗',
        iconTheme: {
            primary: 'rgba(234 179 8 )',
            secondary: '#FFFAEE',
        },
        duration,
    });
};