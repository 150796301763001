import { getAllAmlKycRequirements } from '@api/KycAmlRequirements';
import { useLanguageLocaleState } from '@contexts/LanguageSwitcher';
import { useMountEffect } from '@hooks/useMountEffect';
import { EndInvestorType } from 'common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface KYCDocumentsInfoProps {
    endInvestorType: EndInvestorType;
}

const KYCDocumentsList: React.FC<KYCDocumentsInfoProps> = ({
    endInvestorType,
}) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguageLocaleState();
    const [documentList, setDocumentList] = useState<
        { documentName: string; furtherDetails?: string | string[] }[]
    >([]);

    useEffect(() => {
        getAllAmlKycRequirements(selectedLanguage).then(
            (amlKycRequirements) => {
                const endInvestorRequirements =
                    amlKycRequirements[endInvestorType];
                if (endInvestorRequirements) {
                    setDocumentList(endInvestorRequirements);
                }
            }
        );
    }, [endInvestorType, selectedLanguage]);

    return (
        <ul className="my-4 ml-4 list-disc">
            {documentList.map((item, index) => (
                <li key={index} className="pb-1 text-red-500">
                    <p className="text-sm ">{item.documentName}</p>
                    {item.furtherDetails &&
                    Array.isArray(item.furtherDetails) ? (
                        item.furtherDetails.map((it) => (
                            <li key={it} className="pb-1 mt-1 ml-4 text-xs">
                                {it}
                            </li>
                        ))
                    ) : (
                        <p className="pl-4 text-xs ">{item.furtherDetails}</p>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default KYCDocumentsList;
