import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BasicBreadcrumbs, Button } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { AuthRoleEnum, EndInvestorProfileRequestBody } from '@interfaces/Api';

import { notifySuccess, notifyWarning } from '@helpers/toastrHelper';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import { useCreateTemplate } from '@hooks/useCreateTemplate';

import { useMountEffect } from '@hooks/useMountEffect';
import {
    disabledNextStepButton,
    getSteps,
    handleServiceLevelandSubscriptionSelection,
} from './ClientRegistration.helper';
import { ClientRegistrationErrors } from './ClientRegistrationErrors';
import { Summary } from './Components';
import { StepProps } from './Components/Interfaces';
import { useRegisterInvestorState } from './RegisterInvestor.context';
import { errorToString } from '@helpers/error.helper';
import { getObjectPropertiesPaths } from '@helpers/formik.helper';

const ClientRegistration = () => {
    let dataStore: EndInvestorProfileRequestBody;
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { hasRoles, currentUser, getCurrentUserData } = useAuthState();
    const {
        getAllEndInvestorProfileData,
        getEndInvestorProfilesWithoutLoginAccount:
            getEndInvestorProfilesWithoutLoginAccount,
    } = useEndInvestorProfileState();
    const { data, setData } = useRegisterInvestorState();

    const { preferences } = useConfigurationState();
    const { create: createSubscriptionTemplate } = useCreateTemplate();
    const [errors, setErrors] = useState<string[]>();
    const { sendEndInvestorData, updateEndInvestorData } =
        useEndInvestorProfileState();
    const { systemUserId, endInvestorProfileId } = useParams();
    const navigate = useNavigate();

    const isEdit = !!endInvestorProfileId;
    const isStepEditable = (step: StepProps) => step.isEditable;

    const [stepName, setStepName] = useState<string>('');
    const [steps, setSteps] = useState<StepProps[]>([]);

    // const currentStep = steps?.find((el) => el.name === 'onboardingJurisdictionCountryCode');
    const currentStep = steps?.find((el) => el.name === stepName);

    const submitRefButton = useRef<HTMLDivElement>(null);

    const handleBackStep = () => {
        const prevStepName =
            steps[
                steps.findIndex(
                    (el: StepProps) => el.name === currentStep?.name
                ) - 1
            ]?.name;

        setStepName(prevStepName);
    };

    const isIndividualDetails =
        stepName === 'individualDetails' ||
        stepName === 'nationalities' ||
        stepName === 'emailAddress' ||
        stepName === 'addresses' ||
        stepName === 'bankAccounts';

    const isBusinessEntityDetails =
        stepName === 'businessEntityDetails' ||
        stepName === 'companyName' ||
        stepName === 'registrationNumber' ||
        stepName === 'subscribersLegalForm' ||
        stepName === 'subscribersRegister' ||
        stepName === 'subscribersRegulator';

    const handleNextStep = (values) => {
        if (isBusinessEntityDetails) {
            dataStore = {
                ...data,
                businessEntityDetails: {
                    ...data.businessEntityDetails,
                    ...values,
                },
            };
            setData(dataStore);
        }

        if (isIndividualDetails) {
            dataStore = {
                ...data,
                individualDetails: {
                    ...data.individualDetails,
                    ...values,
                },
                systemUsers: [
                    {
                        email: values.emailAddress,
                        firstName: values.firstName,
                        surname: values.lastName,
                    },
                ],
            };
            setData(dataStore);
        }

        if (!isIndividualDetails && !isBusinessEntityDetails) {
            dataStore = {
                ...values,
                ...handleServiceLevelandSubscriptionSelection({
                    currentStep,
                    values,
                }),
            };
            setData(dataStore);

            disabledNextStepButton(values);
        }

        const editableSteps = getSteps({
            t,
            data,
            formValues: values,
            hasRoles,
        }).filter(isStepEditable);
        setSteps(editableSteps);

        const nextStepName =
            editableSteps[
                editableSteps.findIndex((el) => el.name === currentStep?.name) +
                    1
            ]?.name;

        if (nextStepName) setStepName(nextStepName);
        else if (submitRefButton.current)
            submitRefButton?.current.scrollIntoView({ behavior: 'smooth' });
    };

    const isCurrentStepNotFirst =
        steps?.find((el: StepProps) => el.name === currentStep?.name)?.name !==
        steps?.[0]?.name;

    const isCurrentStepNotLast =
        steps?.find((el: StepProps) => el.name === currentStep?.name)?.name !==
        steps?.slice(-1)[0]?.name;

    const handleRegistrationSubmission = async () => {
        setErrors(undefined);

        const updatedData = {
            ...data,
            individualDetails: {
                ...data.individualDetails,
                bankAccounts: data.individualDetails?.bankAccounts?.filter(
                    (a) =>
                        a.bankName ||
                        a.bankAddress ||
                        a.countryCurrencyCode ||
                        a.countryCodeOfAccount ||
                        a.IBAN
                ),
                addresses: data.individualDetails?.addresses?.filter(
                    (a) =>
                        a.houseName ||
                        a.residentialLine1 ||
                        a.residentialLine2 ||
                        a.city ||
                        a.postCode ||
                        a.countryCode
                ),
                nationalities: data.individualDetails?.nationalities?.filter(
                    (a) => a.countryCode || a.nationalIdNumber
                ),
            },
            ...(dataStore && dataStore),
        };

        try {
            setIsSubmitting(true);
            const endInvestorProfile = isEdit
                ? await updateEndInvestorData(updatedData, endInvestorProfileId)
                : await sendEndInvestorData(updatedData, systemUserId);

            notifySuccess(
                `${
                    isEdit
                        ? t('client.registration.update_success_notification')
                        : t(
                              'client.registration.registration_success_notification'
                          )
                }`
            );

            setTimeout(async () => {
                if (
                    preferences?.feature.investorRegistrationProcess
                        ?.navigateToCreateTemplateAfterClientRegistration &&
                    endInvestorProfile &&
                    !isEdit
                ) {
                    createSubscriptionTemplate(endInvestorProfile);
                } else {
                    if (currentUser?.user.role === AuthRoleEnum.endInvestor) {
                        await getCurrentUserData();
                        navigate(`/portfolio`);
                    } else {
                        await getAllEndInvestorProfileData();
                        await getEndInvestorProfilesWithoutLoginAccount();
                        navigate(`/clients/${endInvestorProfile._id}`);
                    }
                }
            }, 300);
        } catch (err: any) {
            setErrors(err.result.errors);
            notifyWarning(t('ui.labels.validation_message'));
        }

        setIsSubmitting(false);
    };

    const getInitialValues = () => {
        if (isIndividualDetails) return data.individualDetails;
        if (isBusinessEntityDetails) return data.businessEntityDetails;
        return data;
    };

    const initialValues = getInitialValues();

    useMountEffect(() => {
        const editableSteps = getSteps({ t, data, hasRoles }).filter(
            isStepEditable
        );
        setData({
            ...data,
            kycDocuments: [],
        });
        setStepName(editableSteps[0]?.name);
    });

    useEffect(() => {
        if (data) {
            const editableSteps = getSteps({ t, data, hasRoles }).filter(
                isStepEditable
            );
            setSteps(editableSteps);
        }
        // Due to the amount of hooks in this component, we need to disable the exhaustive-deps rule
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    let errorExists = false;
    return (
        <section>
            <div>
                <h1 className="mt-8 mb-12 text-3xl font-bold text-logo-blue">
                    {t('client.registration.title')}
                </h1>
            </div>

            <div className="flex justify-between">
                <div className="flex-1 max-w-xl">
                    <Formik
                        onSubmit={handleNextStep}
                        initialValues={initialValues}
                        validationSchema={currentStep?.validationSchema || null}
                        validateOnMount
                        validateOnBlur
                        validateOnChange
                        enableReinitialize
                    >
                        {(formProps) => (
                            <Form
                                onSubmit={(event) => {
                                    event.preventDefault();

                                    const errorFields = Object.keys(
                                        formProps.errors
                                    );
                                    errorExists = errorFields.length > 0;
                                    if (errorFields.length) {
                                        const errorPaths =
                                            getObjectPropertiesPaths(
                                                formProps.errors
                                            );

                                        errorPaths.forEach((field) => {
                                            formProps.setFieldTouched(field);
                                        });
                                    } else {
                                        formProps.handleSubmit();
                                    }
                                }}
                            >
                                {currentStep?.component && (
                                    <currentStep.component />
                                )}
                                <div
                                    className={classNames('flex my-4', {
                                        'justify-end':
                                            isCurrentStepNotLast &&
                                            !isCurrentStepNotFirst,
                                        'justify-between':
                                            isCurrentStepNotFirst,
                                    })}
                                >
                                    {isCurrentStepNotFirst && (
                                        <Button
                                            onClick={handleBackStep}
                                            label={t(
                                                'client.registration.previous_step'
                                            )}
                                            buttonType="secondary"
                                        />
                                    )}

                                    <div ref={submitRefButton}>
                                        <Button
                                            type="submit"
                                            label={t('ui.controls.next')}
                                            id="nextStepButton"
                                            disabled={disabledNextStepButton({
                                                values: formProps.values,
                                                currentStep,
                                                isEdit,
                                            })}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div className="self-start flex-1 w-4/5 max-w-xl p-6 mb-4 border shadow-md">
                    <Summary
                        steps={steps}
                        setCurrentStep={setStepName}
                        currentStepName={stepName}
                    />

                    {errors && errors.length > 0 && (
                        <ClientRegistrationErrors errors={errors} />
                    )}

                    <div className="fixed right-0 px-4 py-4 overflow-visible bottom-16 md:bottom-0">
                        <Button
                            label={t('ui.controls.save')}
                            onClick={() => {
                                document
                                    .getElementById('nextStepButton')
                                    ?.click();
                                setTimeout(() => {
                                    !errorExists &&
                                        handleRegistrationSubmission();
                                }, 300);
                            }}
                            disabled={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const ClientRegistrationBreadcrumbs = () => {
    const { t } = useTranslation();

    const getItems = (t: TFunction<'translation', undefined>) => [
        {
            displayText: t('navbar.clients'),
            navigationUrl: '',
        },
    ];

    return <BasicBreadcrumbs items={getItems(t)} />;
};

export default mainLayoutWithBreadcrumbsConfig(
    ClientRegistration,
    undefined,
    <ClientRegistrationBreadcrumbs />
);
