import { ReviewClientSummaryDetailsProps } from '@interfaces/ReviewClientSummaryDetailsProps';
import { EndInvestorProfileResponse } from '@interfaces/Api';

import { DownloadLink } from '@components/Molecules';
import { ClientEnum, getAccountNameObjectReference } from 'common';

interface ReviewExternalReferencesProps {
    endInvestorProfile?: EndInvestorProfileResponse;
    client: ClientEnum;
}

const ReviewExternalReferences = (props: ReviewExternalReferencesProps) => {
    const externalReferences = props.endInvestorProfile?.externalReferences;
    const accountNameObject = getAccountNameObjectReference(props.client);
    const accountNameLabel = accountNameObject?.label;
    const noValue = '<No value saved>';
    return (
        <div className="leading-5 mb-4">
            {externalReferences &&
                externalReferences.map((m, index) => (
                    <p
                        key={`${m.referenceSystem}_${m.referenceValue}_${index}`}
                        className="text-base"
                    >
                        <span className="font-bold">{m.referenceSystem}: </span>
                        <span>{m.referenceValue}</span>
                    </p>
                ))}
            {(!externalReferences ||
                (externalReferences && externalReferences.length === 0)) && (
                <p className="text-base">
                    <span className="font-bold">{accountNameLabel}</span>
                    <span>{noValue}</span>
                </p>
            )}
        </div>
    );
};

export default ReviewExternalReferences;
