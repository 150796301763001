import { useEffect, useRef, useState } from 'react';

import { KebabMenuWrapper } from './KebabMenuWrapper';

interface KebabMenuProps {
    menuItems: { title: string; id: string | number }[];
    onMenuItemClick: (id: string | number) => void;
    dropdownSide?: 'left' | 'right';
    showOptionToAddGroup: boolean;
    selectRow: () => void;
    menuClicked: () => void;
}

const KebabMenu: React.FC<KebabMenuProps> = (props) => {
    const { menuItems, onMenuItemClick, dropdownSide } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropRef = useRef<HTMLDivElement>(null);

    const handleDropDown = (e: Event): void => {
        const target = e.target as HTMLButtonElement;
        if (!target.closest(`.${dropRef?.current?.className}`) && isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDropDown);
        return () => {
            document.removeEventListener('click', handleDropDown);
        };
    });

    return (
        <KebabMenuWrapper
            dropdownSide={dropdownSide}
            showOptionToAddGroup={props.showOptionToAddGroup}
            selectRow={props.selectRow}
        >
            <div role="none">
                {menuItems.map((menuItem, i) => {
                    return (
                        <a
                            key={`${menuItem.id}_${i}`}
                            onClick={() => {
                                onMenuItemClick(menuItem.id);
                                props.menuClicked();
                            }}
                            className="block px-4 py-2 text-sm text-gray-700 cursor-pointer menu-item"
                            role="menuitem"
                            tabIndex={-1}
                            id={`menu-item-${i}`}
                        >
                            {menuItem.title}
                        </a>
                    );
                })}
            </div>
        </KebabMenuWrapper>
    );
};

KebabMenu.defaultProps = {
    dropdownSide: 'right',
};

export default KebabMenu;
