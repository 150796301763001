import { FC, useState } from 'react';
import { Button, Checkbox } from '@components/Atoms';
import { useTranslation } from 'react-i18next';
import { ReviewBox, ReviewFrame } from '@components/Molecules';
import { ClientRegistrationErrors } from '@pages/Clients/Clients/Components/Registration/ClientRegistrationErrors';

interface SubscriptionReadinessValidationProps {
    errors: string[];
    onAbort(): void;
    onAccept(): void;
}

export const SubscriptionReadinessValidation: FC<
    SubscriptionReadinessValidationProps
> = ({ errors, onAbort, onAccept }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div>
                <p className="mb-2">
                    {t('subscriptions.validate_investor.body')}
                </p>
                {errors && errors.length > 0 && (
                    <ClientRegistrationErrors errors={errors} />
                )}
            </div>

            <div className="mt-8 flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={onAbort}
                    label={t('subscriptions.validate_investor.cancel')}
                />

                <Button
                    label={t('subscriptions.validate_investor.edit_profile')}
                    onClick={onAccept}
                />
            </div>
        </div>
    );
};
