import MuiDrawer from '@mui/material/Drawer';

interface DrawerProps {
    isOpen: boolean;
    onClose?: () => void;
    anchor?: 'left' | 'right' | 'top' | 'bottom';
}

export const Drawer: React.FC<DrawerProps> = ({
    isOpen,
    onClose,
    anchor = 'right',
    children,
}) => {
    return (
        <MuiDrawer
            anchor={anchor}
            open={isOpen}
            onClose={() => onClose && onClose()}
            slotProps={{ root: (state) => ({ className: 'bg-white' }) }}
        >
            {children}
        </MuiDrawer>
    );
};
