import * as Yup from 'yup';

import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';

import {
    AddressSelection,
    AdvisorSelection,
    BankAccountsSelection,
    BookingCentreSelection,
    BusinessDetailsSelection,
    ContactDetailsSelection,
    ClientTypeSelection,
    EndInvestorTypeSelection,
    IntermediariesSelection,
    JurisdictionSelection,
    KycSelection,
    LoginAccountSelection,
    NationalitiesSelection,
    NomineeSelection,
    PersonalDetailsSelection,
    SubscriptionProcessSelection,
} from './Components';

import { StepProps } from './Components/Interfaces';
import { t } from 'i18next';
import { getComponent } from '@tenants/index';
import { IdType } from 'common';

export const getStepsConfig = () => {
    const steps: StepProps[] = [
        {
            name: getClientRegistrationFormFields(t).intermediaryId.name,
            component: IntermediariesSelection,
            validationSchema: Yup.object().shape({
                intermediaryId: Yup.string().trim().required(
                    t(
                        'client.registration.intermediary.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).endInvestorType.name,
            component: EndInvestorTypeSelection,
            validationSchema: Yup.object().shape({
                endInvestorType: Yup.string().trim().required(
                    t(
                        'client.registration.investor_type.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).adviserSystemUserId.name,
            component: AdvisorSelection,
            validationSchema: Yup.object().shape({
                adviserSystemUserId: Yup.string().trim().required(
                    t(
                        'client.registration.advisor.client_adviser.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).bookingCentreIds.name,
            component: BookingCentreSelection,
            validationSchema: Yup.object().shape({
                bookingCentreIds: Yup.array().required(
                    t(
                        'client.registration.booking_centres.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: getClientRegistrationFormFields(t).serviceLevelType.name,
            component: getComponent('GetServiceLevelSelection'),
            validationSchema: Yup.object().shape({
                serviceLevelType: Yup.string().trim().required(
                    t(
                        'client.registration.service_level_type.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'subscriptionProcessType',
            component: SubscriptionProcessSelection,
            validationSchema: Yup.object().shape({
                subscriptionProcessType: Yup.string().trim().required(
                    t(
                        'client.registration.subscription_process.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'nomineeEndInvestorProfileId',
            component: NomineeSelection,
            validationSchema: Yup.object().shape({
                nomineeEndInvestorProfileId: Yup.string().trim().required(
                    t('client.registration.nominee.required_validation_message')
                ),
            }),
        },
        {
            name: 'onboardingJurisdictionCountryCode',
            component: JurisdictionSelection,
            validationSchema: Yup.object().shape({
                onboardingJurisdictionCountryCode: Yup.string().trim().required(
                    t(
                        'client.registration.onboarding_jurisdiction.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'clientType',
            component: ClientTypeSelection,
            validationSchema: Yup.object().shape({
                clientType: Yup.string().trim().required(
                    t(
                        'client.registration.client_type.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'isKycCompliant',
            component: KycSelection,
            validationSchema: Yup.object().shape({
                isKycCompliant: Yup.string().trim().required(
                    t(
                        'client.registration.kyc_compliance.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'businessEntityDetails',
            component: BusinessDetailsSelection,
            validationSchema: Yup.object().shape({
                companyName: Yup.string().trim().required(
                    t(
                        'client.registration.company_name.required_validation_message'
                    )
                ),
                registrationNumber: Yup.string().trim().required(
                    t(
                        'client.registration.business_registration.required_validation_message'
                    )
                ),
                subscribersLegalForm: Yup.string().trim().required(
                    t(
                        'client.registration.subscriptions_legal_firm.required_validation_message'
                    )
                ),
                subscribersRegister: Yup.string().trim().required(
                    t(
                        'client.registration.subscribers_register.required_validation_message'
                    )
                ),
                subscriberParentListingAuthority: Yup.string().trim().required(
                    t(
                        'client.registration.subscribers.parent_listing_authority.required_validation_message'
                    )
                ),
                subscribersRegulator: Yup.string().trim().required(
                    t(
                        'client.registration.subscribers.regulator.required_validation_message'
                    )
                ),
                dateOfIncorporation: Yup.string().trim().required(
                    t(
                        'subscriptions.add.source_of_funds.select.required_error_message'
                    )
                ),
                countryCodeOfIncorporation: Yup.string().trim().required(
                    t(
                        'subscriptions.add.source_of_funds.select.required_error_message'
                    )
                ),
                principalPlaceOfBusinessCountryCode: Yup.string().trim().required(
                    t(
                        'subscriptions.add.source_of_funds.select.required_error_message'
                    )
                ),
                areaOfBusinessActivityType: Yup.string().trim().required(
                    t(
                        'subscriptions.add.source_of_funds.select.required_error_message'
                    )
                ),
            }),
        },
        {
            name: 'individualDetails',
            component: PersonalDetailsSelection,
            validationSchema: Yup.object().shape({
                salutation: Yup.string().trim().required(
                    t(
                        'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                    )
                ),
                gender: Yup.string().trim().required(
                    t(
                        'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                    )
                ),
                firstName: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.legal_first_name.required_validation_message'
                    )
                ),
                lastName: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.legal_last_name.required_validation_message'
                    )
                ),
                maritalStatus: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.marital_status.required_validation_message'
                    )
                ),
                occupation: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.occupation.required_validation_message'
                    )
                ),
                dateOfBirth: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.date_of_birth.required_validation_message'
                    )
                ),
                townOfBirth: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.town_of_birth.required_validation_message'
                    )
                ),
                countryCodeOfBirth: Yup.string().trim().required(
                    t(
                        'client.registration.personal_details.country_of_birth.required_validation_message'
                    )
                ),
            }),
        },
        {
            name: 'nationalities',
            component: NationalitiesSelection,
            validationSchema: Yup.object().shape({
                nationalities: Yup.array()
                    .of(
                        Yup.object({
                            countryCode: Yup.string().trim().required(t('client.registration.personal_details.nationality.country_code.required_validation_message')),
                            nationalIdNumber: Yup.string().trim().required(t('client.registration.personal_details.nationality.national_id_number.required_validation_message')),
                            idType: Yup.mixed<IdType>().oneOf(Object.values(IdType))
                                .required(t('client.registration.personal_details.nationality.id_type.required_validation_message')),
                            expiryDate: Yup.date().required(t('client.registration.personal_details.nationality.expiryDate.required_validation_message')),
                        })
                    )
                    .optional()
            }),
        },
        {
            name: 'emailAddress',
            component: ContactDetailsSelection,
            validationSchema: Yup.object().shape({
                emailAddress: Yup.string()
                    .email(
                        getClientRegistrationFormFields(t).emailAddress
                            .invalidErrorMsg
                    )
                    .required(
                        getClientRegistrationFormFields(t).emailAddress
                            .requiredErrorMsg
                    ),
                phoneDiallingCode: Yup.string()
                    .max(
                        5,
                        getClientRegistrationFormFields(t).phoneDiallingCode
                            .invalidErrorMsg
                    )
                    .matches(
                        /^(\+?\d{1,3}|\d{1,4})$/,
                        getClientRegistrationFormFields(t).phoneDiallingCode
                            .matchErrorMsg
                    )
                    .required(
                        getClientRegistrationFormFields(t).phoneDiallingCode
                            .requiredErrorMsg
                    ),
                phoneNumber: Yup.string()
                    .max(18)
                    .required(
                        getClientRegistrationFormFields(t).phoneNumber
                            .requiredErrorMsg
                    ),
            }),
        },
        {
            name: 'addresses',
            component: AddressSelection,
            validationSchema: Yup.object().shape({
                addresses: Yup.array().of(
                    Yup.object().shape({
                        houseName: Yup.string().trim().required(
                            t(
                                'client.registration.addresses.house_name_number.required_validation_message'
                            )
                        ),
                        residentialLine1: Yup.string().trim().required(
                            t(
                                'client.registration.addresses.line1.required_validation_message'
                            )
                        ),
                        city: Yup.string().trim().required('Please enter a city'),
                        postCode: Yup.string().trim().required(
                            t(
                                'client.registration.addresses.postcode.required_validation_message'
                            )
                        ),
                        countryCode: Yup.string().trim().required(
                            t(
                                'client.registration.addresses.country.placeholder'
                            )
                        ),
                    })
                ),
            }),
        },
        {
            name: 'bankAccounts',
            component: BankAccountsSelection,
            validationSchema: Yup.object().shape({
                bankAccounts: Yup.array().of(
                    Yup.object().shape({
                        bankName: Yup.string().trim().required(
                            t(
                                'client.registration.bank_accounts.account_nickname.required_validation_message'
                            )
                        ),
                        countryCurrencyCode: Yup.string().trim().required(
                            t(
                                'client.registration.bank_accounts.currency.required_validation_message'
                            )
                        ),
                        countryCodeOfAccount: Yup.string().trim().required(
                            t(
                                'client.registration.bank_accounts.country_of_bank_account.required_validation_message'
                            )
                        ),
                        IBAN: Yup.string().trim().required(
                            t(
                                'client.registration.bank_accounts.iban.required_validation_message'
                            )
                        ),
                        confirmIBAN: Yup.string().trim().required(t(
                            'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                        )).oneOf(
                            [Yup.ref('IBAN'), null],
                            t(
                                'client.registration.bank_accounts.iban.match_validation_message'
                            )
                        ),
                        accountNumber: Yup.string().trim().required(
                            t(
                                'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                            )
                        ),
                        accountCode: Yup.string().trim().required(
                            t(
                                'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                            )
                        ),
                        accountHolderName: Yup.string().trim().required(
                            t(
                                'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                            )
                        ),
                        bankAddress: Yup.string().trim().required(
                            t(
                                'subscriptions.add.subscription_contacts.add.salutation.required_validation_message'
                            )
                        ),
                        
                    })
                ),
            }),
        },
        {
            name: 'systemUsers',
            component: LoginAccountSelection,
            validationSchema: Yup.object().shape(
                {
                    systemUsers: Yup.array().of(
                        Yup.object().shape({
                            _id: Yup.string().optional(),
                            email: Yup.string().trim().required(t('client.registration.contact_details.email_address.required_validation_message')),
                        })
                    )
                }
            ),
        },
    ];

    return { steps };
};
