import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '@components/Atoms';
import { ReviewFrame, ToggleSwitch } from '@components/Molecules';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { EndInvestorProfileResponse } from '@interfaces/Api';
import ReviewClientProposalsList from './ReviewClientProposalsList';
import ReviewClientSubscriptionsList from './ReviewClientSubscriptionsList';

interface ClientSubscriptionsListProps {
    endInvestorProfile?: EndInvestorProfileResponse;
}

const ClientSubscriptionsList: React.FC<ClientSubscriptionsListProps> = ({
    endInvestorProfile,
}) => {
    const { t } = useTranslation();
    const { configuration } = useConfigurationState();
    const configProposalProcess: boolean | undefined =
        configuration?.preferences?.feature?.proposalProcess;
    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    if (!endInvestorProfile) {
        return (
            <div className="flex justify-center">
                <Loading size="large" />
            </div>
        );
    }

    return (
        <div className="mb-8">
            <div className="my-8 w-full">
                <ReviewClientSubscriptionsList
                    endInvestorProfile={endInvestorProfile}
                />

                {configProposalProcess &&
                    endInvestorProfile?.serviceLevelType ===
                        EndInvestorProfileResponse.ServiceLevelTypeEnum
                            .Advisory && (
                        <ReviewFrame
                            title="Proposals"
                            className="-my-4"
                            expanded={isExpanded}
                        >
                            <ReviewClientProposalsList
                                endInvestorProfile={endInvestorProfile}
                            />
                        </ReviewFrame>
                    )}
            </div>
        </div>
    );
};

export default ClientSubscriptionsList;
