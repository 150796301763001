import { Button, Icons } from '@components/Atoms';
import { FormSelect } from '@components/Molecules';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { getSystemUserFullName } from '@helpers/SystemUser.helper';
import {
    BookingCentreResponse,
    IntermediaryResponse,
    SystemUserResponse,
} from '@interfaces/Api';
import { getBookingCentres } from '@stores/BookingCentres/BookingCentres.services';
import { getAdvisors } from '@stores/SystemUsers/SystemUsers.services';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

interface BookingCentreConfigurationProps {
    name: string;
    formFields: {
        intermediaryId: {
            fieldName: string;
            fieldLabel: string;
        };
        bookingCentreId: {
            fieldName: string;
            fieldLabel: string;
        };
        adviserSystemUserId: {
            fieldName: string;
            fieldLabel: string;
        };
        bookingCentres: {
            fieldName: string;
            fieldLabel: string;
        };
    };
    intermediaries: IntermediaryResponse[];
}

export const BookingCentreConfiguration: React.FC<
    BookingCentreConfigurationProps
> = ({ name, formFields, intermediaries }) => {
    const { values, setFieldValue } = useFormikContext();
    const { investorCountryRequirementTree } = useConfigurationState();
    const countryCodeOptionsData = investorCountryRequirementTree.map((a) => ({
        label: a.name,
        value: a.countryCode,
    }));

    const [bookingCentres, setBookingCentres] = useState<
        BookingCentreResponse[]
    >([]);

    const [advisers, setAdvisers] = useState<{
        [bookingCentreId: string]: SystemUserResponse[];
    }>({});

    const reloadBookingCentres = async (intermediaryId: string) => {
        const bookingCentres = await getBookingCentres(intermediaryId);
        setBookingCentres(bookingCentres);
    };

    useEffect(() => {
        // load booking centres for the chosen intermediary
        // todo
        const intermediaryId = values?.[formFields.intermediaryId.fieldName];
        if (intermediaryId) {
            reloadBookingCentres(intermediaryId);
            updateAdvisers(intermediaryId);
        }
    }, [values?.[formFields.intermediaryId.fieldName]]);

    const updateAdvisers = async (intermediaryId: string) => {
        if (intermediaryId) {
            const advisers = await getAdvisors(intermediaryId);

            const advisersByBookingCentreId = advisers.reduce(
                (acc, adviser) => {
                    if (adviser.bookingCentreId) {
                        if (!acc[adviser.bookingCentreId as string]) {
                            acc[adviser.bookingCentreId as string] = [];
                        }

                        acc[adviser.bookingCentreId as string].push(adviser);
                    }

                    return acc;
                },
                {} as { [bookingCentreId: string]: SystemUserResponse[] }
            );
            setAdvisers(advisersByBookingCentreId);
        }
    };

    return (
        <div>
            <h3 className="border-t text-xl pb-2 pt-2">
                {name} Booking Centre Configuration
            </h3>
            <p className="pb-2">
                Set the default booking centre for investors that are considered{' '}
                {name.toLowerCase()}
            </p>
            <FormSelect
                label={formFields.intermediaryId.fieldLabel}
                name={formFields.intermediaryId.fieldName}
                optionsData={intermediaries.map((intermediary) => ({
                    label: intermediary.name,
                    value: intermediary._id,
                }))}
                onChange={(a) => {
                    setFieldValue(
                        formFields.intermediaryId.fieldName,
                        a.target.value
                    );
                    setFieldValue(
                        formFields.bookingCentreId.fieldName,
                        undefined
                    );
                    setFieldValue(
                        formFields.adviserSystemUserId.fieldName,
                        undefined
                    );
                    setFieldValue(
                        formFields.bookingCentres.fieldName,
                        undefined
                    );

                    if (a.target.value) {
                        reloadBookingCentres(a.target.value);
                    }
                }}
            />

            {values[formFields.intermediaryId.fieldName] &&
                bookingCentres.length > 0 && (
                    <>
                        <FormSelect
                            label={formFields.bookingCentreId.fieldLabel}
                            name={formFields.bookingCentreId.fieldName}
                            optionsData={bookingCentres.map(
                                (bookingCentre) => ({
                                    label: bookingCentre.bookingCentreName,
                                    value: bookingCentre._id,
                                })
                            )}
                        />

                        <FormSelect
                            label={formFields.adviserSystemUserId.fieldLabel}
                            name={formFields.adviserSystemUserId.fieldName}
                            optionsData={(
                                advisers[
                                    values[formFields.bookingCentreId.fieldName]
                                ] || []
                            ).map((systemUser) => ({
                                label: getSystemUserFullName(systemUser),
                                value: systemUser._id,
                            }))}
                        />

                        <div className="border-b py-4 my-4">
                            <p className="pb-2">
                                Set the default booking centre for a certain
                                country that are considered {name.toLowerCase()}
                            </p>

                            <FieldArray
                                name={formFields.bookingCentres.fieldName}
                                render={(arrayHelpers) => {
                                    // get the list of countries that are not selected
                                    const getNonSelectedCountries = (
                                        currentCountry
                                    ) => {
                                        const otherSelectedCountries =
                                            values?.[
                                                formFields.bookingCentres
                                                    .fieldName
                                            ]
                                                .map((a) => a.countryCode)
                                                .filter(
                                                    (a) => a !== currentCountry
                                                ) || [];

                                        // remove otherSelectedCountries from the list of countries
                                        return countryCodeOptionsData.filter(
                                            (a) =>
                                                !otherSelectedCountries.includes(
                                                    a.value
                                                )
                                        );
                                    };

                                    const isLoaded =
                                        values?.[
                                            formFields.bookingCentres.fieldName
                                        ] &&
                                        values?.[
                                            formFields.bookingCentres.fieldName
                                        ].length > 0;

                                    return (
                                        <div>
                                            {isLoaded &&
                                                values?.[
                                                    formFields.bookingCentres
                                                        .fieldName
                                                ].map((_, index) => (
                                                    <div key={index}>
                                                        <div className="flex flex-row gap-3">
                                                            <FormSelect
                                                                label="Country"
                                                                name={`${formFields.bookingCentres.fieldName}.${index}.countryCode`}
                                                                optionsData={getNonSelectedCountries(
                                                                    values?.[
                                                                        formFields
                                                                            .bookingCentres
                                                                            .fieldName
                                                                    ][index]
                                                                        .countryCode
                                                                )}
                                                            />

                                                            <FormSelect
                                                                label="Booking centre"
                                                                name={`${formFields.bookingCentres.fieldName}.${index}.bookingCentreId`}
                                                                optionsData={bookingCentres.map(
                                                                    (
                                                                        bookingCentre
                                                                    ) => ({
                                                                        label: bookingCentre.bookingCentreName,
                                                                        value: bookingCentre._id,
                                                                    })
                                                                )}
                                                                // need to do something on change here
                                                                onChange={(
                                                                    a
                                                                ) => {
                                                                    setFieldValue(
                                                                        `${formFields.bookingCentres.fieldName}.${index}.bookingCentreId`,
                                                                        a.target
                                                                            .value
                                                                    );
                                                                    setFieldValue(
                                                                        `${formFields.bookingCentres.fieldName}.${index}.adviserSystemUserId`,
                                                                        undefined
                                                                    );
                                                                }}
                                                            />

                                                            <FormSelect
                                                                label="Adviser"
                                                                name={`${formFields.bookingCentres.fieldName}.${index}.adviserSystemUserId`}
                                                                optionsData={(
                                                                    advisers[
                                                                        _
                                                                            .bookingCentreId
                                                                    ] || []
                                                                ).map(
                                                                    (
                                                                        systemUser
                                                                    ) => ({
                                                                        label: getSystemUserFullName(
                                                                            systemUser
                                                                        ),
                                                                        value: systemUser._id,
                                                                    })
                                                                )}
                                                            />
                                                            <Button
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                                hasIconOnly
                                                                startIcon={
                                                                    <Icons name="TrashIcon" />
                                                                }
                                                            ></Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            <div className="text-right">
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        arrayHelpers.push({
                                                            countryCode: '',
                                                            bookingCentreId: '',
                                                        })
                                                    }
                                                >
                                                    New Row +
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </>
                )}
        </div>
    );
};

export default BookingCentreConfiguration;
