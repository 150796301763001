import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EndInvestorProfileResponse, SubscriptionResponse } from '@interfaces/Api';

import { ReviewFrame, ToggleSwitch } from '@components/Molecules';

import { SubscriptionStep } from '@services/SubscriptionsHelper';

import { sum } from '@helpers/Math.helper';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { notNull } from '@helpers/typeGuards';

import ReviewBeneficialOwners from './ReviewBeneficalOwners';
import ReviewContacts from './ReviewContacts';
import ReviewDistributionOfFunds from './ReviewDistributionOfFunds';
import ReviewInvestmentDetails from './ReviewInvestmentDetails';
import ReviewPoliticallyExposedPeople from './ReviewPoliticallyExposedPeople';
import ReviewSourceOfFundsAndWealth from './ReviewSourceOfFundsAndWealth';
import ReviewLifeCycleNotices from './ReviewLifeCycleNotices';
import ReviewGermanSemiProfessional from './ReviewGermanSemiProfessional';
import ReviewTaxDeclaration from './ReviewTaxDeclaration';
import { useProduct } from '@stores/Products/useProduct';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import ReviewClientDetails from './ReviewClientDetails';
import ReviewTypeOfInvestment from './ReviewTypeOfInvestment';
import ReviewEconomicOriginOfMonies from './ReviewEconomicOriginOfMonies';

interface SubscriptionReviewSummaryProps {
    subscription: SubscriptionResponse;
    endInvestorProfile: EndInvestorProfileResponse,
    steps: SubscriptionStep[];
}

const ReviewSummary = (props: SubscriptionReviewSummaryProps) => {
    const { t } = useTranslation();
    const { subscription, steps } = props;
    const [showAllSections, setShowAllSections] = useState<boolean>(true);

    const [reviewSections, setReviewSections] = useState<JSX.Element[]>([]);
    const { product } = useProduct(subscription.productId);

    useEffect(() => {
        const sections = steps
            .map((val, i) => {
                if (
                    val.endPathUrl == 'german-semi-professional-questionnaire'
                ) {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title="German Semi Professional Questionnaire"
                            summary={`
                                Private Equity Investments(Has Assets): ${toCapitalizedWords(
                                    String(
                                        subscription
                                            .germanSemiProfessionalCategorisation
                                            ?.privateEquityInvestments?.hasAsset
                                    )
                                )},
                                Direct Investments(Has Assets): ${toCapitalizedWords(
                                    String(
                                        subscription
                                            .germanSemiProfessionalCategorisation
                                            ?.directInvestments?.hasAsset
                                    )
                                )}
                            `}
                            expanded={showAllSections}
                        >
                            <ReviewGermanSemiProfessional
                                germanSemiProfessionalCategorisation={
                                    subscription.germanSemiProfessionalCategorisation
                                }
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'client-details') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t('subscriptions.add.client_details.title')}
                            summary={t(
                                'subscriptions.add.client_details.sub_title'
                            )}
                            expanded={showAllSections}
                        >
                            <ReviewClientDetails endInvestorProfile={props.endInvestorProfile}/>
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'investment-details') {
                    const shareClass = [
                        subscription.shareClass?.name,
                        subscription.shareClass?.type,
                    ]
                        .filter((a) => a !== null)
                        .join(', ');
                    const subscriptionAmount = getFormattedCurrency(
                        subscription.subscriptionAmount,
                        subscription.subscriptionCurrencyCode
                    );
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={
                                t(
                                    'subscriptions.add.investment_details.title'
                                ) +
                                ' - ' +
                                product?.name
                            }
                            summary={`${t(
                                'subscriptions.add.subscription.review_subscription.share_class'
                            )} 
                                ${shareClass}, ${t(
                                'subscriptions.add.subscription.review_subscription.subscription_amount'
                            )} ${subscriptionAmount}`}
                            expanded={showAllSections}
                        >
                            <ReviewInvestmentDetails
                                subscription={subscription}
                                endInvestorProfile={props.endInvestorProfile}
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'source-of-funds-and-wealth') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.source_of_funds.source_of_funds.title'
                            )}
                            summary={`${sum(
                                subscription.sourceOfFundsAndWealth
                                    ?.sourceOfFundsDocuments?.length,
                                subscription.sourceOfFundsAndWealth
                                    ?.sourceOfWealthDocuments?.length
                            )} ${t(
                                'subscriptions.add.subscription.review_subscription.number_of_documents_uploaded'
                            )}`}
                            expanded={showAllSections}
                        >
                            <ReviewSourceOfFundsAndWealth
                                sourceOfFundsAndWealth={
                                    subscription.sourceOfFundsAndWealth
                                }
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl === 'economic-origin-of-monies') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.economic_origin_of_monies.title'
                            )}
                            summary={`${
                                Object.entries(
                                    subscription.economicOriginOfMonies || {}
                                ).filter(([key, value]) => {
                                    return (
                                        value !== null &&
                                        value !== undefined &&
                                        value !== ''
                                    );
                                }).length
                            } fields completed`}
                            expanded={showAllSections}
                        >
                            <ReviewEconomicOriginOfMonies
                                economicOriginOfMonies={
                                    subscription.economicOriginOfMonies
                                }
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl === 'type-of-investment') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.type_of_investment.title'
                            )}
                            summary={`${
                                Object.entries(
                                    subscription.typeOfInvestment || {}
                                ).filter(([key, value]) => {
                                    return (
                                        value !== null &&
                                        value !== undefined &&
                                        value !== ''
                                    );
                                }).length
                            } fields completed`}
                            expanded={showAllSections}
                        >
                            <ReviewTypeOfInvestment
                                typeOfInvestment={subscription.typeOfInvestment}
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'banking-preferences') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.banking_preferences.title'
                            )}
                            summary={
                                subscription?.distributionOfFunds?.bankAccount
                                    ?.bankName
                                    ? `${t(
                                          'subscriptions.add.subscription.review_subscription.bank_info'
                                      )} ${[
                                          subscription?.distributionOfFunds
                                              ?.bankAccount?.bankName,
                                          subscription?.distributionOfFunds
                                              ?.bankAccount?.IBAN,
                                          subscription?.distributionOfFunds
                                              ?.bankAccount
                                              ?.countryCodeOfAccount,
                                      ]
                                          .filter((a) => a)
                                          .join(', ')}`
                                    : t(
                                          'subscriptions.add.subscription.review_subscription.zero_distribution_return_details'
                                      )
                            }
                            expanded={showAllSections}
                        >
                            <ReviewDistributionOfFunds
                                bankAccount={
                                    subscription?.distributionOfFunds
                                        ?.bankAccount
                                }
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'beneficial-owners') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.beneficial_owners.beneficial_owners_sub_heading'
                            )}
                            summary={
                                subscription.beneficialOwnership
                                    ?.beneficialOwners &&
                                subscription.beneficialOwnership
                                    ?.beneficialOwners.length > 0
                                    ? `${[
                                          subscription.beneficialOwnership
                                              ?.beneficialOwners.length,
                                      ].filter((a) => a !== null)} ${t(
                                          'subscriptions.add.subscription.review_subscription.number_of_beneficial_owners'
                                      )}`
                                    : t(
                                          'subscriptions.add.subscription.review_subscription.zero_beneficial_owners'
                                      )
                            }
                            expanded={showAllSections}
                        >
                            <ReviewBeneficialOwners
                                owners={subscription.beneficialOwnership}
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'politically-exposed-people') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.politically_exposed_people.title'
                            )}
                            summary={
                                subscription.politicalExposure
                                    ?.politicalPersons &&
                                subscription.politicalExposure?.politicalPersons
                                    .length > 0
                                    ? `${[
                                          subscription.politicalExposure
                                              ?.politicalPersons.length,
                                      ].filter((a) => a !== null)} ${t(
                                          'subscriptions.add.subscription.review_subscription.number_of_politically_exposed_persons'
                                      )}`
                                    : t(
                                          'subscriptions.add.subscription.review_subscription.zero_politically_exposed_persons'
                                      )
                            }
                            expanded={showAllSections}
                        >
                            <ReviewPoliticallyExposedPeople
                                political={subscription.politicalExposure}
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'subscription-contacts') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title={t(
                                'subscriptions.add.subscription_contacts.title'
                            )}
                            summary={
                                subscription.contacts &&
                                subscription.contacts?.length
                                    ? `${[subscription.contacts?.length].filter(
                                          (a) => a !== null
                                      )} ${t(
                                          'subscriptions.add.subscription.review_subscription.number_of_contacts_registered'
                                      )}`
                                    : t(
                                          'subscriptions.add.subscription.review_subscription.zero_of_contacts_registered'
                                      )
                            }
                            expanded={showAllSections}
                        >
                            <ReviewContacts contacts={subscription.contacts} />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == '') {
                    return (
                        <ReviewFrame
                            key={i.toString()}
                            title="Tax declaration"
                            summary={`${
                                (
                                    subscription.taxInformation?.entity
                                        ?.taxCountries ||
                                    subscription.taxInformation?.individual
                                        ?.taxCountries
                                )?.filter(notNull).length || 0
                            } ${t(
                                'subscriptions.add.subscription.review_subscription.number_of_tax_countries_registered'
                            )}`}
                            expanded={showAllSections}
                        >
                            <ReviewTaxDeclaration
                                taxInformation={subscription.taxInformation}
                            />
                        </ReviewFrame>
                    );
                } else if (val.endPathUrl == 'attestations') {
                    return undefined;
                } else {
                    return undefined;
                }
            })
            .filter(notNull);

        setReviewSections(sections);
    }, [steps, subscription, showAllSections, product?.name]);

    return (
        <>
            <div className="flex justify-end py-3">
                <ToggleSwitch
                    title={t('ui.controls.show_hide_all')}
                    checked={showAllSections}
                    onChange={() => {
                        setShowAllSections(!showAllSections);
                    }}
                />
            </div>

            <ReviewFrame
                title={t(
                    'subscriptions.add.review_subscription.lifecycle_events'
                )}
                summary={
                    subscription?.notices && subscription?.notices.length > 0
                        ? `${[subscription?.notices.length].filter(
                              (a) => a !== null
                          )} ${t(
                              'subscriptions.add.subscription.review_subscription.number_of_lifecycle_events'
                          )}`
                        : t(
                              'subscriptions.add.review_subscription.lifecycle_events.none_found'
                          )
                }
                expanded={showAllSections}
            >
                <ReviewLifeCycleNotices notices={subscription?.notices} />
            </ReviewFrame>

            {reviewSections.map((a, i) => (
                <div key={i.toString()}>{a}</div>
            ))}
        </>
    );
};

export default ReviewSummary;
