interface UploadedDocument {
    fileName: string;
    url: string;
    _id: string;
}

interface StatusLog {
    oldStatus: string;
    newStatus: string;
    updatedBy: string;
    createdAt: string;
    updatedAt: string;
    _id: string;
}

interface ProductDetails {
    name: string;
}

export interface OptUpRequest {
    _id: string;
    endInvestorProfileId: EndInvestorDetails;
    productId: ProductDetails;
    investorJurisdiction: string;
    originalCategory?: string;
    newCategory: string;
    uploadedDocuments: UploadedDocument[];
    comment: string;
    requestedBy: string;
    requestedAt: string;
    status: string;
    statusLogs: StatusLog[];
    createdAt: string;
    updatedAt: string;
    statusComment?: string;
}

interface InvestorDetails {
    firstName: string;
    lastName: string;
}
export interface EndInvestorDetails {
    _id: string;
    individualDetails: InvestorDetails;
    clientType?: string;
}

export enum RequestOptUpStatus {
    Approve = 'approve',
    Reject = 'rejected',
}
