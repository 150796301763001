import { FieldArray, Formik, Form } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { ShareClass } from '@interfaces/Api';
import {
    InvestorLockUpEnum,
    ProductResponse,
} from '@interfaces/Api/ProductResponse';

import { useProductSubmit } from '@hooks/useProductSubmit';

import { Button } from '@components/Atoms';
import { FormCheckbox, Option } from '@components/Molecules';

import EditProductFormikButtons from './EditProductFormikButtons';
import FormikLabels from './ModelProducts';
import { ShareClassInfoItem } from './ShareClassInformatonItem';
import { SortableList } from '@components/Organisms';
import { ModalService } from '@services/ModalService';
import { useProductsState } from '@stores/Products';
import { useTranslation } from 'react-i18next';

const ShareClassInformation: React.FC = () => {
    const { t } = useTranslation(); 
    const { formField } = FormikLabels;

    const initialVals: Partial<ShareClass> = {
        name: '',
        type: '',
        distributionFee: 0,
        currency: '',
        minimumInvestment: 0,
        additionalTerms: [],
        openEndedInformation: {
            investorLockUp: InvestorLockUpEnum.None,
            softLockUpDetails: undefined,
            hardLockUpDetail: undefined,
        },
        isProxy: false,
    };

    const [formValue, setFormValue] = useState<any>(initialVals);

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    const [shareClassGroupList, setShareClassGroupList] = useState<Option[]>([
        { value: '', label: t('admin.product.none') },
    ]);

    const numberErrorMessageShareClass = t('admin.product.shareclass_fee_must_be_number');

    useEffect(() => {
        if (productId && product) {
            setFormValue(product);
        }
    }, [product, productId]);

    const selectedValidationSchema = Yup.object().shape({
        shareClasses: Yup.array().of(
            Yup.object().shape({
                [formField.shareClasses.name.name]: Yup.string().trim().required(
                    `${formField.shareClasses.name.requiredErrorMsg}`
                ),
                [formField.shareClasses.type.name]: Yup.string().trim().required(
                    `${formField.shareClasses.type.requiredErrorMsg}`
                ),
                [formField.shareClasses.distributionFee.name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .required(
                        `${formField.shareClasses.distributionFee.requiredErrorMsg}`
                    ),
                [formField.shareClasses.managementFee.name]:
                    Yup.number().typeError(numberErrorMessageShareClass),
                [formField.shareClasses.minimumInvestment.name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .positive(t('admin.product.minimum_fee_must_be_more_than_0'))
                    .required(
                        `${formField.shareClasses.minimumInvestment.requiredErrorMsg}`
                    ),
                [formField.shareClasses.minimumSubsequentSubscriptionAmount
                    .name]: Yup.number()
                    .typeError(numberErrorMessageShareClass)
                    .positive(
                        t('admin.product.minimum_subscription_fee_must_be_more_than_0')
                    ),
                [formField.shareClasses.currency.name]: Yup.string().trim().required(
                    `${formField.shareClasses.currency.requiredErrorMsg}`
                ),
            })
        ),
    });

    const emptyShareClassDetails = {
        name: '',
        type: '',
        distributionFee: undefined,
        managementFee: undefined,
        currency: '',
        minimumInvestment: undefined,
        additionalTerms: [],
        openEndedInformation: {
            investorLockUp: InvestorLockUpEnum.None,
            softLockUpDetails: [
                {
                    lengthOfTimeInMonths: undefined,
                    redemptionFeePercentage: undefined,
                },
            ],
        },
    };

    const onSubmit = useProductSubmit(productId, `product-images`);

    const grdItemWrapperStyle =
        'grid md:grid-flow-col grid-cols-4 gap-1 justyfy-between';

    if (!(productId === 'new' || (productId && formValue._id))) return null;

    return (
        <div className="w-full">
            <h1 className="text-xl text-gray-500">{t('admin.product.shareclass_information')}</h1>
            <Formik
                initialValues={formValue}
                onSubmit={(values, actions) => onSubmit(values, actions)}
                validationSchema={selectedValidationSchema}
                enableReinitialize
            >
                {({ values, setFieldValue }) => {
                    const containsProxyClass = values?.shareClasses?.some(
                        (shareClass: ShareClass) => shareClass.isProxy
                    );

                    return (
                        <Form>
                            <div className="mb-3">
                                <div className="mt-4">
                                    <FormCheckbox
                                        name="hideDistributionFee"
                                        actionLabel={t('admin.product.do_not_show_distribution_fee')}
                                    />
                                </div>
                                <Button
                                    label={t('admin.product.use_proxy_shareclass')}
                                    buttonType="secondary"
                                    className="w-full my-2"
                                    onClick={() => {
                                        ModalService.getInstance()
                                            .setTitle(t('admin.product.add_proxy_shareclass'))
                                            .setComponent(
                                                <div>
                                                    <div className="font-bold text-red-500	">
                                                        {t('client.risk_warning.warning')}
                                                    </div>
                                                    <p>
                                                        {t('admin.product.proxy_shareclass_description')}
                                                    </p>
                                                    {product?.shareClasses
                                                        .length > 0 && (
                                                        <p className="font-bold mt-3">
                                                            {t('admin.product.proxy_shareclass_warning')}
                                                        </p>
                                                    )}
                                                </div>
                                            )
                                            .setConfirmationLabel('Confirm')
                                            .setShowFooter(true)
                                            .setConfirmCallback(() => {
                                                setFieldValue('shareClasses', [
                                                    {
                                                        ...emptyShareClassDetails,
                                                        isProxy: true,
                                                    },
                                                ]);

                                                ModalService.getInstance().hideModal();
                                            })
                                            .showModal();
                                    }}
                                />
                                <h3 className="text-sm font-normal leading-8 mb-3">
                                    {t('admin.product.please_add_a_shareclass')}
                                </h3>
                                <div className="text-logo-blue">
                                    <FieldArray
                                        name="shareClasses"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.shareClasses &&
                                                    values.shareClasses.length >
                                                        0 && (
                                                        <div>
                                                            <SortableList
                                                                onSorted={(
                                                                    items
                                                                ) =>
                                                                    setFieldValue(
                                                                        'shareClasses',
                                                                        items,
                                                                        false
                                                                    )
                                                                }
                                                                handleClassName="relative -inset-x-5 inset-y-10"
                                                                listItems={
                                                                    values.shareClasses
                                                                }
                                                                listElements={values.shareClasses.map(
                                                                    (
                                                                        shareClass: ShareClass,
                                                                        i: number
                                                                    ) => (
                                                                        <ShareClassInfoItem
                                                                            key={
                                                                                shareClass._id
                                                                            }
                                                                            product={
                                                                                product
                                                                            }
                                                                            shareClass={
                                                                                shareClass
                                                                            }
                                                                            grdItemWrapperStyle={
                                                                                grdItemWrapperStyle
                                                                            }
                                                                            shareClassGroupList={
                                                                                shareClassGroupList
                                                                            }
                                                                            setShareClassGroupList={
                                                                                setShareClassGroupList
                                                                            }
                                                                            formField={
                                                                                formField
                                                                            }
                                                                            i={
                                                                                i
                                                                            }
                                                                            values={
                                                                                values
                                                                            }
                                                                            arrayHelpers={
                                                                                arrayHelpers
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                            />
                                                        </div>
                                                    )}
                                                <div>
                                                    {!containsProxyClass && (
                                                        <Button
                                                            label={`+ ${t('admin.product.add_shareClass')}`}
                                                            buttonType="secondary"
                                                            className="w-full my-2"
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    emptyShareClassDetails
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                    <h4 className="w-full flex py-4 mb-4 justify-end text-xs text-brand-navy">
                                                        <strong>
                                                            <sup className="text-blue-500 text-sm">
                                                                *
                                                            </sup>
                                                            {t('admin.product.distribution_fee_for_intermediary')}
                                                        </strong>
                                                    </h4>
                                                </div>
                                            </>
                                        )}
                                    />
                                </div>
                                <EditProductFormikButtons
                                    productId={productId}
                                    isReadOnly={false}
                                    pathPrevious="product-eligibility-exclusion"
                                    pathNext="product-images"
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ShareClassInformation;
