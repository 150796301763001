import {
    EndInvestorProfileResponse,
    getServiceLevelTypeLabels,
} from '@interfaces/Api';
import { RadioOption } from '@interfaces/InterfaceFormsProps';
import { FormRadioGroup } from '@components/Molecules';

import getClientRegistrationFormFields from '@pages/Clients/Clients/Components/Registration/ClientRegistrationFormFields';
import { useRegisterInvestorState } from '@pages/Clients/Clients/Components/Registration/RegisterInvestor.context';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export const ODDOServiceLevelSelection = () => {
    const { t } = useTranslation();
    const { data } = useRegisterInvestorState();
    const isDirectSelectable = data.endInvestorType === 'nomineeEntity';
    const serviceLevelOptionsData =  [
        {
            label: getServiceLevelTypeLabels(
                t,
                EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory
            ),
            value: EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory,
        }
    ];
    const formikProps = useFormikContext()

    // need to set default value as advisory
    useEffect(() => {
        formikProps.setFieldValue('serviceLevelType', EndInvestorProfileResponse.ServiceLevelTypeEnum.Advisory)
    }, []);

    return (
        <>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.service_level_type.title')}
            </h2>

            <FormRadioGroup
                name={getClientRegistrationFormFields(t).serviceLevelType.name}
                disabled={isDirectSelectable}
                optionsData={serviceLevelOptionsData}
            />
        </>
    );
};
