import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { SubscriptionResponse, SystemUserResponse } from '@interfaces/Api';
import { useRmDetermination } from '@tenants/Pages/ODDO/Hooks/useRmDetermination.helper';
import {
    AuthPermissionEnum,
    ClientEnum,
    CompletenessCheckType,
    RiskLevel,
} from 'common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IOddoPageProperties {
    role: string;
    approval: {
        title: string;
        approveLabel: string;
        rejectLabel: string;
    };
    show: boolean;
    title?: undefined;
    approveLabel?: undefined;
    rejectLabel?: undefined;
}

interface IDefualtPageProperties {
    title: string;
    approveLabel: string;
    rejectLabel: string;
    show: boolean;
    role?: undefined;
    approval?: undefined;
}

//TODO: Move to common util
export const getSystemUserName = ({
    systemUserId,
    systemUsers,
}: {
    systemUserId: string;
    systemUsers: SystemUserResponse[];
}) => {
    if (!systemUserId) return '';
    const systemUser = systemUsers.find((val) => val._id === systemUserId);
    return [systemUser?.firstName, systemUser?.surname]
        .filter((a) => a)
        .join(' ');
};

export const GetPageProperties: () => //TODO: This should not be a jsx component
IDefualtPageProperties | IOddoPageProperties = () => {
    const { currentUser, hasPermissions } = useAuthState();
    const { configuration, isClient } = useConfigurationState();
    const { t } = useTranslation();

    const { isOddoPWMGermany, isOddoPWMFrance, isOddoIFA } = useRmDetermination(
        configuration.client,
        currentUser.user
    );

    const middleOfficeName = useMemo(
        () =>
            (isOddoIFA &&
                t(
                    'subscriptions.completeness_checks.departments.middleOffice'
                )) ||
            (isOddoPWMFrance &&
                t(
                    'subscriptions.completeness_checks.departments.internalControl'
                )) ||
            (isOddoPWMGermany &&
                t('subscriptions.completeness_checks.departments.productTeam')),
        [isOddoIFA, isOddoPWMFrance, isOddoPWMGermany]
    );

    const pageProperties: IOddoPageProperties | IDefualtPageProperties =
        useMemo(() => {
            const properties = [
                {
                    role:
                        (hasPermissions(
                            AuthPermissionEnum.approveSubscriptionForMiddleOffice
                        ) &&
                            middleOfficeName) ||
                        '',
                    approval: {
                        title: t(
                            'subscriptions.completeness_checks.approval.title'
                        ),
                        approveLabel: t('ui.controls.approve'),
                        rejectLabel: t('ui.controls.approve'),
                    },
                    show: isClient(ClientEnum.ODDO),
                },
                {
                    title: t(
                        'subscriptions.completeness_checks.approval.title'
                    ),
                    approveLabel: t('ui.controls.approve'),
                    rejectLabel: t('ui.controls.reject'),
                    show: !isClient(ClientEnum.ODDO),
                },
            ].filter((item) => item.show);

            return properties[0];
        }, [configuration?.client]);

    return pageProperties;
};

export const showRiskReview = ({
    subscription,
    isCaceisLux,
    hasPermissions,
}: {
    subscription: SubscriptionResponse;
    isCaceisLux: boolean;
    hasPermissions: (
        permission: AuthPermissionEnum | AuthPermissionEnum[]
    ) => boolean;
}) => {
    return (
        (subscription?.completenessChecks?.showRiskIndication === true &&
            hasPermissions(
                AuthPermissionEnum.approveSubscriptionForMiddleOffice
            ) &&
            !isCaceisLux) ||
        (subscription?.completenessChecks?.showRiskIndication === true &&
            hasPermissions(
                AuthPermissionEnum.approveSubscriptionForTaAdministrator
            ) &&
            isCaceisLux) ||
        hasPermissions(AuthPermissionEnum.approveSubscriptionForCompliance)
    );
};

export const showAmlKycReview = ({
    subscription,
    completenessCheckStatus,
    hasPermissions,
}: {
    subscription: SubscriptionResponse;
    completenessCheckStatus: CompletenessCheckType;
    hasPermissions: (
        permission: AuthPermissionEnum | AuthPermissionEnum[]
    ) => boolean;
}) => {
    const riskLevels =
        subscription?.completenessChecks?.statusLogs?.filter(
            (check) => check.type === CompletenessCheckType.riskIndication
        ) || [];

    const showAbilityToApproveSubForTA =
        hasPermissions(
            AuthPermissionEnum.approveSubscriptionForTaAdministrator
        ) && (riskLevels || []).at(-1)?.decision !== RiskLevel.high;

    const result = Boolean(
        subscription?.completenessChecks?.status ===
            CompletenessCheckType.requestMoreInformation ||
            (subscription?.completenessChecks?.showAmlKyc &&
                ((completenessCheckStatus ===
                    CompletenessCheckType.complianceAmlKyc &&
                    hasPermissions(
                        AuthPermissionEnum.approveSubscriptionForCompliance
                    )) ||
                    (completenessCheckStatus ===
                        CompletenessCheckType.complianceAmlKyc &&
                        showAbilityToApproveSubForTA) ||
                    (completenessCheckStatus ===
                        CompletenessCheckType.middleOfficeAmlKyc &&
                        hasPermissions(
                            AuthPermissionEnum.approveSubscriptionForMiddleOffice
                        )) ||
                    (completenessCheckStatus ===
                        CompletenessCheckType.taAdministratorAmlKyc &&
                        hasPermissions(
                            AuthPermissionEnum.approveSubscriptionForTaAdministrator
                        )) ||
                    completenessCheckStatus ===
                        CompletenessCheckType.reviewCompleted))
    );
    return result;
};
