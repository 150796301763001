import { FC, useEffect, useCallback, useState, useMemo, useRef } from 'react';

import { Preferences } from '@interfaces/Api/Preferences';

import { portalTypesGet, preferencePut } from '@api/Preferences';

import { Button, Icons, SelectBox } from '@components/Atoms';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { errorToString } from '@helpers/error.helper';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { renderPreferencesTree } from './ProductsManagement/Components/PreferencesTree';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import {
    applicationSettingGet,
    applicationSettingPut,
} from '@api/ApplicationSetting';
import { FormFieldWrapper } from '@components/Molecules';
import { PortalTypeEnum } from 'common';

export const PreferenceSettings = () => {
    const { preferences: preferencesOnServer } = useConfigurationState();

    const [preferences, setPreferences] = useState<Preferences | undefined>(
        undefined
    );
    const portalTypeOnServer = useRef<PortalTypeEnum | string>();

    const [portalType, setPortalType] = useState<PortalTypeEnum | string>('');
    const [portalTypes, setPortalTypes] = useState<Record<string, Preferences>>(
        {}
    );

    const optionsData = useMemo(() => {
        return Object.keys(portalTypes).map((type) => ({
            label: toCapitalizedWords(type),
            value: type,
        }));
    }, [portalTypes]);

    useEffect(() => {
        setPreferences(preferencesOnServer);
    }, [preferencesOnServer]);

    useEffect(() => {
        portalTypesGet()
            .then((response) => setPortalTypes(response))
            .catch((error) => notifyError(errorToString(error)));
    }, []);

    useEffect(() => {
        applicationSettingGet()
            .then((response) => {
                portalTypeOnServer.current = response?.portalType ?? '';
                setPortalType(portalTypeOnServer.current);
            })
            .catch((error) => notifyError(errorToString(error)));
    }, []);

    const handleUpdate = useCallback(() => {
        if (portalTypeOnServer.current !== portalType) {
            applicationSettingPut({
                portalType: portalType as PortalTypeEnum,
            })
                .then((response) => {
                    portalTypeOnServer.current = response?.portalType ?? '';
                    setPortalType(portalTypeOnServer.current);
                })
                .catch((error) => errorToString(error));
        }

        if (preferences) {
            preferencePut(preferences)
                .then(() => {
                    notifySuccess('Preferences have been updated');
                    // programatically refresh the preferences page
                    window.location.reload();
                })
                .catch((error) => notifyError(errorToString(error)));
        }
    }, [portalType, preferences]);

    const handlePortalTypeChange = useCallback(
        (event) => {
            setPortalType(event.target.value ? event.target.value : undefined);
            setPreferences(
                portalTypes[event.target.value] ?? preferencesOnServer
            );
        },
        [portalTypes, preferencesOnServer]
    );

    return (
        <div className="pb-4">
            <div className="w-full">
                <h1 className="text-3xl text-logo-blue font-bold">
                    Preference Settings
                </h1>
            </div>
            {optionsData.length > 0 && (
                <div className="relative mt-4">
                    <FormFieldWrapper
                        rightIcon={<Icons name="ChevronDown" size="small" />}
                        label="You can quickly set up use case based preferences setting by select one of the following items"
                    >
                        <SelectBox
                            name="preferenceSetName"
                            value={portalType}
                            onChange={handlePortalTypeChange}
                            optionsData={optionsData}
                        />
                    </FormFieldWrapper>
                </div>
            )}

            {renderPreferencesTree(preferences?.feature, () => {
                if (preferences) {
                    setPreferences({ ...preferences });
                }
            })}
            <div className="right-4 bottom-24 fixed">
                <Button onClick={handleUpdate}>Update Preferences</Button>
            </div>
        </div>
    );
};
