import { IntermediaryResponse } from '@interfaces/Api';
import { Http } from '@libs/http';
import { BaseUrl } from '@helpers/Environment';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { IntermediariesStore } from './Intermediaries.store';

const BASE_URL = `${BaseUrl}/intermediary`;

export const handleFetchIntermediary = async (
    set,
    get,
    intermediaryId: string
) => {
    try {
        set({ isLoading: true });

        const intermediariesInStore = get().intermediaries;

        const foundIntermediary = intermediariesInStore.find(
            (intermediary) => intermediary._id === intermediaryId
        );

        if (foundIntermediary) {
            set({ isLoading: false });
            return intermediariesInStore;
        }

        const intermediary: IntermediaryResponse = await Http.get(
            `${BASE_URL}/${intermediaryId}`
        );

        const intermediaries = [...intermediariesInStore, intermediary];

        set(
            {
                intermediaries,
            },
            false,
            'fetchIntermediary'
        );
    } catch (_) {
        notifyError('An error has occurred, distributor has not been loaded');
    }

    set({ isLoading: false });
};

export const handleFetchIntermediaries = async (set) => {
    try {
        set({ isLoading: true });
        const intermediaries: IntermediaryResponse[] = await Http.get(BASE_URL);
        set({ intermediaries }, false, 'fetchIntermediaries');
    } catch (_) {
        notifyError('An error has occurred, distributors have not been loaded');
    }

    set({ isLoading: false });
};

export const handleAddIntermediary = async (set, intermediary) => {
    try {
        const response: IntermediaryResponse = await Http.post(
            BASE_URL,
            intermediary
        );

        set(
            (state: IntermediariesStore) => ({
                intermediaries: [...state.intermediaries, response],
            }),
            false,
            'addIntermediary'
        );

        notifySuccess(
            'Distributor added successfully, now creating the user',
            3000
        );

        return response;
    } catch (_) {
        notifyError(
            'An error has occurred, a new distributor has not been added'
        );
    }
};

export const handleUpdateIntermediary = async (set, intermediary) => {
    try {
        const response: IntermediaryResponse = await Http.put(
            BASE_URL,
            intermediary
        );

        set(
            (state: IntermediariesStore) => {
                const copyIntermediaries = JSON.parse(
                    JSON.stringify(state.intermediaries)
                );
                const index = copyIntermediaries.findIndex(
                    (intermediary) => intermediary._id === response._id
                );

                if (index !== -1) {
                    copyIntermediaries[index] = response;
                }

                return {
                    intermediaries: copyIntermediaries,
                };
            },
            false,
            'updateIntermediary'
        );

        notifySuccess('Distributor updated successfully', 3000);
    } catch (_) {
        notifyError(
            'An error has occurred, a existing distributor has not been updated'
        );
    }
};
