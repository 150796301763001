import { useRef, useState } from 'react';
import classNames from 'classnames';

import { Icons } from '@components/Atoms';

interface KebabMenuProps {
    children: React.ReactNode;
    dropdownSide?: 'left' | 'right';
    size?: 'sm' | 'md';
    showOptionToAddGroup: boolean;
    selectRow: () => void;
}

export const KebabMenuWrapper = (props: KebabMenuProps) => {
    const { dropdownSide, size = 'md' } = props;
    const dropRef = useRef<HTMLDivElement>(null);

    return (
        <div
            ref={dropRef}
            className="relative dropdown menu-item-container"
            data-toggle="kebab-menu"
        >
            <button
                id="kebab-menu"
                type="button"
                className="p-2 rounded-full hover:bg-gray-300"
                onClick={(e) => {
                    props.selectRow();
                }}
            >
                <Icons name="EllipsisVerticalIcon" strokeWidth="1.5" />
            </button>

            {props.showOptionToAddGroup && (
                <div
                    className={classNames(
                        'origin-top-right absolute z-10',
                        dropdownSide == 'left' && 'right-0',
                        dropdownSide == 'right' && 'left-0',
                        size == 'md' && 'w-56',
                        size == 'sm' && 'w-28',
                        ' mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                    )}
                    aria-orientation="vertical"
                    aria-labelledby="kebab-menu"
                    tabIndex={-1}
                    role="menu"
                >
                    {props.children}
                </div>
            )}
        </div>
    );
};
