import { useApplicationSetting } from '@hooks/useApplicationSetting';

const UnsuccessfulSubmission: React.FC = () => {
    const { applicationSetting } = useApplicationSetting();

    return (
        <div className="mt-3 flex flex-col items-center">
            <h2 className="text-2xl logo-blue align-middle pb-4">
                Application unsuccessful
            </h2>
            <p>
                Unfortunately, based on your responses, your application has not
                been approved. If you require any support, please reach out to a
                member of the team at&nbsp;
                <a
                    href={`mailto:${applicationSetting?.wealthSupportContactEmail}`}
                >
                    {applicationSetting?.wealthSupportContactEmail}
                </a>
                .
            </p>
        </div>
    );
};

export default UnsuccessfulSubmission;
