import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClientEnum } from "common";
import { Button, Icons, SelectBox } from '@components/Atoms';
import { FormFieldWrapper, Logo, Modal } from '@components/Molecules';
import PasswordForm from '@pages/Password/PasswordForm';
import { useUserInfo } from './useUserInfo';
import { getRoleName } from '@helpers/SystemUser.helper';
import { SystemUsersSwitchRolePut } from '@stores/SystemUsers/SystemUsers.services';;
import { notifyError } from '@helpers/toastrHelper';
import { errorToString } from '@helpers/error.helper';
import classnames from 'classnames';
import { AuthRoleEnum } from '@interfaces/Api';

const UserInfo: React.FC = () => {
    const {
        initials,
        fullName,
        handleLogout,
        isDropDownOpen,
        toggleDropDownOpen,
        isPrivacyLinkVisible,
        canChangePassword,
        lastLoginDate,
        platformName,
        isClient,
        role,
        availableRoles,
    } = useUserInfo();

    const { t } = useTranslation();

    const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);

    const roleOptions = useMemo(() => {
        const mergedRoles = availableRoles?.concat(role as AuthRoleEnum) || [];
        return mergedRoles.map((item) => ({
            label: getRoleName(item),
            value: item,
        }));
    }, [availableRoles, role]);

    const handleRoleChange = useCallback((event) => {
        const newValue = event.target.value;

        SystemUsersSwitchRolePut(newValue)
            .then(() => {
                window.location.href = '/';
            })
            .catch((error) => notifyError(errorToString(error)));
    }, []);

    const handleClick = useCallback((event) => {
        event.stopPropagation();
    }, []);

    return (
        <div
            className="order-2 md:order-3 flex flex-wrap items-end mr-0 md:mr-0 lg:ml-auto"
            id="nav-content"
        >
            <div className={isClient(ClientEnum.VP) ? 'flex items-center' : ''}>
                {isClient(ClientEnum.VP) && (
                    <p className="mr-2 text-base text-logo-blue w-28 md:w-auto">
                        {platformName}
                    </p>
                )}
                <div
                    className="relative"
                    data-toggle="menu-button"
                    tabIndex={0}
                    onClick={toggleDropDownOpen}
                >
                    <Button
                        id="menu-button"
                        buttonType="secondary"
                        endIcon={<Icons name="ChevronDown" size="small" />}
                        className="bg-brand-navy-light text-logo-blue h-10 border-0"
                        tabIndex={1}
                        label={initials}
                    />

                    {isDropDownOpen && (
                        <div onMouseLeave={toggleDropDownOpen}>
                            <ul
                                className="fixed left-0 md:left-auto md:absolute z-9999 -mt-14 -mr-14 md:-mr-6 px-5 py-5 md:p-5 shadow flex flex-col right-0 bg-white w-screen md:w-48 h-screen md:h-auto min-w-max"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="menu-button"
                                tabIndex={2}
                            >
                                <Logo className="md:hidden w-full absolute left-2 top-6 md:relative md:left-0 md:top-0 -mt-3 lg:w-20 flex items-center justify-start px-1 md:px-0 py-1" />
                                <Button
                                    id="collapse-button"
                                    buttonType="primary"
                                    label={fullName}
                                    isHoverDisabled
                                    className="absolute right-5 md:relative md:right-0"
                                    endIcon={
                                        <Icons name="ChevronUp" size="small" />
                                    }
                                />
                                {roleOptions.length > 1 && (
                                    <li className="bg-white justify-start mt-12 md:mt-5">
                                        <div className="relative w-full cursor-default">
                                            <FormFieldWrapper label="View as">
                                                <SelectBox
                                                    name="role"
                                                    value={role}
                                                    optionsData={roleOptions}
                                                    onChange={handleRoleChange}
                                                    onClick={handleClick}
                                                />
                                            </FormFieldWrapper>
                                        </div>
                                    </li>
                                )}

                                <li
                                    className={classnames(
                                        'bg-white justify-start md:mt-5',
                                        {
                                            'mt-4': roleOptions.length > 0,
                                            'mt-12': roleOptions.length <= 0,
                                        }
                                    )}
                                >
                                    <a
                                        href="#/"
                                        className="flex p-0 align-text-bottom text-2xl m-0 text-left self-start items-center bg-transparent hover:bg-transparent active:bg-transparent focus:bg-transparent"
                                        onClick={handleLogout}
                                    >
                                        <span className="mr-2">{t('logout')}</span>
                                        <Icons
                                            name="ArrowRightOnRectangleIcon"
                                            size="small"
                                        />
                                    </a>
                                </li>
                                <li className="text-xs text-logo-blue pt-3">
                                    <p className="py-2">
                                        {t('user_info.last_login') + ' '}
                                        <span className="font-bold">
                                            {lastLoginDate}
                                        </span>
                                    </p>
                                </li>
                                {/* {canChangePassword && (
                                    // <li className="border-t border-b border-gray-200 py-2">
                                    //     <button
                                    //         id="change-password"
                                    //         onClick={() => {
                                    //             setShowPasswordModal(true);
                                    //         }}
                                    //         className="w-full px-1 text-xs text-logo-blue flex items-center cursor-pointer"
                                    //     >
                                    //         <span className="mr-2">
                                    //             <Icons
                                    //                 name="LockClosedIcon"
                                    //                 size="small"
                                    //             />
                                    //         </span>
                                    //         <span className="text-left w-5/6">
                                    //             Change Password{' '}
                                    //             <span className="sr-only">
                                    //                 Opens in a new window.
                                    //             </span>
                                    //         </span>
                                    //     </button>
                                    // </li>
                                )} */}

                                {isPrivacyLinkVisible && (
                                    <li className="border-b border-gray-200 py-2">
                                        <Link
                                            className="w-full px-1 flex items-center cursor-pointer"
                                            to="/privacy-document"
                                            rel="noreferrer"
                                        >
                                            <span className="mr-2">
                                                <Icons
                                                    size="small"
                                                    name="DocumentIcon"
                                                />
                                            </span>
                                            <span className="text-xs text-logo-blue">
                                                {t('user_info.privacy_document')}
                                            </span>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}

                    <Modal
                        show={showPasswordModal}
                        width="w-1/2 lg:w-5/12 xl:w-3/12"
                    >
                        <Modal.Header>
                            <div>Change password</div>
                        </Modal.Header>
                        <Modal.Body>
                            <PasswordForm
                                closeForm={() => setShowPasswordModal(false)}
                            />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default UserInfo;
