import {
    EndInvestorProfilesGetAll,
    EndInvestorProfilesGetAllWithoutLoginAccount,
} from '@api/EndInvestorProfiles';
import { Loading } from '@components/Atoms';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { getRiskLevelFromSubscription } from '@helpers/common.helper';
import { useMountEffect } from '@hooks/useMountEffect';
import {
    EndInvestorProfileResponse,
    SubscriptionStatusEnum,
    SystemUserResponse,
} from '@interfaces/Api';
import {
    SubscriptionGridItem,
    SubscriptionsGrid,
} from '@pages/Subscriptions/SubscriptionsGrid';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { useProducts } from '@stores/Products/useProducts';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';
import { getSystemUsersByIds } from '@stores/SystemUsers/SystemUsers.services';
import { CompletenessCheckType } from 'common';
import { useEffect, useMemo, useState } from 'react';

const SubscriptionsToolsView = () => {
    const { subscriptions, isLoading } = useSubscriptions();
    const { products } = useProducts();
    const [endInvestorProfiles, setEndInvestorProfiles] =
        useState<EndInvestorProfileResponse[]>();

    const { intermediaries } = useIntermediaries();

    const [gridSubscriptions, setGridSubscriptions] = useState<
        SubscriptionGridItem[]
    >([]);
    const [adviserSystemUsers, setAdviserSystemUsers] =
        useState<SystemUserResponse[]>();
    const [isSubscriptionListLoading, setIsSubscriptionListLoading] =
        useState<boolean>();

    useMountEffect(() => {
        (async () => {
            const endInvestorProfilesWithLoginAccount =
                await EndInvestorProfilesGetAll();
            const endInvestorProfilesWithoutLoginAccount =
                await EndInvestorProfilesGetAllWithoutLoginAccount();

            setEndInvestorProfiles([
                ...endInvestorProfilesWithLoginAccount,
                ...endInvestorProfilesWithoutLoginAccount,
            ]);
        })();
    });

    const loadSystemUsers = async (ids) => await getSystemUsersByIds(ids);

    useMemo(() => {
        if (!endInvestorProfiles?.length) return;

        const uniqueSystemUserIds = [
            ...new Set(
                endInvestorProfiles
                    .map((profile) => profile.adviserSystemUserId)
                    .filter((id) => id)
            ),
        ];

        if (!uniqueSystemUserIds.length) return;

        loadSystemUsers(uniqueSystemUserIds).then((users) =>
            setAdviserSystemUsers(users)
        );
    }, [endInvestorProfiles]);

    useEffect(() => {
        if (
            !subscriptions &&
            !products &&
            !endInvestorProfiles &&
            !adviserSystemUsers
        )
            return;

        setIsSubscriptionListLoading(true);

        const subscriptionListItems = subscriptions.map((subscription) => {
            const product = products.find(
                (val) => val._id === subscription.productId
            );

            const endInvestorProfile: EndInvestorProfileResponse =
                endInvestorProfiles?.length &&
                endInvestorProfiles.find(
                    (investor) =>
                        subscription.endInvestorProfileId === investor._id
                );

            const distributor = intermediaries.find(
                (i) => i._id === endInvestorProfile?.intermediaryId
            )?.name;

            const rmUser = adviserSystemUsers?.find(
                (su) => su._id === endInvestorProfile?.adviserSystemUserId
            );

            const relationshipManager =
                rmUser?.firstName + ' ' + rmUser?.surname;
            const creationDate = subscription.statusLogs.find(
                (f) => f.status === SubscriptionStatusEnum.inProgress
            )?.createdAt;
            return {
                subscriptionId: subscription._id,
                subscriptionUrl: `/subscriptions/${subscription._id}/`,
                status: subscription.status,
                endInvestorName: getEndInvestorName(endInvestorProfile),
                product,
                shareClass: subscription.shareClass,
                subscriptionAmount: subscription.subscriptionAmount,
                subscriptionCurrencyCode: subscription.subscriptionCurrencyCode,
                statusLogs: subscription.statusLogs,
                signingDocuments: subscription.signingDocuments,
                closingDate: subscription.closingDate,
                workflowStatus: subscription.completenessChecks?.status,
                distributor,
                relationshipManager,
                creationDate: creationDate,
                latestTimeActivity: subscription.latestTimeActivity,
                riskLevel: getRiskLevelFromSubscription(subscription),
            } as SubscriptionGridItem;
        });

        setGridSubscriptions(subscriptionListItems);
        setIsSubscriptionListLoading(false);
    }, [
        subscriptions,
        products,
        endInvestorProfiles,
        adviserSystemUsers,
        intermediaries,
    ]);

    return (
        <div className="mb-6">
            <div>
                {isLoading || isSubscriptionListLoading ? (
                    <Loading size="large" />
                ) : (
                    <div>
                        <SubscriptionsGrid
                            gridName="SubscriptionsToolsView"
                            subscriptions={gridSubscriptions}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionsToolsView;
