import React, { createContext, useContext, useCallback } from 'react';
import { Document } from '@interfaces/Api';
import { BaseUrl } from '@helpers/Environment';

export interface StorageTokenContextProps {
    withToken: (url: string) => string;
    arrayWithToken: (arr: Document[]) => Document[];
}

const storageTokenDefaultState: StorageTokenContextProps = {
    withToken: (url: string) => url,
    arrayWithToken: (arr: Document[]) => arr,
};

export const StorageTokenContext = createContext<StorageTokenContextProps>(
    storageTokenDefaultState
);

export const useStorageTokenState = () => {
    return useContext(StorageTokenContext);
};

export const StorageTokenProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const withToken = useCallback((url: string) => {
        const publicStorageDomain =
            'https://publiccontents.blob.core.windows.net';

        if (
            url &&
            !url.includes('api') &&
            url.indexOf(publicStorageDomain) > -1
        ) {
            return url.replace(publicStorageDomain, `${BaseUrl}/images`);
        }

        if (url) {
            const parsedURL = new URL(url);
            const storagePrefixToRemove = `${parsedURL.protocol}//${parsedURL.host}`;
            const newUrl = url.replace(
                storagePrefixToRemove,
                `${BaseUrl}/documents`
            );

            return newUrl;
        }
    }, []);

    const arrayWithToken = useCallback(
        (arr: Document[]) => {
            arr.map((f) => ({
                ...f,
                url: f.url ? withToken(f.url) : f.url,
            }));
            return arr;
        },
        [withToken]
    );

    return (
        <StorageTokenContext.Provider
            value={{
                withToken,
                arrayWithToken,
            }}
        >
            {children}
        </StorageTokenContext.Provider>
    );
};
