import { Button, IconUpload } from '@components/Atoms';
import React from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const supportedImages = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/webp',
];

export const acceptedExtensionsList = [
    ...supportedImages,
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/csv',
    '.csv',
    'text/csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/json',
];

const acceptedImageExtensions = supportedImages.join(', ');
const acceptedExtensions = acceptedExtensionsList.join(', ');

interface FileDropperProps {
    name: string;
    inputFileChangeHandler: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    onDrop: <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => void;
    imageOnly: boolean;
    supportedFileTypes?: string[];
    disabled?: boolean;
}

const FileDropper: React.FC<FileDropperProps> = (props) => {
    const { t } = useTranslation();
    const {
        name,
        inputFileChangeHandler,
        onDrop,
        imageOnly,
        supportedFileTypes,
    } = props;
    // TODO: work out below
    const maxFiles = 0;

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const { getRootProps, isDragActive } = useDropzone({
        maxFiles,
        onDrop: onDrop,
    });

    return (
        <div
            {...getRootProps()}
            className="relative w-full p-4 mb-4 bg-gold-300 border-2 border-dashed rounded-xl z-9"
        >
            <div className="mt-8 space-y-3">
                <div className="h-full w-full text-center flex flex-col items-center justify-center p-2">
                    <div className="flex flex-auto max-h-48 mx-auto -mt-10 mb-3">
                        <IconUpload />
                    </div>

                    {isDragActive ? (
                        <p className="pointer-none text-logo-blue text-lg">
                            {`Drop file${maxFiles > 1 ? 's' : ''} here`}
                        </p>
                    ) : (
                        <p className="pointer-none text-logo-blue text-xs">
                            {t('file_upload.drag_and_drop_or')}
                        </p>
                    )}
                </div>
                <div className="flex justify-center">
                    {isDragActive || (
                        <Button
                            type="button"
                            buttonType="primary"
                            disabled={props.disabled}
                            label={t('file_upload.browse_button')}
                            onClick={() => {
                                hiddenFileInput.current!.click();
                            }}
                        />
                    )}
                </div>
                {imageOnly && (
                    <p className="text-center text-logo-blue text-xs">
                        {t('file_upload.supported_image_formats')}:{' '}
                        {supportedImages
                            .map((type) => type.replace('image/', ''))
                            .join(', ')}
                    </p>
                )}
                {supportedFileTypes && supportedFileTypes.length > 0 && (
                    <p className="text-center text-logo-blue text-xs">
                        {t('file_upload.supported_file_formats')}:{' '}
                        {supportedFileTypes.join(', ')}
                    </p>
                )}
            </div>

            <input
                type="file"
                className="hidden"
                name={name + 'initial-upload'}
                ref={hiddenFileInput}
                accept={
                    imageOnly ? acceptedImageExtensions : acceptedExtensions
                }
                onChange={inputFileChangeHandler}
                onClick={(event) => {
                    event.currentTarget.value = '';
                }}
            />
        </div>
    );
};

export default FileDropper;
