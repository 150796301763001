import { CSSProperties, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { Icons } from '@components/Atoms';

interface ModalProps {
    show: boolean;
    onBackdropClick?: MouseEventHandler<HTMLDivElement>;
    width?: string;
    className?: string;
    hideBackground?: boolean;
}

interface ModalSubComponents {
    Header: React.FC<ModalHeader>;
    Body: React.FC<ModalBody>;
    Footer: React.FC<ModalFooter>;
}

const Modal: React.FC<ModalProps> & ModalSubComponents = (props) => {
    const { show, width = 'w-80', children, className = '' } = props;

    return (
        <>
            {show && (
                <div
                    className={classNames(
                        'fixed top-0 left-0 w-screen h-screen bg-logo-blue-light flex z-50 cursor-default',
                        props.hideBackground
                            ? 'bg-opacity-100'
                            : 'bg-opacity-20'
                    )}
                    onClick={props.onBackdropClick}
                >
                    <div
                        id="modal-app"
                        onClick={(e) => e.stopPropagation()}
                        className={classNames(
                            className,
                            'w-11/12',
                            [`md:${width}`],
                            'border border-gray-200 rounded-lg m-auto bg-white',
                            'flex flex-col w-full'
                        )}
                    >
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

interface ModalHeader {
    onClose?: () => void;
}

const Header: React.FC<ModalHeader> = (props) => (
    <div className="flex items-center justify-between w-full p-4 border-b border-sky-100">
        <h2 className="text-2xl align-middle logo-blue">{props.children}</h2>

        {props.onClose && (
            <div className="cursor-pointer" onClick={props.onClose}>
                <Icons name="XMarkIcon" strokeWidth="2" />
            </div>
        )}
    </div>
);
Modal.Header = Header;

interface ModalBody {
    className?: string;
    style?: CSSProperties;
}
const Body: React.FC<ModalBody> = (props) => (
    <div
        className={classNames(
            'p-4 max-h-96 overflow-y-auto overscroll-contain',
            props.className
        )}
        style={props.style}
    >
        {props.children}
    </div>
);
Modal.Body = Body;

interface ModalFooter {
    className?: string;
}
const Footer: React.FC<ModalFooter> = (props) => (
    <div
        className={classNames(
            `p-2 flex border-t border-sky-100 justify-between`,
            props.className
        )}
    >
        {props.children}
    </div>
);
Modal.Footer = Footer;

export default Modal;
