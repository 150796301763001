import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';

import { AuthRoleEnum, SystemUserResponse } from '@interfaces/Api';
import { AssetManager } from '@interfaces/Api/AssetManager';

import { getAllAssetManagers } from '@services/AssetManagers';

import { Button } from '@components/Atoms';
import {
    FormInputBox,
    FormSelect,
    FormMultiselect,
} from '@components/Molecules';

import {
    SystemUsersPost,
    SystemUsersPut,
} from '@stores/SystemUsers/SystemUsers.services';

import { useAuthState } from '@contexts/AuthContext';
import { useSystemUsersActions } from '@stores/SystemUsers';
import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';

import { errorToString } from '@helpers/error.helper';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { trimStringInObject } from '@helpers/formik.helper';
import { useFieldsConfig } from './useFieldsConfig';
import { Option } from '@components/Molecules/MultiselectCreatable';
import {
    isAssetManagerRequiredForRoles,
    isAssetManagerRequiredForThisRole,
    isBookingCenterRequiredForRoles,
    isBookingCenterRequiredForThisRole,
    isIntermediaryRequiredForRoles,
    isIntermediaryRequiredForThisRole,
    isTransferAgentRequiredForThisRole,
    transformAvailableRolesOptionsToStringArr,
} from './util';
import { ITransferAgent } from 'common';
import { getTransferAgents } from '@api/TransferAgent';
import { useTranslation } from 'react-i18next';

export const MutateSystemUser = () => {
    const {t} = useTranslation();
    const { userId } = useParams();

    const { systemUsers } = useSystemUsers();
    const { fetchSystemUsers } = useSystemUsersActions();
    const { intermediaries } = useIntermediaries();
    const { currentUser } = useAuthState();
    const [assetManagers, setAssetManagers] = useState<AssetManager[]>([]);
    const [transferAgents, setTransferAgents] = useState<ITransferAgent[]>([]);

    const [selectedIntermediaryId, setSelectedIntermediaryId] =
        useState<string>('');

    const { bookingCentres } = useBookingCentres(selectedIntermediaryId);
    const isEdit = Boolean(userId);

    const { fields, initialValues } = useFieldsConfig({
        assetManagers,
        transferAgents,
        intermediaries,
        bookingCentres,
        setSelectedIntermediaryId,
        isEdit,
        systemUsers,
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().trim().required(t('admin.user_management.first_name_is_required')),
        surname: Yup.string().trim().required(t('admin.user_management.last_name_is_required')),
        email: Yup.string()
            .email(t('admin.user_management.invalid_email'))
            .required(t('client.registration.contact_details.email_address.required_validation_message')),
        role: Yup.string().trim().required(t('admin.user_management.role_is_required')),
        intermediaryId: Yup.string().when('role', {
            is: (role: string) => isIntermediaryRequiredForThisRole(role),
            then: Yup.string().trim().required(t('admin.user_management.intermediary_is_required')),
        }),
        bookingCentreId: Yup.string().when('intermediaryId', {
            is: (role: string) => isBookingCenterRequiredForThisRole(role),
            then: Yup.string().trim().required(t('admin.user_management.booking_center_is_required')),
        }),
        assetManagerId: Yup.string().when('role', {
            is: (role: string) => isAssetManagerRequiredForThisRole(role),
            then: Yup.string().trim().required(t('admin.user_management.asset_manager_is_required')),
        }),
        profiles: Yup.string().when(['role', 'availableRoles'], {
            is: (role: string, availableRoles: any[]) =>
                role === AuthRoleEnum.profileManager ||
                role === AuthRoleEnum.profileAdministrator ||
                availableRoles?.some(
                    (el: any) => el.value === AuthRoleEnum.profileManager
                ) ||
                availableRoles?.some(
                    (el: any) => el.value === AuthRoleEnum.profileAdministrator
                ),
            then: Yup.string().trim().required(t('admin.user_management.profile_is_required')),
        }),
    });

    const navigate = useNavigate();

    const submitHandler = useCallback(
        (values: any, actions: any) => {
            const mutateMap = isEdit
                ? {
                      func: SystemUsersPut,
                      successMessage: t('admin.user_management.user_details_updated'),
                      failMessage: t('admin.user_management.could_not_update_user_detail'),
                  }
                : {
                      func: SystemUsersPost,
                      successMessage: t('admin.user_management.new_user_is_created'),
                      failMessage: t('admin.user_management.fail_to_create_new_user'),
                  };

            const availableRolesStringArr =
                values.role === AuthRoleEnum.superUser
                    ? []
                    : transformAvailableRolesOptionsToStringArr(values);

            const isIntermediaryRequired =
                isIntermediaryRequiredForThisRole(values.role) ||
                isIntermediaryRequiredForRoles(availableRolesStringArr);
            const isBookingCenterRequired =
                isBookingCenterRequiredForThisRole(values.role) ||
                isBookingCenterRequiredForRoles(availableRolesStringArr);
            const isAssetManagerRequired =
                isAssetManagerRequiredForThisRole(values.role) ||
                isAssetManagerRequiredForRoles(availableRolesStringArr);
            const isTransferAgentRequired = isTransferAgentRequiredForThisRole(
                values.role
            );

            const data = {
                ...values,
                ...((!values.bookingCentreId || !isBookingCenterRequired) && {
                    bookingCentreId: null,
                }),
                ...((!values.intermediaryId || !isIntermediaryRequired) && {
                    intermediaryId: null,
                }),
                ...((!values.assetManagerId || !isAssetManagerRequired) && {
                    assetManagerId: null,
                }),
                ...(!isTransferAgentRequired && { transferAgentId: null }),
                availableRoles: availableRolesStringArr,
            };

            if (
                currentUser?.user.role ===
                    AuthRoleEnum.assetManagerAdministrator &&
                currentUser?.user.assetManagerId
            ) {
                data.assetManagerId = currentUser.user.assetManagerId;
            }

            mutateMap
                .func(trimStringInObject(data), userId as string)
                .then(() => {
                    notifySuccess(mutateMap.successMessage);

                    if (!isEdit) {
                        actions?.resetForm();
                    }
                })
                .catch((e) => {
                    notifyError(mutateMap.failMessage + errorToString(e));
                })
                .finally(() => {
                    actions?.setSubmitting(false);
                    fetchSystemUsers(false);
                    navigate('/admin/users');
                });
        },
        [currentUser?.user, fetchSystemUsers, isEdit, userId]
    );

    useEffect(() => {
        const user: SystemUserResponse | undefined = systemUsers.find(
            ({ _id }) => _id === userId
        );

        if (isEdit && user && user.intermediaryId) {
            const id =
                typeof user?.intermediaryId === 'string'
                    ? user?.intermediaryId
                    : user.intermediaryId?._id;

            setSelectedIntermediaryId(id);
        } else {
            if (currentUser?.user?.intermediaryId) {
                setSelectedIntermediaryId(currentUser?.user?.intermediaryId);
            }
        }

        getAllAssetManagers()
            .then((results) => setAssetManagers(results))
            .catch((error) => notifyError(errorToString(error)));
    }, [currentUser?.user?.intermediaryId, setAssetManagers]);

    useEffect(() => {
        getTransferAgents()
            .then((results) => setTransferAgents(results))
            .catch((error) => notifyError(errorToString(error)));
    }, []);

    return (
        <Formik
            onSubmit={submitHandler}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount
            enableReinitialize
        >
            {({
                values,
                handleChange,
                handleBlur,
                isSubmitting,
                isValid,
                setFieldValue,
                setFieldTouched,
            }) => (
                <Form>
                    <div className="w-full mb-5">
                        <h1 className="text-3xl text-logo-blue ">
                            {isEdit ? t('admin.user_management.update_user') : t('admin.user_management.add_user')}
                        </h1>
                    </div>
                    <div>
                        {fields.map(
                            ({
                                type,
                                label,
                                name,
                                placeholder,
                                disabled,
                                optionsData,
                                handleFieldChange,
                                visible,
                            }) => {
                                if (visible && !visible(values)) {
                                    return null;
                                }

                                const value = values?.[name];

                                let component = null;

                                switch (type) {
                                    case 'text':
                                    case 'email':
                                        component = (
                                            <FormInputBox
                                                name={name}
                                                key={name}
                                                label={label}
                                                type={type}
                                                value={value}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                                placeholder={placeholder}
                                                data-testid={name}
                                            />
                                        );

                                        break;
                                    case 'selection':
                                        component = (
                                            <FormSelect
                                                key={name}
                                                name={name}
                                                placeholder={placeholder}
                                                label={label}
                                                value={value}
                                                // eslint-disable-next-line no-shadow-restricted-names
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLSelectElement>
                                                ) => {
                                                    handleChange(e);

                                                    if (handleFieldChange) {
                                                        handleFieldChange(
                                                            e,
                                                            setFieldValue
                                                        );

                                                        setTimeout(() =>
                                                            setFieldTouched(
                                                                name,
                                                                true
                                                            )
                                                        );
                                                    }
                                                }}
                                                onBlur={handleBlur}
                                                disabled={
                                                    disabled
                                                        ? disabled({
                                                              isEdit,
                                                              role: currentUser
                                                                  ?.user?.role,
                                                              values,
                                                          })
                                                        : false
                                                }
                                                optionsData={optionsData}
                                            />
                                        );
                                        break;
                                    case 'multi-selection':
                                        component = (
                                            <div className="pb-3">
                                                <FormMultiselect
                                                    id={name}
                                                    name={name}
                                                    label={label}
                                                    options={
                                                        optionsData as Option[]
                                                    }
                                                    placeholder={label}
                                                />
                                            </div>
                                        );
                                        break;
                                    default:
                                        component = <div key={name}></div>;
                                        break;
                                }

                                return component;
                            }
                        )}
                    </div>

                    <div className="flex justify-between pt-3 pb-8">
                        <Button
                            label={t('ui.controls.cancel')}
                            buttonType="secondary"
                            type="button"
                            id="cancel-button"
                            onClick={() => {
                                navigate('/admin/users');
                            }}
                        />

                        <Button
                            label={`${isEdit ? t('admin.user_management.update_user') : t('admin.user_management.add_user')}`}
                            buttonType="primary"
                            type="submit"
                            id="create-button"
                            disabled={!isValid || isSubmitting}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};
