import { useTranslation } from 'react-i18next';
import {
    DistributorOfFundLabels,
    IntermediaryMifidCategorisationLabels,
    IntermediaryPooledAccountsLabels,
    IntermediaryTypeOfBusinessLabels,
    SegregatedAccountDesignationLabels,
    ThirdPartyAccountLabels,
    TypeOfInvestment,
} from '@interfaces/Api/TypeOfInvestment';
import { getYesOrNo } from '@helpers/string.helper';

interface ReviewTypeOfInvestmentProps {
    typeOfInvestment?: TypeOfInvestment;
}

const ReviewTypeOfInvestment = (props: ReviewTypeOfInvestmentProps) => {
    const { t } = useTranslation();
    const { typeOfInvestment } = props;
    const lines: { heading: string; value: string | undefined }[] = [
        {
            heading: t(
                'subscriptions.add.type_of_investment.made_on_behalf_of_third_party'
            ),
            value: getYesOrNo(typeOfInvestment?.isMadeOnBehalfOfThirdParty),
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.third_party_account_type'
            ),
            value:
                typeOfInvestment?.thirdPartyAccountType &&
                ThirdPartyAccountLabels[typeOfInvestment.thirdPartyAccountType],
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.segregated_account_designation_type'
            ),
            value:
                typeOfInvestment?.segregatedAccountDesignationType &&
                SegregatedAccountDesignationLabels[
                    typeOfInvestment.segregatedAccountDesignationType
                ],
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.mifid_categorisation'
            ),
            value:
                typeOfInvestment?.mifidCategorisation
                    ?.map((a) => IntermediaryMifidCategorisationLabels[a])
                    .filter((a) => a)
                    .join(', ') || '',
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.intermediary_type_of_business'
            ),
            value:
                typeOfInvestment?.intermediaryTypeOfBusiness &&
                IntermediaryTypeOfBusinessLabels[
                    typeOfInvestment.intermediaryTypeOfBusiness
                ],
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.distributor_of_fund_type'
            ),
            value:
                typeOfInvestment?.distributorOfFundType &&
                DistributorOfFundLabels[typeOfInvestment.distributorOfFundType],
        },
        {
            heading: t(
                'subscriptions.add.type_of_investment.intermediary_pooled_accounts'
            ),
            value:
                typeOfInvestment?.intermediaryPooledAccounts
                    ?.map((a) => IntermediaryPooledAccountsLabels[a])
                    .filter((a) => a)
                    .join(', ') || '',
        },
    ].filter((a) => a.value);

    return (
        <div className="text-base leading-5">
            {lines.map((line, i) => (
                <div key={i}>
                    <strong className="pr-2">{line.heading}</strong>
                    {line.value}
                </div>
            ))}
        </div>
    );
};

export default ReviewTypeOfInvestment;
