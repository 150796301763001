import { EndInvestorProfileGroupResponse } from '@api/EndInvestorProfileGroups';
import { EndInvestorProfileAssignLoginAccount } from '@api/EndInvestorProfiles';
import {
    EndInvestorProfileResponse,
    IntermediaryResponse,
} from '@interfaces/Api';
import { getFullName } from './common.helper';
import { errorToString } from './error.helper';
import { notifyError, notifySuccess } from './toastrHelper';

export const getEndInvestorName = (
    endInvestorProfile?: EndInvestorProfileResponse
) => {
    if (!endInvestorProfile) {
        return '';
    }

    const { businessEntityDetails, individualDetails } = endInvestorProfile;

    return (
        (businessEntityDetails?.companyName?.length &&
            businessEntityDetails?.companyName) ||
        getFullName(individualDetails?.firstName, individualDetails?.lastName)
    );
};

export const isEndInvestorNomineeAccount = (
    endInvestor: EndInvestorProfileResponse
) =>
    endInvestor?.endInvestorType ===
    EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

export const isAdvisoryEnabled = (investorType: string) =>
    investorType !== EndInvestorProfileResponse.EndInvestorType.nomineeEntity;

export const isDirectSubscriptionProcessType = (
    endInvestor: EndInvestorProfileResponse
) =>
    endInvestor.subscriptionProcessType ===
    EndInvestorProfileResponse.SubscriptionProcessTypeEnum.Direct;

interface getIntermediaryNameParams {
    endInvestorProfiles: EndInvestorProfileResponse[];
    endInvestorProfileId: string;
    intermediaries?: IntermediaryResponse[];
}

export const getIntermediaryName = ({
    endInvestorProfiles,
    endInvestorProfileId,
    intermediaries,
}: getIntermediaryNameParams) => {
    const endInvestorProfile = endInvestorProfiles.find(
        (i) => i._id === endInvestorProfileId
    );

    if (!endInvestorProfile) return '';

    return intermediaries?.find(
        (intermediary: any) =>
            intermediary._id === endInvestorProfile.intermediaryId
    )?.name;
};

export const searchByNameSearch = ({
    endInvestorProfiles,
    endInvestorProfile,
    intermediaries,
    nameSearch,
}) => {
    const intermediaryName = getIntermediaryName({
        endInvestorProfiles,
        endInvestorProfileId: endInvestorProfile._id,
        intermediaries,
    });

    const stringifiedValue = [
        endInvestorProfile.individualDetails?.firstName,
        endInvestorProfile.individualDetails?.lastName,
        endInvestorProfile.businessEntityDetails?.companyName,
        intermediaryName,
    ]
        .filter((a) => a)
        .join(' ')
        .toLowerCase();

    const doesValueMatch = stringifiedValue.includes(nameSearch.toLowerCase());
    return doesValueMatch;
};

export const filterProfiles = (
    endInvestorProfiles,
    endInvestorProfile: EndInvestorProfileResponse,
    intermediaries?: IntermediaryResponse[],
    selectedGroupIds?: string[],
    endInvestorProfileGroups?: EndInvestorProfileGroupResponse[],
    nameSearch?: string
) => {
    if (nameSearch) {
        return searchByNameSearch({
            endInvestorProfiles,
            endInvestorProfile,
            intermediaries,
            nameSearch,
        });
    }

    if (selectedGroupIds?.length) {
        for (let i = 0; i < selectedGroupIds.length; i++) {
            const groupId = selectedGroupIds[i];
            const group = endInvestorProfileGroups?.find(
                (a) => a._id == groupId
            );
            const isInGroup = group?.endInvestorProfileIds.includes(
                endInvestorProfile._id
            );
            if (!isInGroup) return false;
        }
    }

    return true;
};

export const handleLoginAccountAssignment = async ({
    endInvestorProfileId,
}: {
    endInvestorProfileId: string;
}) => {
    try {
        await EndInvestorProfileAssignLoginAccount({
            endInvestorProfileId,
        });
        notifySuccess(
            'Login account has been created successfully. The end investor will receive an email.'
        );
    } catch (error) {
        notifyError(errorToString(error));
    }
};
