import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Outlet,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import {
    EndInvestorProfileResponse,
    SubscriptionResponse,
    SubscriptionStatusEnum,
    SubscriptionStatusLog,
} from '@interfaces/Api';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useSubscriptionTemplateState } from '@contexts/SubscriptionTemplatesContext';

import {
    CreateLoginAccountToEndInvestor,
    SubscriptionStatusInfo,
    SubscriptionSteps,
} from '@components/Organisms';

import {
    getSubscriptionSteps,
    getSubscriptionTemplateSteps,
    SubscriptionStep,
} from '@services/SubscriptionsHelper';

import { useMountEffect } from '@hooks/useMountEffect';
import { useProduct } from '@stores/Products/useProduct';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import { useSubscriptions } from '@stores/Subscriptions/useSubscriptions';

import { Button, SelectBox } from '@components/Atoms';
import { FormFieldWrapper, Modal } from '@components/Molecules';
import { formatDate } from '@helpers/Date.helper';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useProducts } from '@stores/Products/useProducts';
import {
    cloneSubscriptionPost,
    SubscriptionsGetAll,
} from '@stores/Subscriptions/Subscriptions.services';

interface SubscriptionPageContext {
    steps: SubscriptionStep[];
    showPreviousStep?: boolean;
    showNextStep?: boolean;
    goToPreviousStep?: () => void;
    goToNextStep?: () => void;
    fetchEndInvestorProfile: () => void;
    endInvestorProfile?: EndInvestorProfileResponse;
}

const SubscriptionPageContext = React.createContext<SubscriptionPageContext>({
    steps: [],
    showPreviousStep: false,
    showNextStep: false,
    fetchEndInvestorProfile: () => {},
});

export const useSubscriptionPageContext = () =>
    useContext(SubscriptionPageContext);

const EditSubscription: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { subscriptionId, subscriptionTemplateId } = useParams();

    const { subscription } = useSubscription(subscriptionId);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const {
        updateSubscriptionBeneficialOwnership,
        updateSubscriptionPoliticalExposure,
        updateSubscriptionContacts,
        updateSubscriptionTaxInformation,
        updateSubscriptionSourceOfWealth,
        updateTypeOfInvestment,
        updateEconomicOriginOfMonies,
        updateSubscriptionDistributionOfFunds,
        updateSubscriptionDistributorDetails,
        updateSubscriptionStatus,
        updateSubscriptionSupportingDocuments,
    } = useSubscriptionsActions();

    const {
        getSubscriptionTemplate,
        subscriptionTemplate,
        putSubscriptionTemplateDistributorDetails,
        putSubscriptionTemplateSourceOfWealth,
        putSubscriptionTemplateDistributionOfFunds,
        putSubscriptionTemplateBeneficialOwnership,
        putSubscriptionTemplatePoliticalExposure,
        putSubscriptionTemplateContacts,
        putSubscriptionTemplateTaxInformation,
        putSubscriptionTemplateName,
    } = useSubscriptionTemplateState();

    const endInvestorProfileId =
        subscription?.endInvestorProfileId ||
        subscriptionTemplate?.endInvestorProfileId;

    const { preferences, isClient, configuration } = useConfigurationState();
    const { product } = useProduct(subscription.productId);

    const { getEndInvestorProfile } = useEndInvestorProfileState();
    const [endInvestorProfile, setEndInvestorProfile] =
        useState<EndInvestorProfileResponse>();

    const [steps, setSteps] = useState<SubscriptionStep[]>([]);
    const [showPreviousStep, setShowPreviousStep] = useState<boolean>(false);
    const [previousStep, setPreviousStep] = useState<SubscriptionStep | null>();
    const [showNextStep, setShowNextStep] = useState<boolean>(false);
    const [nextStep, setNextStep] = useState<SubscriptionStep | null>();
    const [relatedSubscriptions, setRelatedSubscriptions] = useState<
        SubscriptionResponse[]
    >([]);

    useMountEffect(() => {
        if (subscriptionTemplateId) {
            getSubscriptionTemplate(subscriptionTemplateId).then();
        }
    });

    useEffect(() => {
        const steps = subscriptionId
            ? getSubscriptionSteps(
                  t,
                  endInvestorProfile,
                  subscription,
                  preferences?.feature,
                  product,
                  isClient,
                  configuration?.client
              )
            : getSubscriptionTemplateSteps(
                  t,
                  endInvestorProfile,
                  subscriptionTemplate,
                  preferences?.feature
              );

        // check if the step in the url is valid
        const currentStepEndPathUrl = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
        );
        const currentStepIndex = steps.findIndex(
            (a) => a.endPathUrl == currentStepEndPathUrl
        );

        if (currentStepIndex == -1 && steps.length) {
            const baseUrl = subscriptionId
                ? '/subscriptions'
                : '/subscription-templates';
            const urlId = subscriptionId || subscriptionTemplateId;
            navigate(
                {
                    pathname: `${baseUrl}/${urlId}/${steps[0].endPathUrl}`,
                },
                { replace: true }
            );
        }

        setSteps(steps);
    }, [
        configuration?.client,
        endInvestorProfile,
        isClient,
        location.pathname,
        navigate,
        preferences,
        product,
        subscription,
        subscriptionId,
        subscriptionTemplate,
        subscriptionTemplateId,
        t,
    ]);

    const fetchEndInvestorProfile = useCallback(() => {
        if (endInvestorProfileId) {
            getEndInvestorProfile(endInvestorProfileId).then(
                (endInvestorProfile) => {
                    setEndInvestorProfile(endInvestorProfile);
                }
            );
        }
    }, [getEndInvestorProfile, endInvestorProfileId]);

    useEffect(() => {
        fetchEndInvestorProfile();
    }, [fetchEndInvestorProfile, endInvestorProfileId]);

    useEffect(() => {
        if (endInvestorProfileId && subscription?._id) {
            SubscriptionsGetAll(endInvestorProfileId).then((subscriptions) => {
                const a = subscriptions.filter(
                    (data) => data._id !== subscription?._id
                );
                setRelatedSubscriptions(a);
            });
        }
    }, [endInvestorProfileId, subscription?._id]);

    useEffect(() => {
        // find current step
        const currentStepEndPathUrl = location.pathname.substring(
            location.pathname.lastIndexOf('/') + 1
        );
        const currentStepIndex = steps.findIndex(
            (a) => a.endPathUrl == currentStepEndPathUrl
        );
        // set previous step
        const previousStep = steps[currentStepIndex - 1];
        setShowPreviousStep(!!previousStep);
        setPreviousStep(previousStep);
        // set next step
        const nextStep = steps[currentStepIndex + 1];
        setShowNextStep(!!nextStep);
        setNextStep(nextStep);
    }, [steps, location]);

    const BASE_URL = subscriptionId
        ? '/subscriptions'
        : '/subscription-templates';
    const URL_ID = subscriptionId || subscriptionTemplateId;

    const goToNextStep = () =>
        navigate({ pathname: `${BASE_URL}/${URL_ID}/${nextStep?.endPathUrl}` });

    const goToPreviousStep = () =>
        navigate({
            pathname: `${BASE_URL}/${URL_ID}/${previousStep?.endPathUrl}`,
        });

    const handleCancelSubscription = async () => {
        setShowCancelModal(false);
        await updateSubscriptionStatus({
            subscriptionId,
            body: {
                status: 'cancelled' as SubscriptionStatusEnum.cancelled,
            },
        });
    };

    const { products } = useProducts();
    const getLastActivityTimeStamp = (statusLogs: SubscriptionStatusLog[]) =>
        formatDate(String(statusLogs.at(0).createdAt.toString()));

    const previousSubscriptionOptions = relatedSubscriptions
        .map(
            ({
                status,
                shareClass,
                _id,
                statusLogs,
                productId,
                subscriptionAmount,
            }) => {
                const product = products.find((val) => val._id === productId);

                return {
                    status,
                    lastActivityTimeStamp: getLastActivityTimeStamp(statusLogs),
                    ...shareClass,
                    subscriptionAmount,
                    id: _id,
                    productName: product?.name || t('product.no_product_found'),
                };
            })
        .map(
            ({
                status,
                productName,
                name,
                subscriptionAmount,
                id: value,
                currency,
                lastActivityTimeStamp,
            }) => {
                return {
                    label: `${toCapitalizedWords(
                        status
                    )} - ${productName} - ${name} | ${getFormattedCurrency(
                        subscriptionAmount,
                        currency
                    )} | ${lastActivityTimeStamp}`,
                    value,
                };
            }
        );

    const [selectedSubscriptionToCopy, setSelectedSubscriptionToCopy] =
        useState<string>();
    const [showCloneModal, setShowCloneModal] = useState(false);

    return (
        <div className="container pb-6 max-w-screen">
            {subscription.status === SubscriptionStatusEnum.inProgress && (
                <>
                    <Modal show={showCloneModal} width="w-auto">
                        <Modal.Header
                            onClose={() => {
                                setShowCloneModal(false);
                            }}
                        >
                            {t('subscription.clone.header')}
                        </Modal.Header>
                        <Modal.Body>
                            <div className=" bg-white">
                                {previousSubscriptionOptions.length > 0 ? (
                                    <div>
                                        <p className="pb-2">
                                            {t(
                                                'subscription.clone.prev_subs.clone_subs.message'
                                            )}
                                        </p>
                                        <div className="relative">
                                            <FormFieldWrapper
                                                label={t(
                                                    'subscription.clone.prev_subs.select'
                                                )}
                                            >
                                                <SelectBox
                                                    optionsData={
                                                        previousSubscriptionOptions
                                                    }
                                                    name="Select box"
                                                    placeholder=""
                                                    showLabel={true}
                                                    onChange={(e) =>
                                                        setSelectedSubscriptionToCopy(
                                                            (
                                                                e.target as HTMLSelectElement
                                                            ).value
                                                        )
                                                    }
                                                />
                                            </FormFieldWrapper>
                                        </div>
                                    </div>
                                ) : (
                                    <p>
                                        {t(
                                            'subscription.clone.no_prev_subs_found.message'
                                        )}
                                    </p>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                onClick={() => {
                                    setShowCloneModal(false);
                                }}
                                buttonType="secondary"
                                label={t('subscription.clone.cancel.button')}
                            ></Button>
                            {previousSubscriptionOptions.length > 0 && (
                                <Button
                                    onClick={() => {
                                        cloneSubscriptionPost(
                                            selectedSubscriptionToCopy,
                                            URL_ID
                                        ).then((x) => {
                                            window.location.reload();
                                            setShowCloneModal(false);
                                        });
                                    }}
                                    label={t('subscription.clone.clone.button')}
                                ></Button>
                            )}
                        </Modal.Footer>
                    </Modal>

                    {/* {console.log(t('asdf', { returnObjects: true }))} */}
                    <div className="w-full flex flex-row-reverse">
                        <Button
                            onClick={() => {
                                setShowCloneModal(true);
                            }}
                        >
                            {t('subscription_copy_previous_subscription')}
                        </Button>
                    </div>
                </>
            )}
            <Modal show={showCancelModal} width="w-auto">
                <Modal.Header
                    onClose={() => {
                        setShowCancelModal(false);
                    }}
                >
                    <h1>{t('client.compliance_cancel.warning.title')}</h1>
                    <Modal.Body>
                        <div className=" bg-white">
                            <div
                                className="bg-red-100 text-red-700 px-2 py-3 rounded relative mt-4"
                                role="alert"
                            >
                                <div className="font-bold">
                                    <span className="block sm:inline">
                                        {t(
                                            'client.compliance_rejection.warning.text2'
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            buttonType="secondary"
                            onClick={() => setShowCancelModal(false)}
                            label={t(
                                'subscriptions.grid.compliance_rejection.abort'
                            )}
                        />

                        <Button
                            label={t(
                                'subscriptions.grid.compliance_rejection.execute'
                            )}
                            onClick={handleCancelSubscription}
                        />
                    </Modal.Footer>
                </Modal.Header>
            </Modal>
            <div>
                {subscription?._id && (
                    <SubscriptionStatusInfo
                        subscription={subscription}
                        product={product}
                        onCancelSubscription={() => setShowCancelModal(true)}
                    />
                )}
                <CreateLoginAccountToEndInvestor
                    endInvestorProfileId={endInvestorProfile?._id}
                />
            </div>

            <div className="flex flex-row pt-4">
                <aside className="block px-0 md:w-3/12">
                    <SubscriptionSteps steps={steps} />
                </aside>
                <main role="main" className="w-full px-0 md:w-9/12 md:px-10">
                    <SubscriptionPageContext.Provider
                        value={{
                            steps,
                            showPreviousStep,
                            goToPreviousStep,
                            showNextStep,
                            goToNextStep,
                            endInvestorProfile,
                            fetchEndInvestorProfile,
                        }}
                    >
                        <Outlet
                            context={{
                                data: subscriptionId
                                    ? subscription
                                    : subscriptionTemplate,
                                updateTemplateName: putSubscriptionTemplateName,
                                updateDistributorDetails: subscriptionId
                                    ? updateSubscriptionDistributorDetails
                                    : putSubscriptionTemplateDistributorDetails,
                                updateSourceOfWealth: subscriptionId
                                    ? updateSubscriptionSourceOfWealth
                                    : putSubscriptionTemplateSourceOfWealth,
                                updateTypeOfInvestment: updateTypeOfInvestment,
                                updateEconomicOriginOfMonies:
                                    updateEconomicOriginOfMonies,
                                updateDistributionOfFunds: subscriptionId
                                    ? updateSubscriptionDistributionOfFunds
                                    : putSubscriptionTemplateDistributionOfFunds,
                                updateBeneficialOwnership: subscriptionId
                                    ? updateSubscriptionBeneficialOwnership
                                    : putSubscriptionTemplateBeneficialOwnership,
                                updatePoliticalExposure: subscriptionId
                                    ? updateSubscriptionPoliticalExposure
                                    : putSubscriptionTemplatePoliticalExposure,
                                updateContacts: subscriptionId
                                    ? updateSubscriptionContacts
                                    : putSubscriptionTemplateContacts,
                                updateTaxInformation: subscriptionId
                                    ? updateSubscriptionTaxInformation
                                    : putSubscriptionTemplateTaxInformation,
                                updateSupportingDocuments:
                                    updateSubscriptionSupportingDocuments,
                            }}
                        />
                    </SubscriptionPageContext.Provider>
                </main>
            </div>
        </div>
    );
};

export default EditSubscription;
