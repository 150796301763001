import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
import { MsalProvider } from '@azure/msal-react';
import {
    ClientEnum,
    getInvestorCountryRequirementTree,
    InvestorCountryRequirement,
} from 'common';
import { ConfigurationResponse } from '@interfaces/Api';

import { configurationGet } from '@api/Configuration';

import { Route, Routes } from 'react-router-dom';
import HealthCheck from '@pages/HealthCheck';
import { EnvironmentEnum } from '@interfaces/Api/ConfigurationResponse';
import { useTheme } from '@hooks/useTheme';
import { useMountEffect } from '@hooks/useMountEffect';

import { useStorageTokenState } from './StorageTokenContext';
import { notifyError } from '@helpers/toastrHelper';
import { initMSAL, msalInstance } from '@libs/msal/msalInit';
import { getImage } from '@api/Documents';

export interface ConfigurationContextProps {
    configuration?: ConfigurationResponse;
    configurationLoading: boolean;
    themeName?: ClientEnum;
    preferences?: ConfigurationResponse['preferences'];
    isEnvironment: (environment: EnvironmentEnum) => boolean;
    isClient: (client: ClientEnum) => boolean;
    investorCountryRequirementTree: InvestorCountryRequirement[];
    refreshConfiguration: (
        callback?: (configuration: ConfigurationResponse) => void | null
    ) => Promise<ConfigurationResponse>;
}

const configurationDefaultState: ConfigurationContextProps = {
    configuration: undefined,
    configurationLoading: false,
    isEnvironment: () => false,
    isClient: () => false,
    investorCountryRequirementTree: [],
    refreshConfiguration: async () => {
        return Promise.resolve({} as ConfigurationResponse);
    },
};

const ConfigurationContext = createContext<ConfigurationContextProps>(
    configurationDefaultState
);

export default ConfigurationContext;

export const useConfigurationState = () => {
    return useContext(ConfigurationContext);
};

export const ConfigurationProvider = ({ children }: PropsWithChildren<{}>) => {
    const { withToken } = useStorageTokenState();
    const [configuration, setConfiguration] = useState<ConfigurationResponse>();
    const [isConfigurationLoading, setIsConfigurationLoading] =
        useState<boolean>(true);

    const [investorCountryRequirementTree, setInvestorCountryRequirementTree] =
        useState<InvestorCountryRequirement[]>();

    const { themeName } = useTheme({ configuration });

    useMountEffect(() => {
        setIsConfigurationLoading(true);
        refreshConfiguration((configResponse) => {
            configResponse?.preferences?.feature?.authentication
                ?.enableAzureEntra && initMSAL(configResponse);
            const isActiveAccount = msalInstance.getActiveAccount();
            if (isActiveAccount) {
                refreshConfiguration();
            }
        });
    });

    useEffect(() => {
        if (configuration?.client) {
            const tree = getInvestorCountryRequirementTree(
                configuration?.client
            );
            setInvestorCountryRequirementTree(tree);
        }
    }, [configuration?.client]);

    const refreshConfiguration = async (
        callback?: (configuration: ConfigurationResponse) => void | null
    ) => {
        const favicon = document.getElementById('favicon');
        try {
            try {
                const val = await configurationGet();

                if (!val.branding.images?.navbarLogo?.url) {
                    val.branding.images.navbarLogo.url = '/assets/s64_logo.png';
                }

                if (!val.branding.images?.loginPageLogo?.url) {
                    val.branding.images.loginPageLogo.url =
                        '/assets/s64_logo.png';
                }
                setConfiguration(val);

                if (callback) {
                    callback(val);
                }

                if (val?.branding?.images?.favicon?.url) {
                    const blob = await getImage(
                        withToken(val.branding.images.favicon?.url)
                    );
                    if (blob) {
                        const favHref = window.URL.createObjectURL(blob);
                        favicon?.setAttribute('href', favHref);
                    }
                } else {
                    favicon?.setAttribute('href', '/favicon.ico');
                }
                
                return val;
            } catch (err: any) {
                notifyError('Error loading api/configuration:', err);
            }
        } finally {
            setIsConfigurationLoading(false);
        }
    };

    const isEnvironment = (environment: EnvironmentEnum) =>
        environment === configuration?.environment;

    const isClient = (client: ClientEnum) => client === configuration?.client;

    useEffect(() => {
        if (configuration?.client) {
            localStorage.setItem('client', configuration.client);
        }
    }, [configuration]);

    return (
        <ConfigurationContext.Provider
            value={{
                configuration,
                configurationLoading: isConfigurationLoading,
                themeName,
                isEnvironment,
                isClient,
                preferences: configuration?.preferences,
                refreshConfiguration,
                investorCountryRequirementTree,
            }}
        >
            {!isConfigurationLoading && (
                <>
                    {configuration &&
                    configuration?.preferences?.feature?.authentication
                        ?.enableAzureEntra ? (
                        <MsalProvider instance={msalInstance}>
                            <div data-theme={themeName} className="bg-white">
                                {children}
                            </div>
                        </MsalProvider>
                    ) : (
                        <div data-theme={themeName} className="bg-white">
                            {children}
                        </div>
                    )}

                    {/* If the request to the configuration endpoint has
                    finished loading and there's no configuration,
                     assume that the configuration endpoint is down */}
                    {!configuration && (
                        <Routes>
                            <Route
                                path="health-check/"
                                element={<HealthCheck />}
                            />
                            <Route
                                path="*"
                                element={
                                    <div className="absolute inset-0 flex flex-col items-center justify-center w-full h-screen text-lg bg-white">
                                        <p>
                                            Configuration could not be loaded.
                                        </p>
                                        <p>Please contact support.</p>
                                    </div>
                                }
                            />
                        </Routes>
                    )}
                </>
            )}
        </ConfigurationContext.Provider>
    );
};
