import { Field, Form, Formik } from 'formik';
import { lazy, Suspense } from 'react';
import { Button } from '@components/Atoms';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
const MDEditor = lazy(() => import('@uiw/react-md-editor'));
import {
    applicationSettingPut,
} from '@api/ApplicationSetting';
import { useApplicationSetting } from '@hooks/useApplicationSetting';


export const DisclaimerText = () => {
    const { applicationSetting } = useApplicationSetting(true);
    
    const handleSubmit = ({ disclaimerText }: { disclaimerText: string }) => {
        const updateConfiguration = async () => {
            try {
                await applicationSettingPut({
                    disclaimerText,
                });

                notifySuccess('Disclaimer text updated successfully');
            } catch (_) {
                notifyError(
                    'There was an error updating the disclaimer text. Please try again.'
                );
            }
        };

        updateConfiguration();
    };

    return (
        <div>
            <h1 className="text-logo-blue text-3xl font-bold mb-4">
                Disclaimer text
            </h1>
            <div className="w-full">
                <Formik
                    initialValues={{
                        disclaimerText: applicationSetting?.disclaimerText ?? '',
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <Form>
                        <Field name="disclaimerText">
                            {(props: any) => (
                                <Suspense fallback={<div />}>
                                    <MDEditor
                                        value={props.field.value}
                                        onChange={(value) => {
                                            props.form.setFieldValue(
                                                'disclaimerText',
                                                value
                                            );
                                        }}
                                        height={480}
                                    />
                                </Suspense>
                            )}
                        </Field>

                        <div className="w-full flex justify-end pt-4 mb-2">
                            <Button type="submit" label="Update" />
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
