import { useFormikContext } from 'formik';
import { FormCheckbox, FormSelect, Modal } from '@components/Molecules';
import ImportantNotice from './ImportantNotice';
import { Button } from '@components/Atoms';
import { openPopup } from '@helpers/popup.helper';
import { useMemo, useState } from 'react';
import { useConfigurationState } from '@contexts/ConfigurationContext';

interface CountryInformationProps {
    onSubmit: (action?: 'reject') => void;
}

const CountryInformation: React.FC<CountryInformationProps> = (props) => {
    const { values, isValid } = useFormikContext<{
        countryCodeOfResidence: string;
        investorType: string;
    }>();
    const { configuration, investorCountryRequirementTree } =
        useConfigurationState();

    const [showFurtherDescription, setShowFurtherDescription] = useState(false);

    const optionsData = useMemo(() => {
        let countries = investorCountryRequirementTree;

        if (configuration.preferences.feature.b2C?.onlyShowProdOnlyCountries) {
            countries = countries.filter(({ prodOnly }) => Boolean(prodOnly));
        }

        return countries.map((a) => ({
            label: a.name,
            value: a.countryCode,
        }));
    }, [configuration.preferences.feature.b2C?.onlyShowProdOnlyCountries]);

    const countryCode = values?.countryCodeOfResidence;
    const investorType = values?.investorType;

    const investorCountryRequirement = investorCountryRequirementTree.find(
        (a) => a.countryCode === countryCode
    );

    const investorTypeObject = investorCountryRequirement?.investorTypes?.find(
        (a) => a.value === investorType
    );
    const investorTypeDescription = investorTypeObject?.description;

    const investorOptions = investorCountryRequirement?.investorTypes?.map(
        (a) => ({
            label: a.name,
            value: a.value,
            // should add ability for components to be rendered below. Maybe the
            // abstract should accept markdown rather than an element
            abstract: <div>{a.description}</div>,
        })
    );

    const investorTypeCheckbox = investorTypeObject?.checkbox;

    const hereBelowDescription = investorTypeObject?.hereBelowDescription;

    return (
        <>
            <ImportantNotice />

            <div className="border rounded p-4 mb-5">
                <p className="pb-4">
                    <b>Please choose from the following countries. </b> By
                    selecting a country from the list below, you certify that
                    you are resident in that country
                </p>

                <h2 className="mb-1 text-lg">I am a resident of:</h2>
                <FormSelect
                    dataTestId="countryCodeOfResidence"
                    name="countryCodeOfResidence"
                    label="Country of Residence *"
                    optionsData={optionsData}
                />

                {investorOptions && (
                    <>
                        <p className="pt-3 pb-2">
                            <b>Please select your investor type.</b> By
                            selecting an investor type from the below list, you
                            certify that you qualify as that type of investor
                            based on the definitions below.
                        </p>

                        <FormSelect
                            name="investorType"
                            label="Investor Type *"
                            optionsData={investorOptions}
                        />

                        {values.investorType && (
                            <>
                                <div className="whitespace-pre-line">
                                    {investorTypeDescription}{' '}
                                    {investorTypeObject?.warningText && (
                                        <div
                                            className="pt-2 inline-flex underline cursor-pointer"
                                            onClick={() => {
                                                openPopup(
                                                    investorTypeObject?.warningText,
                                                    investorTypeObject?.warningTextType
                                                );
                                            }}
                                        >
                                            {
                                                investorTypeObject?.warningTextLabel
                                            }
                                        </div>
                                    )}
                                    {hereBelowDescription && (
                                        <div
                                            className="pt-2 inline-flex underline cursor-pointer"
                                            onClick={() => {
                                                setShowFurtherDescription(
                                                    (a) => !a
                                                );
                                            }}
                                        >
                                            here.
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {showFurtherDescription && (
                            <Modal
                                show={true}
                                onBackdropClick={() =>
                                    setShowFurtherDescription((a) => !a)
                                }
                                width="w-3/5"
                            >
                                <Modal.Header
                                    onClose={() => {
                                        setShowFurtherDescription((a) => !a);
                                    }}
                                >
                                    More Information
                                </Modal.Header>
                                <Modal.Body className="">
                                    <div
                                        className="whitespace-pre-line"
                                        dangerouslySetInnerHTML={{
                                            __html: hereBelowDescription,
                                        }}
                                    ></div>
                                </Modal.Body>
                            </Modal>
                        )}

                        {investorTypeCheckbox && (
                            <div className="pt-4">
                                <FormCheckbox
                                    // TODO: change the name of this checkbox
                                    name="investorTypeCheckbox"
                                    actionLabel={
                                        investorTypeCheckbox.actionLabel
                                    }
                                    description={
                                        investorTypeCheckbox.description
                                    }
                                />
                            </div>
                        )}
                    </>
                )}
                <div className="flex flex-row-reverse justify-between pt-4">
                    <Button
                        type="submit"
                        label="Confirm"
                        disabled={!isValid}
                        onClick={(e) => {
                            if (
                                investorCountryRequirement?.canPass === false ||
                                investorTypeObject?.canPass === false
                            ) {
                                e.preventDefault();
                                props.onSubmit('reject');
                                return;
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default CountryInformation;
