import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { IntermediaryRequestBody, IntermediaryResponse } from '@interfaces/Api';
import {
    handleAddIntermediary,
    handleFetchIntermediaries,
    handleFetchIntermediary,
    handleUpdateIntermediary,
} from './Intermediaries.services';

export interface IntermediariesStore {
    intermediaries: IntermediaryResponse[];
    isLoading: boolean;
    actions: {
        fetchIntermediary: (intermediaryId: string) => Promise<void>;
        fetchIntermediaries: () => Promise<void>;
        addIntermediary: (
            intermediary: IntermediaryRequestBody
        ) => Promise<IntermediaryResponse | void>;
        updateIntermediary: (
            intermediary: IntermediaryRequestBody
        ) => Promise<IntermediaryResponse | void>;
    };
}

export const useIntermediariesStore = create<IntermediariesStore>()(
    devtools(
        (set, get): IntermediariesStore => ({
            intermediaries: [],
            isLoading: false,
            actions: {
                fetchIntermediary: async (intermediaryId: string) =>
                    handleFetchIntermediary(set, get, intermediaryId),
                fetchIntermediaries: async () => handleFetchIntermediaries(set),
                addIntermediary: async (
                    intermediary: IntermediaryRequestBody
                ) => handleAddIntermediary(set, intermediary),
                updateIntermediary: async (
                    intermediary: IntermediaryRequestBody
                ) => handleUpdateIntermediary(set, intermediary),
            },
        })
    )
);
