import { t } from "i18next";

const FormikLabels = {
    formField: {
        availabilityStatus: {
            name: 'availabilityStatus',
            label: t('admin.product.availability_status'),
            requiredErrorMsg: t('admin.product.availability_status_required'),
        },
        visibleWithoutLogin: {
            name: 'visibleWithoutLogin',
            label: t('admin.product.visible_without_login'),
        },
        notInvestibleOnPlatform: {
            name: 'notInvestibleOnPlatform',
            label: t('admin.product.not_investible_on_platform'),
        },
        transferAgentId: {
            name: 'transferAgentId',
            label: t('admin.product.transfer_agent'),
        },
        assetManagerId: {
            name: 'assetManagerId',
            label: t('admin.product.asset_manager'),
        },
        name: {
            name: 'name',
            label: t('admin.product.product_legal_name'),
            requiredErrorMsg: t('admin.product.product_legal_name_required'),
        },
        umbrellaFundName: {
            name: 'umbrellaFundName',
            label: t('admin.product.umbrella_name'),
        },
        fundInterestsName: {
            name: 'fundInterestsName',
            label: t('admin.product.fund_interests_name'),
        },
        groupId: {
            name: 'groupId',
            label: t('admin.product.product_common_name'),
        },
        shortDescription: {
            name: 'shortDescription',
            label: t('admin.product.short_description'),
            requiredErrorMsg: t('admin.product.short_description_required'),
        },
        targetSubscriptionAmount: {
            name: 'targetSubscriptionAmount',
            label: t('admin.product.target_subscription_amount'),
            requiredErrorMsg: t('admin.product.target_subscription_amount_required'),
        },
        capacityAmount: {
            name: 'capacityAmount',
            label: t('admin.product.capacity_amount'),
            requiredErrorMsg: t('admin.product.capacity_amount_required'),
        },
        baseCurrency: {
            name: 'baseCurrency',
            label: t('admin.product.base_currency'),
            requiredErrorMsg: 'Base Currency required',
        },
        inceptionDate: {
            name: 'inceptionDate',
            label: t('admin.product.inception_date'),
            requiredErrorMsg: t('admin.product.inception_date_required'),
        },
        keyInformation: {
            keyName: {
                name: 'keyName',
                label: t('admin.product.title'),
                requiredErrorMsg: t('admin.product.key_name_required'),
            },
            value: {
                name: 'value',
                label: t('add_distributor.description'),
                requiredErrorMsg: t('admin.product.key_information_description_required'),
            },
        },
        keyInformationMarkdown: {
            name: 'keyInformationMarkdown',
            label: t('admin.product.additional_information'),
        },
        shareClasses: {
            name: {
                name: 'name',
                label: t('product.name'),
                requiredErrorMsg: t('admin.product.shareclass_name_required'),
            },
            type: {
                name: 'type',
                label: t('product.type'),
                requiredErrorMsg: t('admin.product.shareslass_type_required'),
            },
            currency: {
                name: 'currency',
                label: t('product.currency'),
                requiredErrorMsg: t('admin.product.currency_code_required'),
            },
            distributionFee: {
                name: 'distributionFee',
                label: `${t('admin.product.distribution_fee')} *`,
                requiredErrorMsg: t('admin.product.distribution_fee_required'),
            },
            managementFee: {
                name: 'managementFee',
                label: t('product.unit_classes.card.management_fee'),
            },
            minimumInvestment: {
                name: 'minimumInvestment',
                label: t('subscriptions.add.subscription.review_subscription.minimum_investment').replaceAll(':', ''),
                requiredErrorMsg: t('admin.product.minimum_investment_required'),
            },
            minimumSubsequentSubscriptionAmount: {
                name: 'minimumSubsequentSubscriptionAmount',
                label: t('admin.product.minimum_subsequent_subscription_amount'),
            },
            openEndedInformation: {
                investorLockUp: {
                    name: 'openEndedInformation.investorLockUp',
                    label: t('admin.product.investor_lock_up'),
                },
                hardLockUpDetail: {
                    lengthOfTimeInMonths: {
                        name: 'openEndedInformation.hardLockUpDetail.lengthOfTimeInMonths',
                        label: t('admin.product.length_of_time'),
                    },
                },
            },
            additionalTerms: {
                keyName: {
                    name: 'keyName',
                    label: t('admin.product.title'),
                },
                value: {
                    name: 'value',
                    label: t('add_distributor.description'),
                },
            },
            isin: {
                name: 'isin',
                label: t('admin.product.isin'),
            },
        },
        documents: {
            fileName: {
                name: 'title',
                label: t('subscriptions.add.document_label'),
                requiredErrorMsg: t('admin.product.document_label_is_required'),
            },
            url: {
                name: 'url',
                label: t('subscriptions.add.document_url'),
            },
        },
        thumbnail: {
            name: 'thumbnail',
            label: t('admin.product.thumbnail_link'),
        },
        images: {
            fileName: {
                name: 'title',
                label: t('admin.product.image_title'),
                requiredErrorMsg: t('admin.product.image_title_is_required'),
            },
            url: {
                name: 'url',
                label: t('admin.product.image_url'),
            },
        },
        videos: {
            title: {
                name: 'title',
                label: t('admin.product.video_label'),
                requiredErrorMsg: t('admin.product.document_label_is_required'),
            },
            url: {
                name: 'url',
                label: t('admin.product.video_url'),
                requiredErrorMsg: t('admin.product.document_label_is_required'),
            },
            embed_url: {
                name: 'embed_url',
                label: t('admin.product.embed_url'),
                requiredErrorMsg: t('admin.product.embed_iframe_url_is_required'),
            },
        },
        assetClassType: {
            name: 'assetClassType',
            label: t('subscriptions.add.investment_details.asset_class_type'),
            requiredErrorMsg: t('admin.product.asset_class_type_is_required'),
        },
        liquidityType: {
            name: 'liquidityType',
            label: `${t('admin.product.liquidity')} *`,
            requiredErrorMsg: t('admin.product.liquidity_type_is_required'),
        },
        closedEndedInformation: {
            coolingOffDays: {
                name: 'closedEndedInformation.coolingOffDays',
                label: t('admin.product.cooling_off_period'),
            },
            keyDates: {
                name: 'closedEndedInformation.keyDates',
                label: t('product.key_dates'),
            },
        },
        openEndedInformation: {
            redemptionFrequency: {
                name: 'openEndedInformation.redemptionFrequency',
                label: t('product.redemption_frequency'),
            },
            redemptionFrequencyDeadline: {
                name: 'openEndedInformation.redemptionFrequencyDeadline',
                label: t('admin.product.redemption_reference_day'),
            },
            redemptionNoticeDays: {
                name: 'openEndedInformation.redemptionNoticeDays',
                label: t('product.redemption_notice_days'),
            },
            redemptionQuantityAvailablePercentage: {
                name: 'openEndedInformation.redemptionQuantityAvailablePercentage',
                label: t('product.redemption_quantity_available'),
            },
            businessDayCountryCodeList: {
                name: 'openEndedInformation.businessDayCountryCodeList',
                label: t('admin.product.business_day_countries'),
            },
            subscriptionFrequency: {
                name: 'openEndedInformation.subscriptionFrequency',
                label: t('product.subscription_frequency'),
            },
            subscriptionFrequencyDeadline: {
                name: 'openEndedInformation.subscriptionFrequencyDeadline',
                label: t('admin.product.subscription_reference_day'),
            },
            subscriptionNoticeDays: {
                name: 'openEndedInformation.subscriptionNoticeDays',
                label: t('product.subscription_notice_days'),
            },
        },
    },
} as const;

export default FormikLabels;
