import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
    ProductResponse,
    ShareClass,
    getLiquidityTypeLabels,
} from '@interfaces/Api';

import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';

import { Badge, Button, Icons } from '@components/Atoms';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
    getAssetClassTypeLabels,
    AvailabilityStatusEnum,
} from '@interfaces/Api/ProductResponse';
import { PopOver } from '@components/Molecules';
import Highlighter from 'react-highlight-words';
import { useAuthState } from '@contexts/AuthContext';
import ImageRender from '@components/Atoms/FileRender/ImageRender';

const ProductCard = ({
    searchText,
    product,
}: {
    searchText?: string | undefined;
    product: ProductResponse;
}) => {
    const {
        images,
        slug,
        name,
        umbrellaFundName,
        thumbnail,
        shortDescription,
        shareClasses,
        assetClassType,
        availabilityStatus,
        logo,
    } = product;

    const [showDescriptionPopOver, setShowDescriptionPopOver] =
        useState<boolean>(false);
    const [descriptionPopOverText, setDescriptionPopOverText] =
        useState<string>('');
    const { t } = useTranslation();
    const { forbiddenToAccessDetailPage } = useAuthState();

    const { pathname } = useLocation();
    const isProductsPage = pathname.includes('/products');
    const productLink = isProductsPage ? `./${slug}` : `/products/${slug}`;
    const shortDescriptionTruncated = shortDescription.replace(
        /(.{200})..+/,
        '$1… '
    );

    return (
        <article className="flex flex-col bg-white rounded-lg overflow-hidden border-2 rounded-xl">
            <div className="bg-product-card-bg rounded-b-xl">
                <div className="relative group">
                    <Link to={forbiddenToAccessDetailPage ? '#' : productLink}>
                        <ImageRender
                            src={(thumbnail || images[0]?.url)}
                            className=" object-cover h-64 w-full rounded-b-xl"
                            alt=""
                        />
                    </Link>
                    <p
                        className={classNames(
                            'absolute right-2 top-2 p-2 cursor-pointer group',
                            {
                                'bg-white rounded-full':
                                    availabilityStatus !==
                                    AvailabilityStatusEnum.openForSubscription,
                            }
                        )}
                    >
                        {availabilityStatus !==
                            AvailabilityStatusEnum.openForSubscription && (
                            <Icons name="LockClosedIcon" />
                        )}
                    </p>
                </div>

                <div className="p-3 h-24 relative">
                    {umbrellaFundName && (
                        <p className="text-white text-sm">
                            <Highlighter
                                searchWords={searchText?.split(' ') || []}
                                autoEscape={true}
                                textToHighlight={umbrellaFundName}
                            />
                        </p>
                    )}

                    {logo?.[0]?.url ? (
                        <div className="w-36 mt-2">
                            <ImageRender alt="Logo"  src={(logo?.[0].url)} />
                        </div>
                    ) : (
                        <p className="text-white">
                            <span className="text-xl xs:text-2xl">
                                {' '}
                                <Highlighter
                                    searchWords={searchText?.split(' ') || []}
                                    autoEscape={true}
                                    textToHighlight={name}
                                />
                            </span>
                        </p>
                    )}
                </div>
            </div>

            <p className="m-3 h-28">
                <Highlighter
                    searchWords={searchText?.split(' ') || []}
                    autoEscape={true}
                    textToHighlight={shortDescriptionTruncated}
                />
                {shortDescription.length > 200 && (
                    <PopOver
                        isOpen={showDescriptionPopOver}
                        positions={['bottom']}
                        align="start"
                        containerClassName="w-1/4"
                        content={<p>{descriptionPopOverText}</p>}
                        onClickOutside={() => setShowDescriptionPopOver(false)}
                    >
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setDescriptionPopOverText(shortDescription);
                                setShowDescriptionPopOver(
                                    !showDescriptionPopOver
                                );
                            }}
                            className="underline"
                        >
                            Read more
                        </a>
                    </PopOver>
                )}
            </p>

            <div className="flex justify-between py-6 px-4 border-t">
                <div>
                    {getLiquidityTypeLabels(t, product.liquidityType) && (
                        <Badge
                            label={getLiquidityTypeLabels(
                                t,
                                product.liquidityType
                            )}
                            badgeType="neutral"
                            sizeType="medium"
                            highlightedText={searchText}
                        />
                    )}
                </div>
                <div>
                    {assetClassType && (
                        <p className="product-badge">
                            <Highlighter
                                searchWords={searchText?.split(' ') || []}
                                autoEscape={true}
                                textToHighlight={toCapitalizedWords(
                                    getAssetClassTypeLabels(t, assetClassType)
                                )}
                            />
                        </p>
                    )}
                </div>
            </div>

            <div className="flex bg-white items-center justify-between">
                <div className="flex m-5 space-x-3">
                    {shareClasses
                        ?.map(
                            (shareClass: ShareClass, i: number) =>
                                shareClass.currency
                        )
                        .filter(
                            (val, index, self) => self.indexOf(val) === index
                        )
                        .map((val, i) => (
                            <div
                                key={i}
                                className="bg-gold-300 text-xs rounded-xl inline-block leading-8 px-2"
                            >
                                <Highlighter
                                    searchWords={searchText?.split(' ') || []}
                                    autoEscape={true}
                                    textToHighlight={val}
                                />
                            </div>
                        ))}
                </div>
                <div className="flex flex-col m-3 text-right">
                    {!forbiddenToAccessDetailPage && (
                        <Link to={'./' + slug}>
                            <Button type="button" className="uppercase">
                                {t('product.card.view')}
                            </Button>
                        </Link>
                    )}
                    {forbiddenToAccessDetailPage && (
                        <Button
                            type="button"
                            className="uppercase"
                            disabled={true}
                        >
                            {t('product.card.view')}
                        </Button>
                    )}
                </div>
            </div>
        </article>
    );
};

export default ProductCard;
