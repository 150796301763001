import { useEffect, useState } from 'react';

import { EndInvestorProfileGroupResponse } from '@api/EndInvestorProfileGroups';
import {
    EndInvestorProfileResponse,
    IntermediaryResponse,
} from '@interfaces/Api';

import { filterProfiles } from '@helpers/EndInvestorHelper';

import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { ClientPillType } from './pillEnum';

export const useClientsFilter = ({
    nameSearch,
    intermediaries,
    pillValue,
}: {
    nameSearch: string | undefined;
    intermediaries: IntermediaryResponse[];
    pillValue?: string;
}) => {
    const {
        endInvestorProfiles,
        endInvestorProfilesWithoutLoginAccount:
            endInvestorProfilesWithoutLoginAccount,
    } = useEndInvestorProfileState();
    const { currentUser } = useAuthState();

    const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
    const [endInvestorProfileGroups, setEndInvestorProfileGroups] = useState<
        EndInvestorProfileGroupResponse[]
    >([]);
    const [visibleEndInvestorProfiles, setVisibleEndInvestorProfiles] =
        useState<EndInvestorProfileResponse[]>([]);

    useEffect(() => {
        const endInvestorsData =
            pillValue === ClientPillType.EndInvestorsWithoutLoginAccount
                ? endInvestorProfilesWithoutLoginAccount
                : endInvestorProfiles;

        const allEndInvestorProfiles = endInvestorsData?.filter((val) =>
            filterProfiles(
                endInvestorProfiles,
                val,
                intermediaries,
                selectedGroupIds,
                endInvestorProfileGroups,
                nameSearch
            )
        );

        setVisibleEndInvestorProfiles(allEndInvestorProfiles || []);
    }, [
        currentUser,
        endInvestorProfiles,
        nameSearch,
        endInvestorProfileGroups,
        selectedGroupIds,
        intermediaries,
        pillValue,
    ]);

    return {
        setSelectedGroupIds,
        visibleEndInvestorProfiles,
        setEndInvestorProfileGroups,
    };
};
