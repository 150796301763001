import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icons } from '@components/Atoms';
export interface ReportsHeaderProps {
    title: string;
    shareClassInfo: string;
    dataExport: Function;
}

const SubscriptionInvestmentReportsHeader: React.FC<ReportsHeaderProps> = (
    props: ReportsHeaderProps
) => {
    const { t } = useTranslation();
    const handleExport = (e: React.MouseEvent<HTMLButtonElement>): void => {
        const target: any = e.target as HTMLButtonElement;
        return target.value;
    };

    return (
        <div className="py-4 text-gray-700 transition duration-500 ease-in-out border-b border-gray-200 dark:text-gray-500">
            <h2 className="text-xl font-medium capitalize md:text-2xl text-logo-blue">
                {props.title}
            </h2>
            <div className="flex items-center justify-between my-4">
                <span className="inline-block p-2 text-xs leading-4 bg-purple-100 md:text-base rounded-xl md:leading-8 md:px-2">
                    {t('subscriptions.add.investment_details.share_class')}:&nbsp;<strong>{props.shareClassInfo}</strong>
                </span>

                <div className="flex items-center select-none">
                    <Button
                        onClick={() => props.dataExport(handleExport)}
                        buttonType="secondary"
                        className="p-1 ml-2 text-xs transition duration-500 ease-in-out rounded-full md:p-4 focus:outline-none hover:text-logo-blue hover:bg-gold-300 md:text-base"
                    >
                        <Icons name="ArrowDownTrayIcon" size="large" />
                        &nbsp;{t('client.investment_reports.export_csv')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionInvestmentReportsHeader;
