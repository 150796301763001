import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

export const useHasEndInvestorLoginAccount = ({
    endInvestorProfileId,
}: {
    endInvestorProfileId: string;
}) => {
    const { endInvestorProfilesWithoutLoginAccount } =
        useEndInvestorProfileState();

    const hasAccount =
        endInvestorProfilesWithoutLoginAccount.length > 0 &&
        endInvestorProfileId &&
        endInvestorProfilesWithoutLoginAccount.some(
            (el) => el._id === endInvestorProfileId
        );

    return {
        hasEndInvestorLoginAccount: hasAccount,
    };
};
