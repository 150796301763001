import { useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PortfolioGet } from '@api/Dashboard';
import { AuthRoleEnum, EndInvestorPortfolioItem } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';

import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';

import { BasicBreadcrumbs, Icons } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import {
    ActionsMenu,
    PillSelector,
    ReviewFrame,
    ToggleSwitch,
} from '@components/Molecules';
import {
    IncompleteClientRegistrationContainer,
    ModalWrapper,
} from '@components/Organisms';
import DashboardPage from '@pages/AnalyticsTools/DashboardPage';
import ClientInvestmentReports from '@pages/Portfolio/Components/ClientInvestmentReports';

import {
    ClientSubscriptionsList,
    EndInvestorNameHeadingComponent,
    OptUpRequests,
    ReviewClientSubscriptionTemplateList,
    UnderlyingClients,
} from './Components';

import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useProfiles } from '@hooks/useProfiles';
import { usePortfolioActionMenuItems } from './usePortfolioActionMenuItems';
import {
    PortfolioPillOptionType,
    usePortfolioPills,
} from './usePortfolioPills';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import CannedReports from './Components/CannedReports';

const PortfolioBreadcrumbs = () => {
    const { t } = useTranslation();
    const params = useParams();

    const { endInvestorProfiles } = useEndInvestorProfileState();
    const endInvestorProfile = endInvestorProfiles.find(
        ({ _id }) => _id === params.endInvestorProfileId
    );
    const { hasRoles } = useAuthState();

    const isEndInvestor = hasRoles([AuthRoleEnum.endInvestor]);

    const getItems = (t: TFunction<'translation', undefined>) =>
        isEndInvestor
            ? [
                {
                    displayText: t('navbar.portfolio'),
                    navigationUrl: '',
                },
            ]
            : [
                {
                    displayText: t('navbar.clients'),
                    navigationUrl: '/clients',
                },
                {
                    displayText: getEndInvestorName(endInvestorProfile),
                    navigationUrl: '',
                },
            ];

    return <BasicBreadcrumbs items={getItems(t)} />;
};

const Portfolio: React.FC = () => {
    const { t } = useTranslation();
    const [portfolioData, setPortfolioData] = useState<
        EndInvestorPortfolioItem[] | undefined
    >(undefined);
    const [portfolioDataLoading, setPortfolioDataLoading] = useState<
        boolean | undefined
    >(false);
    const [selectedPill, setSelectedPill] = useState<PortfolioPillOptionType>();
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const { profiles } = useProfiles();
    const { configuration } = useConfigurationState();
    const {
        isEndInvestorProfileLoading,
        endInvestorProfile,
        endInvestorProfileId,
    } = useEndInvestorProfile();

    const navigate = useNavigate();

    const { currentUser } = useAuthState();

    const { actionMenuItems } = usePortfolioActionMenuItems({
        selectedPill,
    });

    const { pillOptions } = usePortfolioPills(setSelectedPill, portfolioData);

    useEffect(() => {
        // this logic should be sitting in a portfolio context as it is reused in multiple places within the portoflio page
        // non need to add a ticket as it will be addressed once we adopt a state mamagnment library
        const getPortfolioData = async () => {
            try {
                setPortfolioDataLoading(true);
                const data = await PortfolioGet(endInvestorProfileId!);
                setPortfolioData(data);
                setPortfolioDataLoading(false);
            } catch (e) {
                setPortfolioData(undefined);
                setPortfolioDataLoading(false);
            }
        };
        const isPortfolioViewEnabled =
            configuration.preferences?.feature?.clientView?.enablePortfolioView;
        if (endInvestorProfileId && isPortfolioViewEnabled) getPortfolioData();
    }, [configuration.preferences?.feature?.clientView?.enablePortfolioView, endInvestorProfileId]);
    const [searchParams, _] = useSearchParams();
    const [selectedOptUpRequestId, setSelectedOptUpRequestId] = useState<
        undefined | string
    >();
    useEffect(() => {
        if (!searchParams) return;

        const optUpRequestFromQueryString = searchParams.get(
            'selectedOptUpRequestId'
        );
        optUpRequestFromQueryString &&
            setSelectedOptUpRequestId(optUpRequestFromQueryString);
    }, [searchParams]);

    return (
        <>
            {isEndInvestorProfileLoading ? (
                <div className="ml-2">
                    <Icons name="Loading" size="xxl" />
                </div>
            ) : (
                <>
                    {endInvestorProfile ? (
                        <div>
                            <div className="flex flex-col items-start justify-between md:flex-row gap-y-3">
                                <EndInvestorNameHeadingComponent
                                    endInvestorProfile={endInvestorProfile}
                                />

                                <div className="flex flex-col items-start justify-between md:flex-row gap-x-2">
                                    <ActionsMenu
                                        actionItems={actionMenuItems}
                                    />
                                </div>
                            </div>

                            <br />

                            <div className="flex">
                                <PillSelector<PortfolioPillOptionType>
                                    options={pillOptions}
                                    selectedValue={selectedPill}
                                    onChange={(key) => {
                                        navigate(`?section=${key}`, {
                                            replace: true,
                                        });
                                        setSelectedPill(key);
                                    }}
                                />
                            </div>

                            {selectedPill === 'Portfolio' &&
                                currentUser?.user.role && (
                                    <DashboardPage
                                        currentUserRole={currentUser.user.role}
                                        portfolioData={portfolioData || []}
                                        portfolioDataLoading={
                                            portfolioDataLoading
                                        }
                                    />
                                )}

                            {selectedPill === 'Subscriptions' && (
                                <ClientSubscriptionsList
                                    endInvestorProfile={endInvestorProfile}
                                />
                            )}

                            {selectedPill === 'InvestmentReportsDetails' && (
                                <ClientInvestmentReports
                                    endInvestorProfileId={
                                        endInvestorProfile?._id
                                    }
                                />
                            )}
                            {
                                selectedPill === 'Documents' && (<CannedReports
                                    endInvestorProfileId={
                                        endInvestorProfile?._id
                                    } />)
                            }
                            {selectedPill === 'OptUpRequests' && (
                                <OptUpRequests
                                    endInvestorProfileId={
                                        endInvestorProfile?._id
                                    }
                                    selectedOptUpRequestId={
                                        selectedOptUpRequestId
                                    }
                                />
                            )}

                            {selectedPill === 'UnderlyingClients' && (
                                <UnderlyingClients
                                    endInvestorProfile={endInvestorProfile}
                                />
                            )}

                            {/* {selectedPill === 'SubscriptionTemplates' &&
                                currentUser?.user.role !==
                                    AuthRoleEnum.fundAdmin && (
                                    <>
                                        <div className="flex justify-end w-full my-2">
                                            <ToggleSwitch
                                                title={t(
                                                    'ui.controls.show_hide_all'
                                                )}
                                                checked={isExpanded}
                                                onChange={() =>
                                                    setIsExpanded(!isExpanded)
                                                }
                                            />
                                        </div>
                                        <div className="w-full my-2">
                                            <ReviewFrame
                                                title={t(
                                                    'client.subscription_templates.preconfigured_subscription_templates'
                                                )}
                                                className="-my-4"
                                                expanded={isExpanded}
                                            >
                                                <ReviewClientSubscriptionTemplateList
                                                    endInvestorProfile={
                                                        endInvestorProfile
                                                    }
                                                />
                                            </ReviewFrame>
                                        </div>
                                    </>
                                )} */}
                        </div>
                    ) : (
                        <IncompleteClientRegistrationContainer />
                    )}
                </>
            )}
            <ModalWrapper msgPrefix="risk-warning" />
            <ModalWrapper msgPrefix="verify-investor-email" />
        </>
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    Portfolio,
    undefined,
    <PortfolioBreadcrumbs />
);
