import { RunSubscriptionSubmissionJob } from '@api/Jobs';
import { Button } from '@components/Atoms';
import { notifyError, notifySuccess } from '@helpers/toastrHelper';
import { useState } from 'react';

const Jobs = () => {
    const [isJobRunning, setIsJobRunning] = useState<boolean>(false);

    const handleClick = async () => {
        setIsJobRunning(true);
        const result = await RunSubscriptionSubmissionJob();
        if (result.success) {
            notifySuccess(result.message, 20000);
        } else {
            notifyError(result.message);
        }
        setIsJobRunning(false);
    };

    return (
        <div className="xs:container xs:mx-auto max-w-full">
            <div className="flex flex-wrap items-stretch">
                <Button
                    id="subscription-submission-job-button"
                    label={`Run Subscription Submission Job`}
                    className="mt-4"
                    type="button"
                    onClick={handleClick}
                    isLoading={isJobRunning}
                />
            </div>
        </div>
    );
};

export default Jobs;
