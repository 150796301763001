export const Nda = () => {
    const htmlContent = `
    <p><strong><span style="font-weight:bold;font-size:24px;">Website terms and conditions&nbsp;</span></strong></p>
<p><span style="font-size:14px;">These terms and conditions (&ldquo;Website Terms&rdquo;) are the terms of use of this website and any other S64 Website (as defined below). They set out the terms on which you may use this website or any other website owned and operated by S64 that you are given access to. You should read these Website Terms carefully before proceeding to use this or any other website operated by S64.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Acceptance of these terms of use&nbsp;</span></strong></p>
<p><span style="font-size:14px;">By clicking &lsquo;Accept&rsquo; or otherwise accessing or using this website or any other S64 Website you indicate your acceptance of and agreement to abide by these Website Terms described below, all policies, guidelines and legal obligations incorporated by reference, and any subsequent changes to the foregoing.&nbsp;</span></p>
<p><span style="font-size:14px;">If you do not wish to accept or abide by these Website Terms, or you do not wish these Website Terms to apply to you, you should click &lsquo;Decline&rsquo; or navigate away from this website and should not proceed any further into or otherwise use this website or any other S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Incorporated policy&nbsp;</span></strong></p>
<p><span style="font-size:14px;">Privacy Notice &ndash; S64 believes strongly in protecting individual user privacy and providing you notice of S64&rsquo;s collection and use data, including personally identifying information, collected from any of the S64 Websites. Therefore, S64 has adopted a Privacy Policy that you should review in order to fully understand how we collect and use information. A link to the Privacy Policy can be found on the website.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">About S64&nbsp;</span></strong></p>
<p><span style="font-size:14px;">This website is owned and operated by the S64 Ventures Limited&nbsp;</span><strong><span style="font-weight:bold;font-size:14px;">(&ldquo;S64&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;</span></strong><span style="font-size:14px;">).&nbsp;</span></p>
<p><span style="font-size:14px;">S64 is registered in England (Company number: 11888553). It is an appointed representative of Sapeno Partners LLP which is authorized and regulated by the Financial Conduct Authority in the United Kingdom. All references to S64 include S64&rsquo;s subsidiaries, holding companies or other affiliates.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">S64 Website&nbsp;</span></strong></p>
<p><span style="font-size:14px;">&ldquo;S64 Website&rdquo; means this website and any other website owned and operated by S64.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Use of this website or any other S64 Websites&nbsp;</span></strong></p>
<p><span style="font-size:14px;">Access to and use of this website (or any other S64 Website), when available, is permitted on a temporary basis only. We reserve the right to:&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">withdraw or restrict access to this or any other S64 Website;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">withdraw or change any services provided on or through this or any other S64 Website;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">remove or amend any information or data provided on or through this or any other S64 Website at any time without notice and without giving any reason.&nbsp;</span></li>
</ul>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><span style="font-size:14px;">We will not accept any liability for any losses, damages or costs of any kind arising as a result of the use of or inability to use this or any other S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">None of the S64 Websites are intended to be accessed by US Persons. (&ldquo;US Person&rdquo; means any person domiciled or ordinarily resident in any part of the USA or who might otherwise be considered a citizen of the USA in accordance with Rule 902(k) of Regulation S under the U.S. Securities Act of 1933 as amended). We do not grant any right of access to any US Person. By accessing or using this or any other S64 Website you warrant that you are not a US Person and you are not located in the United States.&nbsp;</span></p>
<p><span style="font-size:14px;">The information on an S64 Website may refer to funds or other collective investment vehicles or products (a &ldquo;Fund&rdquo;) or the managers, advisers or other service providers of a Fund (each a &ldquo;Fund Party&rdquo;).&nbsp;</span></p>
<p><span style="font-size:14px;">The information accessible on an S64 Website is not an offer or solicitation for the purchase of securities, units or investments, unless expressly stated otherwise. Without limiting the generality of the foregoing, neither access to an S64 Website nor any of the Confidential Information (as defined below) constitutes an invitation or inducement of any sort to any person in any jurisdiction in which such an invitation or inducement is not permitted, S64 or any Fund Party or the underlying fund in which a Fund will invest would subject such persons to any registration, filing or licensing requirement within such jurisdiction or otherwise subject such persons to regulation by securities or other governmental authorities in such jurisdiction.&nbsp;</span></p>
<p><span style="font-size:14px;">Access to certain areas of S64 Website(s) is restricted. From time to time we may restrict access to some parts of our site, or the entire site, to users who have registered with us or who have certain professional or other qualifications or who satisfy certain specified criteria. If we provide you with a user ID and password to enable access to any restricted areas of any S64 Websites or other content or services, you must ensure that such user ID and password is kept confidential. You should notify us immediately if you become aware of any unauthorized use.&nbsp;</span></p>
<p><span style="font-size:14px;">If you enter a user ID and password in order to access any part of this or any other S64 Website then, in so doing, you warrant and represent that you have a right to use such username and password and that you have a right to enter such details and to access such restricted areas of, or information or data through, the S64 Website and that you have not obtained any username and password dishonestly or fraudulently. You agree to notify S64 if your user ID or password is lost or stolen or if you become aware that such credentials are being used in an unauthorized manner.&nbsp;</span></p>
<p><span style="font-size:14px;">If you are required to confirm any details or provide any information about yourself before accessing any part of this or any other S64 Website or any information or data supplied on or through such website then, in so doing, you warrant and represent that such confirmation is true and that any information you provide is true and accurate.&nbsp;</span></p>
<p><span style="font-size:14px;">You are responsible for making all arrangements necessary for you to have access to this or any other S64 Website. We do not make any representations or warranties as to the compatibility or accessibility of this or any other S64 Website with or through any computer system, software application, web browser or internet connection. If you authorize another person to access or use any S64 Website using your internet connection, you must ensure that such person is aware of and agrees with these Website Terms.&nbsp;</span></p>
<p><span style="font-size:14px;">The information contained on any restricted access part of this and any other S64 Website is confidential. You shall not disclose to any person any information related to any of the parties mentioned in this or any other S64 Website or the documents contained on any S64 Website and all such information is considered non-public, confidential or proprietary information (&ldquo;</span><strong><span style="font-weight:bold;font-size:14px;">Confidential Information&rdquo;</span></strong><span style="font-size:14px;">), and you may not use Confidential Information for any purpose other than in connection with an investment or a proposed investment in a Fund. You should maintain reasonable procedures to safeguard the Confidential Information from loss, unauthorized disclosure or use. You may not use Confidential Information in any manner that may be detrimental to S64, any Fund Party mentioned on this site, its affiliates or any of their respective direct or indirect subsidiaries. You shall maintain the Confidential Information in strict confidence, and you acknowledge and agree that you are responsible for the confidentiality and use of the website. You shall be responsible for all statements, acts or omissions that occur through your user ID and password. Notwithstanding the foregoing, nothing contained herein shall prevent you from furnishing:&nbsp;</span></p>
<p><span style="font-size:14px;">a) any required Confidential Information to any government or governmental, semi-governmental or judicial entity or authority or self-regulatory organization established under statute or a securities exchange (&ldquo;</span><strong><span style="font-weight:bold;font-size:14px;">Government Authority</span></strong><span style="font-size:14px;">&rdquo;) or in connection with any judicial, governmental or other regulatory proceeding or as otherwise required by law (provided that any such disclosure that is either: (i) not to a Government Authority; or (ii) not on a confidential basis, shall in each case to the fullest extent permitted by law require prior written notice thereof to S64 and you will use commercially reasonable efforts to prevent or otherwise limit such disclosure to the extent permitted by law); or&nbsp;</span></p>
<p><span style="font-size:14px;">b) any Confidential Information, so long as such disclosure is for a bona fide purpose, to your directors, agents, representatives, officers, employees and legal, financial and tax advisors (provided that any such director, officer, employee or advisor is informed of the confidential nature of any such information and that you shall be responsible for disclosure by any such person in contravention of this condition as if you were the disclosing party).&nbsp;</span></p>
<p><span style="font-size:14px;">By accepting these Website Terms, you agree that irreparable damage would occur if the provisions of these Website Terms were breached. To the maximum extent permitted by law, S64 or any Fund Party shall be entitled to seek an injunction or injunctions to prevent breaches of these terms and conditions and to enforce specifically the terms and provisions hereof in any court having jurisdiction pursuant to these Website Terms, in addition to any other remedy to which they are entitled at law.&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Intellectual property&nbsp;</span></strong></p>
<p><span style="font-size:14px;">In these Website Terms, &ldquo;</span><strong><span style="font-weight:bold;font-size:14px;">Content</span></strong><span style="font-size:14px;">&rdquo; means any datum, information, text, image, illustration, photograph, sequence, animation, video, audio, logo, trade mark (whether registered or unregistered), graphic or that might be published on or otherwise available to view, access or download from or via this or any other S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">S64 is the owner or licensee (as the case may be) of all copyright and intellectual property rights in this and any other S64 Websites and any Content. Such websites and their Content are protected by copyright laws and treaties around the world and all such rights are reserved.&nbsp;</span></p>
<p><span style="font-size:14px;">Subject to the obligations related to confidentiality referred to above, you may print off one copy, and may download extracts, of any page(s) from this or any other S64 Website for your personal reference only, and you may draw the attention of others within your organization to material posted on any S64 Websites. You must not use any part of the Content on our site for commercial purposes without obtaining a license to do so from us or our licensors. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.&nbsp;</span></p>
<p><span style="font-size:14px;">Our status (and that of any identified contributors) as the authors of material on any S64 Websites must always be acknowledged and referenced.&nbsp;</span></p>
<p><span style="font-size:14px;">If you print off, copy or download any part of this or any other S64 Website in breach of these terms of use, your right to use any S64 Websites will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Trade marks&nbsp;</span></strong></p>
<p><span style="font-size:14px;">S64 or the relevant owner, retains all rights (including copyrights, trademarks, patents as well as any other intellectual property right) in relation to all information provided on or via this website or other S64 Websites (including all texts, graphics and logos). Furthermore, you may not use, copy, modify, license, distribute, frame or utilize framing techniques to enclose any S64 trademark, logo or other proprietary information (including the images found at this website or other S64 Websites operated throughout S64, the content of any text or the layout/design of any page or form contained on a page) without a license agreement issued by S64 or where appropriate S64&rsquo;s express written consent. Further, you may not use any meta tags or any other &quot;hidden text&quot; utilizing a S64 name, trademark, or product name without S64&rsquo;s express written consent. Any unauthorized use of this Site will terminate the permission or license granted by these Website Terms and may violate applicable law including copyright laws, trademark laws, and communications regulations and statutes. S64 will pursue all violators the fullest extent of the law.&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Reliance on information posted&nbsp;</span></strong></p>
<p><span style="font-size:14px;">Whilst every care is taken to ensure the accuracy of the information contained, the opinions, analysis and facts stated are based on information and sources which, while we believe them to be reliable, are not guaranteed. In particular, the materials are provided &quot;as is&quot; without any express or implied warranty of any kind and should not be relied upon as the sole source of reference in relation to the subject matter. No liability can be accepted by S64, its subsidiaries, affiliates, directors, officers or employees for any loss to any person or entity acting or failing to act as a result of anything contained in or omitted from the content, or our conclusions as stated. Nothing on any S64 Website is intended to amount to advice on which reliance should be placed. In particular:&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">nothing on any of the S64 Websites should be relied upon as financial advice or a personal recommendation;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">no data or information on any S64 Websites should be relied upon as a basis for any financial or investment advice or a personal recommendation; and&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">no action should be taken as a result of anything read on any of the S64 Websites without taking advice from an individual or firm authorized to provide such advice.&nbsp;</span></li>
</ul>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><span style="font-size:14px;">Some of the documents posted on an S64 Website may contain additional disclosures and disclaimers and those terms will also apply to the documents to which they are attached.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Our site changes regularly&nbsp;</span></strong></p>
<p><span style="font-size:14px;">We aim to update the S64 Websites regularly,&nbsp;</span><span style="font-size:14px;"><ins cite="mailto:MOIX Jacques" datetime="2023-10-25T12:34">&nbsp;</ins></span><span style="font-size:14px;">and may change the content of any of them at any time. If the need arises, we may suspend access to any or all S64 Websites, or close any or all of them indefinitely.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Durable Medium</span></strong></p>
<p><span style="font-size:14px;">With regard to the documents placed or to be placed on the platform and the information contained therein, which according to the relevant regulatory requirements are to be provided on a durable medium, the user had the choice of receiving them in paper form or on another durable medium (within the meaning of article 4 (1) (62) of Directive 2014/65/EU) . By agreeing to the Website Terms, the user expressly chooses to receive the relevant documents and information placed or to be placed on the platform only via the S64 website, which qualifies as a durable medium as defined above. The user expressly waives the right to receive the relevant documents and information contained therein in paper form.</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Our liability&nbsp;</span></strong></p>
<p><span style="font-size:14px;">The Content displayed on this and any other S64 Website is provided without any guarantees, conditions or warranties as to its accuracy, fitness for purpose or otherwise. To the extent permitted by law, in no event shall S64, its subsidiaries, affiliates, directors, officers or employees be liable for any direct, indirect, incidental, special or consequential damages including, without limitation:&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">any indirect loss of profit;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">data;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">good will;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">loss of savings;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">loss of anticipated savings;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">loss of opportunity;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">damage to reputation; and&nbsp;</span></li>
</ul>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><span style="font-size:14px;">for any other loss or damage of any kind howsoever arising, including by way of negligence (whether under contract or tort), willful default, misrepresentation (negligent or fraudulent) or fraud) arising out of the use of or inability to use the S64 Website(s) any websites linked to it, and any materials posted on any such site, even if foreseeable, provided that this condition shall not prevent claims for loss of or damage to your tangible property or any other claims for direct financial loss that are not excluded by any of the categories set out above.&nbsp;</span></p>
<p><span style="font-size:14px;">Furthermore, S64 do not warrant the accuracy or completeness of the information, text, graphics, links or other items contained within these sites and may make changes at will to any site, or to the products described therein, at any time without notice. S64 makes no commitment to update the S64 website(s).&nbsp;</span></p>
<p><span style="font-size:14px;">Nothing in these Website Terms shall affect our liability for:&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">death or personal injury arising from our negligence;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">fraudulent misrepresentation or misrepresentation as to a fundamental matter; and&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">any other liability which cannot be excluded or limited under any applicable law.&nbsp;</span></li>
</ul>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Information about you and your visits to our site&nbsp;</span></strong></p>
<p><span style="font-size:14px;">We process information about you in accordance with our Privacy Policy. By clicking &lsquo;Accept, or by using or accessing this or any other S64 Website, you consent to such processing and you warrant that all data provided by you is accurate. If you do not agree to our Privacy Policy you must click &lsquo;Decline&rsquo; or otherwise navigate away from our website and must not attempt to access or use any S64 Website.&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Use of cookies on our site&nbsp;</span></strong></p>
<p><span style="font-size:14px;">A &apos;cookie&apos; is a small piece of information which a website places into your browser and can retrieve later. It cannot be read by any website other than that which placed the cookie nor does it contain information which could be used to identify you. We use cookies to facilitate use of our website and access across various pages. Some are temporary and remain in the cookie file of your browser until you leave the site others remain for a longer period avoiding the need for you to re-enter certain information.&nbsp;</span></p>
<p><span style="font-size:14px;">By clicking &lsquo;Accept&rsquo; or continuing to access this or any other S64 Website you consent to cookies being stored on your computer as a consequence of accessing this site. If you do not consent to cookies being stored on your computer you should click &lsquo;Decline&rsquo; and should not access or use this or any other S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Uploading material to our site&nbsp;</span></strong></p>
<p><span style="font-size:14px;">Whenever you make use of a feature that allows you to upload material to our site, or to make contact with other users of our site, you do so on the basis of the Acceptable Use Warranties set out below. Any such contribution must comply with the Acceptable Use Warranties and you indemnify us for any breach of those warranties.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Acceptable Use Warranties&nbsp;</span></strong></p>
<p><span style="font-size:14px;">If you upload any material to this or any other S64 Website or if you post any comment or picture or other material to any forum on this or any other S64 Website then on so doing you make the following warranties:&nbsp;</span></p>
<ul style="list-style-type: undefined;margin-left:26px;">
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that you have all rights and permissions necessary to upload or post such material;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that in uploading or posting any material you do not infringe any copyright or intellectual property rights belonging to any other person nor cause S64 to infringe any copyright or intellectual property rights belonging to any person;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that any comments or remarks you post and the content of any material you upload is not defamatory or libelous or likely to cause any person to make a claim against S64;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that any comments or remarks you post and the content of any material you upload does not contain foul language or malicious or hurtful comments and is not likely to cause any offence to any person;&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that any comments or remarks you post or material you upload is not confidential and does not breach any person&rsquo;s confidentiality or privacy rights and you have a right to release such material into the public domain; and&nbsp;</span></li>
    <li><span style="font-family:Calibri;font-size:14px;font-size:10.5000pt;">that no material you upload nor comment or material you post is likely to cause any person loss or damage or give rise to any claim by any person against S64.&nbsp;</span></li>
</ul>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><span style="font-size:14px;">Any material you upload to our site will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any such material for any purpose. We also have the right to disclose your identity to any third party who is claiming that any material posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.&nbsp;</span></p>
<p><span style="font-size:14px;">We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our site.&nbsp;</span></p>
<p><span style="font-size:14px;">We have the right to remove any material or posting you make on our site if, in our opinion, such material does not comply with our expected content standards or the Acceptable Use Warranties above.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Viruses, hacking and other offences&nbsp;</span></strong></p>
<p><span style="font-size:14px;">You must not misuse any S64 Website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to any S64 Website, the server on which any S64 Website is stored or any server, computer or database connected to our any S64 Website. You must not attack any S64 Website via a denial-of-service attack or a distributed denial-of service attack.&nbsp;</span></p>
<p><span style="font-size:14px;">You must not copy or attempt to copy, record, film, modify, adapt, reverse engineer, decompile or disassemble any S64 Website or any functionality made available through an S64 Website, either in whole or in part, or permit any other person or entity to do so. You may not use any knowledge of any S64 Website or the functionality made available through an S64 Website to create software or websites whose functions are substantially similar to those of an S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">By breaching the provisions in this section, you may commit a criminal offence under the Computer Misuse Act or other relevant legislation. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use all S64 Websites will cease immediately.&nbsp;</span></p>
<p><span style="font-size:14px;">We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of any S64 Website or to your downloading of any material posted on it, or on any website linked to it.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Linking to our site&nbsp;</span></strong></p>
<p><span style="font-size:14px;">You may link to our home page only, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists, nor will you utilize any S64 logo to embed any link without a license agreement.&nbsp;</span></p>
<p><span style="font-size:14px;">You must not establish a link from any website that is not owned by you.&nbsp;</span></p>
<p><span style="font-size:14px;">No S64 Website may be framed on any other site, nor may you create a link to any part of an S64 Website other than the home page. We reserve the right to withdraw linking permission without notice. The website from which you are linking must comply in all respects with the Acceptable Use Warranties set out above.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Links from our site&nbsp;</span></strong></p>
<p><span style="font-size:14px;">Where an S64 Website contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. Any reference to a third party link or website cannot be taken to imply any endorsement or validation by us of the content of the said website. S64 is not responsible for the privacy practices nor do we accept any liability in connection with the content of such websites.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Responsibility for your conduct&nbsp;</span></strong></p>
<p><span style="font-size:14px;">You shall remain solely liable for the Content of any messages or other information you upload or transmit to any S64 Website, including the discussion forums or interactive areas of an S64 Website. You agree to indemnify and hold harmless S64 from any claim, action, demand, loss, or damages (including reasonable legal fees) made or incurred by any third party arising out of or relating to your conduct, your violation of these Website Terms, or your violation of any rights of a third party.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Variations&nbsp;</span></strong></p>
<p><span style="font-size:14px;">We may revise these Website Terms at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Some of the provisions contained in these Website Terms of use may also be superseded by provisions or notices published elsewhere on an S64 Website.&nbsp;</span></p>
<p><span style="font-size:14px;">&nbsp;</span></p>
<p><strong><span style="font-weight:bold;font-size:24px;">Jurisdiction and applicable law&nbsp;</span></strong></p>
<p><span style="font-size:14px;">The English courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to an S64 Website although we retain the right to bring proceedings against you for breach of these conditions in your country of residence or any other relevant country.&nbsp;</span></p>
<p><span style="font-size:14px;">These Website Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.</span></p>
    `;

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
}