import React, { useState, useEffect } from 'react';

import { SystemUserResponse } from '@interfaces/Api';
import { AssetManagerResponse } from '@interfaces/AssetManager';

import { Button } from '@components/Atoms';
import { Modal } from '@components/Molecules';
import { Pagination } from '@components/Organisms';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { useSortableData } from '@hooks/useSortableData/useSortableData';

import { useSystemUsers } from '@stores/SystemUsers/useSystemUsers';
import { useIntermediaries } from '@stores/Intermediaries/useIntermediaries';
import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';

import { getAllAssetManagers } from '@services/AssetManagers';

import UsersTable from './Components/UsersTable';
import { TopBar } from './Components/TopBar';
import { useSystemUsersActions } from '@stores/SystemUsers';
import { useTranslation } from 'react-i18next';

export const ListSystemUsers: React.FC = () => {
    const { t } = useTranslation();
    const [assetManagers, setAssetManagers] =
        useState<AssetManagerResponse[]>();
    const [tableData, setTableData] = useState<any[]>([]);
    const { systemUsers, archiveSystemUser } = useSystemUsers();
    const { fetchSystemUsers } = useSystemUsersActions();
    const { getAllEndInvestorProfileData } = useEndInvestorProfileState();
    const { currentSystemUserRoleActions } = useAuthState();
    const { intermediaries } = useIntermediaries();
    const [searchText, setSearchText] = useState<string>();
    const [showArchiveModal, setShowArchiveModal] = useState<{
        enabled: boolean;
        systemUser?: SystemUserResponse;
    }>({ enabled: false });

    const { preferences } = useConfigurationState();
    const isOwnerVisible =
        preferences?.feature?.userManagement?.displayOwnerEntity;

    const [itemsPerPage, setItemsPerPage] = useState<number | string>(50);

    useEffect(() => {
        const fetchAssetManagers = async () => {
            getAllAssetManagers().then((res) => {
                setAssetManagers(res);
            });
        };

        fetchAssetManagers();
    }, []);

    useEffect(() => {
        const tableData = isOwnerVisible
            ? systemUsers?.map((el) => {
                  const ownerName =
                      intermediaries?.find(
                          (intermediary) =>
                              intermediary._id === el.intermediaryId
                      )?.name ||
                      assetManagers?.find(
                          (assetManager) =>
                              assetManager._id === el.assetManagerId
                      )?.name;

                  return {
                      ...el,
                      owner: ownerName,
                  };
              })
            : systemUsers;

        setTableData(tableData);
    }, [assetManagers, intermediaries, isOwnerVisible, systemUsers]);

    const {
        tableData: visibleUsers,
        tableDataLength,
        requestSort,
        sortConfig,
        setCurrentPageNumber,
        currentPageNumber,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData,
        searchText,
        itemsPerPage,
    });

    const handleArchiveSystemUser = async () => {
        try {
            if (showArchiveModal.systemUser) {
                await archiveSystemUser(showArchiveModal.systemUser._id);
                if (showArchiveModal.systemUser.endInvestorProfileIds?.length) {
                    await getAllEndInvestorProfileData();
                }
            }

            notifySuccess(t('admin.user_management.system_user_has_been_deleted'));
            setTimeout(() => setShowArchiveModal({ enabled: false }), 1000);
        } catch (err) {
            notifyError(t('admin.user_management.fail_to_delete_system_user'));
        }
    };

    const pagination = (
        <Pagination
            rowsPerPage={itemsPerPage}
            itemsLength={tableDataLength}
            paginate={paginate}
            currentPage={currentPageNumber}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            onPageSizeChange={setItemsPerPage}
            className=""
        />
    );

    return (
        <div className="w-full relative">
            <TopBar
                setSearchText={setSearchText}
                setCurrentPageNumber={setCurrentPageNumber}
                getSystemUsers={fetchSystemUsers}
            />

            {pagination}

            <UsersTable
                searchText={searchText || ''}
                items={visibleUsers}
                currentUserRoleActions={currentSystemUserRoleActions}
                requestSort={requestSort}
                columnSortConfig={sortConfig}
                archiveUser={(systemUser: SystemUserResponse) => {
                    setShowArchiveModal({
                        enabled: true,
                        systemUser,
                    });
                }}
            />

            {pagination}

            <Modal
                show={showArchiveModal.enabled}
                onBackdropClick={() => setShowArchiveModal({ enabled: false })}
            >
                <Modal.Header
                    onClose={() => setShowArchiveModal({ enabled: false })}
                >
                    <h2 className="text-2xl">{t('admin.user_management.archive_user')}</h2>
                </Modal.Header>
                <Modal.Body>
                    {t('admin.user_management.are_you_sure_to_archive_user')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowArchiveModal({ enabled: false })}
                        buttonType="secondary"
                        label={t('ui.controls.cancel')}
                    />
                    <Button onClick={handleArchiveSystemUser} label="Delete" />
                </Modal.Footer>
            </Modal>
        </div>
    );
};
