import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    AuthPermissionEnum,
    SubscriptionStatusEnum,
    EndInvestorProfileResponse,
    AuthRoleEnum,
} from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';
import { useEndInvestorProfileState } from '@contexts/EndInvestorProfileContext';

import SubscriptionNoticeManagement from '@containers/Subscriptions/SubscriptionNoticeManagement';
import DocumentSigningSummary from '@containers/Subscriptions/DocumentSigningSummary';

import { Badge, BasicBreadcrumbs, Button, Icons } from '@components/Atoms';
import { ReviewFrame } from '@components/Molecules';
import { ReviewSummary, SubscriptionStatusInfo } from '@components/Organisms';

import {
    getSubscriptionSteps,
    SubscriptionStep,
} from '@services/SubscriptionsHelper';

import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { getEndInvestorName } from '@helpers/EndInvestorHelper';
import { useProduct } from '@stores/Products/useProduct';
import { toCapitalizedWords } from '@helpers/ToCapitalizedWords';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useSubscription } from '@stores/Subscriptions/useSubscription';
import SubscriptionReview from './SubscriptionReview';
import { EndInvestorProfileGet } from '@api/EndInvestorProfiles';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';

const useEndInvestorProfile = (endInvestorProfileId) => {
    const [endInvestorProfile, setEndInvestorProfile] = useState<
        EndInvestorProfileResponse | undefined
    >(undefined);

    useEffect(() => {
        const getEndInvestorProfile = async () => {
            if (endInvestorProfileId) {
                const endInvestorProfileResponse = await EndInvestorProfileGet(
                    endInvestorProfileId
                );
                setEndInvestorProfile(endInvestorProfileResponse);
            }
        };
        getEndInvestorProfile();
    }, [endInvestorProfileId]);

    return { endInvestorProfile };
};

export const Subscription = () => {
    const { t } = useTranslation();
    const { subscriptionId } = useParams();
    const { subscription } = useSubscription(subscriptionId);
    const { preferences, isClient, configuration } = useConfigurationState();
    const { product } = useProduct(subscription.productId);
    const { downloadInvestorProfileDocuments } = useEndInvestorProfileState();
    const { hasPermissions } = useAuthState();

    const endInvestorProfileId = subscription.endInvestorProfileId;
    const { endInvestorProfile } = useEndInvestorProfile(endInvestorProfileId);

    const [isDocumentPackDownloading, setIsDocumentPackDownloading] =
        useState<boolean>(false);

    const [steps, setSteps] = useState<SubscriptionStep[]>([]);

    useEffect(() => {
        const steps = getSubscriptionSteps(
            t,
            endInvestorProfile,
            subscription,
            preferences?.feature,
            product,
            isClient,
            configuration?.client
        );
        setSteps(steps);
    }, [
        endInvestorProfile,
        subscription,
        preferences?.feature,
        product,
        isClient,
        t,
    ]);

    const endInvestorName = getEndInvestorName(endInvestorProfile);

    const handleDownloadDocumentPack = async () => {
        setIsDocumentPackDownloading(true);

        await downloadInvestorProfileDocuments(
            subscription.endInvestorProfileId!,
            subscription._id
        );

        setIsDocumentPackDownloading(false);
    };

    if (subscription?._id)
        return (
            <div className="w-full py-6 mx-auto lg:w-10/12">
                <div className="w-full">
                    {subscription?._id && (
                        <SubscriptionStatusInfo
                            subscription={subscription}
                            product={product}
                        />
                    )}
                </div>
                <div>
                    <div className="flex flex-col items-start justify-between md:flex-row gap-y-3">
                        <h1 className="text-3xl text-logo-blue">
                            {t('subscriptions.add.review_subscription.title')}
                        </h1>

                        {hasPermissions(
                            AuthPermissionEnum.getEndInvestorProfileDocuments
                        ) && (
                            <Button
                                label={t(
                                    'subscriptions.add.review_subscription.download_pack_button'
                                )}
                                onClick={handleDownloadDocumentPack}
                                endIcon={
                                    <Icons
                                        name={
                                            isDocumentPackDownloading
                                                ? 'Loading'
                                                : 'ArrowDownTrayIcon'
                                        }
                                    />
                                }
                                disabled={isDocumentPackDownloading}
                            />
                        )}
                    </div>

                    {subscription.status ==
                        SubscriptionStatusEnum.inProgress && (
                        <p className="py-3 text-base">
                            Please ensure all details below are correct. Once
                            confirmed, contracts will be created for the
                            necessary signers
                        </p>
                    )}

                    <h3 className="pr-3 text-2xl cursor-pointer md:pr-2">
                        <span className="text-brand-navy">
                            {endInvestorName}
                        </span>
                        <span className="align-middle md:inline-block">
                            <Badge
                                label={
                                    endInvestorProfile?.endInvestorType !==
                                    EndInvestorProfileResponse.EndInvestorType
                                        .individual
                                        ? t('end_investor_type.business_entity')
                                        : t('end_investor_type.individual')
                                }
                                badgeType="primary"
                                sizeType="small"
                            />
                        </span>
                    </h3>

                    <ReviewSummary subscription={subscription} steps={steps} endInvestorProfile={endInvestorProfile}/>

                    <DocumentSigningSummary
                        signingDocuments={subscription.signingDocuments}
                        subscriptionId={subscription._id}
                    />

                    {endInvestorProfile && (
                        <SubscriptionReview
                            endInvestorProfile={endInvestorProfile}
                        />
                    )}

                    {[
                        SubscriptionStatusEnum.approved,
                        SubscriptionStatusEnum.rejected,
                    ].includes(subscription.status!) && (
                        <div className="mt-10">
                            <ReviewFrame
                                hideToggleButton
                                title="Accepted Subscription Amount"
                                summary={
                                    getFormattedCurrency(
                                        subscription.acceptedSubscriptionAmount ||
                                            subscription.subscriptionAmount,
                                        subscription.shareClass?.currency,
                                        false
                                    )?.toString() || 'N/A'
                                }
                                summaryClassName="whitespace-pre-line"
                                expanded
                            />

                            <ReviewFrame
                                hideToggleButton
                                title={
                                    toCapitalizedWords(subscription.status) ||
                                    ''
                                }
                                summary={
                                    subscription.statusComment ||
                                    'No comment added '
                                }
                                summaryClassName="whitespace-pre-line"
                                expanded
                            />
                        </div>
                    )}
                </div>

                <div className="h-12 mb-24">
                    {subscription.status === SubscriptionStatusEnum.approved &&
                        hasPermissions([AuthPermissionEnum.raiseNotice]) && (
                            <SubscriptionNoticeManagement />
                        )}
                </div>
            </div>
        );
    else return <div />;
};

export const SubscriptionBreadcrumbs: FC = () => {
    const { t } = useTranslation();
    const { hasPermissions } = useAuthState();
    const { subscriptionId } = useParams();
    const { subscription } = useSubscription(subscriptionId);
    const { endInvestorProfiles } = useEndInvestorProfileState();

    const getInvestorProfile = useCallback((id?: string) =>
    endInvestorProfiles.find((a) => a._id === id), [endInvestorProfiles]) ;
    const endInvestorProfileId = subscription.endInvestorProfileId;
    const endInvestorProfile = getInvestorProfile(endInvestorProfileId);

    const items = [
        {
            displayText: t('navbar.subscriptions'),
            navigationUrl: `/subscriptions`,
        },
        {
            displayText: getEndInvestorName(endInvestorProfile)
            ,
            navigationUrl: hasPermissions(AuthPermissionEnum.viewDashboard) ? `/clients/${endInvestorProfileId}` : '' ,
        },
    ];

    return <BasicBreadcrumbs items={items} />;
};



export default mainLayoutWithBreadcrumbsConfig(
    Subscription,
    undefined,
    <SubscriptionBreadcrumbs />
);
