import { TFunction } from "react-i18next";

interface ProductStep {
    endPathUrl: string;
    stepName: string;
    showStep: boolean;
}

export const productSteps = (t: TFunction<'translation', undefined>): ProductStep[] => {
    return [
        {
            endPathUrl: 'product-information',
            stepName: t('admin.product.product_information'),
            showStep: true,
        },
        {
            endPathUrl: 'liquidity-information',
            stepName: t('admin.product.liquidity_information'),
            showStep: true,
        },
        {
            endPathUrl: 'product-fees',
            stepName: t('admin.product.product_fees'),
            showStep: true,
        },
        {
            endPathUrl: 'product-description',
            stepName: t('admin.product.product_description'),
            showStep: true,
        },
        {
            endPathUrl: 'contact-information',
            stepName: t('product.contact_info'),
            showStep: true,
        },
        {
            endPathUrl: 'product-eligibility',
            stepName: t('admin.product.eligibility_jurisdiction'),
            showStep: true,
        },
        {
            endPathUrl: 'product-eligibility-exclusion',
            stepName: t('admin.product.eligibility_exclusion'),
            showStep: true,
        },
        {
            endPathUrl: 'shareclass-information',
            stepName: t('admin.product.shareclass_information'),
            showStep: true,
        },
        { endPathUrl: 'product-images', stepName: t('admin.product.images'), showStep: true },
        { endPathUrl: 'product-videos', stepName: t('product.videos'), showStep: true },
        { endPathUrl: 'product-documents', stepName: t('product.documents'), showStep: true },
        {
            endPathUrl: 'logo',
            stepName: t('admin.product.logo'),
            showStep: true,
        },
        {
            endPathUrl: 'visibility-settings',
            stepName: t('admin.product.visibility_setting'),
            showStep: true,
        },
        {
            endPathUrl: 'subscription-signing-documents-templates',
            stepName: t('admin.product.signing_documents'),
            showStep: true,
        },
    ];
};
