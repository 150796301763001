export enum ThirdPartyAccountEnum {
    pooledAccount = 'pooledAccount',
    segregatedAccount = 'segregatedAccount',
}

// need to update these to { [key in ThirdPartyAccountEnum]: string }
// to match the interface
export const ThirdPartyAccountLabels: {
    [key in ThirdPartyAccountEnum]: string;
} = {
    pooledAccount:
        'a Pooled Account (reflecting a pool of underlying clients  typically with a generic designation such as “Clients account” or with a designation that makes reference to a region, product or multiple specific customers)',
    segregatedAccount:
        'a Segregated Account (a specific account  for  a single underlying third party)',
};

export const ThirdPartyAccountEnumOptions = Object.entries(
    ThirdPartyAccountLabels
).map(([value, label]) => ({
    value,
    label,
}));

export enum SegregatedAccountDesignationEnum {
    designationOfTheAccountWillReferToTheUnderlyingClientName = 'designationOfTheAccountWillReferToTheUnderlyingClientName',
    designationOfTheAccountIsCoded = 'designationOfTheAccountIsCoded',
}

export const SegregatedAccountDesignationLabels: {
    [key in SegregatedAccountDesignationEnum]: string;
} = {
    designationOfTheAccountWillReferToTheUnderlyingClientName:
        'The designation of the account will refer to the underlying client name ',
    designationOfTheAccountIsCoded:
        'The designation of the  account is coded (the designation contains an internal reference, numbers or combination of letters which do not allow an external party to identify the underlying client)',
};

export const SegregatedAccountDesignationEnumOptions = Object.entries(
    SegregatedAccountDesignationLabels
).map(([value, label]) => ({
    value,
    label,
}));

export enum IntermediaryMifidCategorisationEnum {
    eligibleCounterparty = 'eligibleCounterparty',
    professionalClient = 'professionalClient',
    retailClient = 'retailClient',
}

export const IntermediaryMifidCategorisationLabels: {
    [key in IntermediaryMifidCategorisationEnum]: string;
} = {
    eligibleCounterparty: 'Eligible Counterparty',
    professionalClient: 'Professional Client',
    retailClient: 'Retail Client',
};

export const IntermediaryMifidCategorisationEnumOptions = Object.entries(
    IntermediaryMifidCategorisationLabels
).map(([value, label]) => ({
    value,
    label,
}));

export enum IntermediaryTypeOfBusinessEnum {
    advised = 'advised',
    executionOnly = 'executionOnly',
}
export const IntermediaryTypeOfBusinessLabels: {
    [key in IntermediaryTypeOfBusinessEnum]: string;
} = {
    advised: 'Advised',
    executionOnly: 'Execution Only',
};

export const IntermediaryTypeOfBusinessEnumOptions = Object.entries(
    IntermediaryTypeOfBusinessLabels
).map(([value, label]) => ({
    value,
    label,
}));

export enum DistributorOfFundEnum {
    globalDistributor = 'globalDistributor',
    distributor = 'distributor',
    subDistributor = 'subDistributor',
}

export const DistributorOfFundLabels: {
    [key in DistributorOfFundEnum]: string;
} = {
    globalDistributor: 'Global Distributor',
    distributor: 'Distributor',
    subDistributor: 'Sub-Distributor',
};

export const DistributorOfFundEnumOptions = Object.entries(
    DistributorOfFundLabels
).map(([value, label]) => ({
    value,
    label,
}));

export enum IntermediaryPooledAccountsEnum {
    rDRRules = 'rDRRules',
    rFARules = 'rFARules',
}

export const IntermediaryPooledAccountsLabels: {
    [key in IntermediaryPooledAccountsEnum]: string;
} = {
    rDRRules: 'UK Retail Business (RDR Rules)',
    rFARules: 'Jersey Retail Business (RFA Rules)',
};

export const IntermediaryPooledAccountsEnumOptions = Object.entries(
    IntermediaryPooledAccountsLabels
).map(([value, label]) => ({
    value,
    label,
}));

export interface TypeOfInvestment {
    isMadeOnBehalfOfThirdParty: boolean;
    thirdPartyAccountType?: ThirdPartyAccountEnum;
    segregatedAccountDesignationType?: SegregatedAccountDesignationEnum;
    mifidCategorisation?: IntermediaryMifidCategorisationEnum[];
    intermediaryTypeOfBusiness?: IntermediaryTypeOfBusinessEnum;
    distributorOfFundType?: DistributorOfFundEnum;
    intermediaryPooledAccounts?: IntermediaryPooledAccountsEnum[];
    accountDesignationName?: string;
}
