import { useFormikContext } from 'formik';

import { FormMultiselect, FormSelect } from '@components/Molecules';
import getClientRegistrationFormFields from '../ClientRegistrationFormFields';

import { useRegisterInvestorState } from '../RegisterInvestor.context';
import { BookingCentreResponse } from '@interfaces/Api';

import { useBookingCentres } from '@stores/BookingCentres/useBookingCentres';
import { useTranslation } from 'react-i18next';
import { EndInvestorType } from 'common';

const BusinessRelationshipsFormFields = {
    nomineeEndInvestorProfileId: {
        name: 'nomineeEndInvestorProfileId',
        label: 'Nominees',
        requiredErrorMsg: 'A Nominee selection is required',
    },
};

export const BookingCentreSelection = () => {
    const { t } = useTranslation();
    const { setFieldValue, values } = useFormikContext<any>();
    const { data } = useRegisterInvestorState();
    const { bookingCentres } = useBookingCentres(data.intermediaryId, data.adviserSystemUserId);

    const isMultiSelect =
        data.endInvestorType === EndInvestorType.nomineeEntity;

    const bookingCentreOptions = bookingCentres.map(
        (bookingCentre: BookingCentreResponse) => ({
            value: bookingCentre._id,
            label: bookingCentre.bookingCentreName,
        })
    );

    return (
        <div>
            <h2 className="mb-4 text-2xl">
                {t('client.registration.booking_centres.title')}
            </h2>
            {isMultiSelect ? (
                <FormMultiselect
                    id={
                        getClientRegistrationFormFields(t).bookingCentreIds.name
                    }
                    name={
                        getClientRegistrationFormFields(t).bookingCentreIds.name
                    }
                    label={
                        getClientRegistrationFormFields(t).bookingCentreIds
                            .label
                    }
                    options={bookingCentreOptions}
                    isFieldValuesArray
                />
            ) : (
                <FormSelect
                    name={
                        getClientRegistrationFormFields(t).bookingCentreIds.name
                    }
                    label={
                        getClientRegistrationFormFields(t).bookingCentreIds
                            .label
                    }
                    optionsData={bookingCentreOptions}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        if (setFieldValue) {
                            setFieldValue(
                                getClientRegistrationFormFields(t)
                                    .bookingCentreIds.name,
                                [e.target.value] || undefined
                            );
                            values.nomineeEndInvestorProfileId &&
                                setFieldValue(
                                    BusinessRelationshipsFormFields
                                        .nomineeEndInvestorProfileId.name,
                                    undefined
                                );
                        }
                    }}
                />
            )}
            <h6 className="text-xs -my-2">
                {t('client.registration.booking_centres_if_applicable')}
            </h6>
        </div>
    );
};
