import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

import { SystemUsersGet } from '@stores/SystemUsers/SystemUsers.services';
import { useEffect, useState } from 'react';

export const LoginAccountSummary = () => {
    const { t } = useTranslation();
    const { data: currentState } = useRegisterInvestorState();

    const [email, setEmail] = useState<string>('');

    const selectedSystemUser = currentState?.systemUsers?.[0];

    const loadSystemUser = async (id) => await SystemUsersGet(id);

    useEffect(() => {
        if (!selectedSystemUser?._id) return;

        loadSystemUser(selectedSystemUser._id).then((systemUser: any) => {
            setEmail(systemUser.email);
        });
    }, [selectedSystemUser]);

    return (
        <p>
            <span className="font-bold mr-2">
                {t('client.registration.summary.login_account')}:
            </span>
            {selectedSystemUser?.email ?? email}
        </p>
    );
};
