import { Salutation } from 'common';
import { FieldMetaProps, FormikValues, getIn } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

export const isRequiredField = (
    validationSchema: Yup.ObjectSchema<any, any, any, any>,
    name: string
) => {
    const describe = validationSchema.describe();
    console.log('[formik.helper.ts] describe:', describe);
    console.log('huh:', getIn(describe.fields, name));

    return !!getIn(describe.fields, name).tests.find(
        (test: { name: string; params: any }) => test.name === 'required'
    );
};

export const getRequiredLabel = (
    validationSchema: Yup.ObjectSchema<any, any, any, any>,
    name: string
) => {
    return isRequiredField(validationSchema, name) ? '*' : '';
};

export const convertFieldsToSchema = (
    fields: Record<string, any>[]
): Record<string, any> =>
    fields.reduce((acc: { [key: string]: Yup.AnySchema }, current) => {
        if (current.required) {
            acc[current.name] = Yup.string().trim().required(
                `${current.name} is required`
            );
        }
        return acc;
    }, {});

/**
 * Checks if the form fields meta.error contains the word 'required'. This is not a great way of doing this as we
 * can't access.
 *
 * @param meta
 * @returns
 */
export const isFormikFieldRequired = (meta: FieldMetaProps<string>) => {
    const rest = !!meta.error?.toLowerCase().includes('required');
    return rest;
};

export const trimStringInObject = (obj: Object) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key as string] = typeof value === 'string' ? value.trim() : value;
        return acc;
    }, {} as any);
};

export const transformSelectData = (options: string[]) =>
    options.map((option: string) => ({
        label: option,
        value: option,
    }));

export const getFormValuePath = (formValues: FormikValues, fileName: string) => {
    let currentPath = formValues;
    const paths = fileName.split('.');

    paths.forEach(path => currentPath = currentPath[path]);

    return currentPath;
}

export const getObjectPropertiesPaths = (obj: Object | string, parentPrefix = '') => {
    if (typeof obj !== 'object') {
        return parentPrefix;
    }

    return _.flatten(Object.keys(obj).map((prefix) => getObjectPropertiesPaths(obj[prefix], `${parentPrefix}${parentPrefix && '.'}${prefix}`)));
}

export const salutationOptions = Object.values(Salutation).map((salutation) => ({
    value: salutation,
    label: salutation,
}));
