import { useTranslation } from 'react-i18next';
import {
    DistributorOfFundLabels,
    IntermediaryMifidCategorisationLabels,
    IntermediaryPooledAccountsLabels,
    IntermediaryTypeOfBusinessLabels,
    SegregatedAccountDesignationLabels,
    ThirdPartyAccountLabels,
    TypeOfInvestment,
} from '@interfaces/Api/TypeOfInvestment';
import { getYesOrNo } from '@helpers/string.helper';
import { EconomicOriginOfMonies } from '@interfaces/Api/EconomicOriginOfMonies';
import { getCountryDisplayName } from '@helpers/isoCountries';

interface ReviewEconomicOriginOfMoniesProps {
    economicOriginOfMonies?: EconomicOriginOfMonies;
}

const ReviewEconomicOriginOfMonies = (
    props: ReviewEconomicOriginOfMoniesProps
) => {
    const { t } = useTranslation();
    const { economicOriginOfMonies } = props;
    const lines: { heading: string; value: string | undefined }[] = [
        {
            heading: t(
                'subscriptions.add.economic_origin_of_monies.third_party_origin_of_funds_type'
            ),
            value:
                economicOriginOfMonies.thirdPartyOriginOfFundsTypes
                    ?.map((a) => ThirdPartyAccountLabels[a])
                    .filter((a) => a)
                    .join(', ') || '',
        },
        {
            heading: t(
                'subscriptions.add.economic_origin_of_monies.third_party_origin_of_funds_type_other'
            ),
            value: economicOriginOfMonies.thirdPartyOriginOfFundsOtherDescription,
        },
        {
            heading: t(
                'subscriptions.add.economic_origin_of_monies.is_third_party_origin_of_funds'
            ),
            value: getYesOrNo(
                economicOriginOfMonies.isThirdPartyActingUnderDiscretionaryPortfolioManagement
            ),
        },
        {
            heading: t(
                'subscriptions.add.economic_origin_of_monies.main_country_code_of_origin_customer_base'
            ),
            value: getCountryDisplayName(
                economicOriginOfMonies.mainCountryCodeOfOriginCustomerBase
            ),
        },
        {
            heading: t(
                'subscriptions.add.economic_origin_of_monies.main_country_code_of_origin_of_funds_invested'
            ),
            value: getCountryDisplayName(
                economicOriginOfMonies.mainCountryCodeOfOriginOfFundsInvested
            ),
        },
    ].filter((a) => a.value);

    return (
        <div className="text-base leading-5">
            {lines.map((line, i) => (
                <div key={i}>
                    <strong className="pr-2">{line.heading}</strong>
                    {line.value}
                </div>
            ))}
        </div>
    );
};

export default ReviewEconomicOriginOfMonies;
