import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Icons } from '@components/Atoms';
import { dateShortFormatter } from '@helpers/Date.helper';

interface ClosingDateWarningProps {
    closingDate: Date;
}

export const ClosingDateWarning: FC<ClosingDateWarningProps> = ({
    closingDate,
}) => {
    const { t } = useTranslation();

    return (
        <div className="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span className="p-5 rounded-full bg-yellow-400">
                <Icons
                    name="ExclamationTriangleIcon"
                    size="large"
                    strokeWidth=".2"
                />
            </span>

            <p className="text-l font-medium text-slate-700 mt-3">
                {t('admin.product.closing_date_change_warning')}
            </p>
            <p className="text-l font-medium text-slate-700 mt-3">
                {t('admin.product.closing_date_change_to')}{' '}
                {dateShortFormatter(new Date(closingDate))}
            </p>
        </div>
    );
};
