import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
    RepresentationsAndWarranties,
    SubscriptionStatusEnum,
} from '@interfaces/Api';

import { FormCheckbox, FormInputBox } from '@components/Molecules';

import SubscriptionsFormikSubmitBtns from '@containers/Subscriptions/SubscriptionsFormikSubmitBtns';

import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';
import DownloadManualDraftSubscriptionAgreement from './DownloadManualDraftSubscriptionAgreement';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useMemo } from 'react';
import { useSubscriptionsStore } from '@stores/Subscriptions/Subscriptions.store';
import { useSubscriptionsActions } from '@stores/Subscriptions';
import { notifySuccess } from '@helpers/toastrHelper';

const RepresentationsAndWarrantiesContainer = () => {
    const { t } = useTranslation();
    const { configuration } = useConfigurationState();
    const { goToNextStep } = useSubscriptionPageContext();

    const { subscription } = useSubscriptionsStore();
    const { updateRepresentationsAndWarranties } = useSubscriptionsActions();

    const areInputsDisabled =
        subscription?.status !== SubscriptionStatusEnum.inProgress;

    const validation = Yup.object({
        inPositionToDiscloseTo3rdParties: Yup.bool(),
        subjectToLawsAndRegulations: Yup.boolean(),
        referenceToLawsAndRegulations: Yup.string().when(
            'subjectToLawsAndRegulations',
            {
                is: true,
                then: Yup.string()
                    .trim()
                    .required(
                        t(
                            'subscriptions.add.representations_and_warranties.reference_to_laws_and_regulations.required_validation_message'
                        )
                    ),
            }
        ),
    });

    const initialVals: RepresentationsAndWarranties = {
        inPositionToDiscloseTo3rdParties:
            subscription.representationsAndWarranties
                ?.inPositionToDiscloseTo3rdParties || false,
        subjectToLawsAndRegulations:
            subscription.representationsAndWarranties
                ?.subjectToLawsAndRegulations || false,
        referenceToLawsAndRegulations:
            subscription.representationsAndWarranties
                ?.referenceToLawsAndRegulations || '',
    };

    const onSubmit = async (values: RepresentationsAndWarranties) => {
        await updateRepresentationsAndWarranties({
            subscriptionId: subscription._id,
            body: values,
        }).then(() => {
            notifySuccess(
                t('subscriptions.add.submission_was_successful_notification')
            );
        });

        goToNextStep && goToNextStep();
    };

    return (
        <div className="w-full overflow-y-auto">
            <div className="flex flex-col md:flex-row justify-between items-start w-full md:w-11/12 xl:w-4/5">
                <div>
                    <h1 className="text-3xl text-logo-blue">
                        {t(
                            'subscriptions.add.representations_and_warranties.title'
                        )}
                    </h1>
                    <p className="text-base py-3">
                        {t(
                            'subscriptions.add.representations_and_warranties.instruction'
                        )}
                    </p>
                </div>
                {configuration?.preferences.feature?.subscriptionProcess
                    ?.allowDownloadingPartialDocuments && (
                    <DownloadManualDraftSubscriptionAgreement
                        subscriptionId={subscription._id}
                        subscriptionStatus={subscription.status}
                    />
                )}
            </div>

            <div className="w-full md:w-11/12 xl:w-4/5">
                <Formik
                    initialValues={initialVals}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {(formikProps) => (
                        <Form>
                            <FormCheckbox
                                actionLabel={t(
                                    'subscriptions.add.representations_and_warranties.disclose_to_third_parties'
                                )}
                                name="inPositionToDiscloseTo3rdParties"
                                disabled={
                                    areInputsDisabled ||
                                    formikProps.values
                                        .subjectToLawsAndRegulations
                                }
                            />
                            <p className="text-base py-3">
                                {t(
                                    'subscriptions.add.representations_and_warranties.or'
                                )}
                            </p>
                            <FormCheckbox
                                actionLabel={t(
                                    'subscriptions.add.representations_and_warranties.subject_to_laws_and_regulations'
                                )}
                                name="subjectToLawsAndRegulations"
                                disabled={
                                    areInputsDisabled ||
                                    formikProps.values
                                        .inPositionToDiscloseTo3rdParties
                                }
                            />
                            {formikProps.values.subjectToLawsAndRegulations && (
                                <FormInputBox
                                    label={t(
                                        'subscriptions.add.representations_and_warranties.reference_to_laws_and_regulations.instruction'
                                    )}
                                    name="referenceToLawsAndRegulations"
                                    disabled={areInputsDisabled}
                                />
                            )}
                            <SubscriptionsFormikSubmitBtns
                                isSubscriptionReadOnly={
                                    subscription.status !==
                                    SubscriptionStatusEnum.inProgress
                                }
                                disabled={
                                    formikProps.isSubmitting ||
                                    !formikProps.isValid
                                }
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default RepresentationsAndWarrantiesContainer;
