import { Document, ProductResponse } from '@interfaces/Api';
import { saveAs } from 'file-saver';

/**
 * Format bytes as human-readable text. Slight modification of this solution: https://stackoverflow.com/a/14919494
 *
 * @param bytes Number of bytes.

 * @param decimalPoints Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, decimalPoints = 1) => {
    const thresh = 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** decimalPoints;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(decimalPoints) + ' ' + units[u];
};

export const downloadFileOnTheClient = async (response: any) => {
    const blob = await response.blob();
    const fileName = response.headers
        .get('Content-Disposition')
        ?.split('filename=')[1]
        .trim()
        .replaceAll('"', '')!;

    saveAs(blob, fileName);
};

export const exportJsonData = async (fileName: string, jsonData: ProductResponse) => {
    const copyData: ProductResponse = { ...jsonData };
    const filename = `${fileName}.json`;
    ['images', 'documents', 'logo'].map((key) => {
        (copyData as any)[key]?.forEach((file: Document) => {
            file.url = `${file.url}`
        })
    });

    const jsonStr = JSON.stringify(copyData);

    const element = document.createElement('a');
    element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
};

export const downloadFile = (fileUrl: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileUrl;
    link.dispatchEvent(new MouseEvent('click'));
}
