import { BaseUrl } from '@helpers/Environment';
import { JobResponse } from '@interfaces/Api/JobResponse';
import { Http } from '@libs/http';

const SUBSCRIPTION_SUBMISSION_JOB_URL = `${BaseUrl}/jobs/subscription-submission`;

export const RunSubscriptionSubmissionJob = async () => {
    const result = await Http.put<JobResponse>(
        SUBSCRIPTION_SUBMISSION_JOB_URL,
    );
    return result;
};
