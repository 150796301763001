import { FC, useState } from 'react';
import { Button, Checkbox } from '@components/Atoms';
import { useTranslation } from 'react-i18next';

interface CoolingOffWarningProps {
    onAbort(): void;
    onAccept(): void;
}

export const RejectionWarning: FC<CoolingOffWarningProps> = ({
    onAbort,
    onAccept,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                {t(
                    'subscriptions.completeness_checks.internal_review.reject_modal_body'
                )}
            </div>

            <div className="mt-8 flex justify-between">
                <Button
                    buttonType="secondary"
                    onClick={onAbort}
                    label={t('ui.controls.cancel')}
                />

                <Button label={t('ui.controls.confirm')} onClick={onAccept} />
            </div>
        </>
    );
};
