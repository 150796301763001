import { TFunction } from 'react-i18next';

const getClientRegistrationFormFields = (
    t: TFunction<'translation', undefined>
) => ({
    adviserSystemUserId: {
        name: 'adviserSystemUserId' as const,
        label: `${t('client.registration.advisor.client_adviser')} *`,
    },
    intermediaryId: {
        name: 'intermediaryId' as const,
        label: `${t('client.registration.summary.intermediary')} *`,
    },
    bookingCentreIds: {
        name: 'bookingCentreIds' as const,
        label: `${t('client.registration.booking_centres.title')} *`,
    },
    onboardingJurisdictionCountryCode: {
        name: 'onboardingJurisdictionCountryCode',
        label: `${t('client.registration.summary.onboarding_jurisdiction')} *`,
    },
    departOfBirth: {
        name: 'departOfBirth',
        label: t('client.registration.summary.depart_of_birth'),
    },
    clientType: {
        name: 'clientType',
        label: `${t('client.registration.client_type.title')} *`,
    },
    serviceLevelType: {
        name: 'serviceLevelType' as const,
        label: t('client.registration.service_level_type.title'),
    },
    subscriptionProcessType: {
        name: 'subscriptionProcessType',
        label: 'Subscription Process Type',
    },
    isKycCompliant: {
        name: 'isKycCompliant',
        label: 'Is KYC Compliant?',
    },
    kycDocument: {
        fileName: {
            name: 'fileName',
            label: 'KYC Document',
        },
        url: {
            name: 'url',
            label: 'Document URL',
        },
    },
    salutation: {
        name: 'salutation',
        label: `${t('client.registration.personal_details.salutation')} *`,
        placeHolder: `${t(
            'client.registration.personal_details.salutation_placeholder'
        )} *`,
    },
    gender: {
        name: 'gender',
        label: `${t('client.invite.gender')} *`,
        placeHolder: `${t('ui.controls.please_select')} *`,
    },
    maritalStatus: {
        name: 'maritalStatus',
        label: `${t('client.invite.marital_status')} *`,
        placeHolder: `${t('ui.controls.please_select')} *`,
    },
    additionalContactInformation: {
        name: 'additionalContactInformation',
        label: `${t('client.invite.additional_contact_information')}`,
        placeHolder: `${t(
            'client.invite.additional_contact_information_placeholder'
        )}`,
    },
    firstName: {
        name: 'firstName',
        label: `${t(
            'client.registration.personal_details.legal_first_name'
        )} *`,
    },
    lastName: {
        name: 'lastName',
        label: `${t('client.registration.personal_details.legal_last_name')} *`,
    },
    dateOfBirth: {
        name: 'dateOfBirth',
        label: `${t('client.registration.personal_details.date_of_birth')} *`,
    },
    townOfBirth: {
        name: 'townOfBirth',
        label: `${t('client.registration.personal_details.town_of_birth')} *`,
    },
    occupation: {
        name: 'occupation',
        label: `${t('client.registration.personal_details.occupation')} *`,
    },
    countryCodeOfBirth: {
        name: 'countryCodeOfBirth',
        label: `${t(
            'client.registration.personal_details.country_of_birth'
        )} *`,
    },
    nationalityCountryCode: {
        name: 'nationalityCountryCode',
        label: t('client.registration.nationalities.country'),
    },
    emailAddress: {
        name: 'emailAddress',
        label: `${t('client.registration.contact_details.email_address')}  *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.email_address.required_validation_message'
        ),
        invalidErrorMsg: t(
            'client.registration.contact_details.email_address.invalid_validation_message'
        ),
    },
    phoneDiallingCode: {
        name: 'phoneDiallingCode',
        label: `${t('client.registration.contact_details.country_code')} *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.country_code.required_validation_message'
        ),
        invalidErrorMsg: t(
            'client.registration.contact_details.invalid_phone_dialling_code'
        ),
        matchErrorMsg: t(
            'client.registration.contact_details.match_phone_dialling_code'
        ),
    },
    phoneNumber: {
        name: 'phoneNumber',
        label: `${t('client.registration.contact_details.phone_number')} *`,
        requiredErrorMsg: t(
            'client.registration.contact_details.phone_number.required_validation_message'
        ),
    },
    endInvestorType: {
        name: 'endInvestorType' as const,
        label: 'Investor Type',
    },
    companyName: {
        name: 'companyName',
        label: `${t('client.registration.business_details.company_name')} *`,
    },
    registrationNumber: {
        name: 'registrationNumber',
        label: `${t(
            'client.registration.business_details.registration_number'
        )} *`,
    },
    subscribersLegalForm: {
        name: 'subscribersLegalForm',
        label: `${t(
            'client.registration.business_details.subscribers_legal_form'
        )} *`,
    },
    subscribersRegister: {
        name: 'subscribersRegister',
        label: `${t(
            'client.registration.business_details.subscribers_register_name'
        )} *`,
    },
    subscriberParentListingAuthority: {
        name: 'subscriberParentListingAuthority',
        label: `${t(
            'client.registration.business_details.listing_authority'
        )} *`,
    },
    subscribersRegulator: {
        name: 'subscribersRegulator',
        label: `${t(
            'client.registration.business_details.subscribers_regulator_name'
        )} *`,
    },
    corporateType: {
        name: 'corporateType',
        label: t('client.registration.business_details.corporate_type'),
    },
    otherCorporateType: {
        name: 'otherCorporateType',
        label: 'Please Specify Other Corporate Type',
    },
    parentCompany: {
        name: 'parentCompany',
        label: t('client.registration.business_details.parent_company'),
    },
    dateOfIncorporation: {
        name: 'dateOfIncorporation',
        label: t('client.registration.business_details.date_of_incorporation') + '*',
    },
    countryCodeOfIncorporation: {
        name: 'countryCodeOfIncorporation',
        label: t(
            'client.registration.business_details.country_of_incorporation'
        ) + '*',
    },
    principalPlaceOfBusinessCountryCode: {
        name: 'principalPlaceOfBusinessCountryCode',
        label: t(
            'client.registration.business_details.principal_place_of_business'
        ) + '*',
    },
    areaOfBusinessActivityType: {
        name: 'areaOfBusinessActivityType',
        label: t('client.registration.business_details.area_of_activity') + '*',
    },
    isListedOnStockExchange: {
        name: 'isListedOnStockExchange',
        label: t(
            'client.registration.business_details.is_listed_on_stock_exchange'
        ),
    },
    isEntityWithOwnLegalPersonality: {
        name: 'isEntityWithOwnLegalPersonality',
        label: t(
            'client.registration.business_details.is_own_legal_personality'
        ),
    },
    listedOnStockExchangeListingCode: {
        name: 'listedOnStockExchangeListingCode',
        label: t('client.registration.business_details.listing_code'),
    },
    isRegulatedEntity: {
        name: 'isRegulatedEntity',
        label: t('client.registration.business_details.is_regulated_entity'),
    },
    licenseNumber: {
        name: 'licenseNumber',
        label: t('client.registration.business_details.license_number'),
    },
    regulatorWebsiteAddress: {
        name: 'regulatorWebsiteAddress',
        label: t(
            'client.registration.business_details.regulator_website_address'
        ),
    },
    addresses: {
        houseName: {
            name: 'houseName',
            label: `${t('client.registration.addresses.house_name_number')} *`,
        },
        residentialLine1: {
            name: 'residentialLine1',
            label: `${t('client.registration.addresses.line1')} *`,
        },
        residentialLine2: {
            name: 'residentialLine2',
            label: t('client.registration.addresses.line2'),
        },
        city: {
            name: 'city',
            label: `${t('client.registration.addresses.city')} *`,
        },
        postCode: {
            name: 'postCode',
            label: `${t('client.registration.addresses.postcode')} *`,
        },
        countryCode: {
            name: 'countryCode',
            label: `${t('client.registration.addresses.country')} *`,
        },
    },
    bankAccounts: {
        bankName: {
            name: 'bankName',
            label: `${t(
                'client.registration.bank_accounts.account_nickname'
            )} *`,
        },
        bankAddress: {
            name: 'bankAddress',
            label: `${t('client.registration.bank_accounts.account_address')} *`,
        },
        countryCurrencyCode: {
            name: 'countryCurrencyCode',
            label: `${t('client.registration.bank_accounts.currency')} *`,
        },
        countryCodeOfAccount: {
            name: 'countryCodeOfAccount',
            label: `${t(
                'client.registration.bank_accounts.country_of_bank_account'
            )} *`,
        },
        IBAN: {
            name: 'IBAN',
            label: `${t('client.registration.bank_accounts.iban')} *`,
        },
        accountNumber: {
            name: 'accountNumber',
            label: `${t('client.registration.bank_accounts.account_number')} *`,
        },
        accountCode: {
            name: 'accountCode',
            label: t('client.registration.bank_accounts.account_code') + '*',
        },
        accountHolderName: {
            name: 'accountHolderName',
            label: t('subscriptions.add.subscription.review_subscription.accountHolderName') + '*',
        },
    },
    taxInformations: {
        countryCode: {
            name: 'countryCode',
            label: 'Taxation Country *',
        },
        taxId: {
            name: 'taxId',
            label: 'Tax ID',
        },
    },
    loginAccount: {
        name: 'loginAccount',
        label: t('client.registration.login_account.title'),
        id: {
            name: 'systemUsers[0]._id',
            label: t('client.registration.login_account.title'),
        },
        firstName: {
            name: 'systemUsers[0].firstName',
            label: `${t('client.registration.login_account.legal_first_name')}`,
        },
        surname: {
            name: 'systemUsers[0].surname',
            label: `${t('client.registration.login_account.legal_last_name')}`,
        },
        email: {
            name: 'systemUsers[0].email',
            label: `${t('client.registration.login_account.email_address')} *`,
        },
    },
});

export default getClientRegistrationFormFields;
