import { DataReview, FormSelect } from '@components/Molecules';
import { AddBankAccount } from '@containers/Subscriptions/AddBankAccount';
import { SubscriptionOutletContext } from '@contexts/OutletContext';
import { isoCountries } from '@helpers/isoCountries';
import { isoCurrencies } from '@helpers/isoCurrencies';
import { SubscriptionStatusEnum } from '@interfaces/Api';
import { BankAccount } from 'common';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

interface IBankSelection {
    nameSpace?: string;
    bankAccounts: BankAccount[];
    addBankAccount: (bankAccount: BankAccount) => void;
    displayReview?: boolean;
    displayBankAccountSelection?: boolean;
}

const DataReviewItem = ({ label, value }) => (
    <div className="flex flex-row mb-1">
        <p className="w-1/2 text-sm font-bold">{label}</p>
        <p className="w-1/2 text-sm capitalize">{value}</p>
    </div>
);

export const BankSelection: FC<IBankSelection> = ({
    bankAccounts,
    nameSpace,
    addBankAccount,
    displayReview = true,
    displayBankAccountSelection = true,
}) => {
    const { setFieldValue, values } = useFormikContext();
    const { data: subscription } =
        useOutletContext<SubscriptionOutletContext>();
    const { t } = useTranslation();

    const bankNameField = nameSpace
        ? `${nameSpace}bankAccountName`
        : 'bankAccountName';

    const bankAccount = bankAccounts?.find(
        (bAcc) => bAcc.bankName === values[bankNameField]
    );

    const [selectedBankAccount, setSelectedBankAccount] = useState<
        BankAccount | undefined
    >(
        bankAccount || {
            bankName: '',
            bankAddress: '',
            accountHolderName: '',
            accountCode: '',
            IBAN: '',
            countryCodeOfAccount: '',
            countryCurrencyCode: '',
            accountNumber: '',
        }
    );

    const handleAddBankAccount = (bankAccount: BankAccount) => {
        addBankAccount(bankAccount);
        setSelectedBankAccount(bankAccount);
        setFieldValue(bankNameField, bankAccount.bankName);
    };

    useEffect(() => {
        if (bankAccount?.bankName) {
            setSelectedBankAccount(bankAccount);
            setFieldValue(bankNameField, bankAccount.bankName);
        }
    }, [bankAccount, bankNameField, setFieldValue]);

    const handleBankAccountSelection = (e) => {
        const findBankAccount = (bankName: string) =>
            bankAccounts.find((bAcc) => bAcc.bankName === bankName);
        const selectedBankAccount = findBankAccount(e.target.value);

        setSelectedBankAccount(selectedBankAccount);
        setFieldValue(bankNameField, e.target.value);
    };

    const areInputsDisabled = subscription?.status
        ? subscription?.status !== SubscriptionStatusEnum.inProgress
        : false;

    return (
        <div>
            <div>
                <FormSelect
                    label={t(
                        'subscriptions.add.banking_preferences.select_bank_account_title'
                    )}
                    name={bankNameField}
                    optionsData={bankAccounts?.map((bAcc) => ({
                        label: bAcc.bankName,
                        value: bAcc.bankName,
                    }))}
                    onChange={handleBankAccountSelection}
                    disabled={areInputsDisabled}
                />
                {displayBankAccountSelection && (
                    <div className="flex flex-row-reverse w-full pb-2">
                        <AddBankAccount
                            addBankAccount={handleAddBankAccount}
                            disabled={areInputsDisabled}
                        />
                    </div>
                )}
            </div>

            {displayReview && values[bankNameField] && (
                <DataReview bgColor="bg-gold-300">
                    <div className="pb-2 mr-2">
                        <p className="mb-1 text-sm">
                            {t(
                                'subscriptions.add.banking_preferences.bank_account_details'
                            )}
                        </p>
                        {selectedBankAccount?.bankName ? (
                            <>
                                <DataReviewItem
                                    label={t(
                                        'subscriptions.add.banking_preferences.bank_account_name'
                                    )}
                                    value={selectedBankAccount?.bankName}
                                />

                                <DataReviewItem
                                    label={t(
                                        'subscriptions.add.banking_preferences.bank_account_address'
                                    )}
                                    value={selectedBankAccount?.bankAddress}
                                />
                                <DataReviewItem
                                    label={t(
                                        'subscriptions.add.banking_preferences.bank_account_currency_code'
                                    )}
                                    value={
                                        selectedBankAccount?.countryCurrencyCode
                                            ? isoCurrencies[
                                                  selectedBankAccount
                                                      .countryCurrencyCode
                                              ]
                                            : ''
                                    }
                                />

                                <DataReviewItem
                                    label={t(
                                        'subscriptions.add.banking_preferences.bank_account_country_code'
                                    )}
                                    value={
                                        selectedBankAccount?.countryCodeOfAccount
                                            ? isoCountries[
                                                  selectedBankAccount
                                                      .countryCodeOfAccount
                                              ]
                                            : ''
                                    }
                                />

                                <DataReviewItem
                                    label={t(
                                        'subscriptions.add.banking_preferences.bank_account_iban'
                                    )}
                                    value={selectedBankAccount?.IBAN}
                                />

                                <DataReviewItem
                                    label={t(
                                        'client.registration.bank_accounts.account_number'
                                    )}
                                    value={selectedBankAccount?.accountNumber}
                                />

                                {selectedBankAccount?.accountHolderName && (
                                    <DataReviewItem
                                        label={t(
                                            'subscriptions.add.banking_preferences.account_holder_label'
                                        )}
                                        value={
                                            selectedBankAccount?.accountHolderName
                                        }
                                    />
                                )}

                                {selectedBankAccount?.accountCode && (
                                    <DataReviewItem
                                        label={t(
                                            'subscriptions.add.banking_preferences.account_code_label'
                                        )}
                                        value={selectedBankAccount?.accountCode}
                                    />
                                )}
                            </>
                        ) : (
                            <p>Please select a Bank Name.</p>
                        )}
                    </div>
                </DataReview>
            )}
        </div>
    );
};
