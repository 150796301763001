import { useCallback, useEffect, useState, FC, useMemo } from 'react';
import { Pagination } from '@components/Organisms';
import { useSortableData } from '@hooks/useSortableData/useSortableData';
import { useDocumentTemplatesLibraryState } from '@contexts/DocumentTemplateLibrary';
import { useMountEffect } from '@hooks/useMountEffect';
import DocumentTemplateTable from '@pages/Admin/Containers/DocumentTemplateLibrary/components/DocumentTemplateTable';
import {
    DocumentTemplate,
    DocumentTemplateRow,
} from '@interfaces/Api/DocumentTemplate';

import { Link, useParams } from 'react-router-dom';
import { Button } from '@components/Atoms';
import { ModalService } from '@services/ModalService';
import { AlertBox } from '@components/Molecules';
import { SubscriptionSigningDocumentTypeEnum } from '@interfaces/Api/SigningDocumentTypeEnum';
import { useProductSubmit } from '@hooks/useProductSubmit';
import { TemplateSelections } from './TemplateSelections';
import { useProductsState } from '@stores/Products';
import { useTranslation } from 'react-i18next';

const SubscriptionSigningDocumentTemplates = () => {
    const { t } = useTranslation();
    const [itemsPerPage, setItemsPerPage] = useState<number | string>(50);
    const { documentTemplateList, getDocumentTemplates } =
        useDocumentTemplatesLibraryState();
    const [templates, setTemplates] = useState<DocumentTemplate[]>([]);

    const { productId } = useParams();
    const { products } = useProductsState();
    const product = products.filter((e) => e._id === productId)[0];

    const signingDocumentTemplates = useMemo(
        () =>
            documentTemplateList.filter(({ documentType }) =>
                Object.values(SubscriptionSigningDocumentTypeEnum).includes(
                    documentType as unknown as SubscriptionSigningDocumentTypeEnum
                )
            ),
        [documentTemplateList]
    );

    const signingDocumentTemplatesMap = useMemo(() => {
        return signingDocumentTemplates.reduce((acc, current) => {
            acc[current._id] = current;
            return acc;
        }, {});
    }, [signingDocumentTemplates]);

    useMountEffect(() => {
        getDocumentTemplates();
    });

    useEffect(() => {
        if (
            product?.subscriptionSigningDocumentTemplates &&
            documentTemplateList.length
        ) {
            setTemplates(
                documentTemplateList.filter((documentTemplateList) => {
                    return product.subscriptionSigningDocumentTemplates.includes(
                        documentTemplateList._id
                    );
                })
            );
        }
    }, [product, documentTemplateList]);

    const {
        tableData: templateDocuments,
        tableDataLength,
        requestSort,
        sortConfig,
        currentPageNumber,
        paginate,
        paginateBack,
        paginateFront,
    } = useSortableData({
        tableData: templates,
        itemsPerPage,
    });

    const pagination = (
        <Pagination
            rowsPerPage={itemsPerPage}
            itemsLength={tableDataLength}
            paginate={paginate}
            currentPage={currentPageNumber}
            paginateBack={paginateBack}
            paginateFront={paginateFront}
            onPageSizeChange={setItemsPerPage}
        />
    );

    const onSubmit = useProductSubmit(
        productId,
        undefined,
        t('admin.product.new_signing_documents_have_been_mapped')
    );

    const handleAddTemplate = useCallback(
        (documentIds) => {
            const subscriptionSigningDocumentTemplates =
                product?.subscriptionSigningDocumentTemplates || [];
            const newIdList = [...subscriptionSigningDocumentTemplates];
            const existDocTypes = subscriptionSigningDocumentTemplates.map(
                (documentId) =>
                    signingDocumentTemplatesMap[documentId].documentType
            );
            let idListChanged = false;
            const doctypeNeedToReplace = [];

            for (const documentId of documentIds) {
                if (newIdList.includes(documentId)) {
                    continue;
                }

                const docType =
                    signingDocumentTemplatesMap[documentId].documentType;

                if (existDocTypes.includes(docType)) {
                    const index = newIdList.findIndex(
                        (id) =>
                            signingDocumentTemplatesMap[id].documentType ===
                            docType
                    );

                    if (index !== -1) {
                        newIdList[index] = documentId;
                        doctypeNeedToReplace.push(docType);
                        idListChanged = true;
                    }
                } else {
                    newIdList.push(documentId);
                    idListChanged = true;
                }
            }

            if (idListChanged) {
                if (doctypeNeedToReplace.length) {
                    ModalService.getInstance()
                        .setTitle(t('admin.product.overwrite_documents'))
                        .setComponent(
                            <div>
                                <div className="font-bold text-red-500">
                                    {t('client.risk_warning.warning')}
                                </div>
                                {t('admin.product.doc_type_exists', {docType: doctypeNeedToReplace.join(',')})}
                            </div>
                        )
                        .setShowHeader(true)
                        .setShowFooter(true)
                        .setConfirmationLabel(t('client.registration.kyc_compliance.yes'))
                        .setConfirmCallback(() => {
                            onSubmit({
                                subscriptionSigningDocumentTemplates: newIdList,
                            });

                            ModalService.getInstance().hideModal();
                        })
                        .showModal();
                } else {
                    onSubmit({
                        subscriptionSigningDocumentTemplates: newIdList,
                    });
                }
            }
        },
        [
            onSubmit,
            product?.subscriptionSigningDocumentTemplates,
            signingDocumentTemplatesMap,
        ]
    );

    const handleDelete = useCallback(
        (document: DocumentTemplateRow) => {
            if (
                product.subscriptionSigningDocumentTemplates.includes(
                    document._id
                )
            ) {
                ModalService.getInstance()
                    .setShowHeader(true)
                    .setTitle(t('admin.product.delete_document_template'))
                    .setComponent(
                        <div>
                            <div className="font-bold text-red-500	">
                                {t('client.risk_warning.warning')}!
                            </div>
                            {t('admin.product.delete_document_template_confirmation')}
                        </div>
                    )
                    .setShowFooter(true)
                    .setConfirmationLabel(t('system_user.delete'))
                    .setConfirmCallback(() => {
                        onSubmit({
                            subscriptionSigningDocumentTemplates:
                                product.subscriptionSigningDocumentTemplates.filter(
                                    (id) => id !== document._id
                                ),
                        });

                        ModalService.getInstance().hideModal();
                    })
                    .showModal();
            }
        },
        [onSubmit, product?.subscriptionSigningDocumentTemplates]
    );

    const showModal = useCallback(() => {
        ModalService.getInstance()
            .setTitle(t('admin.product.add_signing_document'))
            .setShowHeader(true)
            .setWidth('w-auto')
            .setShowFooter(false)
            .setComponent(
                <TemplateSelections
                    templateDocuments={signingDocumentTemplates}
                    onAdd={handleAddTemplate}
                />
            )
            .showModal();
    }, [handleAddTemplate, signingDocumentTemplates]);

    return (
        <div className="w-full relative">
            <AlertBox
                message={
                    <div>
                        <p>
                            {t('admin.product.signing_document_alert')}
                        </p>
                        <div className="text-right mt-2 mb-1">
                            <Button
                                label={t('admin.product.map_a_document')}
                                type="button"
                                onClick={showModal}
                            />
                        </div>
                    </div>
                }
                alertType="info"
            />

            {pagination}
            <DocumentTemplateTable
                searchText={''}
                items={templateDocuments}
                requestSort={requestSort}
                columnSortConfig={sortConfig}
                setShowDocumentModal={() => {}}
                hideEdit
                onDeleteCallBack={handleDelete}
                showDocumentUploadModal={false}
            />
            {pagination}

            <div className="flex justify-between mt-10">
                <div className="text-sm md:text-base">
                    <Link to={`../${productId}/visibility-settings`}>
                        <Button
                            buttonType="secondary"
                            type="button"
                            label={t('ui.controls.back')}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionSigningDocumentTemplates;
