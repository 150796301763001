import {
    crsNonFinancialInstitutionTypeLabelOptions,
    crsActiveNonFinancialInstitutionTypeLabelOptions,
    fatcaNonFinancialInstitutionTypeLabelOptions,
} from '@interfaces/Api';
import { Trans, useTranslation } from 'react-i18next';
import { FormCheckbox, FormInputBox, FormSelect } from '@components/Molecules';
import { FatcaNonFinancialInstitutionTypeEnum } from 'common';
import { getTranslatedOptions } from './TaxDeclarations';
import { useEffect, useState } from 'react';

export const RenderNonFinancialFields = ({
    formikProps,
    areInputsDisabled,
}) => {
    const { t } = useTranslation();
    const [crsTypeOptions, setCrsTypeOptions] = useState([
        ...crsNonFinancialInstitutionTypeLabelOptions,
    ]);
    useEffect(() => {
        if (
            formikProps.values.entity?.fatca?.nonFinancialInstitutionType?.includes(
                'active'
            )
        ) {
            setCrsTypeOptions(crsActiveNonFinancialInstitutionTypeLabelOptions);
        } else if (
            formikProps.values.entity?.fatca?.nonFinancialInstitutionType?.includes(
                'passive'
            )
        ) {
            setCrsTypeOptions([
                {
                    value: 'passiveNonFinancialEntity',
                    label: 'Passive Non-Financial Entity (If this box is ticked, please complete a separate Individual Self-Certification Form for each of your Controlling Person(s) )',
                },
            ]);
            formikProps.setFieldValue(
                'entity.crs.nonFinancialInstitutionType',
                'passiveNonFinancialEntity'
            );
        } else {
            formikProps.setFieldValue(
                'entity.crs.nonFinancialInstitutionType',
                'notApplicable'
            );
            setCrsTypeOptions(crsNonFinancialInstitutionTypeLabelOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikProps.values.entity.fatca.nonFinancialInstitutionType]);
    return (
        <>
            <h3 className="my-2 mt-8 text-xl font-normal leading-8">
                Non Financial Institutions
            </h3>

            <FormSelect
                name="entity.fatca.nonFinancialInstitutionType"
                label={
                    t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_not_fi_select'
                    ) + '*'
                }
                optionsData={getTranslatedOptions(
                    t,
                    fatcaNonFinancialInstitutionTypeLabelOptions,
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_not_fi.select_option'
                )}
                disabled={areInputsDisabled}
            />
            {formikProps.values.entity?.fatca?.nonFinancialInstitutionType ===
                FatcaNonFinancialInstitutionTypeEnum.other && (
                <>
                    <FormInputBox
                        name="entity.fatca.otherNonFinancialInstitution"
                        label={
                            t(
                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.other_non_fi'
                            ) + '*'
                        }
                        disabled={areInputsDisabled}
                    />
                </>
            )}
            <FormSelect
                name="entity.crs.nonFinancialInstitutionType"
                label={
                    t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.non_fi_entity_select'
                    ) + '*'
                }
                optionsData={getTranslatedOptions(
                    t,
                    crsTypeOptions,
                    'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.non_fi_entity.select_option'
                )}
                disabled={areInputsDisabled}
            />

            <>
                <p className="py-1 text-base">
                    <Trans
                        i18nKey="subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.not_tax_resident_title"
                        defaults=""
                        components={{
                            bold: <strong />,
                        }}
                    />
                </p>
                {/* tickbox, this is actually probably not required */}
                <FormCheckbox
                    label={t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.not_tax_resident_checkbox.label'
                    )}
                    description={t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.not_tax_resident_checkbox.desc'
                    )}
                    name="entity.crs.isEntityNotATaxResident"
                    disabled={areInputsDisabled}
                />

                {formikProps.values.entity?.crs.isEntityNotATaxResident && (
                    <>
                        <p className="py-1 text-base">
                            {t(
                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.not_tax_resident_input.title'
                            )}
                        </p>

                        <FormInputBox
                            name="entity.crs.entityNotATaxResidentDescription"
                            label={t(
                                'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.entity_crs_classification.not_tax_resident_input.desc'
                            )}
                            disabled={areInputsDisabled}
                        />
                    </>
                )}
            </>
        </>
    );
};
