import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@components/Atoms';
import { notifySuccess, notifyError } from '@helpers/toastrHelper';
import {
    applicationSettingPut,
} from '@api/ApplicationSetting';
import { ApplicationSetting } from 'common';
import { FormInputBox } from '@components/Molecules';

import { useApplicationSetting } from '@hooks/useApplicationSetting';

export const ContactEmails = () => {
    const { applicationSetting } = useApplicationSetting(true);

    const handleSubmit = useCallback(
        async (setting: Partial<ApplicationSetting>) => {
            try {
                await applicationSettingPut(setting);
                notifySuccess('Contact emails updated successfully');
            } catch (_) {
                notifyError(
                    'There was an error updating the contact emails. Please try again.'
                );
            }
        },
        []
    );

    return (
        <div>
            <h1 className="text-logo-blue text-3xl font-bold mb-4">
                Contact Emails
            </h1>
            <div className="w-full">
                <Formik
                    initialValues={{
                        distributorContactEmail: applicationSetting?.distributorContactEmail,
                        techSupportContactEmail: applicationSetting?.techSupportContactEmail,
                        wealthSupportContactEmail: applicationSetting?.wealthSupportContactEmail,
                    }}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    <Form>
                        <FormInputBox
                            label="Distributor Contact Email"
                            name="distributorContactEmail"
                        />
                        <FormInputBox
                            label="Tech Support Contact Email"
                            name="techSupportContactEmail"
                        />
                        <FormInputBox
                            label="Wealth Support Contact Email"
                            name="wealthSupportContactEmail"
                        />
                        <div className="w-full flex justify-end pt-4 mb-2">
                            <Button type="submit" label="Update" />
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};
