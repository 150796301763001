import { Button, IconCloseDark, IconInfo, Loading } from '@components/Atoms';
import DocumentLinkRender from '@components/Atoms/FileRender/DocumentLinkRender';
import { Document } from '@interfaces/Api';
import { useFormikContext } from 'formik';
import React from 'react';

interface FileUploadLineProps {
    url?: string;
    fileName: string;
    fieldName: string;
    fileList?: Document[];
    label?: string;
    fileSize?: string;
    isLoading: boolean;
    onRemoveFileClick?: () => void;
    canEditLabel?: boolean;
    onEditFileClick?: () => void;
    disabled?: boolean;
}

export const FileUploadLine: React.FC<FileUploadLineProps> = (
    props: FileUploadLineProps
) => {
    const {
        url,
        fileName,
        fieldName,
        fileList,
        label,
        fileSize,
        isLoading,
        canEditLabel,
        onRemoveFileClick,
        onEditFileClick,
        disabled,
    } = props;

    const formikProps = useFormikContext();

    const handleFileRemove = ({
        fileList,
        fileUrl,
    }: {
        fileList: Document[];
        fileUrl: string;
    }) => {
        const documents = fileList || [];
        const fileRemoveIndex = documents.findIndex(
            (val: Document) => val.url == fileUrl
        );
        if (fileRemoveIndex !== -1) {
            documents?.splice(fileRemoveIndex, 1);
        }
        formikProps.setFieldValue(fieldName, documents);
    };

    return (
        <div className="text-sm w-full flex justify-between border-b py-1 mb-4">
            <div className="flex items-center">
                <Button
                    type="button"
                    onClick={() => {
                        if (onRemoveFileClick) {
                            onRemoveFileClick();
                        } else if (!isLoading && fileList && url) {
                            handleFileRemove({ fileList, fileUrl: url });
                        }
                    }}
                    disabled={disabled}
                >
                    <IconCloseDark width={12} height={12} />
                </Button>
                {canEditLabel && (
                    <Button
                        type="button"
                        onClick={() => {
                            onEditFileClick && onEditFileClick();
                        }}
                        className="pl-2"
                        disabled={disabled}
                    >
                        <IconInfo width={12} height={12} />
                    </Button>
                )}

                <div className="pl-3">
                    <DocumentLinkRender
                        href={url || ''}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {label ? `${label} - (${fileName})` : fileName}
                    </DocumentLinkRender>
                </div>
                {isLoading && (
                    <div className="ml-2">
                        <Loading />
                    </div>
                )}
            </div>
            {fileSize && <div>{fileSize}</div>}
        </div>
    );
};
