import { createContext, useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfigurationState } from './ConfigurationContext';
import { setLanguagePreference } from '@stores/SystemUsers/SystemUsers.services';
import { useAuthState } from './AuthContext';
import { set } from 'cypress/types/lodash';


export enum LanguageLocaleType {
    ja = 'ja',
    it = 'it',
    de = 'de',
    es = 'es',
    fr = 'fr',
    en = 'en',
    cimode = 'cimode',
}

export interface LanguageLocaleProps {
    selectedLanguage: LanguageLocaleType;
    saveSelectedLanguage: (language: LanguageLocaleType) => void;
}

const lanuageLocaleDefaultState: LanguageLocaleProps = {
    saveSelectedLanguage: () => {},
    selectedLanguage: LanguageLocaleType.en,
};

export const LanguageLocaleContext = createContext<LanguageLocaleProps>(
    lanuageLocaleDefaultState
);

export const useLanguageLocaleState = () => {
    return useContext(LanguageLocaleContext);
};

export const LanguageLocaleProvider: React.FC = ({
    children,
}: React.PropsWithChildren<{}>) => {
    const { i18n } = useTranslation();
    const { currentUser } = useAuthState();
    const { configuration } = useConfigurationState();

    const defaultLanguage = currentUser?.user
        ?.languagePreference as LanguageLocaleType;
    const [selectedLanguage, setSelectedLanguage] =
        useState<LanguageLocaleType>(defaultLanguage);

    useEffect(() => {
        if (currentUser?.user?.languagePreference !== undefined) {
            setSelectedLanguage(
                currentUser?.user?.languagePreference as LanguageLocaleType
            );
        }
        else{
            setSelectedLanguage(LanguageLocaleType.en);
        }
    }, [currentUser?.user?.languagePreference]);

    // useEffect(() => {
    //     if (window !== undefined) {
    //         const browserLocale = window.navigator.language.split('-')[0];
    //         if (
    //             Object.values(LanguageLocaleType).includes(
    //                 browserLocale as LanguageLocaleType
    //             )
    //         ) {
    //             i18n.changeLanguage(browserLocale as LanguageLocaleType);
    //             setSelectedLanguage(browserLocale as LanguageLocaleType);
    //         } else {
    //             i18n.changeLanguage(LanguageLocaleType.en);
    //             setSelectedLanguage(LanguageLocaleType.en);
    //         }
    //     }
    // }, [i18n]);

    useEffect(() => {
        if (
            configuration?.preferences?.feature.topMenu
                ?.enableLocaleBasedLanguages &&
            [
                LanguageLocaleType.en,
                LanguageLocaleType.de,
                LanguageLocaleType.it,
                LanguageLocaleType.es,
                LanguageLocaleType.fr,
                LanguageLocaleType.ja,
                LanguageLocaleType.cimode,
            ].includes(selectedLanguage)
        ) {
            i18n.changeLanguage(selectedLanguage);
        }
    }, [
        configuration?.preferences?.feature.topMenu?.enableLocaleBasedLanguages,
        i18n,
        selectedLanguage,
    ]);

    const saveSelectedLanguage = async (language: LanguageLocaleType) => {
        await setLanguagePreference(currentUser?.user?._id, language);
        setSelectedLanguage(language);
    };

    return (
        <LanguageLocaleContext.Provider
            value={{ selectedLanguage, saveSelectedLanguage }}
        >
            {children}
        </LanguageLocaleContext.Provider>
    );
};
