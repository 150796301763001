import { Button } from '@components/Atoms';
import { FieldArray } from 'formik';
import { t } from 'i18next';
import { FC, useCallback, useState } from 'react';
import { ISigningGroup } from 'common';
import { getFormValuePath } from '@helpers/formik.helper';
import { InlineFormFrame, FormInputBox, DataReview } from '@components/Molecules';
import { useTranslation } from 'react-i18next';

interface FormSigningGroupsProps {
    name?: string;
    disabled?: boolean;
}

export const FormSigningGroups: FC<FormSigningGroupsProps> = ({
    name = 'signingGroups',
    disabled = false,
}) => {
    const { t } = useTranslation();

    const [showSigningGroup, setShowSigningGroup] = useState<boolean>(false);
    const emptySigningGroup: ISigningGroup = {
        name: '',
        id: '',
    };

    const [editSigningGroupIndex, setEditSignGroupIndex] =
        useState<number>(-1);

    const toggleSigningGroup = useCallback((index?: number) => {
        setEditSignGroupIndex(index);
        setShowSigningGroup((bool) => !bool);
    }, []);

    const getActiveSigningGroupIndex = useCallback((signingGroups: ISigningGroup[]) => {
        if (editSigningGroupIndex !== undefined) {
            return editSigningGroupIndex;
        }

        return signingGroups.length - 1;
    }, [editSigningGroupIndex]);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => (
                <div>
                    {getFormValuePath(arrayHelpers.form.values, name)?.map(
                        (signGroup: any, i: number, arr: any) => (
                            <div key={i.toString()}>
                                {
                                    showSigningGroup && i === getActiveSigningGroupIndex(arr) ?
                                        (<InlineFormFrame
                                            primaryAction={t('product.save_signing_group')}
                                            onPrimaryAction={toggleSigningGroup}
                                            secondaryAction={t('ui.controls.remove')}
                                            onSecondaryAction={() => {
                                                arrayHelpers.remove(i);
                                                setEditSignGroupIndex(-1);
                                            }}
                                        >
                                            <FormInputBox
                                                name={`${name}[${i}].name`}
                                                label={t('product.signing_group_name')}
                                                placeholder={t('product.signing_group_name')}
                                                disabled={disabled}
                                            />
                                            <FormInputBox
                                                name={`${name}[${i}].id`}
                                                label={t('product.signing_group_id')}
                                                placeholder={t('product.signing_group_id')}
                                                disabled={disabled}
                                            />
                                        </InlineFormFrame>) :
                                        <DataReview
                                            onDelete={() => arrayHelpers.remove(i)}
                                            onEdit={toggleSigningGroup.bind(null, i)}
                                        >
                                            <p className="text-sm">
                                                {t('product.signing_group_name')}: {signGroup.name}
                                            </p>
                                            <p className="text-sm">
                                                {t('product.signing_group_id')}: {signGroup.id}
                                            </p>
                                        </DataReview>
                                }

                            </div>
                        )
                    )}

                    <Button
                        label={`+ ${t(
                            'product.add_signing_group'
                        )}`}
                        type="button"
                        buttonType="secondary"
                        className="w-full my-2"
                        disabled={disabled}
                        onClick={() => {
                            arrayHelpers.push(emptySigningGroup);
                            toggleSigningGroup();
                        }}
                    />
                </div>
            )}
        />
    );
};
