import { Icons } from '@components/Atoms';
import { ModalService } from '@services/ModalService';
import { FC, useCallback } from 'react';
import { t } from 'i18next';
import { useApplicationSetting } from '@hooks/useApplicationSetting';

export const HelpAndContact: FC = () => {
    const { applicationSetting } = useApplicationSetting(true);

    const clickHandler = useCallback(() => {
        ModalService.getInstance()
            .setShowHeader(true)
            .setShowFooter(true)
            .setTitle(t('support.title'))
            .setComponent(<div>
                <p className='mb-4'>
                    {t('support.paragraph1', {techSupportContactEmail: applicationSetting?.techSupportContactEmail})}
                </p>
                <p>
                    {t('support.paragraph2', {wealthSupportContactEmail: applicationSetting?.wealthSupportContactEmail})}
                </p>
            </div>)
            .setHideCancelBtn(true)
            .setConfirmationLabel('Close')
            .setConfirmCallback(() => {
                ModalService.getInstance().hideModal();
            })
            .showModal();
    }, [applicationSetting]);

    return <div onClick={clickHandler}>
        <Icons name='QuestionMarkCircleIcon' size='medium'/>
    </div>
} 