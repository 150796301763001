import { Button, Icons, Tooltip } from '@components/Atoms';
import { useProfiles } from '@hooks/useProfiles';
import { AuthPermissionEnum, BookingCentreResponse, IntermediaryResponse } from '@interfaces/Api';
import { useMemo, useState } from 'react';
import { LinkedProfileModal } from './LinkedProfileModal';
import { useAuthState } from '@contexts/AuthContext';

interface IProfileSelection {
    profileId?: string;
    bookingCentre?: BookingCentreResponse;
    intermediary?: IntermediaryResponse;
}

export const ProfileSelection = ({
    bookingCentre,
    profileId,
    intermediary
}: IProfileSelection) => {
    const { hasPermissions } = useAuthState();

    const { profiles } = useProfiles();
    const [isProfileSelectionVisible, toggleProfileSelection] = useState(false);
    const showButton = useMemo(() => {
        if (bookingCentre?.intermediaryId?.profileId) {
            return false;
        }

        return true;
    }, [bookingCentre]);

    if (bookingCentre && !hasPermissions([AuthPermissionEnum.updateBookingCentre])) {
        return null;
    }
    if (intermediary && !hasPermissions([AuthPermissionEnum.updateDistributor])) {
        return null;
    }

    const findProfileName = (profileId: string) => {
        const profile = profiles.find((p) => p._id === profileId);
        return profile?.name;
    };

    return (
        <>
            <div className="flex items-center">
                {profileId && (
                    <p className="mr-2">{findProfileName(profileId)}</p>
                )}

                {
                    showButton && <Tooltip tip={profileId ? 'Update profile' : 'Add a profile'}>
                        {profileId ? (
                            <Button
                                hasIconOnly
                                endIcon={<Icons name={'PencilIcon'} size="small" />}
                                onClick={() => toggleProfileSelection(true)}
                            />
                        ) : (
                            <Button
                                label="Add a profile"
                                onClick={() => toggleProfileSelection(true)}
                                size="small"
                            />
                        )}
                    </Tooltip>
                }
            </div>

            {isProfileSelectionVisible && (
                <LinkedProfileModal
                    profileId={profileId}
                    profiles={profiles}
                    isProfileSelectionVisible
                    toggleProfileSelection={toggleProfileSelection}
                    bookingCentre={bookingCentre}
                    intermediary={intermediary}
                />
            )}
        </>
    );
};
