
import { useTranslation } from 'react-i18next';
import { FormNationalities } from '@components/Organisms/FormNationalities';

export const NationalitiesSelection = () => {
    const { t } = useTranslation();

    return (
        <>
            <h3 className="my-2 mt-8 text-xl font-normal leading-8">
                {t('client.registration.nationalities.title')} *
            </h3>
            <p className="py-1 text-base">
                {t('client.registration.nationalities.instruction')}
            </p>

            <FormNationalities name={`nationalities`} />
        </>
    );
};
