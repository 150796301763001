import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthState } from '@contexts/AuthContext';

import { AuthPermissionEnum, SystemUserResponse } from '@interfaces/Api';

import { getEndInvestorName } from '@helpers/EndInvestorHelper';

import { BasicBreadcrumbs } from '@components/Atoms';
import { mainLayoutWithBreadcrumbsConfig } from '@components/Layout';
import { ActionsMenu, ReviewFrame, ToggleSwitch } from '@components/Molecules';

import ReviewClientAddressesDetails from './ReviewClientAddressesDetails';
import ReviewClientBankDetails from './ReviewClientBankDetails';
import ReviewClientBusinessDetails from './ReviewClientBusinessDetails';
import ReviewClientBusinessRelationshipDetails from './ReviewClientBusinessRelationshipDetails';
import ReviewClientContactDetails from './ReviewClientContactDetails';
import ReviewClientKYCDetails from './ReviewClientKYCDetails';
import ReviewClientPersonalDetails from './ReviewClientPersonalDetails';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { useEndInvestorProfile } from '@hooks/useEndInvestorProfile';
import { getSystemUserByEndInvestorProfileId } from '@stores/SystemUsers/SystemUsers.services';
import { ClientEnum } from 'common';
import ReviewDisclosures from './ReviewDisclosures';
import ReviewExternalReferences from './ReviewExternalReferences';
import EndInvestorNameHeadingComponent from './endInvestorHeadingComponent';

const ClientDetailsBreadCrumbs = () => {
    const { endInvestorProfile } = useEndInvestorProfile();
    const { hasPermissions } = useAuthState();

    return (
        <BasicBreadcrumbs
            items={[
                {
                    displayText: t('navbar.clients'),
                    navigationUrl: '/clients',
                },
                {
                    displayText: getEndInvestorName(endInvestorProfile),
                    navigationUrl: hasPermissions(
                        AuthPermissionEnum.viewDashboard
                    )
                        ? `/clients/${endInvestorProfile?._id}?section=Portfolio`
                        : '',
                },
                {
                    displayText: 'Account Details',
                    navigationUrl: '',
                },
            ]}
        />
    );
};

const ClientDetails: React.FC = () => {
    const { endInvestorProfile } = useEndInvestorProfile();
    const {
        configuration: { client },
        isClient,
    } = useConfigurationState();
    const { hasPermissions } = useAuthState();

    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    const navigate = useNavigate();

    const [linkedSystemUser, setLinkedSystemUser] =
        useState<SystemUserResponse>();

    useEffect(() => {
        if (!endInvestorProfile) return;

        const getSystemUser = async () =>
            await getSystemUserByEndInvestorProfileId(endInvestorProfile._id);

        getSystemUser().then((systemUser) => {
            setLinkedSystemUser(systemUser);
        });
    }, [endInvestorProfile]);

    return (
        <div className="mb-8">
            <div className="flex items-center justify-between">
                <EndInvestorNameHeadingComponent
                    endInvestorProfile={endInvestorProfile}
                />
                {endInvestorProfile?._id && (
                    <div className="flex my-2 justify-end">
                        <ActionsMenu
                            actionItems={[
                                {
                                    label: 'Investments',
                                    onClick: () =>
                                        navigate(
                                            `/clients/${endInvestorProfile?._id}?section=Portfolio`
                                        ),
                                    show: hasPermissions(
                                        AuthPermissionEnum.viewDashboard
                                    ),
                                },
                                {
                                    label: 'Edit',
                                    show: hasPermissions([
                                        AuthPermissionEnum.updateEndInvestorProfile,
                                    ]),
                                    onClick: () =>
                                        navigate(
                                            `/clients/edit-investor/${endInvestorProfile?._id}`
                                        ),
                                },
                            ].filter((item) => item.show)}
                        />
                    </div>
                )}
            </div>

            <div className="flex my-2 w-full justify-end">
                <ToggleSwitch
                    title={t('ui.controls.show_hide_all')}
                    checked={isExpanded}
                    onChange={(e) => setIsExpanded(!isExpanded)}
                />
            </div>
            <div className="my-2 w-full">
                <ReviewFrame
                    title="Personal Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientPersonalDetails data={endInvestorProfile} />
                </ReviewFrame>

                {endInvestorProfile?.endInvestorType !== 'individual' ? (
                    <ReviewFrame
                        title="Business Entity Details"
                        className="-my-4"
                        expanded={isExpanded}
                    >
                        <ReviewClientBusinessDetails
                            data={endInvestorProfile}
                        />
                    </ReviewFrame>
                ) : null}

                <ReviewFrame
                    title="Contact Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientContactDetails
                        data={endInvestorProfile}
                        linkedSystemUser={linkedSystemUser}
                    />
                </ReviewFrame>

                <ReviewFrame
                    title="Address Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientAddressesDetails data={endInvestorProfile} />
                </ReviewFrame>

                <ReviewFrame
                    title="Bank Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientBankDetails data={endInvestorProfile} />
                </ReviewFrame>

                <ReviewFrame
                    title="Business Relationship Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientBusinessRelationshipDetails
                        data={endInvestorProfile}
                    />
                </ReviewFrame>

                <ReviewFrame
                    title="KYC Details"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewClientKYCDetails data={endInvestorProfile} />
                </ReviewFrame>

                <ReviewFrame
                    title="External References"
                    className="-my-4"
                    expanded={isExpanded}
                >
                    <ReviewExternalReferences
                        endInvestorProfile={endInvestorProfile}
                        client={client}
                    />
                </ReviewFrame>

                {isClient(ClientEnum.HG) && (
                    <ReviewFrame
                        title="Disclosures"
                        className="-my-4"
                        expanded={isExpanded}
                    >
                        <ReviewDisclosures
                            endInvestorProfile={endInvestorProfile}
                        />
                    </ReviewFrame>
                )}
            </div>
        </div>
    );
};

export default mainLayoutWithBreadcrumbsConfig(
    ClientDetails,
    undefined,
    <ClientDetailsBreadCrumbs />
);
