import { applicationSettingGet } from '@api/ApplicationSetting';
import { errorToString } from '@helpers/error.helper';
import { notifyError } from '@helpers/toastrHelper';
import { ApplicationSetting } from 'common';
import { useEffect, useState } from 'react';

let cache: ApplicationSetting;

export const useApplicationSetting = (useCache = false) => {
    const [applicationSetting, setApplicationSetting] =
        useState<ApplicationSetting>();

    useEffect(() => {
        const getApplicationSetting = () => {
            applicationSettingGet().then((response) => {
                setApplicationSetting(response);
                cache = response;
            }).catch((e) => notifyError(errorToString(e)));
        };

        if (useCache && cache) {
            setApplicationSetting(cache);
        } else {
            getApplicationSetting();
        }
    }, []);

    return {
        applicationSetting
    }
};

