import SubscriptionsFormikSubmitBtns from '@containers/Subscriptions/SubscriptionsFormikSubmitBtns';
import {
    EndInvestorProfileResponse,
    SubscriptionStatusEnum,
    TaxCountry,
    TaxInformation,
} from '@interfaces/Api';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import * as Yup from 'yup';

import { notifyError, notifySuccess } from '@helpers/toastrHelper';

import { useConfigurationState } from '@contexts/ConfigurationContext';
import { SubscriptionOutletContext } from '@contexts/OutletContext';
import { useSubscriptionPageContext } from '@pages/Subscriptions/EditSubscription';
import DownloadManualDraftSubscriptionAgreement from './DownloadManualDraftSubscriptionAgreement';
import { RecursiveErrorDisplay } from './RecursiveErrorDisplay';
import { RenderIndividualTaxDeclarations } from './RenderIndividualTaxDeclarations';
import { RenderEntityTaxDeclarations } from './RenderEntityTaxDeclarations';
import {
    CrsFinancialInstitutionTypeEnum,
    ExemptBeneficialOwnerTypes,
    FatcaFinancialInstitutionTypeEnum,
    FatcaNonFinancialInstitutionTypeEnum,
    NoGIINReasonTypeEnum,
    NoGIINSponsorType,
} from 'common';
import { CrsNonFinancialInstitutionTypeEnum } from '@interfaces/Api/TaxInformation';
import { useState } from 'react';

export interface TaxCountriesArrayFormProps {
    fieldArrayName: string;
    taxCountries?: TaxCountry[];
    disabled?: boolean;
    validationErrors?: any;
}
export const getTranslatedOptions = (t, options: any, key: string) =>
    options.map((o) => ({
        value: o.value,
        label: t(
            // @ts-ignore
            `${key}.${o.value}`
        ),
    }));

const TaxDeclarations = () => {
    const { t } = useTranslation();
    const { configuration } = useConfigurationState();
    const { data, updateTaxInformation } =
        useOutletContext<SubscriptionOutletContext>();
    const { goToNextStep, endInvestorProfile } = useSubscriptionPageContext();
    const areInputsDisabled = data?.status
        ? data?.status !== SubscriptionStatusEnum.inProgress
        : false;
    const isSubscription = data?.status;
    const navigate = useNavigate();
    const [customErrors, setCustomErrors] = useState({});
    const initialVals: TaxInformation =
        endInvestorProfile?.endInvestorType ==
        EndInvestorProfileResponse.EndInvestorType.individual
            ? {
                  individual: {
                      taxCountries: [],
                      ...data.taxInformation?.individual,
                      nonUsPersonConfirmation: true,
                  },
              }
            : {
                  entity: {
                      taxCountries: [],
                      fatca: {
                          isFinancialInstitution: false,
                      },
                      crs: {},
                      controllingPersons: [],
                      ...data.taxInformation?.entity,
                  },
              };
    const controllingPersonSchema = Yup.object().shape({
        fullName: Yup.string().required('Full name is required'),
        townOfBirth: Yup.string().required(
            t(
                'subscriptions.add.beneficial_owners.place_of_birth.required_validation_message'
            )
        ), // Not required
        countryCodeOfBirth: Yup.string().required(
            t(
                'client.registration.personal_details.nationality.country_code.required_validation_message'
            )
        ),
        dateOfBirth: Yup.date().required('Date of birth is required'),
        address: Yup.object().shape({
            houseName: Yup.string().required('House name is required'),
            residentialLine1: Yup.string().required(
                'Address line 1 is required'
            ),
            residentialLine2: Yup.string(), // Not required
            city: Yup.string().required('City is required'),
            postCode: Yup.string().required('Postal code is required'),
            countryCode: Yup.string().required(
                t(
                    'client.registration.personal_details.nationality.country_code.required_validation_message'
                )
            ),
        }),
        taxResidencies: Yup.array(
            Yup.object({
                countryCode: Yup.string()
                    .trim()
                    .required(
                        t(
                            'client.registration.personal_details.nationality.country_code.required_validation_message'
                        )
                    ),
                taxId: Yup.string(),
                reasonForNotHavingTaxId: Yup.string(),
            }).test(
                'taxId-or-reasonForNotHavingTaxId',
                t(
                    'subscriptions.add.tax_id_reason.required_validation_message'
                ),
                function (item) {
                    return !!item.taxId || !!item.reasonForNotHavingTaxId;
                }
            )
        )
            .min(1, 'At least one tax residency must be specified.')
            .required('At least one tax residency is required'),
    });

    const individualValidationSchema = Yup.object().shape({
        individual: Yup.object({
            taxCountries: Yup.array(
                Yup.object({
                    countryCode: Yup.string()
                        .trim()
                        .required(
                            t(
                                'client.registration.personal_details.nationality.country_code.required_validation_message'
                            )
                        ),
                    taxId: Yup.string(),
                    reasonForNotHavingTaxId: Yup.string(),
                }).test(
                    'taxId-or-reasonForNotHavingTaxId',
                    t(
                        'subscriptions.add.tax_id_reason.required_validation_message'
                    ),
                    function (item) {
                        return !!item.taxId || !!item.reasonForNotHavingTaxId;
                    }
                )
            )
                .min(
                    1,
                    t(
                        'subscriptions.add.tax_country.required_validation_message'
                    )
                )
                .required('You must specify tax country information.'),
        }),
    });

    const entityValidationSchema = Yup.object().shape({
        entity: Yup.object({
            taxCountries: Yup.array(
                Yup.object({
                    countryCode: Yup.string()
                        .trim()
                        .required(
                            t(
                                'client.registration.personal_details.nationality.country_code.required_validation_message'
                            )
                        ),
                    taxId: Yup.string(),
                    reasonForNotHavingTaxId: Yup.string(),
                }).test(
                    'taxId-or-reasonForNotHavingTaxId',
                    t(
                        'subscriptions.add.tax_id_reason.required_validation_message'
                    ),
                    function (item) {
                        return !!item.taxId || !!item.reasonForNotHavingTaxId;
                    }
                )
            )
                .min(
                    1,
                    t(
                        'subscriptions.add.tax_country.required_validation_message'
                    )
                )
                .required('You must specify tax country information.'),

            legalEntityType: Yup.string()
                .trim()
                .required(
                    `${t(
                        'subscriptions.add.tax_declaration.entity_investor.fatca_declaration.us_person_selection'
                    )} ${t('isRequired')}`
                ),
            controllingPersons: Yup.array(controllingPersonSchema).when(
                'fatca.nonFinancialInstitutionType',
                {
                    is: 'passiveNonFinancialForeignEntity',
                    then: Yup.array(controllingPersonSchema).min(
                        1,
                        t(
                            'subscriptions.add.controlling_persons.required_validation_message'
                        )
                    ),
                    otherwise: Yup.array(controllingPersonSchema),
                }
            ),
        }),
    });
    const validationSchema =
        endInvestorProfile?.endInvestorType ===
        EndInvestorProfileResponse.EndInvestorType.individual
            ? individualValidationSchema
            : entityValidationSchema;

    const handleSubmit = async (
        values: TaxInformation,
        formikHelpers: FormikHelpers<TaxInformation>
    ) => {
        const isFinancialInstitution =
            values.entity?.fatca?.isFinancialInstitution;
        if (values.entity?.fatca) {
            // if Entity is Financial Institution Clear Non Financial institution Fields, vice versa
            if (isFinancialInstitution) {
                values.entity.fatca.nonFinancialInstitutionType =
                    FatcaNonFinancialInstitutionTypeEnum.other;
                values.entity.fatca.otherNonFinancialInstitution = '';
                values.entity.crs.nonFinancialInstitutionType =
                    CrsNonFinancialInstitutionTypeEnum.notApplicable;
                values.entity.crs.isEntityNotATaxResident = false;
                values.entity.crs.entityNotATaxResidentDescription = '';
                values.entity.controllingPersons = [];
            } else {
                // If toggled to false, clear Financial Institution fields
                values.entity.fatca.fatcaFinancialInstitutionType =
                    FatcaFinancialInstitutionTypeEnum.notApplicable;
                values.entity.fatca.GIIN = '';
                values.entity.fatca.noGIINReasonType =
                    NoGIINReasonTypeEnum.notApplicable;
                values.entity.fatca.sponsorsGIIN = '';
                values.entity.fatca.sponsorsName = '';
                values.entity.fatca.noGIINSponsorType =
                    NoGIINSponsorType.notApplicable;
                values.entity.fatca.exemptBeneficialOwnerType =
                    ExemptBeneficialOwnerTypes.other;
                values.entity.fatca.w8Document = [];

                values.entity.fatca.igaCountryCode = '';

                values.entity.fatca.nonReportingCategory = '';
                values.entity.fatca.entityTypeWithInvestmentComponent = false;
                values.entity.crs.financialInstitutionType =
                    CrsFinancialInstitutionTypeEnum.notApplicable;
            }
            // these are mandatory in DB but optional in UI
            if (!values.entity.fatca.noGIINReasonType) {
                values.entity.fatca.noGIINReasonType =
                    NoGIINReasonTypeEnum.notApplicable;
            }
            if (!values.entity.fatca.noGIINSponsorType) {
                values.entity.fatca.noGIINSponsorType =
                    NoGIINSponsorType.notApplicable;
            }
            if (!values.entity.fatca.exemptBeneficialOwnerType) {
                values.entity.fatca.exemptBeneficialOwnerType =
                    ExemptBeneficialOwnerTypes.other;
            }
            // Clear Controlling Person
            if (
                values.entity?.fatca?.nonFinancialInstitutionType !==
                'passiveNonFinancialForeignEntity'
            ) {
                values.entity.controllingPersons = [];
            }
        }

        await updateTaxInformation({
            subscriptionId: data._id,
            body: values,
        })
            .then(async () => {
                formikHelpers.setSubmitting(false);

                notifySuccess(t('subscriptions.add.tax_declaration_updated'));

                if (isSubscription) {
                    setTimeout(() => {
                        goToNextStep && goToNextStep();
                    }, 1000);
                } else navigate(`/clients/${endInvestorProfile?._id}`);
            })
            .catch((_) => notifyError('Failed to submit data!'));
    };
    return (
        <div className="w-full overflow-y-auto">
            <div className="w-full md:w-11/12 xl:w-4/5">
                <div className="flex flex-col items-start justify-between md:flex-row">
                    <div className="w-4/12 sm:w-6/12 md:w-6/12 lg:w-7/12 xl:w-7/12 2xl:w-8/12">
                        <h1 className="text-3xl text-logo-blue ">
                            {t('subscriptions.add.tax_declaration.title')} *
                        </h1>
                    </div>
                    {configuration?.preferences.feature?.subscriptionProcess
                        ?.allowDownloadingPartialDocuments && (
                        <DownloadManualDraftSubscriptionAgreement
                            subscriptionId={data._id}
                            subscriptionStatus={data.status}
                        />
                    )}
                </div>
            </div>
            {endInvestorProfile?.endInvestorType && (
                <div className="w-full md:w-11/12 xl:w-4/5">
                    <Formik
                        initialValues={initialVals}
                        validationSchema={validationSchema}
                        enableReinitialize
                        onSubmit={handleSubmit}
                    >
                        {(formikProps) => (
                            <Form>
                                {endInvestorProfile?.endInvestorType ===
                                    EndInvestorProfileResponse.EndInvestorType
                                        .individual && (
                                    <RenderIndividualTaxDeclarations
                                        formikProps={formikProps}
                                        areInputsDisabled={areInputsDisabled}
                                    />
                                )}
                                {endInvestorProfile?.endInvestorType !==
                                    EndInvestorProfileResponse.EndInvestorType
                                        .individual && (
                                    <RenderEntityTaxDeclarations
                                        formikProps={formikProps}
                                        setCustomErrors={setCustomErrors}
                                        areInputsDisabled={areInputsDisabled}
                                    />
                                )}
                                <div>
                                    {formikProps.errors?.entity && (
                                        <RecursiveErrorDisplay
                                            errors={
                                                formikProps?.errors?.entity
                                                    ? {
                                                          fatcaFinancialInstitutionType:
                                                              formikProps.errors
                                                                  ?.entity
                                                                  ?.fatca
                                                                  ?.fatcaFinancialInstitutionType,
                                                          nonFinancialInstitutionType:
                                                              formikProps.errors
                                                                  ?.entity
                                                                  ?.fatca
                                                                  ?.nonFinancialInstitutionType,
                                                          crsFinancialInstitutionType:
                                                              formikProps.errors
                                                                  ?.entity?.crs
                                                                  ?.financialInstitutionType,
                                                          crsNonFinancialInstitutionType:
                                                              formikProps.errors
                                                                  ?.entity?.crs
                                                                  ?.nonFinancialInstitutionType,
                                                          legalEntityType:
                                                              formikProps.errors
                                                                  ?.entity
                                                                  .legalEntityType,
                                                          taxCountries:
                                                              typeof formikProps
                                                                  .errors
                                                                  ?.entity
                                                                  .taxCountries ===
                                                              'string'
                                                                  ? formikProps
                                                                        .errors
                                                                        ?.entity
                                                                        .taxCountries
                                                                  : '',
                                                          controllingPersons:
                                                              typeof formikProps
                                                                  .errors
                                                                  ?.entity
                                                                  .controllingPersons ===
                                                              'string'
                                                                  ? formikProps
                                                                        .errors
                                                                        ?.entity
                                                                        .controllingPersons
                                                                  : '',
                                                      }
                                                    : null
                                            }
                                        />
                                    )}
                                    <RecursiveErrorDisplay
                                        errors={customErrors}
                                    />
                                </div>
                                <SubscriptionsFormikSubmitBtns
                                    isSubscriptionReadOnly={
                                        data.status !==
                                        SubscriptionStatusEnum.inProgress
                                    }
                                    saveLabel={
                                        isSubscription
                                            ? undefined
                                            : t('ui.controls.save')
                                    }
                                    disabled={
                                        areInputsDisabled ||
                                        Object.keys(customErrors).length > 0
                                    }
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};

export default TaxDeclarations;
