import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from './Components/DashboardHeader';
import {
    DashboardType,
    FilterType,
    sortFunds,
    SortingProperty,
    SortType,
} from './Dashboard.helper';
import { DashboardFund } from './DashboardFund';
import { DashboardDataControls as Controls } from './Components/DashboardControls';
import {
    ActionPoint,
    EndInvestorPortfolioItem,
} from '@interfaces/Api/Dashboard';
import { getFormattedCurrency } from '@helpers/isoCurrencies';
import { WidgetPieChart } from '@components/Charts';
import EventSummary from './Components/EventSummary';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { ClientEnum } from 'common';
import { EnvironmentEnum } from '@interfaces/Api/ConfigurationResponse';

interface DashboardProps {
    dashboardType: DashboardType;
    items: EndInvestorPortfolioItem[];
}

export const Dashboard: React.FC<DashboardProps> = ({
    dashboardType,
    items,
}) => {
    const { t } = useTranslation();
    const [filteredBy, setFilteredBy] = useState<FilterType>();
    const [sortedBy, setSortedBy] = useState<SortType>();
    const [filteredItems, setUpdatedFundData] =
        useState<EndInvestorPortfolioItem[]>(items);
    const { isClient, isEnvironment } = useConfigurationState();
    useEffect(() => {
        if (filteredBy === 'all') {
            setUpdatedFundData(items);
        } else {
            const filteredFunds = items.filter(
                (item) => item.fundData.liquidityType === filteredBy
            );
            setUpdatedFundData(filteredFunds);
        }
    }, [filteredBy, items]);

    useEffect(() => {
        if (sortedBy === undefined) setUpdatedFundData(items);
        else {
            const sortedFunds = sortFunds({
                funds: items,
                sortingOrder: sortedBy.includes('asc') ? 'asc' : 'desc',
                property: sortedBy
                    .replace(' - asc', '')
                    .replace(' - desc', '') as SortingProperty,
            });
            setUpdatedFundData(sortedFunds);
        }
    }, [items, sortedBy]);

    const headerData = useCallback(() => {
        const numberOfFunds = items.length;

        // Probably need to split different currencies up?
        // E.g. if there are funds in USD and EUR, we need to show the total in both currencies
        const totalCurrentValue = items.reduce(
            (acc, item) => acc + (item.investmentData.currentValue || 0),
            0
        );

        const totalCommitted = items.reduce(
            (acc, item) => acc + (item.investmentData.commitment || 0),
            0
        );

        const totalInvested = items.reduce(
            (acc, item) => acc + (item.investmentData.calledCapital || 0),
            0
        );

        const currencyCode =
            items[0]?.investmentData?.shareClass?.currency || 'GBP';

        const pieChartData = items
            .filter((a) => a.investmentData.commitment)
            .map((b) => ({
                data: b.investmentData.commitment,
                label: [
                    b.fundData.productName,
                    b.investmentData?.shareClass?.currency
                        ? `(${b.investmentData?.shareClass?.currency})`
                        : '',
                ]
                    .filter((a) => a)
                    .join(' '),
            }));

        return {
            header: {
                tile1: [t('client.portfolio.funds'), numberOfFunds.toString()],
                tile2: [
                    t('client.portfolio.value'),
                    getFormattedCurrency(
                        totalCurrentValue,
                        currencyCode,
                        false,
                        'code'
                    ),
                ],
                tile3: (
                    <div className="bg-container-grey p-3 w-full flex items-center justify-between text-lg">
                        <div>{t('client.portfolio.total_committed')}</div>
                        <div>{`${getFormattedCurrency(
                            totalCommitted,
                            currencyCode,
                            false,
                            'code'
                        )}`}</div>
                    </div>
                ),
                tile4: (
                    <div className="bg-container-grey p-3 w-full flex items-center justify-between text-lg">
                        <div>{t('client.portfolio.total_invested')}</div>
                        <div>{`${getFormattedCurrency(
                            totalInvested,
                            currencyCode,
                            false,
                            'code'
                        )}`}</div>
                    </div>
                ),
                tile5: (
                    <div className="bg-container-grey p-2 pl-3 pr-3 w-full flex items-center justify-between">
                        <p className="text-lg">
                            {t('client.portfolio.commitment_allocation')}
                        </p>
                        <div className="w-20">
                            <WidgetPieChart
                                data={{
                                    datasets: [
                                        {
                                            data: pieChartData.map(
                                                (a) => a.data
                                            ),
                                            backgroundColor: [
                                                'rgba(0, 55, 110, 0.2)',
                                                'rgba(0, 97, 110, 0.2)',
                                            ],
                                            borderColor: [
                                                'rgba(0, 55, 110, 1)',
                                                'rgba(0, 97, 110, 1)',
                                            ],
                                            borderWidth: 1,
                                        },
                                    ],
                                    // labels: pieChartData.map((a) => a.label),
                                }}
                                options={{
                                    responsive: true,
                                    layout: {
                                        padding: {
                                            top: -20,
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            display: false,
                                            // position: 'right' as const,
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                ),
            },
        };
    }, [items]);

    const getActionPoints = () => {
        const actionPoints: ActionPoint[] = [];
        items.forEach((item) => {
            if (item.investmentData.actionPoints) {
                actionPoints.push(...item.investmentData.actionPoints);
            }
        });
        return actionPoints;
    };

    return (
        <div className="mt-10">
            <Header dashboardType={dashboardType} data={headerData()} />
            {isClient(ClientEnum.S64) &&
                !isEnvironment(EnvironmentEnum.PROD) && (
                    <EventSummary
                        actionPoints={getActionPoints()}
                        currencyCode={
                            items[0].investmentData.shareClass.currency
                        }
                    />
                )}

            <Controls
                filterByList={[
                    {
                        label: t('client.portfolio.filter_by_liquidity_all'),
                        value: 'all',
                    },
                    {
                        label: t('client.portfolio.filter_by_liquidity_closed'),
                        value: 'closedEnded',
                    },
                    {
                        label: t('client.portfolio.filter_by_liquidity_open'),
                        value: 'openEnded',
                    },
                ]}
                sortByList={[
                    {
                        label: t('ui.controls.launch_date_asc'),
                        value: 'inceptionDate - asc',
                    },
                    {
                        label: t('ui.controls.launch_date_desc'),
                        value: 'inceptionDate - desc',
                    },
                ]}
                setFilteredBy={setFilteredBy}
                setSortedBy={setSortedBy}
            />

            {filteredItems.map((item, i) => (
                <DashboardFund
                    dashboardType={dashboardType}
                    key={i}
                    fundData={item.fundData}
                    investmentData={item.investmentData}
                    performanceData={item.performanceData}
                    latestPerformanceData={item.latestPerformanceData}
                    valuationDate={item.valuationDate}
                    currentUnitPrice={item.currentUnitPrice}
                    averagePurchaseUnitPrice={item.averagePurchaseUnitPrice}
                    totalReturn={item.totalReturn}
                    returnHistory={item.returnHistory}
                />
            ))}
        </div>
    );
};
