import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

export const BusinessDetailsSummary = (): JSX.Element => {
    const { data } = useRegisterInvestorState();
    const { t } = useTranslation();

    const {
        companyName,
        registrationNumber,
        subscribersLegalForm,
        subscribersRegister,
        subscribersRegulator,
    } = data['businessEntityDetails'] || {};

    return (
        <div>
            <span className="font-bold mr-2">
                {t('client.registration.summary.business_details')}:
            </span>
            <p>{companyName}</p>
            <p>{registrationNumber}</p>
            <p>{subscribersLegalForm}</p>
            <p>{subscribersRegister}</p>
            <p>{subscribersRegulator}</p>
        </div>
    );
};
