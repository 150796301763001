import { getLocalStorageAuthTokens } from '@helpers/auth.helper';
import { Document } from '@interfaces/Api';

import { BaseUrl } from '../Helpers/Environment';
import { Http } from '@libs/http';
import { msalInstance } from '@libs/msal/msalInit';

const API_DOCUMENTS = `${BaseUrl}/documents`;

// TODO: pull into own file
export type UploadDocumentType =
    | 'kycDocument'
    | 'passport'
    | 'proofOfAddress'
    | 'sourceOfFunds'
    | 'sourceOfWealth'
    | 'manualSignedSubscriptionAgreement'
    | 'nomineeUnderlyingInvestors'
    | 'proofOfEmployment'
    | 'transactionStatement'
    | 'accountStatement'
    | 'productDocument'
    | 'optUpSupportingDocuments';

export const documentsPost = async (
    label: UploadDocumentType,
    file: File
): Promise<{ url: string; fileName: string }> => {
    const documentUploadUrl = new URL(API_DOCUMENTS);
    documentUploadUrl.searchParams.set('label', label);

    const formData = new FormData();
    formData.append('upfile', file);

    return Http.post(documentUploadUrl.toString(), formData);
};

export const getImage = async (url: string): Promise<Blob> => {
    const account = msalInstance?.getActiveAccount();
    let token: string;

    if (account) {
        const scopes = localStorage.getItem('AUTH_SCOPES');
        const authority = localStorage.getItem('AUTH_AUTHORITY');
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (token = response?.accessToken))
            .catch((error) => {
                return (token = undefined);
            });
    } else {
        token = getLocalStorageAuthTokens()?.access?.token;
    }

    const httpHeaders = {
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: httpHeaders,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.blob();
    } catch (error) {
        console.error('Failed to fetch image', url);
    }
};

export const getDocumentUrl = async (url: string): Promise<string> => {
    const account = msalInstance?.getActiveAccount();
    let token: string;

    if (account) {
        const scopes = localStorage.getItem('AUTH_SCOPES');
        const authority = localStorage.getItem('AUTH_AUTHORITY');
        await msalInstance
            .acquireTokenSilent({
                scopes: JSON.parse(scopes || '[]'),
                account: account,
                authority,
            })
            .then((response) => (token = response?.accessToken))
            .catch((error) => {
                return (token = undefined);
            });
    } else {
        token = getLocalStorageAuthTokens()?.access?.token;
    }

    const httpHeaders = {
        Authorization: `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: httpHeaders,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const contentType = response.headers.get('Content-Type');

        const stream = new ReadableStream({
            start(controller) {
                const push = async () => {
                    const { done, value } = await reader.read();
                    if (done) {
                        controller.close();
                        return;
                    }

                    controller.enqueue(value);
                    push();
                };

                push();
            },
        });

        const blob = await new Response(stream, {
            headers: { 'Content-Type': contentType },
        }).blob();
        return window.URL.createObjectURL(blob);
    } catch (error) {
        throw new Error(`Failed to fetch document: ${(error as any).message}`);
    }
};
