import { useEffect } from 'react';

import {
    useBookingCentresActions,
    useBookingCentresState,
} from '@stores/BookingCentres';

export const useBookingCentres = (intermediaryId?: string, rmId?: string) => {
    const { bookingCentres, isLoading } = useBookingCentresState();
    const { addBookingCentre, fetchBookingCentres, deleteBookingCentre } =
        useBookingCentresActions();

    useEffect(() => {
        if (intermediaryId) {
            const loadData = async () => {
                await fetchBookingCentres(intermediaryId, rmId);
            };

            // A further improvment here would be to check if data already exists in the store
            // and if systemUserId is the same as the one in the store, then don't fetch data
            loadData();
        }
    }, [fetchBookingCentres, intermediaryId, rmId]);

    return { addBookingCentre, bookingCentres, deleteBookingCentre, isLoading };
};
