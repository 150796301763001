import classNames from 'classnames';

export const getInputStyle = ({
    label,
    disabled,
    error,
    leftIcon,
    rightIcon = false,
    cursor = 'default',
    isSelect = false,
}: any) =>
    classNames(
        'min-w-full w-full pl-3 pr-3 placeholder-gray-300 border border-gray-300 rounded-lg focus:shadow-outline z-20',
        {
            'h-16 pt-5': label,
            'h-12': !label,
            'border-red-error bg-error': error,
            'cursor-default': cursor == 'default' || disabled,
            'cursor-text': cursor == 'text' && !disabled,
            'cursor-pointer': cursor == 'pointer' && !disabled,
            'pl-6': leftIcon,
            'pr-10': rightIcon,
            'pr-3': !rightIcon,
            'relative appearance-none bg-white': isSelect,
            'bg-white': !isSelect,
        }
    );
