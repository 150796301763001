import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthRoleEnum } from '@interfaces/Api';

import { useAuthState } from '@contexts/AuthContext';
import { useConfigurationState } from '@contexts/ConfigurationContext';
import { BurgerNav, Logo } from '@components/Molecules';
import { LanguageSelector } from '@components/Organisms';

import UserInfo from './UserInfo';
import MenuItem from './MenuItem';
import { MobileNavMenu } from './MobileNavMenu';

import { useNavBar } from './useNavBar';
import { TenantComponentProviderFactory } from '@tenants/Pages/TenantComponentProviderFactory';

type MenuItem = {
    link?: string;
    name: string;
    showItem: (
        authRole: AuthRoleEnum,
    ) => boolean | boolean;
    subMenuitems: MenuItem[];
};

const Navbar = () => {
    const { t } = useTranslation();
    const [isMobileNavOpen, toggleMobileNav] = useState<boolean>(false);

    const { currentUser } = useAuthState();
    const { preferences, configuration } = useConfigurationState();
    const { defaultMenuItems } = useNavBar(t);

    const tenantComponent =
        TenantComponentProviderFactory.getTenantComponentProvider(
            configuration.client
        );

    const HelpAndContact = tenantComponent?.GetHelpAndContact();

    const menuItems = useMemo(() => {
        let items;
        if (currentUser?.user.role) {
            items = defaultMenuItems.filter((a) =>
                a.showItem
                    ? 
                      a.showItem(currentUser?.user.role)
                    : true
            );
        }

        return items;
    }, [currentUser?.user.role, defaultMenuItems]);

    return (
        <div className="container mx-auto px-4 sm:px-8 h-18 md:h-20 bg-header-bg z-30">
            <div className="w-full flex justify-between h-full pt-2">
                {isMobileNavOpen && (
                    <MobileNavMenu
                        menuItems={menuItems?.map((item, index) => (
                            <MenuItem
                                key={index}
                                exitNav={() => toggleMobileNav(false)}
                                name={item.name}
                                link={item?.link}
                                subMenuitems={item?.subMenuitems}
                            />
                        ))}
                        setOpenNav={toggleMobileNav}
                    />
                )}
                <div className="flex items-center">
                    <Logo className="py-2" />

                    <nav
                        id="menu"
                        className="ml-1 hidden md:flex md:items-start md:w-auto w-full h-full"
                    >
                        <ul className="md:flex items-center justify-between text-base text-logo-blue pt-4 md:pt-0 h-full z-50">
                            {menuItems?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    exitNav={() => toggleMobileNav(false)}
                                    name={item.name}
                                    link={item?.link}
                                    subMenuitems={item?.subMenuitems}
                                />
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className="flex items-center">
                    {preferences?.feature.topMenu
                        ?.enableLocaleBasedLanguages && <LanguageSelector />}

                    <UserInfo />

                    <BurgerNav
                        className="md:hidden p-2 pl-4"
                        setOpenNav={() => toggleMobileNav(true)}
                    />

                    <span className="order-2 md:order-3 cursor-pointer">
                        <HelpAndContact />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
