import React from 'react';
import { useTranslation } from 'react-i18next';

import Select, {
    GroupBase,
    MultiValue,
    ActionMeta,
    StylesConfig,
} from 'react-select';

// Had to update the react-select component type to get access to the error state in the
// custom styles section. More information:
// https://github.com/JedWatson/react-select/issues/4804#issuecomment-927223471
declare module 'react-select/dist/declarations/src/Select' {
    export interface Props<
        Option,
        IsMulti extends boolean,
        Group extends GroupBase<Option>
    > {
        hasError?: boolean;
    }
}

export interface Option {
    value: string;
    label: string;
}

// https://react-select.com/styles
const customStyles: StylesConfig<Option, true> = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '8px',
        minHeight: '56px',
        borderWidth: '1px',
        borderColor: state.selectProps.hasError
            ? 'rgba(187, 6, 6, 1)'
            : state.isFocused
            ? 'rgba(73, 119, 221, 1)'
            : 'rgba(215, 215, 224, 1)',
        boxShadow: 'unset',
        backgroundColor: state.selectProps.hasError
            ? 'rgba(187, 6, 6, 0.08)'
            : 'unset',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        paddingTop: '28px',
        paddingBottom: '4px',
        paddingLeft: '12px',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: '#00376e',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#00376e',
        padding: '0 16px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#00376e',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 50,
    }),
};

interface MultiselectProps {
    name: string;
    options: Option[];
    label?: string;
    value?: Option[];
    placeholder?: string;
    hasError?: boolean;
    onChange: (
        newValue: MultiValue<Option>,
        actionMeta: ActionMeta<Option>
    ) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
}

const Multiselect: React.FC<MultiselectProps> = (props) => {
    const { t } = useTranslation();
    const {
        name,
        options,
        label,
        value,
        placeholder,
        hasError,
        onChange,
        onBlur,
        disabled,
    } = props;
    return (
        <div className="relative" data-testid="input-multiselect">
            <Select
                name={name}
                inputId={name}
                options={options}
                value={value}
                placeholder={placeholder}
                hasError={hasError}
                onChange={onChange}
                onBlur={onBlur}
                isDisabled={disabled}
                styles={customStyles}
                isMulti
                noOptionsMessage={() => t('ui.controls.no_options')}
            />
            {label && (
                <label
                    className="absolute left-1 top-2 text-xs px-2"
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
        </div>
    );
};

export default Multiselect;
