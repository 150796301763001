import { useTranslation } from 'react-i18next';
import { useRegisterInvestorState } from '../RegisterInvestor.context';

import { getAdvisors } from '@stores/SystemUsers/SystemUsers.services';
import { SystemUserResponse } from '@interfaces/Api';
import { useEffect, useState } from 'react';

export const AdvisorSummary = () => {
    const { t } = useTranslation();
    const { data: currentState } = useRegisterInvestorState();
    const [advisers, setAdvisers] = useState<SystemUserResponse[]>([]);

    useEffect(() => {
        if (currentState.intermediaryId) {
            getAdvisors(currentState.intermediaryId).then((a) => {
                setAdvisers(a);
            });
        }
    }, [currentState.intermediaryId]);

    const adviser = advisers.find(
        (a) => a._id === currentState.adviserSystemUserId
    );
    const name = [adviser?.firstName, adviser?.surname]
        .filter((a) => a)
        .join(' ');

    return (
        <p>
            <span className="font-bold mr-2">
                {t('client.registration.summary.advisor')}:
            </span>
            {name}
        </p>
    );
};
